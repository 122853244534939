import React from 'react';

import { Modal, Button, Row, Col } from 'react-bootstrap';

export type ChoiceModalProps = {
    showModal: boolean;
    onHide: () => void;
    modalTitle?: string | any;
    onClose?: any;
    options?: any;
    children?: JSX.Element;
    btnWide?: boolean;
    justifyContentStart?: boolean;
};

const ChoiceModal = ({ showModal, modalTitle, options, onClose, onHide, btnWide, justifyContentStart }: ChoiceModalProps) => {
    const handleCloseModal = (option: any) => {
        onClose && onClose(option);
    };

    return (
        <Modal closeButton show={showModal} onHide={onHide} className="choice-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="p-3" style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
                        <span className="text-center">{modalTitle}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer className="justify-content-center">
                {
                    options.length > 1 && <Row className={`d-flex flex-row ${justifyContentStart ? 'justify-content-start' : 'justify-content-around'}`}>
                        {
                            options.map((option: any, idx: number) => (
                                <Col xs={12} sm={6} key={idx} className='mt-1'>
                                    <Button
                                        key={idx}
                                        variant="primary"
                                        className={`${btnWide ? 'btn-wide' : ''}`}
                                        onClick={() => {
                                            handleCloseModal(option);
                                        }}
                                        style={{ width: '100%', height: '100%' }}
                                    >
                                        {option.title}
                                    </Button>
                                </Col>
                            ))
                        }
                    </Row>
                }
                {
                    options.length === 1 && <Row>
                        <Button
                            variant="primary"
                            onClick={() => {
                                handleCloseModal(options[0]);
                            }}
                            style={{ maxWidth: '200px' }}
                        >
                            {options[0].title}
                        </Button>

                    </Row>
                }

            </Modal.Footer>
        </Modal>
    );
};

export default ChoiceModal;