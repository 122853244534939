import { proxy, Proxy } from '@api/azureProxy';

import {
  ProgramariCitiriTemplate
} from '@type/programari-citiri';

import { rssBaseUrl } from '@constants/ApiUrl';


export async function getCategoriesAndSubjects() {
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `${rssBaseUrl}/get_categories_and_subjects`,
    withAuthBearer: false
  };

  return proxy<ProgramariCitiriTemplate>(proxyData);
}
