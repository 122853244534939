import { useState } from 'react';
import React from 'react';

export type BoxItem = {
  link: string;
  image: string;
  title: string;
  isMobile: boolean;
  isLoggedIn: boolean;
  idx: number;
};

const BoxItemComponent = ({ link, image, title, isMobile, isLoggedIn, idx }: BoxItem): JSX.Element => {
  const [isHovered, setIsHovered] = useState<any>(false);

  const checkFirstBox = () => {
    if (isLoggedIn !== undefined) {
      return 'solicitari';
    } else {
      return 'login';
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`login-box-item ${isMobile ? 'mobile' : ''} ${
        isHovered ? 'item-expanded' : ''
      } w-100 d-flex flex-column justify-content-center align-items-center`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        window.location.href = idx === 0 ? checkFirstBox() : link;
      }}
    >
      <span className="icon-key">
        <img alt={title} src={image} className="image" style={{ maxWidth: '35px' }} />
      </span>
      {isMobile ? <p className="black">{title}</p> : isHovered ? <p className="black">{title}</p> : ''}
    </div>
  );
};

export default BoxItemComponent;
