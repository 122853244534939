import Search from '@components/account/MyAccount/ConsumptionPointsList/Search';
import CPTableHead from '@components/account/MyAccount/ConsumptionPointsList/table/CPTableHead';
import CPTableRow from '@components/account/MyAccount/ConsumptionPointsList/table/CPTableRow';
import { faDownload, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';

type ConsumptionPoint = {
    clientCode: string,
    clientName: string,
    consumptionCode: string,
    pod: string,
    address: string,
    contractStatus: string,
    meterNumber: string,
    connectionStatus: string,
    tariffCategory: string,
    srmp: string,
    minPressure: string,
    maxPressure: string,
    technicalMinPressure: string,
    contractCapacity: number,
    contractDuration: string,
    supplierCode: string,
    partnerId: string,
    invoiceClass: string,
    tariff: string,
    contractNo: string
}

const TabGN = (props: {
    consumptionPoints: ConsumptionPoint[] | null,
    countyChange: any,
    localityChange: any,
    localities: any,
    counties: any,
    submitSearch: any,
    locality: any,
    county: any,
    clcChange: any,
    podChange: any,
    partnerCodeChange: any,
    reset: any,
    searchPod: any,
    searchCLC: any,
    searchPartnerCode: any,
    didSearch: any,
    exportList: (utility: string) => any,
    isFurnizor: boolean,
}) => {
    const [toggleSearch, setToggleSearch] = useState(false);

    return (
        <div>
            { (props.consumptionPoints && props.consumptionPoints.length === 0 && props.didSearch === false) && <div className="mt-3 d-flex justify-content-center"><span>Momentan nu aveți niciun punct de consum!</span></div> }
            { ((props.consumptionPoints && props.consumptionPoints.length > 0) || props.didSearch) && <div className="m-3 d-flex justify-content-end">
                <Button onClick={() => setToggleSearch(prev => !prev)}>
                    <span style={{marginRight: '6px'}}>Panou filtrare</span>
                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                </Button>

                <Button className='ms-2' onClick={() => props.exportList('2')}>
                    <span style={{marginRight: '6px'}}>Export listă</span>
                    <FontAwesomeIcon icon={faDownload}/>
                </Button>
            </div> }
            { (toggleSearch) && <Search
                counties={props.counties}
                localities={props.localities}
                handleLocalityChange={props.localityChange}
                handleCountyChange={props.countyChange}
                submitSearch={props.submitSearch}
                locality={props.locality}
                county={props.county}
                clcChange={props.clcChange}
                podChange={props.podChange}
                partnerCodeChange={props.partnerCodeChange}
                reset={props.reset}
                pod={props.searchPod}
                clc={props.searchCLC}
                partnerCode={props.searchPartnerCode}
                utility={2}
            /> }
            {
                props.consumptionPoints && props.consumptionPoints.length > 0 &&
                <div className="m-3 general-table-container">
                    <Table>
                        <CPTableHead utility={2} isFurnizor={props.isFurnizor} />
                        <tbody>
                        { props.consumptionPoints.map((item, index) => (
                            <CPTableRow utility={2} item={item} key={index} isFurnizor={props.isFurnizor} />
                        )) }
                        </tbody>
                    </Table>
                </div>
            }
            {
                (props.consumptionPoints && props.consumptionPoints.length === 0 && props.didSearch) &&
                    <div className="m-3 text-center" style={{fontWeight: 'bold', color: 'red'}}>Nu există locuri de consum care să corespundă filtrelor dvs.</div>
            }
        </div>
    );
};

export default TabGN;