import { toggleLoader } from '@actions/LoaderActions';
import { GetLocalities } from '@api/geoApi';
import { getRequestsDetails, getSubjectListFurnizor, sendRequest } from '@api/rss/rss';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import EditModal from '@components/rss/requests/EditModal';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const Edit = () => {
    const [item, setItem] = useState<any>(null);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [type, setType] = useState<number | null>(null);
    const [localityName, setLocalityName] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showSentSuccessModal, setShowSentSuccessModal] = useState(false);
    const [categories, setCategories] = useState<any[]>([]);
    const [errorInvalidRequestStatus, setErrorInvalidRequestStatus] = useState('');

    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

    const dispatch = useDispatch();

    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        if(item) {
            if(item.statusId > 1) {
                setErrorInvalidRequestStatus('Solicitarea nu a fost gasită.');
            } else {
                mountValues();
            }
        }
    }, [item]);

    useEffect(() => {
        if (item) {
            if (item.scenarioName === 'RSS_LOCCONSUM_ALTFURNIZOR') {
                setType(2);
            } else if (item.scenarioName === 'RSS_LOCCONSUM') {
                setType(1);
            }

            getLocalityCode();
        }
    }, [item]);

    const getDetails = async () => {
        dispatch(toggleLoader(true));

        const url = new URL(window.location.href);
        // const url = new URL('https://dev.delgaz.ro/editare-petitie?id=303');
        const params = url.search;
        const searchParams = new URLSearchParams(params);

        if (searchParams.has('id')) {
            await getRequestsDetails(searchParams.get('id'))
                .then((res) => {
                    if (res && res.data) {
                        setItem(res.data);
                    } else {
                        setError('Solicitarea nu a fost gasită.');
                    }
                })
                .catch((err) => setError('Solicitarea nu a fost gasită.'));
        } else {
            setError('Solicitarea nu a fost gasită.');
        }

        dispatch(toggleLoader(false));
    };

    const handleShowModal = (value: boolean) => {
        setShowModal(value);
        getDetails();
    };

    const getLocalityCode = () => {
        GetLocalities(item.countyCode).then((res) => {
            res.map((c: any, i: number) => {
                if (item.localityCode === c.localityCode) {
                    setLocalityName(c.localityName);
                }
            });
        });
    };

    const send = async () => {
        setError('');
        dispatch(toggleLoader(true));

        await sendRequest(item.id).then((r) => {
            if (r && r.data && r.data.descriereEroare) {
                setError(r.data.descriereEroare);
            } else if (r && r.data && r.data.status === 'Succes') {
                setShowSentSuccessModal(true);
            }
        })
            .catch((e) => setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    const handleSuccessModal = (show: boolean) => {
        setShowSuccessModal(show);
    };

    const hideSuccessModal = () => {
        setShowSuccessModal(false);
    };

    const hideSentSuccessModal = () => {
        setShowSentSuccessModal(false);
        window.location.href = `/detalii-petitie-furnizor?id=${item.id}`;
    };

    const getValues = (data: any) => {
        let arr: any[] = [];

        data.map((item: any) => {
            Object.keys(item).map((k) => {
                if (k === 'category') {
                    let already = false;

                    for (let i = 0; i < arr.length; ++i) {
                        if (arr[i].category === item[k]) {
                            already = true;
                        }
                    }

                    if (!already) {
                        arr.push({ category: item[k], categorySapValue: item.categorySapValue, subjects: [] });
                    }
                }
            });
        });

        arr.map((item: any) => {
            data.map((i: any) => {
                Object.keys(i).map((k) => {
                    if (k === 'subject') {
                        let already = false;

                        for (let j = 0; j <= item.subjects.length; ++j) {
                            if (item.subjects[j] && item.subjects[j].subject && item.subjects[j].subject === i[k]) {
                                already = true;
                            }
                        }

                        if (!already && (item.category === i['category'])) {
                            item.subjects.push({ subject: i[k], subjectSapValue: i['subjectSapValue'], subSubjects: [] });
                        }
                    }
                });
            });
        });

        arr.map((item: any) => {
            item.subjects.map((subject: any) => {
                data.map((i: any) => {
                    Object.keys(i).map((k) => {
                        if (k === 'subSubject') {
                            let already = false;

                            for (let j = 0; j <= subject.subSubjects.length; ++j) {
                                if (subject.subSubjects[j] && subject.subSubjects[j].subSubject && subject.subSubjects[j].subSubject === i[k]) {
                                    already = true;
                                }
                            }

                            if (!already && (subject.subject === i['subject']) && (item.category === i['category'])) {
                                subject.subSubjects.push({ subSubject: i[k], subSubjectSapValue: i['subSubjectSapValue'] });
                            }
                        }
                    });
                });
            });
        });

        //Filter categories by utility 
        if (item.pod[0] === 'E') {
            arr.map((itm: any, index: number) => {
                if (itm.categorySapValue === 'GN') {
                    arr.splice(index,1);
                }
            });
        } else if (item.pod[0] === 'D') {
            arr.map((itm: any, index: number) => {
                if (itm.categorySapValue === 'EE') {
                    arr.splice(index,1);
                }
            });
        }

        //Remove Alte Subiecte option on RSS Supplier
        arr = arr.filter((category) => {
            if (category.category == 'Alte Subiecte' || category.categorySapValue == 'AS') {
            return false;
            }
            return true;
        });

        setCategories(arr);
    };

    const mountValues = async () => {
        dispatch(toggleLoader(true));

        await getSubjectListFurnizor().then((res) => {
            getValues(res.data);
        });

        dispatch(toggleLoader(false));
    };

    const renderModal = () => {
        return <EditModal show={showModal} handleShow={handleShowModal} categories={categories} pod={item.pod} item={item} type={type} handleSuccessModal={handleSuccessModal} />;
    };

    return (
        <div className="m-3">
            <div className="d-flex align-items-center mb-2">
                <a href={`${OLD_SITE_URL}/petitii`}>
                    <Button>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <span>&nbsp;Înapoi</span>
                    </Button>
                </a>
            </div>
            {item && !errorInvalidRequestStatus && <Row>
                <Col xs={12} md={6} className="mt-2">
                    <div className="details-block h-100">
                        <div className="block-header">
                            <span className="block-title-border" />
                            <span className="block-title">
                                Date identificare
                            </span>
                        </div>
                        <div className="block-body">
                            <div className="property-container">
                                <span className="property-key">POD</span>
                                <span className="property-value">{item.pod}</span>
                            </div>
                            <div className="property-container">
                                <span className="property-key">CLC</span>
                                <span className="property-value">{item.vstelle}</span>
                            </div>
                            {item.cnp !== '' && item.cnp !== null && <div className="property-container">
                                <span className="property-key">Nume</span>
                                <span className="property-value">{item.firstName} {item.lastName}</span>
                            </div>}

                            <div className="property-container">
                                <span className="property-key">Email</span>
                                <span className="property-value">{item.email}</span>
                            </div>
                            <div className="property-container">
                                <span className="property-key">Telefon</span>
                                <span className="property-value">{item.phone}</span>
                            </div>
                            {localityName !== '' && <div className="property-container">
                                <span className="property-key">Adresa</span>
                                <span className="property-value">
                                    {item.countyCode}, {localityName}, {item.street && `${item.street},`} {item.number && `${item.number}`}
                                </span>
                            </div>}
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={6} className="mt-2">
                    <div className="details-block h-100">
                        <div className="block-header">
                            <span className="block-title-border" />
                            <span className="block-title">
                                Date solicitare
                            </span>
                        </div>
                        <div className="block-body">
                            <div className="property-container">
                                <span className="property-key">Tip interacțiune</span>
                                <span className="property-value">{item.requestTypeName}</span>
                            </div>
                            <div className="property-container">
                                <span className="property-key">Categorie Subiect</span>
                                <span className="property-value">{item.category}</span>
                            </div>
                            <div className="property-container">
                                <span className="property-key">Subiect</span>
                                <span className="property-value">{item.subject}</span>
                            </div>
                            <div className="property-container">
                                <span className="property-key">Motivul interacțiunii (subiectul detailat)</span>
                                <span className="property-value">{item.subSubject}</span>
                            </div>
                            <div className="property-container">
                                <span className="property-key">Mesaj</span>
                                <span className="property-value">{item.description}</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>}

            {item && !errorInvalidRequestStatus && <Col xs={12} sm={4} md={6} className="mt-3">
                <div className="d-flex flex-column mt-2">
                    <div>
                        <Button className='mb-2' onClick={() => setShowModal(true)} style={{marginRight: '8px'}}>Modifică</Button>
                        <Button className='mb-2' onClick={() => send()}>Trimite solicitare</Button>
                    </div>
                </div>
            </Col>}
            {error !== '' && <p className="fw-bold red mt-2">{error}</p>}
            {errorInvalidRequestStatus !== '' && <p className="fw-bold red mt-2">{errorInvalidRequestStatus}</p>}
            {item && !errorInvalidRequestStatus && renderModal()}
            <ModalConfirm showModal={showSuccessModal} onClose={hideSuccessModal} modalTitle={'Succes'}>
                <p>Solicitarea s-a salvat cu succes.</p>
            </ModalConfirm>
            <ModalConfirm showModal={showSentSuccessModal} onClose={hideSentSuccessModal} modalTitle={'Succes'}>
                <p>Solicitarea a fost trimisă cu succes.</p>
            </ModalConfirm>
        </div>
    );
};

export default Edit;