import React, {useEffect, useState} from 'react';

const AddCPTableRow = (props: {item: any, onSelect: any, allSelected: any}) => {
    const [selected, setSelected] = useState(false);
    //eslint-disable-next-line
    const selectStyle = selected ? {border: '3px solid red', marginBottom: '4px'} : {border: 'none'};

    useEffect(() => {
        setSelected(props.allSelected);
    }, [props.allSelected]);

    return (
        <tr
            className={((selected || props.allSelected) && props.item.isAvailable) ? 'selected' : ''}
            style={props.item.isAvailable ? {cursor: 'pointer'} : {pointerEvents: 'none', background: 'lightgray'}}
            onClick={() => {
                props.onSelect(props.item);
                setSelected(prev => !prev);
            }}
        >
            <td style={{paddingTop: '15px'}}>
                {props.item.isAvailable && <input type="checkbox" className="create-account-checkbox" checked={selected} readOnly={true} />}
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <span>{props.item.pod}</span>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <span>{props.item.address}</span>
                </div>
            </td>
            <td>
                {!props.item.isAvailable ? <span>Locul de consum este deja atasat unui cont!</span> : <span>-</span>}
            </td>
        </tr>
    );
};

export default AddCPTableRow;