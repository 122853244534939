import { SET_BO_OPTIONS, SET_RESPONSE_HEADERS } from '@constants/ActionTypes/PostTypes';

export function setPath(basePath: string, securityContext: string) {
  return {
    type: SET_BO_OPTIONS,
    payload: {basePath, securityContext}
  };
}

// TODO - refactor and reuse this as a general function to parse headers from
export function documentExportHeaders(headers: any ) {
  return {
    type: SET_RESPONSE_HEADERS,
    payload: {exportDocumentHeaders: headers}
  };
}
