import Oferte from '@components/dev/Components/Achizitii/Oferte';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { OferteAchizitieKentico } from '@type/kentico-types';

export type OferteAchizitieProps = { kentico: OferteAchizitieKentico };

export const OferteAchizitie = (props: OferteAchizitieProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <Oferte {...props}/>
        </DelgazWrapper>
    );
};