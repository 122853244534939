import React, { Fragment, useEffect, useRef, useState } from 'react';

import {
  Button,
  Col,
  Form,
  Row
} from 'react-bootstrap';

import { toggleLoader } from '@actions/LoaderActions';
import { CustomGet, Export, QueryParams } from '@api/backOffice/fetchResults';
import { Dropdown } from '@components/common/Dropdown';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import BackOfficeProvider, { BackOffice, BackOfficeContext, BackOfficeContextType, InputValues, MetaData } from '@contexts/backoffice-context';
import { BackOfficeComponentProps } from '@kentico/backOffice';
import { ToString } from '@lib/Utils';
import { CustomActionTypes, DataTypes } from '@type/backOfficeModule';
import { useDispatch, useSelector } from 'react-redux';
import String from './Fields/String';
import FiltersForm, { FiltersCollapsedState } from './FiltersForm';
import ResponseListing from './ResponseListing';

import { DropdownOptionType } from '@components/common/Dropdown';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { RadioGroup } from '@components/common/RadioGroup';
import RSSCallCenter from '@components/rssCallCenter/RSSCallCenter';
import * as notification from '@lib/Notification';
import { ModalSize } from '@type/backOfficeModule';
import { IState } from '@type/store';
import { AxiosRequestHeaders } from 'axios';
import moment from 'moment';
import { getQueryParam } from '../../../helpers/urlUtils';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
import DetailedItem from './DetailedItem';
import Base64File from './Fields/Base64File';
import Base64FileList from './Fields/Base64FileList';
import DateRange, { DataItem } from './Fields/DateRange';
import DateTime from './Fields/DateTime';
import DropdownField from './Fields/DropdownField';
import DropdownInt from './Fields/DropdownInt';
import DropdownString from './Fields/DropdownString';
import Html from './Fields/Html';
import Int from './Fields/Int';
import Paragraph from './Fields/Paragraph';
import StringList from './Fields/StringList';
import StringListEdit from './Fields/StringListEdit';

type GeneralAction = BackOffice['generalActions'][number];
export type FormGroup = GeneralAction['formGroups'][number];
export type FormField = FormGroup['fields'][number];

export type Filter = {
  Name: string;
  Value: string | number | string [];
  DataTypeId: number;
}

export type ValueToSet = {
  name: string;
  value: string | number | string[] | any;
  itemTypeId: number;
}

export class BackOfficeSearch {
    PageIndex: number = 1;
    PageSize: number | null = null;
    SortColumn?: string | null = null;
    SortAscending: boolean = false;
    GeneralSearchText: string | null = '';
    Filters: Filter[] = [];
}

export type OptionsForField = {
  field: string,
  keyFieldName: string,
  child: string | any,
  childUrl: string | any,
  childUrlQuerryParameterName: string | any,
  parent: string | any,
  options: Option[]
}

export type Option = {
  [key: string | number]: any
}

// type DateObject = {
//   dataTypeName: string;
//   displayName: string;
//   items: null;
//   name: string;
//   dataTypeId: number;
//   value: string;
// }

let integerTypes = [DataTypes.Int, DataTypes.HiddenInt, DataTypes.ReadonlyInt, DataTypes.DropdownInt];

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

const PageWrapper = (props: BackOfficeComponentProps) => {
  const dispatch = useDispatch();
  //eslint-disable-next-line
  const headers: AxiosRequestHeaders = useSelector<IState>((state) => state.backOffice.exportDocumentHeaders) as AxiosRequestHeaders;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [action, setAction] = useState<GeneralAction | null>(null);
  const [values, setValues] = useState<InputValues>({} as InputValues);
  const [formValidationErrors, setFormValidationErrors] = useState<any>([]);
  const { config, fetchResults, response, onRowAction, isFullWidth, detailedElement, searchModel, setSearchModel, onFilterValueChange, requiredFilterNotFound } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [optionsForField, setOptionsForField] = useState<OptionsForField[]>([] as OptionsForField[]);
  //eslint-disable-next-line
  const [pageHeight, setPageHeight] = useState<number>(useWindowDimensions().height);
  const [showModalExportLimit, setShowModalExportLimit] = useState<boolean>(false);

  //Custom Actions
  const [rssRegisteredWithSucces, setRssRegisteredWithSucces] = useState<boolean>(false);
  const [showRSSSaveButton, setShowRSSSaveButton] = useState<boolean>(false);
  const [RSSSaveButtonDisabled, setRSSSaveButtonDisabled] = useState<boolean>(false);
  const RSSCallCenterRef = useRef(null);

  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(false);
  const [initialFiltersCollapsedState, setInitialFiltersCollapsedState] = useState<FiltersCollapsedState>({} as FiltersCollapsedState);

  const [searchRequested, setSearchRequested] = useState<boolean>(false);

  useEffect(() => {
    if (isEditing === false) {
      setValues({} as InputValues);
    } else {
      if (config && action) {
        let allFields: InputValues = {};
        action?.formGroups?.forEach((group) => {
          let fields = group?.fields?.reduce((o, current) => ({
            ...o,
            [current.name]: current.dataTypeId === DataTypes.Base64FileList ? [] : (current.dataTypeId === DataTypes.StringListEdit ? current.value ? current.value : [''] : integerTypes.includes(current.dataTypeId) ? Number(current.value) : current.value) ?? ''
          }),
            {} as InputValues
          );
          allFields = { ...allFields, ...fields };
        });
        setValues((prevValues: any) => {
          return {...prevValues,
              ...allFields
        };});
      }
    }
  }, [isEditing]);

  // const cleanUpFilters = async () => {
  //   if (config) {
  //     const newSearchModel = {
  //       ...new BackOfficeSearch(),
  //       SortColumn: config.sortColumn,
  //       GeneralSearchText: '',
  //       PageSize: config.pagination.defaultPageSizeValue,
  //       Filters: [],
  //       SortAscending: config.sortAscending,
  //     };
  //     await setSearchModel(newSearchModel);

  //     fetchResults(newSearchModel, false);
  //   }
  // };

  const resetFiltersToDefault = async () => {
    if (config) {
      setInitialFiltersValues();
      setSearchRequested(true);
    }
  };
    
  const removeFilter = (name: string) => {
    //@ts-ignore
    setSearchModel((prevStateSearchModel: any) => {
      return {
        ...prevStateSearchModel,
        Filters: prevStateSearchModel.Filters.filter((f: any) => f.Name !== name)
      };
    });
  };

  const onGeneralActionChange = ({ name, value, itemTypeId }: ValueToSet) => {
    let assign: string | boolean | number | string[];
    if (itemTypeId === DataTypes.Bool) {
      assign = value === '1' ? true : false;
    } else {
      assign = integerTypes.includes(itemTypeId) ? Number(value) : value;
    }

    setValues((prevValues: any) => {
      return {...prevValues,
        [name]: assign};
    });
  };

  const exportFiles = async () => {
    //limit export / default 500.000
    const rowsCountMaxLimit = config?.exportButton.rowsCountMaxLimit ?? 500000;
    const totalCountInList = response?.metadata?.totalItemsCount ?? 0;

    if(rowsCountMaxLimit > 0 && totalCountInList > rowsCountMaxLimit) {
      setShowModalExportLimit(true);
    } else {
    dispatch(toggleLoader(true));
    
    //convert all values to string
    await Export({...searchModel, Filters: searchModel.Filters.map((filter: Filter) => {
      let stringValue;
      if (typeof filter.Value !== 'string') {
        stringValue = filter.Value + '';
      } else {
        stringValue = filter.Value;
      }
      return { ...filter, Value: stringValue };
    })})
    .then((res: any) => {
      const contentType = res?.headers['content-type'];
      setTimeout(() => {
        const blob: Blob = new Blob([res.data]);
        let fileName: string = '';
        if (config) {
          fileName = config.exportButton.fileName.replace('{timestamp}', moment().format('YYMMDDHms'));
          if(!contentType.includes('spreadsheetml.sheet')) {
            fileName = fileName.replace('.xlsx', '.zip');
          }
        } else {
          fileName = `export-${moment().format('YYMMDDHms')}.xlsx`;
        }
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
  
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
  
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
        dispatch(toggleLoader(false));
      }, 500);
    })
    .catch(error => {
      dispatch(toggleLoader(false));
      try {
        const decoder = new TextDecoder('utf-8');
        const decodedString = decoder.decode(error);
        const obj = JSON.parse(decodedString);
        const status = obj.status;
        if (status == 401) {
          notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      } catch(error) {
        notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      }
    });
   }
  };

  useEffect(() => {
    setInitialFiltersValues();
  }, [config]);

  useEffect(() => {
    if(searchRequested) {
      fetchResults(searchModel, true);
      setSearchRequested(false);
    }
  }, [searchRequested]);

  const setInitialFiltersValues = () => {
    if (config) {
      let filters: Filter[] = [];
      let initialFiltersCollapsedState: FiltersCollapsedState = {} as FiltersCollapsedState;

      config.filters
        //@ts-ignore
        .filter((item: Item) => item.value || item.dataTypeId === DataTypes.DateRange || item.initializeValueFromQueryString)
        .forEach((item: any, index: number) => {
          let queryParamFilterValue;
          let resetFilterValue;
          
          //@ts-ignore
          if (item.initializeValueFromQueryString) {
            queryParamFilterValue = getQueryParam(item.name.charAt(0).toLowerCase() + item.name.slice(1));
            //if the query param is present but has empty value is considered a reset for the filter
            resetFilterValue = queryParamFilterValue == '';
          }
          //@ts-ignore
          if (item.initializeValueFromQueryString && queryParamFilterValue ) {
            if(queryParamFilterValue == '[]') return;
              filters.push({
                Name: item.name,
                Value: queryParamFilterValue,
                DataTypeId: item.dataTypeId
              } as Filter);
              initialFiltersCollapsedState[item.name] = true;
          } else if (item.value && !resetFilterValue) {
            filters.push({
              Name: item.name,
              Value: item.value,
              DataTypeId: item.dataTypeId
            } as Filter);
            initialFiltersCollapsedState[item.name] = true;
          }

          if (item.dataTypeId === DataTypes.DateRange && item.items) {
            let dateItemCollapsed = false;
            item.items.forEach((dateItem: any, index: number) => {

              let queryParamFilterValue;
              let resetFilterValue;
              //@ts-ignore
              if (dateItem.initializeValueFromQueryString) {
                queryParamFilterValue = getQueryParam(dateItem.name.charAt(0).toLowerCase() + dateItem.name.slice(1));
                resetFilterValue = queryParamFilterValue == '';
              }
              //@ts-ignore
              if (dateItem.initializeValueFromQueryString && queryParamFilterValue) {
                  filters.push({
                    Name: dateItem.name,
                    Value: queryParamFilterValue,
                    DataTypeId: dateItem.dataTypeId
                  } as Filter);
                  dateItemCollapsed = true;
              } else if (dateItem.value && !resetFilterValue) {
                filters.push({
                  Name: dateItem.name,
                  Value: dateItem.value,
                  DataTypeId: dateItem.dataTypeId
                } as Filter);
                dateItemCollapsed = true;
              }
            });
            if(dateItemCollapsed) initialFiltersCollapsedState[item.name] = true;
          }
        });

      setSearchModel({
        ...searchModel,
        SortColumn: config.sortColumn,
        SortAscending: config.sortAscending,
        PageSize: config.pagination.defaultPageSizeValue,
        Filters: filters
      });
      setInitialFiltersCollapsedState(initialFiltersCollapsedState);
    }
  };
  const onValueGeneralChange = ({ name, value, itemTypeId }: ValueToSet) => {
    setSearchModel({
      ...searchModel,
      GeneralSearchText: ToString(value)
    });
  };

  useEffect(() => {
    if (searchModel.SortColumn && searchModel.PageSize && searchModel.PageIndex) {
      fetchResults(searchModel, true);
    }
  }, [searchModel.SortAscending, searchModel.SortColumn, searchModel.PageSize, searchModel.PageIndex]);

  const renderPage = (pagination: MetaData, i: number, showDots: boolean = false) => {
    return (
      <li
        key={i}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setSearchModel({ ...searchModel, PageIndex: i });
        }}
        className={i === pagination.pageIndex ? 'page-link-current' : 'page-link-not-current'}
        style={{ paddingLeft: '7px', paddingRight: '7px' }}
      >
        <a className={i === pagination.pageIndex ? 'current' : 'not-current'}>{showDots ? '...' : i}</a>
      </li>
    );
  };

  const renderPages = (pagination: MetaData) => {
    let pages = [];
    let totalPages: number = Math.ceil(pagination.totalItemsCount / pagination.pageSize);

    if (totalPages < 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(renderPage(pagination, i));
      }
    } else {
      let dottedPages: number[] = [];

      let otherPages: number[] = [];

      if (pagination.pageIndex > 0) {
        otherPages = [pagination.pageIndex - 1, pagination.pageIndex, pagination.pageIndex + 1].filter((page: number) => page > 1 && page < totalPages - 1);
      }

      if (pagination.pageIndex >= totalPages - 2) {
        otherPages = [pagination.pageIndex - 2, pagination.pageIndex - 1, pagination.pageIndex].filter((page: number) => page > 1 && page < totalPages - 1);
      }

      if (otherPages.length > 0) {
        if (otherPages[0] > 2) {
          dottedPages.push(otherPages[0] - 1);
        }

        if (otherPages[otherPages.length - 1] < totalPages - 2) {
          dottedPages.push(otherPages[otherPages.length - 1] + 1);
        }
      }

      let customPages: number[] = [1, ...otherPages, ...dottedPages, totalPages - 1, totalPages].sort((a, b) => a - b);

      for (let i = 0; i <= customPages.length - 1; i++) {
        pages.push(renderPage(pagination, customPages[i], dottedPages.includes(customPages[i])));
      }
    }


    return pages;
  };

  //get options and save them in optionsForField state
  const getOptionsFromApi = async (fieldName: string, keyFieldName: string, url: string | any, childUrl: string | any,childName: string, parentName: string,  childUrlQuerryParameterName: string, urlParams?: QueryParams[]) => {
    let options = await CustomGet(url, urlParams);
    setOptionsForField( preProps => { 
      let existentFieldIndex = preProps.findIndex((p: OptionsForField) => p.field === fieldName);

      if (existentFieldIndex >= 0) {
        return [
          ...preProps.slice(0, existentFieldIndex),
          Object.assign({}, preProps[existentFieldIndex], {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            options: [...options]
          }),
          ...preProps.slice(existentFieldIndex + 1)
        ];
      } else {
        return [
          ...preProps,
          {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            options: [...options]
          }
        ];
      }
      
    });
  };

  //set field child options
  useEffect(()=> {
    optionsForField.forEach((option: OptionsForField) => {
      const fieldValue = values[option.field];
      
      if(option.child && option.child !== '' && fieldValue) {
        let paramValue = option.options.find((op: Option) => op[option.keyFieldName] == values[option.field]);
        paramValue = paramValue ? paramValue[option.childUrlQuerryParameterName] : '';

        let params = [{
          name: option.childUrlQuerryParameterName as string,
          value: paramValue
        }] as QueryParams[];
        getOptionsFromApi(option.child, '', option.childUrl, '','', option.field, '', params);
      }
    });
  },[values]);
  
  //set field options
  useEffect(() => {
    config?.generalActions.forEach((action: GeneralAction) => {
      if (action.actionTypeName === 'ModalForm' && action.formGroups) {
        action.formGroups.forEach((formGroup: FormGroup) => {
          if (formGroup.fields) {
            formGroup.fields.forEach((field: FormField) => {
              if (field.itemsConfiguration && !field.itemsConfiguration.staticData) {
                const url = field.itemsConfiguration.itemsDefinition?.url;
                const keyFieldName = field.itemsConfiguration?.keyFieldName ? field.itemsConfiguration?.keyFieldName.charAt(0).toLocaleLowerCase() + field.itemsConfiguration?.keyFieldName.slice(1)  : 'id';
                //eslint-disable-next-line
                const valueFieldName = field.itemsConfiguration?.valueFieldName ? field.itemsConfiguration?.valueFieldName : 'value';

                let childName;
                let childUrl;
                let childUrlQuerryParamName = '';
                let extraPropertiesToSendInActionModel = field.itemsConfiguration.extraPropertiesToSendInActionModel || [];
                if(field.itemsConfiguration.cascadeChild) {
                  const child = field.itemsConfiguration.cascadeChild;
                  if(child.itemsConfiguration && !child.itemsConfiguration.staticData) {
                    childName = child.name;
                    childUrl = child.itemsConfiguration.itemsDefinition?.url;
                    childUrlQuerryParamName = ((child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').charAt(0).toLowerCase())
                     + ((child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').slice(1));
                  }
                  if(child.itemsConfiguration.extraPropertiesToSendInActionModel && child.itemsConfiguration.extraPropertiesToSendInActionModel[0]) {
                    extraPropertiesToSendInActionModel = [...extraPropertiesToSendInActionModel, ...child.itemsConfiguration.extraPropertiesToSendInActionModel ];
                  }
                }
                if(extraPropertiesToSendInActionModel) {
                  let extraProperties = {} as InputValues;
                  extraPropertiesToSendInActionModel.forEach((property: string) => {
                    extraProperties[property] = '';
                  });
                  setValues((prevValues) => { return {
                    ...prevValues,
                    ...extraProperties
                  };
                  });
                }

                getOptionsFromApi(field.name, keyFieldName, url, childUrl, childName as string, '', childUrlQuerryParamName);
              }
            });
          }
        });
      }
    });
  }, [config?.generalActions]); 

  //@ts-ignore
  const validateValue = (field: any) => {
    let res = true;
    let childRes = true;
    if (field.isRequired) {
      if (!values[field.name]) {
        res = false;
        if(!formValidationErrors.find((v: any) => v.name === field.name)) {
          setFormValidationErrors((prevState: any) => [
            ...prevState,
            {
              name: field.name, 
              displayName: field.displayName
            }
          ]);
        }
      } else {
        if (formValidationErrors.find((v: any) => v.name === field.name)) {
          setFormValidationErrors((prevState: any) => [...prevState.filter((v: any) => v.name !== field.name)]);
        }
      }
    }

    const child = field.itemsConfiguration?.cascadeChild ?? null; 
    if(child) {
      childRes = validateValue(child);
    }
    return res && childRes;
  };


  const validateValues = (values: any, action: any) => {
    let res = true;
    action.formGroups?.forEach((group: any, index: number) => {
      group.fields?.forEach((field: any, index: number) => {
        const isFieldValid = validateValue(field);
        if(!isFieldValid) {
          res = false;
        };
      });
    });
    return res;
  };

  const removeChildValueAtParentChange = (child: any) => {
    if(child) {
      if(child.dataTypeId == DataTypes.DropdownInt) {
        onGeneralActionChange({
          name: child.name,
          value: 0,
          itemTypeId: child.dataTypeId
        });
      } else if(child.dataTypeId == DataTypes.DropdownString) {
        onGeneralActionChange({
          name: child.name,
          value: '',
          itemTypeId: child.dataTypeId
        });
      }

      const childChild = child?.itemsConfiguration?.cascadeChild ?? null;
      if(childChild) {
      removeChildValueAtParentChange(childChild);
      }
    }
  };

  const getModalSize = (size: number) => {
    if (size == ModalSize.Small) {
      return 'sm';
    } else if (size == ModalSize.Medium) {
      return 'lg';
    } else if (size == ModalSize.Large) {
      return 'xl';
    }
  };

  const renderField = (field: FormField, index: number, groupNumberOfColumnsPerRow: number, parent?: any ) => {
    let itemType = field.dataTypeName;
    let itemTypeId = field.dataTypeId as number;

    if(groupNumberOfColumnsPerRow && field.colspan > groupNumberOfColumnsPerRow) {
      field.colspan = groupNumberOfColumnsPerRow;
    }
    let oneColSize = 12 / groupNumberOfColumnsPerRow;
    let colspanValue = field.colspan * oneColSize;

    if(field.isRequired) {
      if(!field.displayName.endsWith('*')) {
        field.displayName = `${field.displayName} *`;
      }
    }
    const child = field.itemsConfiguration?.cascadeChild;
    if(child) {
      //@ts-ignore
      if(child.isRequired) {
        if(!child.displayName.endsWith('*')) {
          child.displayName = `${child.displayName} *`;
        }
      }
    }
    

    switch (itemType) {
      case 'StringList':
        return (
          <Col key={index} md={colspanValue}>
            <StringList
              options={[] as string[]}
              label={field.displayName}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onGeneralActionChange}
              name={field.name}
            />
          </Col>
        );
      case 'String':
        return (
          <Col key={index} md={colspanValue}>
            <String
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onGeneralActionChange}
            />
          </Col>
        );
      case 'MultilineString':
        return (
          <Col key={index} md={colspanValue}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <p className="mb-1">{field.displayName}</p>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onGeneralActionChange({
                    name: field.name,
                    value: event.target.value,
                    itemTypeId
                  } as ValueToSet);
                }}
                value={(values[field.name] as string) ?? ''}
              />
            </Form.Group>
          </Col>
        );
        case 'DateTime':
          return (
            <Col
              key={index}
              md={colspanValue}
            >
              <DateTime
                label={field.displayName}
                name={field.name}
                itemTypeId={itemTypeId}
                onChange={onGeneralActionChange}
                value={ToString(values[field.name] ? (values[field.name] as string) : '')}
              />
            </Col>
          );
      case 'Dropdown':
        return (
          <Col key={index} md={colspanValue}>
            <DropdownField
              options={[] as DropdownOptionType[]}
              label={field.displayName}
              itemTypeId={itemTypeId}
              name={field.name}
              onChange={onGeneralActionChange}
              isUsedInBackOffice={true}
              displaySearch={true}
            />
          </Col>
        );
      case 'DateRange':
        return (
          <Col key={index} md={colspanValue}>
            <DateRange
              onChange={onGeneralActionChange}
              items={field?.items?.map(
                (dateItem: any): DataItem => ({
                  label: dateItem.displayName,
                  itemTypeId: itemTypeId,
                  name: dateItem.name
                })
              )}
            />
          </Col>
        );
      case 'Bool':
        return (
          <Col key={index} md={colspanValue}>
            <RadioGroup
              className="mw-100 mb-24"
              label={field.displayName}
              value={(values[field.name] as boolean) === true ? '1' : '2'}
              itemClassName="bg-eon-red d-inline mr-2 ms-2"
              options={[
                {
                  value: '1',
                  name: 'Da'
                },
                {
                  value: '2',
                  name: 'Nu'
                }
              ]}
              onClick={(value?: string | string[] | number | boolean) => {
                onGeneralActionChange({ name: field.name, value: ToString(value), itemTypeId } as ValueToSet);
              }}
            />
          </Col>
        );
      case 'Date':
          return (
            <Col
              key={index}
              md={
                colspanValue
              }
            >
              <DateTime
                label={field.displayName}
                isInModal={true}
                name={field.name}
                itemTypeId={itemTypeId}
                onChange={onGeneralActionChange}
                value={ToString(values[field.name] ? (values[field.name] as string) : '')}
              />
            </Col>
          );
      case 'ReadonlyString':
        return (
          <Col key={index} md={colspanValue}>
            <String
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onGeneralActionChange}
              disabled={true}
            />
          </Col>
        );
      case 'DropdownInt':
        return(
          <DropdownInt
          key={index}
          item={field}
          itemTypeId={itemTypeId}
          value={values[field.name] ? values[field.name] : ''}
          onChange={(value) => {
            onGeneralActionChange(value);
            const child = field.itemsConfiguration?.cascadeChild ?? null;
            removeChildValueAtParentChange(child);
          }}
          isUsedInFilters={false}
          values={values}
          renderChild={renderField}
          groupNumberOfColumnsPerRow={groupNumberOfColumnsPerRow}
          optionsForField={optionsForField}
          parent={parent}
          colspanValue={colspanValue}
          />
          );
      case 'DropdownString':
        return (
          <DropdownString
          key={index}
          item={field}
          itemTypeId={itemTypeId}
          value={values[field.name] ? values[field.name] : ''}
          onChange={(value) => {
            onGeneralActionChange(value);
            const child = field.itemsConfiguration?.cascadeChild ?? null;
            removeChildValueAtParentChange(child);
          }}
          isUsedInFilters={false}
          values={values}
          renderChild={renderField}
          groupNumberOfColumnsPerRow={groupNumberOfColumnsPerRow}
          optionsForField={optionsForField}
          parent={parent}
          colspanValue={colspanValue}
          />
          );
      case 'StringListEdit':
        return (
          <StringListEdit
           key={index} 
           values={ values[field.name] ? values[field.name] as string[] : [''] as string[]}
           label={field.displayName}
           itemTypeId={itemTypeId}
           onChange={onGeneralActionChange}
           name={field.name}
           colspan={field.colspan ? field.colspan : groupNumberOfColumnsPerRow ? groupNumberOfColumnsPerRow : 2}
           />
        );
      case 'Int':
        return (
          <Col key={index} md={colspanValue}>
            <Int
              label={field.displayName}
              name={field.name}
              value={values[field.name] ? (values[field.name] as string) : ''}
              itemTypeId={itemTypeId}
              onChange={onGeneralActionChange}
            />
          </Col>
        );
      case 'Html':
      return (
        <Col
          key={index}
          md={
            colspanValue
          }
        >
          <Html 
          label={field.displayName}
          name={field.name}
          value={(values[field.name] as string) ??  ''}
          itemTypeId={itemTypeId}
          onChange={onGeneralActionChange}/>
        </Col>
      );
      case 'Empty':
        return <Col md={12}><div className='m-2 p-3'></div></Col>;
      case 'Base64FileList':
      return (
        <Col
          key={index}
          md={
            colspanValue
          }
        >
          <Base64FileList 
          label={field.displayName}
          name={field.name}
          itemTypeId={itemTypeId}
          onChange={onGeneralActionChange}
          //@ts-ignore
          allowedFileExtensions={field.allowedFileExtensions}
          //@ts-ignore
          allowedMaxTotalFileSizeMB={field.allowedMaxTotalFileSizeMB}/>
        </Col>
      );
      case 'Base64File':
      return (
        <Col
          key={index}
          md={
            colspanValue
          }
        >
          <Base64File
          label={field.displayName}
          name={field.name}
          itemTypeId={itemTypeId}
          onChange={onGeneralActionChange}
          //@ts-ignore
          allowedFileExtensions={field.allowedFileExtensions}
          //@ts-ignore
          allowedMaxTotalFileSizeMB={field.allowedMaxTotalFileSizeMB}
          />
        </Col>
      );
      case 'Paragraph':
        return (
          <Col key={index} md={colspanValue}>
            <Paragraph
              //@ts-ignore
              color={field.color}
              //@ts-ignore
              fontStyle={field.fontStyle}
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              hideIfEmtyValue={true}
            />
          </Col>
        );
      default:
        break;
    }

    return null;
  };

  return (
    <div className='backoffice-section p-2'>
      {config && <>
        <Row>
        <Col md={2}>
          <div className='d-flex justify-content-center'>
              {(config.filters.length > 0 || config.showGeneralSearchInput === true) && <>
                <div className=''>
                  <Button type='submit' onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setSearchModel({...searchModel, PageIndex: 1});
                    fetchResults({...searchModel, PageIndex: 1}, true);
                  }}
                    className='btn btn-primary mt-2 mb-2 btn-md btn-submit w-100'
                    disabled={requiredFilterNotFound}>Cauta</Button>
                </div>
                <div className=''>
                  <Button variant='secondary' onClick={(event: React.MouseEvent<HTMLElement>) => {
                    resetFiltersToDefault();
                  }}
                    className='mt-2 mb-2 ms-2 btn-md w-100'>Resetare</Button>
                </div>
              </>}
            </div>
          </Col>
          <Col md={10} className="px-4">
            <div className='d-flex flex-wrap'>
              {config.exportButton && config.exportButton.active === true && (
                <Button type='button'
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    exportFiles();
                  }}
                  className='btn btn-primary me-1 mt-2 mb-2 btn-md btn-submit'
                  disabled={requiredFilterNotFound}>{config.exportButton.buttonDisplayName}</Button>
              )}

              {config && config.generalActions &&
                config.generalActions.map((action: GeneralAction, key: number) => {
                  return <Button key={key} className='btn btn-primary me-1 mt-2 mb-2 btn-md '
                    onClick={(e) => {
                      setAction(action);
                      setIsEditing(true);
                    }} variant='primary'>
                    {action.displayName}
                  </Button>;
                })
              }
            </div>
          </Col>
          <Col md={2} sm={12}>
            {config.showGeneralSearchInput === true && <Row>
              <Col>
                <String
                  label='Cautare Generala'
                  value={searchModel.GeneralSearchText ?? ''}
                  name={'GeneralSearchText'}
                  onChange={onValueGeneralChange}
                />
              </Col>
            </Row>}
            <Row>
              <Row>
                {requiredFilterNotFound && (
                  <p className="m-0 ms-3 red fw-bold text-center">Vă rugăm să introduceți valori pentru toate filtrele obligatorii.</p>
                )}
              </Row>
              <FiltersForm onValueChange={onFilterValueChange} filters={searchModel.Filters} removeFilter={removeFilter} initialFiltersCollapsedState={initialFiltersCollapsedState} />
            </Row>
            {
              config.filters.length > 5 ? 
              <div className='d-flex justify-content-center'>
              {(config.filters.length > 0 || config.showGeneralSearchInput === true) && <>
                <div className=''>
                  <Button type='submit' onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setSearchModel({...searchModel, PageIndex: 1});
                    fetchResults({...searchModel, PageIndex: 1}, true);
                  }}
                    className='btn btn-primary mt-2 mb-2 btn-md btn-submit w-100'
                    disabled={requiredFilterNotFound}>Cauta</Button>
                </div>
                <div className=''>
                  <Button variant='secondary' onClick={(event: React.MouseEvent<HTMLElement>) => {
                    resetFiltersToDefault();
                  }}
                    className='mt-2 mb-2 ms-2 btn-md w-100'>Resetare</Button>
                </div>
              </>}
            </div> : ''
            }
            
          </Col>
          <Col className='px-4' sm={12} md={isFullWidth ? 10 : 7}>
            <Row>
              <Col className='px-2' sm={12}>
                <ResponseListing response={response}
                  searchModel={searchModel}
                  onSort={(column: string) => {
                    setSearchModel({
                      ...searchModel,
                      SortAscending: searchModel.SortColumn === column ? !searchModel.SortAscending : true,
                      SortColumn: column
                    });
                  }} />
                {response && response.items.length > 0 && config.pagination && config.pagination.active === true && (
                  <Row className='mt-4'>
                    <Col md={2} sm={12}>
                      <Dropdown
                        options={config.pagination.pageSizeOptionValues.map((item: number) => ({
                          id: ToString(item),
                          name: ToString(item)
                        }))}
                        label={'Elemente pagina'}
                        value={ToString(searchModel.PageSize) ?? ''}
                        onChange={(value) => {
                          setSearchModel({
                            ...searchModel, PageSize: parseInt(value), PageIndex: 1
                          });
                        }}
                      />
                    </Col>
                    {response.metadata && response.metadata.pageSize <= response.metadata.totalItemsCount &&
                      <Col md={9} sm={12}>
                        <nav>
                          <ul className='pagination'>
                            <span
                              onClick={(event: React.MouseEvent<HTMLElement>) => {
                                if (searchModel.PageIndex > 1)
                                  setSearchModel({ ...searchModel, PageIndex: 1 });
                              }}
                              className={response.metadata.pageSize === 1 ? 'nav-icon-disabled' : 'nav-icon'}>«</span>

                            <span
                              onClick={(event: React.MouseEvent<HTMLElement>) => {
                                if (searchModel.PageIndex > 1)
                                  setSearchModel({ ...searchModel, PageIndex: searchModel.PageIndex - 1 });
                              }}
                              className={response.metadata.pageSize === 1 ? 'nav-icon-disabled' : 'nav-icon'}>&lt;</span>
                            {renderPages(response.metadata)}
                            <span className='nav-icon' onClick={(event: React.MouseEvent<HTMLElement>) => {
                              if (response.metadata && response.metadata.totalItemsCount && response.metadata.pageSize) {
                                let totalPages: number = Math.ceil(response.metadata.totalItemsCount / response.metadata.pageSize);
                                if (response.metadata.pageIndex < totalPages)
                                  setSearchModel({ ...searchModel, PageIndex: response.metadata.pageIndex + 1 });
                              }
                            }}>&gt;</span>

                            <span className='nav-icon' onClick={(event: React.MouseEvent<HTMLElement>) => {
                              if (response.metadata && response.metadata.totalItemsCount && response.metadata.pageSize) {
                                let totalPages: number = Math.ceil(response.metadata.totalItemsCount / response.metadata.pageSize);
                                if (response.metadata.pageIndex < totalPages)
                                  setSearchModel({ ...searchModel, PageIndex: totalPages });
                              }
                            }}>»</span>
                          </ul>
                        </nav>
                      </Col>
                    }
                  </Row>
                )}
                {isFullWidth && <Row className='m-5 p-5'></Row>}
                { pageHeight > 720 && <Row className='pagination-spacer'/>}
              </Col>
            </Row>
          </Col>
          {!isFullWidth && <Col md={3}>
            {detailedElement && <DetailedItem detailedElement={detailedElement} />}
          </Col>}
          {action && action.actionTypeName === 'ModalForm' && <ModalComponent
            title={action.displayMessage}
            show={isEditing}
            className='backoffice-modal'
            backdropStatic={true}
            setShow={(state: boolean) => {
              setIsEditing(!isEditing);
            }}
            //@ts-ignore
            fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'sm-down'}
            //@ts-ignore
            size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
            onClose={() => setFormValidationErrors([])}
            footerAction={
              <Button className='btn btn-primary'
                onClick={async (e) => {
                  if (onRowAction) {
                    const valid = validateValues(values, action);
                    if(valid) {
                      dispatch(toggleLoader(true));
                      let success: boolean = await onRowAction(values, action.actionId);
                      if (success) {
                        setIsEditing(false);
                        setAction(null);
                      }
                      dispatch(toggleLoader(false));
                    }
                  }
                }} variant='primary'>
                Salveaza
              </Button>}
          >
            <Form className="form">
                  <Row>
                    <Col xs={12} md={12}>
                      {action.formGroups?.map((group, index: number) => {
                        return (
                          <Row className="mb-2">
                            {group.displayName && (
                              <Col xs={12}>
                                <h3 className="brand-subtitle">{group.displayName}</h3>
                              </Col>
                            )}
                            {group.fields?.map((field, index: number) => {
                              return (
                                <Fragment>
                                  {field.hint ? (
                                    <Fragment>
                                      <p className="field_hint">* {field.hint}</p>
                                      {renderField(field as FormField, index, group.numberOfColumnsPerRow)}
                                    </Fragment>
                                  ) : (
                                    renderField(field as FormField, index, group.numberOfColumnsPerRow)
                                  )}
                                </Fragment>
                              );
                            })}
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                  {formValidationErrors[0] &&
                    <Row>
                      <Col xs={12} md={12}>
                        <div style={{color: 'red', fontWeight: 'bold'}}>Câmp obligatoriu {formValidationErrors[0].displayName}</div>
                      </Col>
                    </Row>
                  }
                </Form>
          </ModalComponent>}

          {action && action.actionTypeName === 'Confirm' && (
              <ModalComponent
                title={action.displayName}
                show={isEditing}
                setShow={(state: boolean) => {
                  setIsEditing(!isEditing);
                }}
                //@ts-ignore
                size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
                footerAction={
                  <Button
                    className="btn btn-primary"
                    onClick={async (e) => {
                      setConfirmButtonDisabled(true);
                      dispatch(toggleLoader(true));
                      let success: boolean;
                      if (onRowAction) {
                        success = await onRowAction(values, action.actionId);
                        if (success) {
                          setIsEditing(false);
                          setConfirmButtonDisabled(false);
                        }
                        dispatch(toggleLoader(false));
                      }
                    }}
                    variant="primary"
                    disabled={confirmButtonDisabled}
                  >
                    {action.displayName}
                  </Button>
                }
              >
                <p>{action.displayMessage}</p>
              </ModalComponent>
            )}

          {action && action.actionTypeName == CustomActionTypes.RSS && (
          <ModalComponent
            title={action.displayName}
            show={isEditing}
            className="backoffice-modal"
            setShow={(state: boolean) => {
              setIsEditing(!isEditing);
              setRssRegisteredWithSucces(false);
            }}
            closeButttonLabel={`${rssRegisteredWithSucces ? 'OK' : ''}`}
            //@ts-ignore
            fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
            //@ts-ignore
            size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
            footerAction={!rssRegisteredWithSucces && showRSSSaveButton ?
              <Button className='btn btn-primary'
                disabled={RSSSaveButtonDisabled}
                onClick={() => {
                  //@ts-ignore
                  RSSCallCenterRef.current?.submitFromBo && RSSCallCenterRef.current?.submitFromBo();
                  }} variant='primary'>
                Salveaza
              </Button> : undefined
            }
            backdropStatic
          >
            <div className="mt-2">
              <RSSCallCenter userRoles={config?.loggedInUserRoles} ref={RSSCallCenterRef} setShowRSSSaveButton={setShowRSSSaveButton} setDisableRSSSaveButton={setRSSSaveButtonDisabled} onRegisterRssSuccesBo={() => setRssRegisteredWithSucces(true)}/>
            </div>
          </ModalComponent>
        )}

          <ModalConfirm showModal={showModalExportLimit} modalTitle="Eroare!" onClose={() => setShowModalExportLimit(false)}>
            <>
              <p>Setul de date selectat depășește limita admisă pentru export. Vă rugăm să aplicați filtrări suplimentare.</p>
              <p>Limita admisă pentru exportul curent este <strong>{config?.exportButton.rowsCountMaxLimit ?? 500000}</strong></p>
            </>
          </ModalConfirm>
        </Row>
      </>
      }
    </div >
  );
};

export default function PageContexted(props: BackOfficeComponentProps) {
  return (
    <BackOfficeProvider baseUrl={props.kentico.baseUrl} securityContext={props.kentico.securityContext}>
      <PageWrapper {...props} />
    </BackOfficeProvider>
  );
}
