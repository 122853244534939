import React, { Fragment } from 'react';
import moment from 'moment';

const TableItem = (props: { item: any }) => {
  const { item } = props;

  return (
    <Fragment>
      <tr>
        <td>
          <span>{item.codClient}</span>
        </td>
        <td>
          <span>{item.nrCerereRacordare}</span>
        </td>
        <td>
          <span>{item.dataSap !== '' ? moment(item.dataSap).format('DD.MM.YYYY') : '-'}</span>
        </td>
        <td>
          <span>{item.numar}</span>
        </td>
        <td>
          <span>{item.dataEmitere !== '' ? moment(item.dataEmitere).format('DD.MM.YYYY') : '-'}</span>
        </td>
        <td>
          <span>{item.tipDocument !== '' ? item.tipDocument : '-'}</span>
        </td>
      </tr>
    </Fragment>
  );
};

export default TableItem;