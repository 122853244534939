import { toggleLoader } from '@actions/LoaderActions';
import { GetTemplates } from '@api/programareCitiri';
import { getAvailableSlots, meterSelfreadingInfo } from '@api/programariCitiri/programariCitiri';
import { loadCaptchaEngine } from '@components/auth/createAccount/CaptchaLib';
import NotificationModal, { NotificationInputs } from '@components/dev/System/ProgramariCitiri/NotificationModal';
import { faCalendarDays, faFilePen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { readingTemplate } from '@lib/MeterReading';
import * as notification from '@lib/Notification';
import { ProgramariCitiriTemplate } from '@type/programari-citiri';
import { IState } from '@type/store';
import { useEffect, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ModalCitireContor from './ModalCitireContor';
import Results from './Results';
import Verify from './Verify';

type ProgramariCitiriContoareProps = {
  isUsedInBO?: boolean;
  contactNumber?: string;
  codLocConsum?: string;
  linkText?: string;
  reloadContent?: () => void;
  kid?: string;
};

const ProgramariCitiriContoare = (props: ProgramariCitiriContoareProps) => {
  const [contractNumber, setContractNumber] = useState<string>('');
  const [clc, setClc] = useState<string>('');
  const [pod, setPod] = useState<string>('');
  const [data, setData] = useState<any>(null);
  const [notificationId, setNotificationId] = useState<number | null>(null);
  const [error, setError] = useState<string>('');
  const [modalData, setModalData] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [templates, setTemplates] = useState<ProgramariCitiriTemplate>({} as ProgramariCitiriTemplate);
  //eslint-disable-next-line
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [templateUp, setTemplateUp] = useState<string | null>(null);
  const [templateMid, setTemplateMid] = useState<string | null>(null);
  const [templateDown, setTemplateDown] = useState<string | null>(null);
  const xApiKey: string | undefined = useSelector<IState>((state) => state.options.xApiKey) as string;
  const [infoError, setInfoError] = useState('');
  const [showInfoErrorModal, setShowInfoErrorModal] = useState<boolean>(false);

  //eslint-disable-next-line
  const [isExpiredForReading, setIsExpiredForReading] = useState<boolean>(false);
  const [showModalBO, setshowModalBO] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [appointmentResponseModal, setAppointmentResponseModal] = useState(false);
  const [apointmentResponseModalType, setAppointmentResponseModalType] = useState<string | null>(null);

  const [messageButtonDescription, setMessageButtonDescription] = useState<string>('');
  const [buttonDescription, setButtonDescription] = useState<string>('');

  const dispatch = useDispatch();

  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

  const wait = async () => {
    await delay(3000);
  };

  useEffect(() => {
    if (appointmentResponseModal === true) {
      wait().then(() => setAppointmentResponseModal(false));
    }
  }, [appointmentResponseModal]);

  useEffect(() => {
    if (!props.isUsedInBO && xApiKey) {
      GetTemplates(xApiKey)
        .then((res) => {
          setTemplates(res);
        })
        .catch((err) => { });
    }
  }, [props, xApiKey]);

  useEffect(() => {
    if (props.isUsedInBO && showModalBO) {
      verifyForBo();
    }
  }, [props.isUsedInBO, showModalBO]);

  useEffect(() => {
    if (props.isUsedInBO && notificationId && data) {
      getInfo(2);
    }
  }, [props.isUsedInBO, notificationId, data]);

  const handleContractNumber = (data: any) => {
    setContractNumber(data);
  };

  const handleClc = (data: any) => {
    setClc(data);
  };

  const handlePod = (data: any) => {
    setPod(data);
  };

  const checkPOD = (pod: string) => {
    if (pod.length !== 10 && pod.length !== 0) {
      if ((pod[0] !== 'E' && pod[1] !== 'M' && pod[2] !== 'O') || (pod[0] !== 'D' && pod[1] !== 'E' && pod[2] !== 'G')) {
        setError('Câmpul POD trebuie să fie de forma EMOXXXXXXX sau DEGXXXXXXX.');

        return false;
      }
    }

    return true;
  };

  const verify = async () => {
    dispatch(toggleLoader(true));
    setError('');
    setInfoError('');
    setData(null);
    setTemplateUp(null);
    setTemplateMid(null);
    setTemplateDown(null);
    setMessageButtonDescription('');
    setButtonDescription('');

    setIsExpired(false);
    setIsExpiredForReading(false);

    if (checkPOD(pod)) {
      await getAvailableSlots({ contractNumber: contractNumber.trim(), clc, pod: pod.trim() })
        .then((response) => {
          if (response && response.notificationId) {

            let isExpired =
              response.status === 'NO_DAYS_AVAILABLE_TO_ADD' ||
              response.status === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS' ||
              response.status === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS' ||
              response.status === 'NOT_NOTICE_NOTIFICATION' ||
              response.status === 'OUTSIDE_INTERVAL' ||
              response.selfReadingStatus === 'OUTSIDE_INTERVAL';

            let isExpiredForReading =
              response.selfReadingStatus === 'CREATED_WITH_INDEX' ||
              response.selfReadingStatus === 'NOT_NOTICE_NOTIFICATION' ||
              response.selfReadingStatus === 'OUTSIDE_INTERVAL' ||
              response.status === 'CREATED_2';

            if (response.status === 'NO_DAYS_AVAILABLE_TO_ADD' || response.status === 'NO_DAYS_AVAILABLE_TO_EDIT') {
              if (response.selfReadingStatus === 'OK_TO_ADD' || response.selfReadingStatus === 'CREATED_1_OK_TO_ADD' || response.selfReadingStatus === 'CREATED_2') {
                setButtonDescription('Toate intervalele pentru programare sunt ocupate.');
              }
            }

            if (response.selfReadingStatus === 'CREATED_2') {
              setMessageButtonDescription('Pentru acest contact au fost transmise 2 mesaje.');
            }

            setIsExpired(isExpired);
            setIsExpiredForReading(isExpiredForReading);

            if (response.selfReadingStatus === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS' || response.status === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS' || response.selfReadingStatus === 'CREATED_WITH_INDEX') {
              setTemplateUp(readingTemplate(templates, 'up', response, contractNumber));
            } else {
              setTemplateUp(readingTemplate(templates, 'up', response, contractNumber));
              setTemplateMid(readingTemplate(templates, 'mid', response, contractNumber));
              setTemplateDown(readingTemplate(templates, 'down', response, contractNumber));
            }

            setData(response);
            setNotificationId(response.notificationId);
          }
        })
        .catch((error) => {
          if (error && error.status) {
            if (error.status === 406 || error.status === 411) {
              setError('Date incorecte!');
              notification.error('Date incorecte!');
            } else if (error.status === 400 && error.title === 'Bad Request') {
              notification.error('Contactul introdus se referă la înlocuirea contorului. Te rugăm să folosești secțiunea Programări Înlocuiri.');
              setError('Contactul introdus se referă la înlocuirea contorului. Te rugăm să folosești secțiunea Programări Înlocuiri.');
            } else if (error.status === 404 || (error.status === 400 && error.title !== 'Bad Request')) {
              notification.error('Nu s-a găsit notificare.');
              setError('Nu s-a găsit notificare.');
            } else {
              notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
              setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            }
          }
        });
    }

    dispatch(toggleLoader(false));
  };

  const verifyForBo = async () => {
    dispatch(toggleLoader(true));
    setError('');
    setInfoError('');
    setData(null);

    setIsExpired(false);
    setIsExpiredForReading(false);
    await getAvailableSlots({ contractNumber: props.contactNumber, clc: props.codLocConsum, pod: '' })
      .then((response) => {
        if (response && response.notificationId) {
          let isExpired =
            response.status === 'CREATED_PAST' ||
            response.status === 'NO_DAYS_AVAILABLE_TO_ADD' ||
            response.status === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS' ||
            response.status === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS' ||
            response.status === 'NOT_NOTICE_NOTIFICATION' ||
            response.status === 'CREATED_2' ||
            response.status === 'OUTSIDE_INTERVAL' ||
            response.selfReadingStatus === 'OUTSIDE_INTERVAL';

          let isExpiredForReading =
            response.status === 'CREATED_WITH_INDEX' ||
            response.status === 'NOT_NOTICE_NOTIFICATION' ||
            response.status === 'CREATED_2' ||
            response.status === 'OUTSIDE_INTERVAL';

          setIsExpired(isExpired);
          setIsExpiredForReading(isExpiredForReading);

          setData(response);
          setNotificationId(response.notificationId);
        }
      })
      .catch((error) => {
        if (error && error.status) {
          if (error.status === 406 || error.status === 407) {
            setError('Date incorecte!');
            notification.error('Date incorecte!');
          } else if (error.status === 400 && error.title === 'Bad Request') {
            notification.error('Contactul introdus se referă la înlocuirea contorului. Te rugăm să folosești secțiunea Programări Înlocuiri.');
            setError('Contactul introdus se referă la înlocuirea contorului. Te rugăm să folosești secțiunea Programări Înlocuiri.');
          } else if (error.status === 404 || (error.status === 400 && error.title !== 'Bad Request')) {
            notification.error('Nu s-a găsit notificare.');
            setError('Nu s-a găsit notificare.');
          } else {
            notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
        } else {
          notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const getInfo = async (type: number) => {
    setInfoError('');
    dispatch(toggleLoader(true));

    if (type === 1) {
      await meterSelfreadingInfo({ notificationId })
        .then((response) => {
          if (response && response.notificationId) {
            setModalData(response);
            setShowModal(true);
            loadCaptchaEngine('6', 'white', 'black', '', 'programari_citiri');

            if (props && props.isUsedInBO) {
              setShowNotificationModal(true);
            }
          }
        })
        .catch((error) => {
          setInfoError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          setShowInfoErrorModal(true);
        });
    } else {
      setShowNotificationModal(true);
      // await GetAvailableSlots({ contactNumber: contractNumber, codLocConsum: clc })
      // .then((response) => {
      //   if (response && response.notificationId) {
      //     setModalData(response);
      //     setShowNotificationModal(true);

      //     if (props && props.isUsedInBO) {
      //       setShowNotificationModal(true);
      //     }
      //   }
      // })
      // .catch((error) => {
      //   setInfoError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      //   setShowInfoErrorModal(true);
      // });
    }

    dispatch(toggleLoader(false));
  };

  const handleShowModal = (type: boolean) => {
    setShowModal(type);
  };

  const renderInfoErrorModal = () => {
    return <Modal show={showInfoErrorModal} onHide={() => setShowInfoErrorModal(false)} centered backdrop={'static'}>
      <Modal.Header closeButton>
        <div>
          <h5 className="red">Eroare</h5>
        </div>
      </Modal.Header>
      <Modal.Body>{infoError}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowInfoErrorModal(false)}>OK</Button>
      </Modal.Footer>
    </Modal>;
  };

  const renderAppointmentResponseModal = () => {
    return <Modal show={appointmentResponseModal} onHide={() => setAppointmentResponseModal(false)} centered backdrop={'static'}>
      <Modal.Header closeButton>
        <div>
          <h5 className="red">{apointmentResponseModalType}</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        {apointmentResponseModalType === 'Succes' ?
          'Programarea a fost salvată cu succes! Vei fi contactat in vederea citirii contorului.' :
          'A intervenit o eroare tehnică. Programarea nu a fost salvată!'
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setAppointmentResponseModal(false)}>OK</Button>
      </Modal.Footer>
    </Modal>;
  };

  const clearModalData = () => {
    setModalData({});
  };

  const handleError = (error: string) => {
    setError(error);
  };

  const handleData = (data: any) => {
    setData(data);
  };

  const handleSuccessModal = (type: boolean) => {
    setShowModal(false);
    setShowSuccessModal(type);
    clearModalData();

    verify();
  };

  const renderSuccessModal = () => {
    return <Modal
      show={showSuccessModal}
      onHide={() => {
        setShowSuccessModal(false);
      }}
      centered
      backdrop={'static'}
    >
      <Modal.Header closeButton>
        <div>
          <h5 className="red">Succes!</h5>
        </div>
      </Modal.Header>
      <Modal.Body>Solicitarea a fost trimisă cu succes.</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          setShowSuccessModal(false);
        }}>OK</Button>
      </Modal.Footer>
    </Modal>;
  };

  const shouldRenderMessageButton = (notification: any) => {
    // Possible scenarios: ProgCitExpMsg, ProgCitMsg, ProgPreavCitMsg, ProgCitProgTransmis

    // ProgCitExpMsg
    if ((notification.status === 'CREATED_PAST' || notification.status === 'CREATED_FUTURE') && notification.selfReadingStatus === 'CREATED_2') {
      if (notification.notificationStatus === 'INCHID') {
        return true;
      }
    }

    if (notification.selfReadingStatus === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS' || notification.status === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS') {
      return true;
    }

    if ((notification.status === 'CREATED_PAST' || notification.status === 'CREATED_FUTURE') &&
      (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD')) {
      if (notification.notificationStatus === 'INCHID') {
        return true;
      }
    }

    if ((notification.status === 'OK_TO_EDIT' || notification.status === 'OK_TO_ADD') &&
      (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_2')) {
      if (notification.notificationStatus === 'INCHID') {
        return true;
      }
    }

    // ProgCitMsg
    if ((notification.status === 'OK_TO_EDIT' || notification.status === 'OK_TO_ADD' || 
    notification.status === 'NO_DAYS_AVAILABLE_TO_ADD' || notification.status === 'NO_DAYS_AVAILABLE_TO_EDIT') &&
      (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_2')) {
      if (notification.notificationStatus === 'INST') {
        return true;
      }

      // ProgPreavCitMsg
      if (notification.notificationStatus === 'PREAV') {
        return true;
      }
    }

    // ProgCitProgTransmis
    if ((notification.status === 'CREATED_PAST' || notification.status === 'CREATED_FUTURE') &&
      (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD')) {
      if (notification.notificationStatus !== 'INCHID') {
        return true;
      }
    }

    return false;
  };

  const shouldRenderResults = (notification: any) => {
    // ProgCitMsg, ProgPreavCitMsg, ProgCitProgTransmis, ProgCitProgTransmis2Msg

    // ProgCitMsg
    if ((notification.status === 'OK_TO_EDIT' || notification.status === 'OK_TO_ADD' || 
    notification.status === 'NO_DAYS_AVAILABLE_TO_ADD' || notification.status === 'NO_DAYS_AVAILABLE_TO_EDIT') &&
      (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_2')) {
      if (notification.notificationStatus === 'INST') {
        return true;
      }

      // ProgPreavCitMsg
      if (notification.notificationStatus === 'PREAV') {
        return true;
      }
    }

    // ProgCitProgTransmis
    if ((notification.status === 'CREATED_PAST' || notification.status === 'CREATED_FUTURE') &&
      (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD')) {
      if (notification.notificationStatus !== 'INCHID') {
        return true;
      }
    }

    // ProgCitProgTransmis2Msg
    if ((notification.status === 'CREATED_PAST' || notification.status === 'CREATED_FUTURE') && notification.selfReadingStatus === 'CREATED_2') {
      if (notification.notificationStatus !== 'INCHID') {
        return true;
      }
    }

    return false;
  };

  const shouldRenderAppointmentButton = (notification: any) => {
    // ProgCitMsg, ProgPreavCitMsg

    // ProgCitMsg
    if ((notification.status === 'OK_TO_EDIT' || notification.status === 'OK_TO_ADD' || 
    notification.status === 'NO_DAYS_AVAILABLE_TO_ADD' || notification.status === 'NO_DAYS_AVAILABLE_TO_EDIT') &&
      (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_2')) {
      if (notification.notificationStatus === 'INST') {
        return true;
      }

      // ProgPreavCitMsg
      if (notification.notificationStatus === 'PREAV') {
        return true;
      }
    }

    return false;
  };

  const checkDisabledButton = (data: any) => {
    if (data.status === 'NO_DAYS_AVAILABLE_TO_ADD' || data.status === 'NO_DAYS_AVAILABLE_TO_EDIT') {
      if (data.selfReadingStatus === 'OK_TO_ADD' || data.selfReadingStatus === 'CREATED_1_OK_TO_ADD' || data.selfReadingStatus === 'CREATED_2') {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {props && props.isUsedInBO ? (
        <>
          <a
            style={{ color: '#ea1c09', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setshowModalBO(true);
            }}
          >
            {props.linkText}
          </a>

          {data && <NotificationModal
            usedInBO={true}
            notification={data}
            setShow={(state: boolean) => {
              setShowNotificationModal(state);
              setshowModalBO(state);

              if (state === false) {
                setData(null);
              }

            }}
            show={showNotificationModal}
            saveNotification={(inputs: NotificationInputs) => {
              notification.success(
                'Programarea a fost salvată cu succes! Vei fi contactat în vederea citirii contorului.',
                '',
                {
                  closeButton: true,
                  positionClass: 'toast-top-center',
                  timeOut: 3000,
                  extendedTimeOut: 0
                }
              );
              setShowNotificationModal(false);
              setData(null);

              props.reloadContent && props.reloadContent();
            }}
            errorNotification={() => {
              notification.error(
                'A intervenit o eroare tehnică. Programarea nu a fost salvată!',
                '',
                {
                  closeButton: true,
                  positionClass: 'toast-top-center',
                  timeOut: 3000,
                  extendedTimeOut: 0
                }
              );
            }}
            modalTitle={'Programare citire contor'}
          />}
        </>
      ) : (
        <div>
          <Verify
            contractNumber={contractNumber}
            clc={clc}
            pod={pod}
            handleContractNumber={handleContractNumber}
            handleClc={handleClc}
            handlePod={handlePod}
            verify={verify}
            error={error}
            handleError={handleError}
            handleData={handleData}
          />
          <br></br>
          {templates && templateUp && <div dangerouslySetInnerHTML={{ __html: templateUp }} />}
          {(data !== null && shouldRenderResults(data)) && <Results data={data} />}
          {templates && templateMid && <div dangerouslySetInnerHTML={{ __html: templateMid }} />}
          <br></br>
          {data !== null && (
            <div className="actions-container d-flex justify-content-center align-items-center mb-3">
              {shouldRenderMessageButton(data) && (
                <Col xs={12} sm={4}>
                  <div
                    className={`d-flex flex-column text-center align-items-center action ${data.selfReadingStatus === 'CREATED_2' && 'disabled'}`}
                    onClick={() => {
                      if (data.selfReadingStatus !== 'CREATED_2') {
                        getInfo(1);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faFilePen} size={'3x'} className="icon" />
                    <h5 className="w-75 mt-3 mb-0">Scrie-ne un mesaj legat de citirea contorului</h5>
                  </div>
                  {messageButtonDescription !== '' && <div className="red text-center fw-bold">{messageButtonDescription}</div>}
                </Col>
              )}
              {shouldRenderAppointmentButton(data) && (
                <Col xs={12} sm={4}>
                  <div
                    className={`d-flex flex-column text-center align-items-center action ${checkDisabledButton(data) && 'disabled'}`}
                    onClick={() => getInfo(2)}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} size={'3x'} className="icon" />
                    {(data.status === 'OK_TO_ADD' || data.status === 'NO_DAYS_AVAILABLE_TO_ADD') && <h5 className="w-75 mt-3 mb-0">Efectuează o programare</h5>}
                    {(data.status === 'OK_TO_EDIT' || data.status === 'NO_DAYS_AVAILABLE_TO_EDIT') && <h5 className="w-75 mt-3 mb-0">Editează programarea</h5>}
                  </div>
                  {buttonDescription !== '' && <div className="red text-center fw-bold">{buttonDescription}</div>}
                </Col>
              )}
            </div>
          )}
          {templates && templateDown && <div dangerouslySetInnerHTML={{ __html: templateDown }} />}

          <ModalCitireContor
            show={showModal}
            handleShow={handleShowModal}
            modalData={modalData}
            clearModalData={clearModalData}
            handleSuccessModal={handleSuccessModal}
            searchData={data}
          />

          {renderSuccessModal()}
          {renderInfoErrorModal()}
          {renderAppointmentResponseModal()}

          {data && <NotificationModal
            notification={data}
            setShow={(state: boolean) => setShowNotificationModal(state)}
            show={showNotificationModal}
            saveNotification={(inputs: NotificationInputs) => {
              setAppointmentResponseModalType('Succes');
              setAppointmentResponseModal(true);
              setShowNotificationModal(false);

              verify();
            }}
            errorNotification={() => {
              setAppointmentResponseModalType('Eroare');
              setAppointmentResponseModal(true);
            }}
            modalClassName='rss-modal'
            modalTitle={'Programare citire contor'}
          />}
        </div>
      )}
    </>
  );
};

export default ProgramariCitiriContoare;