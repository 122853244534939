import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IncarcareInMasa from '@components/revizii/IncarcareInMasa/IncarcareInMasa';

export const IncarcareInMasaComponent = () => {
  return (
    <DelgazWrapper>
      <IncarcareInMasa />
    </DelgazWrapper>
  );
};
