import React from 'react';
import { PressKentico } from '@type/kentico-types';
import Moment from 'moment';
import 'moment/locale/ro';
import { Accordion } from 'react-bootstrap';

export const PressComponent = (props: { kentico: PressKentico }) => {
    const kentico = props.kentico;

    const renderCompactView = () => {
        // let press = kentico.items.slice(0,5);
        let press = kentico.items;
        return (
            <div className="press-container">
                {press.map((pressItem: any, index: number) => (
                    <div key={index} className="press-compact-view-container">
                        <div className="press-date">
                            <p className="day">{Moment(pressItem.date).format('DD')}</p>
                            <p className="month-year">{Moment(new Date(pressItem.date)).format('MMM YY')}</p>
                        </div>
                        <div className="press-article">
                            <hr className="separator" />
                            {
                                (!('description' in pressItem) || pressItem.description === null) ? <a rel="noreferrer" href={pressItem.link} className="title" target="_blank">{pressItem.title}</a> :
                                    <Accordion>
                                        <Accordion.Header>{pressItem.title}</Accordion.Header>
                                        <Accordion.Body
                                            onClick={() => {
                                                if (pressItem.link !== null) {
                                                    window.open(pressItem.link, '_blank');
                                                }
                                            }}
                                            dangerouslySetInnerHTML={{ __html: pressItem.description }}
                                        />
                                    </Accordion>
                            }
                        </div>
                    </div>
                ))}
                <a href={kentico.link} className="btn btn-sm btn-primary">Afișează mai mult</a>
            </div>
        );
    };

    return (
        <div className="m-5">
            <h3>{props.kentico.title}</h3>
            {renderCompactView()}
        </div>
    );
};