import { toggleLoader } from '@actions/LoaderActions';
import { getCaptcha } from '@api/captcha';
import { createTiket, verifyCounty } from '@api/checkDisturbance';
import { GetCounties, GetLocalities, GetStreets } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import MaskedInput from '@components/common/Input/MaskedInput';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CountyTemplate, LocalityTemplate, StreetTemplate } from '@type/geo';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CheckDisturbanceCPSchema, CheckDisturbanceSchema } from '../../../../helpers/schemas';

type AnuntaDeranjamentProps = {
  submit: (i: any) => void;
  verify: (i: any) => void;
  error: string | any;
  success: string | any;
  syncErrors: string[] | any;
  reset: () => void;
  uuid: string;
  resetUuid: () => void;
};


// const API_RESPONSE_CREATED = 'created';
// const API_RESPONSE_STARTED = 'started';
// const API_RESPONSE_IN_PROGRESS = 'in_progress';
// const API_RESPONSE_FINISHED = 'finished';
const API_RESPONSE_NEW = 'new';

//eslint-disable-next-line
const formatChars = {
  e: '[Ee]',
  m: '[Mm]',
  o: '[Oo]',
  '9': '[0123456789]'
};

//test

//test

const AnuntaDeranjament = (props: AnuntaDeranjamentProps) => {
  //form 1
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [county, setCounty] = useState('');
  const [localities, setLocalities] = useState<LocalityTemplate[]>();
  const [locality, setLocality] = useState('');
  const [streets, setStreets] = useState<StreetTemplate[]>();
  const [street, setStreet] = useState('');
  const [responseCountyCheck, setResponseCountyCheck] = useState({
    locality: '',
    street: '',
    omsStatus: '',
    progressStatus: '',
    type: '',
    solvingDate: ''
  });
  const [responseCPCheck, setResponseCPCheck] = useState({
    locality: '',
    street: '',
    omsStatus: '',
    progressStatus: '',
    type: '',
    solvingDate: ''
  });
  const [tiketNumber, setTiketNumber] = useState(null);

  const [errorStep1, setErrorStep1] = useState('');
  const [errorStep3, setErrorStep3] = useState('');
  const [avemEvenimente, setAvemEvenimente] = useState(false);
  const [existaTiket, setExistaTiket] = useState(false);
  //form 2
  const [measurementPointCode, setMeasurementPointCode] = useState('');
  // const [phone, setPhone] = useState('');
  //step
  const [step, setStep] = useState(1);
  const [captchaImage, setCaptchaImage] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string>('');
  const [instanceId, setInstanceId] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    props.resetUuid();
  }, [county, locality, street]);

  useEffect(() => {
    GetCounties('EE').then((res: any) => {
      setCounties(res);
    });
    apiGetCaptcha();
  }, []);

  useEffect(() => {
    county !== '' &&
      GetLocalities(county).then((res: any) => {
        setLocalities(res);
      });
  }, [county]);

  useEffect(() => {
    let localityId;
    if (locality) {
      localityId = (localities && localities.find((l) => l.localityCode + '' == locality)?.localityId) || '';
    }
    localityId &&
      GetStreets(localityId + '').then((res: any) => {
        let streets = res as any[];
        streets = streets.filter((street: any) => street.streetName !== '');
        setStreets(streets);
      });
  }, [locality]);

  const apiGetCaptcha = async () => {
    dispatch(toggleLoader(true));

    await getCaptcha().then((response) => {
      setCaptchaImage(response.image);
      setInstanceId(response.imageInstanceId);
    });

    dispatch(toggleLoader(false));
  };

  const submit = (values: any) => {
    dispatch(toggleLoader(true));
    values.uuid = props.uuid;

    verifyCounty(values)
      .then((res: any) => {
        if (res.locality) {
          setAvemEvenimente(true);
          setResponseCountyCheck(res);
        }
        dispatch(toggleLoader(false));
        setStep(2);
        setErrorStep1('');
      })
      .catch((er) => {
        dispatch(toggleLoader(false));
        setErrorStep1('Date identificare locație gresite!');
      });
  };

  const submitConsumptionPoint = (values: any) => {
    dispatch(toggleLoader(true));
    values.countyCode = county;
    values.localityCode = locality;
    values.streetCode = street;
    values.uuid = props.uuid;
    values.isCreate = true;
    values.captchaInstance = instanceId;
    values.captchaText = captcha;

    //save cp
    createTiket(values)
      .then((res: any) => {
        if (res.progressStatus === API_RESPONSE_NEW) {
          setTiketNumber(res.ticketId);
          setErrorStep3('');
          dispatch(toggleLoader(false));
          setStep(4);
        } else {
          setExistaTiket(true);
          setResponseCPCheck(res);
          setStep(4);
          dispatch(toggleLoader(false));
        }
      })
      .catch((er) => {
        try {
          if (er.includes('Consumption point could not be found')) {
            setErrorStep3('Date identificare client greșite!');
          } else if (er.includes('OMS data does not match DB data')) {
            setErrorStep3('Date identificare client greșite!');
          } else if (er.includes('Consumption point by') && er.includes('has not been found')) {
            setErrorStep3('Date identificare client greșite!');
          } else if (er.includes('Codul nu e valid')) {
            setErrorStep3('Codul de securitate nu este valid.');
            apiGetCaptcha();
            setCaptcha('');
          } else {
            setErrorStep3('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
            apiGetCaptcha();
            setCaptcha('');
          }
        } catch (ex) {
          setErrorStep3('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          apiGetCaptcha();
          setCaptcha('');
        }
        dispatch(toggleLoader(false));
      });
  };

  return (
    <div className="check-disturbance-form-container">
      <Formik
        initialValues={{
          localityCode: '',
          countyCode: '',
          streetCode: ''
        }}
        validationSchema={CheckDisturbanceSchema}
        onSubmit={(values) => submit(values)}
      >
        {(formProps) => (
          <Form>
            <div className="form-container">
              <div className="mt-4 mb-4 d-flex flex-column">
                {(step === 1 || step === 2) && (
                  <>
                    <Row className="w-100 mb-2 mt-3">
                      <p>Pentru sesizarea unui deranjament, vă rugăm să completați câmpurile de mai jos.</p>
                    </Row>
                    <Row className="w-100 mb-2 mt-3">
                      <Col xs={12} md={4} className="mt-2">
                        <Dropdown
                          style={{ marginBottom: '0.25rem' }}
                          options={
                            counties.map((option, index) => ({
                              id: option.countyCode,
                              name: option.countyName
                            })) as DropdownOptionType[]
                          }
                          label={'Județ'}
                          defaultPlaceholder={'Alege județul'}
                          displaySearch={true}
                          onChange={(value) => {
                            value && formProps.setFieldValue('countyCode', value);
                            value.length === 0 && formProps.setFieldValue('countyCode', '');
                            setCounty(value);
                          }}
                        />
                        <ErrorMessage name="countyCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      </Col>

                      <Col xs={12} md={4} className="mt-2">
                        {/* locality select */}
                        {county ? (
                          <Dropdown
                            style={{ marginBottom: '0.25rem' }}
                            options={
                              localities
                                ? (localities.map((option, index) => ({
                                    id: option.localityCode,
                                    name: option.localityName
                                  })) as DropdownOptionType[])
                                : []
                            }
                            label={'Localitate'}
                            defaultPlaceholder={'Alege localitatea'}
                            displaySearch={true}
                            onChange={(value) => {
                              value && formProps.setFieldValue('localityCode', value);
                              value.length === 0 && formProps.setFieldValue('localityCode', '');
                              setLocality(value);
                            }}
                          />
                        ) : (
                          <>
                            <Dropdown style={{ marginBottom: '0.25rem' }} label={'Localitate'} disabled />
                          </>
                        )}
                        <ErrorMessage name="localityCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      </Col>

                      <Col xs={12} md={4} className="mt-2">
                        {/* street select */}
                        {locality ? (
                          <Dropdown
                            style={{ marginBottom: '0.25rem' }}
                            options={
                              streets
                                ? (streets.map((option: any) => ({
                                    id: option.streetCode,
                                    name: option.streetName
                                  })) as DropdownOptionType[])
                                : []
                            }
                            label={'Strada'}
                            defaultPlaceholder={'Alege strada'}
                            displaySearch={true}
                            onChange={(value) => {
                              value && formProps.setFieldValue('streetCode', value);
                              value.length === 0 && formProps.setFieldValue('streetCode', '');
                              setStreet(value);
                            }}
                          />
                        ) : (
                          <>
                            <Dropdown style={{ marginBottom: '0.25rem' }} label={'Strada'} disabled />
                          </>
                        )}
                        <ErrorMessage name="streetCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      </Col>
                    </Row>

                    {errorStep1 && (
                      <Row>
                        <div className="mt-2">
                          <p style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errorStep1}</p>
                        </div>
                      </Row>
                    )}

                    <Row>
                      <Col md={6} className="mt-1">
                        <Button type="submit">Continuă</Button>
                      </Col>
                    </Row>
                  </>
                )}

                {step === 2 &&
                  (!avemEvenimente ? (
                    <p className="mt-3">
                      Nu au fost înregistrate evenimente la această adresă.
                      <br />
                      Vă rugăm anunțați deranjamentul prin completarea câmpurilor din pagina urmatoare, astfel încât colegii noștri să poată
                      interveni în cel mai scurt timp posibil.
                      <br />
                      Vă mulțumim!
                    </p>
                  ) : (
                    <p className="mt-3">
                      Există evenimente înregistrate la această adresă.
                      <br />
                      Colegii noștri se străduiesc să reducă durata intervenției astfel încât să putem asigura continuitate în alimentarea
                      cu energie electrică, în deplină siguranță! Data estimativă pentru realimentarea cu energie electrică în localitatea{' '}
                      <span style={{ color: '#ea1c0a' }}>{responseCountyCheck?.locality}</span>, str. <span style={{ color: '#ea1c0a' }}>{responseCountyCheck?.street}</span>, este{' '}
                      <span style={{ color: '#ea1c0a' }}>{moment(responseCountyCheck?.solvingDate).format('DD/MM/YYYY HH:mm')}</span>.
                      <br /> Vă mulțumim pentru implicare și înțelegere!
                    </p>
                  ))}

                {step === 2 && (
                  <Row>
                    <Col md={6} className="mt-1">
                      <Button
                        type="button"
                        onClick={() => {
                          setStep(3);
                        }}
                      >
                        Continuă cu anunțarea deranjamentului
                      </Button>
                    </Col>
                    <Col md={6} className="mt-1">
                      <Button
                        type="button"
                        onClick={() => {
                          props.reset();
                        }}
                      >
                        Anulare
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={{
          measurementPointCode: '',
          phone: ''
        }}
        validationSchema={CheckDisturbanceCPSchema}
        onSubmit={(values) => submitConsumptionPoint(values)}
      >
        {(formProps) => (
          <Form>
            <div className="form-container">
              <div className="mt-4 mb-4 ml-4 d-flex flex-column">
                {(step === 3 || step === 4) && (
                  <>
                    <div>
                      Câteva sfaturi utile pentru a ne ajuta să vă oferim cele mai bune servicii:
                      <br />
                      <ul>
                        <li>
                          Dacă locuința dvs. este singura fără alimentare, verificați întrerupătoarele sau siguranțele. De multe ori,
                          întreruperile de curent sunt rezultatul unei probleme de uz casnic din instalatia interioara a carei
                          responsabilitate este in sarcina dvs. proprietarului casei.
                        </li>
                        <li>Dacă nu acestea sunt problema, continuati inregistrarea deranjamentului</li>
                        <li>Verificati dacă vecinii dvs. au curent.</li>
                      </ul>
                    </div>

                    <>
                      <Row className="w-100 mt-3">
                        <p>Pentru sesizarea unui deranjament, vă rugăm să completați câmpurile de mai jos.</p>
                      </Row>
                      <Row className="w-100">
                        <Col xs={12} md={4}>
                          <Dropdown
                            disabled
                            label={'Județ'}
                            placeholder={
                              counties?.find((c: CountyTemplate) => {
                                return c.countyCode === county;
                              })?.countyName
                            }
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <Dropdown
                            disabled
                            label={'Localitate'}
                            placeholder={
                              localities?.find((l: any) => {
                                return l.localityCode === locality;
                              })?.localityName
                            }
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <Dropdown
                            disabled
                            label={'Strada'}
                            placeholder={
                              streets?.find((s: any) => {
                                return s.streetCode === street;
                              })?.streetName
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="w-100">
                        <span>
                          <strong>Codul punctului de măsurare</strong> este de forma <strong>EMOxxxxxxx</strong> și îl regăsiți pe factură
                          la rubrica - Cod punct de măsurare
                        </span>
                      </Row>

                      <Row className="mt-2">
                        <Col xs={12} md={4} className="mt-1">
                          <Field
                            id="measurementPointCode"
                            name="measurementPointCode"
                            placeholder="Cod punct de măsurare"
                            render={() => (
                                <MaskedInput
                                    value={measurementPointCode}
                                    mbZero
                                    onChange={(e: any) => {
                                          const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                          e.target.value.length !== 0 && formProps.setFieldValue('measurementPointCode', currentValue);
                                          e.target.value.length === 0 && formProps.setFieldValue('measurementPointCode', '');
                                          setMeasurementPointCode(currentValue);
                                    }}
                                    onBlur={() => {
                                      formProps.setFieldTouched('measurementPointCode');
                                    }}
                                    utility='EE'
                                    label='Cod punct de măsurare'
                                    mask={'0123333333'}
                                />
                              // <Input
                              //   label="Cod punct de masurare"
                              //   mbZero
                              //   onBlur={() => {
                              //     formProps.setFieldTouched('measurementPointCode');
                              //   }}
                              //   onChange={(e: any) => {
                              //     const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                              //     e.target.value.length !== 0 && formProps.setFieldValue('measurementPointCode', currentValue);
                              //     e.target.value.length === 0 && formProps.setFieldValue('measurementPointCode', '');
                              //     setMeasurementPointCode(currentValue);
                              //   }}
                              // />

                              // <ReactInputMask
                              //   mask="emo9999999"
                              //   id="measurementPointCode"
                              //   formatChars={formatChars}
                              //   placeholder="Cod punct de masurare"
                              //   onChange={(e) => {
                              //     const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                              //     formProps.setFieldValue('measurementPointCode', currentValue);
                              //     setMeasurementPointCode(currentValue);
                              //   }}
                              //   value={measurementPointCode}
                              // />
                            )}
                          />
                          <ErrorMessage
                            name="measurementPointCode"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col xs={12} md={4} className="mt-1">
                          <Field
                            id="phone"
                            name="phone"
                            placeholder="Telefon"
                            phone
                            render={() => (
                              <Input
                                label="Telefon"
                                mbZero
                                onBlur={() => {
                                  formProps.setFieldTouched('phone');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && formProps.setFieldValue('phone', e.target.value);
                                  e.target.value.length === 0 && formProps.setFieldValue('phone', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="phone" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>

                      <Row className="mt-3 mb-3">
                        <Col xs={12} sm={10} className="flex-row">
                          {captchaImage !== null &&
                          <img src={`data:image/jpeg;base64, ${captchaImage}`} alt='captcha'/>}
                          <FontAwesomeIcon
                              icon={faArrowRotateRight}
                              color='red'
                              className="mt-3 pointer"
                              style={{width: '20px', height: '20px', marginLeft: '8px'}}
                              onClick={() => apiGetCaptcha()}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={4}>
                          <Input
                              label='Introdu codul de securitate'
                              value={captcha}
                              onChange={(e) => setCaptcha(e.target.value)}
                          />
                        </Col>
                      </Row>

                      {errorStep3 && (
                          <Row>
                            <div className="mt-2">
                              <p style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errorStep3}</p>
                            </div>
                          </Row>
                      )}

                      {step === 3 && (
                        <Row>
                          <Col md={6} className="mt-3">
                            <Button type="submit" disabled={captcha === ''}>Anunță deranjament</Button>
                          </Col>
                          <Col md={6} className="mt-3">
                            <Button
                              type="button"
                              onClick={() => {
                                props.reset();
                              }}
                            >
                              Anulare
                            </Button>
                          </Col>
                        </Row>
                      )}
                      {step === 4 && (
                        <>
                          <Row>
                            <Col md={12} className="mt-3 mb-3">
                              <Button
                                type="button"
                                onClick={() => {
                                  props.reset();
                                }}
                                // className={'ms-5'}
                                style={{ marginLeft: '50%' }}
                              >
                                Verifică altă adresă
                              </Button>
                            </Col>
                          </Row>
                          {existaTiket ? (
                            responseCPCheck?.solvingDate ? (
                              <p>
                                Există evenimente înregistrate pe locul de consum <span style={{ color: '#ea1c0a' }}>{measurementPointCode}</span>.
                                <br />
                                Colegii noștri au luat la cunoștință și se străduiesc să reducă durata intervenției! Data estimativă pentru
                                realimentarea cu energie electrică în localitatea <span style={{ color: '#ea1c0a' }}>{responseCPCheck.locality}</span>, str. <span style={{ color: '#ea1c0a' }}>{responseCPCheck.street}</span>,
                                este <span style={{ color: '#ea1c0a' }}>{moment(responseCPCheck?.solvingDate).format('DD/MM/YYYY HH:mm')}</span>.
                                <br />
                                Vă mulțumim pentru implicare și înțelegere!
                              </p>
                            ) : (
                              <p>
                                Sesizarea dvs a fost înregistrată. Vom face toate demersurile necesare pentru restabilirea alimentării cu
                                energie electrică în cel mai scurt timp. Vă mulțumim pentru ințelegere.
                              </p>
                            )
                          ) : (
                            <p>
                              Anunțul dumneavoastră a primit numărul de înregistrare <span style={{ color: '#ea1c0a' }}>{tiketNumber}</span>.
                              <br />
                              Vă mulțumim pentru implicare!
                              <br />
                              Ne străduim să reducem durata intervenției astfel încât să vă oferim cele mai bune servicii în deplină
                              siguranță!
                            </p>
                          )}
                        </>
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AnuntaDeranjament;
