import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SET_COUNTIES, SET_LOCALITIES } from '@constants/ActionTypes/PostTypes';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { fetchSuccess } from '@actions/GeneralAction';
import { Action } from '@reducers';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { County, Locality } from '@reducers/geo';
import { toggleLoader } from '@actions/LoaderActions';

function* fetchCounties(action: Action): Generator<any> {
  try {
    const response = yield call(GetCounties);

    let counties = response as CountyTemplate[];
    let parsedCounties = counties.map((item: CountyTemplate) => {
      return {
        id: item.countyId,
        name: item.countyName,
        geoCode: item.countyCode
      } as County;
    });

    yield put(fetchSuccess<{ counties: County[] }>(
      action.type,
      { counties: parsedCounties as County[] }
    ));
  } catch (e) {
    console.error(e);
  }
}

function* fetchLocalities(action: Action<{countyCode: string}>): Generator<any> {
  yield put(toggleLoader(true));
  try {
    let payload: {countyCode: string} = action.payload as {countyCode: string};

    const response = yield call(GetLocalities, payload.countyCode);
    yield put(toggleLoader(false));

    let localities = response as LocalityTemplate[];
    let parsedCounties = localities.map((item: LocalityTemplate) => {
      return {
        id: item.localityId,
        name: item.localityName,
        geoCode: item.localityCode
      } as Locality;
    });

    yield put(fetchSuccess<{ localities: Locality[] }>(
      action.type,
      { localities: parsedCounties as Locality[] }
    ));
  } catch (e) {
    yield put(toggleLoader(false));
    console.error(e);
  }
}


function* geoSaga() {
  yield all([
    takeLatest(SET_COUNTIES, fetchCounties),
    takeLatest(SET_LOCALITIES, fetchLocalities)
  ]);
}

export default geoSaga;