import React from 'react';
import {Tabs} from '@components/dev/Components/Tabs';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { TabsKentico } from '../../../typescript/kentico-types';

export type TabsProps = { kentico: TabsKentico };

export const TabsComponent = (props: TabsProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <Tabs {...props} />
    </DelgazWrapper>
  );
};
