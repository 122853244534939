import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as settingsActionsCreators from '@actions/SettingsActions';
import * as optionsActionsCreators from '@actions/OptionsActions';
import * as authActionsCreators from '@actions/AuthActions';

import { IState } from '@type/store';
import { isClientAuth } from '@lib/Auth';
import { goToDashboardPage, setImmediatelyToStore } from '@lib/Utils';

import { GlobalSettingsKentico } from '@type/kentico-types';
import { AuthenticationContext } from '@contexts/authentication-context';

export type SettingsProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
  kentico: GlobalSettingsKentico;
};

class Settings extends React.Component<SettingsProps> {
  static contextType = AuthenticationContext;
  context!: React.ContextType<typeof AuthenticationContext>;

  componentDidMount() {
    return this._setSettings();
  }

  _setSettings = async () => {
    const queryParams = new URLSearchParams(location.search);
    const hasUser: string | null = queryParams.get('has_user');
    const backtoportal: string | null = queryParams.get('backtoportal');
    const { settings, options } = this.props.kentico;

    await this.props.setOptions({ ...options, isOptionsLoaded: true });

    const isBackOfficeSection: boolean = this.context.isBackOfficeSection;

    if (!isBackOfficeSection) {
      if (hasUser === '1') {
        let email: string | undefined = queryParams.get('email_address') ?? undefined;
        if (email) {
          email = email.trim().replace(' ', '+');
        }
        await this.props.setAuthData({ email: email });

        setTimeout(() => {
          if (backtoportal === '1') {
            // return window.location.href = `${options.oldSite}/ecran-principal`;
          } else {
            return window.location.href = `${location.origin}${location.pathname}`;
          }
        }, 1000);

      } else if (hasUser === '0') {
        await this.props.setAuthData({ email: undefined });
        await this.props.cleanUpAuth();
        document.cookie = 'token=';
        setTimeout(() => {
          return window.location.href = `${location.origin}${location.pathname}`;
        }, 1000);
      }

      // add conditions here
      // let lastCheck = localStorage.getItem('last_login_check');
      //
      // // let a = await this.props.fetchAuthState();
      //
      // if (!lastCheck || (lastCheck && moment(lastCheck) < moment().subtract(10, 'minutes'))) {
      //   localStorage.setItem('last_login_check', moment().format('YYYY-MM-DD, hh:mm:ss A'));
      //   localStorage.setItem('accessed_from', window.location.href);
      //
      //   return window.location.href = `${options.oldSite}/user-state-action?coming_from_external=1&redirect_back=${location.origin}${location.pathname}`;
      // }
    }

    if (settings.version !== this.props.settings.version && !this.props.settings.isVersionChanged) {
      await this.props.setSettings({ ...settings, isVersionChanged: true, isSettingsLoaded: true });

      await setImmediatelyToStore();

      if (isClientAuth()) return goToDashboardPage();
    }

    await this.props.setSettings({ ...settings, isSettingsLoaded: true });

    return setImmediatelyToStore();
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state: IState) => ({ settings: state.settings });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ ...settingsActionsCreators, ...optionsActionsCreators, ...authActionsCreators }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);