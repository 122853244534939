import { toggleLoader } from '@actions/LoaderActions';
import { getRomcardPayloadEE, getRomcardPayloadGN } from '@api/invoices/invoices';
import { getTimelineStepsEE, getTimelineStepsGN } from '@api/timeline';
import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';
import {
  INVOICE_STATUS_ID_NEPLATITA,
  INVOICE_STATUS_ID_PLATA_IN_AVANS,
  INVOICE_STATUS_ID_PLATA_IN_PROCESARE,
  INVOICE_STATUS_ID_PLATITA_INTEGRAL,
  INVOICE_STATUS_ID_PLATITA_PARTIAL,
  INVOICE_STATUS_ID_STORNATA
} from '@constants/invoices/Invoices';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as notification from '@lib/Notification';
import { IState } from '@type/store';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useFileDownloader from '../../hooks/useFileDownloader';
import gnIcon from '../assets/resources/img/events-icons/gn-icon.svg';

type InvoiceType = {
  id: number;
  idUser: number;
  idRequestEvent: number;
  idRequest: number;
  idRequestType: number;
  requestTypeCode: string;
  requestTypeName: string;
  invoiceNO: string;
  invoiceDate: string;
  dueDate: string;
  invoiceValue: number;
  paymentNO: string;
  paymentDate: string;
  paymentValue: number;
  invoiceLink: string;
  paymentResponseCode: number;
  sapProcessing: number;
  division: string;
  region: string;
  contract: string;
  statusId: number;
  dueAmount: number;
};

type RequestType = {
  consumptionPlaceName: string;
  requestReasonType: string;
  requestNO: string;
  requestId: number;
  requestPortalID: string;
  requestDate: string;
  requestType: number;
  neededPower: string;
  requestConnectionType: string;
  consumptionPlaceClientName: string;
  requestAnswer: string;
  firstName: string;
  lastName: string;
  buildingAddress: {
    countyName: string;
    localityName: string;
    countyCode: string;
    localityCode: number;
    villageName: string;
    streetName: string;
    streetNumber: string;
    building: string;
    entraceNumber: string;
    apartment: string;
    supl: string;
    floor: string;
  };
};

type PayInvoicesProps = {
  invoices?: InvoiceType[];
  showTotalToPay?: boolean;
  requestId: string;
  utility?: number;
  isBo?: boolean;
  boBasePath?: string;
  hideHeader?: boolean;
  fetchInvoices: boolean;
};

const PayInvoices = (props: PayInvoicesProps) => {
  const { requestId, utility, fetchInvoices } = props;
  const [request, setRequest] = useState<RequestType | null>(null);
  const [invoices, setInvoices] = useState(props.invoices);
  const [paymentInPending, setPaymentInPending] = useState(false);
  const [invoiceToPayDataArray, setInvoiceToPayDataArray] = useState<any[] | null>(null);
  const [totalToPay, setTotalToPay] = useState(0);

  const apiHost: string | undefined = useSelector<IState>((state) => state.options.apiHost) as string;

  //eslint-disable-next-line
  const { downloadFile, downloadError } = useFileDownloader();

  //eslint-disable-next-line
  const [error, setError] = useState('');

  const payInvoiceFormRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchInvoices && requestId && utility) {
      getInvoices(requestId, utility);
    }
  }, [requestId, utility, fetchInvoices]);

  useEffect(() => {
    if (invoices) {
      let total = 0;
      invoices.forEach((invoice) => {
        total = total + invoice.dueAmount;
      });
      setTotalToPay(total);
    }
  }, [invoices]);

  const getInvoices = async (requestId: string | any, utility: number) => {
    setError('');
    let res: any;
    dispatch(toggleLoader(true, 'PayInvoices'));

    if (utility == UTILITY_EE) {
      res = await getTimelineStepsEE({ requestId: requestId }).catch((err) => {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      });
    } else if (utility == UTILITY_GN) {
      res = await getTimelineStepsGN({ requestId: requestId }).catch((err) => {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      });
    }
    if (res) {
      setRequest(res.request);
      let inv: any[] = [];
      res.events.forEach((step: any) => {
        if (step.invoices && step.invoices.length) {
          inv = [...inv, ...step.invoices];
        }
      });
      setInvoices(inv);
    }
    dispatch(toggleLoader(false, 'PayInvoices'));
  };

  const handleDownloadInvoice = (invoice: InvoiceType) => {
    let finalLink = '';
    if (props.isBo) {
      finalLink = `${apiHost}/${props.boBasePath}/download_invoice?invoiceId=${invoice.id}&requestId=${props.requestId}`;
    } else {
      if (props.utility == UTILITY_EE) {
        finalLink = `${apiHost}/iconnect/RequestConsumer/download_invoice?invoiceId=${invoice.id}&requestId=${props.requestId}`;
      } else if (props.utility == UTILITY_GN) {
        finalLink = `${apiHost}/iconnect/RequestGN/download_invoice?invoiceId=${invoice.id}&requestId=${props.requestId}`;
      }
    }
    downloadFile(
      finalLink,
      `factura-${invoice.invoiceNO}.pdf`,
      'Factura nu poate fi descărcată. Vă rugăm să reîncercați mai târziu!',
      props.isBo
    );
  };

  const handlePayInvoice = (invoice: InvoiceType) => {
    dispatch(toggleLoader(true));

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('PaymentActionReturnUrl', window.location.href);
    }

    if (utility == UTILITY_EE) {
      getRomcardPayloadEE({ invoiceId: invoice.id, requestId: requestId })
        .then((res) => {
          setPaymentInPending(true);
          let dataArray = [];
          dataArray.push({ name: 'AMOUNT', value: res.amount });
          dataArray.push({ name: 'CURRENCY', value: res.currency });
          dataArray.push({ name: 'ORDER', value: res.order });
          dataArray.push({ name: 'DESC', value: res.desc });
          dataArray.push({ name: 'TERMINAL', value: res.terminal });
          dataArray.push({ name: 'TRTYPE', value: res.trtype });
          dataArray.push({ name: 'TIMESTAMP', value: res.timestamp });
          dataArray.push({ name: 'NONCE', value: res.nonce });
          dataArray.push({ name: 'ADDINFO[0][AMOUNT]', value: res.addinfo[0].amount });
          dataArray.push({ name: 'ADDINFO[0][DESC]', value: res.addinfo[0].desc });
          dataArray.push({ name: 'ADDINFO[0][CODBARE]', value: res.addinfo[0].codbare });
          dataArray.push({ name: 'ADDINFO[0][NUMARFACTURA]', value: res.addinfo[0].numarfactura });
          dataArray.push({ name: 'P_SIGN', value: res.p_SIGN });
          dataArray.push({ name: 'BFNAME', value: res.bfname ?? '' });
          dataArray.push({ name: 'BLNAME', value: res.blname ?? '' });
          dataArray.push({ name: 'BADDRESS', value: res.baddress ?? '' });
          dataArray.push({ name: 'BCITY', value: res.bcity ?? '' });
          dataArray.push({ name: 'BCOUNTRY', value: res.bcountry ?? '' });
          dataArray.push({ name: 'BEMAIL', value: res.bemail ?? '' });
          dataArray.push({ name: 'BPHONE', value: res.bphone ?? '' });
          setInvoiceToPayDataArray(dataArray);

          setTimeout(() => {
            dispatch(toggleLoader(false));
            payInvoiceFormRef.current?.submit();
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    } else if (utility == UTILITY_GN) {
      getRomcardPayloadGN({ invoiceId: invoice.id, requestId: requestId })
        .then((res) => {
          setPaymentInPending(true);
          let dataArray = [];
          dataArray.push({ name: 'AMOUNT', value: res.amount });
          dataArray.push({ name: 'CURRENCY', value: res.currency });
          dataArray.push({ name: 'ORDER', value: res.order });
          dataArray.push({ name: 'DESC', value: res.desc });
          dataArray.push({ name: 'TERMINAL', value: res.terminal });
          dataArray.push({ name: 'TRTYPE', value: res.trtype });
          dataArray.push({ name: 'TIMESTAMP', value: res.timestamp });
          dataArray.push({ name: 'NONCE', value: res.nonce });
          dataArray.push({ name: 'ADDINFO[0][AMOUNT]', value: res.addinfo[0].amount });
          dataArray.push({ name: 'ADDINFO[0][DESC]', value: res.addinfo[0].desc });
          dataArray.push({ name: 'ADDINFO[0][CODBARE]', value: res.addinfo[0].codbare });
          dataArray.push({ name: 'ADDINFO[0][NUMARFACTURA]', value: res.addinfo[0].numarfactura });
          dataArray.push({ name: 'P_SIGN', value: res.p_SIGN });
          dataArray.push({ name: 'BFNAME', value: res.bfname ?? '' });
          dataArray.push({ name: 'BLNAME', value: res.blname ?? '' });
          dataArray.push({ name: 'BADDRESS', value: res.baddress ?? '' });
          dataArray.push({ name: 'BCITY', value: res.bcity ?? '' });
          dataArray.push({ name: 'BCOUNTRY', value: res.bcountry ?? '' });
          dataArray.push({ name: 'BEMAIL', value: res.bemail ?? '' });
          dataArray.push({ name: 'BPHONE', value: res.bphone ?? '' });
          setInvoiceToPayDataArray(dataArray);

          setTimeout(() => {
            dispatch(toggleLoader(false));
            payInvoiceFormRef.current?.submit();
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    }
  };

  const getStatusById = (statusId: number) => {
    switch (statusId) {
      case INVOICE_STATUS_ID_NEPLATITA:
        return 'Neplătită';
      case INVOICE_STATUS_ID_PLATITA_INTEGRAL:
        return 'Plătită integral';
      case INVOICE_STATUS_ID_PLATITA_PARTIAL:
        return 'Plătită parțial';
      case INVOICE_STATUS_ID_PLATA_IN_AVANS:
        return 'Plată în avans';
      case INVOICE_STATUS_ID_PLATA_IN_PROCESARE:
        return 'Plată în procesare';
      case INVOICE_STATUS_ID_STORNATA:
        return 'Stornată';
      default:
        return '';
    }
  };

  const getAddressConcatenated = () => {
    let res = '';
    if (!request?.buildingAddress) return res;

    if (request?.buildingAddress.countyName) {
      // let countyName = counties.find((c) => c.countyCode == request?.buildingAddress.Judet)?.countyName;
      res = res + request?.buildingAddress.countyName;
    }
    if (request?.buildingAddress.localityName) {
      res = res + ', ' + request?.buildingAddress.localityName;
    }
    if (request?.buildingAddress.streetName) {
      res = res + ', ' + request?.buildingAddress.streetName;
    }
    if (request?.buildingAddress.streetNumber) {
      res = res + ', nr. ' + request?.buildingAddress.streetNumber;
    }
    if (request?.buildingAddress.supl) {
      res = res + ', ' + request?.buildingAddress.supl;
    }
    if (request?.buildingAddress.building) {
      res = res + ', bloc/scara ' + request?.buildingAddress.building;
    }
    if (request?.buildingAddress.entraceNumber) {
      res = res + ' ' + request?.buildingAddress.entraceNumber;
    }
    if (request?.buildingAddress.floor) {
      res = res + ', et. ' + request?.buildingAddress.floor;
    }
    if (request?.buildingAddress.apartment) {
      res = res + ', ap. ' + request?.buildingAddress.apartment;
    }
    return res;
  };

  return (
    <Fragment>
      {!props.hideHeader && request ? (
        <div className="d-flex flex-row p-3 details-block mb-3">
          <div className="utility-square me-3">
            {utility == UTILITY_EE ? <FontAwesomeIcon icon={faBolt} size="lg" /> : <img width={17.5} src={gnIcon} alt="gn-icon" />}
          </div>

          <div>
            <div className="p-gray" style={{ fontSize: '18px' }}>
              Obiectiv de racordare
            </div>

            <div>
              <h3 className="mb-2" style={{ fontSize: '22px', fontWeight: '400' }}>
                <strong>{request.consumptionPlaceName}</strong> {request.consumptionPlaceClientName}
              </h3>

              <p className="p-gray mb-1">
                Adresa: <strong className="black">{getAddressConcatenated()}</strong>
              </p>

              {utility == UTILITY_EE && request.neededPower != null && (
                <p className="p-gray mb-1">
                  Putere solicitată: <strong className="black">{request.neededPower}</strong> kW
                </p>
              )}

              {utility == UTILITY_EE && request.requestConnectionType && (
                <p className="p-gray mb-1">
                  Tip bransament: <strong className="black">{request.requestConnectionType}</strong>
                </p>
              )}

              {utility == UTILITY_GN && request.requestReasonType && (
                <p className="p-gray mb-1">
                  Motiv racordare: <strong className="black">{request.requestReasonType}</strong>
                </p>
              )}

              {utility == UTILITY_GN && request.requestAnswer && (
                <p className="p-gray mb-1">
                  Răspuns așteptat: <strong className="black">{request.requestAnswer}</strong>
                </p>
              )}
              <p className="p-gray mb-1">
                Număr cerere portal: <strong className="black">{request.requestPortalID}</strong>
              </p>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {invoices && (
        <div className="general-table-container table-narrow">
          <Table>
            <thead>
              <tr>
                <th>Număr factură</th>
                <th>Dată emitere</th>
                <th>Dată scadență</th>
                <th>Valoare factură</th>
                <th>Rest plată</th>
                <th>Stare</th>
                <th>Acțiuni</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice: InvoiceType, index: number) => (
                <tr key={index}>
                  <td>{invoice.invoiceNO}</td>
                  <td>{invoice.invoiceDate ? moment(invoice.invoiceDate).format('DD-MM-YYYY') : '-'}</td>
                  <td>{invoice.dueDate ? moment(invoice.dueDate).format('DD-MM-YYYY') : '-'}</td>
                  <td>{invoice.invoiceValue ? invoice.invoiceValue.toFixed(2) : 0}</td>
                  <td>{invoice.dueAmount ? invoice.dueAmount.toFixed(2) : 0}</td>
                  <td>{getStatusById(invoice.statusId)}</td>
                  <td>
                    <div className="d-flex flex-row">
                      <Button className="me-2" onClick={() => handleDownloadInvoice(invoice)}>
                        Descarcă
                      </Button>

                      {(invoice.statusId == INVOICE_STATUS_ID_PLATITA_PARTIAL || invoice.statusId == INVOICE_STATUS_ID_NEPLATITA) &&
                        invoice.dueAmount > 0 && (
                          <Button
                            disabled={paymentInPending}
                            onClick={() => {
                              if (props.isBo) {
                                notification.warning('Acțiunea este accesibilă doar clienților!');
                                return;
                              }
                              handlePayInvoice(invoice);
                            }}
                          >
                            Plătește
                          </Button>
                        )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {props.showTotalToPay && (
        <div className="d-flex justify-content-end mt-2">
          <div className="d-flex flex-column align-items-end">
            <h5> Total valoare restantă:</h5>
            <h4 className="red">{`${totalToPay ? totalToPay.toFixed(2) : 0} RON`}</h4>
          </div>
        </div>
      )}

      <form action="https://www.secure11gw.ro/tds/process/" method="post" ref={payInvoiceFormRef}>
        {invoiceToPayDataArray?.map((entry: { name: string; value: string }, index: number) => {
          return <input key={index} type="hidden" name={entry.name} value={entry.value} />;
        })}
      </form>
    </Fragment>
  );
};

export default PayInvoices;
