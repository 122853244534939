import React, { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { ForcedChangePasswordSchema } from '../../../helpers/schemas';
import { useDispatch, useSelector } from 'react-redux';
import { reqLogin } from '@actions/user/user';
import { IState } from '@type/store';
import { changePassword } from '@api/user/user';
import PasswordStrengthBar from 'react-password-strength-bar';
import { toggleLoader } from '@actions/LoaderActions';
import { Input } from '@components/common/Input';

const ForcedChangePasswordFormComponent = (props: any) => {
  const [errorMessage, setErrorMessage] = useState('');
  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const redirect_url: string | undefined = useSelector<IState>((state) => state.options.oldSite) as string;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setErrorMessage('');
    };
  }, []);

  const getErrorMessage = (errorCode: number) => {
    switch (errorCode) {
      case 1:
        return 'E-mail gresit.';
      case 2:
        return 'Parola gresita.';
      case 5:
        return 'OTP-ul este invalid.';
      default:
        return 'E-mail sau parola gresita.';
    }
  };

  return (
    <div className="login-form-container">
      <h4 className="mb-4">Schimbă parola</h4>
      <Fragment>
        <Formik
          initialValues={{ email: '', otp: '', password: '', newPassword: '' }}
          validationSchema={ForcedChangePasswordSchema}
          validateOnChange={false}
          onSubmit={async (values) => {
            dispatch(toggleLoader(true));
            const data = {
              userEmail: props.email,
              otp: values.otp,
              oldPassword: values.password,
              newPassword: values.newPassword,
              transactionId: props.transactionId
            };

            let response = await changePassword(data);
            if (response && !response.success) {
              setErrorMessage(getErrorMessage(response.errorCode));
            }
            if (
              response &&
              !response.changePasswordRequired &&
              !response.otpRequired &&
              response.success &&
              response.jwt &&
              response.jwt.access_token
            ) {
              dispatch(reqLogin(props.email, response.jwt.access_token));
              document.cookie = 'token=' + response.jwt.access_token;
              // window.location.href = `${OLD_SITE_URL}/solicitari`;
              window.location.href = `${redirect_url}/login?token=${response.jwt.access_token}`;
            }
            dispatch(toggleLoader(false));
          }}
        >
          {(props) => (
            <Fragment>
              <Form>
                <div className="form-container">
                  <div className="mb-4">
                    <Field
                      className="input-field"
                      id="otp"
                      name="otp"
                      placeholder="OTP"
                      render={() => (
                        <Input
                          label="OTP"
                          mbZero
                          onBlur={() => {
                            props.setFieldTouched('otp');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && props.setFieldValue('otp', e.target.value);
                            e.target.value.length === 0 && props.setFieldValue('otp', '');
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="otp" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </div>
                  <div className="mb-4">
                    <Field
                      className="input-field"
                      id="password"
                      name="password"
                      placeholder="Parola veche"
                      render={() => (
                        <Input
                          label="Parola veche"
                          type="password"
                          autoComplete="new-password"
                          mbZero
                          onBlur={() => {
                            props.setFieldTouched('password');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && props.setFieldValue('password', e.target.value);
                            e.target.value.length === 0 && props.setFieldValue('password', '');
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="password" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </div>
                  <div className="mb-4">
                    <Field
                      className="input-field"
                      id="newPassword"
                      name="newPassword"
                      placeholder="Parola nouă"
                      render={() => (
                        <Input
                          label="Parola nouă"
                          type="password"
                          autoComplete="new-password"
                          mbZero
                          onBlur={() => {
                            props.setFieldTouched('newPassword');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && props.setFieldValue('newPassword', e.target.value);
                            e.target.value.length === 0 && props.setFieldValue('newPassword', '');
                          }}
                        />
                      )}
                    />
                    <PasswordStrengthBar
                      password={props.values.newPassword}
                      scoreWords={[
                        'Puterea parolei: Slabă',
                        'Puterea parolei: Slabă',
                        'Puterea parolei: Medie',
                        'Puterea parolei: Bună',
                        'Puterea parolei: Puternică'
                      ]}
                      shortScoreWord={'Puterea parolei: Slabă'}
                    />
                    <ErrorMessage name="newPassword" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    {errorMessage !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}
                  </div>
                </div>
                <div className="buttons-container d-flex flex-row align-items-center">
                  <Button type="submit">Acceseaza</Button>
                  <a className="forgot-password-text" href={`${OLD_SITE_URL}/resetare-parola`}>
                    Ai uitat parola?
                  </a>
                </div>
              </Form>
            </Fragment>
          )}
        </Formik>
      </Fragment>
    </div>
  );
};

export default ForcedChangePasswordFormComponent;
