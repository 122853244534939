import { DropdownOptionType } from '@components/common/Dropdown';
import { RadioGroup } from '@components/common/RadioGroup';
import { ToString } from '@lib/Utils';
import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Item } from '../FiltersForm';
import { Filter, FormField, ValueToSet } from '../PageWrapper';
import DropdownField from './DropdownField';

type DropdownStringProps = {
  item: Item | FormField;
  options?: DropdownOptionType[];
  name?: string;
  label?: string;
  value?: string | number | string[] | any;
  itemTypeId: number;
  isUsedInFilters?: boolean;
  displaySearch?: boolean;
  onChange?: (e: ValueToSet) => void;
  valueWrapper?: (value: string | number) => number | string;
  renderChild?: (field: FormField, index: number, groupNumberOfColumnsPerRow: number, parent?: any) => void;
  renderChildFilter?: (filter: Item, parent?: any) => void;
  values?: any;
  groupNumberOfColumnsPerRow?: number;
  optionsForField?: OptionsForField[];
  parent?: Item | FormField;
  colspanValue?: number;
  filters?: any;
  hideLabel?: boolean;
  mbZero?: boolean;
  toggled?: boolean;
  containerClassName?: string;
};

export type Option = {
  [key: string | number]: any;
};

export type OptionsForField = {
  field: string;
  keyFieldName: string;
  child: string | any;
  childUrl: string | any;
  childUrlQuerryParameterName: string | any;
  parent: string | any;
  options: Option[];
};

const DropdownString = ({
  onChange,
  itemTypeId,
  value,
  item,
  renderChild,
  groupNumberOfColumnsPerRow,
  values,
  optionsForField,
  parent,
  colspanValue,
  renderChildFilter,
  isUsedInFilters,
  filters,
  hideLabel,
  mbZero,
  toggled,
  containerClassName
}: DropdownStringProps) => {
  const [child, setChild] = useState<any>(null);
  //eslint-disable-next-line
  const [staticData, setstaticData] = useState<boolean>(item?.itemsConfiguration?.staticData as boolean);
  const [dropdownOptions, setDropdownOptions] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(null);
  const [extraProperty, setExtraProperty] = useState<any>('');

  useEffect(() => {
    if (parent && !isUsedInFilters) {
      setDisabled(!values[parent.name]);
    }
  }, [values, parent, isUsedInFilters]);

  useEffect(() => {
    if (parent && isUsedInFilters) {
      const e = filters.find((f: any) => {
        return f.Name === parent.name;
      });
      setDisabled(!e);
    }
  }, [filters, parent, isUsedInFilters]);

  useEffect(() => {
    if (parent && !isUsedInFilters) {
      colspanValue = colspanValue ? colspanValue * 2 : 12;
    }
  }, [colspanValue, parent, isUsedInFilters]);

  useEffect(() => {
    setChild(item?.itemsConfiguration?.cascadeChild ?? null);
    if (staticData) {
      //
      if (parent) {
        const parentName = parent.name.charAt(0).toLocaleLowerCase() + parent.name.slice(1);
        let parentValue = '';
        if (!isUsedInFilters) {
          parentValue = values && values[parent.name] ? values[parent.name] : null;
        } else {
          const filterParent = filters.find((f: Filter) => f.Name === parent.name);
          parentValue = filterParent ? filterParent.Value : null;
        }
        const firstItem = item?.items && item?.items[0] ? item?.items[0] : {};
        const itemKeys = firstItem ? Object.keys(firstItem) : [];

        if (itemKeys.includes(parentName) && parentValue) {
          let filteredItems: any[] = [];
          item?.items?.forEach((i: any) => {
            if (i[parentName] === parentValue) {
              filteredItems.push(i);
            }
          });
          setDropdownOptions(getStaticOptions({ ...item, items: filteredItems }));
        } else {
          setDropdownOptions(getStaticOptions(item));
        }
      } else {
        setDropdownOptions(getStaticOptions(item));
      }
      //
    } else {
      const keyName = item.itemsConfiguration?.keyFieldName
        ? item.itemsConfiguration?.keyFieldName.charAt(0).toLocaleLowerCase() + item.itemsConfiguration?.keyFieldName.slice(1)
        : 'id';
      const valueName = item.itemsConfiguration?.valueFieldName
        ? item.itemsConfiguration?.valueFieldName.charAt(0).toLocaleLowerCase() + item.itemsConfiguration?.valueFieldName.slice(1)
        : 'value';

      let extraProperty = '';
      if (item.itemsConfiguration?.extraPropertiesToSendInActionModel && item.itemsConfiguration?.extraPropertiesToSendInActionModel[0]) {
        extraProperty = item.itemsConfiguration?.extraPropertiesToSendInActionModel[0] as string;
        extraProperty = extraProperty.charAt(0).toLocaleLowerCase() + extraProperty.slice(1);
        setExtraProperty(extraProperty);
      }

      const options =
        optionsForField &&
        optionsForField
          .find((o) => {
            return o.field === item.name;
          })
          ?.options.map((o: any) => {
            if (extraProperty) {
              return {
                id: o[keyName],
                name: o[valueName],
                extraPropertyName: extraProperty ? extraProperty : null,
                extraPropertyValue: o[extraProperty],
                disabled: false
              };
            } else {
              return {
                id: o[keyName],
                name: o[valueName],
                disabled: false
              };
            }
          });
      setDropdownOptions(options);
    }
  }, [item, staticData, parent, filters, values, optionsForField]);

  const getStaticOptions = (filterItem: Item | any) => {
    let options;
    const keyFieldName: string =
      filterItem?.itemsConfiguration?.keyFieldName?.charAt(0).toLowerCase() + filterItem?.itemsConfiguration?.keyFieldName?.slice(1);
    const valueFieldName: string =
      filterItem?.itemsConfiguration?.valueFieldName?.charAt(0).toLowerCase() + filterItem?.itemsConfiguration?.valueFieldName?.slice(1);
    //options for radio
    if (filterItem?.itemsConfiguration.showAsRadioButtons) {
      options = filterItem.items
        ? filterItem.items.map((item: any) => {
            return {
              value: item[keyFieldName],
              name: item[valueFieldName],
              disabled: false
            };
          })
        : [];
      if (isUsedInFilters) {
        options &&
          options.unshift({
            value: '',
            name: 'Fără filtru',
            disabled: false
          });
      }
    }
    //options for dropdown
    else {
      options = filterItem.items
        ? filterItem.items.map((item: any) => {
            return {
              id: item[keyFieldName],
              name: item[valueFieldName],
              disabled: false
            };
          })
        : [];
    }
    return options;
  };

  const onChangeExtraPropertyValue = (value: string | number) => {
    if (extraProperty) {
      onChange && onChange({ name: extraProperty, value: ToString(value), itemTypeId } as ValueToSet);
    }
  };

  return (
    <>
      {!disabled && (
        <>
          {item.itemsConfiguration?.showAsRadioButtons ? (
            isUsedInFilters ? (
              <RadioGroup
                className="w-100"
                multiline={true}
                // defaultMax={10}
                value={value}
                itemClassName="bg-eon-red d-inline mr-2 ms-2 small-font"
                options={dropdownOptions}
                onClick={(value?: string | string[] | number | boolean) => {
                  onChange && onChange({ name: item.name, value: ToString(value), itemTypeId } as ValueToSet);
                }}
              />
            ) : (
              <Col md={colspanValue}>
                <RadioGroup
                  className="w-100 mb-2"
                  label={item.displayName}
                  multiline={true}
                  // defaultMax={10}
                  value={value}
                  itemClassName="bg-eon-red d-inline mr-2 ms-2 small-font"
                  options={dropdownOptions}
                  onClick={(value?: string | string[] | number | boolean) => {
                    onChange && onChange({ name: item.name, value: value, itemTypeId } as ValueToSet);
                  }}
                />
              </Col>
            )
          ) : isUsedInFilters ? (
            <DropdownField
              options={dropdownOptions as any[]}
              label={item.displayName}
              itemTypeId={itemTypeId}
              name={item.name}
              value={value}
              onChange={onChange}
              isUsedInBackOffice={true}
              displaySearch={dropdownOptions && dropdownOptions.length > 10}
              hideLabel={hideLabel}
              mbZero={mbZero}
              toggled={toggled}
              containerClassName={containerClassName}
            />
          ) : (
            <>
              <Col md={colspanValue}>
                <DropdownField
                  options={dropdownOptions as any[]}
                  label={item.displayName}
                  itemTypeId={itemTypeId}
                  name={item.name}
                  value={value}
                  onChange={onChange}
                  isUsedInBackOffice={true}
                  displaySearch={dropdownOptions && dropdownOptions.length > 10}
                  changeExtraPropertyValue={onChangeExtraPropertyValue}
                  hideLabel={hideLabel}
                  mbZero={mbZero}
                  toggled={toggled}
                  containerClassName={containerClassName}
                />
              </Col>
            </>
          )}
          {!isUsedInFilters &&
            child &&
            renderChild &&
            renderChild(child, Math.floor(Math.random() * 1000), groupNumberOfColumnsPerRow ? groupNumberOfColumnsPerRow : 1, item)}
          {isUsedInFilters && child && renderChildFilter && renderChildFilter(child, item)}
        </>
      )}
    </>
  );
};

export default DropdownString;
