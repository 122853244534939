
const RichTextComponent = (props: { item: any, show: boolean }) => {
    return (
        <div className={`d - flex align-items-center justify-content-center h-100 flex-wrap ${props.show ? '' : 'd-none'}`}>
            {/* <h3 className="col-12 text-center">{item.title}</h3> */}
            <div dangerouslySetInnerHTML={{ __html: props.item.details.Text }} />
        </div>
    );
};

export default RichTextComponent;
