import React from 'react';
import {MultiTabs} from '@components/dev/Components/Tabs';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { MultiTabsKentico } from '../../../typescript/kentico-types';

export type MultiTabsProps = { kentico: MultiTabsKentico };

export const MultiTabsComponent = (props: MultiTabsProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <MultiTabs {...props} />
    </DelgazWrapper>
  );
};
