import { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '@type/store';
import { toggleLoader } from '@actions/LoaderActions';
import * as notification from '@lib/Notification';
import { store } from '@lib/store';

const useFileDownloader = () => {
  const [downloadError, setDownloadError] = useState<string | any>(null);

  const token: string | undefined = useSelector<IState>((state) => state.auth.token) as string;
  const subKey: string | undefined = useSelector<IState>((state) => state.options.subscriptionKey) as string;
  const state = store.getState();

  const dispatch = useDispatch();

  const downloadFile = (url: string, fileName: string, errorMessage?: string, isBo?: boolean) => {
    setDownloadError(null);
    dispatch(toggleLoader(true));

    let headers: any = {
      'Ocp-Apim-Subscription-Key': subKey,
      Authorization: 'Bearer ' + token
    };

    if (isBo) {
      headers.BackOfficeSecurityContext = state.backOffice.securityContext ?? '';
    }

    axios
      .get(url, {
        headers: headers,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        if (response && response.status !== 204) {
          try {
            setTimeout(() => {
              let res = response.data as BlobPart;
              const blob: Blob = new Blob([res]);
              const objectUrl: string = URL.createObjectURL(blob);
              const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

              a.href = objectUrl;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();

              document.body.removeChild(a);
              URL.revokeObjectURL(objectUrl);

              dispatch(toggleLoader(false));
            }, 500);
          } catch (error) {
            dispatch(toggleLoader(false));
            setDownloadError(error);
            notification.error(errorMessage ? errorMessage : 'A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
        } else {
          // setDownloadError(err);
          dispatch(toggleLoader(false));
          notification.error(errorMessage ? errorMessage : 'A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        }
      })
      .catch((err) => {
        setDownloadError(err);
        dispatch(toggleLoader(false));
        notification.error(errorMessage ? errorMessage : 'A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      });
  };

  return { downloadFile, downloadError };
};

export default useFileDownloader;
