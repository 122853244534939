import { toggleLoader } from '@actions/LoaderActions';
import Search from '@components/account/MyAccount/ConsumptionPointsList/Search';
import CPTableHead from '@components/account/MyAccount/ConsumptionPointsList/table/CPTableHead';
import CPTableRow from '@components/account/MyAccount/ConsumptionPointsList/table/CPTableRow';
import { faDownload, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  getSupplierTechnicalDocumentDownload,
  getSupplierTechnicalDocuments,
  getTemplatesTechnicalDocuments
} from '../../../../api/user/supplier';
import ModalDownloadDocuments from './modalDownloadDocuments/ModalDownloadDocuments';

type ConsumptionPoint = {
  clientCode: string;
  clientName: string;
  consumptionCode: string;
  pod: string;
  address: string;
  contractStatus: string;
  meterNumber: string;
  connectionStatus: string;
  tariffCategory: string;
  srmp: string;
  minPressure: string;
  maxPressure: string;
  technicalMinPressure: string;
  contractCapacity: number;
  contractDuration: string;
  supplierCode: string;
  partnerId: string;
  invoiceClass: string;
  tariff: string;
  contractNo: string;
};
type TechnicalDocumentsType = {
  solicitareInProcesare: boolean;
  data: [
    {
      documentType: string;
      documentName: string;
    }
  ];
};

const NO_DOCUMENTS = 'NO_DOCUMENTS';
const NO_DOCUMENTS_REQUEST_IN_PROGRESS = 'NO_DOCUMENTS_REQUEST_IN_PROGRESS';
const ONLY_ATR_NEW_REQUEST = 'ONLY_ATR_NEW_REQUEST';

const TabEE = (props: {
  consumptionPoints: ConsumptionPoint[] | null;
  countyChange: any;
  localityChange: any;
  localities: any;
  counties: any;
  submitSearch: any;
  locality: any;
  county: any;
  clcChange: any;
  podChange: any;
  partnerCodeChange: any,
  reset: any;
  searchPod: any;
  searchCLC: any;
  searchPartnerCode: any,
  didSearch: any;
  exportList: (utility: string) => any;
  exportAnexa2: () => any;
  exportConventiiConsum: () => any;
  showExportAnexa2Button: boolean;
  isFurnizor: boolean;
}) => {
  const [toggleSearch, setToggleSearch] = useState(false);
  const [showModalTechnicalDocuments, setShowModalTechnicalDocuments] = useState(false);
  const [modalTechnicalDocumentsTitle, setModalTechnicalDocumentsTitle] = useState('');
  const [technicalDocuments, setTechnicalDocuments] = useState<TechnicalDocumentsType>({} as TechnicalDocumentsType);
  const [currentClc, setCurrentClc] = useState('');
  const [technicalDocumentsModalMessageCase, setTechnicalDocumentsModalMessageCase] = useState<any>(null);

  const [templates, setTemplates] = useState<any>({});

  useEffect(() => {
    getTemplatesTechnicalDocuments()
      .then((res: any) => {
        setTemplates(res);
      })
      .catch((err: any) => {});
  }, []);

  const dispatch = useDispatch();

  const getTechnicalDocuments = async (item: any) => {
    dispatch(toggleLoader(true));
    let payload = { clc: item.codLocConsum };

    await getSupplierTechnicalDocuments(payload).then((documents: any) => {
      setTechnicalDocumentsModalMessageCase(null);

      const hasSolicitare = documents.solicitareInProcesare;
      let hasOtherDocumentsExceptAtr =
        documents.data.filter((doc: any) => {
          return doc.documentType != 'ATR';
        }).length > 0;
      let hasAtr =
        documents.data.filter((doc: any) => {
          return doc.documentType == 'ATR';
        }).length > 0;

      if (!hasOtherDocumentsExceptAtr) {
        if (!hasAtr && !hasSolicitare) {
          setTechnicalDocumentsModalMessageCase(NO_DOCUMENTS);
        } else if (hasAtr) {
          setTechnicalDocumentsModalMessageCase(ONLY_ATR_NEW_REQUEST);
        } else {
          setTechnicalDocumentsModalMessageCase(NO_DOCUMENTS_REQUEST_IN_PROGRESS);
        }
      }

      //remove extra ""
      if (documents && documents.data) {
        //@ts-ignore
        documents.data = documents.data.map((doc: any) => {
          doc.documentName = doc.documentName.replaceAll('"', '');
          return doc;
        });
      }

      setCurrentClc(item.codLocConsum);
      setModalTechnicalDocumentsTitle(`Documente tehnice pentru locul de consum ${item.codLocConsum || '-'}`);
      setTechnicalDocuments(documents as TechnicalDocumentsType);
      setShowModalTechnicalDocuments(true);
      dispatch(toggleLoader(false));
    });
  };

  const downloadTechnicalDocument = async (doc: any) => {
    dispatch(toggleLoader(true));
    let payload = { clc: currentClc };

    await getSupplierTechnicalDocumentDownload(payload)
      .then((res: any) => {
        const blob: Blob = new Blob([res]);

        const fileName: string = doc.documentName;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
        dispatch(toggleLoader(false));
      })
      .catch((err: any) => {
        dispatch(toggleLoader(false));
      });
  };

  const getTechnicalDocumentsModalMessage = () => {
    let content = '';
    if (technicalDocumentsModalMessageCase == NO_DOCUMENTS) {
      content = templates['FaraDocumenteTehnice'];
    } else if (technicalDocumentsModalMessageCase == NO_DOCUMENTS_REQUEST_IN_PROGRESS) {
      content = templates['FaraCRinDMS'];
    } else if (technicalDocumentsModalMessageCase == ONLY_ATR_NEW_REQUEST) {
      content = templates['ATRExistent'];
    } else;

    if (content) {
      return <div className="p-3" dangerouslySetInnerHTML={{ __html: content }}></div>;
    } else {
      return '';
    }
  };

  return (
    <div>
      {props.consumptionPoints && props.consumptionPoints.length === 0 && props.didSearch === false && (
        <div className="mt-3 d-flex justify-content-center">
          <span>Momentan nu aveți niciun punct de consum!</span>
        </div>
      )}
      {((props.consumptionPoints && props.consumptionPoints.length > 0) || props.didSearch) && (
        <div className="m-3 d-flex justify-content-end">
          <Button onClick={() => setToggleSearch((prev) => !prev)}>
            <span style={{ marginRight: '6px' }}>Panou filtrare</span>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </Button>

          {/* <Button className="ms-2" onClick={() => props.exportList('1')}>
            <span style={{ marginRight: '6px' }}>Export listă</span>
            <FontAwesomeIcon icon={faDownload} />
          </Button> */}

          {!props.isFurnizor && (
            <Button className="ms-2" onClick={() => props.exportList('1')}>
              <span style={{ marginRight: '6px' }}>Export listă</span>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          )}

          {props.showExportAnexa2Button && (
            <Button className="ms-2" onClick={() => props.exportAnexa2()}>
              <span style={{ marginRight: '6px' }}>Export Anexa 2</span>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          )}

          {props.isFurnizor && (
            <Button className="ms-2" onClick={() => props.exportConventiiConsum()}>
              <span style={{ marginRight: '6px' }}>Export convenții consum</span>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          )}
        </div>
      )}
      {toggleSearch && (
        <Search
          counties={props.counties}
          localities={props.localities}
          handleLocalityChange={props.localityChange}
          handleCountyChange={props.countyChange}
          submitSearch={props.submitSearch}
          locality={props.locality}
          county={props.county}
          clcChange={props.clcChange}
          podChange={props.podChange}
          partnerCodeChange={props.partnerCodeChange}
          reset={props.reset}
          pod={props.searchPod}
          clc={props.searchCLC}
          partnerCode={props.searchPartnerCode}
          utility={1}
          hidePartnerCodeFilter={props.isFurnizor ? true : false}
        />
      )}
      {props.consumptionPoints && props.consumptionPoints.length > 0 && (
        <div className="m-3 general-table-container">
          <Table>
            <CPTableHead utility={1} isFurnizor={props.isFurnizor} />
            <tbody>
              {props.consumptionPoints &&
                props.consumptionPoints.map((item, index) => (
                  <CPTableRow
                    utility={1}
                    item={item}
                    key={index}
                    isFurnizor={props.isFurnizor}
                    getTechnicalDocuments={getTechnicalDocuments}
                  />
                ))}
            </tbody>
          </Table>
        </div>
      )}
      {props.consumptionPoints && props.consumptionPoints.length === 0 && props.didSearch && (
        <div className="m-3 text-center" style={{ fontWeight: 'bold', color: 'red' }}>
          Nu există locuri de consum care să corespundă filtrelor dvs.
        </div>
      )}
      <ModalDownloadDocuments
        modalTitle={modalTechnicalDocumentsTitle}
        showModal={showModalTechnicalDocuments}
        handleCloseModal={() => setShowModalTechnicalDocuments(false)}
      >
        <div>{getTechnicalDocumentsModalMessage()}</div>
        {(!technicalDocumentsModalMessageCase ||
          (technicalDocumentsModalMessageCase != NO_DOCUMENTS &&
            technicalDocumentsModalMessageCase != NO_DOCUMENTS_REQUEST_IN_PROGRESS)) && (
          <div className="general-table-container">
            <Table>
              <thead>
                <tr>
                  <th>Tip document</th>
                  <th>Nume fișier</th>
                </tr>
              </thead>

              <tbody>
                {technicalDocuments?.data?.map((document: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <span>{document.documentType}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="pointer"
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '600px' }}
                        onClick={() => {
                          downloadTechnicalDocument(document);
                        }}
                      >
                        <span className="me-1 brand-link-download link link--download pointer"></span>
                        <a className="black hover-red pointer">{document.documentName}</a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </ModalDownloadDocuments>
    </div>
  );
};

export default TabEE;
