import {store} from '@lib/store';
import axios from 'axios';
import {Proxy, proxy} from '@api/azureProxy';

export function notificationsSummary(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/notifications/Notifications/list?unread=true&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function getNotifications(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/notifications/Notifications/list?keyword=${payload.search}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function getNotificationDetails(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/notifications/Notifications/details?id=${payload.id}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function markNotificationAsRead(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/notifications/Notifications/mark_as_read',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
        }
    });
}

export function deleteNotification(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/notifications/Notifications/delete',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
        }
    });
}