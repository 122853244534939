import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import CalendarIcon from '../../assets/resources/img/events-icons/calendar.svg';
import moment from 'moment';
import { CalendarWrapper } from './CalendarWrapper';
import { ro } from 'date-fns/locale';
registerLocale('ro', ro);

type DatePickerProps = {
  label?: string;
  name?: string;
  selected: any;
  onChange?: (e: any) => void;
  col?: number;
  minDate?: any;
  endDate?: any;
  maxDate?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  heightSmall?: boolean;
  disabled?: boolean;
  mbZero?: boolean;
  className?: string;
  acceptedDifferenceInYears?: number;
  placeholderText?: string;
  hideLabel?: boolean;
  filterDate?: any;
};

const DatePicker = (props: DatePickerProps) => {
  return (
    <div className={`field-group ${props.mbZero ? 'mb-0' : ''}`}>
      <span className={`input ${props.heightSmall ? 'input-height-small' : ''}`}>
        <ReactDatePicker
          filterDate={props.filterDate}
          placeholderText={props.placeholderText}
          popperContainer={CalendarWrapper}
          showYearDropdown
          className={`input-with-value ${props.className ? props.className : ''}`}
          autoComplete="off"
          showMonthYearPicker={props.showMonthYearPicker}
          name={props.name}
          minDate={
            props.minDate
              ? props.minDate
              : props.acceptedDifferenceInYears
              ? moment().subtract(props.acceptedDifferenceInYears, 'years').toDate()
              : ''
          }
          maxDate={
            props.maxDate
              ? props.maxDate
              : props.acceptedDifferenceInYears
              ? moment().add(props.acceptedDifferenceInYears, 'years').toDate()
              : ''
          }
          selected={props.selected ?? ''}
          dateFormat={props.dateFormat ? props.dateFormat : 'dd.MM.yyyy'}
          onChange={(date) => {
            props.onChange && props.onChange(date);
          }}
          disabled={props.disabled}
          locale="ro"
        />
        <img style={{ position: 'absolute', right: '0.5rem', color: '#D73824', height: '24px' }} src={CalendarIcon} alt="CalendarIcon" />
        {props.name && !props.hideLabel && (
          <label className={`date-picker-input-label ${props.disabled ? 'disabled' : ''}`}>{props.label ? props.label : props.name}</label>
        )}
      </span>
    </div>
  );
};
export default DatePicker;
