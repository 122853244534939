// mSrv
export const allowedStatus = [200, 201];
export const unauthorizedStatus = [401];

// Myline
export const ACCOUNT_MISSING_OR_INVALID_EMAIL_CODE = '6000';
export const ACCOUNT_USER_ALREADY_EXIST_CODE = '6011';
export const ACCOUNT_OLD_PASSWORD_NOT_MATCH_CODE = '6015';
export const ACCOUNT_SEND_EMAIL_ERROR_CODE = '6027';
export const ACCOUNT_USER_NOT_EXIST_CODE = '6035';
export const ACCOUNT_NEW_PASSWORD_IS_SAME_WITH_OLD_CODE = '6107';
export const ACCOUNT_NOT_MATCH_CODE = '6032';
export const FB_ACCOUNT_NOT_MATCH_CODE = '6032';
export const APPLE_ACCOUNT_NOT_ASSOCIATED_BUT_EON_EMAIL_EXISTS = '6122';
export const APPLE_ACCOUNT_NOT_ASSOCIATED = '6132';
export const APPLE_ACCOUNT_ALREADY_ASSOCIATED = '6141';

export const ACCOUNT_BAD_CRED_CODE = '6101';
export const ACCOUNT_CRED_EXP_CODE = '6102';
export const ACCOUNT_INACTIVE_CODE = '6103';
export const ACCOUNT_EXPIRED_CODE = '6104';
export const ACCOUNT_LOCKED_CODE = '6105';
export const ACCOUNT_DISABLED_CODE = '6106';
export const ACCOUNT_TERMINATED_CODE = '1205';

export const REGISTER_INVALID_PARTNER_CODE = '6023';
export const REGISTER_INVALID_ACCOUNT_CONTRACT = '6024';
export const REGISTER_INVALID_PARTNER_CONSUMPTION_CODE_PAIR = '1137';
export const REGISTER_INVALID_PARTNER_ACCOUNT_CONTRACT_CODE_PAIR = '1130';
export const REGISTER_INVALID_PARTNER_OR_ACCOUNT_CONTRACT_CODE = '1102';
export const REGISTER_PARTNER_ALREADY_ASSIGNED = '1148';
export const REGISTER_EMAIL_ALREADY_USED = '6011';
export const REGISTER_INVALID_PHONE = '6001';
export const REGISTER_INVALID_FIRST_NAME = '6003';
export const REGISTER_INVALID_LAST_NAME = '6004';
export const REGISTER_INVALID_EMAIL = '6000';
export const REGISTER_INVALID_CNP = '1139';
export const REGISTER_INVALID_CNP_LAST_DIGITS = '1127';
export const REGISTER_MISSING_OR_INVALID_PASSWORD = '6002';
export const REGISTER_MISSING_GDPR = '6010';
export const REGISTER_INVALID_PARTNER_CODE_VALIDATE = '1106';
export const REGISTER_KA_PARTNER_NOT_ALLOWED = '1107';

export const FORGOT_PASSWORD_INVALID_EMAIL = '6017';
export const FORGOT_PASSWORD_USER_INVALID_EMAIL = '6053';

export const RESET_PASSWORD_INVALID_UUID = '6030';
export const RESET_PASSWORD_INVALID_TOKEN = '6028';
export const RESET_PASSWORD_INVALID_TOKEN_TIME = '6016';
export const RESET_PASSWORD_INVALID_PASSWORD = '6002';

export const AUTH_GUEST_WITH_SEA_AC_FORBIDDEN = '1158';
export const AUTH_NOT_MMC_USER = '1132';
export const AUTH_TRANSCRIPTION_NOT_MMC = '1215';
export const AUTH_PARTNER_ALREADY_ASSOCIATED = '1148';
export const AUTH_CP_NOT_NATURAL_GAS = '1140';
export const AUTH_PARTNER_ALREADY_ASSIGNED = '1148';
export const AUTH_TOO_MANY_ATTEMPTS = '6050';

export const GUST_ACTIVATE_ELECT_INV_BAD_TOKEN = '1200';

export const SELF_METER_READING_SIMULATION_READ_TIMED_OUT = '408';
export const SELF_METER_READING_SIMULATION_RESPONSE_INVOICE_VALUE_ZERO = '3050';
export const SELF_METER_READING_SIMULATION_RESPONSE_CC_WITH_EIRT = '3051';
export const SELF_METER_READING_SIMULATION_RESPONSE_MISSING_PCS = '3053';

export const ADVENT_CONTEST_REGISTER_ALREADY_EXISTS = '1321';

// Product Price
export const PRODUCT_PRICE_INVALID_TOKEN = '2016';

// Recaptcha
export const INVALID_CAPTCHA = '6051';
export const TO_OLD_CAPTCHA = '6052';
