import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToSlug } from '@lib/Slugify';
import Tippy from '@tippyjs/react';
import { DynamicComponentDefinition, FixedMenuKentico } from '@type/kentico-types';
import React, { useEffect, useState } from 'react';
import ContactComponent from './DynamicComponents/ContactComponent';
import RichTextComponent from './DynamicComponents/RichTextComponent';

const getComponent = (name: string) => {
    let Component: object | null = null;

    if (components[name] !== undefined) {
        Component = components[name];
    }

    return Component;
};

const components: DynamicComponentDefinition = {
    'Delgaz.ContactForm': ContactComponent,
    'Delgaz.RichTextEditor': RichTextComponent
};

const FixedMenu = ({ kentico }: { kentico: Partial<FixedMenuKentico> }) => {
    const [show, setShow] = useState(false);
    const [contentSection, setContentSection] = useState<string | null>(null);
    const [expanded, setExpanded] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const buttonHandler = (event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>, content: string) => {
        event.preventDefault();

        //eslint-disable-next-line
        const button: HTMLAnchorElement | HTMLDivElement = event.currentTarget;
        let oldContent = contentSection;

        setContentSection(content);
        if (oldContent === content) {
            show === true ? setContentSection(null) : setShow(true);
        } else {
            setShow(false);
            setShow(true);
        }
    };

    const slugTitle = (title: string): string => convertToSlug(title);

    useEffect(() => {
        handleResize();
        var ignoreClickOnMeElement = document.getElementsByClassName('fixed-area')[0];

        document.addEventListener('click', function (this: Document, event: Event) {
            if (event.target instanceof HTMLElement) {
                var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
                if (!isClickInsideElement) {
                    setContentSection(null);
                    setShow(true);
                }
            }

        });
    }, []);

    const handleResize = () => {
        setExpanded(false);
        let area = document.getElementById('fixed-area');
        area?.classList.remove('expanded');
        if (window.innerWidth < 500) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const renderFixedMenu = () => {
        return <aside className="fixed-area" id="fixed-area">
            <div className="position-relative">
                <div className="wrapper">
                    { !expanded && <div className="collapsed-icons-container">
                        {kentico.items && kentico.items.map((item: any, index: number) => {
                            return (
                                <Tippy
                                    key={index}
                                    content={
                                        <p style={{background: 'rgba(0,0,0,.6)', color: 'white', padding: '7.5px', marginTop: '15px', borderRadius: '5px', fontSize: '0.75rem'}}>
                                            {item.title}
                                        </p>
                                    }
                                    duration={0}
                                    placement="right"
                                >
                                    <div
                                        key={index}
                                        className={`collapsed-icon-container ${contentSection === slugTitle(item.title) ? 'active' : ''}`}
                                        onClick={(e) => {
                                            buttonHandler(e, slugTitle(item.title));
                                        }}
                                    >
                                        <img src={item.icon} alt={item.title} className="collapsed-icon"/>
                                    </div>
                                </Tippy>
                            );
                        })}
                    </div> }

                    <ul className="menu-list">
                        {expanded && kentico.items && kentico.items.map((item: any, key: number) => {
                            return (
                                <li key={key}>
                                    <a href="#"
                                       className={contentSection === slugTitle(item.title) ? 'active' : ''}
                                       onClick={(e) => buttonHandler(e, slugTitle(item.title))}>
                                        <span className="icon">
                                            <i className="fas fa-home"></i>
                                        </span>
                                        <span className="description">{item.title}</span>
                                    </a>
                                </li>
                            );
                        })}
                        <li>
                            <FontAwesomeIcon
                                icon={faArrowRightArrowLeft}
                                size={'1x'}
                                className="expand-collapse"
                                color="#bebebe"
                                onClick={() => {
                                    if (!expanded) {
                                        setExpanded(true);
                                        let area = document.getElementById('fixed-area');
                                        area?.classList.add('expanded');
                                    } else {
                                        setExpanded(false);
                                        let area = document.getElementById('fixed-area');
                                        area?.classList.remove('expanded');
                                    }
                                }}
                            />
                        </li>
                    </ul>
                    {kentico.items && kentico.items.map((item: any, index: number) => {
                        let Component: any = getComponent(item.subComponent);
                            return Component ?
                                <div key={index} id="custom-section" style={show ? {left: '0', padding: '10px'} : {left: '-200px'}} className={`custom-section ${contentSection === slugTitle(item.title) ? 'show' : null} ${expanded ? '' : 'collapsed'}`}>
                                    <Component item={{ ...item }} show={contentSection === slugTitle(item.title)}/>
                                </div> : null;
                    })}
                </div>
            </div>
        </aside>;
    };

    const renderMobileFixedMenu = () => {
        return (
            <aside className="fixed-area-mobile">
                <div className="collapsed-icons-container">
                    <ul className="menu-list">
                        {kentico.items && kentico.items.slice(0,2).map((item: any, key: number) => {
                            return (
                                <Tippy
                                    key={key}
                                    content={
                                        <span style={{background: 'black', color: 'white', padding: '5px', borderRadius: '10px', fontSize: '0.75rem'}}>
                                            {item.title}
                                        </span>
                                    }>
                                    <div
                                        key={key}
                                        className={`collapsed-icon-container ${contentSection === slugTitle(item.title) ? 'active' : ''}`}
                                        onClick={(e) => buttonHandler(e, slugTitle(item.title))}
                                    >
                                        <img src={item.icon} alt={item.title} className="collapsed-icon"/>
                                    </div>
                                </Tippy>
                            );
                        })}
                    </ul>
                    {kentico.items && kentico.items.map((item: any, index: number) => {
                        let Component: any = getComponent(item.subComponent);
                            return Component ?
                                <div key={index} id="custom-section" style={show ? {left: '0', padding: '10px'} : {left: '-200px'}} className={`custom-section ${contentSection === slugTitle(item.title) ? 'show' : null} ${expanded ? '' : 'collapsed'}`}>
                                    <Component item={{...item}} show={contentSection === slugTitle(item.title)}/>
                                </div> : null;
                    })}
                </div>
            </aside>
        );
    };

    return (
        <>{isMobile ? renderMobileFixedMenu() : renderFixedMenu()}</>
    );
};

export default FixedMenu;
