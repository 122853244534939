
import CheckDisturbance from '@components/delgaz/ElectricEnergy/CheckDisturbance';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { CheckDisturbanceKentico } from '@type/kentico-types';



export type CheckDisturbanceComponentProps = {
  kentico: CheckDisturbanceKentico;
};

export const CheckDisturbanceComponent = (props: CheckDisturbanceComponentProps): JSX.Element => {
 
  return (
    <DelgazWrapper>
      <CheckDisturbance {...props} />
    </DelgazWrapper>
  );
};
