import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

const PartnersTableRow = (props: {item: any, onSelect: any, currentPartner: any, onDelete: any}) => {
    const { item, onSelect, currentPartner, onDelete } = props;
    //eslint-disable-next-line
    const selectStyle = (currentPartner === item) ? {border: '3px solid red', marginBottom: '4px'} : {border: 'none'};

    return (
        <tr
            className={currentPartner === item ? 'selected' : ''}
            style={{cursor: 'pointer'}}
            onClick={() => {
                onSelect(item);
            }}
        >
            <td className="p-3">
                <div className="d-flex align-items-center">
                    <input type="radio" className="custom-radio-button pointer" checked={currentPartner === item} readOnly={true} />
                    <span
                        style={
                            {
                                marginLeft: '12px',
                                border: '1px solid black',
                                borderRadius: '5px',
                                padding: '0px 4px',
                                fontSize: '14px'
                            }
                        }
                    >
                        {item.partnerCode}
                    </span>
                    <span style={{marginLeft: '12px'}} className="fw-bold">{item.partnerName}</span>
                </div>
            </td>
            <td className="p-3">
                <div className="d-flex justify-content-end w-100 h-100" style={{marginRight: '18px'}}>
                    <div 
                        className="d-flex align-items-center" 
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(item);
                        }}
                        style={{marginRight: '18px'}}
                    >
                        <Button className="btn btn-sm">
                            <FontAwesomeIcon icon={faTrash} color="white"/>
                            <span style={{marginLeft: '4px'}}>Ștergeți</span>
                        </Button>
                        
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default PartnersTableRow;