import Modal from '@components/dev/Components/Modal';
import React, { useEffect, useState } from 'react';
import { v1 as uuid } from 'uuid';
import FileLogo from '../../assets/resources/img/events-icons/file.svg';
import UploadLogo from '../../assets/resources/img/events-icons/upload.svg';
import WarningIcon from '../../assets/resources/img/events-icons/warning.svg';
import {
  ICONNECT_UPLOADED_FILES_LIMIT,
  UPLOAD_COMPONENT__DEFAULT_ACCEPTED_EXTENSIONS,
  UPLOAD_COMPONENT__DEFAULT_FILE_SIZE_LIMIT
} from '@constants/Upload';
import { Hourglass } from 'react-loader-spinner';

export type File = {
  [key: string]: any;
  fileUrl?: string | any;
  containerName?: string | any;
};

export type UploadFileProps = {
  uploadedFilesLimit?: number;
  acceptedExtensions?: string[];
  fileSizeLimit?: number;
  onSetFileList: (list: File[], index: number) => void;
  fileList: File[];
  index: number;
  preview?: boolean;
  onFileRemove?: (item: any) => void;
  disabled?: boolean;
  allFiles?: any[];
  isLoading?: boolean;
  toggleLoading?: any;
};

const FileUpload = ({
  fileList,
  index,
  onSetFileList,
  fileSizeLimit,
  acceptedExtensions,
  uploadedFilesLimit,
  preview,
  onFileRemove,
  disabled,
  allFiles,
  isLoading,
  toggleLoading
}: UploadFileProps) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  //eslint-disable-next-line
  const [errorTitle, setEttorTitle] = useState('Eroare de procesare');
  const [errorMessage, setErrorMessage] = useState('');
  const [localAcceptedExtensions, setLocalAcceptedExtensions] = useState(UPLOAD_COMPONENT__DEFAULT_ACCEPTED_EXTENSIONS);
  const [localUploadedFilesLimit, setLocalUploadedFilesLimit] = useState(ICONNECT_UPLOADED_FILES_LIMIT);
  // max size in MB
  const [localFileSizeLimit, setLocalFileSizeLimit] = useState(UPLOAD_COMPONENT__DEFAULT_FILE_SIZE_LIMIT);

  useEffect(() => {
    if (uploadedFilesLimit) {
      setLocalUploadedFilesLimit(uploadedFilesLimit);
    }
    if (acceptedExtensions) {
      setLocalAcceptedExtensions(acceptedExtensions);
    }
    if (fileSizeLimit) {
      setLocalFileSizeLimit(fileSizeLimit);
    }
  }, [uploadedFilesLimit, acceptedExtensions, fileSizeLimit]);

  const getExtension = (fileName: string) => {
    let extension = '';
    extension = fileName.substring(fileName.lastIndexOf('.')).toLocaleLowerCase();
    return extension;
  };

  function formatBytesToMb(bytes: number, decimals = 2) {
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm));
  }

  //eslint-disable-next-line
  const fileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      //@ts-ignore
      reader.readAsDataURL(file);
      reader.onerror = reject;
    });

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const checkIfFileWasAlreadyUploaded = (file: File, idx: number) => {
    let validated = true;

    allFiles?.map((f, index) => {
      if (f && f.length > 0) {
        for (let i = 0; i < file.target.files.length; i++) {
          for (let j = 0; j < f.length; j++) {
            if (file.target.files[i].name === f[j].name) {
              validated = false;
            }
          }
        }
      }
    });

    return validated;
  };

  const onFileDrop = async (e: File) => {
    toggleLoading && toggleLoading(true);

    const updatedList = [...fileList];

    for (let i = 0; i < e.target.files.length; i++) {
      console.log(e.target.files);

      const newFile = e.target.files[i];
      newFile.uuid = uuid();
      newFile.base64 = await convertToBase64(e.target.files[i]);
      const fileName = e.target.files[i].name;
      const extension = getExtension(fileName);
      const fileSizeInMb = formatBytesToMb(e.target.files[i].size, 2);

      if (!checkIfFileWasAlreadyUploaded(e, index)) {
        let message = 'Un document poate fi încărcat o singură dată.';
        setErrorMessage(message);
        setShowErrorModal(true);
        toggleLoading && toggleLoading(false);
        return;
      }

      if (e.target.files[i].size == 0) {
        let message = 'Pot fi încărcate doar fișiere care au o dimensiune mai mare de zero biți';
        setErrorMessage(message);
        setShowErrorModal(true);
        toggleLoading && toggleLoading(false);
        return;
      }
      if (!localAcceptedExtensions?.includes(extension)) {
        let message = 'Se acceptă documente și fișiere în format ' + localAcceptedExtensions?.join(', ');
        setErrorMessage(message);
        setShowErrorModal(true);
        toggleLoading && toggleLoading(false);
        return;
      }
      if (
        (fileList && fileList.length >= localUploadedFilesLimit) ||
        e.target.files.length > localUploadedFilesLimit ||
        e.target.files.length + fileList.length > localUploadedFilesLimit
      ) {
        let message = `Se pot încărca maxim ${localUploadedFilesLimit} fișiere`;
        setErrorMessage(message);
        setShowErrorModal(true);
        toggleLoading && toggleLoading(false);
        return;
      }
      if (fileSizeInMb > localFileSizeLimit) {
        let message = `Documentele nu trebuie să depășească limita maximă de ${localFileSizeLimit}MB/fisier`;
        setErrorMessage(message);
        setShowErrorModal(true);
        toggleLoading && toggleLoading(false);
        return;
      }
      if (newFile) {
        updatedList.push(newFile);
      }
    }

    onSetFileList(updatedList, index);
  };

  const fileRemove = (item: File) => {
    const updatedList = fileList.filter((file) => file.name !== item.name);
    onSetFileList(updatedList, index);
  };

  return (
    <>
      <div className="drop-file-input">
        <div className="drop-file-input__label">
          {!isLoading && (
            <div>
              <img src={UploadLogo} alt="Upload logo" />
              <p>Încarcă document</p>
            </div>
          )}
          {isLoading && (
            <div>
              <Hourglass
                visible={isLoading}
                height="24"
                width="24"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#ea1b0a', '#ea1c0a']}
              />
              <p>Încărcare...</p>
            </div>
          )}
          <input
            type="file"
            className="file-input"
            onChange={(event) => onFileDrop(event)}
            onClick={(event) => {
              // @ts-ignore
              event.target.value = null;
            }}
            disabled={disabled}
            multiple
          />
        </div>
      </div>

      {fileList && fileList.length > 0 ? (
        <>
          {fileList.map((item, index) => {
            //ignore files marked to delete
            if (item.Delete) return;
            return (
              <div key={index} className="drop-file-preview__item">
                <img src={FileLogo} alt="File logo" />
                <p>{item.name} </p>
                <div className="drop-file-preview__item__container_buttons">
                  {preview && item.fileUrl ? (
                    <span
                      className="drop-file-preview__item__del"
                      style={{ marginRight: '5px' }}
                      onClick={() => {
                        window.location.href = item.fileUrl;
                      }}
                    >
                      Descarcă
                    </span>
                  ) : (
                    <span className="drop-file-preview__item__del disabled" style={{ marginRight: '5px' }}>
                      Descarcă
                    </span>
                  )}
                  <span
                    className="drop-file-preview__item__del"
                    onClick={() => {
                      if (item.fileUrl) {
                        onFileRemove && onFileRemove(item);
                      } else {
                        if (!disabled) {
                          fileRemove(item);
                        }
                      }
                    }}
                  >
                    Șterge
                  </span>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
      <Modal title={errorTitle} show={showErrorModal} setShow={() => setShowErrorModal(!setShowErrorModal)}>
        <span className="error-modal">
          <img width={40} height={40} src={WarningIcon} alt="Warning icon" />
          <p>{errorMessage}</p>
        </span>
      </Modal>
    </>
  );
};

export default FileUpload;
