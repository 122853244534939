import React, { useState, useEffect } from 'react';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToString } from '@lib/Utils';

export type RadioGroupOptions = {
  name?: string;
  value?: string | string[] | number | boolean;
  className?: string;
};

export type RadioGroupProps = {
  options?: RadioGroupOptions[];
  value?: string | string[] | number | boolean;
  label?: JSX.Element | string;
  error?: JSX.Element | string;
  insideLabel?: string;
  className?: string;
  defaultMax?: number;
  itemClassName?: string;
  onClick?: (value?: string | string[] | number | boolean) => void;
  disabled?: string | boolean;
  linkText?: string;
  multiline?: boolean;
  onClickLink?: (value?: string | string[] | number | boolean) => void;
};

//eslint-disable-next-line
type RadioGroupState = {};


const RadioGroup = (props: RadioGroupProps) => {

  const [isShowingAll, setIsShowingAll] = useState<boolean>(false);
  const [hasMoreThanLimit, setHasMoreThanLimit] = useState<boolean>(false);

  useEffect(() => {
    if (props.options && props.defaultMax && props.options.length > props.defaultMax) {
      setHasMoreThanLimit(true);
    }
  }, []);

  const renderItems = (option: RadioGroupOptions) => {
    return <label
      // key={option.name || index}
      className={`eon-radio-label ${ToString(props.itemClassName)} ${ToString(option.className)} ${props.value == option.value ? 'checked' : ''
        }`}
      onClick={_onClick}
    >
      {option.name}
      <input
        type="radio"
        className="eon-radio"
        checked={props.value === option.value}
        onChange={_onChange}
        value={ToString(option.value)}
        disabled={!!props.disabled}
      />
    </label>;
  };

  const renderGroup = () => {
    if (!props || !props.options || !props.options.length) {
      return null;
    }

    return <div className='radio-group-items'>
      {props.options.slice(0, isShowingAll || !props.defaultMax ? props.options.length : (props.defaultMax ?? props.options.length)).map((option, index) => (
        <React.Fragment key={index}>
          {!props.linkText && (
            <>
              {props.multiline ? <div className='mt-1'>
                {renderItems(option)}
              </div> :
                <React.Fragment >{renderItems(option)}</React.Fragment>
              }
            </>
          )}
          {props.linkText && (
            <div>
              <label
                // key={option.name || index}
                className={`eon-radio-label ${ToString(props.itemClassName)} ${ToString(option.className)} ${props.value === option.value ? 'checked' : ''
                  }`}
                onClick={_onClick}
              >
                {option.name}
                <input
                  type="radio"
                  className="eon-radio"
                  checked={props.value === option.value}
                  onChange={_onChange}
                  value={ToString(option.value)}
                  disabled={!!props.disabled}
                />
              </label>
              <a
                onClick={() => {
                  props.onClickLink && props.onClickLink(option.value);
                }}
                rel="noopener"
                className=""
              >
                Vezi mai multe
              </a>
            </div>
          )}
        </React.Fragment>
      ))}
      {hasMoreThanLimit && <div className="text-center"><a
        onClick={() => {
          setIsShowingAll(!isShowingAll);
        }}
        rel="noopener"

      >
        {!isShowingAll ? <FontAwesomeIcon
          icon={faPlusCircle}
          size={'2x'}
          className="expand-collapse"
          color="#bebebe"
        /> : <FontAwesomeIcon
          icon={faMinusCircle}
          size={'2x'}
          className="expand-collapse"
          color="#bebebe"
        />}
      </a></div>}
    </div>;
  };

  const renderError = () => {
    if (!props.error) {
      return null;
    }

    return <span className="label-error">{props.error}</span>;
  };

  const _onClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    if (event && event.preventDefault) event.preventDefault();

    const target = event.currentTarget || event.target || {};
    const input = target.children && target.children.item(0);

    return props.onClick && props.onClick((input && input.getAttribute && input.getAttribute('value')) || undefined);
  };

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => event && event.preventDefault && event.preventDefault();

  return (
    <React.Fragment>
      {props.label && <span className={`radio-label ${props.multiline ? 'ms-2 mt-1' : ''}`}>{props.label}</span>}
      <div className={`radio-section ${ToString(props.className)}`}>
        {props.insideLabel && <span>{props.insideLabel}</span>}
        {renderGroup()}
        {renderError()}
      </div>
    </React.Fragment>
  );
};

export default RadioGroup;
