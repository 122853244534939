
import React, { Fragment, useEffect, useState } from 'react';
import { AdCardKentico } from '@type/kentico-types';
import { Button, Col, Row, Modal } from 'react-bootstrap';
// import { AdCardGoogleMaps } from '@kentico/global/AdCard/AdCardGoogleMaps';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { AdDetailsSchema } from '../../../../helpers/schemas';
import AdCardDetailsSlider from '@components/dev/Components/AdCard/AdCardDetailsSlider';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '@type/store';
import { toggleLoader } from '@actions/LoaderActions';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Input } from '@components/common/Input';
import InputTextArea from '@components/common/Input/InputTextArea';

const AdCardDetails = (props: { kentico: AdCardKentico }) => {
    const [terms, setTerms] = useState(false);
    const [acord, setAcord] = useState(false);
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const item = props.kentico;
    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    // const emailImobiliare: string | undefined = useSelector<IState>((state) => state.options.emailImobiliare) as string;
    // const emailAuto: string | undefined = useSelector<IState>((state) => state.options.emailAuto) as string;
    const xapikey: string | undefined = useSelector<IState>((state) => state.options.xApiKey) as string;
    const dispatch = useDispatch();
    const [fullScreenImage, setFullScreenImage] = useState<any>(null);
    const [showImage, setShowImage] = useState<boolean>(false);
    const [existsAtLeastOneDocument, setExistsAtLeastOneDocument] = useState<boolean>(false);

    useEffect(() => {
        if (item) {
          Object.keys(item).map((prop: any, index) => {
            if (
              item[prop].name !== 'Poze' &&
              item[prop].details.is_image === false &&
              (item[prop].details.is_link === true || item[prop].details.is_download === true)
            ) {
                setExistsAtLeastOneDocument(true);
            }
          });
        }
      }, [item]);
      
    const submit = async (values: any) => {
        dispatch(toggleLoader(true));

        const url = new URL(window.location.href);
        const params = url.search;

        setResponse('');

        values.message = message;
        //eslint-disable-next-line
        let body = 'Solicitare informatii suplimentare ' + `<a href=${window.location.href} target="_blank">` + `${window.location.href}` + '</a>' + `</br> Nume: ${values.lastName} </br> Prenume: ${values.firstName} </br> Telefon: ${values.phone} </br> Email: ${values.email} </br> </br> Continut mesaj: </br> ${values.message}`;

        let payload = {
            FirstName: values.firstName,
            LastName: values.lastName,
            PhoneNumber: values.phone,
            MessageEmail: values.email,
            MessageBody: values.message,
            PageType: item[0].pageType,
            UrlParams: params.slice(1)
        };

        await axios.post('https://delgaz.ro/cms_api/Utils/send_email', payload, {headers: {'XApiKey': xapikey}})
            .then((res) => {
                if (res && res.status === 200) {
                    setResponse('Solicitarea dumneavoastră a fost trimisă cu succes.');
                }
            })
            .catch((err) => setResponse('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    const findAttribute = (attributeName: string) => {
        let value;

        Object.keys(item).map((prop: any, index) => {
            if (item[prop].name === attributeName) {
                value = Number(item[prop].value);
            }
        });

        return value;
    };

    //eslint-disable-next-line
    const returnAttribute = (attributeName: string): string => {
        let value = '';

        Object.keys(item).map((prop: any, index) => {
            if (item[prop].name === attributeName) {
                value = item[prop].value;
                return value;
            }
        });

        return value;
    };

    const showImages = () => {
        return Object.keys(item).map((prop: any, index) => {
            if (item[prop].details.is_image === true) {
                return <img 
                    key={index} 
                    src={item[prop].value} 
                    alt="" 
                    className="mb-2" 
                    style={{ width: '100%', objectFit: 'cover', height: '420px' }} 
                />;
            }
        });
    };

    const showProperties = () => {
        return Object.keys(item).map((prop: any, index) => {
            if (item[prop].details.is_link === false && item[prop].details.is_download === false && item[prop].details.is_image === false && item[prop].name !== 'imagine' && item[prop].name !== 'Latitude' && item[prop].name !== 'Longitude' && item[prop].name !== 'Title' && item[prop].name !== 'ImageDisplay' && item[prop].name !== 'Telefon' && item[prop].name !== 'Fax' && item[prop].name !== 'email' && item[prop].name !== 'Tip_Centru') {
                return (
                    <div key={index} className="d-flex flex-row">
                        {item[prop].value !== '' && <div className="d-flex flex-row">
                            {item[prop].details.show_property && <span style={{ fontWeight: 'bold' }}>{item[prop].name}: &nbsp;</span>}
                            <div
                                key={index}
                                dangerouslySetInnerHTML={{ __html: item[prop].value }}
                                className="mb-3"
                                style={item[prop].details.is_highlight ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                            />
                        </div>
                        }
                    </div>
                );
            }
        });
    };

    const getLinkName = (link: string) => {
        var name = '';
        for (let i = link.length - 1; i >= 0; --i) {
            if (link[i] === '/') break;
            name += link[i];
        }
        name = name.slice(4, name.length);
        name = name.split('').reverse().join('');

        return name.replaceAll('_', ' ');
    };

    const showLinks = () => {
        return Object.keys(item).map((prop: any, index) => {
            if (item[prop].name !== 'Poze' && item[prop].details.is_image === false && (item[prop].details.is_link === true || item[prop].details.is_download === true)) {
                return (
                    <div key={index} className="d-flex flex-column">
                        {item[prop].value !== '' && item[prop].value && item[prop].value !== null && item[prop].value.split(';').map((link, i) => {
                            return (
                                <span key={i} className="d-flex flex-column">
                                    <a href={link}>{item[prop].details.show_document_name ? getLinkName(link) : 'Descarcă'}{'\n'}</a>
                                </span>
                            );
                        })}
                    </div>
                );
            }
        });
    };

    const getImagesForCarousel = () => {
        let images: any = [];

        Object.keys(item).map((prop: any, index) => {
            if (item[prop].name === 'Poze' && item[prop].value && item[prop].value !== '' && item[prop].value !== null) {
                item[prop].value.split(';').map((image) => {
                    if (image.includes('.png') || image.includes('.jpg') || image.includes('.jpeg') || image.includes('.tif') || image.includes('.tiff') ||
                        image.includes('.PNG') || image.includes('.JPG') || image.includes('.JPEG') || image.includes('.TIF') || image.includes('.TIFF')) {
                        images.push(image);
                    }
                });
            }
        });

        return images;
    };

    const showProperty = (name: string) => {
        return Object.keys(item).map((prop: any, index) => {
            if (item[prop].name === name) {
                return <div key={index} dangerouslySetInnerHTML={{ __html: item[prop].value }} className="m-2" />;
            }
        });
    };

    const showFullScreenImage = (image: any) => {
        return <Modal 
                    className="fullscreen-image-preview" 
                    centered 
                    show={showImage} 
                    onHide={() => {
                        setShowImage(false);
                        setFullScreenImage(null);
                    }}>
            <Modal.Header className="mt-4 d-flex justify-content-end">
                <FontAwesomeIcon 
                    icon={faX} 
                    className="close-btn" 
                    onClick={() => {
                        setShowImage(false);
                        setFullScreenImage(null);
                    }}
                />
            </Modal.Header>
            <Modal.Body>
                <img
                    src={image}
                    alt="" 
                    className="mb-2" 
                    style={{ width: '100%', height: '100%', objectFit: 'cover'}} 
                />
            </Modal.Body>
        </Modal>;
    };

    const showCarousel = () => {
        if (getImagesForCarousel().length > 0) {
            return <AdCardDetailsSlider 
                        images={getImagesForCarousel()} 
                        onDoubleClick={(image: any) => {
                            setFullScreenImage(image);
                            setShowImage(true);
                        }}
                    />;
        }
    };

    return (
        <div className="mt-3 p-4 m-auto container-detalii-imobiliare" style={{ maxWidth: '1320px' }}>
            <h5>{showProperty('Tip_Centru')}</h5>
            <Row>
                {(showImage && fullScreenImage) && showFullScreenImage(fullScreenImage)}
                <Col sm={12} md={item[0].pageType === 'Imobiliare' ? 8 : 6}>
                    <div style={{ width: '100%' }}>
                        {item[0].pageType === 'Repere' ? showCarousel() : showImages()}
                        {item[0].pageType != 'Repere'  &&
                            <div className="mt-2 mb-2">
                            {showProperties()}
                            {existsAtLeastOneDocument && <b>Documente:</b>}
                            {showLinks()}
                            </div>
                        }
                    </div>
                    {item[0].pageType === 'Repere' && <div style={{marginBottom: '2rem'}}/>}
                </Col>
                <Col sm={12} md={item[0].pageType === 'Imobiliare' ? 4 : 6}>
                    {item[0].pageType === 'Repere' ? (
                        <div className="mt-2 mb-2">
                        {showProperties()}
                        {existsAtLeastOneDocument && <b>Documente:</b>}
                        {showLinks()}
                        </div>
                    ) : (
                    <div style={{ width: '100%' }}>
                        {/*<div className="mb-3 p-3" style={{background: '#f8f8f8'}}>*/}
                        {/*    {showProperty('Tip_Centru')}*/}
                        {/*    {showProperty('Telefon')}*/}
                        {/*    {showProperty('Fax')}*/}
                        {/*    {showProperty('email')}*/}
                        {/*</div>*/}
                        <div className="p-3" style={{ background: '#f8f8f8' }}>
                            <Formik
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    phone: '',
                                    email: '',
                                    message: '',
                                }}
                                onSubmit={(values) => submit(values)}
                                validationSchema={AdDetailsSchema}
                            >
                                {(props) => (
                                    <Fragment>
                                        <Form>
                                            <div>
                                                <div className="d-flex flex-column">
                                                    <Row className="mb-3">
                                                        <Col xs={12}>
                                                            <Field id="lastName" name="lastName" placeholder="Nume" 
                                                                render={() => (
                                                                    <Input
                                                                      label="Nume"
                                                                      mbZero
                                                                      onBlur={() => {
                                                                        props.setFieldTouched('lastName');
                                                                      }}
                                                                      onChange={(e: any) => {
                                                                        e.target.value.length !== 0 && props.setFieldValue('lastName', e.target.value);
                                                                        e.target.value.length === 0 && props.setFieldValue('lastName', '');
                                                                      }}
                                                                    />
                                                                  )}
                                                            />
                                                            <ErrorMessage name="lastName" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xs={12}>
                                                            <Field id="firstName" name="firstName" placeholder="Prenume" 
                                                                render={() => (
                                                                    <Input
                                                                      label="Prenume"
                                                                      mbZero
                                                                      onBlur={() => {
                                                                        props.setFieldTouched('firstName');
                                                                      }}
                                                                      onChange={(e: any) => {
                                                                        e.target.value.length !== 0 && props.setFieldValue('firstName', e.target.value);
                                                                        e.target.value.length === 0 && props.setFieldValue('firstName', '');
                                                                      }}
                                                                    />
                                                                  )}
                                                            />
                                                            <ErrorMessage name="firstName" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xs={12}>
                                                            <Field id="email" name="email" placeholder="Email"
                                                                render={() => (
                                                                    <Input
                                                                      label="Email"
                                                                      mbZero
                                                                      onBlur={() => {
                                                                        props.setFieldTouched('email');
                                                                      }}
                                                                      onChange={(e: any) => {
                                                                        e.target.value.length !== 0 && props.setFieldValue('email', e.target.value);
                                                                        e.target.value.length === 0 && props.setFieldValue('email', '');
                                                                      }}
                                                                    />
                                                                  )}
                                                            />
                                                            <ErrorMessage name="email" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xs={12}>
                                                            <Field id="phone" name="phone" placeholder="Telefon"
                                                                render={() => (
                                                                    <Input
                                                                      label="Telefon"
                                                                      mbZero
                                                                      onBlur={() => {
                                                                        props.setFieldTouched('phone');
                                                                      }}
                                                                      onChange={(e: any) => {
                                                                        e.target.value.length !== 0 && props.setFieldValue('phone', e.target.value);
                                                                        e.target.value.length === 0 && props.setFieldValue('phone', '');
                                                                      }}
                                                                    />
                                                                  )}
                                                            />
                                                            <ErrorMessage name="phone" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-1">
                                                        <Col xs={12}>
                                                            <InputTextArea
                                                            id={'message'}
                                                            label="Mesaj*"
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            mbZero
                                                            />
                                                            <ErrorMessage name="message" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                                        </Col>
                                                    </Row>
                                                </div>


                                            </div>

                                            <div className="d-flex">
                                                <div className="d-flex checkbox-container">
                                                    <div style={{width: '25px'}}>
                                                        <Field type="checkbox" checked={terms} onChange={() => setTerms(prev => !prev)} className="create-account-checkbox" style={{width: '25px'}}/>
                                                    </div>
                                                    <span className="checkbox-span">Sunt de acord cu <a href={`${OLD_SITE_URL}/conditii-de-utilizare`} target="_blank" style={{ color: '#ea1c0a' }} rel="noreferrer">termenii și condițiile.</a></span>
                                                </div>
                                            </div>

                                            <div className="d-flex">
                                                <div className="d-flex checkbox-container">
                                                    <div style={{width: '25px'}}>
                                                        <Field type="checkbox" checked={acord} onChange={() => setAcord(prev => !prev)} className="create-account-checkbox" style={{width: '25px'}}/>
                                                    </div>
                                                    <span className="checkbox-span">Am luat cunoștință de informațiile privind <a href={`${OLD_SITE_URL}/protectia-datelor`} target="_blank" style={{ color: '#ea1c0a' }} rel="noreferrer">Protecția și confidențialitatea datelor.</a></span>
                                                </div>
                                            </div>

                                            {response !== '' && <div className="mt-2" style={{ fontWeight: 'bold', color: 'red' }}>{response}</div>}
                                            <div className="mt-2 buttons-container d-flex flex-row align-items-center">
                                                {(terms && acord) ? <Button type="submit">Solicită informații</Button> : <Button disabled type="submit">Solicită informații</Button>}
                                            </div>
                                        </Form>
                                    </Fragment>
                                )}
                            </Formik>
                        </div>
                    </div>
                    )}
                </Col>
            </Row>
            <Row>

            </Row>
            {item[0].pageType !== 'Repere' && <Row className="mt-2 p-1">
                {
                    getImagesForCarousel().length > 0 ? <Fragment>
                        <Col sm={12} md={6}>
                            {getImagesForCarousel().length > 0 && <AdCardDetailsSlider images={getImagesForCarousel()} />}
                        </Col>
                        {/*
                        DISABLED
                        <Col sm={12} md={6}>
                            <AdCardGoogleMaps kentico={{ latitude: findAttribute('Latitude')!, longitude: findAttribute('Longitude')! }} />
                        </Col> */}
                    </Fragment> : <Fragment>
                        {/*
                        DISABLED
                        <Col sm={12}>
                            <AdCardGoogleMaps kentico={{ latitude: findAttribute('Latitude')!, longitude: findAttribute('Longitude')! }} />
                        </Col> */}
                    </Fragment>
                }
            </Row>}
        </div>
    );
};

export default AdCardDetails;
