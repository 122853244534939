import React, {Fragment, useEffect, useState} from 'react';
import {UploadFile} from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import {getCaptcha} from '@api/captcha';
import {Input} from '@components/common/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '@type/store';
import {Button, Col, Row} from 'react-bootstrap';
import { toggleLoader } from '@actions/LoaderActions';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import axios from 'axios';

const Form = () => {
    const [fileList, setFileList] = useState<File[]>([] as File[]);
    const [captchaImage, setCaptchaImage] = useState<string | null>(null);
    const [captcha, setCaptcha] = useState<string>('');
    const [instanceId, setInstanceId] = useState<string>('');
    const [message, setMessage] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const url: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    const xApiKey: string | undefined = useSelector<IState>((state) => state.options.xApiKey) as string;

    const dispatch = useDispatch();

    useEffect(() => {
        apiGetCaptcha();
    }, []);

    useEffect(() => {
        if (successMsg !== '') {
            setModalTitle('Succes!');
            setModalMessage('Documentele au fost preluate cu succes.');
            setShowModal(true);
        }
    }, [successMsg]);

    const handleSubmit = async () => {
        if (validateEmail(userEmail)) {
            dispatch(toggleLoader(true));

            const url = new URL(window.location.href);
            const params = url.search;
            const searchParams = new URLSearchParams(params);
            let id: string | null = '';
    
            if (searchParams.has('documentId')) {
                id = searchParams.get('documentId');
            } else if (searchParams.has('DocumentId')) {
                id = searchParams.get('DocumentId');
            }
    
            setMessage('');
            setSuccessMsg('');
    
            let formData = new FormData();
            fileList.map((file) => {
                formData.append('files', file as Blob);
            });
            formData.append('captchaInstance', instanceId);
            formData.append('CaptchaText', captcha);
            //@ts-ignore
            formData.append('DocumentId', id);
            formData.append('UserEmail', userEmail);
            formData.append('UserName', userName);
    
            await axios.post('https://delgaz.ro/cms_api/Aquisition/upload', formData, {headers: {'XAPIKey': xApiKey}})
                .then((response) => {
                    if (response.status === 200) {
                        setMessage('Documentele au fost preluate cu succes.');
                        setSuccessMsg('Documentele au fost preluate cu succes.');
                    } else {
                        setMessage(response.data);
                    }
                })
                .catch((error) => console.log(error));
    
            apiGetCaptcha();
    
            dispatch(toggleLoader(false));
        }
    };

    const apiGetCaptcha = async () => {
        dispatch(toggleLoader(true));

        await getCaptcha().then((response) => {
            setCaptchaImage(response.image);
            setInstanceId(response.imageInstanceId);
        });

        dispatch(toggleLoader(false));
    };

    const handleFileListChange = (list: File[]) => {
        setFileList(list);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const validateEmail = (mail: string) => {
        setEmailError('');

        //eslint-disable-next-line
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        } else {
            setEmailError('Adresa de email are formatul gresit.');
            return false;
        }
    };

    return (
        <div className="container-formular mt-4 p-3">
            { successMsg === '' && <Fragment>
                <h5 style={{fontSize: '29px'}} className="red mb-4">Depune oferta</h5>
                <Row className="mb-0">
                    <Input
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        label="Denumire ofertant"
                    />
                </Row>
                <Row className="mb-4">
                    <Input
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        label="Adresa de email"
                        mbZero
                    />
                    {emailError !== '' && <p className="red fw-bold">{emailError}</p>}
                </Row>
                <UploadFile
                    onSetFileList={handleFileListChange}
                    fileList={fileList}
                    index={0}
                    acceptedExtensions={['.bmp', '.gif', '.jpg', '.png', '.doc', '.docx', '.pdf', '.xls', '.xlsx', '.zip', '.xlsm', '.jpeg']}
                    fileSizeLimit={150}
                />
                <Row className="mt-3 mb-3">
                    <Col xs={12} sm={10} className="flex-row">
                        {captchaImage !== null &&
                        <img src={`data:image/jpeg;base64, ${captchaImage}`} alt='captcha'/>}
                        <FontAwesomeIcon
                            icon={faArrowRotateRight}
                            color='red'
                            className="mt-3 pointer"
                            style={{width: '20px', height: '20px', marginLeft: '8px'}}
                            onClick={() => apiGetCaptcha()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <Input
                            label='Introdu codul de securitate'
                            value={captcha}
                            onChange={(e) => setCaptcha(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    {message !== '' && <p className="red fw-bold">{message}</p>}
                </Row>
                <Row className="mt-4 mb-4">
                    <div className="d-flex justify-content-between">
                        <Button onClick={() => window.location.href = `${url}/serviciul-de-informare/anunturi-de-achizitii`}>
                            Anulare
                        </Button>
                        <Button disabled={captcha === '' || fileList.length === 0} onClick={() => handleSubmit()}>
                            Salvare
                        </Button>
                    </div>
                </Row>
            </Fragment>}
            { successMsg !== '' &&
            <Fragment>
                <p className="red fw-bold">{successMsg}</p>
            </Fragment>
            }
            <ModalConfirm modalTitle={modalTitle} showModal={showModal} onClose={handleCloseModal} >
                <h5>{modalMessage}</h5>
            </ModalConfirm>
        </div>
    );
};

export default Form;