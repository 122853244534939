import { ToString } from '@lib/Utils';
import moment from 'moment';

const Results = (props: { data: any }) => {
    const { data } = props;

    const renderDate = () => {
        const dateOffset: number = moment(data.appointmentUtcDate, 'YYYYMMDDHHmm').utcOffset();

        let date = data.appointmentUtcDate ? moment(data.appointmentUtcDate, 'YYYYMMDDHHmm').toDate() : null;
        let time = '';
        let formattedDate = '';
        if (date) {
            time = `${ToString((date.getHours() + ((dateOffset) / 60))).padStart(2, '0')}:${ToString(date.getMinutes()).padStart(2, '0')}`;
            //@ts-ignore
            formattedDate = moment(date).format('DD.MM.YYYY HH:mm');
            if (formattedDate.substring(11) === '00:00') {
                formattedDate = formattedDate.substring(0, 10);
            } else {
                formattedDate = formattedDate.substring(0, 10) + ' ' + time;
            }
        }

        return formattedDate;
    };

    return (
        <div className="general-table-container mb-3">
            <table className="w-100">
                <thead>
                    <tr>
                        <th>Nume si Prenume / Denumire client</th>
                        <th>Adresa locului de consum</th>
                        <th>Echipament</th>
                        <th>Material</th>
                        <th>Serie</th>
                        <th>Tip de notificare</th>
                        <th>Programare</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{data.clientName} {data.clientFirstName}</td>
                        <td>{data.consumptionPointAddress}</td>
                        <td>{data.equipment}</td>
                        <td>{data.material}</td>
                        <td>{data.series}</td>
                        <td>{data.notificationTypeDescription}</td>
                        <td>{data.appointmentUtcDate !== null ? renderDate() : '-'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Results;