import React from 'react';
import { Button } from 'react-bootstrap';

type StepsNavigationButtonsProps = {
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  btnNextTypeSubmit?: boolean;
  btnNextLabel?: string;
  disableBtnPrevious?: boolean;
  className?: string;
};

const StepsNavigationButtons = (props: StepsNavigationButtonsProps) => {
  return (
    <div className={`${props.className ? props.className : ''} d-flex flex-row justify-content-between`}>
      <Button className="d-flex mt-3" onClick={() => props.onClickPrevious && props.onClickPrevious()} disabled={props.disableBtnPrevious}>
        Pasul anterior
      </Button>

      {props.btnNextTypeSubmit ? (
        <Button className="d-flex mt-3" type="submit">
          Pasul următor
        </Button>
      ) : (
        <Button className="d-flex mt-3" onClick={() => props.onClickNext && props.onClickNext()}>
          {props.btnNextLabel ? props.btnNextLabel : 'Pasul următor'}
        </Button>
      )}
    </div>
  );
};

export default StepsNavigationButtons;
