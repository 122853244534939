import { ToString } from '@lib/Utils';
import React from 'react';


export enum ChangeStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING'
}
export type ChangeStatusType = { [key in ChangeStatus]?: string };

export type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

export type SelectProps = {
    error?: string;
    value?: string;
    defaultValue?: string;
    style?: any;
    id?: string;
    success?: string;
    alert?: string;
    label?: string;
    description?: string;
    containerClassName?: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    // name?: ValueOf<typeof FormFieldsNames>;
    name?: string;
    icon?: JSX.Element;
    // status?: ChangeStatusType;
    // statusKey?: ChangeStatus;
    additionalHtml?: JSX.Element;
    hasTooltip?: boolean;
    className?: string;
    options: LocalityTemplate[];
    tooltipHtml?: JSX.Element;
    placeholder?: string | null;
    warningStreetNumberBuilding?: string;
    status?: ChangeStatusType;
    styleClass?: string;
    disabled?: string | boolean;
    statusKey?: ChangeStatus;
};
export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;
export const INVALID = 'invalid';

const LocalitySelect = (props: SelectProps) => {

    const {
        id,
        label,
        error,
        success,
        alert,
        description,
        containerClassName,
        icon,
        placeholder,
        value,
        defaultValue,
        className,
        additionalHtml,
        hasTooltip,
        tooltipHtml,
        name,
        warningStreetNumberBuilding,
        //eslint-disable-next-line
        ...rest
    } = props;

    const OnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event = event || defaultEvent;

        event.persist();

        return props.onChange(event);
    };

    // const SetOptions = (props = this.props) => {
    //     return this.setState({ options: props.options });
    // };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return <span className="label-error">{props.error}</span>;
    };

    const RenderOptions = () => {
        if (!props.options || !props.options.length) {
            return null;
        }

        return props.options.map((option, index) => (
            <option key={index} value={option.localityId}>
                {option.localityName}
            </option>
        ));
    };

    const validation = props.error ? INVALID : '';
    //eslint-disable-next-line
    const val = props.value !== undefined ? props.value : '-1';

    const statusClass = ToString(props.status && props.statusKey).toLowerCase();

    return (
        <div
            className={`field-group select ${validation} ${ToString(props.containerClassName)} ${statusClass}`}
            style={props.style}
        >
            <label>{label}</label>
            <select
                className={`${props.styleClass || ''} ${props.placeholder === null ? 'no' : 'with'}-placeholder`}
                value={value}
                onChange={OnChange}
                name={name}
                disabled={!!props.disabled}
            >
                {props.placeholder !== null && (
                    <option defaultValue="true" key="-1" value="">
                        {props.placeholder}
                    </option>
                )}
                {RenderOptions()}
            </select>

            {renderError()}
            {props.status && props.statusKey && (
                <div className="status">
                    <p>{props.status[props.statusKey]}</p>
                </div>
            )}
        </div>
    );
};

export default LocalitySelect;
