import { toggleLoader } from '@actions/LoaderActions';
import { getDocumentTypes, postStepSix } from '@api/prosumatori/prosumatori';
import { Select } from '@components/common/Select';
import { File } from '@components/common/UploadFile/UploadFile';
import FileUpload from '@components/prosumatori/components/FileUpload';
import { faCircleInfo, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const DocumenteAnexate = (props: {
  isEditing: any;
  requestId: any;
  setStep: any;
  currentStep: any;
  progress: any;
  setProgress: any;
  files: any;
  handleFiles: any;
  wereFilesMounted: any;
  handleWereFilesMounted: any;
  uploadedFiles: any;
  handleInitalFilesToUpload: any;
  uploadFile: any;
  alreadyUploaded: any;
  handleShowExtraFiles: any;
  reloadAlreadyUploadedDocuments: any;
}) => {
  const {
    isEditing,
    requestId,
    setStep,
    currentStep,
    progress,
    setProgress,
    files,
    handleFiles,
    wereFilesMounted,
    handleWereFilesMounted,
    uploadedFiles,
    uploadFile,
    alreadyUploaded,
    handleShowExtraFiles,
    reloadAlreadyUploadedDocuments
  } = props;

  const [error, setError] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    reloadAlreadyUploadedDocuments();
  }, []);

  useEffect(() => {
    setToggleLoading(false);
  }, [uploadedFiles])

  const mountFiles = async (payload: any) => {
    dispatch(toggleLoader(true));

    await getDocumentTypes({ requestId: payload })
      .then((response: any) => {
        let mdn: any[] = [];
        response.map((item: any, index: any) => {
          if (item.isMandatory === true) {
            let i = item;
            i.show = true;
            mdn.push(i);
          } else {
            let i = item;
            i.show = false;
            mdn.push(i);
          }
        });
        mdn = mdn.sort((a: any, b: any) => (a.isMandatory ? -1 : 1));
        handleFiles(mdn);
      })
      .catch((error: any) => {
        if (error && error.status === 200) {
          if (wereFilesMounted === false) {
            handleFiles(error);
            handleWereFilesMounted(true);
          }
        }
      });

    dispatch(toggleLoader(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    mountFiles(requestId);
  }, [currentStep]);

  const addUploadedFilesToList = (uploaded: any) => {
    uploaded.map((item: any, index: number) => {
      files.map((file: any, idx: number) => {
        let arr: any[] = [];

        if (item.documentTypeId === file.documentTypeId) {
          item.files.map((f: any, i: number) => {
            let addedItem: any = {};

            addedItem.name = f.fileName;
            addedItem.base64 = f.base64 !== null ? f.base64 : null;
            addedItem.fileDownloadUrl = f.fileDownloadUrl;
            addedItem.requestDocumentId = f.requestDocumentId;

            arr.push(addedItem);
          });

          uploadFile(arr, idx, true);
          file.show = true;
        }
      });
    });
  };

  useEffect(() => {
    addUploadedFilesToList(alreadyUploaded);
  }, [alreadyUploaded, files]);

  const validateRequiredDocuments = () => {
    let validated = true;

    files.map((file: any, index: number) => {
      if (file.isMandatory === true) {
        if (uploadedFiles[index].length === 0) {
          setError('Câmpurile marcate cu * sunt obligatorii.');
          validated = false;
        }
        if (uploadedFiles[index][0].base64 === 'data:') {
          setError('Documentele încărcate nu sunt valide.');
          validated = false;
        }
      }
    });

    return validated;
  };

  const constructPayload = () => {
    let allFiles = files;
    let allUploadedFiles = uploadedFiles;

    let data = new FormData();

    let i = 0;
    
    allUploadedFiles.map((files: File[], index: number) => {
      if (files.length !== 0) {
        if (isEditing === true) {
          data.append(`RequestDocuments[${i}].DocumentTypeId`, allFiles[index].documentTypeId);

          files.map((file: File, idx: number) => {
            let RequestDocId = allUploadedFiles[index][idx].requestDocumentId ? allUploadedFiles[index][idx].requestDocumentId : -1;

            let isNewFile = file.base64 as boolean;
            //@ts-ignore
            data.append(`RequestDocuments[${i}].Files[${idx}].fileName`, file.name);
            // @ts-ignore
            data.append(`RequestDocuments[${i}].Files[${idx}].RequestDocumentId`, RequestDocId);
            //@ts-ignore
            data.append(`RequestDocuments[${i}].Files[${idx}].File`, isNewFile ? file : null);
            data.append(`RequestDocuments[${i}].Files[${idx}].FileDownloadUrl`, isNewFile ? null : file.fileDownloadUrl);
          });
        } else {
          data.append(`RequestDocuments[${i}].DocumentTypeId`, allFiles[index].documentTypeId);

          files.map((file: File, idx: number) => {
            let RequestDocId = allUploadedFiles[index][idx].requestDocumentId ? allUploadedFiles[index][idx].requestDocumentId : -1;

            let isNewFile = file.base64 as boolean;

            data.append(`RequestDocuments[${i}].Files[${idx}].fileName`, file.name);
            // @ts-ignore
            data.append(`RequestDocuments[${i}].Files[${idx}].RequestDocumentId`, RequestDocId);
            //@ts-ignore
            data.append(`RequestDocuments[${i}].Files[${idx}].File`, isNewFile ? file : null);
            data.append(`RequestDocuments[${i}].Files[${idx}].FileDownloadUrl`, isNewFile ? null : file.fileDownloadUrl);
          });
        }

        i = i + 1;
      }
    });

    data.append('requestId', requestId);

    return data;
  };

  const stepSixApiCall = async (payload: any) => {
    dispatch(toggleLoader(true));

    await postStepSix(payload)
      .then((response: any) => {
        if (response && response.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        }
      })
      .catch((error: any) => {
        if (error && error.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        } else if (error === 'Request is completed') {
          setError('Cererea a fost deja finalizată.');
        } else if (error && error.StatusCode && error.StatusCode === 500) {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        } else if (error && error.statusCode && error.statusCode === 500) {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const handleNextStep = () => {
    if (isEditing !== false) {
      setError('');

      if (validateRequiredDocuments()) {
        stepSixApiCall(constructPayload());
      }
    } else {
      setStep(currentStep + 1);
      currentStep === progress && setProgress(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    setStep(currentStep - 1);
  };

  const renderDocuments = () => {
    return files.map((file: any, index: number) => {
      if (file.show === true) {
        return (
          <Row className="mt-3 mb-4 document-row" key={index}>
            <Col xs={12} md={4} className="align-items-center d-flex text-col">
              <h5>
                {file.name} {file.isMandatory === true && '*'}
              </h5>
              <Tippy
                content={
                  <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                    {file.description}
                  </div>
                }
                duration={0}
                placement="bottom"
              >
                <div className="icon-div">
                  <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                </div>
              </Tippy>
            </Col>
            <Col xs={12} md={8}>
              <FileUpload
                index={index}
                onSetFileList={uploadFile}
                fileList={uploadedFiles[index]}
                disabled={isEditing === false}
                allFiles={uploadedFiles}
                acceptedExtensions={['.bmp', '.gif', '.jpg', '.jpeg', '.tiff', '.tif', '.png', '.doc', '.docx', '.pdf']}
                isLoading={toggleLoading}
                toggleLoading={(value: boolean) => setToggleLoading(value)}
              />
            </Col>
          </Row>
        );
      }
    });
  };

  return (
    <div className="prosumatori-documente m-3 w-100 d-flex align-self-center flex-column">
      <h4 className="register-section-title mt-3">Documente anexate cererii</h4>
      {files.length > 0 && renderDocuments()}
      {!isAdding && (
        <div className="d-flex mt-4 mb-4">
          <Button onClick={() => isEditing !== false && setIsAdding(true)} disabled={isEditing === false}>
            Adaugă document
          </Button>
        </div>
      )}
      {isAdding && (
        <div className="d-flex mt-4 mb-4">
          <Col xs={4} className="d-flex flex-row">
            <Select
              placeholder={'Alege tipul de document'}
              onChange={(e) => {
                handleShowExtraFiles(e.target.value);
              }}
              options={files
                .filter((f: any) => f.show === false)
                .map((file: any, index: number) => ({
                  id: file.name,
                  name: file.name
                }))}
              value={''}
              mbZero={true}
            />
            <div className="d-flex align-items-center justify-content-center">
              <span className="d-flex align-items-center justify-content-center">
                <Button onClick={() => setIsAdding(false)}>
                  <FontAwesomeIcon icon={faX} />
                </Button>
              </span>
            </div>
          </Col>
        </div>
      )}

      <p>
        Se acceptă documente în format BMP, GIF, JPG, JPEG, TIFF, TIF, PNG și fișiere DOC, DOCX, PDF. Documentele nu trebuie să depășească
        limita maximă de 10 MB/fișier.
      </p>

      {error !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{error}</p>}
      <div className="d-flex flex-row justify-content-between">
        <Button className="d-flex" onClick={handlePrevStep}>
          Pasul anterior
        </Button>
        <Button className="d-flex" onClick={handleNextStep}>
          Pasul următor
        </Button>
      </div>
    </div>
  );
};

export default DocumenteAnexate;