import { toggleLoader } from '@actions/LoaderActions';
import { getCaptcha } from '@api/captcha';
import { pvcSaveAgreement, pvcSendSmsValidationCode } from '@api/pvc';
import { CheckBox } from '@components/common/CheckBox';
import DatePicker from '@components/common/Date/DatePicker';
import { Input } from '@components/common/Input';
import MaskedInput from '@components/common/Input/MaskedInput';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { GDPR_MESSAGE } from '@constants/GDPR';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as notification from '@lib/Notification';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { PVCAgreementSchema } from '../../../helpers/schemas';
import { Signature } from '@components/common/Signature';

const AgreementPVC = (props: any) => {
  const [agreementPVC, setAgreementPVC] = useState(false);
  const [agreementGDPR, setAgreementGDPR] = useState(false);
  const [date, setDate] = useState<any>(null);
  const [clc, setClc] = useState<any>(null);

  //saved PVC id
  const [PVCId, setPVCId] = useState('');

  //phone validate
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [phoneNumberSent, setPhoneNumberSent] = useState(false);
  const [fieldPhoneNumberTouched, setFieldPhoneNumberTouched] = useState(false);

  //signature file upload
  const [fileList, setFileList] = useState<File[]>([] as File[]);
  const [isSignatureUploadFileEmpty, setIsSignatureUploadFileEmpty] = useState<any>(false);
  const [fieldSignatureUploadFileTouched, setFieldSignatureUploadFileTouched] = useState(false);

  //signature
  const [isSignatureEmpty, setIsSignatureEmpty] = useState<any>(false);
  const [isUploadSignature, setIsUploadSignature] = useState<any>(true);
  const [isUploadDocument, setIsUploadDocument] = useState<any>(false);
  const signatureComponentRef = useRef(null);

  //final modal
  const [showModalSucces, setShowModalSucces] = useState(false);

  //captcha
  const [captchaImage, setCaptchaImage] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string>('');
  const [instanceId, setInstanceId] = useState<string>('');

  //error
  const [smsCodeError, setSmsCodeError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    apiGetCaptcha();
  }, []);

  useEffect(() => {
    if (fieldSignatureUploadFileTouched) {
      if (fileList.length > 0) {
        setIsSignatureUploadFileEmpty(false);
      } else {
        setIsSignatureUploadFileEmpty(true);
      }
    }
  }, [fileList, fieldSignatureUploadFileTouched]);

  useEffect(() => {
    if (fieldPhoneNumberTouched) {
      if (!phoneNumber) {
        setPhoneNumberError('Numărul de telefon este obligatoriu.');
        return;
      } else if (!phoneNumber.match(/0\d{9}/) || phoneNumber.length != 10) {
        setPhoneNumberError('Numărul de telefon are formatul gresit.');
        return;
      } else {
        setPhoneNumberError('');
      }
    }
  }, [phoneNumber, fieldPhoneNumberTouched]);

  const submit = async (values: any) => {
    let valid = true;
    //validate values
    if (!phoneNumber) {
      setPhoneNumberError('Numărul de telefon este obligatoriu.');
      valid = false;
    } else if (!phoneNumber.match(/0\d{9}/) || phoneNumber.length != 10) {
      setPhoneNumberError('Numărul de telefon are formatul gresit.');
      valid = false;
    }
    if (!valid) return;

    let formData = new FormData();
    formData.append('PVCNumber', values.numberPVC);
    formData.append('PVCDate', values.date);
    formData.append('CNP', values.CNP);
    formData.append('VSTELLE', values.clc);
    formData.append('GDPRAgreement', agreementGDPR ? 'true' : 'false');
    formData.append('UserPhoneNumber', phoneNumber);
    formData.append('CaptchaText', captcha);
    formData.append('CaptchaInstance', instanceId);

    if (isUploadSignature) {
      //@ts-ignore
      let signature = signatureComponentRef.current.handleSave();
      if (signature) {
        signature = signature.slice(signature.lastIndexOf('base64,') + 7);
        formData.append('UserSignature', signature);
      } else {
        return;
      }
    } else {
      //upload as file
      if (fileList.length > 0) {
        fileList.map((file) => {
          formData.append('PVCDocuments', file as Blob);
        });
      } else {
        setFieldSignatureUploadFileTouched(true);
        setIsSignatureUploadFileEmpty(true);
        return;
      }
    }

    dispatch(toggleLoader(true));
    await pvcSaveAgreement(formData)
      .then((res) => {
        setPhoneNumberSent(true);
        setPVCId(res.data);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        apiGetCaptcha();
        setCaptcha('');
        if (err.response.status == 499) {
          notification.error('Codul captcha nu este valid.');
        } else if (err.response.status == 498) {
          notification.error('Locul de consum nu este pe gaze naturale.');
        } else if (err.response.status == 497) {
          notification.error('Locul de consum nu există.');
        } else notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  const apiGetCaptcha = async () => {
    dispatch(toggleLoader(true));

    await getCaptcha().then((response) => {
      setCaptchaImage(response.image);
      setInstanceId(response.imageInstanceId);
    });

    dispatch(toggleLoader(false));
  };

  const sendSmsCode = () => {
    dispatch(toggleLoader(true));

    let payload: any = { id: PVCId, smsCode: smsCode };
    pvcSendSmsValidationCode(payload)
      .then((res) => {
        if (res) {
          setSmsCodeError('');
          setShowModalSucces(true);
        } else {
          notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        }
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        try {
          if (err.status == 200 && err.data == false) {
            notification.error('Codul introdus este greșit.');
            setSmsCodeError('Codul introdus este greșit.');
          } else {
            notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
        } catch (err) {
          notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        }
        dispatch(toggleLoader(false));
      });
  };

  const handleFileListChange = (list: File[]) => {
    setFileList(list);
  };

  const validatePhoneNumberForm = () => {
    return (
      <Fragment>
        <div className="form-container">
          <div className="d-flex flex-column">
            {!phoneNumberSent ? (
              <>
                <h5 className="red">
                  Pentru a confirma corectitudinea informațiilor completate în formular vei primi un cod prin SMS la numărul de telefon:
                </h5>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <Input
                      label="Număr telefon*"
                      mbZero
                      onBlur={() => {
                        setFieldPhoneNumberTouched(true);
                      }}
                      value={phoneNumber}
                      onChange={(e: any) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{phoneNumberError}</div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h5 className="red">Introduceți codul primit prin SMS</h5>

                <Row className="w-100 mt-3">
                  <Col xs={12} md={6}>
                    <Input
                      label="Cod primit prin SMS*"
                      mbZero
                      value={smsCode}
                      onChange={(e: any) => {
                        setSmsCode(e.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent form submission
                        }
                      }}
                    />
                    <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{smsCodeError}</div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          numberPVC: '',
          date: '',
          CNP: '',
          clc: '',
          agreementPVC: ''
        }}
        onSubmit={submit}
        validationSchema={PVCAgreementSchema}
      >
        {(props) => (
          <Fragment>
            <Form>
              <div className="form-container">
                {phoneNumberSent ? (
                  <>{validatePhoneNumberForm()}</>
                ) : (
                  <>
                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h5 className="red">Identificare document</h5>

                      <Row className="w-100">
                        <Col xs={12} md={6} className="mb-3 mt-3">
                          <Field
                            label="Număr PVC*"
                            id="numberPVC"
                            name="numberPVC"
                            placeholder="Număr PVC*"
                            render={() => (
                              <Input
                                label="Număr PVC*"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('numberPVC');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('numberPVC', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('numberPVC', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="numberPVC"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col xs={12} md={6} className="mb-3 mt-3">
                          <Field
                            id="date"
                            name="date"
                            placeholder="Data*"
                            render={() => (
                              <DatePicker
                                label="Data*"
                                name="Data*"
                                selected={date ? moment(date).toDate() : null}
                                onChange={(d) => {
                                  let selectedDate = d ? moment(d).format('YYYY-MM-DD') : '';
                                  setDate(selectedDate);
                                  props.setFieldValue('date', selectedDate);
                                }}
                                mbZero
                              />
                            )}
                          />
                          <ErrorMessage name="date" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>

                      <h5 className="red">Identificare consumator</h5>

                      <Row className="w-100">
                        <Col xs={12} md={6} className="mb-3 mt-3">
                          <Field
                            label="Cod Numeric Personal (CNP)*"
                            id="CNP"
                            name="CNP"
                            placeholder="Cod Numeric Personal (CNP)*"
                            render={() => (
                              <Input
                                label="Cod Numeric Personal (CNP)*"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('CNP');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('CNP', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('CNP', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="CNP" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col xs={12} md={6} className="mb-3 mt-3">
                          <Field
                            id="clc"
                            name="clc"
                            placeholder="Loc Consum*"
                            render={() => (
                              <MaskedInput
                                value={clc}
                                mbZero
                                onChange={(e: any) => {
                                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                  e.target.value.length !== 0 && props.setFieldValue('clc', currentValue);
                                  e.target.value.length === 0 && props.setFieldValue('clc', '');
                                  setClc(currentValue);
                                }}
                                onBlur={() => {
                                  props.setFieldTouched('clc');
                                }}
                                utility="CLC"
                                label="Loc Consum*"
                                mask={'0123333333'}
                                isClc
                              />
                            )}
                          />
                          <ErrorMessage name="clc" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>

                      <h5 className="red">Decizie PVC</h5>
                      <Row className="w-100 mt-3 mb-3">
                        <CheckBox
                          checked={agreementPVC}
                          subtitle={'Sunt de acord cu cantitatea, suma recalculata si facturarea acesteia'}
                          onClick={() => {
                            setAgreementPVC(!agreementPVC);
                          }}
                        />
                        <ErrorMessage
                          name="agreementPVC"
                          render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                        />
                      </Row>

                      {agreementPVC && (
                        <Row>
                          <Col xs={12} md={4}>
                            <div className="p-3">
                              <div className={'d-flex flex-column'}>
                                <span
                                  style={{ marginRight: '25px', marginBottom: '8px', cursor: 'pointer' }}
                                  className={`eon-radio-label bg-eon-red ${isUploadSignature && 'checked'}`}
                                  onClick={() => {
                                    setIsUploadDocument(false);
                                    setIsUploadSignature(true);
                                  }}
                                >
                                  <input type="radio" className="eon-radio" name="optionGroup" style={{ marginRight: '4px' }} />
                                  <span style={{ fontSize: '18px' }}>Semnătura</span>
                                </span>
                              </div>
                              <div className={'d-flex flex-column'}>
                                <span
                                  style={{ marginRight: '25px', marginBottom: '8px', cursor: 'pointer' }}
                                  className={`eon-radio-label bg-eon-red ${isUploadDocument && 'checked'}`}
                                  onClick={() => {
                                    setIsUploadDocument(true);
                                    setIsUploadSignature(false);
                                  }}
                                >
                                  <input type="radio" className="eon-radio" name="optionGroup" style={{ marginRight: '4px' }} />
                                  <span style={{ fontSize: '18px' }}>Încarcă documentul PVC semnat (poză sau document scanat)</span>
                                </span>
                              </div>
                            </div>
                          </Col>

                          {isUploadSignature && (
                            <Col xs={12} md={8}>
                              <p className="m-0">Vă rugăm să semnați în chenarul de mai jos, folosind click-ul de la mouse</p>
                              <Signature
                                ref={signatureComponentRef}
                                height={100}
                                width={400}
                                setEmpty={(empty) => {
                                  setIsSignatureEmpty(empty);
                                }}
                              />
                              <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
                                {isSignatureEmpty && 'Semnătura este obligatorie.'}
                              </div>
                            </Col>
                          )}
                          {isUploadDocument && (
                            <Col xs={12} md={6} className="mb-2">
                              <p className="m-0">Document</p>
                              <UploadFile
                                onSetFileList={handleFileListChange}
                                fileList={fileList}
                                index={0}
                                uploadedFilesLimit={3}
                                acceptedExtensions={['.jpg', '.jpeg', '.png', '.pdf', '.tif', '.tiff']}
                              />

                              <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
                                {isSignatureUploadFileEmpty && 'Documentul este obligatoriu.'}
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}

                      <h5 className="red mt-3">Acord de prelucrare a datelor</h5>
                      <Row className="w-100 mt-3">
                        <CheckBox
                          checked={agreementGDPR}
                          subtitle={GDPR_MESSAGE}
                          onClick={() => {
                            setAgreementGDPR(!agreementGDPR);
                          }}
                        />
                      </Row>

                      <Row className="mt-3 mb-3">
                        <Col xs={12} sm={10} className="flex-row">
                          {captchaImage !== null && <img src={`data:image/jpeg;base64, ${captchaImage}`} alt="captcha" />}
                          <FontAwesomeIcon
                            icon={faArrowRotateRight}
                            color="red"
                            className="mt-3 pointer"
                            style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                            onClick={() => apiGetCaptcha()}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={4}>
                          <Input label="Introdu codul de securitate" value={captcha} onChange={(e) => setCaptcha(e.target.value)} />
                        </Col>
                      </Row>

                      {validatePhoneNumberForm()}
                    </div>
                  </>
                )}

                {
                  <Row className="w-100 mt-1">
                    <Col md={6}>
                      {phoneNumberSent ? (
                        <Button type="button" onClick={() => sendSmsCode()}>
                          Trimite
                        </Button>
                      ) : (
                        <Button
                          disabled={!agreementGDPR || !captcha || !agreementPVC}
                          type="submit"
                          onKeyDown={(event) => event.preventDefault()}
                        >
                          Salvează
                        </Button>
                      )}
                    </Col>
                  </Row>
                }
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>

      <>
        <ModalConfirm
          modalTitle={'Succes!'}
          showModal={showModalSucces}
          onClose={() => {
            setShowModalSucces(false);
            window.location.href = '/serviciul-de-informare/intrebari-frecvente';
          }}
        >
          <h5>Am înregistrat acordul tău și vom începe procesarea informațiilor în scopul emiterii facturii aferente procesului verbal.</h5>
        </ModalConfirm>
      </>
    </Fragment>
  );
};

export default AgreementPVC;
