import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import InregistrareRSS from '@components/rss/inregistrareRSS/InregistrareRSS';

export const InregistrareRSSFurnizor = () => {
    return (
        <DelgazWrapper>
            <InregistrareRSS />
        </DelgazWrapper>
    );
};