import rootReducer from '@reducers/index';
import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../sagas/index';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (a: any) => a;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
// export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware, logger)));

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;
