import React, { useState } from 'react';
import { Image } from 'react-bootstrap';

const SocialMediaMenu = (props: any) => {
    const kentico = props.kentico;
    const [hoveredKey, setHoveredKey] = useState(-1);

    const handleHover = (value: number) => {
        setHoveredKey(value);
    };

    return (
        <div>
            <h5>Social Media</h5>
            <div className="d-flex">
                {kentico.items.map((item: any, key: number) => {
                    let src = '';
                    if(item.iconOnHover) {
                        src = hoveredKey == key ? item.iconOnHover : item.icon;
                    } else {
                        src = item.icon;
                    }
                    return (
                        <a className="ml-1" key={key} href={item.link}
                        onMouseEnter={() => handleHover(key)}
                        onMouseLeave={() => handleHover(-1)}>
                            <Image className="thumbnail-social-icon" src={src} thumbnail={true} />
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default SocialMediaMenu;
