import { toggleLoader } from '@actions/LoaderActions';
import { getAnexaStepSeven, postAnexaStepSeven } from '@api/revizii/revizii';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type RVStepSevenProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  idAnexa: string;
};

type StepSevenType = {
  id_anexa: number;
  utilizare_conforma: boolean;
  utilizare_conforma_id: number;
  utilizare_conforma_motiv: string;
};

const RVStepSeven = (props: RVStepSevenProps) => {
  const dispatch = useDispatch();
  const { setStep, currentStep, progress, setProgress } = props;

  const [tipuriNeconformitati, setTipuriNeconformitati] = useState<DropdownOptionType[]>([]);

  const [instalatiaIndeplinesteConditiile, setInstalatiaIndeplinesteConditiile] = useState<any>(null);
  const [tipNeconformitate, setTipNeconformitate] = useState<any>('');
  const [motiv, setMotiv] = useState('');

  //errors
  const [error, setError] = useState('');
  const [errorMotiv, setErrorMotiv] = useState(false);
  const [errorInstalatiaIndeplinesteConditiile, setErrorInstalatiaIndeplinesteConditiile] = useState(false);
  const [errorTipNeconformitateFisaRvRules, setErrorTipNeconformitateFisaRvRules] = useState(false);

  useEffect(() => {
    if (props.idAnexa) {
      dispatch(toggleLoader(true));
      getAnexaStepSeven(props.idAnexa)
        .then((res) => {
          dispatch(toggleLoader(false));
          initializeData(res);
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
        });
    }
  }, []);

  useEffect(() => {
    if (instalatiaIndeplinesteConditiile) {
      setTipNeconformitate('');
    }
  }, [instalatiaIndeplinesteConditiile]);

  useEffect(() => {
    if (tipNeconformitate != '2') {
      setMotiv('');
    }
  }, [tipNeconformitate]);

  useEffect(() => {
    if (props.dictionare) {
      setTipuriNeconformitati(props.dictionare.NonconformityType);
    }
  }, []);

  useEffect(() => {
    if (instalatiaIndeplinesteConditiile != null) {
      setErrorInstalatiaIndeplinesteConditiile(false);
    }
    if (tipNeconformitate) {
      setErrorTipNeconformitateFisaRvRules(false);
    }
    if (motiv) {
      setErrorMotiv(false);
    }
  }, [motiv, instalatiaIndeplinesteConditiile, tipNeconformitate]);

  const initializeData = (values: StepSevenType) => {
    setInstalatiaIndeplinesteConditiile(values.utilizare_conforma);
    setTipNeconformitate(values.utilizare_conforma_id);
    setMotiv(values.utilizare_conforma_motiv);
  };

  const submit = () => {
    if (validateValues()) {
      dispatch(toggleLoader(true));
      let payload: any = {
        utilizare_conforma: instalatiaIndeplinesteConditiile,
        utilizare_conforma_id: tipNeconformitate ? tipNeconformitate : null,
        utilizare_conforma_motiv: motiv
      };

      if (props.idAnexa) {
        payload.id_anexa = props.idAnexa;
      }

      postAnexaStepSeven(payload)
        .then((res) => {
          props.setStep(props.currentStep + 1);
          props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            props.setStep(props.currentStep + 1);
            props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const validateValues = () => {
    let valid = true;

    if (instalatiaIndeplinesteConditiile == null) {
      setErrorInstalatiaIndeplinesteConditiile(true);
      valid = false;
    }

    if (!instalatiaIndeplinesteConditiile) {
      if (!tipNeconformitate) {
        setErrorTipNeconformitateFisaRvRules(true);
        valid = false;
      }
      if (tipNeconformitate == '2') {
        if (!motiv) {
          setErrorMotiv(true);
          valid = false;
        }
      }
    }

    return valid;
  };

  return (
    <Fragment>
      <h4 className="red mt-2 mb-3">Concluzii</h4>

      <Row className="mb-3">
        <p className="p-gray m-0">
          Instalaţia de utilizare a gazelor naturale îndeplineşte condiţiile tehnice de funcţionare în siguranţă prevăzute în prevederile
          normelor tehnice din domeniul gaze naturale*
        </p>
      </Row>

      <Row>
        <Col xs={12} md={3}>
          <div className="d-flex">
            <span onClick={() => setInstalatiaIndeplinesteConditiile(true)} className="pointer">
              <input
                className="custom-radio-button pointer"
                type="radio"
                value={1}
                checked={instalatiaIndeplinesteConditiile == true}
                onChange={() => setInstalatiaIndeplinesteConditiile(true)}
                style={{ marginRight: '6px' }}
              />
              <span className="fw-bold">Da</span>
            </span>
            <span onClick={() => setInstalatiaIndeplinesteConditiile(false)} className="pointer ms-2">
              <input
                className="custom-radio-button pointer"
                type="radio"
                value={1}
                checked={instalatiaIndeplinesteConditiile == false}
                onChange={() => setInstalatiaIndeplinesteConditiile(false)}
                style={{ marginRight: '6px' }}
              />
              <span className="fw-bold">Nu</span>
            </span>
          </div>
          {errorInstalatiaIndeplinesteConditiile && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
        </Col>

        {instalatiaIndeplinesteConditiile == false && (
          <Col xs={12} md={9}>
            <Row>
              <Col xs={12} md={6} className="mt-3">
                <Dropdown
                  options={tipuriNeconformitati as DropdownOptionType[]}
                  label={'Tip neconformitate*'}
                  defaultPlaceholder={'Tip neconformitate'}
                  displaySearch={true}
                  onChange={(value) => {
                    setTipNeconformitate(value);
                  }}
                  mbZero
                  value={tipNeconformitate}
                />
                {errorTipNeconformitateFisaRvRules && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
              </Col>
              <Col xs={12} md={6} className="mt-3">
                {tipNeconformitate == '2' && (
                  <>
                    <Input
                      label="Motiv*"
                      mbZero
                      value={motiv}
                      onChange={(e: any) => {
                        setMotiv(e.target.value);
                      }}
                    />
                    {errorMotiv && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul Motiv este obligatoriu.</div>}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {error && (
        <div className="mt-3" style={{ color: 'red', fontWeight: 'bold' }}>
          {error}
        </div>
      )}

      <StepsNavigationButtons
        className="mb-3"
        onClickPrevious={() => {
          setStep(currentStep - 1);
          currentStep === progress && setProgress(currentStep - 1);
        }}
        onClickNext={() => {
          submit();
        }}
      />
    </Fragment>
  );
};

export default RVStepSeven;
