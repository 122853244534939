import React from 'react';
import { SliderKentico } from '../../typescript/kentico-types';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { CarouselSlider } from '../../components/static/Carousel/CarouselSlider';

export type SliderListProps = { kentico: SliderKentico };

export const Slider = (props: SliderListProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <CarouselSlider {...props} />
    </DelgazWrapper>
  );
};
