import React from 'react';
import Slider from 'react-slick';
import CardComponent from '@components/dev/Components/Card/Card';
import { Row, Col } from 'react-bootstrap';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Cards = (props: any) => {
  const kentico = props.kentico;

  let size: string = kentico.items ? kentico.items[0].size : 'M';
  let isLarge: boolean = size === 'L';

  const settings = {
    dots: false,
    infinite: kentico.items.length > 3,
    speed: 500,
    slidesToShow: isLarge ? 3 : 4,
    slidesToScroll: 1,
    nextArrow: <FontAwesomeIcon icon={faCaretRight} />,
    prevArrow: <FontAwesomeIcon icon={faCaretLeft} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: isLarge ? 2 : 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: isLarge ? 1 : 2,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <div style={{ position: 'relative' }} className="mb-3">
      <div
        className={`container delgaz-carousel position-relative cards-container ${
          isLarge ? 'card-container-large' : 'card-container-small'
        } ${size === 'S' && 'card-container-extra-small'}`}
      >
        <Row>
          <Col md={12}>
            <h2 className="text-center brand-title"> {kentico.title}</h2>
            <Slider {...settings}>
              {kentico.items.map((cardItem: any, key: number) => {
                return (
                  <CardComponent
                    key={key}
                    title={cardItem.title}
                    description={cardItem.description}
                    linkUrl={cardItem.linkUrl ?? null}
                    linkText={cardItem.linkText ?? null}
                    linkTargetBlank={cardItem.openNewTab ?? false}
                    img={cardItem.image}
                  />
                );
              })}
            </Slider>
          </Col>
        </Row>
      </div>
    </div>
  );
};
