import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import {AdCardGoogleMapsComponent} from '@components/dev/Components/AdCard/AdCardGoogleMaps';
import {AdCardGoogleMapsKentico} from '@type/kentico-types';

type AdCardGoogleMapsProps = {
    kentico: AdCardGoogleMapsKentico
}

export const AdCardGoogleMaps = (props: AdCardGoogleMapsProps) => {
    return (
        <DelgazWrapper>
            <AdCardGoogleMapsComponent
                containerElement={<div style={{
                    height: '400px',
                    width: '100%',
                    position: 'relative'
                }}/>}
                mapElement={<div style={{height: '100%'}}/>}
                googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyAXE_ITlrBwfEB9xZR8BBTFryzQVvSAeko&v=3.exp&libraries=geometry,drawing,places'}
                loadingElement={<div style={{height: '100%'}}/>}
                {...props}
            />
        </DelgazWrapper>
    );
};