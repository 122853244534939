import React, { createContext, Component } from 'react';
import { isClientAuth, isExpiredToken } from '@lib/Auth';
import { history } from '@lib/history';

export type AuthenticationContextType = {
  isLoading: boolean;
  isAuthenticated: boolean;
  isTokenExpired: boolean;
  isBackOfficeSection: boolean;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({} as AuthenticationContextType);

type AuthenticationProps = {
  children?: React.ReactNode;
};

type AuthenticationState = {
  isInitialized: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  isTokenExpired: boolean;
  isBackOfficeSection: boolean;
};

export class AuthenticationProvider extends Component<AuthenticationProps, AuthenticationState> {
  interval!: NodeJS.Timeout;

  state: AuthenticationState = {
    isInitialized: false,
    isLoading: true,
    isAuthenticated: false,
    isTokenExpired: false,
    isBackOfficeSection: false
  };

  async componentDidMount() {
    await this._setAuthenticated();
    await this._setIsBackOffice();

    this.interval = setInterval(() => {
      this._setAuthenticated();
    }, 300);

    return this.setState({ isInitialized: true });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  _setIsBackOffice = async () => {
      if(history.location.pathname.includes('/backoffice')) await this.setState({ isBackOfficeSection: true });
  };

  _setAuthenticated = async () => {
    const isAuthenticated = !!isClientAuth();
    const isTokenExpired = !!isExpiredToken();

    if (isAuthenticated !== this.state.isAuthenticated) await this.setState({ isAuthenticated });
    if (isTokenExpired !== this.state.isTokenExpired) await this.setState({ isTokenExpired });

    return undefined;
  };

  render() {
    const { isInitialized, isLoading, isAuthenticated, isTokenExpired, isBackOfficeSection } = this.state;
    const { children } = this.props;

    const configObject: AuthenticationContextType = {
      isLoading,
      isAuthenticated,
      isTokenExpired,
      isBackOfficeSection
    };

    if (isInitialized) {
      return <AuthenticationContext.Provider value={configObject}>{children}</AuthenticationContext.Provider>;
    } else {
      return null;
    }
  }
}
