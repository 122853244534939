import { proxy, Proxy } from '@api/azureProxy';
import { store } from '@lib/store';

export async function getRomcardPayloadEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/iconnect/requestconsumer/romcard-payload?invoiceId=${payload.invoiceId}&RequestId=${payload.requestId}`,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function getRomcardPayloadGN(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/iconnect/requestGN/romcard-payload?invoiceId=${payload.invoiceId}&RequestId=${payload.requestId}`,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function postRomcardResponse(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/iconnect/request/romcard-response',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}
