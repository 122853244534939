import React from 'react';

export type SliderItem = {
  title: string;
  description: string;
  linkText: string;
  linkUrl: string;
  img?: string;
  textColor?: string;
};
export type state = {};

class Item extends React.Component<SliderItem, state> {

  render() {

    let style = this.props.textColor ? {color: this.props.textColor} : {};

    return (
      <div className="full-page-slider position-relative">
        <img
          className="d-block w-100"
          src={this.props.img}
          alt="First slide"
          style={{filter: 'brightness(50%)'}}
        />
        <div className="carousel-item-caption  position-absolute d-flex align-items-start justify-content-center flex-wrap flex-column">
          <h3 style={style}>{this.props.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: this.props.description }}/>
          {this.props.linkUrl && this.props.linkText && (<><a href={this.props.linkUrl} className="btn btn-md btn-primary border-rounded">
              <div className="card-link-wrapper">{this.props.linkText}</div></a></>)}
        </div>
      </div>
    );
  }
}

export default Item;
