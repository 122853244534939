import { OferteAchizitieKentico } from '@type/kentico-types';
import moment from 'moment';
import { useEffect } from 'react';

const Oferte = (props: {kentico: OferteAchizitieKentico}) => {
    const item = props.kentico;

    useEffect(() => {
        const result = Object.values(item);
        console.log(result);
    }, []);

    return (
        <div className="container-oferte mt-4 p-3">
            <h5 style={{fontSize: '29px'}} className="red">Oferte:</h5>
            { item && Object.values(item).map((oferta: any, index: number) => (
                <div className="mb-4 p-3 bg-eon-lightgrey">
                    <div className="d-flex justify-content-between">
                        <p>{oferta.BlobGroup} {oferta.UserName}</p>
                        <span>{moment(oferta.BlobDate).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </div>
                    <div>
                        {oferta.DownloadUrls.map((url: any, idx: number) => (
                            <span>
                                <a href={url.DownloadUrl} download className="red hover-red">{url.FileName}</a>
                                <br></br>
                            </span>
                        ))}
                    </div>
                </div>
            )) }
        </div>
    );
};

export default Oferte;