import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getTypes } from '@api/prosumatori/prosumatori';
import { Input } from '@components/common/Input';

const columns = [
  { name: 'Nr. crt.', width: '40px' },
  { name: 'Date energetice ale consumatorului', width: 'auto' },
  { name: '', width: '60px' },
  { name: 'U.M.', width: '60px' },
  { name: 'Situatia existenta', width: 'auto' },
  { name: 'Puterea ceruta pe ani', width: 'auto' },
  { name: 'Situatia finala', width: 'auto' },
  { name: 'Obs', width: 'auto' }
];

const DetaliiReceptoare = (props: { detalii: any; handleDetalii: any; isEditing: any }) => {
  const [structure, setStructure] = useState(null);
  const { detalii, handleDetalii, isEditing } = props;

  useEffect(() => {
    getOptionTypes(['ReceiverEnergyDetailTypes']).then((res) => {
      console.log(res);
      setStructure(res);
    });
  }, []);

  const getOptionTypes = async (keys: string[]) => {
    return await getTypes(keys).then((res: any) => {
      return res[keys[0]];
    });
  };

  const renderInputs = (index: number) => {
    return (
      <Fragment>
        <td className="p-2">
          <input
            value={detalii[index].situatie_curenta}
            onChange={(e) => handleDetalii(index, 'situatie_curenta', e.target.value)}
            disabled={isEditing === false}
          />
        </td>
        <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
          <input value={detalii[index].an1} onChange={(e) => handleDetalii(index, 'an1', e.target.value)} disabled={isEditing === false} />
        </td>
        <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
          <input value={detalii[index].an2} onChange={(e) => handleDetalii(index, 'an2', e.target.value)} disabled={isEditing === false} />
        </td>
        <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
          <input value={detalii[index].an3} onChange={(e) => handleDetalii(index, 'an3', e.target.value)} disabled={isEditing === false} />
        </td>
        <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
          <input value={detalii[index].an4} onChange={(e) => handleDetalii(index, 'an4', e.target.value)} disabled={isEditing === false} />
        </td>
        <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
          <input value={detalii[index].an5} onChange={(e) => handleDetalii(index, 'an5', e.target.value)} disabled={isEditing === false} />
        </td>
        <td className="p-2">
          <input
            value={detalii[index].situatie_finala}
            onChange={(e) => handleDetalii(index, 'situatie_finala', e.target.value)}
            disabled={isEditing === false}
          />
        </td>
        <td className="p-2">
          <input
            value={detalii[index].observatii}
            onChange={(e) => handleDetalii(index, 'observatii', e.target.value)}
            disabled={isEditing === false}
          />
        </td>
      </Fragment>
    );
  };

  const renderRows = (structure: any) => {
    return structure.map((row: any, index: number) => {
      return row.grup.map((grup: any, idx: number) => {
        return grup.subgrup.map((subgrup: any, i: number) => {
          if (i !== grup.subgrup.length - 1 && grup.subgrup.length > 1) {
            return (
              <tr className="case-1">
                <td className="p-2" style={{ minWidth: 'unset', borderTopWidth: '0', borderBottomWidth: '0' }}>
                  {subgrup.id}
                </td>
                {grup.nume === null ? 
                  <td className="p-2" style={{ borderBottomWidth: '0' }}>{idx === 0 && row.nume}</td> :
                  <td className="p-2" style={{ borderTopWidth: '0', borderBottomWidth: '0' }}>
                    <Row>
                      <Col xs={6}>{idx === 0 && row.nume}</Col>
                      <Col xs={6} className="d-flex align-items-center">
                        <Input 
                          label={grup.nume} 
                          value={detalii[subgrup.id].putere_instalata_absorbita} 
                          onChange={(e) => handleDetalii(subgrup.id, 'putere_instalata_absorbita', e.target.value)}
                          mbZero 
                        />
                      </Col>
                    </Row>
                  </td>
                }
                <td className="p-2">
                  {subgrup.nume}
                </td>
                <td className="p-2">
                  {subgrup.um}
                </td>
                {renderInputs(subgrup.id - 1)}
              </tr>
            );
          } else
            return (
              <tr className="case-2">
                <td className="p-2" style={{ minWidth: 'unset' }}>
                  {subgrup.id}
                </td>
                <td className="p-2" style={grup.nume === null ? { borderTopWidth: '0' } : { borderTopWidth: '0', borderBottomWidth: '0' }}>{i === 0 && row.nume}</td>
                <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
                  {subgrup.nume}
                </td>
                <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
                  {subgrup.um}
                </td>
                {renderInputs(subgrup.id - 1)}
              </tr>
            );
        });
      });
    });
  };

  return (
    <div className="d-flex flex-column mb-4 overflow-auto">
      <table className="dynamic-table">
        <thead>
          <tr style={{ height: '25px' }}>
            {columns.map((column: any, index: number) => {
              return (
                <th
                  style={{ border: '1px solid red', width: column.width }}
                  className="p-2 text-center"
                  key={index}
                  colSpan={index === 5 ? 5 : 1}
                >
                  <span style={index === 1 ? { width: '300px', display: 'block' } : { width: 'unset' }}>{column.name}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        {/* <tbody>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}></td>
            <td className="p-2"></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2"></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an1
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an2
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an3
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an4
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an5
            </td>
            <td className="p-2"></td>
            <td className="p-2"></td>
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              1
            </td>
            <td className="p-2">Puterea absorbita in orele de varf de sarcina</td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(0)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              2
            </td>
            <td className="p-2" style={{ border: 'none' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Schimb 1
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              %
            </td>
            {renderInputs(1)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              3
            </td>
            <td className="p-2" style={{ borderTopWidth: '0', borderBottomWidth: '0' }}>
              Puterea absorbita pe schimburi (% din Pmax)
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Schimb 2
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              %
            </td>
            {renderInputs(2)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              4
            </td>
            <td className="p-2" style={{ border: 'none' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Schimb 3
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              %
            </td>
            {renderInputs(3)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              5
            </td>
            <td className="p-2" style={{ borderBottomWidth: '0' }}>
              Puterea receptoarelor cu regim de socuri
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pinst
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(4)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              6
            </td>
            <td className="p-2" style={{ borderTopWidth: '0' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pabs
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(5)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              7
            </td>
            <td className="p-2">Durata socului</td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              secunde
            </td>
            {renderInputs(6)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              8
            </td>
            <td className="p-2">Frecventa socuri</td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              socuri / min
            </td>
            {renderInputs(7)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              9
            </td>
            <td className="p-2">Amplitudinea maxima a socului</td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              A
            </td>
            {renderInputs(8)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              10
            </td>
            <td className="p-2" style={{ borderBottomWidth: '0' }}>
              Puterea receptoarelor cu regim deformant
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pinst
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(9)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              11
            </td>
            <td className="p-2" style={{ borderTopWidth: '0' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pabs
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(10)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              12
            </td>
            <td className="p-2" style={{ borderBottomWidth: '0' }}>
              Puterea receptoarelor cu regim nesimetric
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pinst
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(11)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              13
            </td>
            <td className="p-2" style={{ borderTopWidth: '0' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pabs
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(12)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              14
            </td>
            <td className="p-2" style={{ borderBottomWidth: '0' }}>
              <Row className="justify-content-between">
                <Col xs={5}></Col>
                <Col xs={5}>
                  <span>U1=...kV</span>
                  <input
                    value={detalii[13].putere_instalata_absorbita}
                    onChange={(e) => handleDetalii(13, 'putere_instalata_absorbita', e.target.value)}
                    disabled={isEditing === false}
                  />
                </Col>
              </Row>
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pinst
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(13)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              15
            </td>
            <td className="p-2" style={{ borderTopWidth: '0', borderBottomWidth: '0' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pabs
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(14)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              16
            </td>
            <td className="p-2" style={{ borderTopWidth: '0', borderBottomWidth: '0' }}>
              <Row className="justify-content-between">
                <Col xs={5}>Puterea instalata si absorbita la tensiunile de utilizare a energiei electrice</Col>
                <Col xs={5}>
                  <span>U2=...kV</span>
                  <input
                    value={detalii[15].putere_instalata_absorbita}
                    onChange={(e) => handleDetalii(15, 'putere_instalata_absorbita', e.target.value)}
                    disabled={isEditing === false}
                  />
                </Col>
              </Row>
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pinst
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(15)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              17
            </td>
            <td className="p-2" style={{ borderTopWidth: '0', borderBottomWidth: '0' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pabs
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(16)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              18
            </td>
            <td className="p-2" style={{ borderTopWidth: '0', borderBottomWidth: '0' }}>
              <Row className="justify-content-between">
                <Col xs={5}></Col>
                <Col xs={5}>
                  <span>U3=...kV</span>
                  <input
                    value={detalii[17].putere_instalata_absorbita}
                    onChange={(e) => handleDetalii(17, 'putere_instalata_absorbita', e.target.value)}
                    disabled={isEditing === false}
                  />
                </Col>
              </Row>
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pinst
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(17)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              19
            </td>
            <td className="p-2" style={{ borderTopWidth: '0' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pabs
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(18)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              20
            </td>
            <td className="p-2" style={{ borderBottomWidth: '0' }}>
              CET propriu
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Pinst
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MW
            </td>
            {renderInputs(19)}
          </tr>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}>
              21
            </td>
            <td className="p-2" style={{ borderTopWidth: '0' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              Wanulata
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              MWh
            </td>
            {renderInputs(20)}
          </tr>
        </tbody> */}
        <tbody>
          <tr>
            <td className="p-2" style={{ minWidth: 'unset' }}></td>
            <td className="p-2"></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}></td>
            <td className="p-2"></td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an1
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an2
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an3
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an4
            </td>
            <td className="p-2" style={{ maxWidth: '75px', minWidth: '75px' }}>
              an5
            </td>
            <td className="p-2"></td>
            <td className="p-2"></td>
          </tr>
          {structure && renderRows(structure)}
        </tbody>
      </table>
    </div>
  );
};

export default DetaliiReceptoare;
