import React from 'react';
// import { AemWrapper } from '@components/global';
import FixedMenu from '@components/delgaz/Menu/FixedMenu';
import { FixedMenuKentico } from '../../typescript/kentico-types';
import { DelgazWrapper } from '@components/global/DelgazWrapper';

export type MylineMenuUserCompProps = { kentico: FixedMenuKentico };

export class FixedMenuComponent extends React.Component<MylineMenuUserCompProps> {
  render() {
    return (
      <DelgazWrapper>
        <FixedMenu {...this.props} />
      </DelgazWrapper>
    );
  }
}
