import {Proxy, proxy} from '@api/azureProxy';
import {store} from '@lib/store';

export function adressByPOD(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/masterdata/ConsumptionPoint/address_by_pod',
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
            pod: payload.pod,
            countyCode: payload.countyCode,
            localityCode: payload.localityCode,
        },
        headers: {
            'Authorization': 'Bearer ' + state.auth.token
        }
    });
}

export function adressByCLC(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/masterdata/ConsumptionPoint/address_by_clc',
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
            clc: payload.clc,
            countyCode: payload.countyCode,
            localityCode: payload.localityCode,
        },
        headers: {
            'Authorization': 'Bearer ' + state.auth.token
        }
    });
}

export function verifyPODCountyLocality(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/masterdata/ConsumptionPoint/verify',
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
            pod: payload.pod,
            countyCode: payload.countyCode,
            localityCode: payload.localityCode,
            metterTypes: payload.metterTypes
        },
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}