import { store } from '@lib/store';
import axios from 'axios';
import { Proxy, proxy } from '@api/azureProxy';

export async function postIncarcareFisaNoua(payloadFormData: any) {
  const state = store.getState();

  return axios.post(`${state.options.apiHost}/maintenance/revision/add`, payloadFormData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
      CommunicationSystem: 'Portal'
    }
  });
}

export function getTipuriRevizii(revisionType: string, types: string[]) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/maintenance/DataType/get_types?revisionType=${revisionType}`,
    data: types,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function getRevizii(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/revision/list',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10,
      sortBy: payload.sortBy,
      sortType: payload.sortType,
      consumptionPlace: payload.consumptionPlace,
      oe: payload.oe,
      createdDate: payload.createdDate,
      eraNumber: payload.eraNumber
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function revisionRetry(revisionId: string | number) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/revision/retry',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      revisionId: revisionId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getExportRevizii(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/revision/export',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      consumptionPlace: payload.consumptionPlace,
      oe: payload.oe,
      createdDate: payload.createdDate,
      eraNumber: payload.eraNumber
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    },
    responseType: 'arraybuffer'
  });
}

export function getExportReviziiAnexa6(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/revision/anexa6',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      startDate: payload.startDate,
      endDate: payload.endDate
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    },
    responseType: 'arraybuffer'
  });
}

export function postAnexaStepOne(payload: any) {
  const state = store.getState();
  return axios.post(`${state.options.apiHost}/maintenance/annexe/step1`, payload, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function postAnexaStepTwo(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/maintenance/annexe/step2',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postAnexaStepThree(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/maintenance/annexe/step3',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postAnexaStepFour(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/maintenance/annexe/step4',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postAnexaStepFive(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/maintenance/annexe/step5',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postAnexaStepSix(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/maintenance/annexe/step6',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postAnexaStepSeven(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/maintenance/annexe/step7',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export async function postAnexaStepEight(payloadFormData: any) {
  const state = store.getState();

  return axios.post(`${state.options.apiHost}/maintenance/annexe/step8`, payloadFormData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function getExportAnexa(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/maintenance/annexe/export-annexe?annexeId=${annexeId}`,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    },
    responseType: 'arraybuffer'
  });
}

export function getListAnexe(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/list',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10,
      sortBy: payload.sortBy,
      sortType: payload.sortType
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepOne(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step1',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepTwo(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step2',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepThree(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step3',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepFour(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step4',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepFive(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step5',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepSix(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step6',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepSeven(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step7',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getAnexaStepEight(annexeId: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/annexe/step8',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function deleteAnexa(annexeId: number) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'DELETE',
    endPoint: '/maintenance/annexe/delete',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      annexeId: annexeId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'DELETE'
    }
  });
}

export async function postBulkImport(payloadFormData: any) {
  const state = store.getState();

  return axios.post(`${state.options.apiHost}/maintenance/bulk/import`, payloadFormData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function getBulkImportList(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/bulk/import_list',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10
      // sortBy: payload.sortBy,
      // sortType: payload.sortType
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getBulkImportDetails(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/bulk/import_details',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    params: {
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10,
      sortBy: payload.sortBy,
      sortType: payload.sortType,
      statusId: payload.statusId,
      bulkImportId: payload.bulkImportId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function postBulkConfirmImport(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/maintenance/bulk/confirm_import',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function getBulkExportEntriesByStatus(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/bulk/export_bulk_entries_by_status',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      bulkId: payload.bulkId,
      statusId: payload.statusId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    },
    responseType: 'arraybuffer'
  });
}

export function deleteBulkImportEntry(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'DELETE',
    endPoint: '/maintenance/bulk/delete_bulk_import_entry',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      bulkImportEntryId: payload.bulkImportEntryId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'DELETE'
    }
  });
}

export function getBulkExportEntry(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/maintenance/bulk/get_entry',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      bulkEntryId: payload.bulkEntryId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function updateBulkExportEntry(payloadFormData: any) {
  const state = store.getState();
  return axios.post(`${state.options.apiHost}/maintenance/bulk/update_entry`, payloadFormData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function getDuplicateRevision(consumptionPoint: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/maintenance/revision/duplicate_revision?consumptionPoint=${consumptionPoint}`,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}
