import React, { FunctionComponent } from 'react';
import { OverlayImageKentico } from '@type/kentico-types';
import OverlayItem from './OverlayItem';

export type OverlayProps = { kentico: OverlayImageKentico };
const Overlay: FunctionComponent<OverlayProps> = (props) => {

  return (
    <OverlayItem {...props.kentico}/>
  );
};

export default Overlay;
