import React from 'react';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';

let captcha_value = '';
let captcha_number = '';
let backgroundColor_value = '';
let fontColor_value = '';
let charMap_value = '';
let id = '';
//eslint-disable-next-line
let LoadCanvasTemplate_HTML = '<div><canvas id=' + id + '></canvas><div><a id="reload_href"  style="cursor: pointer; color: blue">Reload Captcha</a></div></div>';
//eslint-disable-next-line
let LoadCanvasTemplateNoReload_HTML = '<div><canvas id=' + id + '></canvas><div><a id="reload_href"  style="cursor: pointer; color: blue"></a></div></div>';;


export const loadCaptchaEngine = (numberOfCharacters: string, backgroundColor = 'white', fontColor = 'black', charMap = '', id: string) => {

    backgroundColor_value = backgroundColor;
    fontColor_value = fontColor;
    charMap_value = charMap;
    captcha_number = numberOfCharacters;
    let retVal = '';
    let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    if (charMap === 'upper') {
        charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    } else if (charMap === 'lower') {
        charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    }
    else if (charMap === 'numbers') {
        charset = '0123456789';
    }
    else if (charMap === 'special_char') {
        //eslint-disable-next-line
        charset = "~`!@#$%^&*()_+-=[]{}\|:'<>,.?/";
    }

    let length = parseInt(numberOfCharacters);



    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    let captcha = retVal;

    captcha_value = captcha;


    // @ts-ignore
    let length_height_canvas = Math.round(parseInt(length) / 3);

    let canvas = document.getElementById(id) as HTMLCanvasElement;
    let ctx = canvas.getContext('2d');
    //eslint-disable-next-line
    let img = document.getElementById('image');
    let text = captcha;
    //eslint-disable-next-line
    let x = 12.5;
    //eslint-disable-next-line
    let y = 15;
    let lineheight = 30;

    // @ts-ignore
    //eslint-disable-next-line
    let canvas_height = (parseInt(length) - parseInt(length_height_canvas)) * 20;
    let lines = text.split('\n');
    //eslint-disable-next-line
    let lineLengthOrder = lines.slice(0).sort(function (a, b) {
        return b.length - a.length;
    });

    // @ts-ignore
    ctx.canvas.width = parseInt(length) * 25;
    // @ts-ignore
    ctx.canvas.height = (lines.length * lineheight);

    // @ts-ignore
    ctx.fillStyle = backgroundColor;
    // @ts-ignore
    ctx.fillRect(0, 0, canvas?.width, canvas?.height);


    // @ts-ignore
    ctx.textBaseline = 'middle';

    // @ts-ignore
    ctx.font = 'italic 20px Arial';
    // @ts-ignore
    ctx.fillStyle = fontColor;




    let num = 0;
    // @ts-ignore
    for (let i = 0; i < parseInt(length); i++) {
        // @ts-ignore
        num = parseInt(num) + 1;
        let heigt_num = 20 * num;
        // @ts-ignore
        ctx.fillText(retVal[i], heigt_num, Math.round(Math.random() * (15 - 12) + 12));
    }

    // @ts-ignore
    document.getElementById('reload_href').onclick = function () {
        loadCaptchaEngine(captcha_number, backgroundColor, fontColor, charMap, id);
    };

};

// @ts-ignore
export const validateCaptcha = (userValue, reload = true, id: string) => {
    if (userValue != captcha_value) {
        if (reload == true) {
            loadCaptchaEngine(captcha_number, backgroundColor_value, fontColor_value, charMap_value, id);
        }

        return false;
    }

    else {
        return true;
    }
};

type Props = { id: string };
export class LoadCanvasTemplateNoReload extends React.Component<Props> {

    render() {


        let LoadCanvasTemplateNoReload_HTML = '<div><canvas id=' + this.props.id + '></canvas><div><a id="reload_href"  style="cursor: pointer; color: blue"></a></div></div>';;

        return (ReactHtmlParser(LoadCanvasTemplateNoReload_HTML));
    }

};
