import { toggleLoader } from '@actions/LoaderActions';
import { adressByCLC, adressByPOD } from '@api/consumptionPoint';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { getDuplicateRevision, getTipuriRevizii } from '@api/revizii/revizii';
import { getEconomicOperator } from '@api/user/economicOperator';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import MaskedInput from '@components/common/Input/MaskedInput';
import Steps from '@components/common/Steps/Steps';
import { stepsArrayRevizii, stepsArrayVerificari } from '@constants/revizii/RVSteps';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { REGEX_CLC_RULES, REGEX_POD_RULES } from '../../../helpers/regex';
import { IncarcareFisaNouaVerificareClient } from '../../../helpers/schemas';
import { getQueryParam, setQueryParam } from '../../../helpers/urlUtils';
import RVStepEight from './Steps/RVStepEight';
import RVStepFive from './Steps/RVStepFive';
import RVStepFour from './Steps/RVStepFour';
import RVStepOne from './Steps/RVStepOne';
import RVStepSeven from './Steps/RVStepSeven';
import RVStepSix from './Steps/RVStepSix';
import RVStepThree from './Steps/RVStepThree';
import RVStepTwo from './Steps/RVStepTwo';

export const TYPE_VERIFICARE = 'VERIFICARE';
export const TYPE_REVIZIE = 'REVIZIE';

type EconomicOperatorType = {
  companyName: string;
  oeCodSap: string;
  userId: string;
};

type CpDataType = {
  address: string;
  building: string;
  clientFirstName: string;
  clientLastName: string;
  countyCode: string;
  countyName: string;
  houseNum1: string;
  houseNum2: string;
  isCompany: boolean;
  localityCode: number;
  localityName: string;
  pod: string;
  roomNumber: string;
  streetCode: number;
  streetName: string;
  utility: number;
  vstelle: string;
  companyName: string;
};

const CreareFisa = () => {
  const [idAnexa, setIdAnexa] = useState('');

  const [type, setType] = useState('');
  const [clc, setClc] = useState('');
  const [pod, setPod] = useState('');

  const [isClcFocused, setIsClcFocused] = useState(false);
  const [isPodFocused, setIsPodFocused] = useState(false);

  const [verifyField, setVerifyField] = useState('');
  const [cpData, setCpData] = useState<CpDataType | null>(null);
  const [dictionare, setDictionare] = useState(null);

  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [county, setCounty] = useState('');
  const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
  const [locality, setLocality] = useState('');

  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [errorEmptyPodAndClc, setErrorEmptyPodAndClc] = useState('');
  //eslint-disable-next-line
  const [errorTypeEmpty, setErrorTypeEmpty] = useState('');

  //steps
  const [steps, setSteps] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isVerify, setIsVerify] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const formRef = useRef<FormikProps<any>>(null);

  const [economicOpertatorData, setEconomicOpertatorData] = useState<EconomicOperatorType | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (idAnexa) {
      let isAlreadySet = getQueryParam('id_anexa');
      if (!isAlreadySet) {
        setIsEdit(true);
        setQueryParam('id_anexa', idAnexa);
        setQueryParam('type', type == TYPE_REVIZIE ? 'Revizie' : 'Verificare');
      }
    }
  }, [idAnexa]);

  useEffect(() => {
    if (currentStep >= 0 && isEdit) {
      setQueryParam('step', currentStep + 1);
    }
  }, [currentStep, isEdit]);

  useEffect(() => {
    GetCounties().then((res) => setCounties(res));

    const queryParams = new URLSearchParams(location.search);
    const idAnexaParam: string | null = queryParams.get('id_anexa');
    const typeParam: string | any = queryParams.get('type');
    let stepParam: string | any = queryParams.get('step');
    stepParam = Number(stepParam) - 1;

    dispatch(toggleLoader(true));
    if (idAnexaParam) {
      setIdAnexa(idAnexaParam);
      setIsEdit(true);
      getTipuriRevizii(typeParam == 'Revizie' ? 'REV' : 'INSP', ['DeviceType', 'OptionType', 'InstalationType', 'NonconformityType'])
        .then((res) => {
          setDictionare(res);
          setType(typeParam == 'Revizie' ? TYPE_REVIZIE : TYPE_VERIFICARE);
          setIsVerify(false);
          setCurrentStep(stepParam);
          setProgress(stepParam);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
        });
    } else {
      getEconomicOperator()
        .then((res) => {
          setEconomicOpertatorData(res);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
        });
    }
  }, []);

  useEffect(() => {
    if (type) {
      if (type == TYPE_REVIZIE) {
        setSteps(stepsArrayRevizii);
      } else {
        setSteps(stepsArrayVerificari);
      }
      if (!dictionare) {
        getTipuriRevizii(type == TYPE_REVIZIE ? 'REV' : 'INSP', ['DeviceType', 'OptionType', 'InstalationType', 'NonconformityType'])
          .then((res) => {
            setDictionare(res);
          })
          .catch((err) => {
            setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          });
      }
    }
  }, [type, dictionare]);

  useEffect(() => {
    county !== '' && GetLocalities(county).then((res) => setLocalities(res));
  }, [county]);

  useEffect(() => {
    GetCounties().then((res) => setCounties(res));
  }, []);

  useEffect(() => {
    let updatedErrors = validateValues({ ...formRef.current?.errors });
    formRef.current?.setErrors({ ...updatedErrors });
  }, [pod, clc]);

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const handleProgress = (step: number) => {
    setProgress(step);
  };

  const handleSearch = async (values: any) => {
    let valid = true;
    if (!pod && !clc) {
      setErrorEmptyPodAndClc('Trebuie să introduceți Cod loc consum sau Cod punct de măsurare!');
      setVerifyField('');
      valid = false;
    } else if (pod) {
      setVerifyField('POD');
      setErrorEmptyPodAndClc('');
    } else {
      setVerifyField('CLC');
      setErrorEmptyPodAndClc('');
    }
    if (!type) {
      setErrorTypeEmpty('Câmp obligatoriu!');
      valid = false;
    }
    if (!valid) return;

    let duplicateFound = false;
    await getDuplicateRevision(verifyField == 'POD' ? pod : clc)
      .then((res) => {
        duplicateFound = res;
      })
      .catch((err) => {});

    if (duplicateFound) {
      setError('Mai aveți o fișă introdusă! Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
      return;
    }

    verify();
  };

  const verify = async () => {
    setError('');
    dispatch(toggleLoader(true));

    if (verifyField == 'POD') {
      await adressByPOD({ pod: pod, countyCode: county, localityCode: locality })
        .then(async (res: any) => {
          if (res) {
            if (economicOpertatorData) {
              res.companyName = economicOpertatorData.companyName;
            }
            setCpData(res);
            setIsVerify(false);
          }
        })
        .catch((e) => {
          if (e && e.status == 204) {
            setError('Date de identificare client greșite. Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
          } else {
            setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        });
    } else {
      await adressByCLC({ clc: clc, countyCode: county, localityCode: locality })
        .then(async (res: any) => {
          if (res) {
            if (res.utility == 1) {
              setError('Date de identificare client greșite. Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
              return;
            }
            if (economicOpertatorData) {
              res.companyName = economicOpertatorData.companyName;
            }
            setCpData(res);
            setIsVerify(false);
          }
        })
        .catch((e) => {
          if (e && e.status == 204) {
            setError('Date de identificare client greșite. Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
          } else {
            setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        });
    }

    dispatch(toggleLoader(false));
  };

  const validateValues = (initialErrors?: any) => {
    let errors: any = {};

    if (initialErrors) {
      errors = initialErrors;
    }

    if (!pod && !clc) {
      errors.clc = 'Trebuie să introduceți Cod loc consum sau Cod punct de măsurare!';
      errors.pod = 'Trebuie să introduceți Cod loc consum sau Cod punct de măsurare!';
      setVerifyField('');
    } else if (pod) {
      if (!REGEX_POD_RULES.test(pod)) {
        errors.pod = 'Câmpul Cod punct de măsurare trebuie sa fie de forma DEGxxxxxxx.';
      } else {
        delete errors.pod;
      }
      delete errors.clc;
      setVerifyField('POD');
    } else if (clc) {
      if (!REGEX_CLC_RULES.test(clc)) {
        errors.clc = 'Câmpul Cod loc consum trebuie sa fie de forma 500xxxxxxx.';
      } else {
        delete errors.clc;
      }
      delete errors.pod;
      setVerifyField('CLC');
    }
    return errors;
  };

  return (
    <>
      <Fragment>
        {steps && !isVerify && <Steps steps={steps} currentStep={currentStep} changeStep={handleStepChange} progress={progress} />}

        {isVerify && !idAnexa && (
          <Formik
            enableReinitialize
            initialValues={{
              pod: '',
              clc: '',
              county: '',
              locality: '',
              type: ''
            }}
            onSubmit={(values) => handleSearch(values)}
            validationSchema={IncarcareFisaNouaVerificareClient}
            validate={(values: any) => {
              let errors = validateValues();
              return errors;
            }}
            innerRef={formRef}
          >
            {(props) => {
              return (
                <Fragment>
                  <Form>
                    <div className="h-100">
                      <span>
                        <span className="m-0 mb-1 fw-bold">Tip fișă:</span>
                        <span
                          onClick={() => {
                            props.setFieldValue('type', TYPE_REVIZIE);
                            setType(TYPE_REVIZIE);
                          }}
                          className="me-2 ms-2"
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={1}
                            checked={type === TYPE_REVIZIE}
                            onChange={() => {
                              props.setFieldValue('type', TYPE_REVIZIE);
                              setType(TYPE_REVIZIE);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Revizie</span>
                        </span>

                        <span
                          onClick={() => {
                            props.setFieldValue('type', TYPE_VERIFICARE);
                            setType(TYPE_VERIFICARE);
                          }}
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={1}
                            checked={type === TYPE_VERIFICARE}
                            onChange={() => {
                              props.setFieldValue('type', TYPE_VERIFICARE);
                              setType(TYPE_VERIFICARE);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Verificare</span>
                        </span>
                      </span>
                      <ErrorMessage name="type" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />

                      <Row>
                        <Col xs={12} md={5} className="mt-3">
                          <Field
                            id="clc"
                            name="clc"
                            placeholder="Cod loc consum"
                            render={() => (
                              <MaskedInput
                                value={clc}
                                mbZero
                                onChange={(e: any) => {
                                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                  setClc(currentValue);
                                }}
                                onBlur={() => {
                                  props.setFieldTouched('clc');
                                  setIsClcFocused(false);
                                }}
                                onFocus={() => {
                                  setIsClcFocused(true);
                                }}
                                utility="GN"
                                label="Cod loc consum"
                                mask={'0123333333'}
                                isClc
                                preloadedStartDisabled
                                disabled={isPodFocused || !!pod}
                              />
                            )}
                          />
                          <ErrorMessage name="clc" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>

                        <Col xs={12} md={1} className="mt-3">
                          <span className="d-flex align-text-center justify-content-center mt-2">
                            <strong>sau </strong>
                          </span>
                        </Col>
                        <Col xs={12} md={5} className="mt-3">
                          <Field
                            id="pod"
                            name="pod"
                            placeholder="Cod punct de masurare"
                            render={() => (
                              <MaskedInput
                                value={pod}
                                mbZero
                                onChange={(e: any) => {
                                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                  setPod(currentValue);
                                }}
                                onBlur={() => {
                                  props.setFieldTouched('pod');
                                  setIsPodFocused(false);
                                }}
                                onFocus={() => {
                                  setIsPodFocused(true);
                                }}
                                utility="GN"
                                label="Cod punct de măsurare"
                                mask={'0123333333'}
                                preloadedStartDisabled
                                disabled={isClcFocused || !!clc}
                              />
                            )}
                          />
                          <ErrorMessage name="pod" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={5} className="mt-3">
                          <Dropdown
                            options={
                              counties.map((option: any, index: number) => ({
                                id: option.countyCode,
                                name: option.countyName
                              })) as DropdownOptionType[]
                            }
                            label={'Județ'}
                            defaultPlaceholder={'Alege județul'}
                            displaySearch={true}
                            onChange={(value) => {
                              setCounty(value);
                              value && props.setFieldValue('county', value);
                              value.length === 0 && props.setFieldValue('county', '');
                            }}
                            mbZero
                            value={county}
                          />
                          <ErrorMessage name="county" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col xs={12} md={1} className="mt-3"></Col>

                        {county && localities && localities.length > 0 && (
                          <Col xs={12} md={5} className="mt-3">
                            <Dropdown
                              options={
                                localities.map((option: any, index: number) => ({
                                  id: option.localityCode,
                                  name: option.localityName
                                })) as DropdownOptionType[]
                              }
                              label={'Localitate'}
                              defaultPlaceholder={'Alege localitatea'}
                              displaySearch={true}
                              onChange={(value) => {
                                setLocality(value);
                                value && props.setFieldValue('locality', value);
                                value.length === 0 && props.setFieldValue('locality', '');
                              }}
                              mbZero
                              value={locality}
                            />
                            <ErrorMessage name="locality" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                          </Col>
                        )}
                      </Row>
                      <div>
                        {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
                        <Button type={'submit'} className="btn-large mt-2">
                          Caută
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Fragment>
              );
            }}
          </Formik>
        )}

        {!isVerify && (
          <Fragment>
            {currentStep == 0 && (
              <RVStepOne
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                setIdAnexa={setIdAnexa}
                idAnexa={idAnexa}
              />
            )}

            {currentStep == 1 && (
              <RVStepTwo
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                idAnexa={idAnexa}
              />
            )}

            {currentStep == 2 && (
              <RVStepThree
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                idAnexa={idAnexa}
              />
            )}
            {currentStep == 3 && type == TYPE_REVIZIE && (
              <RVStepFour
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                idAnexa={idAnexa}
              />
            )}
            {((type == TYPE_REVIZIE && currentStep == 4) || (type == TYPE_VERIFICARE && currentStep == 3)) && (
              <RVStepFive
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                idAnexa={idAnexa}
              />
            )}
            {((type == TYPE_REVIZIE && currentStep == 5) || (type == TYPE_VERIFICARE && currentStep == 4)) && (
              <RVStepSix
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                idAnexa={idAnexa}
              />
            )}
            {((type == TYPE_REVIZIE && currentStep == 6) || (type == TYPE_VERIFICARE && currentStep == 5)) && (
              <RVStepSeven
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                idAnexa={idAnexa}
              />
            )}
            {((type == TYPE_REVIZIE && currentStep == 7) || (type == TYPE_VERIFICARE && currentStep == 6)) && (
              <RVStepEight
                type={type}
                cpData={cpData}
                pod={pod}
                clc={clc}
                dictionare={dictionare}
                currentStep={currentStep}
                setStep={handleStepChange}
                progress={progress}
                setProgress={handleProgress}
                county={county}
                locality={locality}
                idAnexa={idAnexa}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </>
  );
};

export default CreareFisa;
