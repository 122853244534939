import { convertToSlug } from '@lib/Slugify';
import { useMemo, useState } from 'react';

const TabsContent = (props: any) => {

    const { kentico } = props;
    const [currentTab, setCurrentTab] = useState<number>(0);
    const randTabId = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);

    const openTabSection = (evt: any, tabNmae: any, index?: number, tabSection?: string) => {
        let i, tabcontent, tablinks;

        tabcontent = document.getElementsByClassName('tabs_item');
        for (i = 0; i < tabcontent.length; i++) {
            let element: Element = tabcontent[i];

            element.setAttribute('style', 'display:none');
        }

        tablinks = document.querySelectorAll('.tabs li');

        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(' current', '');
        }

        document.getElementById(tabNmae)?.setAttribute('style', 'display:block');
        evt.currentTarget.className += ' current';
        index !== undefined && setCurrentTab(index as number);
    };

    let prefix = convertToSlug(kentico.items[0].tabTitle);

    let type = kentico.itemsDisplay ?? 'text';

    return (
        <section className="tab-section mt-5">
            <div className="container">
                {kentico.title &&
                <div className="section-title align-items-center">
                    {/* {kentico.title && <h3 className="text-center">{kentico.title}</h3>} */}
                </div>
                }
                <div className="tab boosting-list-tab">
                    {/* Tabs Nav */}
                    <ul className={`tabs scroll-styled ${type === 'text' ? 'text-tab justify-content-between' : 'number-tab'}`}>
                        {kentico.items.map((item: any, index: number) => (
                            <li
                                key={index}
                                className={`position-relative ${type === 'text' ? 'li-item-text' : 'li-item-number'} ${currentTab === index ? 'current' : ''}`}
                                onClick={(e) => openTabSection(e, `tab-${randTabId}-${prefix}${index}`, index, prefix)}
                            >
                                {type === 'text' && item.icon && <div className="w-100 d-flex justify-content-center step-item-content">
                                    <img src={item.icon} alt="icn-multi-tool.png"
                                         className="d-block stepper-icon" />
                                </div>
                                }
                                <span style={{fontSize: '18.5px', fontFamily: 'EONBrixSans, serif'}} className={type === 'text' ? '' : 'li-counter'}>{type === 'text' ? item.tabTitle : index + 1}</span>
                                {type === 'number' && item.icon && <div className="w-100 d-flex justify-content-center step-item-content">
                                    <img src={item.icon} alt="icn-multi-tool.png"
                                         className="d-block stepper-icon" />
                                </div>
                                }
                            </li>)
                        )}
                    </ul>
                    <div className="tab_content">
                        {kentico.items.map((item: any, index: number) => (
                            <div id={`tab-${randTabId}-${prefix}${index}`} className="tabs_item" key={index}>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="tab-image">
                                            <img
                                                className="responsive img-fluid"
                                                src={item.image} alt={item.title} />
                                        </div>
                                    </div>

                                    <div className="col-lg-7">
                                        <div className="content">
                                            <h2>{item.title ?? item.tabTitle}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                            {item.menu && <div className="tab-navigation mt-5 d-flex justify-content-start">
                                                {item.menu.map((menu: any, index: number) => (
                                                    <div key={index} className="me-3">
                                                        <a href={menu.link}>{menu.title}</a>
                                                    </div>
                                                ))}
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );

};

export default TabsContent;