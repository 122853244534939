import React from 'react';

const AddCPTableHead = (props: any) => {
    return (
        <thead>
        <tr>
            <th>#</th>
            <th>Pod</th>
            <th>Adresa</th>
            <th>Actiuni</th>
        </tr>
        </thead>
    );
};

export default AddCPTableHead;