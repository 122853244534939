import React, { Fragment, useEffect, useState } from 'react';
import { CustomModal } from '@components/common/ModalConfirm';
import { postRomcardResponse } from '@api/invoices/invoices';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';

type RomcardCallbackProps = {
  AMOUNT: string;
  CURRENCY: string;
  ORDER: string;
  TERMINAL: string;
  TRTYPE: string;
  INT_REF: string;
  RRN: string;
  ACTION: string;
  RC: string;
  MESSAGE: string;
  APPROVAL: string;
  TIMESTAMP: string;
  NONCE: string;
  P_SIGN: string;
};

const RomcardCallback = (props: { kentico: RomcardCallbackProps }) => {
  const [error, setError] = useState('');
  const [succesMessage, setSuccesMessage] = useState('');

  const [showModalError, setShowModalError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getPaymentStatusResponse();
  }, []);

  useEffect(() => {
    if (error) {
      setShowModalError(true);
    }
  }, [error]);

  const getPaymentStatusResponse = () => {
    dispatch(toggleLoader(true));
    postRomcardResponse(props.kentico)
      .then((res) => {
        dispatch(toggleLoader(false));
        setSuccesMessage('Tranzacția a fost acceptată, plata este în curs de procesare.');
      })
      .catch((err: any) => {
        if (err.status == 200) {
          setSuccesMessage('Tranzacția a fost acceptată, plata este în curs de procesare.');
        } else if (typeof err == 'string') {
          setError(`A apărut o eroare în procesare (${err})`);
        } else {
          setError('A intervenit o eroare. Încercați mai târziu.');
        }
        dispatch(toggleLoader(false));
      });
  };

  return (
    <Fragment>
      <CustomModal
        modalTitle={'Eroare!'}
        showModal={showModalError}
        onClose={() => {
          let urlToRedirectAfterPayment = '/lista-cereri-racordare';
          if (typeof localStorage !== 'undefined') {
            let lsReturnUrl = localStorage.getItem('PaymentActionReturnUrl') ?? '';
            if (lsReturnUrl) {
              localStorage.removeItem('PaymentActionReturnUrl');
              urlToRedirectAfterPayment = lsReturnUrl;
            }
          }
          window.location.href = urlToRedirectAfterPayment;
        }}
      >
        <h5>{error}</h5>
      </CustomModal>

      <CustomModal
        modalTitle={'Succes!'}
        showModal={!!succesMessage}
        onClose={() => {
          let urlToRedirectAfterPayment = '/lista-cereri-racordare';
          if (typeof localStorage !== 'undefined') {
            let lsReturnUrl = localStorage.getItem('PaymentActionReturnUrl') ?? '';
            if (lsReturnUrl) {
              localStorage.removeItem('PaymentActionReturnUrl');
              urlToRedirectAfterPayment = lsReturnUrl;
            }
          }
          window.location.href = urlToRedirectAfterPayment;
        }}
      >
        <h5>{succesMessage}</h5>
      </CustomModal>
    </Fragment>
  );
};

export default RomcardCallback;
