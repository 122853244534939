import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

type SignatureProps = {
  className?: string;
  width: number;
  height: number;
  setEmpty: (empty: boolean) => void;
};

const Signature = forwardRef((props: SignatureProps, ref) => {
  const canvasRef = useRef(null);

  useImperativeHandle(ref, () => ({
    handleSave
  }));

  function handleClear() {
    //@ts-ignore
    if (canvasRef?.current) canvasRef.current.clear(); // This will clear the canvas
  }

  function handleSave() {
    let dataUrl;
    //@ts-ignore
    if (canvasRef?.current?.isEmpty()) {
      props.setEmpty(true);
      return false;
    } else {
      props.setEmpty(false);
      if (canvasRef?.current) {
        //@ts-ignore
        dataUrl = canvasRef.current.toDataURL();
        return dataUrl;
      }
    }
  }

  return (
    <div>
      <div style={{ border: '1px solid black', padding: '5px', width: props.width + 10 }}>
        <SignatureCanvas
          ref={canvasRef}
          penColor="black"
          canvasProps={{ width: props.width, height: props.height, className: props.className || '' }}
        />
      </div>
      <Button className="mt-1" onClick={handleClear}>
        Resetează
      </Button>
    </div>
  );
});

export default Signature;
