import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ToString } from '@lib/Utils';
import { InputTypes } from '@constants/InputTypes';
import * as FormFieldsValidation from '@lib/Validator';
export enum ChangeStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING'
}
export type ChangeStatusType = { [key in ChangeStatus]?: string };
export type HTMLInputTypes = React.InputHTMLAttributes<HTMLInputElement>;
export type InputProps = HTMLInputTypes & {
  error?: string;
  success?: string;
  alert?: string;
  label?: string;
  description?: string;
  containerClassName?: string;
  // name?: ValueOf<typeof FormFieldsNames>;
  name?: string;
  value?: string;
  icon?: JSX.Element;
  // status?: ChangeStatusType;
  // statusKey?: ChangeStatus;
  additionalHtml?: JSX.Element;
  hasTooltip?: boolean;
  tooltipHtml?: JSX.Element;
  warningStreetNumberBuilding?: string;
  status?: ChangeStatusType;
  statusKey?: ChangeStatus;
  isUsedInBackOffice?: boolean;
  onChange?: (e: any) => any;
  mbZero?: boolean;
};
export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Input = forwardRef((props: InputProps, ref) => {
  const inputRef = useRef<any>('');

  const [autoFill, setAutoFill] = useState<boolean>(false);
  const [validation, setValidation] = useState<'invalid' | 'valid' | ''>('');
  const prevProps = usePrevious(props);

  const {
    id,
    label,
    error,
    success,
    alert,
    hidden,
    description,
    containerClassName,
    type,
    icon,
    placeholder,
    className,
    required,
    additionalHtml,
    hasTooltip,
    tooltipHtml,
    value,
    status,
    statusKey,
    name,
    warningStreetNumberBuilding,
    isUsedInBackOffice,
    mbZero,
    ...rest
  } = props;

  useEffect(() => {
    updateValidation();
  }, [prevProps?.error]);

  useImperativeHandle(ref, () => ({
    handleFocus
  }));

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  const _onKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.onKeyDown) {
      return props.onKeyDown(event);
    } else if (props.type === InputTypes.NUMBER) {
      // if (!FormFieldsValidation.NUMBER.test(event.key) && event.key !== 'Enter') event.preventDefault();
    } else if (props.type === InputTypes.FLOAT) {
      if (
        !FormFieldsValidation.NUMBER.test(event.key) &&
        event.key !== 'Enter' &&
        (event.key !== '.' || ToString(event.currentTarget.value).includes('.'))
      )
        event.preventDefault();
    }
  };

  const _onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event = event || defaultEvent;
    if (event.persist) event.persist();

    const target = event.currentTarget || event.target || undefined;

    if (!target) return props.onChange && props.onChange(event);

    const { selectionStart, selectionEnd } = target;

    // update the state and reset the caret
    if (props.onChange) await props.onChange(event);

    if (selectionStart !== null && selectionEnd !== null) target.setSelectionRange(selectionStart, selectionEnd);

    if (autoFill) await setAutoFill(false);

    return undefined;
  };

  const inputHasPlaceholder = placeholder ? 'input-with-placeholder' : '';
  const justPlaceholder = !label && placeholder ? 'input-just-placeholder' : '';

  if (hidden) return null;

  const statusClass = ToString(status && statusKey).toLowerCase();

  const getValidation = (): '' | 'invalid' | 'valid' => {
    if (props.error) {
      return 'invalid';
    } else if (props.success) {
      return 'valid';
    }
    return '';
  };

  const updateValidation = () => {
    let state = getValidation();
    setValidation(state);
  };

  return (
    <div className={`field-group ${validation} ${ToString(containerClassName)} ${statusClass} ${error ? 'error' : ''} ${mbZero && 'mb-0'}`}>
      <span className="input">
        {additionalHtml}

        {hasTooltip && (label || placeholder) && (
          <label htmlFor={id} className={isUsedInBackOffice ? 'bo_label' : ''}>
            {tooltipHtml ? (
              <div>
                {label}
                {tooltipHtml}
              </div>
            ) : (
              label
            )}
          </label>
        )}

        <input
          ref={inputRef}
          {...rest}
          className={`input-with-value ${inputHasPlaceholder} ${ToString(autoFill && 'chrome-auto-fill')} ${justPlaceholder} ${ToString(
            className
          )}`}
          type={type}
          onChange={props.onChange ? props.onChange : _onChange}
          name={name}
          value={value}
          placeholder={placeholder}
          onKeyDown={_onKeypress}
          // onBlur={this._onBlur}
          // onFocus={_onFocus}
          id={id}
          hidden={hidden}
          // onPaste={this._onPaste}
          // ref={(ref) => (this.element = ref)}
        />

        {!hasTooltip && (label || placeholder) && (
          <label htmlFor={id} className={isUsedInBackOffice ? 'bo_label' : ''}>
            {label}
          </label>
        )}
      </span>

      {/* {name === FormFieldsNames.STREET_NUMBER && warningStreetNumberBuilding && (
          <div className="notification-warning">
            <p> {warningStreetNumberBuilding}</p>
          </div>
        )} */}

      {icon}

      {description && <span>{description}</span>}

      {error && <span className="label-error">{error}</span>}
      {success && <span className={label}>{success}</span>}
      {alert && <span className={label}>{alert}</span>}
      {status && statusKey && (
        <div className="status">
          <p>{status[statusKey]}</p>
        </div>
      )}
    </div>
  );
});

export default Input;
