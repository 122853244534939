import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import CautareProgramare from '@components/dev/System/ProgramariCitiri/CautareProgramare';

export type MultiTabsProps = { kentico?: null };

export const CautareProgramareComp = (props: any): JSX.Element => {
  return (
    <DelgazWrapper>
      <CautareProgramare {...props} />
    </DelgazWrapper>
  );
};
