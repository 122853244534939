// import * as FormFieldsNames from '@constants/FormFieldsNames';

export enum InputTypes {
  ACTION_SEARCH = 'actionSearch',
  ADDRESS = 'address',
  CHECKBOX = 'checkbox',
  CNP = 'cnp',
  CNP_SHORT = 'cnpShort',
  CONFIRM_EMAIL = 'confirmEmail',
  CUI = 'cui',
  DATE_PICKER = 'datePicker',
  DATE_SIMPLE_PICKER = 'date',
  DATETIME_PICKER = 'datetime-local',
  EMAIL = 'email',
  FLOAT = 'float',
  ID_SERIES = 'idSeries',
  LOCALITY = 'locality',
  NUMBER = 'number',
  PASSWORD = 'password',
  POSTAL_CODE = 'postalCode',
  RADIO = 'radio',
  SEARCH = 'search',
  SELECTOR = 'select',
  SELECTOR_BOX = 'selectBox',
  STREET = 'street',
  TEL = 'tel',
  TEXT = 'text',
  TEXTAREA = 'textarea'
}

export const actionSearchType = [InputTypes.LOCALITY, InputTypes.POSTAL_CODE, InputTypes.STREET];

export const preventInputPaste = [

];

export const allowedInputType = [
  InputTypes.CHECKBOX,
  // InputTypes.EMAIL,
  // InputTypes.NUMBER,
  InputTypes.PASSWORD,
  InputTypes.RADIO,
  InputTypes.SEARCH,
  InputTypes.SELECTOR,
  InputTypes.TEL,
  InputTypes.TEXT,
  InputTypes.TEXTAREA
];
