export const PROCESS_STATUS_NEPROCESAT = 0;
export const PROCESS_STATUS_IN_PROCESARE = 1;
export const PROCESS_STATUS_PROCESAT = 2;
export const PROCESS_STATUS_IN_ASTEPTARE = 3;
export const PROCESS_STATUS_FINALIZAT = 4;
export const PROCESS_STATUS_INCETARE_PROCES = 5;
export const PROCESS_STATUS_RENUNTARE_CLIENT = 6;
export const PROCESS_STATUS_ANULAT = 7;
export const PROCESS_STATUS_NETRIMISA = 8;

export const PROCESS_STATUS_NAME_NEPROCESAT = 'Neprocesat';
export const PROCESS_STATUS_NAME_IN_PROCESARE = 'In procesare';
export const PROCESS_STATUS_NAME_PROCESAT = 'Procesat';
export const PROCESS_STATUS_NAME_IN_ASTEPTARE = 'In asteptare';
export const PROCESS_STATUS_NAME_FINALIZAT = 'Finalizat';
export const PROCESS_STATUS_NAME_INCETARE_PROCES = 'Încetare proces';
export const PROCESS_STATUS_NAME_RENUNTARE_CLIENT = 'Renuntare client';
export const PROCESS_STATUS_NAME_ANULAT = 'Anulat';
export const PROCESS_STATUS_NAME_NETRIMISA = 'Netrimisă';
