import { toggleLoader } from '@actions/LoaderActions';
import { GetAvailableSlots, GetTemplates } from '@api/programareCitiri';
import * as FormFieldsNames from '@constants/FormFieldsNames';
import { InputTypes } from '@constants/InputTypes';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { template } from '@lib/MeterReading';
import * as notification from '@lib/Notification';
import { ToString } from '@lib/Utils';
import { EE_TYPE, GN_TYPE, MeterReading, MeterReadingModel, ProgramariCitiriTemplate } from '@type/programari-citiri';
import { IState } from '@type/store';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormCautare from './FormCautare';
import NotificationModal, { NotificationInputs } from './NotificationModal';

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

type InputValues = {
  [FormFieldsNames.NUMAR_CONTACT]: any, [FormFieldsNames.COD_LOC_CONSUM]: any
};
type CautareProgramareProps = {
  isUsedInBO?: boolean;
  contactNumber?: string;
  codLocConsum?: string;
  linkText?: string;
  reloadContent?: () => void;
  kid?: string;
}

//eslint-disable-next-line
const mapping: object = {
  'Delgaz.ContactForm': '',
  'Delgaz.RichTextEditor': ''
};

type ErrorsType = { [key: string]: string | undefined };

//eslint-disable-next-line
type FormCautare = {
  [FormFieldsNames.NUMAR_CONTACT]: string;
  [FormFieldsNames.COD_LOC_CONSUM]: string;
  invalidContactNumber: string;
  invalidCodLocConsum: string;
};

//eslint-disable-next-line
type Template = {
  [key: string]: string
}

const CautareProgramare = (props: CautareProgramareProps) => {
  const [inputValues, setInputValues] = useState<InputValues>({} as InputValues);
  const [errors, setErrors] = useState<ErrorsType>({});
  const [apiResponse, setApiResponse] = useState<string | null>(null);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [readingNotification, setReadingNotification] = useState<MeterReadingModel | null>(null);
  const [templates, setTemplates] = useState<ProgramariCitiriTemplate>({} as ProgramariCitiriTemplate);
  const [showModalBO, setshowModalBO] = useState<boolean>(false);
  const xApiKey: string | undefined = useSelector<IState>((state) => state.options.xApiKey) as string;
  const [currentOffset, setCurrentOffset] = useState<number>(120);
  const dispatch = useDispatch();

  useEffect(() => {
    if(readingNotification && readingNotification.appointmentUtcDate) {
        const dateOffset: number = moment(readingNotification.appointmentUtcDate, 'YYYYMMDDHHmm').utcOffset();
        setCurrentOffset(dateOffset);
    }
  },[readingNotification]);

  useEffect(() => {
    if(!props.isUsedInBO && xApiKey) {
      GetTemplates(xApiKey).then((res) => {
        setTemplates(res);
      }).catch((err) => {
      });
    }
  }, [props.isUsedInBO, xApiKey]);

  //folosite pentru sectiunea din Back Office
  useEffect(() => {
    if(props.isUsedInBO && showModalBO) {
      handleSearchForBO();
    }
  },[showModalBO]);
  useEffect(() => {
    if(props.isUsedInBO && readingNotification && (readingNotification.status === 'OK_TO_ADD' || readingNotification.status === 'OK_TO_EDIT' || readingNotification.status === 'NO_DAYS_AVAILABLE_TO_ADD')) {
      setShowNotificationModal(true);
    }
  },[readingNotification]);
  //
  

  const SetValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    event = event || defaultEvent;

    const target = event.currentTarget || event.target || {};
    // const name = target.name as InputNames;
    if (!target.name) {
      return;
    }

    const name: keyof InputValues = target.name as keyof InputValues;

    if (errors && errors[name] !== undefined) errors[name] = undefined;

    inputValues[name] =
      target.type === InputTypes.CHECKBOX && typeof target.value === 'string' ? target.checked : target.value;

    return setInputValues({ ...inputValues });
  };

  const _validation = () => {
    const data = {
      [FormFieldsNames.NUMAR_CONTACT]: '',
      [FormFieldsNames.COD_LOC_CONSUM]: ''
    } as ErrorsType;

    let isValid = true;

    if (!inputValues.contactNumber) {
      data[FormFieldsNames.NUMAR_CONTACT] = 'Număr contact obligatoriu';
      isValid = false;
    }

    if (inputValues.contactNumber && (inputValues.contactNumber.includes('_'))) {
      data[FormFieldsNames.NUMAR_CONTACT] = 'Numărul este format din 10 caractere';
      isValid = false;
    }

    if (!inputValues.codLocConsum) {
      data[FormFieldsNames.COD_LOC_CONSUM] = 'Cod loc consum obligatoriu';
      isValid = false;
    }

    if (inputValues.codLocConsum && (inputValues.codLocConsum.includes('_'))) {
      data[FormFieldsNames.COD_LOC_CONSUM] = 'Loc consum este format din 10 caractere';
      isValid = false;
    }

    setErrors({ ...data });

    if (!isValid)
      notification.error('Va rugam verificati datele introduse!');

    return isValid;
  };

  const handleSearch = async () => {
    if (_validation()) {
      dispatch(toggleLoader(true));
      setApiResponse(null);
      setReadingNotification(null);

      try {
        let response: MeterReading = await GetAvailableSlots({ [FormFieldsNames.NUMAR_CONTACT]: inputValues.contactNumber, [FormFieldsNames.COD_LOC_CONSUM]: inputValues.codLocConsum });
        let meterReadingModel: MeterReadingModel = Object.assign({ ...response as MeterReading }, {
          utilityType: response.sparte === '01' ? EE_TYPE : GN_TYPE
        }) as MeterReadingModel;

        setReadingNotification({ ...meterReadingModel });
      } catch (err) {
        let { status } = err as { status: number };

        if (status === 404) {
          setReadingNotification({} as MeterReadingModel);
          notification.error('Nici o notificare nu a fost gasita!');
          setApiResponse('Ne pare rău, nu există date disponibile! Va rugăm verificați dacă datele sunt corecte.');
        } else if (status === 400) {
          notification.error('Contactul introdus se referă la citirea contorului. Te rugăm să folosești secțiunea Programări Citiri.');
          setApiResponse('Contactul introdus se referă la citirea contorului. Te rugăm să folosești secțiunea Programări Citiri.');
        }
        else if (status === 500) {
          setReadingNotification({} as MeterReadingModel);
          notification.error('A apărut o problemă!');
          setApiResponse('A apărut o problemă! Va rugăm încercați mai târziu.');
        }

      }
      dispatch(toggleLoader(false));
    }
  };

  const handleSearchForBO = async () => {
      dispatch(toggleLoader(true));
      setApiResponse(null);
      try {
        let response: MeterReading = await GetAvailableSlots({ [FormFieldsNames.NUMAR_CONTACT]: props.contactNumber || '', [FormFieldsNames.COD_LOC_CONSUM]: props.codLocConsum || '' });
        let meterReadingModel: MeterReadingModel = Object.assign({ ...response as MeterReading }, {
          utilityType: response.sparte === '01' ? EE_TYPE : GN_TYPE
        }) as MeterReadingModel;

        setReadingNotification({ ...meterReadingModel });
      } catch (err) {}
      dispatch(toggleLoader(false));
  };

  const renderFormCautare = () => {
    return (
      <FormCautare
        numarContact={inputValues?.contactNumber ?? ''}
        invalidNumarContact={errors.contactNumber}
        invalidCodLocConsum={errors.codLocConsum}
        codLocConsum={inputValues?.codLocConsum ?? ''}
        messageToShow={apiResponse}
        onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          handleSearch();
        }}
        setValue={SetValue}
      />
    );
  };

  const renderNotificationDataHandler = () => {
    if (readingNotification) {
      let date = readingNotification.appointmentUtcDate ? moment(readingNotification.appointmentUtcDate, 'YYYYMMDDHHmm').toDate() : null;
      let time = '';
      let formattedDate = '';
      if(date) {
        time = `${ToString((date.getHours() + ((currentOffset) / 60))).padStart(2, '0')}:${ToString(date.getMinutes()).padStart(2, '0')}`;
          //@ts-ignore
          formattedDate = moment(date).format('DD.MM.YYYY HH:mm');
        if(formattedDate.substring(11) === '00:00') {
          formattedDate = formattedDate.substring(0, 10);
        } else {
          formattedDate = formattedDate.substring(0, 10) + ' ' + time;
        }
      }
  
      if (readingNotification.status === 'OK_TO_EDIT') {
        return (<a className="d-block link w-100 mt1 red text-nowrap" onClick={(event: React.MouseEvent<HTMLElement>) => {
          setShowNotificationModal(true);
          return true;
        }}>
          <FontAwesomeIcon
            icon={faCalendar}
            style={{
              textAlign: 'center',
              width: '12px',
              color: 'red',
              lineHeight: '10px',
              zIndex: 1
            }}
          />
          <span className="ml-2" style={{ marginLeft: '10px' }}>Actualizare programare {formattedDate}</span>
        </a>);
      } else if (readingNotification.status === 'CREATED_FUTURE' || readingNotification.status === 'NO_DAYS_AVAILABLE_TO_ADD') {
        return <span>{formattedDate}</span>;
      } else if (readingNotification.status === 'OK_TO_ADD') {
        return (
          <a className="d-block link w-100 mt1 red text-nowrap" onClick={(event: React.MouseEvent<HTMLElement>) => {
            setShowNotificationModal(true);
            return true;
          }}>
            <FontAwesomeIcon
              icon={faCalendar}
              style={{
                textAlign: 'center',
                width: '12px',
                lineHeight: '10px',
                color: 'red',
                zIndex: 1
              }}
            />
            <span style={{ marginLeft: '10px' }}>Efectuati o programare</span>
          </a>);
      }
    }

    return null;
  };

  const renderNotification = () => {
    let templateUp = '';
    let templateDown = '';
    let isExpired = false;
    
    if (readingNotification && Object.keys(readingNotification).length === 0) return null;
    if (readingNotification) {
      isExpired = readingNotification.status === 'CREATED_PAST' || readingNotification.status === 'NO_DAYS_AVAILABLE_TO_ADD' || readingNotification.status === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS' || readingNotification.status === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS';

      templateUp = template(templates, 'up', readingNotification, inputValues[FormFieldsNames.NUMAR_CONTACT]);
      templateDown = '';
  
      if(!isExpired && readingNotification.notificationStatus !== 'INCHID') {
        templateDown = template(templates, 'down', readingNotification, inputValues[FormFieldsNames.NUMAR_CONTACT]);
      } 
    }

    return (
      <>
        {templates && templateUp && <div dangerouslySetInnerHTML={{ __html: templateUp }} />}
        {(!isExpired && readingNotification && readingNotification.notificationStatus !== 'INCHID') && <Row>
          <Col>
            <div className='general-table-container'>
              <Table className="w-100 table responsive table-hover">
                <thead>
                  <tr>
                    <th>Nume și Prenume/ Denumire client</th>
                    <th>Adresa locului de consum</th>
                    <th>Echipament</th>
                    <th>Material</th>
                    <th>Serie</th>
                    <th>Tip de notificare</th>
                    <th>Programare</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Nume și Prenume/ Denumire client">{readingNotification.clientFirstName} {readingNotification.clientName}</td>
                    <td data-label="Adresa locului de consum">{readingNotification.consumptionPointAddress}</td>
                    <td data-label="Echipament">{readingNotification.equipment}</td>
                    <td data-label="Material">{readingNotification.material}</td>
                    <td data-label="Serie">{readingNotification.series}</td>
                    <td data-label="Tip de notificare">{readingNotification.notificationTypeDescription}</td>
                    <td data-label="Programare">
                      {renderNotificationDataHandler()}
                    </td>
                  </tr>
                </tbody>
                </Table>
            </div>
          </Col>
          <NotificationModal notification={readingNotification} onClose={handleSearch} setShow={(state: boolean) => setShowNotificationModal(state)} show={showNotificationModal}
            saveNotification={(inputs: NotificationInputs) => {
              notification.success('Programarea a fost salvata cu succes!');
              setShowNotificationModal(false);
              // setInputValues({} as InputValues);
              handleSearch();
            }}
            errorNotification={() => {
              notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            }} 
            modalTitle={'Programare înlocuire contor'} 
            modalClassName='rss-modal' 
          />
        </Row>
        }
        {!isExpired && templateDown && <div dangerouslySetInnerHTML={{ __html: templateDown }} />}
      </>
    );
  };

  return (
    <>
     { props.isUsedInBO ?
      <>
      {/* <div dangerouslySetInnerHTML={{ __html: props.link || ''}} onClick={()=>{setshowModalBO(true)}}/> */}
      <a style={{color: '#ea1c09', textDecoration: 'underline', cursor: 'pointer' }} onClick={()=>{setshowModalBO(true);}}>{props.linkText}</a>
      
      {readingNotification && <NotificationModal kid={props.kid} usedInBO={true} notification={readingNotification} setShow={(state: boolean) =>{setShowNotificationModal(state); setshowModalBO(state);}} show={showNotificationModal}
            saveNotification={(inputs: NotificationInputs) => {
              notification.success('Programarea a fost salvata cu succes!');
              setShowNotificationModal(false);
              setshowModalBO(false);
              setInputValues({} as InputValues);
              props.reloadContent && props.reloadContent();
            }}
            errorNotification={() => {
              notification.error('A intervenit o eroare tehnică. Programarea nu a fost salvată!');
            }}  
            modalTitle={'Programare înlocuire contor'} />}
      </>  
    : 
    <div className="delgaz-programari-citiri">
    {renderFormCautare()}
    <p className="brand-text lh-copy mt1">
      Regăsiți Număr contact și Cod loc de consum în colțul din dreapta jos de pe înștiințarea transmisă de Delgaz Grid
    </p>
    {renderNotification()}
  </div >
  }
  </>
  );
};

export default CautareProgramare;
