import React from 'react';
import { LoginIcons } from '@type/kentico-types';
import BoxItemComponent from './BoxItem';

export type BoxesProps = {
  icons: LoginIcons;
  isMobile: boolean,
  loggedInUser: string | undefined
};


const Boxes = (props: BoxesProps): JSX.Element => {
  let icons = props.icons;
  let isMobile = props.isMobile;
  let isLoggedIn = props.loggedInUser;

  return (
      <div className={`login-box-items ${isMobile ? 'd-flex' : ''} d-md-flex flex-no-wrap justify-content-between`}>
        {icons && icons.map((item: any, index: number) => {
          return (<BoxItemComponent key={index} {...item} isMobile={isMobile} isLoggedIn={isLoggedIn} idx={index}/>);
        })
        }
      </div >
  );
};

export default Boxes;
