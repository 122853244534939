import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import * as loaderActionsCreators from '@actions/LoaderActions';
import { IState } from '@type/store';

export type LoaderProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type LoaderState = {};

export class Loader extends React.Component<LoaderProps, LoaderState> {
  GetBall = () => [1, 2, 3].map((number) => <div key={number} className={`bounce-${number}`} />);

  render() {
    const { message, isOpen, activeLoaders } = this.props.loader;

    if (!isOpen && !activeLoaders.length) return null;

    return (
      <div className="d-flex flex-column justify-content-center loader">
        <div className="spinner">{this.GetBall()}</div>
        {message ? <p className="mx-auto eon-title eon-white">{message}</p> : ''}
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({ loader: state.loader || {} });

const mapDispatchToProps = (dispatch: Dispatch) => ({ ...bindActionCreators({ ...loaderActionsCreators }, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
