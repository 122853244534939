import React from 'react';
import { Button } from 'react-bootstrap';

type ButtonWithTagProps = {
  onClick: () => void;
  label: string;
  tag: any;
  style?: any;
  className?: string;
};

const ButtonWithTag = (props: ButtonWithTagProps) => {
  return (
    <Button
      className={props.className}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        ...props.style
      }}
      onClick={() => props.onClick()}
    >
      <span style={{ marginRight: '6px' }}>{props.label}</span>
      <span
        style={{
          background: 'white',
          color: '#ea1c0a',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          borderRadius: '5px'
        }}
      >
        {props.tag}
      </span>
    </Button>
  );
};

export default ButtonWithTag;
