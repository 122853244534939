import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RaportAnexa from '@components/revizii/RaportAnexa/RaportAnexa';

export const RaportAnexaComponent = () => {
  return (
    <DelgazWrapper>
      <RaportAnexa />
    </DelgazWrapper>
  );
};
