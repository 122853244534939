export const authBaseUrl = 'userauth';

const invoiceVersion = 'v1';
const invoiceRevision = '';
export const invoicesBaseUrl = `/invoices/${invoiceVersion}${invoiceRevision}`;

const leadsVersion = 'v1';
const leadsRevision = ';rev=2';
export const leadsBaseUrl = `/leads/${leadsVersion}${leadsRevision}`;

const leadsVersionV2 = 'v2';
const leadsRevisionV2 = '';
export const leadsBaseUrlV2 = `/leads/${leadsVersionV2}${leadsRevisionV2}`;

const partnersVersion = 'v2';
const partnersRevision = '';
export const partnersBaseUrl = `/partners/${partnersVersion}${partnersRevision}`;


const meterReadingsVersion = '';
const meterReadingsRevision = '';
export const meterReadingsBaseUrl = `/meter-reading/Appointment${meterReadingsVersion}${meterReadingsRevision}`;

const geoVersion = '';
const geoRevision = '';
export const geoBaseUrl = `/masterData/Geo/${geoVersion}${geoRevision}`;

const calorificValuesVersion = '';
const calorificValuesRevision = '';
export const calorificValuesBaseUrl = `/utils/CalorificValues/${calorificValuesVersion}${calorificValuesRevision}`;

const rssVersion = '';
const rssRevision = '';
export const rssBaseUrl = `/rss/RSS/${rssVersion}${rssRevision}`;

export const kenticoTemplatesUrl ='/template_api/Template/get_all';

const posfGNVersion = '';
const posfGNRevision = '';
export const posfGNBaseUrl = `/posf/BackOfficeGN/${posfGNVersion}${posfGNRevision}`;
// export const posfGNBaseUrl = `/posf/BackOfficeIdeFilesGN/${posfGNVersion}${posfGNRevision}`;
// export const posfGNBaseUrl = `/utils/BackOfficeServicesStatus/${posfGNVersion}${posfGNRevision}`;
// export const posfGNBaseUrl = `/posf/BackOfficeFUIGN/${posfGNVersion}${posfGNRevision}`;