import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Steps = (props: {steps: any, currentStep: number, changeStep: any, progress: any, isEditing: boolean | null}) => {
    const {steps, currentStep, progress, changeStep, isEditing} = props;

    const mapSteps = (steps: any) => {
        return steps.map((step: any, index: number) => (
            <div
                className={`d-flex flex-column p-3 justify-content-center text-center ${index <= progress ? 'active-step' : 'step'}`}
                key={index}
                onClick={() => isEditing === false && changeStep(index)}
            >
                <span className={`${index === currentStep ? 'active-icon-span' : 'icon-span'}`}>
                    <FontAwesomeIcon icon={step.icon} size={'2x'} color="grey" className="icon"/>
                </span>
                <span
                    className={`d-flex align-items-center justify-content-center ${index === currentStep ? 'active-step-text' : 'step-text'}`}
                    style={{fontWeight: 'bold', height: '70px'}}>
                    <span className="text-span">
                        {index + 1}. {step.title}
                    </span>
                </span>
            </div>
        ));
    };

    return (
        <div className="d-flex flex-row align-self-center w-100 mt-2">
            {mapSteps(steps)}
        </div>
    );
};

export default Steps;