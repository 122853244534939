import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AddCP from '@components/account/MyAccount/AddConsumptionPoint/AddCP';

export const AddCPoint = () => {
    return (
        <DelgazWrapper>
            <AddCP />
        </DelgazWrapper>
    );
};