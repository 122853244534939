import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ChangeEmailAccesEmail from './ChangeEmail/ChangeEmailAccesEmail';

export const ChangeEmailAccesEmailComponent = () => {
  return (
    <DelgazWrapper>
      <ChangeEmailAccesEmail />
    </DelgazWrapper>
  );
};
