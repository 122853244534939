import { Proxy, proxy } from '@api/azureProxy';
import { store } from '@lib/store';

export function getAvailableSlots(payload: any) {
    //eslint-disable-next-line
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/meter-reading/Appointment/available_slots_for_reading?numarContact=${payload.contractNumber}&codLocConsum=${payload.clc}&pod=${payload.pod}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {}
    });
}

export function meterSelfreadingInfo(payload: any) {
    //eslint-disable-next-line
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/meter-reading/Appointment/meter_selfreading_info?notificationId=${payload.notificationId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {}
    });
}

export function meterSelfreadingSave(payload: any) {
    //eslint-disable-next-line
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/meter-reading/Appointment/meter_selfreading_save',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}