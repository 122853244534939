import React, { Fragment, useState, useEffect } from 'react';
import {
  ROLE_FURNIZOR,
  ROLE_CLIENT_JURIDIC,
  ROLE_CLIENT_FIZIC,
  ROLE_OPERATOR_ECONOMIC,
  ROLE_PARTENER_VALORI_MASURATE
} from '../../../constants/Roles/Roles';
import ModalComponent from '@components/dev/Components/Modal/Modal';

type FormDetailsProps = {
  templates: any;
  role: number;
};
const FormDetails = (props: FormDetailsProps) => {
  const [showModalOE, setShowModalOE] = useState(false);

  useEffect(() => {
    //ROLE_OPERATOR_ECONOMIC
    const handleOpenModalOE = () => {
      setShowModalOE(true);
    };
    const openModalLink = document.getElementById('idCreareContOEOpenModal');
    if (openModalLink) {
      openModalLink.addEventListener('click', handleOpenModalOE);
    }
  }, []);

  const getResponseByRole = () => {
    if (!props.templates) return <></>;
    switch (props.role) {
      case ROLE_FURNIZOR:
        return <></>;
      case ROLE_CLIENT_FIZIC:
        return <></>;
      case ROLE_CLIENT_JURIDIC:
        return <></>;
      case ROLE_OPERATOR_ECONOMIC:
        return (
          <Fragment>
            {<div className="inner-a-red" dangerouslySetInnerHTML={{ __html: props.templates.CreareContOE }}></div>}
            <ModalComponent
              size="lg"
              show={showModalOE}
              setShow={(state: boolean) => {
                setShowModalOE(state);
              }}
              title={props.templates.CreareContOEModalTitlu}
              onClose={() => setShowModalOE(false)}
            >
              <div className="inner-a-red" dangerouslySetInnerHTML={{ __html: props.templates.CreareContOEModalContinut }}></div>
            </ModalComponent>
          </Fragment>
        );
      case ROLE_PARTENER_VALORI_MASURATE:
        return <></>;
      default:
        return <></>;
    }
  };

  return <div className="mt-3">{getResponseByRole()}</div>;
};

export default FormDetails;
