import { toggleLoader } from '@actions/LoaderActions';
import { verifyRssByEmail, verifyRssByPod } from '@api/rss/rss';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import MaskedInput from '@components/common/Input/MaskedInput';
import { CustomModal } from '@components/common/ModalConfirm';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { VerifyRSSByEmailSchema, VerifyRSSByPodDegSchema, VerifyRSSByPodEmoSchema } from '../../../helpers/schemas';
import VerifyDetails from './VerifyDetails';

const Verify = () => {
  const [rssNumber, setRssNumber] = useState('RSS_');
  const [year, setYear] = useState('');
  const [podEmo, setPodEmo] = useState('');
  const [podDeg, setPodDeg] = useState('');
  const [email, setEmail] = useState('');

  const [registedFor, setRegistedFor] = useState('');
  const [utility, setUtility] = useState('');
  const [yearsArray, setYearsArray] = useState<any>([]);

  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [showModalRssNotFound, setShowModalRssNotFound] = useState(false);

  const [error, setError] = useState('');

  const [item, setItem] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setEmail('');
    setPodDeg('');
    setPodEmo('');
  }, [registedFor]);

  useEffect(() => {
    setPodDeg('');
    setPodEmo('');
  }, [utility]);

  useEffect(() => {
    const currentYear = moment().year();
    const startYear = 2021;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push({ id: year.toString(), name: year.toString() });
    }
    setYearsArray(years);
  }, []);

  useEffect(() => {
    if (item) {
      setShowDetailsPage(true);
    }
  }, [item]);

  const submit = (values: any) => {
    setError('');
    dispatch(toggleLoader(true));
    if (registedFor == 'POD') {
      verifyRssByPod({ year: year, era: rssNumber, pod: utility == 'EE' ? podEmo : podDeg })
        .then((res) => {
          if (res.data) {
            setItem(res.data);
          }
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
          if (err?.response?.status == 400) {
            setShowModalRssNotFound(true);
          } else {
            setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
        });
    } else if (registedFor == 'EMAIL') {
      verifyRssByEmail({ year: year, era: rssNumber, email: email })
        .then((res) => {
          if (res.data) {
            setItem(res.data);
          }
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
          if (err?.response?.status == 400) {
            setShowModalRssNotFound(true);
          } else {
            setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
        });
    }
  };

  const resetValues = () => {
    setRssNumber('RSS_');
    setPodDeg('');
    setPodEmo('');
    setYear('');
    setEmail('');
    setRegistedFor('');
    setUtility('');
  };

  return (
    <Fragment>
      {showDetailsPage ? (
        <VerifyDetails
          item={item}
          hideDetailsPage={() => {
            setShowDetailsPage(false);
            resetValues();
          }}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            rssNumber: 'RSS_',
            podEmo: '',
            podDeg: '',
            year: '',
            email: ''
          }}
          onSubmit={(values) => submit(values)}
          validationSchema={
            registedFor == 'POD' ? (utility == 'EE' ? VerifyRSSByPodEmoSchema : VerifyRSSByPodDegSchema) : VerifyRSSByEmailSchema
          }
        >
          {(props) => {
            return (
              <Fragment>
                <Form>
                  <h4 className="red">Identificare petiție</h4>
                  <Row>
                    <Col xs={12} md={4} className="mt-3">
                      <Field label="Număr petiție*" id="rssNumber" name="rssNumber" placeholder="Număr petiție*">
                        {() => (
                          <Input
                            label="Număr petiție*"
                            value={rssNumber}
                            mbZero
                            onChange={(e: any) => {
                              const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                              if (currentValue.match(/\bRSS_\d+\b/) || currentValue.match(/\bRSS_\b/)) {
                                e.target.value.length !== 0 && setRssNumber(currentValue);
                                props.setFieldValue('rssNumber', currentValue);
                              }
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="rssNumber" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Dropdown
                        options={yearsArray as DropdownOptionType[]}
                        label={'An petiție*'}
                        defaultPlaceholder={'Alege anul'}
                        displaySearch={false}
                        onChange={(value) => {
                          setYear(value);
                          props.setFieldValue('year', value);
                        }}
                        value={year}
                        mbZero
                      />
                      <ErrorMessage name="year" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4} className="mt-3">
                      <h5>Petiția a fost înregistrată pentru</h5>
                      <div className="m-2 d-flex justify-content-between" style={{ flexDirection: 'column' }}>
                        <span onClick={() => setRegistedFor('POD')}>
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={1}
                            checked={registedFor == 'POD'}
                            onChange={() => setRegistedFor('POD')}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">POD</span>
                        </span>

                        <span onClick={() => setRegistedFor('EMAIL')}>
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={1}
                            checked={registedFor == 'EMAIL'}
                            onChange={() => setRegistedFor('EMAIL')}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Adresa de email</span>
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {registedFor == 'POD' && (
                      <Col xs={12} md={4} className="mt-3">
                        <h5>Utilitate</h5>
                        <div className="m-2 d-flex justify-content-between" style={{ flexDirection: 'column' }}>
                          <span onClick={() => setUtility('EE')}>
                            <input
                              className="custom-radio-button pointer"
                              type="radio"
                              value={1}
                              checked={utility == 'EE'}
                              onChange={() => setUtility('EE')}
                              style={{ marginRight: '6px' }}
                            />
                            <span className="fw-bold pointer">Energie Electrică</span>
                          </span>

                          <span onClick={() => setUtility('GN')}>
                            <input
                              className="custom-radio-button pointer"
                              type="radio"
                              value={1}
                              checked={utility == 'GN'}
                              onChange={() => setUtility('GN')}
                              style={{ marginRight: '6px' }}
                            />
                            <span className="fw-bold pointer">Gaze Naturale</span>
                          </span>
                        </div>
                      </Col>
                    )}

                    <Col xs={12} md={4} className="mt-3">
                      {registedFor == 'POD' && (
                        <div style={{ marginTop: '2rem' }}>
                          {utility == 'EE' ? (
                            <>
                              <MaskedInput
                                onChange={(e: any) => {
                                  let v = e.target.value;
                                  e.target.value.length !== 0 && setPodEmo(e.target.value);
                                  props.setFieldValue('podEmo', v);
                                }}
                                value={podEmo}
                                utility="EE"
                                label="POD*"
                                mask={'0123333333'}
                                mbZero
                              />
                              <ErrorMessage
                                name="podEmo"
                                render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                              />
                            </>
                          ) : (
                            utility == 'GN' && (
                              <>
                                <MaskedInput
                                  onChange={(e: any) => {
                                    let v = e.target.value;
                                    e.target.value.length !== 0 && setPodDeg(e.target.value);
                                    props.setFieldValue('podDeg', v);
                                  }}
                                  value={podDeg}
                                  utility="GN"
                                  label="POD*"
                                  mask={'0123333333'}
                                  mbZero
                                />
                                <ErrorMessage
                                  name="podDeg"
                                  render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                                />
                              </>
                            )
                          )}
                        </div>
                      )}

                      {registedFor == 'EMAIL' && (
                        <>
                          <Input
                            label="Email*"
                            value={email}
                            mbZero
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && setEmail(e.target.value);
                              e.target.value.length === 0 && setEmail('');
                              props.setFieldValue('email', e.target.value);
                            }}
                          />
                          <ErrorMessage name="email" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </>
                      )}
                    </Col>
                  </Row>

                  {error && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{error}</div>}

                  <Col className="mt-3">
                    <Button className="btn-large" type="submit" disabled={!rssNumber || !year || (!email && !podDeg && !podEmo)}>
                      Căutare
                    </Button>
                  </Col>
                </Form>
              </Fragment>
            );
          }}
        </Formik>
      )}
      <CustomModal
        showModal={showModalRssNotFound}
        modalTitle={'Eroare'}
        onClose={() => {
          setShowModalRssNotFound(false);
          resetValues();
        }}
      >
        <h5>Nu s-a indentificat nicio petiție cu aceste date. Te rugăm să reiei căutarea.</h5>
      </CustomModal>
    </Fragment>
  );
};

export default Verify;
