import * as PostTypes from '@constants/ActionTypes/PostTypes';
import { all, call, takeLatest } from 'redux-saga/effects';

const getPosts = async (): Promise<number> => {
    return new Promise<number>((resolve) => {
        resolve(4);
    });
};

function* fetchPosts(): Generator<any> {
    try {
        const response = yield call(getPosts);

        yield response;
    } catch (e) {
        console.error(e);
    }
}

function* postSaga() {
    yield all([takeLatest(PostTypes.FETCH_POST_REQUEST, fetchPosts)]);
}

export default postSaga;