import React from 'react';
import {RichTextKentico} from '@type/kentico-types';
import {Container} from 'react-bootstrap';

const RichTextComponent = (props: { kentico: RichTextKentico }) => {
    const kentico = props.kentico;

    return (
        <Container>
            <span dangerouslySetInnerHTML={{ __html: kentico.text }} />
        </Container>
    );
};

export default RichTextComponent;