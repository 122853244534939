import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@lib/store';
import { AuthenticationProvider } from '@contexts/authentication-context';

export type DelgazWrapperProps<T> = {
  aem?: T;
  hideAuthorMode?: boolean;
  children?: ReactNode; // Include the children property
};

class DelgazWrapper<T> extends React.Component<DelgazWrapperProps<T>> {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthenticationProvider>
            {this.props.children}
          </AuthenticationProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default DelgazWrapper;
