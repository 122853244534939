import { toggleLoader } from '@actions/LoaderActions';
import { getBulkImportList } from '@api/revizii/revizii';
import Pagination from '@components/common/Pagination/Pagination';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const STATUS_IN_ASTEPTAREA_CONFIRMARII = 'in asteptarea confirmarii';
const STATUS_IN_PROCESARE = 'in procesare';
const STATUS_FINALIZATA = 'finalizata';

type EntryType = {
  bulkImportId: number;
  entryDate: string;
  finalCount: string;
  status: string;
};

const IstoricIncarcareInMasa = () => {
  const [list, setList] = useState<EntryType[]>([]);
  //eslint-disable-next-line
  const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
  //eslint-disable-next-line
  const [selectedItemConfirm, setSelectedItemConfirm] = useState<any>(null);

  const [error, setError] = useState<any>('');

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    let payload = {
      pageNumber: currentPage,
      pageSize: perPage
    };
    getBulkImportList(payload)
      .then((res) => {
        setList(res.items);
        setTotalItemsCount(res.metadata.totalItemsCount);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  }, [currentPage, perPage]);

  return (
    <Fragment>
      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      {list && list.length > 0 && (
        <div className="general-table-container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Dată încărcare</th>
                <th>Nr. total fișe/Nr. fișe înregistrate</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map((item: EntryType, index: number) => (
                <tr key={index}>
                  <td>{item.bulkImportId}</td>
                  <td>{item.entryDate ? moment(item.entryDate).format('DD.MM.YYYY') : '-'}</td>
                  <td>{item.finalCount}</td>
                  <td>{item.status}</td>

                  <td>
                    <div className="align-items-center d-flex">
                      {item.status == STATUS_IN_PROCESARE || item.status == STATUS_FINALIZATA ? (
                        <Button
                          onClick={() => {
                            setSelectedItemDetails(item);
                            window.location.href = `/detalii-incarcare-in-masa?bulkId=${item.bulkImportId}`;
                          }}
                          className="ms-2 btn-wide"
                        >
                          Detalii
                        </Button>
                      ) : (
                        item.status == STATUS_IN_ASTEPTAREA_CONFIRMARII && (
                          <Button
                            onClick={() => {
                              setSelectedItemConfirm(item);
                              window.location.href = `/incarcare-in-masa-fise-noi?bulkId=${item.bulkImportId}`;
                            }}
                            className="ms-2 btn-wide"
                          >
                            Confirmare
                          </Button>
                        )
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Pagination
        currentPage={currentPage}
        totalElements={totalItemsCount}
        perPage={perPage}
        paginate={paginate}
        setElementsPerPage={setElementsPerPage}
      />
    </Fragment>
  );
};

export default IstoricIncarcareInMasa;
