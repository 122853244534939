import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import LoginPopupForm from './LoginPopupForm';
import { IState } from '@type/store';
import { useSelector } from 'react-redux';

const LoginPopup = () => {
  const URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const alreadyLoggedIn: string | undefined = useSelector<IState>((state) => state.auth.email) as string;
  const [isClosed, setIsClosed] = useState(true);
  const [initiated, setInitiated] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!initiated) {
        setIsClosed(false);
    
        wait(500).then(() => {
          let popup = document.getElementById('login-popup');
          popup?.setAttribute('style', `right: ${0}`);
        });

        setInitiated(true);
    }
  }, [initiated]);

  useEffect(() => {
    if (isClosed && initiated) {
      wait(120000).then(() => {
        let popup = document.getElementById('login-popup');
        popup?.setAttribute('style', `right: ${0}`);
        setIsClosed(false);
      });
    }
  }, [isClosed]);

  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

  const wait = async (value: number) => {
    await delay(value);
  };

  const close = () => {
    let popup = document.getElementById('login-popup');
    popup?.setAttribute('style', `right: -330px`);
    setIsClosed(true);
  };

  const renderPopup = () => {
    return <div className="login-popup" id="login-popup" ref={ref}>
    <div className="popup-header">
      <h5 className="head-text">Online e mai simplu</h5>
      <FontAwesomeIcon icon={faXmark} className="close-icon" onClick={() => close()} />
    </div>
    <div className="popup-container">
      <p className="popup-text mb-4"><FontAwesomeIcon icon={faKey} className="icon" />
          Intră în contul tău Delgaz sau <span className="popup-text-link" onClick={() => window.location.href = `${URL}/login`}>creează-ți un cont acum</span>
      </p>
      <LoginPopupForm />
      <br />
      <p className="popup-text m-0">Vei fi ghidat pas cu pas în procesul de racordare, astfel ai siguranța datelor și o cerere completă.</p>
    </div>
  </div>
  }

  return (
    <>
        { alreadyLoggedIn ? null : renderPopup() }
    </>
  );
};

export default LoginPopup;