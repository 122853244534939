import React, { useState, useEffect, Fragment } from 'react';
import { toggleLoader } from '@actions/LoaderActions';
import { deleteAnexa, getListAnexe } from '@api/revizii/revizii';
import Pagination from '@components/common/Pagination/Pagination';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import * as notification from '@lib/Notification';
import SortAscDesc from '@components/common/Sort/SortAscDesc';
import ChoiceModal from '@components/common/Modal/ChoiceModal';

type RevisionType = {
  id_anexa: number;
  pas: number;
  afisare_pas: number;
  cod_loc_consum: string;
  data_incarcare: string;
  nr_inreg_oe: string;
  data_ultima_revizie: string;
  judet: string;
  localitate: string;
  tip_revizie: string;
};

const ListaFiseDraft = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [revisions, setRevisions] = useState<RevisionType[]>([]);
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false);
  const [draftToDeleteId, setDraftToDeleteId] = useState<any>('');

  //sort
  const [sortColumn, setSortColumn] = useState('EntryDate');
  const [sortAscending, setSortAscending] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    getList();
  }, [currentPage, perPage, sortColumn, sortAscending]);

  const getList = () => {
    dispatch(toggleLoader(true));
    let payload = {
      sortBy: sortColumn,
      sortType: sortAscending ? 'asc' : 'desc',
      pageNumber: currentPage,
      pageSize: perPage
    };
    getListAnexe(payload)
      .then((res) => {
        setRevisions(res.items);
        setTotalItemsCount(res.metadata.totalItemsCount);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  const handleDeleteRev = async (idAnexa: number) => {
    dispatch(toggleLoader(true));

    await deleteAnexa(idAnexa)
      .then((res) => {
        getList();
      })
      .catch((err) => {
        if (err.status == 200) {
          getList();
          notification.success('Fișa a fost Ștearsă cu succes.');
        } else {
          notification.error('A intervenit o eroare În procesul de ștergere.');
          dispatch(toggleLoader(false));
        }
      });
  };

  const onSort = (column: string) => {
    setSortColumn(column);
    if (sortColumn == column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  return (
    <Fragment>
      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
      <div className="general-table-container">
        <table>
          <thead>
            <tr>
              <th>
                <SortAscDesc sortColumn={sortColumn} sortAscending={sortAscending} onSort={onSort} fieldId="POD" fieldName="CLC/NLC" />
              </th>
              <th>
                <SortAscDesc sortColumn={sortColumn} sortAscending={sortAscending} onSort={onSort} fieldId="CountyCode" fieldName="Județ" />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="LocalityCode"
                  fieldName="Localitate"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="RevisionTypeId"
                  fieldName="Tip lucrare"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="OENumber"
                  fieldName="Număr fișă"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="OEDate"
                  fieldName="Dată înregistrare fișă"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="LastRVDate"
                  fieldName="Data efectuării reviziei/verificării"
                />
              </th>
              <th>
                <SortAscDesc sortColumn={sortColumn} sortAscending={sortAscending} onSort={onSort} fieldId="Step" fieldName="Pas" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {revisions.map((rev: RevisionType, index: number) => (
              <tr key={index}>
                <td>
                  <p>{rev.cod_loc_consum}</p>
                </td>
                <td>{rev.judet}</td>
                <td>{rev.localitate}</td>
                <td>{rev.tip_revizie}</td>
                <td>{rev.nr_inreg_oe}</td>
                <td>{rev.data_incarcare ? moment(rev.data_incarcare).format('DD.MM.YYYY') : '-'}</td>
                <td>{rev.data_ultima_revizie ? moment(rev.data_ultima_revizie).format('DD.MM.YYYY') : '-'}</td>
                <td>{rev.afisare_pas}</td>
                <td>
                  <div className="d-flex flex-row">
                    <Button
                      className="me-2"
                      onClick={() => {
                        setDraftToDeleteId(rev.id_anexa);
                        setShowDeleteDraftModal(true);
                      }}
                    >
                      Șterge
                    </Button>
                    <Button
                      onClick={() => {
                        window.open(`/creare-fisa?id_anexa=${rev.id_anexa}&type=${rev.tip_revizie}&step=${rev.afisare_pas}`, '_blank');
                      }}
                    >
                      Editează
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalElements={totalItemsCount}
          perPage={perPage}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      </div>

      <ChoiceModal
        showModal={showDeleteDraftModal}
        modalTitle={'Sunteți sigur că doriți să ștergeți fișa?'}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={(option: any) => {
          if (option.id == 'DA') {
            handleDeleteRev(draftToDeleteId);
            setShowDeleteDraftModal(false);
          } else if (option.id == 'NU') {
            setShowDeleteDraftModal(false);
          }
        }}
        onHide={() => {
          setShowDeleteDraftModal(false);
        }}
        btnWide
      />
    </Fragment>
  );
};

export default ListaFiseDraft;
