import { toggleLoader } from '@actions/LoaderActions';
import { meterSelfreadingSave } from '@api/programariCitiri/programariCitiri';
import {
    LoadCanvasTemplateNoReload,
    loadCaptchaEngine,
    validateCaptcha
} from '@components/auth/createAccount/CaptchaLib';
import { Input } from '@components/common/Input';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ModalCitireContorSchema } from '../../helpers/schemas';

const ModalCitireContor = (props: { handleShow: any, show: boolean, modalData: any, clearModalData: any, handleSuccessModal: any, searchData: any }) => {
    const [fileList, setFileList] = useState<File[]>([] as File[]);
    const [message, setMessage] = useState('');
    const [captchaError, setCaptchaError] = useState('');
    const [acord1, setAcord1] = useState(false);
    const [acord2, setAcord2] = useState(false);
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [phone, setPhone] = useState('');
    // const [email, setEmail] = useState('');
    const [messageError, setMessageError] = useState('');
    const [error, setError] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [echipamente, setEchipamente] = useState<any>(null);
    const [indexError, setIndexError] = useState('');
    //eslint-disable-next-line
    const [wasVerified, setWasVerified] = useState(false);
    const [lastIndex, setLastIndex] = useState<any>(0);
    const messageRef = useRef(null);
    const [placeholder, setPlaceholder] = useState('Scrie-ne perioada în care te putem contacta pentru citirea contorului.');
    const [showNoIndexConfirmModal, setShowNoIndexConfirmModal] = useState(false);
    const [form, setForm] = useState<any>(null);

    //eslint-disable-next-line
    const { handleShow, show, modalData, clearModalData, handleSuccessModal, searchData } = props;

    const dispatch = useDispatch();

    //@ts-ignore
    const handleClickOutside = (event) => {
        //@ts-ignore
        if (messageRef.current && !messageRef.current.contains(event.target)) {
            setPlaceholder('Scrie-ne perioada în care te putem contacta pentru citirea contorului.');
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (modalData && modalData.echipamente) {
            let equip = modalData.echipamente;

            equip?.map((e: any, eIndex: number) => {
                e.registri.map((r: any, rIndex: number) => {
                    r.indexVechi = r.index;
                    r.index = '';
                    r.error = '';
                });
            });

            setEchipamente(equip);

            let lastEq = modalData.echipamente[modalData.echipamente.length];
            let lastIdx = lastEq?.registri[lastEq.registri.length].index;

            setLastIndex(lastIdx);
        }
    }, [modalData]);

    const handleFileListChange = (list: File[]) => {
        setFileList(list);
    };

    const submit = (values: any) => {
        setMessageError('');
        setCaptchaError('');
        setError('');

        let wasValidated = true;
        let user_captcha = (document.getElementById('user_captcha_input') as HTMLInputElement).value;

        if (validateCaptcha(user_captcha, true, 'programari_citiri') === false) {
            setCaptchaError('Codul de securitate nu este valid.');
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
        }

        if (wasValidated && validateCaptcha(user_captcha, true, 'programari_citiri') === true && checkIndexes(echipamente)) {
            let formData = new FormData();

            formData.append('notificationId', modalData.notificationId);
            formData.append('firstname', values.firstName);
            formData.append('lastname', values.lastName);
            formData.append('phonenumber', values.phone);
            formData.append('email', values.email);
            formData.append('message', message);

            if (!areAllIndexesEmpty(echipamente)) {
                let e: any = [];
                
                echipamente.map((echipament: any, index: number) => {
                    e.push({echipamentName: echipament.echipamentName, registri: []});

                    echipament.registri.map((registru: any, idx: number) => {
                        if (registru.index !== '') {
                            e[index].registri.push({reg: registru.reg, index: registru.index });
                        }
                    });
                });

                // echipamente.map((echipament: any, index: number) => {
                //     formData.append(`echipamente[${index}].EchipamentName`, echipament.echipamentName);

                //     echipament.registri.map((registru: any, idx: number) => {
                //         if (registru.index !== '') {
                //             formData.append(`echipamente[${index}].registri[${idx}].reg`, registru.reg);
                //             formData.append(`echipamente[${index}].registri[${idx}].index`, registru.index);
                //         }
                //     })
                // })

                e.map((echipament: any, index: number) => {
                    formData.append(`echipamente[${index}].EchipamentName`, e[index].echipamentName);

                    echipament.registri.map((registru: any, idx: number) => {
                        formData.append(`echipamente[${index}].registri[${idx}].reg`, registru.reg);
                        formData.append(`echipamente[${index}].registri[${idx}].index`, registru.index);
                    });
                });
            }

            for (let i = 0; i < fileList.length; i++) {
                //@ts-ignore
                formData.append('documents', fileList[i]);
            }

            if (areAllIndexesEmpty(echipamente) && (echipamente[0].zziD_AMR !== 'TAR' && echipamente[0].zziD_AMR !== 'DEC')) {
                const modal = document.querySelector('.meter-reading-modal');
                modal?.classList.add('d-none');
                setForm(formData);
                setShowNoIndexConfirmModal(true);
            } else {
                apiCall(formData);
            }
        }
    };

    const apiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await meterSelfreadingSave(payload)
            .then((response) => {
                if (typeof response === 'number') {
                    setShowNoIndexConfirmModal(false);
                    handleSuccessModal(true);
                    setMessage('');
                    setAcord1(false);
                    setAcord2(false);
                    setCaptcha('');
                    setMessageError('');
                    setCaptchaError('');
                    setIndexError('');
                    setError('');
                    setFileList([]);
                }
            })
            .catch((err) => {
                setError(err);
            });

        dispatch(toggleLoader(false));
    };

    const checkIndexes = (equipments: any) => {
        let e = equipments;

        // Clear all errors
        e.map((equipment: any, index: number) => {
            equipment.registri.map((registru: any, idx: number) => {
                registru.error = '';
            });
        });

        if (!areAllIndexesEmpty(equipments)) {
            // Validation 1 - Check if indexes have correct value
            e.map((equipment: any, index: number) => {
                equipment.registri.map((registru: any, idx: number) => {
                    if (registru.index < registru.indexVechi && registru.index !== '') {
                        registru.error = 'Indexul transmis trebuie să fie mai mare (sau egal) decât ultimul index înregistrat.';
                    }
                });
            });

            // Validation 2 - Check absolute value for index difference
            e.map((equipment: any, index: number) => {
                equipment.registri.map((registru: any, idx: number) => {
                    if ((searchData.utility === 1 && registru.index >= registru.indexVechi && (registru.index - registru.indexVechi > 1500))) {
                        registru.error = 'Cantitatea de energie electrică consumată este mai mare decât 1500 kWh.';
                    }

                    if ((searchData.utility === 2 && registru.index >= registru.indexVechi && (registru.index - registru.indexVechi > 700))) {
                        registru.error = 'Cantitatea de gaze naturale consumată este mai mare decât 700 mc.';
                    }
                });
            });
        }

        setEchipamente([...e]);

        // Check if all indexes passed the validations
        let isValid = true;

        e.map((equipment: any, index: number) => {
            equipment.registri.map((registru: any, idx: number) => {
                if (registru.error !== '') {
                    isValid = false;
                    setError('Te rugăm să verifici informațiile transmise.');
                }
            });
        });

        return isValid;
    };

    const areAllIndexesEmpty = (equipments: any) => {
        let empty = true;

        equipments.map((equipment: any, index: number) => {
            equipment.registri.map((registru: any, idx: number) => {
                if (registru.index !== '') {
                    empty = false;
                }
            });
        });

        return empty;
    };

    const onIndexChange = (value: string, index: number, idx: number) => {
        let equip = echipamente;

        equip?.map((e: any, eIndex: number) => {
            e.registri.map((r: any, rIndex: number) => {
                if (eIndex === index && rIndex === idx) {
                    r.index = value;
                }
            });
        });

        setEchipamente([...equip]);
    };

    const renderEquipments = (equipments: any) => {
        return (
            <div className="mt-2 mb-2">
                {
                    equipments.map((equipment: any, index: number) => {
                        return (
                            <div key={index}>
                                <span>Echipament: </span><span className="fw-bold">{equipment.echipamentName}</span>
                                {
                                    equipment.registri.map((registru: any, idx: number) => {
                                        return (
                                            <div className="equipment mt-2 mb-1" key={idx}>
                                                <Row>
                                                    <Col className='mb-3'>
                                                        <Input value={registru.reg} label="Registru" disabled mbZero />
                                                    </Col>
                                                    <Col className='mb-3'>
                                                        <Input
                                                            value={registru.indexVechi}
                                                            label="Index vechi"
                                                            disabled
                                                            mbZero
                                                        />
                                                    </Col>
                                                    <Col className='mb-3'>
                                                        <Input
                                                            value={registru.index}
                                                            label="Index nou"
                                                            onChange={(e) => {
                                                                if (/^[0-9]+$/.test(e.target.value) || e.target.value === '') {
                                                                    onIndexChange(e.target.value, index, idx);
                                                                }
                                                            }}
                                                            mbZero
                                                            className={registru.error !== '' ? 'forced-focus' : ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                {registru.error !== '' && <Row>
                                                    <div className="fw-bold red text-center">{registru.error}</div>
                                                </Row>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    const isButtonDisabled = () => {
        if (!acord1 || !acord2 || captcha === '' || message === '') {
            return true;
        } else {
            return false;
        }
    };

    //eslint-disable-next-line
    const verifyIndex = () => {
        setIndexError('');
        setWasVerified(true);

        echipamente.map((e: any, index: number) => {
            e.registri.map((r: any, idx: number) => {
                if (r.index < lastIndex) {
                    setWasVerified(false);
                    setIndexError('Valoarea acestuia trebuie să fie mai mare (sau egal) decât ultimul index înregistrat - ');
                }
            });
        });
    };

    const renderNoIndexConfirmModal = () => {
        return <Modal
            show={showNoIndexConfirmModal}
            backdrop={'static'}
            centered
            className="meter-reading-confirm-modal"
            onHide={() => {
                setShowNoIndexConfirmModal(false);

                const modal = document.querySelector('.meter-reading-modal');
                modal?.classList.remove('d-none');
            }} 
            style={{ zIndex: '1056' }}
            onClose={() => {
                setShowNoIndexConfirmModal(false);

                const modal = document.querySelector('.meter-reading-modal');
                modal?.classList.remove('d-none');
            }}
        >
            <Modal.Header closeButton>
                <div className="p-0">
                    <h5 className="m-0 modal-title">Confirmare</h5>
                </div>
            </Modal.Header>
            <Modal.Body>
                <span>Ești sigur că vrei să trimiți mesajul fară index autocitit?</span>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => apiCall(form)}>Da</Button>
                <Button 
                    onClick={() => {
                        setShowNoIndexConfirmModal(false);

                        const modal = document.querySelector('.meter-reading-modal');
                        modal?.classList.remove('d-none');
                    }}
                >
                    Nu
                </Button>
            </Modal.Footer>
        </Modal>;
    };

    return (
        <Fragment>
            {renderNoIndexConfirmModal()}
            <Modal show={show} backdrop={'static'} onHide={() => {
                handleShow(false);
                setMessage('');
                setAcord1(false);
                setAcord2(false);
                setCaptcha('');
                setMessageError('');
                setCaptchaError('');
                setIndexError('');
                setError('');
                setFileList([]);

            }} className="meter-reading-modal">
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                    }}
                    onSubmit={(v) => submit(v)}
                    validationSchema={ModalCitireContorSchema}
                >
                    {(props) => (
                        <Form className="w-100">
                            <Modal.Header closeButton>
                                <div className="p-0">
                                    <h5 className="m-0 modal-title">Mesaj legat de citirea contorului</h5>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="client-type-section mb-0">
                                    <Row className="client-type-section-header">
                                        <Col xs={12} sm={6}>
                                            <div className="section-red-border" />
                                            <h5 className="m-0">Date de identificare loc de consum</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="separator" />
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col sm={6} className="flex-column d-flex">
                                            <Input value={modalData.clientName !== null ? (modalData.clientName + ' ' + modalData.clientFirstName) : ''} disabled label="Nume și prenume/Denumire client" />
                                        </Col>
                                        <Col sm={6} className="flex-column d-flex">
                                            <Input value={modalData.consumptionPointCode} disabled label="Cod loc consum" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} className="flex-column d-flex">
                                            <Input value={modalData.consumptionPointAddress} disabled label="Adresă loc consum" />
                                        </Col>
                                        {/* <Col xs={6} className="flex-column d-flex">
                                            <Input value={modalData.equipment} disabled label="Echipament" />
                                        </Col> */}
                                        <Col sm={6} className="flex-column d-flex">
                                            <Input value={modalData.material} disabled label="Tip contor" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} className="flex-column d-flex">
                                            <Input value={modalData.series} disabled label="Serie" />
                                        </Col>
                                    </Row>
                                </div>
                                <br></br>
                                <div className="client-type-section mt-2">
                                    <Row className="client-type-section-header">
                                        <Col xs={12} sm={4}>
                                            <div className="section-red-border" />
                                            <h5 className="m-0">Date de contact</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col xs={12}>
                                            <div className="separator" />
                                        </Col>
                                    </Row>
                                    <span>Aceste informații sunt necesare pentru ca, colegii noștri să te poată contacta  în vederea citirii/ înlocuirii contorului.</span>
                                    <Row className="mt-1">
                                        <Col xs={12} sm={6} className='mt-3'>
                                            <Field label='lastName' id="lastName" name="lastName" placeholder="Nume">
                                                {({
                                                    //@ts-ignore
                                                    meta: { touched, error }
                                                }) =>
                                                    <Input
                                                        mbZero
                                                        value={props.values.lastName}
                                                        label='Nume'
                                                        onBlur={() => props.setFieldTouched('lastName')}
                                                        onChange={(e) => props.setFieldValue('lastName', e.target.value)}
                                                    />
                                                }
                                            </Field>
                                            <ErrorMessage name="lastName" render={msg => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                                        </Col>
                                        <Col xs={12} sm={6} className='mt-3'>
                                            <Field label='firstName' id="firstName" name="firstName" placeholder="Prenume">
                                                {({
                                                    //@ts-ignore
                                                    meta: { touched, error }
                                                }) =>
                                                    <Input
                                                        mbZero
                                                        value={props.values.firstName}
                                                        label='Prenume'
                                                        onBlur={() => props.setFieldTouched('firstName')}
                                                        onChange={(e) => props.setFieldValue('firstName', e.target.value)}
                                                    />
                                                }
                                            </Field>
                                            <ErrorMessage name="firstName" render={msg => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                                        </Col>
                                    </Row>
                                    <span className="property-label" style={{ fontSize: '14px' }}>Numele și prenumele se vor completa doar dacă persoana de contact este diferită față e titularul de contract.</span>
                                    <Row className="mt-1">
                                        <Col xs={12} sm={6} className='mt-3'>
                                            <Field label='phone' id="phone" name="phone" placeholder="Telefon*">
                                                {({
                                                    //@ts-ignore
                                                    meta: { touched, error }
                                                }) =>
                                                    <Input
                                                        mbZero
                                                        value={props.values.phone}
                                                        label='Telefon*'
                                                        onBlur={() => props.setFieldTouched('phone')}
                                                        onChange={(e) => props.setFieldValue('phone', e.target.value)}
                                                    />
                                                }
                                            </Field>
                                            <ErrorMessage name="phone" render={msg => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                                        </Col>
                                        <Col xs={12} sm={6} className='mt-3'>
                                            <Field label='email' id="email" name="email" placeholder="Email*">
                                                {({
                                                    //@ts-ignore
                                                    meta: { touched, error }
                                                }) =>
                                                    <Input
                                                        mbZero
                                                        value={props.values.email}
                                                        label='Email*'
                                                        onBlur={() => props.setFieldTouched('email')}
                                                        onChange={(e) => props.setFieldValue('email', e.target.value)}
                                                    />
                                                }
                                            </Field>
                                            <ErrorMessage name="email" render={msg => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                                        </Col>
                                    </Row>
                                    <span className="property-label" style={{ fontSize: '14px' }}>Îți respectăm viața privată, prin urmare datele tale personale vor fi prelucrate în conformitate cu prevederile legale privind protectia datelor cu caracter personal.</span>
                                </div>
                                <br></br>
                                <div className="client-type-section mt-4">
                                    <Row className="client-type-section-header">
                                        <Col xs={12} sm={4}>
                                            <div className="section-red-border" />
                                            <h5 className="m-0">Mesajul tău</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col xs={12}>
                                            <div className="separator" />
                                        </Col>
                                    </Row>
                                    <span>Înregistrează indexul contorului tău în câmpul de mai jos sau transmite-ne o poza cu acesta. Colegii noștri vor prelua aceste informații, și nu mai este necesară realizarea unei programări în vederea citirii contorului.</span>

                                    {/* {modalData && modalData.echipamente && renderEquipments(modalData.echipamente)} */}
                                    {echipamente && (echipamente[0].zziD_AMR !== 'TAR' && echipamente[0].zziD_AMR !== 'DEC') && renderEquipments(echipamente)}

                                    {indexError !== '' && <p className="red fw-bold">{indexError} {lastIndex}</p>}

                                    <div className="mt-2">
                                        <div className="mb-2">
                                            <span>Atașează poza la indexul citit pe contor.</span>
                                            <br></br>
                                            <span>Dacă contorul tău are mai multi regiștri, te rugăm să transmiți poză pentru toti regiștrii afișati.</span>
                                        </div>
                                        <UploadFile 
                                            fileList={fileList} 
                                            onSetFileList={handleFileListChange} 
                                            index={0} 
                                            acceptedExtensions={['.bmp', '.gif', '.jpg', '.jpeg', '.tiff', '.tif', '.png', '.doc', '.docx', '.pdf']}
                                        />
                                        <span className="property-label" style={{ fontSize: '14px' }}>Se acceptă documente în format BMP, GIF, JPG, JPEG, TIFF, TIF, PNG și fișiere DOC, DOCX, PDF. Documentele nu trebuie să depășească limita maximă de 10MB/fișier.</span>

                                        <div className="mt-4">
                                            <div className="field-group">
                                                <span className="input" ref={messageRef} onClick={() => setPlaceholder('')}>
                                                    <textarea
                                                        value={message}
                                                        placeholder={placeholder}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />
                                                    <label>Mesaj*</label>
                                                </span>
                                            </div>
                                            {/* <InputTextArea value={message} onChange={(e) => setMessage(e.target.value)} label="Mesaj*" mbZero /> */}
                                            {messageError !== '' && <p className="fw-bold red">{messageError}</p>}
                                            <span className="property-label mb-3" style={{ fontSize: '14px' }}>Maxim 500 de caractere.</span>
                                        </div>
                                    </div>
                                    <div className="separator mt-4" />
                                </div>
                                <div className="bottom-section mb-4">
                                    <br />
                                    <span>Completarea formularului de mai sus presupune faptul ca ești îndreptățit să folosești datele comunicate. Dacă nu ești destinatarul datelor solicitate te informăm că orice divulgare, copiere, distribuire sau luarea de măsuri în dependență de conţinutul acestor informaţii realizata cu rea credinţă este strict interzisă şi poți suporta consecinţele legale.</span>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <div className="mt-4 mb-2">
                                                <Col onClick={() => setAcord1(!acord1)} className="checkbox-container pointer">
                                                    <input className="create-account-checkbox" type="checkbox" checked={acord1} onChange={() => setAcord1(!acord1)} />
                                                    <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>Sunt de acord cu </span><a className="red" href="https://delgaz.ro/conditii-de-utilizare">Conditiile de utilizare ale site-ului.</a>
                                                </Col>
                                            </div>
                                            <div className="mb-4">
                                                <Col onClick={() => setAcord2(!acord2)} className="checkbox-container pointer">
                                                    <input className="create-account-checkbox" type="checkbox" checked={acord2} onChange={() => setAcord2(!acord2)} />
                                                    <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>Am luat cunoștință de informațiile privind </span><a className="red" href="https://delgaz.ro/conditii-de-utilizare">Protecția și confidențialitatea datelor.</a>
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <div className="d-flex flex-column mb-2">
                                                <Row className="w-100 mt-3">
                                                    <Col sm={12} md={6}>
                                                        <div className="d-flex">
                                                            <LoadCanvasTemplateNoReload id='programari_citiri' />
                                                            <FontAwesomeIcon
                                                                icon={faRotate}
                                                                style={{ width: '18px', height: '18px', cursor: 'pointer', color: '#ea1c0a' }}
                                                                onClick={() => loadCaptchaEngine('6', 'white', 'black', '', 'programari_citiri')}
                                                            />
                                                        </div>
                                                        <div>
                                                            <input
                                                                placeholder="Cod securitate"
                                                                id="user_captcha_input"
                                                                name="user_captcha_input"
                                                                type="text"
                                                                value={captcha}
                                                                onChange={(e) => setCaptcha(e.target.value)}
                                                            />
                                                        </div>
                                                        {captchaError !== '' && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{captchaError}</div>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    {error !== '' && <p className="fw-bold red mt-3">{error}</p>}
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            variant="secondary"
                                            style={{ marginRight: '8px' }}
                                            onClick={() => {
                                                handleShow(false);
                                                setMessage('');
                                                setAcord1(false);
                                                setAcord2(false);
                                                setCaptcha('');
                                                setMessageError('');
                                                setCaptchaError('');
                                                setIndexError('');
                                                setError('');
                                                setFileList([]);
                                            }}
                                        >
                                            Renunță
                                        </Button>
                                        <Button type="submit" disabled={isButtonDisabled()}>Salvează</Button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </Fragment>
    );
};

export default ModalCitireContor;