import React, { useState, useEffect } from 'react';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faRotate, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, Row, Col } from 'react-bootstrap';
import InPortofolio from './tabs/InPortofolio';
import { jwtDecode } from 'jwt-decode';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountDetails } from '@api/user/user';
import { GetCounties, GetLocalities } from '@api/geoApi';
import DatePFInPortofolio from './forms/DatePFInPortofolio';
import { Formik, Form } from 'formik';
import {
    RSSClientPFInPortofolioSchema,
    RSSClientPJInPortofolioSchema,
    RSSClientPFNotInPortofolioSchema,
    RSSClientPJNotInPortofolioSchema,
} from '../../helpers/schemas';
import Tippy from '@tippyjs/react';
import InputTextArea from '@components/common/Input/InputTextArea';
import { File } from '@components/common/UploadFile/UploadFile';
import { UploadFile } from '@components/common/UploadFile';
import {
    LoadCanvasTemplateNoReload,
    loadCaptchaEngine,
    validateCaptcha
} from '@components/auth/createAccount/CaptchaLib';
import DatePJInPortofolio from './forms/DatePJInPortofolio';
import PJNotInPortofolio from './forms/PJNotInPortofolio';
import PFNotInPortofolio from './forms/PFNotInPortofolio';
import { v4 as uuidv4 } from 'uuid';
import { registerRSS, verifyCPoint } from '@api/rss/rss';
import { IState } from '@type/store';

let PFValues = {
    cnp: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    county: '',
    locality: '',
    street: '',
    number: '',
    postalCode: '',
    pod: ''
};

let PJValues = {
    cui: '',
    name: '',
    phone: '',
    email: '',
    county: '',
    locality: '',
    street: '',
    number: '',
    postalCode: '',
    representative: '',
    pod: ''
};

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
};

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const RSSClientStepTwo = (props: { handleStep: any, category: string, subject: string, subSubject: string, handleSuccessModal: any }) => {
    const [racordat, setRacordat] = useState<boolean | null>(null);
    const [selected, setSelected] = useState<string | null>(null);
    const [clientType, setClientType] = useState<number | null>(null);
    const [roleType, setRoleType] = useState<number | null>(1);
    const [PFFormData, setPFFormData] = useState(JSON.parse(JSON.stringify(PFValues)));
    const [PJFormData, setPJFormData] = useState(JSON.parse(JSON.stringify(PJValues)));
    const [pod, setPod] = useState('');
    // const [county, setCounty] = useState('');
    // const [locality, setLocality] = useState<number>(0);
    const [requestType, setRequestType] = useState('');
    const [message, setMessage] = useState('');
    const [fileList, setFileList] = useState<File[]>([] as File[]);
    const [checkboxOne, setCheckboxOne] = useState(false);
    const [checkboxTwo, setCheckboxTwo] = useState(false);
    const [captcha, setCaptcha] = useState('');

    const [CPointData, setCPointData] = useState<any>(null);

    const [verifyCPPayload, setVerifyCPPayload] = useState<any>(null);
    const [validationError, setValidationError] = useState('');
    const [captchaError, setCaptchaError] = useState('');
    const [error, setError] = useState('');

    //eslint-disable-next-line
    const [wasDataLoaded, setWasDataLoaded] = useState(false);

    const { handleStep, category, subject, subSubject, handleSuccessModal } = props;
    const dispatch = useDispatch();

    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    useEffect(() => {
        getDetails();

        if (jwt.groups.includes('ClientFizic')) {
            setClientType(1);
        } else if (jwt.groups.includes('ClientJuridic')) {
            setClientType(2);
        }
    }, []);

    useEffect(() => {
        if (selected && CPointData) {
            loadCaptchaEngine('6', 'white', 'black', '', 'rss_client');
        }

        if (racordat === false) {
            loadCaptchaEngine('6', 'white', 'black', '', 'rss_client');
        }
    }, [selected, racordat, CPointData]);

    useEffect(() => {
        if (verifyCPPayload) {
            verifyCP(verifyCPPayload);
        }
    }, [verifyCPPayload]);

    const concatCompanyName = (data: any) => {
        if (data.firstName !== null && data.lastName === null) {
            return data.firstName;
        }
        if (data.firstName === null && data.lastName !== null) {
            return data.lastName;
        }
        if (data.firstName !== null && data.lastName !== null) {
            return data.lastName.concat(' ', data.firstName);
        }

        return '';
    };

    const verifyCP = async (payload: any) => {
        await verifyCPoint(payload).then((response) => {
            if (response && response.data && response.data.data) {
                setCPointData(response.data.data);

                if (response.data.data.isCompany === false) {
                    setPFFormData({
                        ...PFFormData,
                        firstName: response.data.data.firstName,
                        lastName: response.data.data.lastName,
                        county: response.data.data.countyCode,
                        locality: response.data.data.localityCode,
                        street: response.data.data.street,
                        number: response.data.data.streetNo,
                        cnp: response.data.data.clientCnp,
                        postalCode: response.data.data.postalCode,
                    });
                }

                if (response.data.data.isCompany === true) {
                    setPJFormData({
                        ...PJFormData,
                        name: concatCompanyName(response.data.data),
                        county: response.data.data.countyCode,
                        locality: response.data.data.localityCode,
                        street: response.data.data.street,
                        number: response.data.data.streetNo,
                        cui: response.data.data.clientCui,
                        postalCode: response.data.data.postalCode,
                    });
                }

                setWasDataLoaded(true);
            }
        });
    };

    const getDetails = async () => {
        dispatch(toggleLoader(true));

        await getAccountDetails().then((res) => {
            if (res) {
                let PFdata = {
                    cnp: '',
                    firstName: '',
                    lastName: '',
                    phone: res.mobilePhone,
                    email: res.email,
                    county: '',
                    locality: '',
                    street: '',
                    number: '',
                    postalCode: ''
                };

                setPFFormData(PFdata);

                let PJdata = {
                    cui: '',
                    name: '',
                    phone: res.mobilePhone,
                    email: res.email,
                    county: '',
                    locality: '',
                    street: '',
                    number: '',
                    postalCode: '',
                    representative: '',
                    pod: ''
                };

                setPJFormData(PJdata);
            }
        }).catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const handlePFForm = (data: any, field: any) => {
        let newData = PFFormData;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setPFFormData(newData);
    };

    const handlePJForm = (data: any, field: any) => {
        let newData = PJFormData;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setPJFormData(newData);
    };

    const handleRacordat = (type: boolean) => {
        setRacordat(type);
        setSelected(null);
        setRequestType('');
        setRoleType(1);
        setMessage('');
        setFileList([]);
        setCheckboxOne(false);
        setCheckboxTwo(false);
        setCaptcha('');
        setError('');
        setCaptchaError('');
        setValidationError('');
        setPFFormData({ ...PFValues });
        setPJFormData({ ...PJValues });
        getDetails();
    };

    const handleRoleType = (number: number) => {
        setRoleType(number);
    };

    const handleSelect = async (pod: string, address: string, name: string) => {
        dispatch(toggleLoader(true));
        setWasDataLoaded(false);

        setCPointData(null);
        setSelected(pod);
        setPod(pod);

        let data: any = {};

        data.street = address.split(',')[2].trim();
        data.number = address.split(',')[3].trim();

        let clientName = name.split(' ');
        let firstName: string = '';
        let lastName: string = '';

        if (clientName.length === 1) {
            firstName = lastName = clientName[0];
        } else if (clientName.length === 2) {
            firstName = clientName[1];
            lastName = clientName[0];
        } else if (clientName.length === 3) {
            lastName = clientName[0] + ' ' + clientName[1];
            firstName = clientName[2];
        } else if (clientName.length === 4) {
            //eslint-disable-next-line
            lastName = clientName[0] + ' ' + clientName[1];
            //eslint-disable-next-line
            firstName = clientName[2] + ' ' + clientName[3];
        }

        setVerifyCPPayload(null);

        setPFFormData({
            ...PFFormData,
            firstName: '',
            lastName: '',
            street: '',
            number: '',
            cnp: '',
            postalCode: '',
        });

        setPJFormData({
            ...PJFormData,
            name: '',
            street: '',
            number: '',
            cui: '',
            postalCode: '',
        });

        GetCounties().then(res => {
            if (res) {
                res.map((itm: CountyTemplate, idx: number) => {

                    if (itm.countyName === address.split(',')[0].trim()) {
                        data.county = itm.countyCode;

                        GetLocalities(itm.countyCode).then((resp) => {
                            if (resp) {
                                resp.map((loc: LocalityTemplate, index: number) => {
                                    if (loc.localityName.trim() === address.split(',')[1].trim() || loc.localityName === address.split(',')[1].trim()) {

                                        data.locality = loc.localityCode;

                                        setPFFormData({
                                            ...PFFormData,
                                            county: data.county,
                                            locality: data.locality,
                                        });

                                        setPJFormData({
                                            ...PJFormData,
                                            county: data.county,
                                            locality: data.locality,
                                        });

                                        let payload = {
                                            pod: pod,
                                            countyCode: itm.countyCode,
                                            localityCode: loc.localityCode,
                                        };

                                        setVerifyCPPayload(payload);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        dispatch(toggleLoader(false));
    };

    const handleFileListChange = (list: File[]) => {
        setFileList(list);
    };

    const filePayload = () => {
        const files: any[] = [];

        fileList.map((file: File) => {
            let add = {
                NumeDocument: file.name,
                ContinutDocument: file.base64.split(',')[1]
            };

            files.push(add);
        });

        return files;
    };

    const constructPayloadRacordat = () => {
        const atributeGenerice = {
            IdExtern: uuidv4(),
            Scenariu: 'RSS_LOCCONSUM',
            DocumenteSolicitare: filePayload()
        };

        const atributeSpecificeScenariului = {
            Judet: '',
            Localitate: '',
            CodJudet: clientType === 1 ? PFFormData.county : PJFormData.county,
            CodLocalitate: clientType === 1 ? PFFormData.locality : PJFormData.locality,
            TipAdresa: requestType,
            CategorieSubiect: category,
            Subiect: subject,
            SubSubiect: subSubject,
            CNP: CPointData.isCompany === false ? (PFFormData.cnp || '') : '',
            CUI: CPointData.isCompany === true ? (PJFormData.cui || '') : '',
            Telefon: clientType === 1 ? PFFormData.phone : PJFormData.phone,
            Descriere: message,
            Email: clientType === 1 ? PFFormData.email : PJFormData.email,
            AcordPrelucrareDateClient: 'DA',
            IsCompany: CPointData.isCompany,
            POD: pod,
        };

        const payload = {
            atributeGenerice,
            atributeSpecificeScenariului
        };

        return payload;
    };

    const constructPayloadNeracordat = () => {
        const atributeGenerice = {
            IdExtern: uuidv4(),
            Scenariu: 'RSS_LOCCONSUM_NO_POD',
            DocumenteSolicitare: filePayload()
        };

        const atributeNotInPortofolio = {
            ImobilRacordat: false,
            Strada: roleType === 1 ? PFFormData.street : PJFormData.street,
            Numar: roleType === 1 ? PFFormData.number : PJFormData.number,
            CodPostal: roleType === 1 ? PFFormData.postalCode : PJFormData.postalCode,
            Nume: roleType === 1 ? PFFormData.firstName : '',
            Prenume: roleType === 1 ? PFFormData.lastName : '',
            NumeCompanie: roleType === 1 ? '' : PJFormData.name,
            NumeReprezentantLegal: roleType === 1 ? '' : PJFormData.representative,
        };

        const atributeSpecificeScenariului = {
            Judet: '',
            Localitate: '',
            CodJudet: roleType === 1 ? PFFormData.county : PJFormData.county,
            CodLocalitate: roleType === 1 ? PFFormData.locality : PJFormData.locality,
            TipAdresa: requestType,
            CategorieSubiect: category,
            Subiect: subject,
            SubSubiect: subSubject,
            CNP: roleType === 1 ? PFFormData.cnp : '',
            CUI: roleType === 1 ? '' : PJFormData.cui,
            Telefon: roleType === 1 ? PFFormData.phone : PJFormData.phone,
            Descriere: message,
            Email: roleType === 1 ? PFFormData.email : PJFormData.email,
            AcordPrelucrareDateClient: 'DA',
            IsCompany: roleType === 1 ? false : true,
            ...atributeNotInPortofolio
        };

        const payload = {
            atributeGenerice,
            atributeSpecificeScenariului
        };

        return payload;
    };

    const submit = (values: any) => {
        setValidationError('');
        setCaptchaError('');
        setError('');

        let user_captcha = (document.getElementById('user_captcha_input') as HTMLInputElement).value;

        let isValid = true;

        if (validateCaptcha(user_captcha, true, 'rss_client') === false) {
            setCaptchaError('Codul de securitate nu este valid.');
            setCaptcha('');
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
            isValid = false;
        }

        if (message.length > 2000) {
            setValidationError('Ați depășit numărul de caractere permis!');
            isValid = false;
        }

        if (isValid) {
            apiCall(racordat ? constructPayloadRacordat() : constructPayloadNeracordat());
        }
    };

    const apiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await registerRSS(payload, 'ClientAccount')
            .then((response) => {
                if (response) {
                    if (response.data && response.data.status && response.data.status === 'Succes' && response.data.rssId && response.data.rssId !== 0) {
                        handleStep(1);
                        handleSuccessModal(true);
                        setError('Petiția ta a fost trimisă pentru procesare și poate fi vizualizată în secțiunea Listă petiții.');
                    } else {
                        setError('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.');
                    }
                }
            })
            .catch((error) => setError('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    const renderCaptcha = () => {
        return (
            <div className="d-flex flex-column mb-2">
                <Row className="w-100 mt-3">
                    <Col sm={12} md={6}>
                        <div className="d-flex">
                            <LoadCanvasTemplateNoReload id='rss_client' />
                            <FontAwesomeIcon
                                icon={faRotate}
                                style={{ width: '18px', height: '18px', cursor: 'pointer', color: '#ea1c0a' }}
                                onClick={() => loadCaptchaEngine('6', 'white', 'black', '', 'rss_client')}
                            />
                        </div>
                        <div>
                            <input
                                placeholder="Cod securitate"
                                id="user_captcha_input"
                                name="user_captcha_input"
                                type="text"
                                value={captcha}
                                onChange={(e) => setCaptcha(e.target.value)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const onTabChange = () => {
        setSelected(null);
        setRequestType('');
        setMessage('');
        setFileList([]);
        setCheckboxOne(false);
        setCheckboxTwo(false);
        setCaptcha('');
        setError('');
        setCaptchaError('');
        setValidationError('');
    };

    const getValidationSchema = () => {
        if (racordat && CPointData.isCompany === false) {
            return RSSClientPFInPortofolioSchema;
        }

        if (racordat && CPointData.isCompany === true) {
            return RSSClientPJInPortofolioSchema;
        }

        if (!racordat && roleType === 1) {
            return RSSClientPFNotInPortofolioSchema;
        }

        if (!racordat && roleType === 2) {
            return RSSClientPJNotInPortofolioSchema;
        }
    };

    const renderForm = () => {
        return <Formik
            initialValues={racordat ? (CPointData.isCompany === false ? PFFormData : PJFormData) : (roleType === 1 ? PFFormData : PJFormData)}
            onSubmit={(values) => submit(values)}
            validationSchema={getValidationSchema()}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {(props) => (
                <Form>
                    <h4 className="red">Date de identificare</h4>

                    {!racordat && <ChoiceComponent
                        options={[{ title: 'Persoană fizică', id: 1 }, { title: 'Persoană juridică', id: 2 }]}
                        onChange={(value: number) => {
                            props.setFieldValue('street', '');
                            props.setFieldValue('number', '');
                            props.setFieldValue('postalCode', '');
                            props.setTouched({}, false);
                            handleRoleType(value);
                        }}
                        value={roleType}
                    />}

                    {racordat && CPointData.isCompany === false && <DatePFInPortofolio props={props} formData={PFFormData} handleData={handlePFForm} />}
                    {racordat && CPointData.isCompany === true && <DatePJInPortofolio props={props} formData={PJFormData} handleData={handlePJForm} />}

                    {!racordat && roleType === 1 && <PFNotInPortofolio props={props} formData={PFFormData} handleData={handlePFForm} isEmailDisabled={true} />}
                    {!racordat && roleType === 2 && <PJNotInPortofolio props={props} formData={PJFormData} handleData={handlePJForm} />}

                    <h4 className="red mt-4">Mesajul tău</h4>
                    <h6 className="mb-2 mt-4">Alege tipul interacțiunii*</h6>
                    <Row>
                        <Col sm={12} md={6} className="d-flex align-items-center justify-content-between flex-row flex-wrap">
                            <span onClick={() => setRequestType('Solicitare')} className="d-flex">
                                <input
                                    className="custom-radio-button pointer"
                                    type="radio"
                                    value={1} checked={requestType === 'Solicitare'}
                                    onChange={() => setRequestType('Solicitare')}
                                    style={{ marginRight: '6px' }}
                                />
                                <span className="fw-bold pointer">Solicitare</span>
                                <Tippy
                                    content={
                                        <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                            <b>Solicitare</b> - petiție prin intermediul căreia soliciți informații și/sau prestarea unor servicii ce țin de activitatea noastră, a operatorului de distribuție.
                                        </div>
                                    }
                                    duration={0}
                                    placement="bottom"
                                >
                                    <div className="icon-div" style={{ marginLeft: '5px' }}>
                                        <FontAwesomeIcon icon={faCircleInfo} className="icon" style={{ color: '#dbdbdb' }} />
                                    </div>
                                </Tippy>
                            </span>

                            <span onClick={() => setRequestType('Sesizare')} className="d-flex">
                                <input
                                    className="custom-radio-button pointer"
                                    type="radio"
                                    value={1} checked={requestType === 'Sesizare'}
                                    onChange={() => setRequestType('Sesizare')}
                                    style={{ marginRight: '6px' }}
                                />
                                <span className="fw-bold pointer">Sesizare</span>
                                <Tippy
                                    content={
                                        <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                            <b>Sesizare</b> - petiție prin intermediul căreia sesizezi diferite aspecte, nereguli legate de activitatea operatorului de distribuție.
                                        </div>
                                    }
                                    duration={0}
                                    placement="bottom"
                                >
                                    <div className="icon-div" style={{ marginLeft: '5px' }}>
                                        <FontAwesomeIcon icon={faCircleInfo} className="icon" style={{ color: '#dbdbdb' }} />
                                    </div>
                                </Tippy>
                            </span>

                            <span onClick={() => setRequestType('Reclamatie')} className="d-flex">
                                <input
                                    className="custom-radio-button pointer"
                                    type="radio"
                                    value={1} checked={requestType === 'Reclamatie'}
                                    onChange={() => setRequestType('Reclamatie')}
                                    style={{ marginRight: '6px' }}
                                />
                                <span className="fw-bold pointer">Reclamație</span>
                                <Tippy
                                    content={
                                        <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                            <b>Reclamație</b> - petiție prin intermediul căreia îți manifești nemulțumirea față de serviciul prestat și soliciți o explicație sau remedierea situației.
                                        </div>
                                    }
                                    duration={0}
                                    placement="bottom"
                                >
                                    <div className="icon-div" style={{ marginLeft: '5px' }}>
                                        <FontAwesomeIcon icon={faCircleInfo} className="icon" style={{ color: '#dbdbdb' }} />
                                    </div>
                                </Tippy>
                            </span>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12}>
                            <InputTextArea label="Mesaj*" value={message} onChange={(e) => setMessage(e.target.value)} mbZero />
                            <span className="property-label mb-3" style={{ fontSize: '14px' }}>Maxim 2000 de caractere.</span>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-4">
                        <span className="mb-2">Dacă ai documente pe care dorești să ni le transmiți, te rugăm să le încarci în câmpul de mai jos:</span>
                        <Col xs={12} className="mb-2">
                            <div>
                                <UploadFile onSetFileList={handleFileListChange} fileList={fileList} index={0} acceptedExtensionsError={'Sunt acceptate următoarele formate de fișiere: '} />
                                <span className="property-label" style={{ fontSize: '14px' }}>Se acceptă documente în format BMP, GIF, JPG și PNG, fișiere .doc., pdf. si xls. Documentele nu trebuie să depășească limita maximă de 10MB/fișier.</span>
                            </div>
                        </Col>
                    </Row>

                    <div>
                        <span>Completarea formularului de mai sus presupune faptul ca ești îndreptățit să folosești datele comunicate. Dacă nu ești destinatarul datelor solicitate te informăm că orice divulgare, copiere, distribuire sau luarea de măsuri în dependență de conţinutul acestor informaţii realizata cu rea credinţă este strict interzisă şi poți suporta consecinţele legale.</span>
                        <Row>
                            <Col xs={12}>
                                <Row className="mt-3 mb-3">
                                    <Col className="checkbox-container pointer">
                                        <input className="create-account-checkbox" type="checkbox" checked={checkboxOne} onChange={() => setCheckboxOne(!checkboxOne)} />
                                        <span className="fw-bold checkbox-span" onClick={() => setCheckboxOne(!checkboxOne)} style={{ marginLeft: '5px' }}>Sunt de acord cu </span><a className="red hover-red pointer" href="https://delgaz.ro/conditii-de-utilizare" target="_blank" rel="noreferrer">Condițiile de utilizare ale site-ului.</a>
                                    </Col>
                                </Row>
                                <Row className="mt-3 mb-3">
                                    <Col className="checkbox-container pointer">
                                        <input className="create-account-checkbox" type="checkbox" checked={checkboxTwo} onChange={() => setCheckboxTwo(!checkboxTwo)} />
                                        <span className="fw-bold checkbox-span" onClick={() => setCheckboxTwo(!checkboxTwo)} style={{ marginLeft: '5px' }}>Am luat cunoștință de informațiile privind </span><a className="red hover-red pointer" href="https://delgaz.ro/protectia-datelor" target="_blank" rel="noreferrer">Protecția și confidențialitatea datelor.</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {renderCaptcha()}

                        {captchaError !== '' && <p className="red fw-bold">{captchaError}</p>}
                        {validationError !== '' && <p className="red fw-bold">{validationError}</p>}
                        {error !== '' && <p className="red fw-bold">{error}</p>}

                        <Button type="submit" disabled={requestType === '' || message === '' || !checkboxOne || !checkboxTwo || captcha === ''}>Trimite</Button>
                    </div>
                </Form>
            )}
        </Formik>;
    };

    const renderRacordat = () => {
        return <div className="mt-4">
            <p>Alege locul de consum pentru care depui o petiție:</p>
            <InPortofolio selected={selected} handleSelect={handleSelect} onTabChange={onTabChange} subject={category} />
            {selected && CPointData && renderForm()}
        </div>;
    };

    const renderNeracordat = () => {
        return <div className="mt-4">
            {renderForm()}
        </div>;
    };

    return (
        <div>
            <div className="mb-2">
                <Button onClick={() => handleStep(1)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>&nbsp;Înapoi</span>
                </Button>
            </div>
            <div>
                <ChoiceComponent
                    options={[{ title: 'Client cu imobil racordat la rețea', id: true }, { title: 'Client cu imobil neracordat la rețea', children: <Tippy
                    content={
                        <div className="p-3" style={{ background: 'rgba(0,0,0,0.9)', color: 'white', borderRadius: '6px' }}>
                            Imobilul este în curs de racordare/nu este racordat la reteaua de distribuție a Delgaz Grid
                        </div>
                    }
                    duration={0}
                    placement="top"
                >
                    <div className="icon-div">
                        <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                    </div>
                </Tippy>, id: false }]}
                    onChange={handleRacordat}
                    value={racordat}
                />
            </div>
            {racordat && renderRacordat()}
            {racordat === false && renderNeracordat()}
        </div>
    );
};

export default RSSClientStepTwo;