import Axios, { AxiosRequestConfig, AxiosResponse, Canceler, InternalAxiosRequestConfig } from 'axios';

// import { RefreshToken } from '@api/refreshToken';

import { allowedStatus, unauthorizedStatus } from '@constants/Api';
import { ONE_MINUTE, isDevelopment } from '@constants/Utils';

import { documentExportHeaders } from '@actions/BoActions';
import { authBaseUrl } from '@constants/ApiUrl';
import { ToNumber, ToString, isMaintenanceMode } from '@lib/Utils';
import { store } from '@lib/store';
// import { InvalidSubscriptionKey } from '@constants/Error';

// Start type definition
export type Proxy = {
    method: AuthAxiosRequestConfig['method'];
    endPoint: AuthAxiosRequestConfig['url'];
    data?: AuthAxiosRequestConfig['data'];
    withCredentials?: AuthAxiosRequestConfig['withCredentials'];
    withAuthBearer?: boolean;
    cancelRequest?: boolean;
};

type AuthAxiosRequestConfig = AxiosRequestConfig;

type ProxyResponse<T = any> = {
    httpCode: number;
    payload?: T;
};
// Stop type definition

// Set API proxy base URL based on environment
const baseURL = isDevelopment
    ? 'https://api-dev.delgaz.ro'
    : 'https://msapi.delgaz.ro';

const CancelToken = Axios.CancelToken;
let cancel: Canceler;

// Axios Instance
export const axios = Axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
    timeout: 15 * ONE_MINUTE
});

export const axiosEonsn = Axios.create({
  //dev
  // baseURL: 'http://dev.api-acces.eonsn.ro/EONBOServices/api',

  //test
  baseURL: 'https://test.api-acces.eonsn.ro/EONBOServices/api',
  headers: { 'Content-Type': 'application/json' },
  timeout: 15 * ONE_MINUTE
});

export const axiosOldSite = Axios.create({
  //test
  baseURL: 'https://portal.delgaz.ro',
  headers: { 'Content-Type': 'application/json' },
  timeout: 15 * ONE_MINUTE
});

axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // cancel request
        if (isMaintenanceMode() || config?.headers?.cancelRequest) {
            cancel();
        }

        config.cancelToken = new CancelToken((c) => {
            cancel = c;
        });

        const state = store.getState();

        if (config && config.headers && !config.headers.removeSub) {
            config.headers['Ocp-Apim-Subscription-Key'] = ToString(state.options.subscriptionKey);
        } else if (config && config.headers && config.headers.removeSub === true) {
            delete axios.defaults.headers.common['Ocp-Apim-Subscription-Key'];
            delete config.headers.removeSub;
        }

        return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response: AxiosResponse<ProxyResponse>) => {
        // Get status code httpCode for proxy call's, status for direct call
        const statusCode = (response.data && response.data.httpCode) || response.status;

        if (
            // CHECK if request failed cause UNAUTHORIZED and not an "/auth/myline" CALL
            unauthorizedStatus.indexOf(ToNumber(statusCode)) !== -1 &&
            ToString(response.config.url).indexOf(authBaseUrl) === -1 &&
            response.config.headers &&
            !response.config.headers.isAlreadyFetching
        ) {
            // const originalRequest = response.config;

            // return RefreshToken(originalRequest);
        } else if (!response.data) {
            // CHECK if response data NOT exist
            return Promise.reject(response);
        } else if (allowedStatus.indexOf(statusCode) === -1) {
            // CHECK if response status is ALLOWED
            return Promise.reject((response.data.payload && response.data.payload.error) || response.data.payload);
        }

        if(response && response.config && response.config.url && response.config.url.includes('/export')) {
            store.dispatch(documentExportHeaders(response.headers));
        }

        return Promise.resolve(response.data.payload || response.data);
    },
    (error) => {
        const response = error.response;
        const statusCode = (response.data && response.data.httpCode) || response.status;

        if (
            // CHECK if request failed cause UNAUTHORIZED and not an "/auth/myline" CALL
            unauthorizedStatus.indexOf(ToNumber(statusCode)) !== -1 &&
            ToString(response.config.url).indexOf(authBaseUrl) === -1 &&
            response.config.headers &&
            !response.config.headers.isAlreadyFetching
        ) {
            // const originalRequest = response.config;

            // return RefreshToken(originalRequest);
        }

        return Promise.reject(response.data);
    }
);

// Proxy request
export async function proxy<T = any>(proxyData = {} as Proxy, options = {} as AuthAxiosRequestConfig): Promise<T> {
    const state = store.getState();

    const headers: AuthAxiosRequestConfig['headers'] = {
        ...options.headers
        // withAuthBearer: proxyData.withAuthBearer
    };

    delete options?.headers?.sync;

    const endpoint = `${state.options.apiHost ?? ''}${proxyData.endPoint}`;

    try {
        return ((await axios.request<T>({
            ...options,
            method: proxyData.method,
            // url: `${headers.sync ? proxyData.endPoint : state.options.apiHost ?? ''}${proxyData.endPoint}`,
            url: headers.sync ? proxyData.endPoint : endpoint,
            headers,
            data: proxyData.data ? proxyData.data : undefined,
            timeout: state.options.timeoutInMilSec || 15 * ONE_MINUTE,
            withCredentials: proxyData.withCredentials
        })) as unknown) as Promise<T>;
    } catch (error) {
        throw error;
    }
}

// Proxy GET request
export function proxyGet<T = any>(url = '', options = {} as AuthAxiosRequestConfig) {
    return (axios.get<T>(url, options) as unknown) as T;
}

// Proxy POST request
export function proxyPost<T = any>(url = '', formData = {} as any, options = {} as AuthAxiosRequestConfig) {
    return (axios.post<T>(url, formData, options) as unknown) as T;
}

// Proxy GET request from Eonsn
export function proxyGetEonsn<T = any>(url = '', options = {} as AuthAxiosRequestConfig) {
  return (axiosEonsn.get<T>(url, options) as unknown) as T;
}

// Proxy POST request from Eonsn
export function proxyPostEonsn<T = any>(url = '', options = {} as AuthAxiosRequestConfig) {
  return (axiosEonsn.post<T>(url, options) as unknown) as T;
}

// Proxy POST request Old Site
export function proxyPostOldSite<T = any>(url = '', formData = {} as any, options = {} as AuthAxiosRequestConfig) {
  return (axiosOldSite.post<T>(url, formData, options) as unknown) as T;
}
