import axios from 'axios';
import {store} from '@lib/store';

export function getElectronicRegistryList(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/iconnect/ElectronicRegistry/list?${payload.join('&')}`,
        {
            headers: {
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};