import {Proxy, proxy} from '@api/azureProxy';
import {store} from '@lib/store';
import axios from 'axios';

export function getTypes(payload: any, utility?: string) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/iconnect/DataType/get_types?requestType=${utility || 'EE'}`,
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepOne(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/iconnect/RequestProsumer/step1',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getStepOne(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/step1?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepTwo(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/iconnect/RequestProsumer/step2',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getStepTwo(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/step2?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepThree(payload: any) {
    const state = store.getState();
    const ENDPOINT_POST_3 = 'https://msapi.delgaz.ro/iconnect/RequestProsumer/step3';

    return axios.post(
        ENDPOINT_POST_3,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );

    // const proxyData: Proxy = {
    //     method: 'POST',
    //     endPoint: `/iconnect/RequestProsumer/step3`,
    //     data: payload,
    //     withAuthBearer: true,
    // };
    //
    // return proxy(proxyData, {
    //     headers: {
    //         'Authorization': 'Bearer ' + state.auth.token,
    //         'Access-Control-Allow-Headers': 'Authorization',
    //         'Access-Control-Allow-Credentials': true,
    //         'access-control-allow-methods': 'POST',
    //     }
    // });
}

export function getStepThree(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/step3?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepFour(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/iconnect/RequestProsumer/step4',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getStepFour(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/step4?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepFive(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/iconnect/RequestProsumer/step5',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getStepFive(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/step5?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getDocumentTypes(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/document_types?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

// export function postStepSix(payload: any) {
//     const state = store.getState();

//     const proxyData: Proxy = {
//         method: 'POST',
//         endPoint: '/iconnect/RequestProsumer/step6',
//         data: payload,
//         withAuthBearer: true,
//     };

//     return proxy(proxyData, {
//         headers: {
//             'Authorization': 'Bearer ' + state.auth.token,
//             'Access-Control-Allow-Headers': 'Authorization',
//             'Access-Control-Allow-Credentials': true,
//             'access-control-allow-methods': 'POST',
//         }
//     });
// }

export function postStepSix(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestProsumer/step6',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getStepSix(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/step6?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepSeven(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/iconnect/RequestProsumer/step7',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getStepSeven(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/step7?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getUserDetails() {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/authentication/User/account_details',
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function sendSMS(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/iconnect/RequestProsumer/send-sms?phoneNumber=${payload.phoneNumber}&requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function checkSMS(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/iconnect/RequestProsumer/check-sms?code=${payload.code}&requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getRequestsList(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/Request/list?${payload.join('&')}`,
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function exportPDF(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestProsumer/export-pdf?requestId=${payload}`,
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        },
        responseType: 'arraybuffer'
    });
}

export function deleteRequest(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'DELETE',
        endPoint: `/iconnect/RequestProsumer/delete?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'DELETE',
        }
    });
}

export function getGDPRFile() {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/iconnect/Request/gdpr-file',
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'GET',
        }
    });
};

export function getATR(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/SAP/atr?pod=${payload.pod}`,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'GET',
        }
    });
}