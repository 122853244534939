import { toggleLoader } from '@actions/LoaderActions';
import { getAnexaStepSix, postAnexaStepSix } from '@api/revizii/revizii';
import { DropdownOptionType } from '@components/common/Dropdown';
import DynamicTable from '@components/common/DynamicTable/DynamicTable';
import { Input } from '@components/common/Input';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';
import React, { Fragment, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type RVStepSixProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  idAnexa: string;
};

type StepSixType = {
  id_anexa: number;
  instalatie_conforma: boolean;
  instalatie_conforma_motiv: string;
  consumatoare_finale: {
    annexeDeviceId: number;
    tip: number;
    numar: number;
    debit: number;
    descriere: string;
    nr_doc_curatare: string;
    data_doc_curatare: string;
    nr_doc_consumatoare: string;
    daca_doc_consumatoare: string;
  }[];
};

const RVStepSix = (props: RVStepSixProps) => {
  const dispatch = useDispatch();
  const { setStep, currentStep, progress, setProgress } = props;

  const [tipuriSarcini, setTipuriSarcini] = useState<DropdownOptionType[]>([]);
  const [devicesConsumatoareDeCombustibiliGazosi, setDevicesConsumatoareDeCombustibiliGazosi] = useState<any[]>([]);
  const [instalatiaIndeplinesteConditiile, setInstalatiaIndeplinesteConditiile] = useState<any>(null);
  const [motiv, setMotiv] = useState('');

  //errors
  const [error, setError] = useState('');
  const [errorDevices, setErrorDevices] = useState<any>(false);
  const [errorMotiv, setErrorMotiv] = useState(false);
  const [errorInstalatiaIndeplinesteConditiile, setErrorInstalatiaIndeplinesteConditiile] = useState(false);

  const dynamicTableFormat = {
    columns: [
      { name: 'nrCrt', type: 'id', dataType: '', colName: 'Nr. CRT' },
      { name: 'tip', type: 'select', options: tipuriSarcini, dataType: 'numeric', colName: 'Tip', width: '15rem' },
      { name: 'numar', type: 'input', dataType: 'string', colName: 'Numar', width: '8rem' },
      { name: 'debit', type: 'input', dataType: 'numeric', floatPrecision: 2, colName: 'Debit', width: '8rem' },
      { name: 'descriere', type: 'input', dataType: 'string', colName: 'Descriere', width: '10rem' },
      {
        name: 'nrDocumentCosuri',
        type: 'input',
        dataType: 'string',
        colName: 'Numar document curatare cosuri si canale de evacuare',
        info: 'Documentul care atesta curatarea cosurilor si a canalelor de evacuare a gazelor de ardere',
        width: '8rem'
      },
      { name: 'dataDocumentCosuri', type: 'input', dataType: 'date', colName: 'Data document curatare cosuri si canale de evacuare' },
      {
        name: 'nrDocumentConsumatori',
        type: 'input',
        dataType: 'string',
        colName: 'Numar document verificare consumatori combustibili gazosi',
        info: 'Documentul care atesta verificarea aparatelor consumatoare de combustibili gazosi',
        width: '8rem'
      },
      {
        name: 'dataDocumentConsumatori',
        type: 'input',
        dataType: 'date',
        colName: 'Data document verificare consumatori combustibili gazosi'
      }
    ]
  };

  useEffect(() => {
    if (tipuriSarcini.length > 0) {
      if (props.idAnexa) {
        dispatch(toggleLoader(true));
        getAnexaStepSix(props.idAnexa)
          .then((res) => {
            dispatch(toggleLoader(false));
            initializeData(res);
          })
          .catch((err) => {
            dispatch(toggleLoader(false));
          });
      }
    }
  }, [tipuriSarcini]);

  useEffect(() => {
    if (props.dictionare) {
      setTipuriSarcini(props?.dictionare?.DeviceType);
    }
  }, [props.dictionare]);

  useEffect(() => {
    if (instalatiaIndeplinesteConditiile) {
      setMotiv('');
    }
  }, [instalatiaIndeplinesteConditiile]);

  useEffect(() => {
    let valid = true;
    devicesConsumatoareDeCombustibiliGazosi.forEach((device) => {
      if (
        !device.tip ||
        !device.numar ||
        !device.debit ||
        !device.descriere ||
        !device.nrDocumentCosuri ||
        !device.dataDocumentCosuri ||
        !device.nrDocumentConsumatori ||
        !device.dataDocumentConsumatori
      ) {
        valid = false;
      }
    });
    if (valid) {
      setErrorDevices(false);
    }
  }, [devicesConsumatoareDeCombustibiliGazosi]);

  useEffect(() => {
    if (instalatiaIndeplinesteConditiile != null) {
      setErrorInstalatiaIndeplinesteConditiile(false);
    }
    if (motiv) {
      setErrorMotiv(false);
    }
  }, [motiv, instalatiaIndeplinesteConditiile]);

  const initializeData = (values: StepSixType) => {
    setInstalatiaIndeplinesteConditiile(values.instalatie_conforma);
    setMotiv(values.instalatie_conforma_motiv);

    const initialDevicesConsumatoareDeCombustibiliGazosi = values.consumatoare_finale.map((consumator) => {
      return {
        annexeDeviceId: consumator.annexeDeviceId,
        tip: tipuriSarcini.find((ts) => ts.id == consumator.tip)?.name ?? '',
        numar: consumator.numar,
        debit: consumator.debit,
        descriere: consumator.descriere,
        nrDocumentCosuri: consumator.nr_doc_curatare,
        dataDocumentCosuri: consumator.data_doc_curatare,
        nrDocumentConsumatori: consumator.nr_doc_consumatoare,
        dataDocumentConsumatori: consumator.daca_doc_consumatoare
      };
    });
    setDevicesConsumatoareDeCombustibiliGazosi(initialDevicesConsumatoareDeCombustibiliGazosi);
  };

  const submit = () => {
    if (validateValues()) {
      dispatch(toggleLoader(true));
      let payload: any = {
        instalatie_conforma: instalatiaIndeplinesteConditiile,
        instalatie_conforma_motiv: motiv,
        consumatoare_finale: devicesConsumatoareDeCombustibiliGazosi.map((device) => {
          return {
            annexeDeviceId: device.annexeDeviceId ?? 0,
            tip: tipuriSarcini.find((ts) => ts.name == device.tip)?.id ?? '',
            numar: device.numar,
            debit: device.debit,
            descriere: device.descriere,
            nr_doc_curatare: device.nrDocumentCosuri,
            data_doc_curatare: device.dataDocumentCosuri,
            nr_doc_consumatoare: device.nrDocumentConsumatori,
            daca_doc_consumatoare: device.dataDocumentConsumatori
          };
        })
      };

      if (props.idAnexa) {
        payload.id_anexa = props.idAnexa;
      }

      postAnexaStepSix(payload)
        .then((res) => {
          props.setStep(props.currentStep + 1);
          props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            props.setStep(props.currentStep + 1);
            props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const validateValues = () => {
    let valid = true;

    if (instalatiaIndeplinesteConditiile == null) {
      setErrorInstalatiaIndeplinesteConditiile(true);
      valid = false;
    }

    if (instalatiaIndeplinesteConditiile == false) {
      if (!motiv) {
        setErrorMotiv(true);
        valid = false;
      }
    }

    devicesConsumatoareDeCombustibiliGazosi.forEach((device) => {
      if (
        !device.tip ||
        !device.numar ||
        !device.debit ||
        !device.descriere ||
        !device.nrDocumentCosuri ||
        !device.dataDocumentCosuri ||
        !device.nrDocumentConsumatori ||
        !device.dataDocumentConsumatori
      ) {
        setErrorDevices(true);
        valid = false;
      }
    });

    return valid;
  };

  return (
    <Fragment>
      <h4 className="red mt-2 mb-3">Condiţii tehnice de funcţionare</h4>

      <Row className="mb-3">
        <p className="p-gray m-0 mb-1">
          Instalaţia de utilizare a gazelor naturale îndeplineşte condiţiile tehnice de funcţionare în siguranţă prevăzute în prevederile
          normelor tehnice din domeniul gaze naturale*
        </p>
        <div className="d-flex">
          <span onClick={() => setInstalatiaIndeplinesteConditiile(true)} className="pointer">
            <input
              className="custom-radio-button pointer"
              type="radio"
              value={1}
              checked={instalatiaIndeplinesteConditiile == true}
              onChange={() => setInstalatiaIndeplinesteConditiile(true)}
              style={{ marginRight: '6px' }}
            />
            <span className="fw-bold">Da</span>
          </span>
          <span onClick={() => setInstalatiaIndeplinesteConditiile(false)} className="pointer ms-2">
            <input
              className="custom-radio-button pointer"
              type="radio"
              value={1}
              checked={instalatiaIndeplinesteConditiile == false}
              onChange={() => setInstalatiaIndeplinesteConditiile(false)}
              style={{ marginRight: '6px' }}
            />
            <span className="fw-bold">Nu</span>
          </span>
        </div>
        {errorInstalatiaIndeplinesteConditiile && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
      </Row>

      {instalatiaIndeplinesteConditiile == false && (
        <Row className="mb-3">
          <Input
            label="Motiv*"
            mbZero
            value={motiv}
            onChange={(e: any) => {
              setMotiv(e.target.value);
            }}
          />
          {errorMotiv && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul Motiv este obligatoriu.</div>}
        </Row>
      )}

      <Row>
        <p className="m-0 red">
          <strong>APARATELE CONSUMATOARE DE COMBUSTIBILI GAZOSI</strong>
        </p>
        <DynamicTable
          format={dynamicTableFormat}
          data={devicesConsumatoareDeCombustibiliGazosi}
          handler={(devices: any) => {
            setDevicesConsumatoareDeCombustibiliGazosi(devices);
          }}
          isEditing={true}
          emptyTable
          minimumNrOfRows={0}
          mbZero
          style={{ height: '32rem' }}
        />
        {errorDevices && <div style={{ color: 'red', fontWeight: 'bold' }}>Vă rugăm să completați toate câmpurile din tabel!</div>}
      </Row>

      {error && (
        <div className="mt-3" style={{ color: 'red', fontWeight: 'bold' }}>
          {error}
        </div>
      )}

      <StepsNavigationButtons
        className="mb-3"
        onClickPrevious={() => {
          setStep(currentStep - 1);
          currentStep === progress && setProgress(currentStep - 1);
        }}
        onClickNext={() => {
          submit();
        }}
      />
    </Fragment>
  );
};

export default RVStepSix;
