import { toggleLoader } from '@actions/LoaderActions';
import { getAnexaStepFive, postAnexaStepFive } from '@api/revizii/revizii';
import DynamicTable from '@components/common/DynamicTable/DynamicTable';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

type RVStepFiveProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  idAnexa: string;
};

type StepFiveType = {
  id_anexa: number;
  defecte: {
    annexeMalfunctionId: number;
    defect: string;
    mod_remediere: string;
    defect_remediat: boolean;
  }[];
};

const RVStepFive = (props: RVStepFiveProps) => {
  const dispatch = useDispatch();
  const { setStep, currentStep, progress, setProgress } = props;

  const [devicesCuDefecteConstatate, setDevicesCuDefecteConstatate] = useState<any[]>([]);

  //errors
  const [error, setError] = useState('');
  const [errorDevices, setErrorDevices] = useState<any>(false);

  const dynamicTableFormat = {
    columns: [
      { name: 'nrCrt', type: 'id', dataType: '', colName: 'Nr. CRT' },
      { name: 'defecteConstatate', type: 'input', dataType: 'string', colName: 'Defecte constatate', width: '8rem' },
      { name: 'modRemediere', type: 'input', dataType: 'string', colName: 'Modul de remediere a defectelor', width: '10rem' },
      {
        name: 'remediat',
        type: 'radio',
        options: [
          { id: 'DA', name: 'Da' },
          { id: 'NU', name: 'Nu' }
        ],
        colName: 'Defect remediat?'
      }
    ]
  };

  useEffect(() => {
    if (props.idAnexa) {
      dispatch(toggleLoader(true));
      getAnexaStepFive(props.idAnexa)
        .then((res) => {
          dispatch(toggleLoader(false));
          initializeData(res);
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
        });
    }
  }, []);

  useEffect(() => {
    let valid = true;
    devicesCuDefecteConstatate.forEach((device) => {
      if (!device.defecteConstatate || !device.modRemediere || !device.remediat) {
        valid = false;
      }
    });
    if (valid) {
      setErrorDevices(false);
    }
  }, [devicesCuDefecteConstatate]);

  const initializeData = (values: StepFiveType) => {
    let initialDevicesCuDefecteConstatate = values.defecte.map((def) => {
      return {
        annexeMalfunctionId: def.annexeMalfunctionId,
        defecteConstatate: def.defect,
        modRemediere: def.mod_remediere,
        remediat: def.defect_remediat ? 'DA' : 'NU'
      };
    });

    setDevicesCuDefecteConstatate(initialDevicesCuDefecteConstatate);
  };

  const submit = () => {
    if (validateValues()) {
      dispatch(toggleLoader(true));

      let payload: any = {
        defecte: devicesCuDefecteConstatate.map((device) => {
          return {
            // annexeMalfunctionId: 0,
            defect: device.defecteConstatate,
            mod_remediere: device.modRemediere,
            defect_remediat: device.remediat == 'DA' ? true : false
          };
        })
      };
      if (props.idAnexa) {
        payload.id_anexa = props.idAnexa;
      }

      postAnexaStepFive(payload)
        .then((res) => {
          props.setStep(props.currentStep + 1);
          props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            props.setStep(props.currentStep + 1);
            props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const validateValues = () => {
    let valid = true;

    devicesCuDefecteConstatate.forEach((device) => {
      if (!device.defecteConstatate || !device.modRemediere || !device.remediat) {
        setErrorDevices(true);
        valid = false;
      }
    });

    return valid;
  };

  return (
    <Fragment>
      <h4 className="red mt-2">Defectele constatate</h4>

      <DynamicTable
        format={dynamicTableFormat}
        data={devicesCuDefecteConstatate}
        handler={(devices: any) => {
          setDevicesCuDefecteConstatate(devices);
        }}
        isEditing={true}
        emptyTable
        minimumNrOfRows={0}
        style={{ height: '35rem' }}
      />

      {errorDevices && <div style={{ color: 'red', fontWeight: 'bold' }}>Vă rugăm să completați toate câmpurile din tabel!</div>}
      {error && (
        <div className="mt-3" style={{ color: 'red', fontWeight: 'bold' }}>
          {error}
        </div>
      )}

      <StepsNavigationButtons
        className="mb-3"
        onClickPrevious={() => {
          setStep(currentStep - 1);
          currentStep === progress && setProgress(currentStep - 1);
        }}
        onClickNext={() => {
          submit();
        }}
      />
    </Fragment>
  );
};

export default RVStepFive;
