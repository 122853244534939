import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import { ChangePasswordSchema } from '../../../../helpers/schemas';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { passwordChange } from '@api/user/user';
import { logout } from '@actions/user/user';

type Props = {
  setIsReqPasswordChange: (value: boolean) => any;
};

const ChangePasswordFromEditPage = (props: Props) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const submitPasswordChange = async (values: any) => {
    dispatch(toggleLoader(true));

    setError('');
    setSuccess(false);

    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.password
    };

    await passwordChange(data)
      .then((res: any) => {
        if (res.success === false) {
          let message;
          switch (res.message) {
            case 'RepeatingPassword':
              message = 'Noua parolă a mai fost folosita in trecut.';
              break;
            case 'InvalidOldPassword':
              message = 'Vechea parolă este greșită.';
              break;
            default:
              message = 'A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.';
              break;
          }
          setError(message);
        } else {
          setSuccess(true);
          dispatch(logout());
          document.cookie = 'token=';
          window.location.href = '/login';
        }
      })
      .catch((e: any) => {
        setError('Vechea parolă este greșită.');
      });

    dispatch(toggleLoader(false));
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          oldPassword: '',
          password: '',
          confirmNewPassword: ''
        }}
        validateOnMount={true}
        onSubmit={(values) => submitPasswordChange(values)}
        validationSchema={ChangePasswordSchema}
      >
        {(formProps) => (
          <Fragment>
            <Form>
              <div className="form-container">
                <Row className="mb-2 mt-3">
                  <h4 className="register-section-title">Schimbă parola</h4>
                  <Col className="mt-2 mb-3" xs={12} md={6}>
                    <Field
                      className="input-field-register"
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="Parola veche"
                      type="password"
                      render={() => (
                        <Input
                          label="Parola veche"
                          type="password"
                          mbZero
                          onBlur={() => {
                            formProps.setFieldTouched('oldPassword');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && formProps.setFieldValue('oldPassword', e.target.value);
                            e.target.value.length === 0 && formProps.setFieldValue('oldPassword', '');
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="oldPassword" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>
                  <Col md={6} />
                  <Col xs={12} md={6}>
                    <Field
                      className="input-field-register"
                      id="password"
                      name="password"
                      placeholder="Parola nouă"
                      type="password"
                      render={() => (
                        <Input
                          label="Parola nouă"
                          type="password"
                          mbZero
                          onBlur={() => {
                            formProps.setFieldTouched('password');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && formProps.setFieldValue('password', e.target.value);
                            e.target.value.length === 0 && formProps.setFieldValue('password', '');
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="password" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    <PasswordStrengthBar
                      password={formProps.values.password}
                      scoreWords={[
                        'Puterea parolei: Slabă',
                        'Puterea parolei: Slabă',
                        'Puterea parolei: Medie',
                        'Puterea parolei: Bună',
                        'Puterea parolei: Puternică'
                      ]}
                      shortScoreWord={'Puterea parolei: Slabă'}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      className="input-field-register"
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      placeholder="Confirmă noua parolă"
                      type="password"
                      render={() => (
                        <Input
                          label="Confirmă noua parolă"
                          type="password"
                          mbZero
                          onBlur={() => {
                            formProps.setFieldTouched('confirmNewPassword');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && formProps.setFieldValue('confirmNewPassword', e.target.value);
                            e.target.value.length === 0 && formProps.setFieldValue('confirmNewPassword', '');
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="confirmNewPassword"
                      render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                    />
                  </Col>
                  {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
                  {success && <div style={{ fontWeight: 'bold' }}>Parola a fost schimbată cu succes.</div>}
                  <div className="mb-4 mt-3 buttons-container d-flex justify-content-center justify-content-sm-start">
                    <Button type="submit" disabled={!formProps.isValid}>
                      Schimbă parola
                    </Button>
                    <Button style={{ marginLeft: '8px' }} onClick={() => props.setIsReqPasswordChange(false)}>
                      Inapoi
                    </Button>
                  </div>
                </Row>
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

export default ChangePasswordFromEditPage;
