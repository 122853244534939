import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { PopupKentico } from '@type/kentico-types';
import PopupComponent from '@components/dev/Components/Popup/PopupComponent';

export type PopupProps = { kentico: PopupKentico };

export const Popup = (props: PopupProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <PopupComponent {...props}/>
        </DelgazWrapper>
    );
};