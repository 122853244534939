import React, { FunctionComponent } from 'react';
import { FigureItemKentico } from '@type/kentico-types';

export type FigureProps = { kentico: FigureItemKentico };
const Figure: FunctionComponent<FigureProps> = (props: FigureProps) => {

  return (
    <div className="my-5">
      <div className="row text-center">
        <div className="col-12 icon-key">
          <img alt={props.kentico.title} src={props.kentico.image} />
        </div>

        <div className="col-12">
          <h3 className="idm-stat">{props.kentico.title}</h3>

          <p style={{fontSize: '16px', fontFamily: 'EONBrixSans, serif'}}>{props.kentico.shortDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default Figure;
