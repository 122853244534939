import { toggleLoader } from '@actions/LoaderActions';
import { adressByCLC, adressByPOD } from '@api/consumptionPoint';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { getBulkExportEntry, getDuplicateRevision, getTipuriRevizii } from '@api/revizii/revizii';
import { getEconomicOperator } from '@api/user/economicOperator';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import MaskedInput from '@components/common/Input/MaskedInput';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { REGEX_CLC_RULES, REGEX_POD_RULES } from '../../../helpers/regex';
import { IncarcareFisaNouaVerificareClient } from '../../../helpers/schemas';
import { getQueryParam } from '../../../helpers/urlUtils';
import Formular from './Formular';

export const TYPE_VERIFICARE = 'VERIFICARE';
export const TYPE_REVIZIE = 'REVIZIE';

type CpDataType = {
  address: string;
  building: string;
  clientFirstName: string;
  clientLastName: string;
  countyCode: string;
  countyName: string;
  houseNum1: string;
  houseNum2: string;
  isCompany: boolean;
  localityCode: number;
  localityName: string;
  pod: string;
  roomNumber: string;
  streetCode: number;
  streetName: string;
  utility: number;
  vstelle: string;
};

type BulkImportEntryDataType = {
  bulkImportId: number;
  bulkImportEntryId: number;
  nume_partener: string;
  adresa_loc_consum: string;
  societate: string;
  revisionTypeId: number;
  tip_lucrare: string;
  pod: string;
  clc: string;
  numar_contract: string;
  data_contract: string;
  numar_fisa: string;
  data_oe: string;
  data_lucrare: string;
  instalatii: number[];
  utilizare_conforma: boolean;
  utilizare_conforma_id: 1;
  utilizare_conforma_motiv: string;
  lista_sarcini: boolean;
  sistare_gaze: boolean;
  demontare_contor: boolean;
  nr_proces_verbal: string;
  sigiliu_nou: string;
  sigiliu_vechi: string;
  fileName: string;
  fileUrl: string;
  errors: {
    propertyName: string;
    errorMessage: string;
  }[];
};

type EconomicOperatorType = {
  companyName: string;
  oeCodSap: string;
  userId: string;
};

const IncarcareFisaNoua = () => {
  const [type, setType] = useState('');
  const [clc, setClc] = useState('');
  const [pod, setPod] = useState('');

  const [isClcFocused, setIsClcFocused] = useState(false);
  const [isPodFocused, setIsPodFocused] = useState(false);

  const [verifyField, setVerifyField] = useState('');
  const [cpData, setCpData] = useState<CpDataType | any>(null);
  const [dictionare, setDictionare] = useState(null);
  const [step, setStep] = useState(1);

  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [county, setCounty] = useState('');
  const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
  const [locality, setLocality] = useState('');

  const [error, setError] = useState('');

  const [bulkId, setBulkId] = useState<any>('');
  const [bulkImportEntryId, setBulkImportEntryId] = useState('');
  const [bulkImportEntryData, setBulkImportEntryData] = useState<BulkImportEntryDataType | null>(null);

  const [economicOpertatorData, setEconomicOpertatorData] = useState<EconomicOperatorType | null>(null);

  const formRef = useRef<FormikProps<any>>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cpData) {
      setClc(cpData.vstelle);
      setPod(cpData.pod);
    }
  }, [cpData]);

  useEffect(() => {
    //fetch details for edit
    if (bulkImportEntryId) {
      dispatch(toggleLoader(true));
      getBulkExportEntry({ bulkEntryId: bulkImportEntryId })
        .then((res) => {
          setBulkImportEntryData(res as BulkImportEntryDataType);
        })
        .catch((err) => {
          setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          dispatch(toggleLoader(false));
        });
    }
  }, [bulkImportEntryId]);

  useEffect(() => {
    //get dictionare for edit
    if (bulkImportEntryData) {
      dispatch(toggleLoader(true));
      getTipuriRevizii(bulkImportEntryData.revisionTypeId == 1 ? 'REV' : 'INSP', [
        'DeviceType',
        'OptionType',
        'InstalationType',
        'NonconformityType'
      ])
        .then((res) => {
          setDictionare(res);
          setType(bulkImportEntryData.revisionTypeId == 1 ? TYPE_REVIZIE : TYPE_VERIFICARE);
          setCpData({
            address: bulkImportEntryData.adresa_loc_consum,
            building: '',
            clientFirstName: '',
            clientLastName: '',
            countyCode: '',
            countyName: '',
            houseNum1: '',
            houseNum2: '',
            isCompany: bulkImportEntryData.societate ? true : false,
            localityCode: 0,
            localityName: '',
            pod: bulkImportEntryData.pod,
            roomNumber: '',
            streetCode: 0,
            streetName: '',
            utility: 0,
            vstelle: bulkImportEntryData.clc
          });
          setStep(2);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          dispatch(toggleLoader(false));
        });
    }
  }, [bulkImportEntryData]);

  useEffect(() => {
    GetCounties().then((res) => setCounties(res));

    const bulkImportEntryIdParam = getQueryParam('bulkImportEntryId');
    const bulkIdParam = getQueryParam('bulkId');
    if (bulkImportEntryIdParam) {
      setBulkImportEntryId(bulkImportEntryIdParam);
      setBulkId(bulkIdParam);
    } else {
      getEconomicOperator()
        .then((res) => {
          setEconomicOpertatorData(res);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
        });
    }
  }, []);

  useEffect(() => {
    county !== '' && GetLocalities(county).then((res) => setLocalities(res));
  }, [county]);

  useEffect(() => {
    GetCounties().then((res) => setCounties(res));
  }, []);

  useEffect(() => {
    let updatedErrors = validateValues({ ...formRef.current?.errors });
    formRef.current?.setErrors({ ...updatedErrors });
  }, [pod, clc]);

  const verify = async (values: any) => {
    setError('');
    dispatch(toggleLoader(true));

    let duplicateFound = false;
    await getDuplicateRevision(verifyField == 'POD' ? pod : clc)
      .then((res) => {
        duplicateFound = res;
      })
      .catch((err) => {});

    if (duplicateFound) {
      dispatch(toggleLoader(false));
      setError('Mai aveți o fișă introdusă! Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
      return;
    }

    if (verifyField == 'POD') {
      await adressByPOD({ pod: pod, countyCode: county, localityCode: locality })
        .then(async (res: any) => {
          if (res) {
            if (economicOpertatorData) {
              res.companyName = economicOpertatorData.companyName;
            }
            setCpData(res);
            await getTipuriRevizii(type == TYPE_REVIZIE ? 'REV' : 'INSP', [
              'DeviceType',
              'OptionType',
              'InstalationType',
              'NonconformityType'
            ])
              .then((res) => {
                setDictionare(res);
                setStep(2);
              })
              .catch((err) => {
                setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
              });
          }
        })
        .catch((e) => {
          if (e && e.status == 204) {
            setError('Date de identificare client greșite. Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
          } else {
            setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        });
    } else {
      await adressByCLC({ clc: clc, countyCode: county, localityCode: locality })
        .then(async (res: any) => {
          if (res) {
            if (res.utility == 1) {
              setError('Date de identificare client greșite. Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
              return;
            }
            if (economicOpertatorData) {
              res.companyName = economicOpertatorData.companyName;
            }
            setCpData(res);
            await getTipuriRevizii(type == TYPE_REVIZIE ? 'REV' : 'INSP', [
              'DeviceType',
              'OptionType',
              'InstalationType',
              'NonconformityType'
            ])
              .then((res) => {
                setDictionare(res);
                setStep(2);
              })
              .catch((err) => {
                setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
              });
          }
        })
        .catch((e) => {
          if (e && e.status == 204) {
            setError('Date de identificare client greșite. Vă rugăm să ne contactați la adresa verificari.revizii@delgaz-grid.ro');
          } else {
            setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        });
    }

    dispatch(toggleLoader(false));
  };

  const validateValues = (initialErrors?: any) => {
    let errors: any = {};

    if (initialErrors) {
      errors = initialErrors;
    }

    if (!pod && !clc) {
      errors.clc = 'Trebuie să introduceți Cod loc consum sau Cod punct de măsurare!';
      errors.pod = 'Trebuie să introduceți Cod loc consum sau Cod punct de măsurare!';
      setVerifyField('');
    } else if (pod) {
      if (!REGEX_POD_RULES.test(pod)) {
        errors.pod = 'Câmpul Cod punct de măsurare trebuie sa fie de forma DEGxxxxxxx.';
      } else {
        delete errors.pod;
      }
      delete errors.clc;
      setVerifyField('POD');
    } else if (clc) {
      if (!REGEX_CLC_RULES.test(clc)) {
        errors.clc = 'Câmpul Cod loc consum trebuie sa fie de forma 500xxxxxxx.';
      } else {
        delete errors.clc;
      }
      delete errors.pod;
      setVerifyField('CLC');
    }
    return errors;
  };

  return (
    <>
      <Fragment>
        {bulkId && (
          <Button className="mb-1" onClick={() => (window.location.href = `/detalii-incarcare-in-masa?bulkId=${bulkId}`)}>
            Înapoi
          </Button>
        )}

        {step == 1 && (
          <Formik
            enableReinitialize
            initialValues={{
              pod: '',
              clc: '',
              county: '',
              locality: '',
              type: ''
            }}
            onSubmit={(values) => verify(values)}
            validationSchema={IncarcareFisaNouaVerificareClient}
            validate={(values: any) => {
              let errors = validateValues();
              return errors;
            }}
            innerRef={formRef}
          >
            {(props) => {
              return (
                <Fragment>
                  <Form>
                    <div className="h-100">
                      <span>
                        <span className="m-0 mb-1 fw-bold">Tip fișă:</span>

                        <span
                          onClick={() => {
                            props.setFieldValue('type', TYPE_REVIZIE);
                            setType(TYPE_REVIZIE);
                          }}
                          className="me-2 ms-2"
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={1}
                            checked={type === TYPE_REVIZIE}
                            onChange={() => {
                              props.setFieldValue('type', TYPE_REVIZIE);
                              setType(TYPE_REVIZIE);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Revizie</span>
                        </span>

                        <span
                          onClick={() => {
                            props.setFieldValue('type', TYPE_VERIFICARE);
                            setType(TYPE_VERIFICARE);
                          }}
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={1}
                            checked={type === TYPE_VERIFICARE}
                            onChange={() => {
                              props.setFieldValue('type', TYPE_VERIFICARE);
                              setType(TYPE_VERIFICARE);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Verificare</span>
                        </span>
                      </span>
                      <ErrorMessage name="type" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />

                      <Row>
                        <Col xs={12} md={5} className="mt-3">
                          <Field
                            id="clc"
                            name="clc"
                            placeholder="Cod loc consum"
                            render={() => (
                              <MaskedInput
                                value={clc}
                                mbZero
                                onChange={(e: any) => {
                                  const currentValue = e.target.value as string;
                                  setClc(currentValue);
                                }}
                                onBlur={() => {
                                  props.setFieldTouched('clc');
                                  setIsClcFocused(false);
                                }}
                                onFocus={() => {
                                  setIsClcFocused(true);
                                }}
                                utility="GN"
                                label="Cod loc consum"
                                mask={'0123333333'}
                                isClc
                                preloadedStartDisabled
                                disabled={isPodFocused || !!pod}
                              />
                            )}
                          />
                          <ErrorMessage name="clc" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>

                        <Col xs={12} md={1} className="mt-3">
                          <span className="d-flex align-text-center justify-content-center mt-2">
                            <strong>sau </strong>
                          </span>
                        </Col>
                        <Col xs={12} md={5} className="mt-3">
                          <Field
                            id="pod"
                            name="pod"
                            placeholder="Cod punct de masurare"
                            render={() => (
                              <MaskedInput
                                value={pod}
                                mbZero
                                onChange={(e: any) => {
                                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                  setPod(currentValue);
                                }}
                                onBlur={() => {
                                  props.setFieldTouched('pod');
                                  setIsPodFocused(false);
                                }}
                                onFocus={() => {
                                  setIsPodFocused(true);
                                }}
                                utility="GN"
                                label="Cod punct de măsurare"
                                mask={'0123333333'}
                                preloadedStartDisabled
                                disabled={isClcFocused || !!clc}
                              />
                            )}
                          />
                          <ErrorMessage name="pod" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} md={5} className="mt-3">
                          <Dropdown
                            options={
                              counties.map((option: any, index: number) => ({
                                id: option.countyCode,
                                name: option.countyName
                              })) as DropdownOptionType[]
                            }
                            label={'Județ'}
                            defaultPlaceholder={'Alege județul'}
                            displaySearch={true}
                            onChange={(value) => {
                              setCounty(value);
                              value && props.setFieldValue('county', value);
                              value.length === 0 && props.setFieldValue('county', '');
                            }}
                            mbZero
                            value={county}
                          />
                          <ErrorMessage name="county" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col xs={12} md={1} className="mt-3"></Col>

                        {county && localities && localities.length > 0 && (
                          <Col xs={12} md={5} className="mt-3">
                            <Dropdown
                              options={
                                localities.map((option: any, index: number) => ({
                                  id: option.localityCode,
                                  name: option.localityName
                                })) as DropdownOptionType[]
                              }
                              label={'Localitate'}
                              defaultPlaceholder={'Alege localitatea'}
                              displaySearch={true}
                              onChange={(value) => {
                                setLocality(value);
                                value && props.setFieldValue('locality', value);
                                value.length === 0 && props.setFieldValue('locality', '');
                              }}
                              mbZero
                              value={locality}
                            />
                            <ErrorMessage name="locality" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                          </Col>
                        )}
                      </Row>
                      <div>
                        {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
                        <Button type={'submit'} className="btn-large mt-2">
                          Caută
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Fragment>
              );
            }}
          </Formik>
        )}
        {step == 2 && (
          <Formular type={type} cpData={cpData} pod={pod} clc={clc} dictionare={dictionare} bulkImportEntryData={bulkImportEntryData} />
        )}
      </Fragment>
    </>
  );
};

export default IncarcareFisaNoua;
