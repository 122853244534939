import SearchInput from '@components/common/ActionSearch/SearchInput';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import Pagination from '@components/prosumatori/components/table/Pagination';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

type RequestItem = {
    id: number,
    requestTypeId: number,
    requestTypeName: string,
    requestStatusId: number,
    requestStatusName: string,
    pod: string,
    requestNo: string,
    requestDate: any,
    Utility: string,
    SentDate: any,
    entryDate: any,
    NotInPortofolio: boolean
}

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const RequestList = (props: {
    list: any[],
    totalItems: any,
    pageIndex: any,
    pageSize: any,
    getList: any,
    summary: any,
    filterStatus: any,
    setFilterStatus: any,
    searchText: any,
    handleSearchText: any,
    sortColumn: string,
    sortAscending: boolean,
    onSort: any,
    handleSortColumn: any,
    handleSortAscending: any
}) => {
    const {
        list,
        totalItems,
        pageIndex,
        pageSize,
        getList,
        summary,
        filterStatus,
        setFilterStatus,
        searchText,
        handleSearchText,
        sortColumn,
        sortAscending,
        onSort,
        handleSortAscending,
        handleSortColumn
    } = props;

    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    const [clientType, setClientType] = useState<number | null>(null);
    let jwt: JWT = jwtDecode(token);

    useEffect(() => {
        if (jwt.groups.includes('ClientFizic') || jwt.groups.includes('ClientJuridic')) {
            setClientType(1);
        } else {
            setClientType(2);
        }
    }, []);
    //eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [toggled, setToggled] = useState(false);

    const indexOfLastElement = pageIndex * pageSize;
    //eslint-disable-next-line
    const indexOfFirstElement = indexOfLastElement - pageSize;
    // const currentElements = list.slice(indexOfFirstElement, indexOfLastElement);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        getList({sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc', pageNumber, pageSize: perPage, text: searchText, status: filterStatus});
    };
    const setElementsPerPage = (number: number) => {
        setPerPage(number);
        getList({sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc', pageNumber: 1, pageSize: number, text: searchText, status: filterStatus});
    };

    return (
        <div className="m-3 rss-list">
            <div className="mb-3">
                <Row className="mb-3">
                    <div className="justify-content-center justify-content-sm-end d-flex flex-wrap">
                        <ButtonFilters className='ms-2 mb-2' toggled={toggled} onClick={() => setToggled(!toggled)}/>
                        <a 
                        href={`${OLD_SITE_URL}/${clientType === 1 ? 'inregistrare-petitie-client' : 'inregistrare-petitie'}`}><Button className="btn-wide ms-2 mb-2" style={{minWidth: '172px'}}>Creează petiție</Button></a>
                    </div>
                </Row>
                {toggled && <Row className="d-flex justify-content-between">
                    <Col xs={12} md={6} className="d-flex align-items-center flex-wrap mb-2">
                        {
                            summary && summary.length > 0 && summary[0].values.map((item: any, index: number) => {
                                return (
                                    <span className={filterStatus === item.id ? 'active' : 'not-active'} key={index} onClick={() => setFilterStatus(item.id)}>
                                        <span className="m-2">{item.name}</span>
                                        <span
                                            className="bg-eon-lightgrey fw-bold"
                                            style={{marginRight: '10px', borderRadius: '4px', padding: '2px 6px'}}
                                        >
                                            {item.count}
                                        </span>
                                    </span>
                                );
                            })
                        }
                    </Col>
                    <Col xs={12} md={6} className="justify-content-end d-flex align-items-center">
                        <div className="d-flex flex-row flex-wrap justify-content-center justify-content-sm-end">
                        <SearchInput
                            className="ms-2 mb-2"
                            onSearch={() =>
                                getList({
                                    sortBy: sortColumn,
                                    sortType: sortAscending ? 'asc' : 'desc',
                                    pageNumber: 1,
                                    pageSize: 10,
                                    text: searchText,
                                    status: filterStatus
                                })
                            }
                            placeholder="Caută"
                            searchInputValue={searchText}
                            setSearchInputValue={(value) => handleSearchText(value)}
                            width={175}
                        />
                            <div className="d-flex align-items-center">
                                <Button className="btn-wide ms-2 mb-2" style={{minWidth: '172px'}} onClick={() => {
                                    // getList({pageNumber: 1, pageSize: 10});
                                    handleSortColumn('ID');
                                    handleSortAscending(false);
                                    handleSearchText('');
                                    setFilterStatus(null);
                                }}>Resetează</Button>
                            </div>
                        </div>
                    </Col>
                </Row>}
                <div className="general-table-container">
                    <table className="w-100">
                        <thead>
                        <tr>
                            <th>
                                <div style={{ cursor: 'pointer' }} onClick={() => onSort('RequestNo')}>
                                    Număr petiție{' '}
                                    {sortColumn != 'RequestNo' ? (
                                        <FontAwesomeIcon icon={faSort} size="sm" />
                                    ) : sortAscending ? (
                                        <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                                    )}
                                </div>
                            </th>
                            <th>
                                <div style={{ cursor: 'pointer' }} onClick={() => onSort('RequestDate')}>
                                    Dată înregistrare{' '}
                                    {sortColumn != 'RequestDate' ? (
                                        <FontAwesomeIcon icon={faSort} size="sm" />
                                    ) : sortAscending ? (
                                        <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                                    )}
                                </div>
                            </th>
                            <th>
                                <div style={{ cursor: 'pointer' }} onClick={() => onSort('EntryDate')}>
                                    Dată depunere{' '}
                                    {sortColumn != 'EntryDate' ? (
                                        <FontAwesomeIcon icon={faSort} size="sm" />
                                    ) : sortAscending ? (
                                        <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                                    )}
                                </div>
                            </th>
                            <th>
                                <div style={{ cursor: 'pointer' }} onClick={() => onSort('POD')}>
                                    POD{' '}
                                    {sortColumn != 'POD' ? (
                                        <FontAwesomeIcon icon={faSort} size="sm" />
                                    ) : sortAscending ? (
                                        <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                                    )}
                                </div>
                            </th>
                            <th>
                                <div style={{ cursor: 'pointer' }} onClick={() => onSort('RequestTypeName')}>
                                    Tip{' '}
                                    {sortColumn != 'RequestTypeName' ? (
                                        <FontAwesomeIcon icon={faSort} size="sm" />
                                    ) : sortAscending ? (
                                        <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                                    )}
                                </div>
                            </th>
                            <th>
                                <div style={{ cursor: 'pointer' }} onClick={() => onSort('RequestStatusName')}>
                                    Status{' '}
                                    {sortColumn != 'RequestStatusName' ? (
                                        <FontAwesomeIcon icon={faSort} size="sm" />
                                    ) : sortAscending ? (
                                        <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                                    )}
                                </div>
                            </th>
                            <th>Acțiuni</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            list.length > 0 && list.map((item: RequestItem, index) => (
                                <tr key={index}>
                                    <td className="p-2">
                                        <span>{item.requestNo !== null ? item.requestNo : '-'}</span>
                                    </td>
                                    <td className="p-2">{item.requestDate !== null ? moment(item.requestDate).format('DD.MM.YYYY') : '-'}</td>
                                    <td className="p-2">
                                        <span>{moment(item.entryDate).format('DD.MM.YYYY')}</span>
                                    </td>
                                    <td className="p-2">{item.pod}</td>
                                    <td className="p-2">{item.requestTypeName}</td>
                                    <td className="p-2">{item.requestStatusName}</td>
                                    <td className="p-2">
                                        { ((clientType !== 1 && (item.requestStatusId === 4 || item.requestStatusId === 2 || item.requestStatusId === 3 || item.requestStatusId === 5)) || 
                                            (clientType === 1 && item.requestNo !== null)) &&
                                            <Button 
                                                style={{marginRight: '5px', width: '80px'}} 
                                                onClick={() => {
                                                    if (clientType === 1) {
                                                        window.location.href = `${OLD_SITE_URL}/detalii-petitie-client?id=${item.id}`;
                                                    } else {
                                                        window.location.href = `${OLD_SITE_URL}/detalii-petitie-furnizor?id=${item.id}`;
                                                    }
                                                }}
                                            >
                                                Detalii
                                            </Button>
                                        }
                                        { (clientType !== 1 && item.requestStatusId === 1) &&
                                        <Button
                                            onClick={() => window.location.href = `${OLD_SITE_URL}/editare-petitie?id=${item.id}`}
                                            style={{width: '80px'}}
                                        >
                                            Editează
                                        </Button>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>

            </div>
            { list.length > 0 && <Pagination
                currentPage={pageIndex}
                totalElements={totalItems}
                perPage={perPage}
                paginate={paginate}
                setElementsPerPage={setElementsPerPage}
            /> }
        </div>
    );
};

export default RequestList;