import { toggleLoader } from '@actions/LoaderActions';
import { getAnexaStepTwo, postAnexaStepTwo } from '@api/revizii/revizii';
import { DropdownOptionType } from '@components/common/Dropdown';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { TYPE_REVIZIE } from '../CreareFisa';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';

type RVStepTwoProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  idAnexa: string;
};

type StepTwoType = {
  id_anexa: number;
  tip: number;
  instalatii: {
    annexeInstalationId: number;
    tip_instalatie: number;
  }[];
};

const RVStepTwo = (props: RVStepTwoProps) => {
  const dispatch = useDispatch();
  const { setStep, currentStep, progress, setProgress } = props;

  const [tipuriLucrare, setTipuriLucrare] = useState<DropdownOptionType[]>([]);
  const [tipuriInstalatie, setTipuriInstalatie] = useState<DropdownOptionType[]>([]);
  const [tipLucrare, setTipLucrare] = useState<any>('');
  const [instalatieUtilizareComuna, setInstalatieUtilizareComuna] = useState(false);
  const [instalatieUtilizareIndividuala, setInstalatieUtilizareIndividuala] = useState(false);

  //errors
  const [error, setError] = useState('');
  const [errorInstalatieUtilizare, setErrorInstalatieUtilizare] = useState(false);
  const [errorTipLucrare, setErrorTipLucrare] = useState(false);

  useEffect(() => {
    if (props.idAnexa) {
      dispatch(toggleLoader(true));
      getAnexaStepTwo(props.idAnexa)
        .then((res) => {
          dispatch(toggleLoader(false));
          initializeData(res);
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
        });
    }

    if (props.dictionare) {
      setTipuriLucrare(props.dictionare.OptionType);
      setTipuriInstalatie(props.dictionare.InstalationType);
    }
  }, []);

  useEffect(() => {
    if (instalatieUtilizareComuna || instalatieUtilizareIndividuala) {
      setErrorInstalatieUtilizare(false);
    }
    if (tipLucrare) {
      setErrorTipLucrare(false);
    }
  }, [instalatieUtilizareComuna, instalatieUtilizareIndividuala, tipLucrare]);

  const submit = () => {
    if (validateValues()) {
      dispatch(toggleLoader(true));
      let payload: any = {
        tip: tipLucrare
      };

      if (props.idAnexa) {
        payload.id_anexa = props.idAnexa;
      }

      const instalatiiDeAdaugat = [];
      if (instalatieUtilizareComuna) {
        let idComuna = (tipuriInstalatie.find((ti) => ti.name == 'Comuna')?.id as string) ?? ('1' as string);
        instalatiiDeAdaugat.push({ tip_instalatie: idComuna });
      }
      if (instalatieUtilizareIndividuala) {
        let idIndividuala = (tipuriInstalatie.find((ti) => ti.name == 'Individuala')?.id as string) ?? '2';
        instalatiiDeAdaugat.push({ tip_instalatie: idIndividuala });
      }
      payload.instalatii = instalatiiDeAdaugat;

      postAnexaStepTwo(payload)
        .then((res) => {
          props.setStep(props.currentStep + 1);
          props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            props.setStep(props.currentStep + 1);
            props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const validateValues = () => {
    let valid = true;
    if (!tipLucrare) {
      setErrorTipLucrare(true);
      valid = false;
    }
    if (!instalatieUtilizareComuna && !instalatieUtilizareIndividuala) {
      setErrorInstalatieUtilizare(true);
      valid = false;
    }
    return valid;
  };

  const initializeData = (values: StepTwoType) => {
    setTipLucrare(values.tip ?? '');
    if (
      values.instalatii.find((inst) => {
        return inst.tip_instalatie == 1;
      })
    ) {
      setInstalatieUtilizareComuna(true);
    }
    if (
      values.instalatii.find((inst) => {
        return inst.tip_instalatie == 2;
      })
    ) {
      setInstalatieUtilizareIndividuala(true);
    }
  };

  return (
    <Fragment>
      {props.type == TYPE_REVIZIE ? (
        <h4 className="red mt-2">Revizia tehnică a instalaţiei de utilizare a gazelor naturale</h4>
      ) : (
        <h4 className="red mt-2">Verificarea tehnică a instalaţiei de utilizare a gazelor naturale</h4>
      )}

      <Row>
        <Col xs={12} md={12} className="mt-3">
          <p className="p-gray m-0">Tip lucrare*</p>
          <div className="m-2 d-flex justify-content-between" style={{ flexDirection: 'column' }}>
            {tipuriLucrare.map((tl: any, index: number) => {
              return (
                <span key={index} onClick={() => setTipLucrare(tl.id)}>
                  <input
                    className="custom-radio-button pointer"
                    type="radio"
                    value={1}
                    checked={tipLucrare == tl.id}
                    onChange={() => setTipLucrare(tl.id)}
                    style={{ marginRight: '6px' }}
                  />
                  <span className="fw-bold pointer">{tl.name}</span>
                </span>
              );
            })}
          </div>
          {errorTipLucrare && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <p className="p-gray m-0">Instalație utilizare*</p>
          <div className="m-2 d-flex justify-content-between" style={{ flexDirection: 'column' }}>
            <div onClick={() => setInstalatieUtilizareComuna(!instalatieUtilizareComuna)} className="checkbox-container pointer">
              <input
                className="create-account-checkbox pointer"
                type="checkbox"
                checked={instalatieUtilizareComuna}
                onChange={() => setInstalatieUtilizareComuna(!instalatieUtilizareComuna)}
              />
              <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
                Verificarea/Revizia tehnică a instalaţiei comune de utilizare a gazelor naturale
              </span>
            </div>

            <div onClick={() => setInstalatieUtilizareIndividuala(!instalatieUtilizareIndividuala)} className="checkbox-container pointer">
              <input
                className="create-account-checkbox pointer"
                type="checkbox"
                checked={instalatieUtilizareIndividuala}
                onChange={() => setInstalatieUtilizareIndividuala(!instalatieUtilizareIndividuala)}
              />
              <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
                Verificarea/Revizia tehnică a instalaţiei individuale de utilizare a gazelor naturale
              </span>
            </div>
          </div>
          {errorInstalatieUtilizare && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
        </Col>
      </Row>

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      <StepsNavigationButtons
        className="mb-3"
        onClickPrevious={() => {
          setStep(currentStep - 1);
          currentStep === progress && setProgress(currentStep - 1);
        }}
        onClickNext={() => {
          submit();
        }}
      />
    </Fragment>
  );
};

export default RVStepTwo;
