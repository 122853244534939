import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getTypes } from '@api/prosumatori/prosumatori';
import { postStepFive } from '@api/iConnect/iConnectEE';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';

const CanaleDeComunicare = (props: {
  isEditing: any;
  requestId: any;
  setStep: any;
  currentStep: any;
  progress: any;
  setProgress: any;
  raspuns: any;
  handleRaspuns: any;
}) => {
  const { isEditing, requestId, setStep, currentStep, progress, setProgress, raspuns, handleRaspuns } = props;

  const [validationError, setValidationError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    // getOptionTypes(['CommunicationType']).then((res) => setROptions(res));
    raspuns.length === 0 && getOptionTypes(['ClientNotificationType']).then((res) => {
        if (res && res.clientnotificationtype && res.clientnotificationtype.length > 0) {
            let options: any[] = [];

            res.clientnotificationtype.map((item: any) => {
                let newItem = item;
                newItem.active = item.id === 1;

                options.push(newItem);
            });

            console.log(options);
            handleRaspuns(options);
        }
    });
  }, []);

  const getOptionTypes = async (keys: string[]) => {
    return await getTypes(keys, 'EE').then((res: any) => {
      return res;
    });
  };

  const setOptionActive = (option: any) => {
    let op = raspuns;

    op.map((el: any, i: number) => {
      if (el === option) {
        el.active = !el.active;
      }
    });

    handleRaspuns(op);
  };

  const constructResponse = (array: any) => {
    let payload: any[] = [];

    array.map((item: any) => {
        item.active && payload.push({id_comunicare: item.id});
    });

    return payload;
  };

  const constructPayload = () => {
    return {
      requestId: requestId,
      tip_comunicare: constructResponse(raspuns)
    };
  };

  const stepFiveApiCall = async (payload: any) => {
    dispatch(toggleLoader(true));

    await postStepFive(payload)
      .then((response) => {
        if (response && response.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        }
      })
      .catch((error: any) => {
        if (error && error.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        } else if (error === 'Request is completed') {
          setValidationError('Cererea a fost deja finalizată.');
        } else if (error && error.StatusCode && error.StatusCode === 500) {
          setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const handlePrevStep = () => {
    setStep(currentStep - 1);
  };

  const handleNextStep = () => {
    if (isEditing !== false) {
      let r = document.getElementById('raspuns');
      r?.classList.remove('red');
      setValidationError('');
      let wasValidated = true;

      if (raspuns.length === 0) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('raspuns');
        err?.classList.add('red');
      }

      if (wasValidated) {
        stepFiveApiCall(constructPayload());
      }
    } else {
      setStep(currentStep + 1);
      currentStep === progress && setProgress(currentStep + 1);
    }
  };

  return (
    <div className="iconnect-cc m-3 w-100 d-flex align-self-center flex-column">
      <h5 id="raspuns">Comunicarile in legatura cu racordarea acestui obiectiv doresc sa se transmita prin:*</h5>
      {raspuns.length > 0 &&
        raspuns.map((item: any, index: number) => {
          return <span
            key={index}
            style={{ marginRight: '25px', marginBottom: '8px' }}
            onClick={() => {
              if (isEditing !== false && item.id !== 1) {
                setOptionActive(item);
              }
            }}
            className="m-2"
          >
            <input
              type="checkbox"
              onChange={() => console.log(item)}
              value={item}
              checked={item.active}
              style={{ marginRight: '8px', cursor: 'pointer' }}
              className="create-account-checkbox"
              disabled={isEditing === false || item.id === 1}
            />
            <span style={{ fontSize: '18px', cursor: 'pointer' }}>{item.title || item.name || item.tip_instalatie}</span>
          </span>;
        })}

      {validationError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{validationError}</p>}
      <div className="d-flex flex-row justify-content-between mt-3 mb-3">
        <Button className="d-flex" onClick={handlePrevStep}>
          Pasul anterior
        </Button>
        <Button className="d-flex" onClick={handleNextStep}>
          Pasul următor
        </Button>
      </div>
    </div>
  );
};

export default CanaleDeComunicare;
