import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from './DatePicker';

export type DataItem = {
  name: string;
  label: string;
};

type DateTimeProps = {
  items: DataItem[] | undefined;
  values?: DateRangeInputs;
  onChange?: (e: any) => void;
  col?: number;
  minDate?: any;
  endDate?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  heightSmall?: boolean;
  setLastDayOfMonthForEndDateChange?: boolean;
  mbZero?: boolean;
  disabled?: boolean;
};

export type DateRangeInputs = { [name: string]: Date | null };

const DateRange = ({
  items,
  values,
  onChange,
  col,
  minDate,
  endDate,
  showMonthYearPicker,
  dateFormat,
  heightSmall,
  setLastDayOfMonthForEndDateChange,
  mbZero,
  disabled
}: DateTimeProps) => {
  const [value, setValue] = useState<DateRangeInputs>({} as DateRangeInputs);

  useEffect(() => {
    if (items && values) {
      setValue({ ...values });
    }
  }, [values]);

  return (
    <>
      {items && (
        <Row>
          {items.map((item: DataItem, index: number) => {
            return (
              <Col sm={col ? col : 12} md={col ? col : 12} key={index} className="my-1">
                <DatePicker
                  showMonthYearPicker={showMonthYearPicker}
                  maxDate={endDate ? endDate : ''}
                  label={item.label}
                  name={item.name}
                  heightSmall={heightSmall}
                  minDate={
                    index === items.length - 1
                      ? value[items[items.length - 2].name]
                        ? value[items[items.length - 2].name]
                        : minDate
                        ? minDate
                        : null
                      : minDate
                      ? minDate
                      : null
                  }
                  selected={value[item.name]}
                  dateFormat={dateFormat ? dateFormat : 'dd.MM.yyyy'}
                  onChange={(date) => {
                    if (setLastDayOfMonthForEndDateChange && item.name == 'Dată sfârșit' && date) {
                      date = moment(date).endOf('month').toDate();
                    }
                    setValue({ ...value, [item.name]: date });
                    let selectedDate = moment(date);
                    onChange &&
                      onChange({
                        name: item.name,
                        value: selectedDate.isValid() ? selectedDate.format('YYYY-MM-DD') : null
                      } as any);
                  }}
                  mbZero={mbZero}
                  disabled={disabled}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default DateRange;
