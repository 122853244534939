import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { postStepSeven, sendSMS, checkSMS, getUserDetails } from '@api/iConnect/iConnectEE';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { IState } from '@type/store';
import { Input } from '@components/common/Input';
import { getGDPRFile } from '@api/prosumatori/prosumatori';

const InformareFinala = (props: {
    isEditing: any,
    checkedOne: any,
    handleCheckOne: any,
    checkedTwo: any,
    handleCheckTwo: any,
    checkedThree: any,
    handleCheckThree: any,
    checkedFour: any,
    handleCheckFour: any,
    checkedFive: any,
    handleCheckFive: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    casnic: any,
}) => {
    //eslint-disable-next-line
    const [wasSent, setWasSent] = useState(false);
    const [phone, setPhone] = useState('');
    const [wasSMSSent, setWasSMSSent] = useState(false);
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [stepError, setStepError] = useState('');
    const [wasSuccessful, setWasSuccessful] = useState(false);
    const [GDPRFileURL, setGDPRFileURL] = useState('');
    const [timeLeft, setTimeLeft] = useState<number | null>(null);
    const [totalAttempts, setTotalAttempts] = useState<number>(0);
    const [phoneFormatError, setPhoneFormatError] = useState<string>('');
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const { setStep, currentStep, casnic } = props;

    const RedirectURL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

    const dispatch = useDispatch();

    useEffect(() => {
        if (timeLeft === 0) {
           setTimeLeft(null);
        }
    
        // exit early when we reach 0
        if (!timeLeft) return;
    
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
    
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
      }, [timeLeft]);

    const getUserPhone = async () => {
        await getUserDetails()
            .then((response) => {
                if (response && response.mobilePhone) {
                    setPhone(response.mobilePhone);
                } else if (response && response.contactMobilePhone) {
                    setPhone(response.contactMobilePhone);
                } else if (response && response.contactPhone) {
                    setPhone(response.contactPhone);
                }
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getUserPhone();
        getGDPRFile().then((res) => {
            // let link = res;
            // link = link.slice(0,4) + link.slice(5);

            setGDPRFileURL(res);
        });
    }, []);

    useEffect(() => {
        if (wasSuccessful === true) {
            setTimeout(() => {
                window.location.href = `${RedirectURL}/lista-cereri-racordare`;
            }, 3000);
        }
    }, [wasSuccessful]);

    const handleSendRequest = () => {
        const payload = {
            Acord1: props.checkedOne,
            Acord2: props.checkedTwo,
            Acord3: props.checkedThree,
            Acord_GDPR: props.checkedFour,
            Acord_DateValide: props.checkedFive,
            requestId: props.requestId
        };

        stepSevenApiCall(payload);
    };

    const stepSevenApiCall = async (payload: any) => {
        setStepError('');
        dispatch(toggleLoader(true));

        await postStepSeven(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setWasSent(true);
                    setShowModal(true);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setWasSent(true);
                    setShowModal(true);
                } else if (error === 'Request is completed') {
                    setStepError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setStepError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else {
                    setStepError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const callSendSMS = async (payload: any) => {
        dispatch(toggleLoader(true));

        await sendSMS(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setWasSMSSent(true);
                    
                    if (totalAttempts <= 2) {
                        setTimeLeft(30);
                    } else {
                        setTimeLeft(180);
                    }
                    
                    setTotalAttempts(totalAttempts => totalAttempts + 1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setWasSMSSent(true);
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const callCheckSMS = async (payload: any) => {
        dispatch(toggleLoader(true));

        await checkSMS(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    if (response === false || response.data === false) {
                        setError('Codul primit prin SMS nu este corect.');
                    } else if (response === true || (response.data && response.data === true)) {
                        setSuccess('Cererea a fost trimisă pentru procesare și se poate descărca din secțiunea Listă cereri.');
                        setShowModal(false);
                        setShowSuccessModal(true);
                        setWasSuccessful(true);
                    }
                } else if (response === true) {
                    setSuccess('Cererea a fost trimisă pentru procesare și se poate descărca din secțiunea Listă cereri.');
                    setShowModal(false);
                    setShowSuccessModal(true);
                    setWasSuccessful(true);
                } else if (response === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    if (error === false || error.data === false) {
                        setError('Codul primit prin SMS nu este corect.');
                    } else if (error === true) {
                        setSuccess('Cererea a fost generată și se poate descărca din secțiunea Listă cereri racordare.');
                        setShowModal(false);
                        setShowSuccessModal(true);
                        setWasSuccessful(true);
                    }
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleSendSMS = () => {
        setSuccess('');
        setError('');
        setCode('');
        setPhoneFormatError('');

        if (phone[0] !== '0' || phone[1] !== '7') {
            setPhoneFormatError('Numărul de telefon trebuie să fie de forma 07********.');
        } else {
            timeLeft === null && callSendSMS({phoneNumber: phone, requestId: props.requestId});
        }
    };

    const handleCheckSMS = () => {
        setSuccess('');
        setError('');
        callCheckSMS({ code: code, requestId: props.requestId });
    };

    const handlePrevStep = () => {
        setStep(currentStep - 1);
    };

    const replaceAt = (s: string, index: number, replacement: string) => {
        return s.substring(0, index) + replacement + s.substring(index + replacement.length);
    };

    const showPhoneSecretFormat = (p: string) => {
        let phone = p;
        phone = replaceAt(phone, 2, '*');
        phone = replaceAt(phone, 3, '*');
        phone = replaceAt(phone, 4, '*');
        phone = replaceAt(phone, 5, '*');
        phone = replaceAt(phone, 6, '*');

        return phone;
    };

    const renderModal = () => {
        return (
            <Modal style={{ 'marginTop': '3%' }} show={showModal} onHide={() => setShowModal(false)} className="modal-prosumatori">
                <Modal.Title>
                    <div className="p-3" style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
                        <span>Cod confirmare</span>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <p>Pentru a confirma corectitudinea informațiilor completate în cerere și a o transmite pentru înregistrare, vei primi un cod pentru semnarea cererii, prin SMS, la numărul de telefon:</p>
                    <Row className="mb-4">
                        <Col xs={12}>
                            <Input
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                label="Număr de telefon"
                                placeholder="07XXXXXXXX (10 cifre)"
                            />
                            {phoneFormatError !== '' && <p className="fw-bold red">{phoneFormatError}</p>}
                        </Col>
                        <Col xs={12}>
                            <Button disabled={phone.length !== 10 || (totalAttempts > 0 && timeLeft !== null)} onClick={handleSendSMS}>Solicită cod</Button>
                            {timeLeft !== null && <p>Poți solicita un nou cod in {timeLeft} secunde.</p>}
                        </Col>
                    </Row>
                    {wasSMSSent &&
                        <>
                            <p>Te rugăm să introduci codul pentru semnarea cererii (Telefon: {showPhoneSecretFormat(phone)}).</p>
                            <Row>
                                <Col xs={12}>
                                    <Input
                                        value={code}
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;

                                            if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 6) {
                                                setCode(e.target.value);
                                            }
                                        }}
                                        label="Cod de semnare"
                                        placeholder="6 cifre"
                                    />
                                </Col>
                                <span className="fst-italic mb-4">
                                    Codul a fost transmis prin SMS. Acesta este valabil timp de 10 minute. Dacă nu ai primit SMS-ul, click <span onClick={handleSendSMS} className="red hover-red text-decoration-underline pointer">aici</span>.
                                </span>
                                <Col xs={12}>
                                    <Button disabled={code.length < 6} onClick={handleCheckSMS}>Trimite cererea</Button>
                                </Col>
                            </Row>
                        </>
                    }
                    {/* {success !== '' && <Row className="mt-4">
                        <p className="m-0" style={{ fontWeight: 'bold', color: 'red' }}>{success}</p>
                    </Row>} */}
                    {error !== '' && <Row className="mt-4">
                        <p className="m-0" style={{ fontWeight: 'bold', color: 'red' }}>{error}</p>
                    </Row>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setWasSent(false);
                        setShowModal(false);
                        setWasSMSSent(false);
                        setCode('');
                        setError('');
                        setSuccess('');
                    }}>Anulare</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const renderSuccessModal = () => {
        return <Modal 
            centered
            show={showSuccessModal} 
            onHide={() => window.location.href = `${RedirectURL}/lista-cereri-racordare?tab=EE`} 
            className="modal-prosumatori"
            onExit={() => window.location.href = `${RedirectURL}/lista-cereri-racordare?tab=EE`}
        >
            <Modal.Title>
                <div className="p-3" style={{color: '#ea1c0a', fontWeight: 'bold'}}>
                    <span>Succes</span>
                </div>
            </Modal.Title>
            <Modal.Body>
                <p className="m-0">{success}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => window.location.href = `${RedirectURL}/lista-cereri-racordare?tab=EE`}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>;
    };

    const renderCasnicCheckboxes = () => {
        return <Fragment>
            <div className="checkbox-container mt-3 mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckOne(!props.checkedOne);
                }
            }}>
                <input
                    className="create-account-checkbox"
                    type="checkbox"
                    checked={props.checkedOne}
                    onChange={() => props.handleCheckOne(!props.checkedOne)}
                    disabled={props.isEditing === false}
                />
                <span style={{ marginLeft: '5px' }}>
                    Energia electrică o voi folosi numai pentru iluminat şi alimentarea unor receptoare electrice pentru propriile nevoi gospodareşti, fără a presta o activitate aducătoare de venituri, pe cont propriu sau în cadrul unui agent economic.
                </span>
            </div>

            <div className="checkbox-container mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckTwo(!props.checkedTwo);
                }
            }}>
                <input
                    className="create-account-checkbox" type="checkbox"
                    checked={props.checkedTwo}
                    onChange={() => props.handleCheckTwo(!props.checkedTwo)}
                    disabled={props.isEditing === false}
                />
                <span style={{ marginLeft: '5px' }}>
                    Accept ca durata de restabilire a alimentării în cazul unei întreruperi accidentale să fie cea specificată în Standardul de Performanță privind distribuția de energie electrică.
                </span>
            </div>

            <div className="checkbox-container mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckThree(!props.checkedThree);
                }
            }}>
                <input
                    className="create-account-checkbox" type="checkbox"
                    checked={props.checkedThree}
                    onChange={() => props.handleCheckThree(!props.checkedThree)}
                    disabled={props.isEditing === false}
                />
                <span style={{ marginLeft: '5px' }}>
                    Mă angajez ca în cazul în care voi executa o extindere a instalației de utilizare prin construcții noi (supraetajari, anexe) sau când în locuința existentă consumul de putere urmează a depăşi puterea instalată totală indicată în prezenta cerere, să depun o nouă cerere de alimentare cu energie electrică, pentru redimensionarea instalației.
                </span>
            </div>

            <div className="checkbox-container mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckFour(!props.checkedFour);
                }
            }}>
                <input
                    className="create-account-checkbox" 
                    type="checkbox"
                    checked={props.checkedFour}
                    onChange={() => props.handleCheckFour(!props.checkedFour)}
                    disabled={props.isEditing === false}
                />
                <span style={{ marginLeft: '5px' }}>
                    Am luat la cunoștință <a href={GDPRFileURL} download={GDPRFileURL} target="_blank" className="red hover-red" rel="noreferrer">Nota de informare cu privire la prelucrarea datelor cu caracter personal</a>.
                </span>
            </div>

            <div className="checkbox-container mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckFive(!props.checkedFive);
                }
            }}>
                <input
                    className="create-account-checkbox" type="checkbox"
                    checked={props.checkedFive}
                    onChange={() => props.handleCheckFive(!props.checkedFive)}
                    disabled={props.isEditing === false}
                />
                <span style={{ marginLeft: '5px' }}>
                    Declar pe propria răspundere că datele sunt reale și documentele anexate în copie sunt conforme cu originalul. Mă angajez să respect prevederile Ordin 59/2013, privind prezenta cerere și documentele anexate.
                </span>
            </div>
        </Fragment>;
    };

    const renderNonCasnicCheckboxes = () => {
        return <Fragment>
            <div className="checkbox-container mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckFour(!props.checkedFour);
                }
            }}>
                <input
                    className="create-account-checkbox" type="checkbox"
                    checked={props.checkedFour}
                    onChange={() => props.handleCheckFour(!props.checkedFour)}
                    disabled={props.isEditing === false}
                />
                <span style={{ marginLeft: '5px' }}>
                    Am luat la cunoștință <a href={GDPRFileURL} download={GDPRFileURL} target="_blank" className="red hover-red" rel="noreferrer">Nota de informare cu privire la prelucrarea datelor cu caracter personal</a>.
                </span>
            </div>

            <div className="checkbox-container mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckFive(!props.checkedFive);
                }
            }}>
                <input
                    className="create-account-checkbox" type="checkbox"
                    checked={props.checkedFive}
                    onChange={() => props.handleCheckFive(!props.checkedFive)}
                    disabled={props.isEditing === false}
                />
                <span style={{ marginLeft: '5px' }}>
                    Declar pe propria răspundere că datele sunt reale și documentele anexate în copie sunt conforme cu originalul. Mă angajez să respect prevederile Ordin 59/2013, privind prezenta cerere și documentele anexate.
                </span>
            </div>
        </Fragment>;
    };

    return (
        <div className="prosumatori-informare m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3">Informare</h4>

            {casnic === true ? renderCasnicCheckboxes() : renderNonCasnicCheckboxes()}

            {stepError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{stepError}</p>}

            {props.isEditing === false &&
                <p style={{ fontWeight: 'bold', color: 'red' }}>
                    Cererea a fost generată și se poate descărca din secțiunea Listă cereri racordare.
                </p>
            }

            <div className="d-flex flex-row">
                <Button className="d-flex" style={{ marginRight: '8px' }} onClick={handlePrevStep}>Pasul anterior</Button>
                {(props.isEditing !== false && casnic === true) &&
                    <Button
                        disabled={!props.checkedOne || !props.checkedTwo || !props.checkedThree || !props.checkedFour || !props.checkedFive}
                        onClick={handleSendRequest}
                    >
                        Trimite cerere
                    </Button>
                }

                {(props.isEditing !== false && casnic === false) &&
                    <Button
                        disabled={!props.checkedFour || !props.checkedFive}
                        onClick={handleSendRequest}
                    >
                        Trimite cerere
                    </Button>
                }
            </div>

            {renderModal()}
            {renderSuccessModal()}
        </div>
    );
};

export default InformareFinala;