import { SET_OPTIONS, CLEANUP_OPTIONS } from '@constants/ActionTypes/PostTypes';
import { Action } from './';

export class OptionsPayload {
    isFirstWizardSubstep?: boolean;
    comeBackFromNextStep?: boolean;
    showBackSubstep?: boolean;
    showNextSubstep?: boolean;
    homepageLink?: string;
    loginPageLink?: string;
    logoutPageLink?: string;
    dashboardLink?: string;
    mylinePage?: string;
    maintenanceMode?: MaintenanceMode;
    subscriptionKey?: string;
    xApiKey?: string;
    apiHost?: string;
    oldSite?: string;
    webApiHost?: string;
    timeoutInMilSec?: number;
    isOptionsLoaded?: boolean = false;
    renderGuest?: boolean = false;
    renderAuth?: boolean = false;
    loadingApi?: boolean = false;
    googleKey?: GoogleKey;
    emailImobiliare?: string;
    emailAuto?: string;
}

type MaintenanceMode = {
    debugEnabled?: boolean;
    services: {
        msrvDisabled?: boolean;
        mylineDisabled?: boolean;
        fbDisabled?: boolean;
        appleDisabled?: boolean;
    };
};

type GoogleKey = {
    apiKey?: string,
    signingSecret?: string
}

const initialState = new OptionsPayload();


export function options(state = initialState, action = {} as Action<OptionsPayload>) {
    const { type, payload } = action;

    switch (type) {
        case SET_OPTIONS: {
            return { ...state, ...payload };
        }
        case CLEANUP_OPTIONS: {
            return { ...initialState };
        }
        default:
            return state;
    }
}
