import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import postReducer from '@reducers/postsReducer';
import {options} from '@reducers/options';
import {auth} from '@reducers/auth';
import {loader} from '@reducers/loader';
import {settings} from '@reducers/settings';
import {geo} from '@reducers/geo';
import {rss} from '@reducers/rss';
import {device} from '@reducers/device';
import {backOffice} from '@reducers/backOffice';
import {notifications} from '@reducers/notifications';

const rootReducer = {
  postReducer,
  options,
  auth,
  loader,
  settings,
  geo,
  rss,
  device,
  backOffice,
  notifications
};

const rootSessionReduces = {
  options,
  postReducer,
  loader,
  auth,
  device,
  backOffice
};

//eslint-disable-next-line
const rootLocalReducers = {
  device
};

const persistConfig = {
  blacklist: [
    '_persist',
    'persist',
    'sessionStorage',
    'loader'
  ],
  key: 'DELGAZ',
  storage,
  // whitelist: ['device']
};

const persistSessionConfig = {
  blacklist: [],
  key: `${persistConfig.key}-session`,
  storage: sessionStorage
};

const reducers = combineReducers({
  sessionStorage: persistReducer(persistSessionConfig, combineReducers(rootSessionReduces)),
  // localStorage: persistReducer(persistConfig, combineReducers(rootLocalReducers)),
  ...rootReducer
});

export type RootState = ReturnType<typeof reducers>;

export type Action<T = {}> = {
  type: string;
  initialType?: string;
  payload?: T | [keyof T];
};

export default persistReducer(persistConfig, reducers);
