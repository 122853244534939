import React, { useState, useEffect, Fragment } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { postValidateBulkImport, postSaveBulkImport } from '@api/conventions/conventions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { CustomModal } from '@components/common/ModalConfirm';
import ChoiceModal from '@components/common/Modal/ChoiceModal';

// const ACTION_TYPE_ID_VIEW = '1';
// const ACTION_TYPE_ID_MODIFY = '2';
// const STAUS_INVALID = 'Invalid';
const STAUS_VALID = 'Valid';
const STAUS_AVERTIZARE = 'Avertizare';

type ImportBulkProps = {
  actionTypeId: string;
};

const ImportBulk = (props: ImportBulkProps) => {
  const [fileList, setFileList] = useState<File[]>([] as File[]);
  const [step, setStep] = useState(1);
  const [bulkImportId, setBulkImportId] = useState<any>('');
  const [conventions, setConventions] = useState<any[]>([]);
  const [summary, setSummary] = useState<any>([]);
  const [filterStatus, setFilterStatus] = useState<any>('');
  const [showModalSuccess, setShowModalSuccess] = useState<any>(false);
  const [showModalDelete, setShowModalDelete] = useState<any>(false);
  const [existsAtLeastOneValidConvention, setExistsAtLeastOneValidConvention] = useState<any>(false);
  const [existsAtLeastOneConventionNotInPortfolio, setExistsAtLeastOneConventionNotInPortfolio] = useState<any>(false);
  const [showModalConfirmSubmitWithConventionNotInPortfolio, setShowModalConfirmSubmitWithConventionNotInPortfolio] = useState<any>(false);
  const [actionTypeId, setActionTypeId] = useState<any>('1');

  const [error, setError] = useState('');
  // const [errorActionType, setErrorActionType] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setActionTypeId(props.actionTypeId);
  }, []);

  useEffect(() => {
    let existsAtLeastOneValidConvention = false;
    let existsAtLeastOneConventionNotInPortfolio = false;
    if (conventions && conventions.length > 0) {
      let filters: any = {};
      conventions.forEach((convention: any) => {
        filters[convention.statusName] = (filters[convention.statusName] ?? 0) + 1;
        if (convention.statusName == STAUS_VALID || convention.statusName == STAUS_AVERTIZARE) {
          existsAtLeastOneValidConvention = true;
        }
        if (convention.notInPortfolio) {
          existsAtLeastOneConventionNotInPortfolio = true;
        }
      });
      setSummary(filters);
    }
    setExistsAtLeastOneValidConvention(existsAtLeastOneValidConvention);
    setExistsAtLeastOneConventionNotInPortfolio(existsAtLeastOneConventionNotInPortfolio);
  }, [conventions]);

  const handleFileListFiseChange = (list: File[], index: number) => {
    setFileList(list);
  };

  const submitFile = async () => {
    dispatch(toggleLoader(true));
    setError('');
    let formData = new FormData();
    formData.append('ActionTypeId', actionTypeId);
    if (fileList.length > 0) {
      fileList.map((file) => {
        formData.append('ExcelFile', file as Blob);
      });
    }

    await postValidateBulkImport(formData)
      .then((res: any) => {
        if (res.data) {
          setBulkImportId(res.data.bulkImportId);
          setConventions(res.data.conventions);
          setStep(2);
        }
      })
      .catch((err) => {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      });
    dispatch(toggleLoader(false));
  };

  const submitConventions = async () => {
    dispatch(toggleLoader(true));
    setError('');
    await postSaveBulkImport({ bulkImportId: bulkImportId })
      .then((res: any) => {
        if (res) {
          setShowModalSuccess(true);
        }
      })
      .catch((err) => {
        if (err?.response?.data && err?.response?.data == 'Nu exista intrari valide') {
          setError('Nu există intrări valide.');
        } else {
          setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        }
      });
    dispatch(toggleLoader(false));
  };

  return (
    <Fragment>
      <Row>
        <div className="mb-3">
          <a href={'/conventii-de-consum'}>
            <Button>
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
              <span>Înapoi</span>
            </Button>
          </a>
        </div>
      </Row>

      <Fragment>
        {step == 1 && (
          <>
            <Row className="mt-3">
              <Col xs={12} md={12}>
                <div className="justify-content-between d-flex">
                  <span>Listă locuri consum (XLS, XLSX)</span>
                  <span className={'p-gray'}>Marime maxima fisier: 10 MB</span>
                </div>
                <UploadFile
                  onSetFileList={handleFileListFiseChange}
                  fileList={fileList}
                  index={0}
                  uploadedFilesLimit={1}
                  acceptedExtensions={['.xls', '.xlsx']}
                  hideDownloadButton
                />
              </Col>
            </Row>

            {error && (
              <p className="mt-2" style={{ fontWeight: 'bold', color: 'red' }}>
                {error}
              </p>
            )}

            <Button onClick={submitFile} className="btn-large mt-3" disabled={fileList.length == 0}>
              Trimite
            </Button>
          </>
        )}

        {step == 2 && (
          <>
            <Row className="d-flex justify-content-between mb-2">
              <Col xs={12} md={6} className="d-flex align-items-center gray-filters-list flex-wrap">
                {summary &&
                  Object.keys(summary).map((key: string, index) => {
                    let active = filterStatus === key;
                    return (
                      <span
                        className={active ? 'active' : 'not-active'}
                        key={index}
                        onClick={() => (!active ? setFilterStatus(key) : setFilterStatus(''))}
                      >
                        <span className="m-2">{key}</span>
                        <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                          {summary[key]}
                        </span>
                      </span>
                    );
                  })}
              </Col>
              <Col xs={12} md={6} className="justify-content-end d-flex align-items-center">
                <div className="d-flex flex-row">
                  <Button
                    onClick={() => {
                      if (existsAtLeastOneConventionNotInPortfolio) {
                        setShowModalConfirmSubmitWithConventionNotInPortfolio(true);
                      } else {
                        submitConventions();
                      }
                    }}
                    className="me-2"
                    disabled={!existsAtLeastOneValidConvention}
                  >
                    Continuă cu locurile valide
                  </Button>
                  <Button
                    className="btn-wide"
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  >
                    Anulează
                  </Button>
                </div>
              </Col>
            </Row>

            <div className="general-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Nr. crt. </th>
                    <th>Cod punct de măsurare</th>
                    <th>Mesaje</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {conventions && filterStatus
                    ? conventions
                        .filter((convention: any) => convention.statusName == filterStatus)
                        .map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.pod}</td>
                            <td dangerouslySetInnerHTML={{ __html: item.validMessage }} />
                            <td>{item.statusName}</td>
                          </tr>
                        ))
                    : conventions.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.pod}</td>
                          <td dangerouslySetInnerHTML={{ __html: item.validMessage }} />
                          <td>{item.statusName}</td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
            {error && (
              <p className="mt-2" style={{ fontWeight: 'bold', color: 'red' }}>
                {error}
              </p>
            )}
          </>
        )}

        <CustomModal
          modalTitle={'Succes!'}
          showModal={showModalSuccess}
          onClose={() => {
            setShowModalSuccess(false);
            window.location.href = '/conventii-de-consum';
          }}
        >
          <h5>Importul a fost realizat cu succes</h5>
        </CustomModal>

        <ChoiceModal
          showModal={showModalDelete}
          modalTitle={'Sunteți sigur că doriți să anulați importul?'}
          options={[
            { id: 'DA', title: 'Da' },
            { id: 'NU', title: 'Nu' }
          ]}
          onClose={(option: any) => {
            if (option.id == 'DA') {
              window.location.href = '/conventii-de-consum';
            } else if (option.id == 'NU') {
              setShowModalDelete(false);
            }
          }}
          onHide={() => {
            setShowModalDelete(false);
          }}
          btnWide
        />

        <ChoiceModal
          showModal={showModalConfirmSubmitWithConventionNotInPortfolio}
          modalTitle={
            'Prin continuarea cu această solicitare confirmați că aveți acceptul consumatorului final pentru a accesa aceste informații!'
          }
          options={[
            { id: 'DA', title: 'Continuă' },
            { id: 'NU', title: 'Anulare' }
          ]}
          onClose={(option: any) => {
            if (option.id == 'DA') {
              setShowModalConfirmSubmitWithConventionNotInPortfolio(false);
              submitConventions();
            } else if (option.id == 'NU') {
              setShowModalConfirmSubmitWithConventionNotInPortfolio(false);
            }
          }}
          onHide={() => {
            setShowModalConfirmSubmitWithConventionNotInPortfolio(false);
          }}
          btnWide
        />
      </Fragment>
    </Fragment>
  );
};

export default ImportBulk;
