import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RomaniaMapComponent from '@components/dev/Components/RomaniaMap/RomaniaMapComponent';
import { RomaniaMapKentico } from '@type/kentico-types';

export type RomaniaMapProps = { kentico: RomaniaMapKentico };

export const RomaniaMap = (props: RomaniaMapProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <RomaniaMapComponent {...props}/>
        </DelgazWrapper>
    );
};