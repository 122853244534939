import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';

export type props = {
  title: string;
  description: string;
  linkUrl: string;
  linkText?: string;
  linkTargetBlank?: boolean;
  img?: string;
};
export type state = { mouseMoved: boolean };

class CardComponent extends React.Component<props, state> {
  constructor(props: any) {
    super(props);
    this.state = { mouseMoved: false };
  }

  render() {
    return (
      <div style={{ paddingTop: '30px' }} className="d-flex justify-content-center">
        <Card
          className={`card-box ${this.props.linkUrl ? 'has-link' : ''}`}
          onMouseMove={() => this.setState({ mouseMoved: true })}
          onMouseDown={() => this.setState({ mouseMoved: false })}
          onMouseUp={() => {
            if (this.props.linkUrl && !this.state.mouseMoved) {
              let target = this.props.linkTargetBlank ? '_blank' : '_self';
              window.open(this.props.linkUrl, target, 'noopener,noreferrer');
            }
          }}
        >
          <div className="card-image-wrapper">
            <Card.Img className="card-image" variant="top" src={this.props.img} />
          </div>
          <Card.Body className="card-body">
            {this.props.title && <Card.Title className="card-title">{this.props.title}</Card.Title>}
            <Card.Text className="card-content">
              {this.props.description && <span dangerouslySetInnerHTML={{ __html: this.props.description }} />}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            {this.props.linkUrl && (
              <>
                <hr />
                <a
                  href={this.props.linkUrl}
                  rel="noopener noreferrer"
                  target={this.props.linkTargetBlank ? '_blank' : '_self'}
                  className="card-link link link-primary black"
                >
                  <div className="card-link-wrapper">
                    {this.props.linkText ?? 'Read full article'} <FontAwesomeIcon className="link-arrow" icon={faChevronRight} />
                  </div>
                </a>
              </>
            )}
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default CardComponent;
