import React from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from 'react-google-maps';
import { AdCardGoogleMapsKentico } from '@type/kentico-types';

export const AdCardGoogleMapsComponent = withScriptjs(withGoogleMap((props: {kentico: AdCardGoogleMapsKentico}) => {
        return (
            <div className="m-5">
                {
                //@ts-ignore
                <GoogleMap
                    defaultZoom={12}
                    defaultCenter={{lat: props.kentico.latitude, lng: props.kentico.longitude}}
                    center={{lat: props.kentico.latitude, lng: props.kentico.longitude}}
                >
                    <Marker
                        position={{lat: props.kentico.latitude, lng: props.kentico.longitude}}
                    />
                </GoogleMap>
                }
            </div>
        );
    }
));