import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { adressByPOD } from '@api/consumptionPoint';
import {
  verifyView,
  addViewConvention,
  addDetailsViewConvention,
  getConventionDetails,
  finalSendRequest,
  deleteRequestPodFromConvention
} from '@api/conventions/conventions';
import * as notification from '@lib/Notification';
import Verify from '../Verify';
import { IState } from '@type/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const RequestView = () => {
  const [error, setError] = useState('');
  // const [wasValidated, setWasValidated] = useState(false);
  const [pod, setPod] = useState('');
  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');
  const [wasVerified, setWasVerified] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState<any>('');
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [currentRequestDetails, setCurrentRequestDetails] = useState<any>(null);
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [verifyKey, setVerifyKey] = useState(1); //used to reset Verify POD component
  const [verifyModalType, setVerifyModalType] = useState(1);

  const dispatch = useDispatch();

  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = url.search;
    const searchParams = new URLSearchParams(params);
    if (searchParams.has('requestId')) {
      //EDIT CASE
      let currentRequestId: any = searchParams.get('requestId');
      dispatch(toggleLoader(true));
      setRequestId(currentRequestId);
      getConventionDetails(currentRequestId)
        .then((res) => {
          setCurrentRequestDetails(res.data);
          dispatch(toggleLoader(false));
        })
        .catch((error) => {
          dispatch(toggleLoader(false));
        });
    }
  }, []);

  const handlePod = (value: string) => {
    setPod(value);
  };

  const handleCounty = (value: string) => {
    setCounty(value);
  };

  const handleLocality = (value: string) => {
    setLocality(value);
  };

  // Step 1 - validate
  const validate = async () => {
    setError('');
    dispatch(toggleLoader(true));
    const payload = {
      pod,
      countyCode: county,
      localityCode: locality
    };

    await adressByPOD(payload)
      .then((response) => {
        if (response && response.pod) {
          verify();
        } else {
          setPod('');
          setCounty('');
          setLocality('');
          setError('Date de identificare client greșite!');
          dispatch(toggleLoader(false));
        }
      })
      .catch((error) => {
        try {
          if (error.status == 204) {
            setPod('');
            setCounty('');
            setLocality('');
            setError('Date de identificare client greșite!');
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        } catch (error) {
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        }

        dispatch(toggleLoader(false));
      });
  };

  // Step 2 - verify
  const verify = async () => {
    dispatch(toggleLoader(true));

    setWasVerified(false);
    setVerifyMessage('');
    setVerifyModalType(1);

    await verifyView(pod)
      .then((response) => {
        if (response && response.data) {
          if (response.data.statusCode === 'NotInPortfolio') {
            setVerifyMessage(
              'Locul de consum nu face parte din portofoliul propriu. Prin continuarea cu această solicitare confirmați că aveți acceptul consumatorului final pentru a accesa aceste informații. Dumneavoastră sunteți responsabil de a avea această permisiune.'
            );
            dispatch(toggleLoader(false));
            setVerifyModalType(2);
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'NoConvention') {
            setVerifyMessage('Pentru acest loc de consum nu există convenție de consum.');
            dispatch(toggleLoader(false));
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'NoConventionContact') {
            setVerifyMessage(
              <p>
                Nu s-a regăsit o convenție de consum pentru acest loc de consum. Vă rugăm să vă adresați echipei Delgaz Grid pe adresa de
                mail{' '}
                <a className="red" href={'mailto:contractedistributie.el@delgaz-grid.ro'}>
                  contractedistributie.el@delgaz-grid.ro
                </a>
              </p>
            );
            dispatch(toggleLoader(false));
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'OK') {
            if (
              !currentRequestDetails ||
              !currentRequestDetails.consumptionPoints.find((cp: any) => cp.pod == response.data.consumptionPlace.pod)
            ) {
              setWasVerified(true);
            } else {
              notification.error('Locul de consum a fost deja adăugat!');
              dispatch(toggleLoader(false));
            }
          }
        } else {
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          dispatch(toggleLoader(false));
        }
      })
      .catch((err) => {
        notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        dispatch(toggleLoader(false));
      });
  };

  useEffect(() => {
    wasVerified === true && add();
  }, [wasVerified]);

  // Step 3 - add view
  const add = async () => {
    let payload: any = {
      pod
    };
    if (requestId) {
      payload.requestId = requestId;
      await addDetailsViewConvention(payload)
        .then(async (response) => {
          if (response && response.status === 200) {
            notification.success('Locul de consum a fost adăugat cu succes.');
            setPod('');
            setCounty('');
            setLocality('');
            setVerifyKey(verifyKey + 1);
            await getConventionDetails(requestId)
              .then((res) => {
                setCurrentRequestDetails(res.data);
              })
              .catch((error) => {});
          } else {
            notification.success('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        })
        .catch((err) => {
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        });
    } else {
      await addViewConvention(payload)
        .then(async (response) => {
          if (response && response.status === 200) {
            setRequestId(response.data.requestId);
            notification.success('Locul de consum a fost adăugat cu succes.');
            setPod('');
            setCounty('');
            setLocality('');
            setVerifyKey(verifyKey + 1);
            await getConventionDetails(response.data.requestId)
              .then((res) => {
                setCurrentRequestDetails(res.data);
              })
              .catch((error) => {});
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        })
        .catch((err) => {
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        });
    }
    dispatch(toggleLoader(false));
  };

  const deletePod = async (requestDetailId: string) => {
    dispatch(toggleLoader(true));
    await deleteRequestPodFromConvention(requestDetailId)
      .then(async (res: any) => {
        if (res.status == 200) {
          notification.success('Locul de consum a fost ștears cu succes!');
          await getConventionDetails(requestId)
            .then((res) => {
              setCurrentRequestDetails(res.data);
            })
            .catch((error) => {});
        } else {
          notification.error('A apărut o eroare la ștergere!');
        }
      })
      .catch((error) => {
        notification.error('A apărut o eroare la ștergere!');
      });
    dispatch(toggleLoader(false));
  };

  const renderVerifyModal = () => {
    return (
      <Modal show={showVerifyModal} onHide={() => setShowVerifyModal(false)} centered backdrop={'static'}>
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Eroare</h5>
          </div>
        </Modal.Header>
        <Modal.Body>{verifyMessage}</Modal.Body>
        <Modal.Footer>
          {verifyModalType === 1 && (
            <Button
              onClick={() => {
                setPod('');
                setCounty('');
                setLocality('');
                // setCurrentRequestDetails(null);
                setShowVerifyModal(false);
              }}
            >
              OK
            </Button>
          )}
          {verifyModalType === 2 && (
            <Button
              variant="secondary"
              onClick={() => {
                setShowFinalModal(false);
                setShowVerifyModal(false);
              }}
            >
              Anulare
            </Button>
          )}
          {verifyModalType === 2 && (
            <Button
              onClick={() => {
                setShowFinalModal(false);
                setShowVerifyModal(false);
                dispatch(toggleLoader(true));
                setWasVerified(true);
              }}
            >
              Continuare
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  const renderFinalSendModal = () => {
    return (
      <Modal
        show={showFinalModal}
        onHide={() => {
          window.location.href = '/conventii-de-consum';
        }}
        centered
        backdrop={'static'}
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Succes</h5>
          </div>
        </Modal.Header>
        <Modal.Body>Solicitarea dumneavoastră a fost trimisă cu succes.</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              window.location.href = '/conventii-de-consum';
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Row>
        <div className="mb-3">
          <a href={`${OLD_SITE_URL}/conventii-de-consum`}>
            <Button>
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
              <span>Înapoi</span>
            </Button>
          </a>
        </div>
      </Row>
      <Verify
        key={`verify_key_${verifyKey}`}
        onClick={() => validate()}
        pod={pod}
        handlePod={handlePod}
        county={county}
        handleCounty={handleCounty}
        locality={locality}
        handleLocality={handleLocality}
        withLocalityCode
      />

      {error !== '' && <p className="text-center fw-bold red mt-3">{error}</p>}

      {
        <div>
          <div className="general-table-container">
            <table>
              <thead>
                <tr>
                  <th>POD</th>
                  <th>Client</th>
                  <th>Adresă</th>
                  {currentRequestDetails && currentRequestDetails?.statusTypeName === 'Netrimisa' && <th>Status</th>}
                  <th>Acțiuni</th>
                </tr>
              </thead>
              <tbody>
                {currentRequestDetails &&
                  currentRequestDetails?.consumptionPoints.map((cp: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{cp.pod}</td>
                        <td>{cp.client}</td>
                        <td>{cp.address}</td>
                        {currentRequestDetails && currentRequestDetails?.statusTypeName === 'Netrimisa' && (
                          <td>{currentRequestDetails.statusTypeName}</td>
                        )}
                        <td>
                          <Button onClick={() => deletePod(cp.requestDetailId)}>Sterge</Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="btn-wide mt-3 mb-1"
              disabled={!currentRequestDetails}
              onClick={async () => {
                dispatch(toggleLoader(true));
                await finalSendRequest(requestId)
                  .then((res) => {
                    if (res.status == 200) {
                      setShowFinalModal(true);
                    }
                  })
                  .catch((error) => {
                    try {
                      if (error.response.status == 400) {
                        notification.error('Solicitarea a fost deja trimisă!');
                      } else {
                        notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
                      }
                    } catch (err) {
                      notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
                    }
                  });
                dispatch(toggleLoader(false));
              }}
            >
              Trimite solicitare
            </Button>
          </div>
        </div>
      }
      {renderVerifyModal()}
      {renderFinalSendModal()}
    </Fragment>
  );
};

export default RequestView;
