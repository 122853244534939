import * as FormFieldsNames from '@constants/FormFieldsNames';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Mask from '../../../programariCitiri/Mask';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

export type CautareProgramareProps = {
  numarContact: string;
  invalidNumarContact: string | undefined;
  codLocConsum: string;
  invalidCodLocConsum: string | undefined;
  messageToShow?: string | null;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void | Promise<void>;
  setValue: (event: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
};

const FormCautare = (props: CautareProgramareProps): JSX.Element => {
  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.onSubmit(e)} className="form">
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <div className="position-relative">
            <Mask
              value={props.numarContact}
              name={FormFieldsNames.NUMAR_CONTACT}
              onChange={(e) => {
                props.setValue(e);
              }}
              mask={'0111111111'}
              type={'contact'}
              label="Număr contact"
              mbZero
            />
            <span className="position-absolute" style={{ right: '4%', bottom: '-36px', zIndex: 5 }}>
              <Tippy
                content={
                  <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                    Regăsești Numărul de contact în colțul din dreapta jos de pe înștiințarea transmisă de Delgaz Grid.
                  </div>
                }
                duration={0}
                placement="bottom"
              >
                <div className="icon-div" style={{ marginLeft: '5px' }}>
                  <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                </div>
              </Tippy>
            </span>
          </div>
          {props.invalidNumarContact && <p className="red">{props.invalidNumarContact}</p>}
        </Col>

        <Col xs={12} md={6} className="mb-3">
          <div className="position-relative">
            <Mask
              value={props.codLocConsum}
              name={FormFieldsNames.COD_LOC_CONSUM}
              onChange={(e) => props.setValue(e)}
              mask={'0123333333'}
              type={'clc'}
              label="Cod loc de consum"
              mbZero
            />
            <span className="position-absolute" style={{ right: '4%', bottom: '-36px', zIndex: 5 }}>
              <Tippy
                content={
                  <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                    Regăsești Codul locului de consum în colțul din dreapta jos de pe înștiințarea transmisă de Delgaz Grid.
                  </div>
                }
                duration={0}
                placement="bottom"
              >
                <div className="icon-div" style={{ marginLeft: '5px' }}>
                  <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                </div>
              </Tippy>
            </span>
          </div>
          {props.invalidCodLocConsum && <p className="red">{props.invalidCodLocConsum}</p>}
        </Col>
      </Row>
      {props.messageToShow && (
        <Row>
          <Col xs={12}>
            <p className="brand-text lh-copy mt1 fw-bold red">{props.messageToShow}</p>
          </Col>
        </Row>
      )}
      <Button type="submit" className="btn btn-primary mb-2 btn-md btn-submit">
        Căutare
      </Button>
    </Form>
  );
};

export default FormCautare;
