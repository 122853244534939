import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import Cereri from '@components/prosumatori/Cereri';

export const ListaCereri = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <Cereri />
        </DelgazWrapper>
    );
};