// Development
export const isDevelopment = process.env.NODE_ENV === 'development' ? true : false;

export const ONE_SECOND = 1000; // one second in milliseconds
export const ONE_MINUTE = 60 * ONE_SECOND; // one minute in milliseconds
export const GRID_CSS_COLUMNS = 12; // number of CSS columns used in the design
export const CURRENCY_LOCALE = 'ro-RO'; // the locale used in currency (language code - country code)
export const DECIMAL_REGEXP = /\d+/g;
export const ALL = 'all';
export const AEM_AUTHOR_MODE = 'author'; // AEM author mode

// API & Tokens & Cookies
export enum API_MANAGER_SOURCE {
  ALTEX = 'ALTEX',
  GUEST = 'GUEST',
  LIBERALIZATION = 'WEB_LIBERALIZARE',
  RETENTION = 'WEB_RETENTIE',
  WEB_TOMBOLA = 'WEB_TOMBOLA',
  WEB_UPPRICING = 'WEB_UPPRICING',
  WEBSITE = 'WEBSITE',
  WEBSITEADD = 'WEBSITEADD'
}
export const MRSV_AUTH_TOKEN = 'mrsv-auth-token';
export const GUEST_AUTH_TOKEN = 'Eon-Fast-Account-Contract-Guest-Token';
export const BEARER = 'Bearer';
export const COOKIE_SERVEXPRESS_MYLINE = 'EonRoServExpressMyline';

// Pages
export const MYLINE_URL_PREFIX = '/myline'; // prefix for Myline URLs
export const HOME_PAGE = '/';
export const MYLINE_DASHBOARD_PAGE = '/myline/dashboard';
export const MYLINE_WELCOME_PAGE = '/myline/welcome';
export const MYLINE_LOGOUT_PAGE = '/myline/logout';
export const MYLINE_INVOICES_PAGE = '/myline/facturile-mele';

// Templates
export const MYLINE_GUEST_TEMPLATE = 'myline-guest';
export const MYLINE_CLIENT_TEMPLATE = 'myline-client';

export const SORT_LAST_INDEX_PREFIX = 'zzz_';

// Input fields visibility enum
export const VISIBILITY_VISIBLE = 'visible';
export const VISIBILITY_HIDDEN = 'hidden';
export const VISIBILITY_NONE = 'none';
export const VISIBILITY_HIDDEN_TRUE = 'hidden_true';

// Placeholders
export const ACTION_SEARCH_LOCALITY_PLACEHOLDER = '{LOCALITY_NAME}, {COUNTY_NAME}';
export const ACTION_SEARCH_STREET_PLACEHOLDER = '{STREET_LABEL} {STREET_NAME}';
export const ACTION_SEARCH_POSTAL_CODE_PLACEHOLDER = '{POSTAL_CODE}';

export enum ChangeStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING'
}

// MeterReading, ConsumptionConvention indexes
export type INDEX_TYPES = 'P' | 'R' | 'OR' | 'OS' | 'OW' | 'OZ' | 'OG' | 'ON' | 'OV' | 'TG';

// CRM Flat Fee Products
export const CRM_FLAT_FEE_PRODUCTS = ['E.ON Cost Control Gas'];
