import React, { useEffect, useRef, useState } from 'react';
import { PopupKentico } from '@type/kentico-types';
import { IState } from '@type/store';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import LoginPopupForm from '@components/loginPopup/LoginPopupForm';

const PopupComponent = (props: { kentico: PopupKentico }) => {
  const URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const alreadyLoggedIn: string | undefined = useSelector<IState>((state) => state.auth.email) as string;
  const [isClosed, setIsClosed] = useState(true);
  const [initiated, setInitiated] = useState(false);
  const ref = useRef(null);

  console.log(props.kentico);

  useEffect(() => {
    if (!initiated) {
      setIsClosed(false);

      wait(500).then(() => {
        let popup = document.getElementById('login-popup');
        popup?.setAttribute('style', `right: ${0}`);
      });

      setInitiated(true);
    }
  }, [initiated]);

  useEffect(() => {
    if (isClosed && initiated) {
      wait(120000).then(() => {
        let popup = document.getElementById('login-popup');
        popup?.setAttribute('style', `right: ${0}`);
        setIsClosed(false);
      });
    }
  }, [isClosed]);

  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

  const wait = async (value: number) => {
    await delay(value);
  };

  const close = () => {
    let popup = document.getElementById('login-popup');
    popup?.setAttribute('style', `right: -330px`);
    setIsClosed(true);
  };

  const renderPopup = () => {
    return (
      <div className="login-popup" id="login-popup" ref={ref}>
        <div className="popup-header">
          <h5 className="head-text">{props.kentico.title}</h5>
          <FontAwesomeIcon icon={faXmark} className="close-icon" onClick={() => close()} />
        </div>
        <div className="popup-container">
          {props.kentico.formular === '1' && (
            <p className="popup-text mb-4">
              <FontAwesomeIcon icon={faKey} className="icon" />
              Intră în contul tău Delgaz sau{' '}
              <span className="popup-text-link" onClick={() => (window.location.href = `${URL}/login`)}>
                creează-ți un cont acum
              </span>
            </p>
          )}
          {props.kentico.formular === '1' && <LoginPopupForm />}
          {props.kentico.image !== '' && <img src={props.kentico.image} alt={props.kentico.image} style={{ objectFit: 'contain' }} />}
          {(props.kentico.formular !== '' || props.kentico.image !== '') && <br />}
          <div className="popup-text m-0" dangerouslySetInnerHTML={{ __html: props.kentico.text }} />
        </div>
      </div>
    );
  };

  return <>{alreadyLoggedIn ? null : renderPopup()}</>;
};

export default PopupComponent;