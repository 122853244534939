export function setQueryParam(param: string, value: string | any) {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  url.searchParams.set(param, value);
  window.history.replaceState(null, '', url.href);
}

export function getQueryParam(param: string) {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  return url.searchParams.get(param);
}

export function removeQueryParam(param: string) {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  if (url.searchParams.has(param)) {
    url.searchParams.delete(param);
    window.history.replaceState(null, '', url.href);
  }
}
