import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import {ErrorMessage, Field} from 'formik';
import {Input} from '@components/common/Input';

const DatePJ = (props: any) => {
    let formikProps = props.props;
    let handleData = props.handleData;

    return (
        <Fragment>
            { props && <div>
                <Row>
                    <Col xs={12} sm={4} className="mb-3">
                        <Field label='denumire' id="denumire" name="denumire" placeholder="Nume companie*">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.denumire}
                                    label='Nume companie*'
                                    onBlur={() => formikProps.setFieldTouched('denumire')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'denumire');
                                        formikProps.setFieldValue('denumire', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="denumire" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                        <Field label='reprezentant' id="reprezentant" name="reprezentant" placeholder="Nume si prenume reprezentant legal*">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.reprezentant}
                                    label='Nume si prenume reprezentant legal*'
                                    onBlur={() => formikProps.setFieldTouched('reprezentant')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'reprezentant');
                                        formikProps.setFieldValue('reprezentant', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="reprezentant" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                        <Field label='cui' id="cui" name="cui" placeholder="CUI (Cod unic de identificare al firmei)">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.cui}
                                    label='CUI (Cod unic de identificare al firmei)'
                                    onBlur={() => formikProps.setFieldTouched('cui')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'cui');
                                        formikProps.setFieldValue('cui', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="cui" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} className="mb-3">
                        <Field label='telefon' id="telefon" name="telefon" placeholder="Telefon">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.telefon}
                                    label='Telefon'
                                    onBlur={() => formikProps.setFieldTouched('telefon')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'telefon');
                                        formikProps.setFieldValue('telefon', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="telefon" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                    <Col className="mb-3">
                        <Field label='email' id="email" name="email" placeholder="E-mail*">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.email}
                                    label='E-mail*'
                                    onBlur={() => formikProps.setFieldTouched('email')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'email');
                                        formikProps.setFieldValue('email', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="email" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                </Row>
            </div>}
        </Fragment>
    );
};

export default DatePJ;