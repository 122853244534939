import React from 'react';
import {
    Row,
    Col,
    Table
} from 'react-bootstrap';

import { SuperiorCalorificTemplate, SuperiorCalorificTemplateByPodOrClc } from '@type/superiorCalorificPower';
import moment from 'moment';

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;


type ResultListingProps = {
    superiorCaloricValues?: SuperiorCalorificTemplate[];
    superiorCaloricValuesByClcOrPod?: SuperiorCalorificTemplateByPodOrClc[];
    searchType: 'locality' | 'consumptionPoint' | 'pod';
    searchValue?: string;
    showError?: boolean;
    isDataLoaded?: boolean;
};

const ResultListing = ({ superiorCaloricValues, superiorCaloricValuesByClcOrPod, searchType, searchValue, showError, isDataLoaded }: ResultListingProps) => {

    function renderMessage() {
        switch (searchType) {
            case 'locality':
                return <strong>Valoare PCS pentru localitatea: {searchValue}</strong>;
            case 'pod':
                return <strong>Valoare PCS pentru punctul de consum: {searchValue}</strong>;
            case 'consumptionPoint':
                return <strong>Valoare PCS pentru locul de consum: {searchValue}</strong>;
            default:
                return null;
        }
    }

    const renderByLocalityItem = (power: SuperiorCalorificTemplate, key: number) => (
        <tr key={key}>
            <td>{power.caloricArea}</td>
            <td>{power.pcs}</td>
            <td>{moment(power.compDate, 'YYYYMMDD')?.format('YYYY-MM-DD')}</td>
        </tr>
    );

    const renderByPodOrClcItem = (power: SuperiorCalorificTemplateByPodOrClc, key: number) => (
        <tr key={key}>
            <td>{power.pcs}</td>
            <td>{moment(power.startDate, 'YYYYMMDD')?.format('YYYY-MM-DD')} - {moment(power.endDate, 'YYYYMMDD')?.format('YYYY-MM-DD')}</td>
        </tr>
    );

    const renderListing = () => {
        if (searchType === 'locality' && superiorCaloricValues) {
            return superiorCaloricValues.map((power: SuperiorCalorificTemplate, key: number) => {
                return renderByLocalityItem(power, key);
            });
        } else if (superiorCaloricValuesByClcOrPod) {
            return superiorCaloricValuesByClcOrPod.map((power: SuperiorCalorificTemplateByPodOrClc, key: number) => {
                return renderByPodOrClcItem(power, key);
            });
        }
    };

    const renderData = () => {
        if (isDataLoaded) {
            if ((superiorCaloricValues && superiorCaloricValues.length > 0) || (superiorCaloricValuesByClcOrPod && superiorCaloricValuesByClcOrPod.length > 0)) {
                return (<Row>
                    <Col xs={12}>
                        <h2 className="brand-subtitle my-2">{renderMessage()} </h2>
                    </Col>
                    <Col>
                        <div className="table-responsive table-index general-table-container">
                            <Table className="w-100 table table-hover">
                                <thead>
                                    <tr>
                                        {searchType === 'locality' && <th>Zona calitate gaze</th>}
                                        <th>Valoare PCS</th>
                                        {searchType === 'locality' ? <th>Data valabilitate</th> : <th>Perioada facturare</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderListing()}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>);
            } else {
                return (<Row>
                    <Col xs={12}>
                        <h2 className="brand-subtitle my-2">Nu exista date disponibile!</h2>
                    </Col>
                </Row>);
            }
        }

        return null;
    };

    return (
        <>
            {(showError === false) ?
                renderData()
                :
                <Col xs={12}>
                    <p className="my-2">A aparut o problema! Va rugam incercati mai tarziu!</p>
                </Col>
            }
        </>
    );
};

export default ResultListing;