import React, { useState, useEffect, Fragment } from 'react';
import { toggleLoader } from '@actions/LoaderActions';
import { getExportReviziiAnexa6 } from '@api/revizii/revizii';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from '@components/common/Date/DatePicker';
import * as notification from '@lib/Notification';

const RaportAnexa = (props: any) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [minDate, setMinDate] = useState<any>(null);

  const [maxDateStartDate, setMaxDateStartDate] = useState<any>(null);
  const [minDateEndDate, setMinDateEndDate] = useState<any>(null);

  useEffect(() => {
    setMinDate(moment().subtract(3, 'years').toDate());
  }, []);

  useEffect(() => {
    if (startDate) {
      setMinDateEndDate(moment(startDate).toDate());
    } else {
      setMinDateEndDate(null);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setMaxDateStartDate(moment(endDate).toDate());
    } else {
      setMaxDateStartDate(null);
    }
  }, [endDate]);

  const dispatch = useDispatch();

  const sumbit = async () => {
    dispatch(toggleLoader(true));
    let res = '' as BlobPart;
    let isError = false;

    res = await getExportReviziiAnexa6({ startDate: startDate, endDate: endDate }).catch((err) => {
      isError = true;
    });

    if (isError) {
      notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      dispatch(toggleLoader(false));
      return;
    }

    try {
      setTimeout(() => {
        const blob: Blob = new Blob([res]);
        const fileName: string = `export-revizii-anexa-6-${moment().format('YYMMDDHms')}.xlsx`;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
        dispatch(toggleLoader(false));
      }, 500);
    } catch (error) {
      notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      dispatch(toggleLoader(false));
    }
  };
  return (
    <Fragment>
      <div className="h-100">
        <Row>
          <Col className="mt-3" xs={12} md={6}>
            <DatePicker
              mbZero
              label="Data început"
              name="Data început"
              minDate={minDate}
              maxDate={maxDateStartDate}
              selected={startDate ? moment(startDate).toDate() : startDate}
              onChange={(e: any) => {
                let date = e ? moment(e).format('YYYY-MM-DD') : '';
                setStartDate(date);
              }}
            />
          </Col>
          <Col className="mt-3" xs={12} md={6}>
            <DatePicker
              mbZero
              label="Data sfârșit"
              name="Data sfârșit"
              minDate={minDateEndDate}
              selected={endDate ? moment(endDate).toDate() : endDate}
              onChange={(e: any) => {
                let date = e ? moment(e).format('YYYY-MM-DD') : '';
                setEndDate(date);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Button onClick={sumbit} className="btn-large mt-2 mb-2" disabled={!startDate || !endDate}>
              Generează raport
            </Button>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default RaportAnexa;
