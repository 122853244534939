import React, { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { GetCounties, GetLocalities } from '@api/geoApi';
import DatePicker from '@components/common/Date/DatePicker';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import Mask from './Mask';

type CountyTemplate = {
  countyId: number;
  countyName: string;
  countyCode: string;
};

type LocalityTemplate = {
  localityId: number;
  localityName: string;
  localityCode: string;
};

const Filters = (props: {
  county: any;
  handleCounty: any;
  locality: any;
  handleLocality: any;
  startDate: any;
  handleStartDate: any;
  endDate: any;
  handleEndDate: any;
  requestNo: any;
  handleRequestNo: any;
  submit: any;
  reset: any;
}) => {
  const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const dispatch = useDispatch();

  const {
    county,
    handleCounty,
    locality,
    handleLocality,
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    requestNo,
    handleRequestNo,
    submit,
    reset
  } = props;

  useEffect(() => {
    mountCounties();
  }, []);

  useEffect(() => {
    county === '' && setLocalities([]);
    county !== '' && mountLocalities(county);
  }, [county]);

  const mountCounties = async () => {
    dispatch(toggleLoader(true));

    await GetCounties('GN')
      .then((res) => setCounties(res))
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const mountLocalities = async (county: any) => {
    dispatch(toggleLoader(true));

    await GetLocalities(county)
      .then((res) => {
        setLocalities(res);
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  return (
    <Fragment>
      <Row>
        <Col xs={12} sm={6} className="mb-4">
          <Dropdown
            options={
              counties.map((option, index) => ({
                id: option.countyCode,
                name: option.countyName
              })) as DropdownOptionType[]
            }
            label={'Județ*'}
            defaultPlaceholder={'Alege județul'}
            displaySearch={true}
            onChange={(value) => {
              handleCounty(value);
            }}
            value={county}
            mbZero
          />
        </Col>
        <Col xs={12} sm={6} className="mb-4">
          <Dropdown
            options={
              localities.map((option, index) => ({
                id: option.localityCode,
                name: option.localityName
              })) as DropdownOptionType[]
            }
            label={'Localitate*'}
            defaultPlaceholder={'Alege localitatea'}
            displaySearch={true}
            onChange={(value) => {
              handleLocality(value);
            }}
            value={locality}
            mbZero
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} className="mb-4">
          <DatePicker
            label="Dată început"
            name="Dată început"
            selected={startDate ? moment(startDate).toDate() : startDate}
            onChange={(e: any) => {
              let date = moment(e).format('YYYY-MM-DD') || '';
              handleStartDate(date);
            }}
            mbZero
          />
        </Col>
        <Col xs={12} sm={6} className="mb-4">
          <DatePicker
            label="Dată sfârșit"
            name="Dată sfârșit"
            selected={endDate ? moment(endDate).toDate() : endDate}
            onChange={(e: any) => {
              let date = moment(e).format('YYYY-MM-DD') || '';
              handleEndDate(date);
            }}
            minDate={startDate && startDate}
            mbZero
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <div className="position-relative">
            {/* <Input
              value={requestNo}
              onChange={(e) => {
                if ((/^[0-9]+$/.test(e.target.value) || e.target.value === '') && e.target.value.length <= 9) {
                  handleRequestNo(e.target.value);
                }
              }}
              label={'Număr cerere'}
            /> */}
            <Mask
              value={requestNo}
              onChange={(e) => handleRequestNo(e.target.value)}
              mask={'012222222'}
              label="Număr cerere"
            />
            <span className="position-absolute" style={{ right: '2%', bottom: '-36px', zIndex: 5 }}>
              <Tippy
                content={
                  <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                    Numărul cererii este format din 9 caractere și începe cu 21..... (exemplu 212403015)
                  </div>
                }
                duration={0}
                placement="bottom"
              >
                <div className="icon-div">
                  <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                </div>
              </Tippy>
            </span>
          </div>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12}>
          <Button className="mb-1" onClick={submit} style={{ marginRight: '8px', width: '95px' }}>
            Caută
          </Button>
          <Button className="mb-1" onClick={reset} style={{ width: '95px' }}>
            Resetează
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Filters;
