import { toggleLoader } from '@actions/LoaderActions';
import { verifyPODCountyLocality } from '@api/consumptionPoint';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ValidatePodCountyLocallityFormSchemaEE, ValidatePodCountyLocallityFormSchemaGN } from '../../../../helpers/schemas';
import MaskedInput from '../../../common/Input/MaskedInput';

const STATUS_NO_CONTENT = 'No Content';

export type ValidatePodCountyLocallityFormProps = {
  initialMeasurementPointCode?: string;
  metterTypes: any;
  setValid: (valid: boolean) => void;
  setCounty?: (county: string) => void;
  setLocality?: (county: string) => void;
  setMeasurementPointCode?: (measurementPointCode: string) => void;
  editDisabled?: boolean;
  setData?: (data: any) => void;
  utility: number;
};

export const ValidatePodCountyLocallityForm = (props: ValidatePodCountyLocallityFormProps) => {
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [county, setCounty] = useState('');
  const [countyName, setCountyName] = useState('');
  const [localities, setLocalities] = useState<LocalityTemplate[]>();
  const [locality, setLocality] = useState('');
  const [localityName, setLocalityName] = useState('');
  const [measurementPointCode, setMeasurementPointCode] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setError('');
  }, [county, locality, measurementPointCode]);

  useEffect(() => {
    GetCounties().then((res: any) => {
      setCounties(res);
    });

    //set initial measurementPointCode value if exists
    if (props.initialMeasurementPointCode) {
      setMeasurementPointCode(props.initialMeasurementPointCode);
    }
  }, []);

  useEffect(() => {
    county !== '' &&
      GetLocalities(county).then((res: any) => {
        setLocalities(res);
      });
  }, [county]);

  const submit = async (values: any) => {
    dispatch(toggleLoader(true));

    // let payload = { ...values, pod: values.measurementPointCode, metterTypes: props.metterTypes };
    //temporar fara metterTypes
    let payload = { ...values, pod: values.measurementPointCode };
    //eslint-disable-next-line
    const res = await verifyPODCountyLocality(payload)
      .then((res) => {
        //set true if succes
        if (res) {
          if (res.isInPortofolio) {
            setError('Locul de consum este in portofoliul propriu. Selectati un alt loc de consum pentru a continua cu solicitarea.');
            props.setValid(false);
          } else {
            props.setValid(true);
            props.setData &&
              props.setData({ pod: values.measurementPointCode, countyCode: values.countyCode, localityCode: values.localityCode });
          }
        }
      })
      .catch((err) => {
        if (err.statusText === STATUS_NO_CONTENT) {
          setError('Datele nu sunt valide.');
        } else {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
        props.setValid(false);
      });

    dispatch(toggleLoader(false));
  };

  return (
    <>
      <div>
        <Formik
          initialValues={{
            localityCode: '',
            countyCode: '',
            measurementPointCode: ''
          }}
          validationSchema={props.utility == 1 ? ValidatePodCountyLocallityFormSchemaEE : ValidatePodCountyLocallityFormSchemaGN}
          onSubmit={(values) => submit(values)}
        >
          {(formProps) => (
            <Form>
              <div className="form-container">
                <Row className="m-1">
                  <Col xs={12} md={3} className="mt-2">
                    <div className="position-relative">
                      <Field
                        id="measurementPointCode"
                        name="measurementPointCode"
                        placeholder="POD*"
                        render={() =>
                          props.utility == 1 ? (
                            <MaskedInput
                              value={measurementPointCode}
                              mbZero
                              onChange={(e: any) => {
                                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                e.target.value.length !== 0 && formProps.setFieldValue('measurementPointCode', currentValue);
                                e.target.value.length === 0 && formProps.setFieldValue('measurementPointCode', '');
                                props.setMeasurementPointCode && props.setMeasurementPointCode(currentValue);
                                setMeasurementPointCode(currentValue);
                              }}
                              onBlur={() => {
                                formProps.setFieldTouched('measurementPointCode');
                              }}
                              utility="EE"
                              label="POD*"
                              mask={'0123333333'}
                              disabled={props.editDisabled}
                            />
                          ) : (
                            <MaskedInput
                              value={measurementPointCode}
                              mbZero
                              onChange={(e: any) => {
                                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                e.target.value.length !== 0 && formProps.setFieldValue('measurementPointCode', currentValue);
                                e.target.value.length === 0 && formProps.setFieldValue('measurementPointCode', '');
                                props.setMeasurementPointCode && props.setMeasurementPointCode(currentValue);
                                setMeasurementPointCode(currentValue);
                              }}
                              onBlur={() => {
                                formProps.setFieldTouched('measurementPointCode');
                              }}
                              utility="GN"
                              label="CLC*"
                              mask={'0123333333'}
                              disabled={props.editDisabled}
                            />
                          )
                        }
                      />
                      <span className="position-absolute" style={{ right: '4%', top: '12px', zIndex: 5 }}>
                        <Tippy
                          content={
                            <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                              {props.utility == 1
                                ? 'POD - punct de măsurare, codul de regăsește pe factura de energie electrică primită de la furnizor.'
                                : 'CLC -  cod loc consum care se regasește pe factura de gaze naturale primită de la furnizor.'}
                            </div>
                          }
                          duration={0}
                          placement="bottom"
                        >
                          <div className="icon-div">
                            <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                          </div>
                        </Tippy>
                      </span>
                      <ErrorMessage
                        name="measurementPointCode"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={3} className="mt-2">
                    {!props.editDisabled ? (
                      <Dropdown
                        style={{ marginBottom: '0.25rem' }}
                        options={
                          counties.map((option, index) => ({
                            id: option.countyCode,
                            name: option.countyName
                          })) as DropdownOptionType[]
                        }
                        label={'Județ'}
                        defaultPlaceholder={'Alege județul'}
                        displaySearch={true}
                        onChange={(value) => {
                          value && formProps.setFieldValue('countyCode', value);
                          value.length === 0 && formProps.setFieldValue('countyCode', '');
                          props.setCounty && props.setCounty(value);
                          setCounty(value);
                          let countyName =
                            counties.find((county: any) => {
                              return county.countyCode == value;
                            })?.countyName ?? '';
                          setCountyName(countyName);
                        }}
                      />
                    ) : (
                      <>
                        <Dropdown style={{ marginBottom: '0.25rem' }} label={countyName} disabled />
                      </>
                    )}

                    <ErrorMessage name="countyCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>
                  <Col xs={12} md={3} className="mt-2">
                    {/* locality select */}
                    {county && !props.editDisabled ? (
                      <Dropdown
                        style={{ marginBottom: '0.25rem' }}
                        options={
                          localities
                            ? (localities.map((option, index) => ({
                                id: option.localityCode,
                                name: option.localityName
                              })) as DropdownOptionType[])
                            : []
                        }
                        label={'Localitate'}
                        defaultPlaceholder={'Alege localitatea'}
                        displaySearch={true}
                        onChange={(value) => {
                          value && formProps.setFieldValue('localityCode', value);
                          value.length === 0 && formProps.setFieldValue('localityCode', '');
                          props.setLocality && props.setLocality(value);
                          setLocality(value);
                          let localityName =
                            (localities &&
                              localities.find((county: any) => {
                                return county.localityCode == value;
                              })?.localityName) ??
                            '';
                          setLocalityName(localityName);
                        }}
                      />
                    ) : (
                      <>
                        <Dropdown style={{ marginBottom: '0.25rem' }} label={localityName} disabled />
                      </>
                    )}
                    <ErrorMessage name="localityCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>
                  <Col xs={12} md={3} className="mt-2 btn-wide">
                    <Button type="submit" disabled={props.editDisabled} style={{ height: '48px' }}>
                      Verifică informații
                    </Button>
                  </Col>
                </Row>
                <div className="d-flex ms-3">{error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}</div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ValidatePodCountyLocallityForm;
