import React, { Fragment, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar';
import { ChangePasswordSchema } from '../../../helpers/schemas';
import { passwordChange } from '@api/user/user';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '@type/store';
import { toggleLoader } from '@actions/LoaderActions';
import { Input } from '@components/common/Input';

const ChangePasswordForm = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const loggedInUser: string | undefined = useSelector<IState>((state) => state.auth.email) as string;
  const isLoggedIn = loggedInUser && true;
  const dispatch = useDispatch();

  const submit = async (values: any) => {
    dispatch(toggleLoader(true));

    setError('');
    setSuccess(false);

    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.password
    };

    await passwordChange(data)
      .then((res: any) => {
        if (res && res.success === true) {
          setSuccess(true);
        } else if (res && res.success === false && res.errorCode === 3) {
          setError('Noua parolă trebuie să fie diferită de parola veche.');
        } else if (res && res.success === false && res.errorCode === 2) {
          setError('Parola este gresită.');
        }
      })
      .catch((e: any) => {
        // setError('Vechea parolă este greșită.');
        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      });

    dispatch(toggleLoader(false));
  };

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={{
            oldPassword: '',
            password: '',
            confirmNewPassword: ''
          }}
          validateOnMount={true}
          onSubmit={(values) => submit(values)}
          validationSchema={ChangePasswordSchema}
        >
          {(props) => (
            <Fragment>
              <Form>
                <div className="form-container">
                  <Row className="mt-3">
                    <Col className="mb-3" xs={12} md={6}>
                      <Field
                        className="input-field-register"
                        id="oldPassword"
                        name="oldPassword"
                        placeholder="Parola veche"
                        type="password"
                        autoComplete="new-password"
                        render={() => (
                          <Input
                            label="Parola veche"
                            type="password"
                            autoComplete="new-password"
                            mbZero
                            onBlur={() => {
                              props.setFieldTouched('oldPassword');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('oldPassword', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('oldPassword', '');
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="oldPassword" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col md={6} />
                    <Col className="mb-3" xs={12} md={6}>
                      <Field
                        className="input-field-register"
                        id="password"
                        name="password"
                        placeholder="Parola nouă"
                        type="password"
                        autoComplete="new-password"
                        render={() => (
                          <Input
                            label="Parola nouă"
                            type="password"
                            autoComplete="new-password"
                            mbZero
                            onBlur={() => {
                              props.setFieldTouched('password');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('password', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('password', '');
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="password" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      <PasswordStrengthBar
                        password={props.values.password}
                        scoreWords={[
                          'Puterea parolei: Slabă',
                          'Puterea parolei: Slabă',
                          'Puterea parolei: Medie',
                          'Puterea parolei: Bună',
                          'Puterea parolei: Puternică'
                        ]}
                        shortScoreWord={'Puterea parolei: Slabă'}
                      />
                    </Col>
                    <Col className="mb-3" xs={12} md={6}>
                      <Field
                        className="input-field-register"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        placeholder="Confirmă noua parolă"
                        type="password"
                        autoComplete="new-password"
                        render={() => (
                          <Input
                            label="Confirmă noua parolă"
                            type="password"
                            autoComplete="new-password"
                            mbZero
                            onBlur={() => {
                              props.setFieldTouched('confirmNewPassword');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('confirmNewPassword', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('confirmNewPassword', '');
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="confirmNewPassword"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                    {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
                    {success && <div style={{ fontWeight: 'bold', color: 'red' }}>Parola a fost schimbată cu succes.</div>}
                    <div className="mb-4 mt-1 buttons-container d-flex flex-row align-items-center">
                      <Button type="submit" disabled={!props.isValid}>
                        Schimbă parola
                      </Button>
                    </div>
                  </Row>
                </div>
              </Form>
            </Fragment>
          )}
        </Formik>
      </div>
    );
  };

  return <Fragment>{isLoggedIn && renderForm()}</Fragment>;
};

export default ChangePasswordForm;
