import toastr from 'toastr';

declare var window: Window & { toastr: Toastr };

// Declare witch toastr level will be showed
const allowTypes = ['success', 'warning', 'error'];

let wasConfig = false;

export function configToastr(
  options = {
    closeButton: true,
    positionClass: 'toast-top-right',
    timeOut: 5000,
    extendedTimeOut: 0
  } as ToastrOptions
) {
  window.toastr = window.toastr || toastr;

  window.toastr.options = options;

  return (wasConfig = true);
}

export function success(message = '', title = '', options = {} as ToastrOptions) {
  if (!wasConfig) configToastr();

  /* FAST-10553 - hide tutorialBox component on page with afferent option */
  // $('.eon-ro-tutorial-box .hide-on-success-notification').hide();

  if (!message || allowTypes.indexOf('success') === -1) {
    return undefined;
  }

  return window.toastr.success(message, title, options);
}

export function warning(message = '', title = '', options = {} as ToastrOptions) {
  if (!wasConfig) configToastr();
  if (!message || allowTypes.indexOf('warning') === -1) {
    return undefined;
  }

  return window.toastr.warning(message, title, options);
}

export function error(message = '', title = '', options = {} as ToastrOptions) {
  if (!wasConfig) configToastr();

  /* FAST-10553 - hide tutorialBox component on page with afferent option */
  // $('.eon-ro-tutorial-box .hide-on-error-notification').hide();

  if (!message || allowTypes.indexOf('error') === -1) {
    return undefined;
  }
  return window.toastr.error(message, title, options);
}

export function clear() {
  if (!wasConfig) configToastr();
  return window.toastr.clear();
}
