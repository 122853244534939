import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import CerereIConnectEE from '@components/iConnectEE/CerereIConnectEE';

export const CerereEnergieElectrica = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <CerereIConnectEE />
        </DelgazWrapper>
    );
};