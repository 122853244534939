import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import SearchByPod from '@components/delgaz/Account/SuperiorCaloricValue/SearchByPod';

export const SearchByPodComponent = (): JSX.Element => {
  return (
    <DelgazWrapper>
      <SearchByPod />
    </DelgazWrapper>
  );
};
