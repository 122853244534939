import { EE_TYPE, MeterReadingModel, ProgramariCitiriTemplate } from '@type/programari-citiri';
import moment from 'moment';

export function template(templates: ProgramariCitiriTemplate, position: string, notification: MeterReadingModel, contactNumber: string): string {
  let utility = getTemplateUtilityType(notification);
  let isExpired = false;

  if (notification.status === 'CREATED_PAST' || notification.status === 'NO_DAYS_AVAILABLE_TO_ADD' || notification.status === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS' || notification.status === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS') {
    isExpired = true;
    // return templates[`ProgCitExp${utility}`]?.replace('{{loccons}}', notification.consumptionPointCode)
    // ?.replace('{{contactno}}', contactNumber);
  }

  let templatePositionPart = position === 'up' ? 'Sus' : 'Jos';

  let processType = getProcessType(notification);
  let actionType = getAction(notification);

  if (isExpired) {
    return templates[`Prog${actionType}${processType}Exp${utility}`]?.replace('{{loccons}}', notification.consumptionPointCode)
      ?.replace('{{contactno}}', contactNumber);
  } else {
    if (notification.notificationStatus === 'INCHID') {
      return templates[`Prog${processType}Exp${utility}`].replace('{{loccons}}', notification.consumptionPointCode)
        ?.replace('{{contactno}}', contactNumber);
    }

    return templates[`Prog${actionType}${processType}${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
      ?.replace('{{contactno}}', contactNumber);
  }

}

export function readingTemplate(templates: ProgramariCitiriTemplate, position: string, notification: MeterReadingModel, contactNumber: string): string {
  let utility = getTemplateUtilityType(notification);

  let templatePositionPart = '';

  if (position === 'up') {
    templatePositionPart = 'Sus';
  } else if (position === 'down') {
    templatePositionPart = 'Jos';
  } else if (position === 'mid') {
    templatePositionPart = 'Mij';
  }

  //eslint-disable-next-line
  let actionType = getAction(notification);

  // Template pentru procese Expirat peste 60 de zile
  //@ts-ignore
  if (notification.selfReadingStatus === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS' || notification.status === 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS') {
    return templates[`ProgCitExp${utility}`]?.replace('{{loccons}}', notification.consumptionPointCode)
      ?.replace('{{contactno}}', contactNumber);
  }

  // Template pentru procese index introdus
  //@ts-ignore
  if (notification.selfReadingStatus === 'CREATED_WITH_INDEX') {
    return templates[`ProgCitIndexTransmis${utility}`]?.replace('{{loccons}}', notification.consumptionPointCode)
      ?.replace('{{contactno}}', contactNumber)?.replace('{{dataindex}}', moment(notification.indexDate).format('DD.MM.YYYY'));
  }

  // Template pentru procese în termen cu programare și 2 mesaje transmise (depășit cele 2h)
  //@ts-ignore
  if ((notification.status === 'CREATED_PAST' || notification.status === 'CREATED_FUTURE') && notification.selfReadingStatus === 'CREATED_2') {
    // INCHID
    if (notification.notificationStatus === 'INCHID') {
      return templates[`ProgCitExpMsg${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
        ?.replace('{{contactno}}', contactNumber);
    }

    // INST & PREAV
    return templates[`ProgCitProgTransmis2Msg${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
      ?.replace('{{contactno}}', contactNumber)?.replace('{{dataindex}}', moment(notification.indexDate).format('DD.MM.YYYY'));
  }

  // Template pentru procese Expirat pana în 60 de zile
  //@ts-ignore
  if (notification.selfReadingStatus === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS' || notification.status === 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS') {
    return templates[`ProgCitExpMsg${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
      ?.replace('{{contactno}}', contactNumber);
  }

  // Template pentru procese în termen cu programare (depășit cele 2h)
  //@ts-ignore
  if ((notification.status === 'CREATED_PAST' || notification.status === 'CREATED_FUTURE') &&
    //@ts-ignore
    (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD')) {
    // INCHID
    if (notification.notificationStatus === 'INCHID') {
      return templates[`ProgCitExpMsg${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
        ?.replace('{{contactno}}', contactNumber);
    }

    // INST & PREAV
    return templates[`ProgCitProgTransmis${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
      ?.replace('{{contactno}}', contactNumber);
  }

  // Template pentru procese în termen fără programare sau cu programare sub 2h
  //@ts-ignore
  if ((notification.status === 'OK_TO_EDIT' || notification.status === 'OK_TO_ADD' || 
  notification.status === 'NO_DAYS_AVAILABLE_TO_ADD' || notification.status === 'NO_DAYS_AVAILABLE_TO_EDIT') &&
    //@ts-ignore
    (notification.selfReadingStatus === 'OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_1_OK_TO_ADD' || notification.selfReadingStatus === 'CREATED_2')) {
    // INCHID
    if (notification.notificationStatus === 'INCHID') {
      return templates[`ProgCitExpMsg${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
        ?.replace('{{contactno}}', contactNumber);
    }

    // INST
    if (notification.notificationStatus === 'INST') {
      return templates[`ProgCitMsg${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
        ?.replace('{{contactno}}', contactNumber);
    }

    // PREAV
    if (notification.notificationStatus === 'PREAV') {
      return templates[`ProgPreavCitMsg${utility}${templatePositionPart}`]?.replace('{{loccons}}', notification.consumptionPointCode)
        ?.replace('{{contactno}}', contactNumber);
    }
  }

  return '';
}

function getProcessType(notification: MeterReadingModel): string {
  let template = '';

  switch (notification.notificationType) {
    case 'DEF':
      template = 'InlocDef';
      break;
    case 'INL':
      template = 'InlocPer';
      break;
    case 'CIT':
      template = 'Cit';
      break;
    default:
      break;
  }
  return template;
}

function getAction(notification: MeterReadingModel): string {
  let template = '';

  switch (notification.notificationStatus) {
    case 'PREAV':
      template = 'Preav';
      break;
    case 'INCHID':
      template = '';
      break;
    case 'INST':
      template = '';
      break;
    default:
      break;
  }
  return template;
}

function getTemplateUtilityType(notification: MeterReadingModel): string {
  return notification.utility === EE_TYPE ? 'EE' : 'GN';
}

export function isPreaviz(notification: MeterReadingModel) {
  return notification.notificationStatus === 'PREAV';
}

// 1 - PREAV preav
// 2 - INCHID inchidere
// 3 - INST instiintare


//process
// 1 - INL - inlocuire contor
// 2 - DEF - inlocuire contor defect
// 3 - CIT - citire contor