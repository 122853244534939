export const EE_TYPE = 1;
export const GN_TYPE = 2;

export type ProgramariCitiriTemplate = {
  [name: string]: any
};

export type TimeSlot = {
  hour: number,
  minute: number
}

export type AvailableDays = {
  date: string,
  timeSlots?: TimeSlot[],
}

export type MeterReading = {
  notificationId: number,
  clientName: string,
  clientFirstName: string,
  consumptionPointAddress: string,
  consumptionPointCode: string,
  equipment: string,
  material: string,
  series: string,
  notificationType: string,
  notificationTypeId: number,
  notificationTypeDescription: string,
  notificationStatus: string,
  appointmentUtcDate: string,
  appointmentUtcDateTime: string,
  sparte: string,
  phoneNumber: string,
  comments: string,
  status: ReadingStatus,
  notificationStatusId: number,
  notificationStatusDescription: string,
  availableDays?: AvailableDays[]
  timeSlotMinutes: number,
  utility?: number,
  indexDate?: string,
};

export type MeterReadingModel = MeterReading & {
  utilityType: typeof EE_TYPE | typeof GN_TYPE
};

export type ReadingStatus = 'OK_TO_ADD' | 'OK_TO_EDIT' | 'CREATED_FUTURE' | 'CREATED_PAST' | 'NO_DAYS_AVAILABLE_TO_ADD' | 'OUTSIDE_INTERVAL_UNDER_SIXTY_DAYS' | 'OUTSIDE_INTERVAL_OVER_SIXTY_DAYS' | 'CREATED_WITH_INDEX' | 'NO_DAYS_AVAILABLE_TO_EDIT' | 'OUTSIDE_INTERVAL';