import React, { useEffect, useState } from 'react';
import PartnerRow from '@components/account/MyAccount/Partners/table/PartnersTableRow';
import { getPartners, getPartnerConsumptionPoints, deletePartner } from '@api/user/partners';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import PartnerCPTableRow from './table/PartnerCPTableRow';
import { Button, Modal } from 'react-bootstrap';
import AddPartnerCP from './AddPartnerCP';
import { Row, Col } from 'react-bootstrap';

const Partners = () => {
    const [list, setList] = useState<any>([]);
    const [currentPartner, setCurrentPartner] = useState(null);
    const [error, setError] = useState('');
    // const [deleteError, setDeleteError] = useState('');
    const [cpList, setCPList] = useState([]);
    const [cpError, setCPError] = useState('');
    const [itemToDelete, setItemToDelete] = useState<any>(null);
    const [showModal, setShowModal] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        setError('');
        setCPError('');

        dispatch(toggleLoader(true));

        await getPartners()
            .then((res) => {
                if (res && res.data) {
                    setList(res.data);
                    setIsAdding(false);
                } else {
                    setError('A apărut o eroare. Vă rugăm să reveniți mai târziu.');
                }
            })
            .catch((err) => setError('A apărut o eroare. Vă rugăm să reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    const getConsumptionPoints = async (partnerCode: string) => {
        setCPError('');

        dispatch(toggleLoader(true));

        await getPartnerConsumptionPoints(partnerCode)
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.items && res.data !== null) {
                    setCPList(res.data.data.items);
                    setIsAdding(false);
                } else {
                    setCPError('A apărut o eroare. Vă rugăm să reveniți mai târziu.');
                }
            })
            .catch((err) => setCPError('A apărut o eroare. Vă rugăm să reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    const deleteClientPartner = async (partnerCode: string) => {
        dispatch(toggleLoader(true));

        await deletePartner(partnerCode)
            .then((res) => {
                if (res && res.status === 200) {
                    setCurrentPartner(null);
                    setCPList([]);
                    getList();
                    setShowModal(false);
                    setIsAdding(false);
                }
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const onPartnerSelect = (item: any) => {
        if (item !== currentPartner) {
            setCurrentPartner(item);
            getConsumptionPoints(item.partnerCode);
        }
    };

    const onPartnerDelete = (item: any) => {
        setItemToDelete(item);
        setShowModal(true);
    };

    const renderConfirmModal = () => {
        return <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop={'static'}>
            <Modal.Header closeButton>
                <div>
                    <h5 className="red">Șterge</h5>
                </div>
            </Modal.Header>
            <Modal.Body>Ești sigur că vrei să ștergi acest partener?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Renunță</Button>
                <Button onClick={() => deleteClientPartner(itemToDelete?.partnerCode)}>Șterge</Button>
            </Modal.Footer>
        </Modal>;
    };

    const handleSuccessModal = (show: boolean) => {
        setShowSuccessModal(show);
    };

    const renderSuccessModal = () => {
        return <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop={'static'}>
            <Modal.Header closeButton>
                <div>
                    <h5 className="red">Succes!</h5>
                </div>
            </Modal.Header>
            <Modal.Body>Locul de consum a fost adăugat cu succes.</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowSuccessModal(false)}>OK</Button>
            </Modal.Footer>
        </Modal>;
    };

    return (
        <div className="m-3">
            {!isAdding && <Button onClick={() => setIsAdding(true)}>Adaugă punct consum</Button>}
            {isAdding && <AddPartnerCP getList={getList} handleSuccessModal={handleSuccessModal} />}

            <Row className="mt-2">
                <Col sm={12} md={6}>
                    {(list.length === 0 && error === '') && <p className="text-center">Momentan nu aveți niciun partener.</p>}
                    {error !== '' && <p className="text-center fw-bold red">{error}</p>}
                    <div style={{ height: '710px', overflowY: 'scroll' }}>
                        <div className="general-table-container">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>
                                            <h6 className="m-0">Parteneri</h6>
                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.length > 0 && list.map((item: any, index: number) => (
                                            <PartnerRow item={item} onSelect={onPartnerSelect} key={index} currentPartner={currentPartner} onDelete={onPartnerDelete} />
                                        ))
                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    {(cpList.length === 0 && currentPartner !== null && cpError === '') && <p className="text-center">Nu există niciun loc de consum asociat acestui partener.</p>}
                    {cpError !== '' && <p className="text-center fw-bold red">{cpError}</p>}
                    <div style={{ height: '710px', overflowY: 'scroll' }}>
                        {cpList.length > 0 &&
                            <div className="general-table-container">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            <th>
                                                <h6 className="m-0">Locuri de consum</h6>
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cpList.map((item, index) => (
                                                <PartnerCPTableRow key={index} item={item} />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>

                </Col>
            </Row>

            {renderConfirmModal()}
            {renderSuccessModal()}
        </div>
    );
};

export default Partners;