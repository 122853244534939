import {
    REQUEST_LIST,
    REQUEST_DETAILS,
    UPDATE_REQUEST,
    VERIFY_CONSUMPTION_POINT,
    SUBJECT_LIST,
    CREATE_RSS, SEND_REQUEST,
    EMAIL_LINK, SUBJECT_LIST_FURNIZOR, VERIFY_RSS_BY_EMAIL, VERIFY_RSS_BY_POD, GET_CP
} from '@constants/endpoints/rss';
import {store} from '@lib/store';
import axios from 'axios';

export function getRequestsList(payload: any) {
    const state = store.getState();

    return axios.get(
        `${REQUEST_LIST}?sortBy=${payload.sortBy || ''}&sortType=${payload.sortType || ''}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&statusId=${payload.status || ''}&keywords=${payload.text || ''}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getRequestsDetails(id: any) {
    const state = store.getState();

    return axios.get(
        `${REQUEST_DETAILS}?id=${id}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function updateRequest(payload: any) {
    const state = store.getState();

    return axios.post(
        UPDATE_REQUEST,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function verifyCPoint(payload: any) {
    const state = store.getState();

    return axios.get(
        `${VERIFY_CONSUMPTION_POINT}?pod=${payload.pod}&countyCode=${payload.countyCode}&localityCode=${payload.localityCode}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getSubjectList() {
    const state = store.getState();

    return axios.get(
        `${SUBJECT_LIST}`,
        {
            headers: {
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function registerRSS(payload: any, channel: string) {
    const state = store.getState();
    let headers = {};

    if (channel === 'ContactForm') {
        headers = {...headers, 'Client': payload.atributeSpecificeScenariului.Email};
    }

    return axios.post(
        CREATE_RSS,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
                'CommunicationChannel': channel,
                ...headers
            }
        }
    );
}

export function sendRequest(id: any) {
    const state = store.getState();

    return axios.get(
        `${SEND_REQUEST}`,
        {
            params: { id },
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function emailLinkRSS(token: any) {
    const state = store.getState();

    return axios.get(
        EMAIL_LINK,
        {
            params: { token },
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function getSubjectListFurnizor() {
    const state = store.getState();

    return axios.get(
        `${SUBJECT_LIST_FURNIZOR}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function verifyRssByEmail(payload: any) {
    const state = store.getState();

    return axios.get(
        `${VERIFY_RSS_BY_EMAIL}?verifyPod=false&era=${payload.era}&year=${payload.year}&text=${payload.email}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function verifyRssByPod(payload: any) {
    const state = store.getState();

    return axios.get(
        `${VERIFY_RSS_BY_POD}?verifyPod=true&era=${payload.era}&year=${payload.year}&text=${payload.pod}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getConsumptionPoints(payload: any) {
    const state = store.getState();

    return axios.get(
        `${GET_CP}?countyCode=${payload.county}&pod=${payload.pod}&cnp=${payload.cnp}&cui=${payload.cui}`,
        {
            headers: {
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
            }
        }
    );
}

export function getConsumptionPointsBO(payload: any) {
    console.log('bo');
    const state = store.getState();

    return axios.get(
        `${GET_CP}?countyCode=${payload.county}&pod=${payload.pod}&cnp=${payload.cnp}&cui=${payload.cui}`,
        {
            headers: {
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
                'BackOfficeSecurityContext': state.backOffice.securityContext ?? ''
            }
        }
    );
}

export function registerRSSBO(payload: any) {
    const state = store.getState();

    return axios.post(
        CREATE_RSS,
        payload,
        {
            headers: {
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
                'CommunicationChannel': 'backoffice',
                //@ts-ignore
                'Client': state.backOffice.securityContext,
            }
        }
    );
}