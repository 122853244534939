import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faAnglesLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Dropdown } from 'react-bootstrap';

const Pagination = (props: { currentPage: any; totalElements: any; perPage: any; paginate: any; setElementsPerPage: any }) => {
  const { currentPage, totalElements, perPage, paginate, setElementsPerPage } = props;

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(Number(totalElements) / Number(perPage)); i++) {
    pageNumbers.push(i);
  }

  const renderPages = (prev: number, current: number, next: number) => {
    return (
      <>
        <li onClick={() => paginate(prev)} className={prev === Number(currentPage) ? 'page-link-current' : 'page-link-not-current'}>
          <a className={prev === Number(currentPage) ? 'current' : 'not-current'} href="#!">
            {prev}
          </a>
        </li>
        <li onClick={() => paginate(current)} className={current === Number(currentPage) ? 'page-link-current' : 'page-link-not-current'}>
          <a className={current === Number(currentPage) ? 'current' : 'not-current'} href="#!">
            {current}
          </a>
        </li>
        <li onClick={() => paginate(next)} className={next === Number(currentPage) ? 'page-link-current' : 'page-link-not-current'}>
          <a className={next === Number(currentPage) ? 'current' : 'not-current'} href="#!">
            {next}
          </a>
        </li>
      </>
    );
  };

  const pageCases = (pages: number[]) => {
    if (pages.length > 10) {
      return (
        <>
          <li
            onClick={() => {
              paginate(1);
            }}
            className={1 === Number(currentPage) ? 'page-link-current' : 'page-link-not-current'}
          >
            <a className={1 === Number(currentPage) ? 'current' : 'not-current'} href="#!">
              1
            </a>
          </li>
          {Number(currentPage) < 4 && (
            <>
              {renderPages(2, 3, 4)}
              <li className="page-link-not-current">...</li>
            </>
          )}
          {Number(currentPage) >= 4 && Number(currentPage) <= pageNumbers.length - 3 && (
            <>
              <li className="page-link-not-current">...</li>
              {renderPages(Number(currentPage) - 1, Number(currentPage), Number(currentPage) + 1)}
              <li className="page-link-not-current">...</li>
            </>
          )}
          {Number(currentPage) > pageNumbers.length - 3 && (
            <>
              <li className="page-link-not-current">...</li>
              {renderPages(pageNumbers.length - 3, pageNumbers.length - 2, pageNumbers.length - 1)}
            </>
          )}
          <li
            onClick={() => {
              paginate(pageNumbers.length);
            }}
            className={pageNumbers.length === Number(currentPage) ? 'page-link-current' : 'page-link-not-current'}
          >
            <a className={pageNumbers.length === Number(currentPage) ? 'current' : 'not-current'} href="#!">
              {pageNumbers.length}
            </a>
          </li>
        </>
      );
    } else {
      return (
        <>
          {pages.map((number) => (
            <li
              key={number}
              className={number === Number(currentPage) ? 'page-link-current' : 'page-link-not-current'}
              onClick={() => {
                paginate(number);
              }}
            >
              <a className={number === Number(currentPage) ? 'current' : 'not-current'} href="#!">
                {number}
              </a>
            </li>
          ))}
        </>
      );
    }
  };

  return (
    <>
      <Row className="d-flex justify-content-between">
        <Col xs={12} md={4}>
          <Row>
            <div className="d-flex">
              <span className="mt-1 me-2" style={{ color: '#707070', fontFamily: 'Open Sans, sans-serif', fontSize: '13px' }}>
                Număr pe pagină:
              </span>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  style={{
                    fontFamily: 'Open Sans, sans-serif',
                    borderStyle: 'none',
                    height: '30px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#FAFAFA',
                    color: '#707070',
                    fontSize: '13px'
                  }}
                >
                  {Number(perPage)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '13px' }}
                    onClick={() => {
                      setElementsPerPage(10);
                      paginate(1);
                    }}
                  >
                    10
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '13px' }}
                    onClick={() => {
                      setElementsPerPage(20);
                      paginate(1);
                    }}
                  >
                    20
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '13px' }}
                    onClick={() => {
                      setElementsPerPage(50);
                      paginate(1);
                    }}
                  >
                    50
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '13px' }}
                    onClick={() => {
                      setElementsPerPage(100);
                      paginate(1);
                    }}
                  >
                    100
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <nav>
            <ul className="pagination">
              {Number(currentPage) === 1 ? (
                // <FontAwesomeIcon style={{ height: '14px' }} className="nav-icon-disabled" icon={faAnglesLeft} />
                <></>
              ) : (
                <FontAwesomeIcon style={{ height: '14px' }} className="nav-icon" onClick={() => paginate(1)} icon={faAnglesLeft} />
              )}
              {Number(currentPage) === 1 ? (
                // <FontAwesomeIcon style={{ height: '14px' }} className="nav-icon-disabled" icon={faAngleLeft} />
                <></>
              ) : (
                <FontAwesomeIcon
                  style={{ height: '14px' }}
                  className="nav-icon"
                  onClick={() => paginate(Number(currentPage) - 1)}
                  icon={faAngleLeft}
                />
              )}
              {pageCases(pageNumbers)}
              {Number(currentPage) === pageNumbers.length ? (
                // <FontAwesomeIcon style={{ height: '14px' }} className="nav-icon-disabled" icon={faAngleRight} />
                <></>
              ) : (
                <FontAwesomeIcon
                  style={{ height: '14px' }}
                  className="nav-icon"
                  onClick={() => paginate(Number(currentPage) + 1)}
                  icon={faAngleRight}
                />
              )}
              {Number(currentPage) === pageNumbers.length ? (
                // <FontAwesomeIcon style={{ height: '14px' }} className="nav-icon-disabled" icon={faAnglesRight} />
                <></>
              ) : (
                <FontAwesomeIcon
                  style={{ height: '14px' }}
                  className="nav-icon"
                  onClick={() => paginate(pageNumbers.length)}
                  icon={faAnglesRight}
                />
              )}
            </ul>
          </nav>
        </Col>
      </Row>
    </>
  );
};

export default Pagination;
