import DatePicker from '@components/common/Date/DatePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ValueToSet } from '../PageWrapper';

type YearMonthSelectorProps = {
  name: string;
  value: string | any;
  label?: string;
  itemTypeId?: number;
  onChange?: (e: ValueToSet) => void;
  disabled?: boolean;
  focus?: boolean;
  monthFieldName: string | any;
  monthFieldValue: string | any;
  monthItemTypeId: number;
  minValue: string | any;
  maxValue: string | any;
};

const YearMonthSelector = ({
  name,
  value,
  label,
  onChange,
  itemTypeId,
  disabled,
  focus,
  monthFieldName,
  monthFieldValue,
  monthItemTypeId,
  minValue,
  maxValue
}: YearMonthSelectorProps) => {
  const [minDate, setMinDate] = useState<any>('');
  const [maxDate, setMaxDate] = useState<any>('');
  const [dateValue, setDateValue] = useState<any>('');

  useEffect(() => {
    if (value && monthFieldValue) {
      let date = moment()
        .year(value)
        .month(Number(monthFieldValue) - 1)
        .date(1)
        .toDate();
      setDateValue(date);
    } else {
      setDateValue('');
    }
  }, [value, monthFieldValue]);

  useEffect(() => {
    if (minValue) {
      let minDateYear = Number(minValue.split('-')[0]);
      let minDateMonth = Number(minValue.split('-')[1]) - 1;
      let minDate = moment().year(minDateYear).month(minDateMonth).date(1).toDate();
      setMinDate(minDate);
      onChange && onChange({ name: name, value: '', itemTypeId } as ValueToSet);
    }
  }, [minValue]);

  useEffect(() => {
    if (maxValue) {
      let maxDateYear = Number(maxValue.split('-')[0]);
      let maxDateMonth = Number(maxValue.split('-')[1]) - 1;
      let maxDate = moment().year(maxDateYear).month(maxDateMonth).endOf('month').toDate();
      setMaxDate(maxDate);
      onChange && onChange({ name: name, value: '', itemTypeId } as ValueToSet);
    }
  }, [maxValue]);

  return (
    <DatePicker
      label={label}
      name={label}
      showMonthYearPicker
      selected={dateValue}
      onChange={async (e: any) => {
        let year = moment(e).format('YYYY');
        let month = `${Number(moment(e).format('MM'))}`;
        onChange && onChange({ name: name, value: year, itemTypeId } as ValueToSet);
        onChange && onChange({ name: monthFieldName, value: month, itemTypeId: monthItemTypeId } as ValueToSet);
      }}
      minDate={minDate}
      maxDate={maxDate}
      mbZero
      dateFormat={'yyyy MM'}
    />
  );
};

export default YearMonthSelector;
