import { TOGGLE_LOADING_SCREEN, SET_CONTENT_LOADING_SCREEN } from '@constants/ActionTypes/PostTypes';
import { LoaderPayload } from '@reducers/loader';

export function toggleLoader(isOpen = false, key?: string) {
  return {
    type: TOGGLE_LOADING_SCREEN,
    payload: { isOpen, key } as LoaderPayload
  };
}

export function setLoaderMessage(message = '') {
  return {
    type: SET_CONTENT_LOADING_SCREEN,
    payload: { message } as LoaderPayload
  };
}
