import { SET_BO_OPTIONS, SET_RESPONSE_HEADERS } from '@constants/ActionTypes/PostTypes';
import { AxiosRequestHeaders } from 'axios';
import { Action } from './index';
export class BackOfficeState {
    basePath?: string | null;
    securityContext?: string | null;
    exportDocumentHeaders?: AxiosRequestHeaders | null;
}

const initialState: BackOfficeState = {
    basePath: null,
    securityContext: ''
};

export function backOffice(state = initialState, action = {} as Action<BackOfficeState>) {
    const { type, payload } = action;

    switch (type) {
        case SET_BO_OPTIONS: {
            return { ...state, ...payload };
        }
        case SET_RESPONSE_HEADERS: {
            return { ...state, ...payload };
        }
        default:
            return state;
    }
}