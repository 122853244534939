import React from 'react';
import {Cards} from '@components/static/Carousel/Cards';
import { CardsKentico } from '../../typescript/kentico-types';
import { DelgazWrapper } from '@components/global/DelgazWrapper';

export type CardsListProps = { kentico: CardsKentico };

export const CardsList = (props: CardsListProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <Cards {...props} />
    </DelgazWrapper>
  );
};
