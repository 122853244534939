import { DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from '@components/common/Date/DatePicker';
import moment from 'moment';
import DynamicTable from '@components/common/DynamicTable/DynamicTable';
import { getAnexaStepOne, postAnexaStepOne } from '@api/revizii/revizii';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { RVCreareFisaNouaStepOne } from '../../../../helpers/schemas';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';

export const TYPE_VERIFICARE = 'VERIFICARE';
export const TYPE_REVIZIE = 'REVIZIE';

type RVStepOneProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  setIdAnexa: (id: string) => void;
  idAnexa: string;
};

type StepOneType = {
  clc: string;
  POD: string;
  cod_judet: string;
  cod_localitate: string;
  nume_partener: string;
  societate: string;
  adresa_loc_consum: string;
  nr_contract: string;
  data_contract: string;
  nr_oe: string;
  data_oe: string;
  nume_instalator: string;
  tip_legitimatie: string;
  numar_legitimatie: string;
  valabilitate_legitimatie: string;
  an_legitimatie: string;
  demontare_contor: boolean;
  sistare_gaze: boolean;
  an_documentatie_tehnica: number;
  sigiliu_vechi: string;
  sigiliu_nou: string;
  nr_proces_verbal: string;
  data_proces_verbal: string;
  nr_documentatie_tehnica: string;
  data_ultima_revizie: string;
  data_scadenta_verbal: string;
  data_lucrare: string;
  consumatoare_notificate: any[];
  consumatoare_loc_consum: any[];
};

const RVStepOne = (props: RVStepOneProps) => {
  const dispatch = useDispatch();
  //eslint-disable-next-line
  const { setStep, currentStep, progress, setProgress } = props;
  const [initialValues, setInitialValues] = useState<any>(null);

  //errors
  const [error, setError] = useState('');
  const [errorNrProcesVerbal, setErrorNrProcesVerbal] = useState(false);
  const [errorSigiliuVechi, setErrorSigiliuVechi] = useState(false);
  const [errorSigiliuNou, setErrorSigiliuNou] = useState(false);
  const [errorDataDemontareContor, setErrorDataDemontareContor] = useState(false);
  const [errorNrDocumentatieTehnica, setErrorNrDocumentatieTehnica] = useState(false);
  const [errorAnDocumentatieTehnica, setErrorAnDocumentatieTehnica] = useState(false);
  const [errorDevicesNotificate, setErrorDevicesNotificate] = useState(false);
  const [errorDevicesIdentificate, setErrorDevicesIdentificate] = useState(false);
  const [tipuriSarcini, setTipuriSarcini] = useState<DropdownOptionType[]>([]);

  const [name, setName] = useState<any>('');
  const [adress, setAdress] = useState<any>('');
  const [companyName, setCompanyName] = useState<any>('');
  const [clc, setClc] = useState<any>('');
  const [pod, setPod] = useState<any>('');
  const [codJudet, setCodJudet] = useState<any>('');
  const [codLocalitate, setCodLocalitate] = useState<any>('');

  const [nrContract, setNrContract] = useState<any>('');
  const [dataContract, setDataContract] = useState<any>('');
  const [nrFisa, setNrFisa] = useState<any>('');
  const [dataFisa, setDataFisa] = useState<any>('');
  const [detineDocumentatiaTehnica, setDetineDocumentatiaTehnica] = useState<any>(false);
  const [nrDocumentatieTehnica, setNrDocumentatieTehnica] = useState<any>('');
  const [anDocumentatieTehnica, setAnDocumentatieTehnica] = useState<any>('');
  const [dataUltimeiVerificariSauRevizii, setDataUltimeiVerificariSauRevizii] = useState<any>('');
  const [dataScadentaVerificariiSauReviziei, setDataScadentaVerificariiSauReviziei] = useState<any>('');
  const [devicesNotificate, setDevicesNotificate] = useState<any[]>([]);
  const [devicesIdentificate, setDevicesIdentificate] = useState<any[]>([]);
  const [demontareContor, setDemontareContor] = useState<boolean | any>(null);
  const [nrProcesVerbal, setNrProcesVerbal] = useState<any>('');
  const [sigiliuVechi, setSigiliuVechi] = useState<any>('');
  const [sigiliuNou, setSigiliuNou] = useState<any>('');
  const [dataDemontareContor, setDataDemontareContor] = useState<any>('');
  const [dataEfectuariiVerificariiSauReviziei, setDataEfectuariiVerificariiSauReviziei] = useState<any>('');
  const [sistareAlimentareCuGaze, setSistareAlimentareCuGaze] = useState<boolean | any>(null);

  //instalator
  const [numeInstalator, setNumeInstalator] = useState('');
  const [legitimatieTipInstalator, setLegitimatieTipInstalator] = useState('');
  const [legitimatieAnInstalator, setLegitimatieAnInstalator] = useState('');
  const [dataValabilitateLegitimatieInstalator, setDataValabilitateLegitimatieInstalator] = useState('');
  const [legitimatieNumarInstalator, setLegitimatieNumarInstalator] = useState('');

  const dynamicTableFormat = {
    columns: [
      { name: 'nrCrt', type: 'id', dataType: '', colName: 'Nr. CRT' },
      { name: 'tip', type: 'select', options: tipuriSarcini, dataType: 'numeric', colName: 'Tip', width: '15rem' },
      { name: 'numar', type: 'input', dataType: 'string', colName: 'Numar', width: '8rem' },
      { name: 'debit', type: 'input', dataType: 'numeric', floatPrecision: 2, colName: 'Debit', width: '8rem' },
      { name: 'descriere', type: 'input', dataType: 'string', colName: 'Descriere', width: '10rem' }
    ]
  };

  useEffect(() => {
    if (tipuriSarcini.length > 0) {
      if (props.idAnexa) {
        dispatch(toggleLoader(true));
        getAnexaStepOne(props.idAnexa)
          .then((res) => {
            dispatch(toggleLoader(false));
            initializeData(res);
          })
          .catch((err) => {
            dispatch(toggleLoader(false));
          });
      }
    }
  }, [tipuriSarcini]);

  useEffect(() => {
    if (props.dictionare) {
      setTipuriSarcini(props?.dictionare?.DeviceType);
    }
  }, [props.dictionare]);

  useEffect(() => {
    if (props.cpData) {
      setCompanyName(props.cpData.companyName ?? '');
      setName(getNameConcatenated() ?? '');
      setAdress(props.cpData.address ?? getAddressConcatenated());
      setClc(props.clc ?? '');
      setCodJudet(props.county);
      setCodLocalitate(props.locality);
      setPod(props.pod ?? '');
    }
  }, []);

  useEffect(() => {
    if (nrProcesVerbal) {
      setErrorNrProcesVerbal(false);
    }
    if (sigiliuVechi) {
      setErrorSigiliuVechi(false);
    }
    if (sigiliuNou) {
      setErrorSigiliuNou(false);
    }
    if (dataDemontareContor) {
      setErrorDataDemontareContor(false);
    }
    if (nrDocumentatieTehnica) {
      setErrorNrDocumentatieTehnica(false);
    }
    if (anDocumentatieTehnica) {
      setErrorAnDocumentatieTehnica(false);
    }
  }, [nrProcesVerbal, sigiliuVechi, sigiliuNou, dataDemontareContor, anDocumentatieTehnica, nrDocumentatieTehnica]);

  useEffect(() => {
    let valid = true;
    devicesNotificate.forEach((device) => {
      if (!device.tip || !device.debit || !device.descriere || !device.numar) {
        valid = false;
      }
    });
    if (valid) {
      setErrorDevicesNotificate(false);
    }
  }, [devicesNotificate]);

  useEffect(() => {
    let valid = true;
    devicesIdentificate.forEach((device) => {
      if (!device.tip || !device.debit || !device.descriere || !device.numar) {
        valid = false;
      }
    });
    if (valid) {
      setErrorDevicesIdentificate(false);
    }
  }, [devicesIdentificate]);

  useEffect(() => {
    if (detineDocumentatiaTehnica == true) {
      setNrDocumentatieTehnica('');
      setAnDocumentatieTehnica('');
    }
  }, [detineDocumentatiaTehnica]);

  useEffect(() => {
    if (demontareContor == false) {
      setNrProcesVerbal('');
      setSigiliuVechi('');
      setSigiliuNou('');
      setDataDemontareContor('');
    }
  }, [demontareContor]);

  const getNameConcatenated = () => {
    let name = '';
    if (props.cpData.clientLastName) {
      name += `${props.cpData.clientLastName}`;
    }
    if (props.cpData.clientFirstName) {
      name += ` ${props.cpData.clientFirstName}`;
    }
    return name;
  };

  const getAddressConcatenated = () => {
    let address = '';
    if (props.cpData.countyName) {
      address += `${props.cpData.countyName}`;
    }
    if (props.cpData.localityName) {
      address += `, ${props.cpData.localityName}`;
    }
    if (props.cpData.streetName) {
      address += `, ${props.cpData.streetName}`;
    }
    if (props.cpData.building) {
      address += `, ${props.cpData.building}`;
    }
    if (props.cpData.houseNum1) {
      address += `, ${props.cpData.houseNum1}`;
    }
    if (props.cpData.building) {
      address += `, ${props.cpData.houseNum2}`;
    }
    return address;
  };

  const validateValues = () => {
    let valid = true;

    if (demontareContor) {
      if (!nrProcesVerbal) {
        setErrorNrProcesVerbal(true);
        valid = false;
      }
      if (!sigiliuVechi) {
        setErrorSigiliuVechi(true);
        valid = false;
      }
      if (!sigiliuNou) {
        setErrorSigiliuNou(true);
        valid = false;
      }
      if (!dataDemontareContor) {
        setErrorDataDemontareContor(true);
        valid = false;
      }
    }

    if (!detineDocumentatiaTehnica) {
      if (!nrDocumentatieTehnica) {
        setErrorNrDocumentatieTehnica(true);
        valid = false;
      }
      if (!anDocumentatieTehnica) {
        setErrorAnDocumentatieTehnica(true);
        valid = false;
      }
    }

    devicesNotificate.forEach((device) => {
      if (!device.tip || !device.debit || !device.descriere || !device.numar) {
        setErrorDevicesNotificate(true);
        valid = false;
      }
    });
    devicesIdentificate.forEach((device) => {
      if (!device.tip || !device.debit || !device.descriere || !device.numar) {
        setErrorDevicesIdentificate(true);
        valid = false;
      }
    });

    return valid;
  };

  const submit = (values: any) => {
    if (validateValues()) {
      dispatch(toggleLoader(true));

      let payload: any = {
        cod_judet: codJudet,
        cod_localitate: codLocalitate,
        tip_revizie: props.type == TYPE_REVIZIE ? 'REV' : 'INSP',
        nr_contract: nrContract,
        data_contract: dataContract,
        nr_oe: nrFisa,
        data_oe: dataFisa,
        nume_instalator: numeInstalator,
        tip_legitimatie: legitimatieTipInstalator,
        numar_legitimatie: legitimatieNumarInstalator,
        valabilitate_legitimatie: dataValabilitateLegitimatieInstalator,
        an_legitimatie: legitimatieAnInstalator ?? null,
        demontare_contor: !!demontareContor,
        sigiliu_vechi: sigiliuVechi,
        sigiliu_nou: sigiliuNou,
        nr_proces_verbal: nrProcesVerbal,
        data_proces_verbal: dataDemontareContor ? dataDemontareContor : null,
        nr_documentatie_tehnica: nrDocumentatieTehnica,
        an_documentatie_tehnica: anDocumentatieTehnica ? anDocumentatieTehnica : null,
        data_ultima_revizie: dataUltimeiVerificariSauRevizii,
        data_scadenta_verbal: dataScadentaVerificariiSauReviziei,
        data_lucrare: dataEfectuariiVerificariiSauReviziei,
        sistare_gaze: sistareAlimentareCuGaze,
        consumatoare_notificate: devicesNotificate.map((device) => {
          return {
            annexeDeviceId: device.annexeDeviceId ?? 0,
            tip: tipuriSarcini.find((ts) => ts.name == device.tip)?.id ?? '',
            numar: device.numar,
            debit: device.debit,
            descriere: device.descriere
          };
        }),
        consumatoare_loc_consum: devicesIdentificate.map((device) => {
          return {
            annexeDeviceId: device.annexeDeviceId ?? 0,
            tip: tipuriSarcini.find((ts) => ts.name == device.tip)?.id ?? '',
            numar: device.numar,
            debit: device.debit,
            descriere: device.descriere
          };
        })
      };
      if (props.idAnexa) {
        payload.id_anexa = props.idAnexa;
      } else {
        payload.consumption_point = pod ? pod : clc ? clc : '';
      }

      postAnexaStepOne(payload)
        .then((res: any) => {
          dispatch(toggleLoader(false));
          props.setIdAnexa(res.data);
          props.setStep(props.currentStep + 1);
          props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
        })
        .catch((err) => {
          if (err && err.response.status && err.response.status == 412) {
            setError('Locul de consum introdus nu mai este valid.');
          } else setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    }
  };

  const initializeData = (values: StepOneType) => {
    setInitialValues(values);
    setNrContract(values['nr_contract']);
    setDataContract(values['data_contract']);
    setNrFisa(values['nr_oe']);
    setDataFisa(values['data_oe']);
    setNumeInstalator(values['nume_instalator']);
    setLegitimatieTipInstalator(values['tip_legitimatie']);
    setLegitimatieNumarInstalator(values['numar_legitimatie']);
    setDataValabilitateLegitimatieInstalator(values['valabilitate_legitimatie']);
    setLegitimatieAnInstalator(values['an_legitimatie']);
    setDemontareContor(values['demontare_contor']);
    setSigiliuVechi(values['sigiliu_vechi']);
    setSigiliuNou(values['sigiliu_nou']);
    setNrProcesVerbal(values['nr_proces_verbal']);
    setDataDemontareContor(values['data_proces_verbal']);
    setNrDocumentatieTehnica(values['nr_documentatie_tehnica']);
    setAnDocumentatieTehnica(values['an_documentatie_tehnica']);
    setDataUltimeiVerificariSauRevizii(values['data_ultima_revizie']);
    setDataScadentaVerificariiSauReviziei(values['data_scadenta_verbal']);
    setDataEfectuariiVerificariiSauReviziei(values['data_lucrare']);
    setSistareAlimentareCuGaze(values['sistare_gaze']);

    setName(values['nume_partener']);
    setAdress(values['adresa_loc_consum']);
    setCompanyName(values['societate']);
    setClc(values['clc']);
    setCodJudet(values['cod_judet']);
    setCodLocalitate(values['cod_localitate']);
    setPod(values['POD']);

    if (values.nr_documentatie_tehnica && values.an_documentatie_tehnica) {
      setDetineDocumentatiaTehnica(false);
    } else {
      setDetineDocumentatiaTehnica(true);
    }

    let initialDevicesNotificate = values['consumatoare_notificate'].map((consumator) => {
      return {
        annexeDeviceId: consumator.annexeDeviceId,
        tip: tipuriSarcini.find((ts) => ts.id == consumator.tip)?.name ?? '',
        numar: consumator.numar,
        debit: consumator.debit,
        descriere: consumator.descriere
      };
    });

    let initialDevicesIdentificate = values['consumatoare_loc_consum'].map((consumator) => {
      return {
        annexeDeviceId: consumator.annexeDeviceId,
        tip: tipuriSarcini.find((ts) => ts.id == consumator.tip)?.name ?? '',
        numar: consumator.numar,
        debit: consumator.debit,
        descriere: consumator.descriere
      };
    });

    setDevicesNotificate(initialDevicesNotificate);
    setDevicesIdentificate(initialDevicesIdentificate);
  };

  return (
    <>
      <Fragment>
        <Formik
          enableReinitialize
          initialValues={{
            nrContract: initialValues ? initialValues['nr_contract'] : '',
            dataContract: initialValues ? initialValues['data_contract'] : '',
            nrFisa: initialValues ? initialValues['nr_oe'] : '',
            dataFisa: initialValues ? initialValues['data_oe'] : '',
            demontareContor: initialValues ? initialValues['demontare_contor'] : '',
            dataUltimeiVerificariSauRevizii: initialValues ? initialValues['data_ultima_revizie'] : '',
            dataScadentaVerificariiSauReviziei: initialValues ? initialValues['data_scadenta_verbal'] : '',
            numeInstalator: initialValues ? initialValues['nume_instalator'] : '',
            legitimatieTipInstalator: initialValues ? initialValues['tip_legitimatie'] : '',
            legitimatieNumarInstalator: initialValues ? initialValues['numar_legitimatie'] : '',
            legitimatieAnInstalator: initialValues ? initialValues['an_legitimatie'] : '',
            dataValabilitateLegitimatieInstalator: initialValues ? initialValues['valabilitate_legitimatie'] : '',
            dataEfectuariiVerificariiSauReviziei: initialValues ? initialValues['data_lucrare'] : '',
            sistareAlimentareCuGaze: initialValues ? initialValues['sistare_gaze'] : ''
          }}
          onSubmit={(values) => submit(values)}
          validationSchema={RVCreareFisaNouaStepOne}
        >
          {(props) => {
            return (
              <Fragment>
                <h4 className="red mt-2">
                  FlŞA DE EVIDENŢĂ a lucrărilor periodice de revizie tehnică a instalaţiei de utilizare a gazelor naturale
                </h4>

                <Form>
                  <Row>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="name"
                        name="name"
                        render={() => <Input label="Nume și prenume/Denumire partener" mbZero value={name} disabled />}
                      />
                      <ErrorMessage name="name" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>

                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="adress"
                        name="adress"
                        render={() => <Input label="Adresă loc de consum" mbZero value={adress} disabled />}
                      />
                      <ErrorMessage name="adress" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="companyName"
                        name="companyName"
                        render={() => <Input label="Denumire societate" mbZero value={companyName} disabled />}
                      />
                      <ErrorMessage name="companyName" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="nrContract"
                        name="nrContract"
                        render={() => (
                          <Input
                            label="Nr. contract prestări servicii*"
                            mbZero
                            value={nrContract}
                            onBlur={() => {
                              props.setFieldTouched('nrContract');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('nrContract', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('nrContract', '');
                              setNrContract(e.target.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="nrContract" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="dataContract"
                        name="dataContract"
                        render={() => (
                          <DatePicker
                            acceptedDifferenceInYears={20}
                            mbZero
                            label="Dată contract prestări servicii*"
                            name="Dată contract prestări servicii*"
                            selected={dataContract ? moment(dataContract).toDate() : dataContract}
                            onChange={(e: any) => {
                              let date = e ? moment(e).format('YYYY-MM-DD') : '';
                              setDataContract(date);
                              props.setFieldValue('dataContract', date);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="dataContract" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="nrFisa"
                        name="nrFisa"
                        render={() => (
                          <Input
                            label="Nr.înregistrare fișa OE*"
                            mbZero
                            value={nrFisa}
                            onBlur={() => {
                              props.setFieldTouched('nrFisa');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('nrFisa', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('nrFisa', '');
                              setNrFisa(e.target.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="nrFisa" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="dataFisa"
                        name="dataFisa"
                        render={() => (
                          <DatePicker
                            acceptedDifferenceInYears={20}
                            mbZero
                            label="Dată înregistrare fișa OE*"
                            name="Dată înregistrare fișa OE*"
                            selected={dataFisa ? moment(dataFisa).toDate() : dataFisa}
                            onChange={(e: any) => {
                              let date = e ? moment(e).format('YYYY-MM-DD') : '';
                              setDataFisa(date);
                              props.setFieldValue('dataFisa', date);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="dataFisa" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                  </Row>
                  <p className="m-0 mt-3">
                    <strong>Documentaţia tehnică în baza căreia se efectuează verificarea/revizia tehnică</strong>
                  </p>
                  <Row>
                    <Col xs={12} md={2} className="mt-3" style={{ display: 'flex' }}>
                      <div onClick={() => setDetineDocumentatiaTehnica(!detineDocumentatiaTehnica)} className="checkbox-container pointer">
                        <input
                          className="create-account-checkbox"
                          type="checkbox"
                          checked={detineDocumentatiaTehnica}
                          onChange={() => setDetineDocumentatiaTehnica(!detineDocumentatiaTehnica)}
                        />
                        <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
                          Nu deține
                        </span>
                      </div>
                    </Col>
                    <Col xs={12} md={5} className="mt-3">
                      {!detineDocumentatiaTehnica && (
                        <>
                          <Field
                            id="nrDocumentatieTehnica"
                            name="nrDocumentatieTehnica"
                            render={() => (
                              <Input
                                label="Număr*"
                                mbZero
                                value={nrDocumentatieTehnica}
                                onBlur={() => {
                                  props.setFieldTouched('nrDocumentatieTehnica');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('nrDocumentatieTehnica', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('nrDocumentatieTehnica', '');
                                  setNrDocumentatieTehnica(e.target.value);
                                }}
                              />
                            )}
                          />
                          {errorNrDocumentatieTehnica && (
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul Număr este obligatoriu.</div>
                          )}
                        </>
                      )}
                    </Col>
                    <Col xs={12} md={5} className="mt-3">
                      {!detineDocumentatiaTehnica && (
                        <>
                          <Field
                            id="anDocumentatieTehnica"
                            name="anDocumentatieTehnica"
                            render={() => (
                              <Input
                                label="An*"
                                mbZero
                                value={anDocumentatieTehnica}
                                onBlur={() => {
                                  props.setFieldTouched('anDocumentatieTehnica');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('anDocumentatieTehnica', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('anDocumentatieTehnica', '');
                                  setAnDocumentatieTehnica(e.target.value);
                                }}
                              />
                            )}
                          />
                          {errorAnDocumentatieTehnica && (
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul An este obligatoriu.</div>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="dataUltimeiVerificariSauRevizii"
                        name="dataUltimeiVerificariSauRevizii"
                        render={() => (
                          <DatePicker
                            acceptedDifferenceInYears={20}
                            mbZero
                            label="Data ultimei verificări/revizii efectuate la locul de consum*"
                            name="Data ultimei verificări/revizii efectuate la locul de consum*"
                            selected={
                              dataUltimeiVerificariSauRevizii
                                ? moment(dataUltimeiVerificariSauRevizii).toDate()
                                : dataUltimeiVerificariSauRevizii
                            }
                            onChange={(e: any) => {
                              let date = e ? moment(e).format('YYYY-MM-DD') : '';
                              setDataUltimeiVerificariSauRevizii(date);
                              props.setFieldValue('dataUltimeiVerificariSauRevizii', date);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="dataUltimeiVerificariSauRevizii"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="dataScadentaVerificariiSauReviziei"
                        name="dataScadentaVerificariiSauReviziei"
                        render={() => (
                          <DatePicker
                            acceptedDifferenceInYears={20}
                            mbZero
                            label="Data scadentă a verificării/reviziei periodice a instalației de utilizare*"
                            name="Data scadentă a verificării/reviziei periodice a instalației de utilizare*"
                            selected={
                              dataScadentaVerificariiSauReviziei
                                ? moment(dataScadentaVerificariiSauReviziei).toDate()
                                : dataScadentaVerificariiSauReviziei
                            }
                            onChange={(e: any) => {
                              let date = e ? moment(e).format('YYYY-MM-DD') : '';
                              setDataScadentaVerificariiSauReviziei(date);
                              props.setFieldValue('dataScadentaVerificariiSauReviziei', date);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="dataScadentaVerificariiSauReviziei"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="dataEfectuariiVerificariiSauReviziei"
                        name="dataEfectuariiVerificariiSauReviziei"
                        render={() => (
                          <DatePicker
                            acceptedDifferenceInYears={20}
                            mbZero
                            label="Data efectuării verificării/reviziei*"
                            name="Data efectuării verificării/reviziei*"
                            selected={
                              dataEfectuariiVerificariiSauReviziei
                                ? moment(dataEfectuariiVerificariiSauReviziei).toDate()
                                : dataEfectuariiVerificariiSauReviziei
                            }
                            onChange={(e: any) => {
                              let date = e ? moment(e).format('YYYY-MM-DD') : '';
                              setDataEfectuariiVerificariiSauReviziei(date);
                              props.setFieldValue('dataEfectuariiVerificariiSauReviziei', date);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="dataEfectuariiVerificariiSauReviziei"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                  <p className="m-0 mt-3">
                    <strong>
                      Instalatorul autorizat din cadrul operatorului economic autorizat ANRE care efectuează verificarea tehnică
                    </strong>
                  </p>
                  <Row>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="numeInstalator"
                        name="numeInstalator"
                        render={() => (
                          <Input
                            label="Nume şi prenume*"
                            mbZero
                            value={numeInstalator}
                            onBlur={() => {
                              props.setFieldTouched('numeInstalator');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('numeInstalator', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('numeInstalator', '');
                              setNumeInstalator(e.target.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="numeInstalator" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="legitimatieTipInstalator"
                        name="legitimatieTipInstalator"
                        render={() => (
                          <Input
                            label="Legitimaţie tip*"
                            mbZero
                            value={legitimatieTipInstalator}
                            onBlur={() => {
                              props.setFieldTouched('legitimatieTipInstalator');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('legitimatieTipInstalator', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('legitimatieTipInstalator', '');
                              setLegitimatieTipInstalator(e.target.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="legitimatieTipInstalator"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="legitimatieNumarInstalator"
                        name="legitimatieNumarInstalator"
                        render={() => (
                          <Input
                            label="Legitimaţie număr*"
                            mbZero
                            value={legitimatieNumarInstalator}
                            onBlur={() => {
                              props.setFieldTouched('legitimatieNumarInstalator');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('legitimatieNumarInstalator', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('legitimatieNumarInstalator', '');
                              setLegitimatieNumarInstalator(e.target.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="legitimatieNumarInstalator"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="legitimatieAnInstalator"
                        name="legitimatieAnInstalator"
                        render={() => (
                          <Input
                            label="Legitimaţie an*"
                            mbZero
                            value={legitimatieAnInstalator}
                            type="number"
                            onBlur={() => {
                              props.setFieldTouched('legitimatieAnInstalator');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('legitimatieAnInstalator', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('legitimatieAnInstalator', '');
                              setLegitimatieAnInstalator(e.target.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="legitimatieAnInstalator"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                    <Col xs={12} md={6} className="mt-3">
                      <Field
                        id="dataValabilitateLegitimatieInstalator"
                        name="dataValabilitateLegitimatieInstalator"
                        render={() => (
                          <DatePicker
                            acceptedDifferenceInYears={20}
                            mbZero
                            label="Valabilă până la data de*"
                            name="Valabilă până la data de*"
                            selected={
                              dataValabilitateLegitimatieInstalator
                                ? moment(dataValabilitateLegitimatieInstalator).toDate()
                                : dataValabilitateLegitimatieInstalator
                            }
                            onChange={(e: any) => {
                              let date = e ? moment(e).format('YYYY-MM-DD') : '';
                              setDataValabilitateLegitimatieInstalator(date);
                              props.setFieldValue('dataValabilitateLegitimatieInstalator', date);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="dataValabilitateLegitimatieInstalator"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                  <p className="m-0 mt-3">
                    <strong>Aparate consumatoare de combustibili gazoşi</strong>
                  </p>
                  <Row className="mb-3">
                    <p className="m-0 red">
                      <strong>NOTIFICATE DE FURNIZORUL DE GAZE NATURALE</strong>
                    </p>
                    <DynamicTable
                      format={dynamicTableFormat}
                      data={devicesNotificate}
                      handler={(devices: any) => {
                        setDevicesNotificate(devices);
                      }}
                      isEditing={true}
                      emptyTable
                      minimumNrOfRows={0}
                      mbZero
                    />
                    {errorDevicesNotificate && (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>Vă rugăm să completați toate câmpurile din tabel!</div>
                    )}
                  </Row>
                  <Row className="mb-3">
                    <p className="m-0 red">
                      <strong>IDENTIFICATE LA LOCUL DE CONSUM</strong>
                    </p>
                    <DynamicTable
                      format={dynamicTableFormat}
                      data={devicesIdentificate}
                      handler={(devices: any) => {
                        setDevicesIdentificate(devices);
                      }}
                      isEditing={true}
                      emptyTable
                      minimumNrOfRows={0}
                      mbZero
                    />
                    {errorDevicesIdentificate && (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>Vă rugăm să completați toate câmpurile din tabel!</div>
                    )}
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <p className="p-gray m-0">Sistare alimentare gaze*</p>
                      <div className="d-flex" style={{ flexDirection: 'row' }}>
                        <span
                          onClick={() => {
                            setSistareAlimentareCuGaze(true);
                            props.setFieldValue('sistareAlimentareCuGaze', true);
                          }}
                          className="me-2"
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={sistareAlimentareCuGaze}
                            checked={sistareAlimentareCuGaze == true}
                            onChange={() => {
                              setSistareAlimentareCuGaze(true);
                              props.setFieldValue('sistareAlimentareCuGaze', true);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Da</span>
                        </span>
                        <span
                          onClick={() => {
                            setSistareAlimentareCuGaze(false);
                            props.setFieldValue('sistareAlimentareCuGaze', false);
                          }}
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={sistareAlimentareCuGaze}
                            checked={sistareAlimentareCuGaze == false}
                            onChange={() => {
                              setSistareAlimentareCuGaze(false);
                              props.setFieldValue('sistareAlimentareCuGaze', false);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Nu</span>
                        </span>
                      </div>
                      <ErrorMessage
                        name="sistareAlimentareCuGaze"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                  <p className="m-0 mt-3">
                    <strong>Procesul-verbal de demontare/montare a sistemului/mijlocului de măsurare a gazelor naturale</strong>
                  </p>
                  <Row>
                    <Col xs={12} md={6}>
                      <p className="p-gray m-0">Demontare contor*</p>
                      <div className="d-flex" style={{ flexDirection: 'row' }}>
                        <span
                          onClick={() => {
                            setDemontareContor(true);
                            props.setFieldValue('demontareContor', true);
                          }}
                          className="me-2"
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={demontareContor}
                            checked={demontareContor == true}
                            onChange={() => {
                              setDemontareContor(true);
                              props.setFieldValue('demontareContor', true);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Da</span>
                        </span>
                        <span
                          onClick={() => {
                            setDemontareContor(false);
                            props.setFieldValue('demontareContor', false);
                          }}
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={demontareContor}
                            checked={demontareContor == false}
                            onChange={() => {
                              setDemontareContor(false);
                              props.setFieldValue('demontareContor', false);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold pointer">Nu</span>
                        </span>
                      </div>
                      <ErrorMessage
                        name="demontareContor"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                  {demontareContor && (
                    <Row>
                      <Col xs={12} md={3} className="mt-3">
                        <Field
                          id="nrProcesVerbal"
                          name="nrProcesVerbal"
                          render={() => (
                            <Input
                              label="Nr. proces verbal*"
                              mbZero
                              value={nrProcesVerbal}
                              onBlur={() => {
                                props.setFieldTouched('nrProcesVerbal');
                              }}
                              onChange={(e: any) => {
                                e.target.value.length !== 0 && props.setFieldValue('nrProcesVerbal', e.target.value);
                                e.target.value.length === 0 && props.setFieldValue('nrProcesVerbal', '');
                                setNrProcesVerbal(e.target.value);
                              }}
                            />
                          )}
                        />
                        {errorNrProcesVerbal && (
                          <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul Nr. proces verbal este obligatoriu.</div>
                        )}
                      </Col>
                      <Col xs={12} md={3} className="mt-3">
                        <Field
                          id="sigiliuVechi"
                          name="sigiliuVechi"
                          render={() => (
                            <Input
                              label="Sigiliu vechi*"
                              mbZero
                              value={sigiliuVechi}
                              onBlur={() => {
                                props.setFieldTouched('sigiliuVechi');
                              }}
                              onChange={(e: any) => {
                                e.target.value.length !== 0 && props.setFieldValue('sigiliuVechi', e.target.value);
                                e.target.value.length === 0 && props.setFieldValue('sigiliuVechi', '');
                                setSigiliuVechi(e.target.value);
                              }}
                            />
                          )}
                        />
                        {errorSigiliuVechi && (
                          <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul Sigiliu vechi este obligatoriu.</div>
                        )}
                      </Col>
                      <Col xs={12} md={3} className="mt-3">
                        <Field
                          id="sigiliuNou"
                          name="sigiliuNou"
                          render={() => (
                            <Input
                              label="Sigiliu nou*"
                              mbZero
                              value={sigiliuNou}
                              onBlur={() => {
                                props.setFieldTouched('sigiliuNou');
                              }}
                              onChange={(e: any) => {
                                e.target.value.length !== 0 && props.setFieldValue('sigiliuNou', e.target.value);
                                e.target.value.length === 0 && props.setFieldValue('sigiliuNou', '');
                                setSigiliuNou(e.target.value);
                              }}
                            />
                          )}
                        />
                        {errorSigiliuNou && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul Sigiliu nou este obligatoriu.</div>}
                      </Col>
                      <Col xs={12} md={3} className="mt-3">
                        <Field
                          id="dataDemontareContor"
                          name="dataDemontareContor"
                          render={() => (
                            <DatePicker
                              acceptedDifferenceInYears={20}
                              mbZero
                              label="Data*"
                              name="Data*"
                              selected={dataDemontareContor ? moment(dataDemontareContor).toDate() : dataDemontareContor}
                              onChange={(e: any) => {
                                let date = e ? moment(e).format('YYYY-MM-DD') : '';
                                setDataDemontareContor(date);
                                props.setFieldValue('dataDemontareContor', date);
                              }}
                            />
                          )}
                        />
                        {errorDataDemontareContor && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmpul Data este obligatoriu.</div>}
                      </Col>
                    </Row>
                  )}
                  {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

                  <StepsNavigationButtons className="mb-3" disableBtnPrevious btnNextTypeSubmit />
                </Form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    </>
  );
};

export default RVStepOne;
