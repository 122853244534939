import 'filepond/dist/filepond.min.css';
import { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';

import { File as FileType } from '@components/common/UploadFile/UploadFile';
import Modal from '@components/dev/Components/Modal';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FilePond } from 'react-filepond';
import { removeElementsByClass } from '../../../helpers/dom';
import { convertBytesToMb } from '../../../helpers/file';
import WarningIcon from '../../assets/resources/img/events-icons/warning.svg';
import { CustomModal } from '../ModalConfirm';

export type File = {
  [key: string]: any;
  fileUrl?: string | any;
  containerName?: string | any;
};

export type UploadFilesBulkProps = {
  files: any;
  setFiles: (files: any) => void;
  filesEdit?: any;

  deleteOneEditFile?: (file: any) => void;
  deleteAllEditFiles?: () => void;

  requiredDeleteConfirm?: boolean;
  allowedFileExtensions?: string[] | null;
  reloadComponent?: () => void;
  setExternalSelectedTab?: (tab: string) => void;
  externalSelectedTab?: string;
  totalSizeLimit?: number | any;
};

const UploadFilesBulk = ({
  files,
  setFiles,
  filesEdit,
  deleteAllEditFiles,
  deleteOneEditFile,
  requiredDeleteConfirm,
  allowedFileExtensions,
  reloadComponent,
  setExternalSelectedTab,
  externalSelectedTab,
  totalSizeLimit
}: UploadFilesBulkProps) => {
  const [fileToDelete, setFileToDelete] = useState<FileType>();

  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [showModalConfirmDeleteAllFiles, setShowModalConfirmDeleteAllFiles] = useState(false);
  const [selectedTabKey, setSelectedTabKey] = useState<string>('uploadfiles');

  const [showModalError, setShowModalError] = useState(false);
  const [showModalErrorSizeZeroBytes, setShowModalErrorSizeZeroBytes] = useState(false);
  const [showModalToalSizeLimitError, setShowModalToalSizeLimitError] = useState(false);

  useEffect(() => {
    removeLibCredits();
    if (externalSelectedTab) {
      setSelectedTabKey(externalSelectedTab);
    }
  }, []);

  const removeLibCredits = () => {
    setTimeout(() => {
      //remove lib credits
      removeElementsByClass('filepond--credits');
    }, 10);
  };

  const handleSelectTab = (tab: string | any) => {
    removeLibCredits();
    setSelectedTabKey(tab);
    setExternalSelectedTab && setExternalSelectedTab(tab);
  };

  const handleUpdateFiles = (fileItems: any) => {
    let errorWrongExtension = false;
    let errorSizeZeroBytes = false;

    fileItems.forEach((fi: any) => {
      if (fi.fileSize == 0) {
        errorSizeZeroBytes = true;
      }
      if (allowedFileExtensions && allowedFileExtensions.length > 0 && !allowedFileExtensions.includes(`.${fi.fileExtension}`)) {
        errorWrongExtension = true;
      }
    });

    if (errorSizeZeroBytes) {
      setShowModalErrorSizeZeroBytes(true);
      return;
    }
    if (errorWrongExtension) {
      reloadComponent && reloadComponent();
      setShowModalError(true);
      return;
    }

    if (totalSizeLimit) {
      let total = 0;
      fileItems.forEach((fi: any) => {
        let sizeInMb = convertBytesToMb(fi.fileSize, 2);
        total = total + sizeInMb;
      });
      if (total > totalSizeLimit) {
        setShowModalToalSizeLimitError(true);
        return;
      }
    }

    setFiles(fileItems);
  };

  const handleRemoveFileFromEditList = (file: FileType) => {
    if (filesEdit) {
      if (requiredDeleteConfirm) {
        setShowModalConfirmDelete(true);
        setFileToDelete(file);
      } else {
        deleteOneEditFile && deleteOneEditFile(file);
      }
    }
  };

  const confirmDeleteFile = () => {
    deleteOneEditFile && deleteOneEditFile(fileToDelete);
    setShowModalConfirmDelete(false);
  };

  const confirmDeleteAllFiles = () => {
    setFiles([]);
    deleteAllEditFiles && deleteAllEditFiles();
    setShowModalConfirmDeleteAllFiles(false);
  };

  const renderEdit = () => {
    if (filesEdit && filesEdit.length > 0) {
      return (
        <div
          style={{
            backgroundColor: '#F1F0EF',
            width: '100%',
            marginTop: '-30px',
            minHeight: '120px',
            height: 'auto',
            borderRadius: '0.5em'
          }}
        >
          <div style={{ backgroundColor: '#F1F0EF', height: '5px' }}></div>
          <hr className="hr" />

          <div style={{ margin: '0.25em', paddingBottom: '0.25em' }}>
            <p style={{ margin: '4px', paddingLeft: '10px' }}>Fișiere existente</p>

            {filesEdit.map((fe: any) => (
              <div
                style={{
                  backgroundColor: '#ffff',
                  margin: '0.75em',
                  height: '45px',
                  borderRadius: '0.5em'
                }}
              >
                <div
                  style={{
                    position: 'static',
                    display: 'flex',
                    padding: '0.5625em',
                    color: '#ea1c0a',
                    overflow: 'hidden'
                  }}
                >
                  <div onClick={() => handleRemoveFileFromEditList(fe)}>
                    <div style={{ color: '#ea1c0a', cursor: 'pointer' }}>
                      <FontAwesomeIcon width={12} icon={faTrash} className="edit-icon" />
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      window.location.href = fe.fileUrl;
                    }}
                  >
                    <a download className="ms-2 brand-link-download link link--download pointer">
                      &nbsp;
                    </a>
                  </div>

                  <div className="d-flex justify-content-between mt-1" style={{ width: '95%' }}>
                    <span
                      style={{
                        marginLeft: '8px',
                        fontSize: '12px',
                        lineHeight: 'normal',
                        whiteSpace: 'nowrap' /* Prevents line breaks */,
                        overflow: 'hidden' /* Hides any content beyond the width of the container */,
                        textOverflow: 'ellipsis',
                        fontWeight: 450,
                        textRendering: 'optimizeLegibility'
                      }}
                    >
                      {fe.name}
                    </span>

                    {fe.date && (
                      <span
                        style={{
                          marginLeft: '8px',
                          marginRight: '8px',
                          fontSize: '12px',
                          lineHeight: 'normal',
                          whiteSpace: 'nowrap',
                          fontWeight: 450,
                          textRendering: 'optimizeLegibility'
                        }}
                      >
                        {moment.utc(fe.date)?.local()?.format('DD.MM.YYYY HH:mm')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <div>
          <Tabs
            className="create-account-tabs d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start"
            mountOnEnter={true}
            unmountOnExit={true}
            onSelect={handleSelectTab}
            activeKey={selectedTabKey}
          >
            <Tab eventKey="uploadfiles" title={'Încărcare fișiere'} mountOnEnter={true} unmountOnExit={true}>
              <div>
                <FilePond
                  files={files}
                  allowMultiple={true}
                  maxFiles={20}
                  onupdatefiles={handleUpdateFiles}
                  name="files"
                  labelIdle='<span class="filepond--label-action">Atașează fișiere</span>'
                  fileSizeBase={1024}
                />
                {renderEdit()}

                {totalSizeLimit && (
                  <p
                    className="red"
                    style={{
                      marginBottom: `${filesEdit && filesEdit.length > 0 ? '5px' : '16px'}`,
                      marginTop: `${filesEdit && filesEdit.length > 0 ? '0px' : '-14px'}`
                    }}
                  >
                    Dimensiunea maximă admisă pentru un set de date este de {totalSizeLimit} MB
                  </p>
                )}

                <Button
                  style={{ marginTop: `${filesEdit && filesEdit.length > 0 ? '0px' : '-16px'}` }}
                  onClick={() => {
                    if (requiredDeleteConfirm && (filesEdit.length > 0 || files.length > 0)) {
                      setShowModalConfirmDeleteAllFiles(true);
                    } else {
                      setFiles([]);
                      deleteAllEditFiles && deleteAllEditFiles();
                    }
                  }}
                >
                  Sterge toate fisierele
                </Button>
              </div>
            </Tab>
            <Tab eventKey="uploadfolder" title={'Încărcare directoare'} mountOnEnter={true} unmountOnExit={true}>
              <div>
                <FilePond
                  files={files}
                  allowDirectoriesOnly
                  allowMultiple={true}
                  maxFiles={20}
                  onupdatefiles={handleUpdateFiles}
                  name="files"
                  labelIdle='<span class="filepond--label-action">Atașează director</span>'
                  fileSizeBase={1024}
                />
                {renderEdit()}

                {totalSizeLimit && (
                  <p
                    className="red"
                    style={{
                      marginBottom: `${filesEdit && filesEdit.length > 0 ? '5px' : '16px'}`,
                      marginTop: `${filesEdit && filesEdit.length > 0 ? '0px' : '-14px'}`
                    }}
                  >
                    Dimensiunea maximă admisă pentru un set de date este de {totalSizeLimit} MB
                  </p>
                )}

                <Button
                  style={{ marginTop: `${filesEdit && filesEdit.length > 0 ? '0px' : '-16px'}` }}
                  onClick={() => {
                    if (requiredDeleteConfirm && (filesEdit.length > 0 || files.length > 0)) {
                      setShowModalConfirmDeleteAllFiles(true);
                    } else {
                      setFiles([]);
                      deleteAllEditFiles && deleteAllEditFiles();
                    }
                  }}
                >
                  Sterge toate fisierele
                </Button>
              </div>
            </Tab>
          </Tabs>
          <CustomModal
            showModal={showModalConfirmDelete}
            setShowModal={setShowModalConfirmDelete}
            cancelButton
            cancelButtonText="Nu"
            okButtonText="Da"
            onClose={confirmDeleteFile}
            onHide={() => setShowModalConfirmDelete(false)}
          >
            <p>Ești sigur că vrei sa ștergi fișierul {fileToDelete?.name}?</p>
          </CustomModal>

          <CustomModal
            showModal={showModalConfirmDeleteAllFiles}
            setShowModal={setShowModalConfirmDeleteAllFiles}
            cancelButton
            cancelButtonText="Nu"
            okButtonText="Da"
            onClose={confirmDeleteAllFiles}
            onHide={() => setShowModalConfirmDeleteAllFiles(false)}
          >
            <p>Ești sigur că vrei sa ștergi fișierele?</p>
          </CustomModal>

          <Modal title={'Eroare de procesare'} show={showModalError} setShow={() => setShowModalError(!showModalError)}>
            <span className="error-modal">
              <img width={40} height={40} src={WarningIcon} alt="Warning icon" />
              <p>Se acceptă documente și fișiere în format {allowedFileExtensions?.join(', ')}</p>
            </span>
          </Modal>

          <Modal
            title={'Eroare de procesare'}
            show={showModalToalSizeLimitError}
            setShow={() => {
              reloadComponent && reloadComponent();
            }}
          >
            <span className="error-modal">
              <img width={40} height={40} src={WarningIcon} alt="Warning icon" />
              <p>Dimensiunea maximă admisă pentru un set de date este de {totalSizeLimit} MB</p>
            </span>
          </Modal>

          <Modal
            title={'Eroare de procesare'}
            show={showModalErrorSizeZeroBytes}
            setShow={() => {
              reloadComponent && reloadComponent();
            }}
          >
            <span className="error-modal">
              <img width={40} height={40} src={WarningIcon} alt="Warning icon" />
              <p>Pot fi încărcate doar fișiere care au o dimensiune mai mare de zero biți</p>
            </span>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UploadFilesBulk;
