import { toggleLoader } from '@actions/LoaderActions';
import { deleteRequestConvention } from '@api/conventions/conventions';
import SearchInput from '@components/common/ActionSearch/SearchInput';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import Pagination from '@components/prosumatori/components/table/Pagination';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as notification from '@lib/Notification';
import moment from 'moment';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const RequestGrid = (props: {
  list: any[];
  totalItems: any;
  pageIndex: any;
  pageSize: any;
  getList: any;
  filterStatus: any;
  setFilterStatus: any;
  searchText: any;
  handleSearchText: any;
  sortColumn: string;
  sortAscending: boolean;
  onSort: any;
  handleSortColumn: any;
  handleSortAscending: any;
  summary: any;
}) => {
  const {
    list,
    totalItems,
    pageIndex,
    pageSize,
    getList,
    filterStatus,
    setFilterStatus,
    searchText,
    handleSearchText,
    sortColumn,
    sortAscending,
    onSort,
    handleSortAscending,
    handleSortColumn,
    summary
  } = props;

  //eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showModalNewRequest, setShowModalNewRequest] = useState(false);
  const [showModalImportBulk, setShowModalImportBulk] = useState(false);


  const indexOfLastElement = pageIndex * pageSize;
  //eslint-disable-next-line
  const indexOfFirstElement = indexOfLastElement - pageSize;
  // const currentElements = list.slice(indexOfFirstElement, indexOfLastElement);
  const [toggleSearch, setToggleSearch] = useState(false);

  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState(false);
  const [requestToDeleteId, setRequestToDeleteId] = useState('');

  const dispatch = useDispatch();

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getList({
      sortBy: sortColumn,
      sortType: sortAscending ? 'asc' : 'desc',
      pageNumber,
      pageSize: perPage,
      text: searchText,
      status: filterStatus
    });
  };
  const setElementsPerPage = (number: number) => {
    setPerPage(number);
    getList({
      sortBy: sortColumn,
      sortType: sortAscending ? 'asc' : 'desc',
      pageNumber: 1,
      pageSize: number,
      text: searchText,
      status: filterStatus
    });
  };

  const deleteRequest = async (requestId: string) => {
    dispatch(toggleLoader(true));
    await deleteRequestConvention(requestId)
      .then((res) => {
        if (res.status == 200) {
          notification.success('Solicitarea a fost ștearsă cu succes!');
          getList({
            sortBy: sortColumn,
            sortType: sortAscending ? 'asc' : 'desc',
            pageNumber: 1,
            pageSize: perPage,
            text: searchText,
            status: filterStatus
          });
        } else {
          notification.error('A apărut o eroare la ștergere!');
        }
      })
      .catch((error) => {
        notification.error('A apărut o eroare la ștergere!');
      });
  };

  return (
    <div className="m-3 rss-list">
      <div className="mb-3">
        <div className="d-flex justify-content-end flex-column flex-sm-row">
          <div className="ms-2 mb-2">
            <ButtonFilters toggled={toggleSearch} onClick={() => setToggleSearch((prev) => !prev)} />
          </div>

          <Button
            className="btn-wide ms-2 mb-2"
            onClick={() => {
              setShowModalImportBulk(true);
            }}
          >
            Solicitare nouă încărcare în masă
          </Button>

          <Button
            className="btn-wide ms-2 mb-2"
            onClick={() => {
              setShowModalNewRequest(true);
            }}
          >
            Solicitare nouă
          </Button>
        </div>
        {toggleSearch && (
          <Row className="d-flex justify-content-between mb-2">
            <Col xs={12} md={6} className="d-flex align-items-center flex-wrap">
              {summary &&
                summary.length > 0 &&
                summary[0].values.map((item: any, index: number) => {
                  return (
                    <span
                      className={filterStatus === item.id ? 'active' : 'not-active'}
                      style={{ margin: '0.1rem' }}
                      key={index}
                      onClick={() => setFilterStatus(item.id)}
                    >
                      <span className="m-2">{item.name}</span>
                      <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                        {item.count}
                      </span>
                    </span>
                  );
                })}
            </Col>

            <Col xs={12} md={6} className="justify-content-end d-flex align-items-center">
              <div className="d-flex flex-row flex-wrap justify-content-center justify-content-sm-end">
                <SearchInput
                  className="ms-1 mt-1"
                  onSearch={() =>
                    getList({
                      sortBy: sortColumn,
                      sortType: sortAscending ? 'asc' : 'desc',
                      pageNumber: 1,
                      pageSize: 10,
                      text: searchText,
                      status: filterStatus
                    })
                  }
                  placeholder="Caută"
                  searchInputValue={searchText}
                  setSearchInputValue={(value) => handleSearchText(value)}
                  width={165}
                />
                <Button
                  className="btn-wide ms-1 mt-1"
                  onClick={() => {
                    // getList({pageNumber: 1, pageSize: 10});
                    handleSortColumn('RequestId');
                    handleSortAscending(false);
                    handleSearchText('');
                    setFilterStatus(null);
                  }}
                >
                  Resetează
                </Button>
              </div>
            </Col>
          </Row>
        )}
        <div className="general-table-container">
          <table className="w-100">
            <thead>
              <tr>
                <th>
                  <div style={{ cursor: 'pointer' }} onClick={() => onSort('ActionTypeId')}>
                    Tip de solicitare{' '}
                    {sortColumn != 'ActionTypeId' ? (
                      <FontAwesomeIcon icon={faSort} size="sm" />
                    ) : sortAscending ? (
                      <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                    )}
                  </div>
                </th>
                <th>
                  <div style={{ cursor: 'pointer' }} onClick={() => onSort('RequestDate')}>
                    Data înregistrării{' '}
                    {sortColumn != 'RequestDate' ? (
                      <FontAwesomeIcon icon={faSort} size="sm" />
                    ) : sortAscending ? (
                      <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                    )}
                  </div>
                </th>
                <th>
                  <div style={{ cursor: 'pointer' }} onClick={() => onSort('RequestNo')}>
                    Număr înregistrare{' '}
                    {sortColumn != 'RequestNo' ? (
                      <FontAwesomeIcon icon={faSort} size="sm" />
                    ) : sortAscending ? (
                      <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                    )}
                  </div>
                </th>
                <th>
                  <div style={{ cursor: 'pointer' }} onClick={() => onSort('AliasName')}>
                    Status{' '}
                    {sortColumn != 'AliasName' ? (
                      <FontAwesomeIcon icon={faSort} size="sm" />
                    ) : sortAscending ? (
                      <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                    )}
                  </div>
                </th>
                <th>
                  <div style={{ cursor: 'pointer' }} onClick={() => onSort('TotalConsumptionPlaces')}>
                    Număr locuri de consum{' '}
                    {sortColumn != 'TotalConsumptionPlaces' ? (
                      <FontAwesomeIcon icon={faSort} size="sm" />
                    ) : sortAscending ? (
                      <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                    )}
                  </div>
                </th>
                <th>Descarcă</th>
                <th>Acțiuni</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 &&
                list.map((item: any, index) => (
                  <tr key={index}>
                    <td className="p-2 border-bottom">
                      <span>{item.actionTypeName || '-'}</span>
                    </td>
                    <td className="p-2 border-bottom">
                      <span>{item.requestDate ? moment(item.requestDate).format('DD.MM.YYYY') : '-'}</span>
                    </td>
                    <td className="p-2 border-bottom">{item.requestNo || '-'}</td>
                    <td className="p-2 border-bottom">{item.aliasName || '-'}</td>
                    <td className="p-2 border-bottom">{item.totalConsumptionPlaces || '-'}</td>
                    {/* <td className="p-2 border-bottom">{item.requestStatusName}</td> */}
                    <td className="p-2 border-bottom">
                      {item.documentUrl && item.actionTypeName != 'Modificare' && (
                        // <Button onClick={() => (window.location.href = item.documentUrl)}>Descarcă convenția</Button>
                        <a className="bold-link" href={`${item.documentUrl}`}>
                          Descarcă convenția
                        </a>
                      )}
                    </td>
                    <td className="p-2 border-bottom">
                      {/* {(item.aliasName == 'Procesata' || item.aliasName == 'In procesare') && (
                        // <Button onClick={() => (window.location.href = `/detalii-conventie?id=${item.requestId}`)}>Detalii</Button>
                        <Button onClick={() => (window.location.href = `/afisare-detalii-conventii?id=${item.requestId}`)}>Detalii</Button>
                      )} */}

                      {(item.aliasName == 'Neinregistrata' || item.aliasName == 'Netrimisa') && (
                        <Button
                          className="mt-1 me-2"
                          onClick={() => {
                            if (item.actionTypeName === 'Vizualizare') {
                              window.location.href = `/solicitare-vizualizare?requestId=${item.requestId}`;
                            } else {
                              window.location.href = `/solicitare-modificare?requestId=${item.requestId}`;
                            }
                          }}
                        >
                          Editează
                        </Button>
                      )}
                      {(item.aliasName == 'Neinregistrata' || item.aliasName == 'Netrimisa') && (
                        <Button
                          className="mt-1 me-2"
                          onClick={() => {
                            setRequestToDeleteId(item.requestId);
                            setShowDeleteRequestModal(true);
                          }}
                        >
                          Sterge
                        </Button>
                      )}
                      {item.aliasName !== 'Neinregistrata' && item.aliasName != 'Netrimisa' && (
                        <Button className="mt-1" onClick={() => (window.location.href = `/afisare-detalii-conventii?id=${item.requestId}`)}>
                          Detalii
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {list.length > 0 && (
        <Pagination
          currentPage={pageIndex}
          totalElements={totalItems}
          perPage={perPage}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      )}
      {showModalNewRequest && (
        <ChoiceModal
          showModal={showModalNewRequest}
          modalTitle={'Alege tipul de solicitare'}
          btnWide
          options={[
            { id: 'VIZUALIZARE', title: 'Vizualizare' },
            { id: 'MODIFICARE', title: 'Modificare' }
          ]}
          onClose={(option: any) => {
            if (option.id == 'VIZUALIZARE') {
              window.location.href = '/solicitare-vizualizare';
            } else if (option.id == 'MODIFICARE') {
              window.location.href = '/solicitare-modificare';
            }
          }}
          onHide={() => {
            setShowModalNewRequest(false);
          }}
        />
      )}

      {showModalImportBulk && (
        <ChoiceModal
          showModal={showModalImportBulk}
          modalTitle={'Solicitare nouă încărcare în masă'}
          btnWide
          options={[
            { id: 'VIZUALIZARE', title: 'Vizualizare' },
            { id: 'MODIFICARE', title: 'Modificare' }
          ]}
          onClose={(option: any) => {
            if (option.id == 'VIZUALIZARE') {
              window.location.href = '/import-conventii-in-masa-vizualizare';
            } else if (option.id == 'MODIFICARE') {
              window.location.href = '/import-conventii-in-masa-modificare';
            }
          }}
          onHide={() => {
            setShowModalImportBulk(false);
          }}
        />
      )}

      <ChoiceModal
        showModal={showDeleteRequestModal}
        modalTitle={'Sunteți sigur că doriți să ștergeți solicitarea?'}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={(option: any) => {
          if (option.id == 'DA') {
            deleteRequest(requestToDeleteId);
            setShowDeleteRequestModal(false);
          } else if (option.id == 'NU') {
            setShowDeleteRequestModal(false);
          }
        }}
        onHide={() => {
          setShowDeleteRequestModal(false);
        }}
        btnWide
      />
    </div>
  );
};

export default RequestGrid;
