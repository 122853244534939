import { convertToSlug } from '@lib/Slugify';
import { useEffect, useMemo, useState } from 'react';

const TabsRacordareContent = (props: any) => {
  const { kentico } = props;
  const [currentTab, setCurrentTab] = useState<number>(0);
  const randTabId = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);
  const tabSectionId = String(Math.floor(Math.random() * 1000));

  const openTabSection = (evt: any, tabNmae: any, index?: number, tabSection?: string) => {
    let i, tabcontent, tablinks;

    tabcontent = document.getElementsByClassName(tabSectionId);
    for (i = 0; i < tabcontent.length; i++) {
      let element: Element = tabcontent[i];

      element.setAttribute('style', 'display:none');
    }

    tablinks = document.querySelectorAll('.tabs-racordare li');

    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' current', '');
    }

    document.getElementById(tabNmae)?.setAttribute('style', 'display:block');
    evt.currentTarget.className += ' current';
    index !== undefined && setCurrentTab(index as number);
  };

  let prefix = convertToSlug(kentico.items[0].tabTitle);

  let type = kentico.itemsDisplay ?? 'text';

  useEffect(() => {
    let tabcontent = document.getElementsByClassName(tabSectionId);
    for (let i = 1; i < tabcontent.length; i++) {
      let element: Element = tabcontent[i];

      element.setAttribute('style', 'display:none');
    }
  }, [])

  return (
    <section className="tab-section mt-5">
      <div className="container">
        {kentico.title && (
          <div className="section-title align-items-center">
            {/* {kentico.title && <h3 className="text-center">{kentico.title}</h3>} */}
          </div>
        )}
        <div className="tab boosting-list-tab-racordare d-flex">
          <div className="tabs-racordare-nav">
            <div className={`tabs-racordare ${type === 'text' ? 'text-tab flex-column' : 'number-tab'}`}>
              {kentico.items.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`rt-nav-item ${currentTab === index ? 'current' : ''}`}
                  onClick={(e) => openTabSection(e, `tab-${randTabId}-${prefix}${index}`, index, prefix)}
                >
                  <span className="tab-index">{index + 1}</span>
                  <span className={`rt-tab-title  ${currentTab === index ? 'current' : ''}`}>{item.tabTitle}</span>
                </div>
              ))}
            </div>

            <div>
              <div className="tab-image">
                <img className="responsive img-fluid" src={kentico.items[currentTab].image} alt={kentico.items[currentTab].title} />
              </div>
            </div>
          </div>

          <div className="tab-content-racordare">
            {kentico.items.map((item: any, index: number) => (
              <div id={`tab-${randTabId}-${prefix}${index}`} className={tabSectionId} key={index}>
                <div className="row">
                  <div>
                    <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                      {item.menu && (
                        <div className="tab-navigation mt-5 d-flex justify-content-start">
                          {item.menu.map((menu: any, index: number) => (
                            <div key={index} className="me-3">
                              <a href={menu.link}>{menu.title}</a>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TabsRacordareContent;