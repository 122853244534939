import React, {Fragment} from 'react';
import { jwtDecode } from 'jwt-decode';
import {IState} from '@type/store';
import {useSelector} from 'react-redux';
import AddConsumptionPoint from '@components/account/MyAccount/AddConsumptionPoint/AddConsumptionPoint';
import Partners from '@components/account/MyAccount/Partners/Partners';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const AddCP = () => {
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);
    
    return (
        <Fragment>
            { (jwt.groups.includes('ClientFizic') || jwt.groups.includes('ClientJuridic')) ? <Partners /> : <AddConsumptionPoint /> }
        </Fragment>
    );
};

export default AddCP;