import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_CATEGORIES_AND_SUBJECTS } from '@constants/ActionTypes/PostTypes';
import { getCategoriesAndSubjects } from '@api/rss';
import { fetchSuccess } from '@actions/GeneralAction';
import { Action } from '@reducers';
import { CategorySubjectTemplate } from '@type/rss';
import { CategoryType, RssPayload, Subject, SubSubject } from '@reducers/rss';

function* fetchCategoriesAndSubjects(action: Action): Generator<any> {
  try {
    const response = yield call(getCategoriesAndSubjects);

    let rawCategoriesAndSubjects = response as CategorySubjectTemplate[];

    let categories: CategoryType[] = [];
    let subjects: Subject[] = [];
    let subSubjects: SubSubject[] = [];

    rawCategoriesAndSubjects.forEach((category: CategorySubjectTemplate) => {
      categories.push({
        name: category.category,
        code: category.categorySapValue,
      } as CategoryType);

      subjects.push({
        name: category.subject,
        code: category.subjectSapValue,
      } as Subject);

      subSubjects.push({
        name: category.subSubject,
        code: category.subSubjectSapValue,
      } as SubSubject);
    });

    yield put(fetchSuccess<RssPayload>(
      action.type,
      {
        categories, subjects, subSubjects
      }
    ));
  } catch (e) {
    console.error(e);
  }
}

function* rssSaga() {
  yield all([
    takeLatest(FETCH_CATEGORIES_AND_SUBJECTS, fetchCategoriesAndSubjects),
  ]);
}

export default rssSaga;