import { toggleLoader } from '@actions/LoaderActions';
import { getTypes, postStepFour } from '@api/prosumatori/prosumatori';
import { Input } from '@components/common/Input';
import DateTime from '@components/delgaz/BackOffice/Fields/DateTime';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import TabelTipRacord from '@components/prosumatori/components/TabelTipRacord';
import DynamicTable from '@components/prosumatori/components/table/DynamicTable';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const InformatiiTehniceLC = (props: {
    requestType: any,
    handleRequestType: any,
    isEditing: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    tipLC: any,
    adresaObiectiv: any,
    modAlimentare: any,
    handleModAlimentare: any,
    dataSolicitata: any,
    handleDataSolicitata: any,
    dateTehnice: any,
    handleDateTehnice: any,
    tipTarif: any,
    handleTipTarif: any,
    tensiune: any,
    handleTensiune: any,
    tipTensiune: any,
    handleTipTensiune: any,
    factorPutere: any,
    handleFactorPutere: any,
    tipFactorPutere: any,
    handleTipFactorPutere: any,
    regimLucru: any,
    handleRegimLucru: any,
    specificulActivitatii: any,
    handleSpecificulActivitatii: any,
    otherSA: any,
    handleOtherSA: any,
    surseDeAlimentare: any,
    handleSurseDeAlimentare: any,
    timpIntrerupere: any,
    handleTimpIntrerupere: any,
    listaReceptoare: any,
    SI: any,
    handleSI: any,
    statiiIncarcare: any,
    totalPI: any,
    handleTotalPIUser: any,
    handleDateLR: any,
    handleDateSI: any,
    dateLR: any,
    dateSI: any,
}) => {
    const {
        requestType,
        handleRequestType,
        isEditing,
        requestId,
        setStep,
        currentStep,
        progress,
        setProgress,
        tipLC,
        // adresaObiectiv,
        dataSolicitata,
        handleDataSolicitata,
        modAlimentare,
        handleModAlimentare,
        dateTehnice,
        handleDateTehnice,
        tipTarif,
        handleTipTarif,
        tensiune,
        handleTensiune,
        tipTensiune,
        handleTipTensiune,
        factorPutere,
        handleFactorPutere,
        tipFactorPutere,
        handleTipFactorPutere,
        regimLucru,
        handleRegimLucru,
        specificulActivitatii,
        handleSpecificulActivitatii,
        otherSA,
        handleOtherSA,
        surseDeAlimentare,
        handleSurseDeAlimentare,
        timpIntrerupere,
        handleTimpIntrerupere,
        listaReceptoare,
        SI,
        handleSI,
        statiiIncarcare,
        totalPI,
        handleTotalPIUser,
        handleDateLR,
        handleDateSI,
        dateLR,
        dateSI,
    } = props;

    const [TTOptions, setTTOptions] = useState([]);
    //eslint-disable-next-line
    const [SAOptions, setSAOptions] = useState([]);
    //eslint-disable-next-line
    const [accesStatieOptions, setAccesStatieOptions] = useState([]);
    //eslint-disable-next-line
    const [SFOptions, setSFOptions] = useState([]);
    // const [DTError, setDTError] = useState('');
    const [powerError, setPowerError] = useState('');
    const [LRError, setLRError] = useState('');
    const [SIError, setSIError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [numbersError, setNumbersError] = useState('');
    const [workRegimeError, setWorkRegimeError] = useState('');
    const [sourcesError, setSourcesError] = useState('');
    // const [gotATR, setGotATR] = useState(false);

    // const token: string | undefined = useSelector<IState>((state) => state.auth.token) as string;

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('initialTableData: ', dateSI);
        window.scrollTo(0,0);
        getOptionTypes(['TariffType']).then((res) => setTTOptions(res));
        specificulActivitatii.length === 0 && getOptionTypes(['ActivityType']).then((res) => {
            let data = res;
            data.map((el: any, i: number) => {
                el.active = false;
            });

            handleSpecificulActivitatii(data);
            setSAOptions(data);
        });
        getOptionTypes(['StationType']).then((res) => setAccesStatieOptions(res));
        getOptionTypes(['SourceFoundingType']).then((res) => setSFOptions(res));
        handleRequestType();
    }, []);

    const getOptionTypes = async (keys: string[]) => {
        return await getTypes(keys).then((res: any) => {
            return res[keys[0]];
        });
    };

    const constructSpecAct = () => {
        let list: any[] = [];
        specificulActivitatii.map((el: any, i: number) => {
            if (el.active === true) {
                list.push(el);
            }
        });

        list.map((e: any, i: number) => {
            if (e.name === 'Altele') {
                list[i] = {
                    id: 6,
                    name: otherSA,
                    code: '',
                    active: true
                };
            }
        });

        let newList: any[] = [];
        list.map((e: any, i: number) => {
            let el = {
                requestId: e.id,
                activityTypeId: e.id,
                activityType: e.name
            };

            newList.push(el);
        });

        return newList;
    };

    const setOptionsForChargingStations = (data: any) => {
        let newData = JSON.parse(JSON.stringify(data));

        newData.map((el: any, i: number) => {
            Object.keys(el).map((key: any, idx: number) => {
                // if (idx === 4) {
                //     if (el[key] === 'AC') {
                //         el[key] = 1;
                //     } else if (el[key] === 'DC') {
                //         el[key] = 2;
                //     }
                // }
                if (idx === 5) {
                    if (el[key] === 'public') {
                        el[key] = 2;
                    } else if (el[key] === 'privat') {
                        el[key] = 1;
                    }
                }
                if (idx === 6) {
                    if (el[key] === 'FORTE PROPRII') {
                        el[key] = 1;
                    } else if (el[key] === 'AFM2021-UAT SI INSTIT.PUBLICE') {
                        el[key] = 2;
                    } else if (el[key] === 'ELECTRIC UP') {
                        el[key] = 3;
                    }
                }
                if (idx === 4) {
                    if (el[key] === 'lenta') {
                        el[key] = 1;
                    } else if (el[key] === 'rapida') {
                        el[key] = 2;
                    }
                }
            });
        });

        return newData;
    };

    const constructPayload = () => {
        return {
            requestId: requestId,
            data_solicitata: tipLC === 1 ? dataSolicitata : null,
            mod_alimentare: modAlimentare,
            date_tehnice_old: {
                'TipRacord': dateTehnice.seTipRacord,
                'KW': Number(dateTehnice.seKW.replace(',', '.')),
                'KVA': Number(dateTehnice.seKVA.replace(',', '.'))
            },
            date_tehnice_new: {
                'TipRacord': tipLC === 2 ? dateTehnice.ssTipRacord : dateTehnice.seTipRacord,
                'KW': tipLC === 2 ? Number(dateTehnice.ssKW.replace(',', '.')) : Number(dateTehnice.seKW.replace(',', '.')),
                'KVA': tipLC === 2 ? Number(dateTehnice.ssKVA.replace(',', '.')) : Number(dateTehnice.seKVA.replace(',', '.'))
            },
            tip_tarif: tipTarif,
            tensiune: Number(tensiune.replace(',', '.')),
            factor_putere: Number(factorPutere.replace(',', '.')),
            specificul_activitatii: constructSpecAct(),
            regim_lucru: {
                nr_schimburi: regimLucru.nrSchimburi !== '' ? regimLucru.nrSchimburi : null,
                interval_orar: regimLucru.interval !== '' ? regimLucru.interval : null,
                nr_zile: regimLucru.nrZile !== '' ? regimLucru.nrZile : null,
            },
            surse_alimentare: {
                tip: surseDeAlimentare.tip,
                putere: surseDeAlimentare.putere,
                timp: surseDeAlimentare.timpPornire,
                durata: surseDeAlimentare.durataFunc,
            },
            timp_max_intrerupere: timpIntrerupere,
            lista_receptoare: dateLR,
            statie_masina_electrica: SI === 1,
            ...(SI === 1 ? {statii_incarcare: setOptionsForChargingStations(dateSI)}: {statii_incarcare: []}),
            pi_total: totalPI
        };
    };

    const constructN1N2Payload = () => {
        return {
            requestId: requestId,
            data_solicitata: tipLC === 1 ? dataSolicitata : null,
            mod_alimentare: 'N1/N2',
            date_tehnice_old: {
                'TipRacord': 1,
                'KW': 1,
                'KVA': 1.11
            },
            date_tehnice_new: {
                'TipRacord': 1,
                'KW': 1,
                'KVA': 1.11
            },
            tip_tarif: 1,
            tensiune: 0.23,
            factor_putere: 0.9,
            specificul_activitatii: constructSpecAct(),
            regim_lucru: {
                nr_schimburi: 0,
                interval_orar: null,
                nr_zile: 0,
            },
            surse_alimentare: {
                tip: 'N1/N2',
                putere: '0',
                timp: '0',
                durata: '0',
            },
            timp_max_intrerupere: '0',
            lista_receptoare: [{Denumire: 'N1/N2', Putere_instalata: '0', Observatii: ''}],
            statie_masina_electrica: false,
            statii_incarcare: [],
            pi_total: 2
        };
    };

    const stepFourApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepFour(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                } else if (error === 'Request is completed') {
                    setValidationError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setValidationError('');
            setLRError('');
            setSIError('');
            setPowerError('');
            setNumbersError('');
            setWorkRegimeError('');
            setSourcesError('');
            let sa = document.getElementById('SA');
            sa?.classList.remove('red');
            let lr = document.getElementById('LR');
            lr?.classList.remove('red');
            let si = document.getElementById('SI');
            si?.classList.remove('red');
            let de = document.getElementById('DE');
            de?.classList.remove('red');
            let dt = document.getElementById('DT');
            dt?.classList.remove('red');
            let dtTable = document.getElementById('DATE');
            dtTable?.classList.remove('err-border');
            let tu = document.getElementById('TU');
            tu?.classList.remove('red');
            let fp = document.getElementById('FP');
            fp?.classList.remove('red');
            let tiptarif = document.getElementById('tiptarif');
            tiptarif?.classList.remove('red');
            let wasValidated = true;

            if (constructSpecAct().length === 0) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('SA');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (!validateTable(dateLR, 2)) {
                setLRError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('LR');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if (!validateNumbersInTable(dateLR, 1)) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('LR');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (SI === 1 && !validateTable(dateSI, 7)) {
                setSIError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('SI');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if (SI === 1 && (!validateNumbersInTable(dateSI, 1) || !validateNumbersInTable(dateSI, 2) || !validateNumbersInTable(dateSI, 3))) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('SI');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (tipLC === 1) {
                if (dateTehnice.seKW === '' || dateTehnice.seKVA === '') {
                    let err = document.getElementById('DT');
                    err?.classList.add('red');

                    let table = document.getElementById('DATE');
                    table?.classList.add('err-border');

                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                    wasValidated = false;
                }

                if (dateTehnice.seTipRacord === 0) {
                    let err = document.getElementById('DT');
                    err?.classList.add('red');
                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                    wasValidated = false;
                }

                if (dateTehnice.seKW > totalPI || dateTehnice.seKVA > totalPI) {
                    setPowerError('Puterea maxim simultan absorbită trebuie să fie mai mică sau egală cu Puterea instalată.');
                    wasValidated = false;
                }
                if (dataSolicitata === '' || dataSolicitata === null) {
                    let err = document.getElementById('DE');
                    err?.classList.add('red');
                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                    wasValidated = false;
                }
                if (isNaN(Number(dateTehnice.seKW.replace(',', '.'))) || isNaN(Number(dateTehnice.seKVA.replace(',', '.')))) {
                    setNumbersError('Te rugăm să revizuiești informațiile completate.');
                    let err = document.getElementById('DT');
                    err?.classList.add('red');
                    wasValidated = false;
                }
            }
            if (tipLC === 2) {
                if (dateTehnice.seKW === '' || dateTehnice.seKVA === '' || dateTehnice.ssKW === '' || dateTehnice.ssKVA === '') {
                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                    let err = document.getElementById('DT');
                    err?.classList.add('red');
                    wasValidated = false;
                }

                if (dateTehnice.seTipRacord === 0 || dateTehnice.ssTipRacord === 0) {
                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                    let err = document.getElementById('DT');
                    err?.classList.add('red');
                    wasValidated = false;
                }

                if (dateTehnice.seKW > totalPI || dateTehnice.seKVA > totalPI || dateTehnice.ssKW > totalPI || dateTehnice.ssKVA > totalPI) {
                    setPowerError('Puterea maxim simultan absorbită trebuie să fie mai mică sau egală cu Puterea instalată.');
                    wasValidated = false;
                }
                if (modAlimentare === '') {
                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                    let err = document.getElementById('DT');
                    err?.classList.add('red');
                    wasValidated = false;
                }
                if (isNaN(Number(dateTehnice.seKW.replace(',', '.'))) || isNaN(Number(dateTehnice.seKVA.replace(',', '.'))) ||
                    isNaN(Number(dateTehnice.ssKW.replace(',', '.'))) || isNaN(Number(dateTehnice.ssKVA.replace(',', '.')))) {
                    setNumbersError('Te rugăm să revizuiești informațiile completate.');
                    let err = document.getElementById('DT');
                    err?.classList.add('red');
                    wasValidated = false;
                }
            }

            if (factorPutere === 0 || factorPutere === '0') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('FP');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (SI === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('SI');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (tipTarif === null) {
                let err = document.getElementById('tiptarif');
                err?.classList.add('red');
            }

            if (tipTensiune === null || tipTensiune === '') {
                let err = document.getElementById('TU');
                err?.classList.add('red');
            }

            if ((tensiune.includes(',') && isNaN(Number(tensiune.replace(',', '.')))) || (factorPutere.includes(',') && isNaN(Number(factorPutere.replace(',', '.'))))) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('TU');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (wasValidated) {
                if (tipFactorPutere === null || specificulActivitatii === null) {
                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                } else {
                    stepFourApiCall(constructPayload());
                }
            }
        } else {
            setStep(currentStep+1);
            currentStep === progress && setProgress(currentStep+1);
        }
    };

    const handleNextStepN1N2 = () => {
        if (isEditing !== false) {
            stepFourApiCall(constructN1N2Payload());
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    const validateTable = (table: any, indexException: number | null) => {
        let validated = true;

        table.map((row: any, index: number) => {
            Object.keys(row).map((column: any, idx: number) => {
                if (idx !== indexException) {
                    if (row[column] === '') {
                        validated = false;
                    }
                }
            });
        });

        return validated;
    };

    const validateNumbersInTable = (table: any, indexToLookAt: number) => {
        console.log(table);
        let validated = true;

        table.map((row: any, index: number) => {
            Object.keys(row).map((column: any, idx: number) => {
                if (idx === indexToLookAt) {
                    if (row[column] === null) {
                        validated = false;
                    } else if ((isNaN(row[column])) || (typeof row[column] !== 'number' && row[column].includes(',') && isNaN(Number(row[column].replace(',','.'))))) {
                        validated = false;
                    }
                }
            });
        });

        return validated;
    };

    const handlePrevStep = () => {
        setStep(currentStep-1);
    };

    const setOptionActive = (option: any) => {
        let op = specificulActivitatii;

        op.map((el: any, i: number) => {
            if (el === option) {
                el.active = !el.active;
            }
        });

        handleSpecificulActivitatii(op);
    };

    const renderSpecificulActivitatii = () => {
        return (
            <div className="mt-3 mb-3">
                <h5 className="m-0" id="SA">Specificul activității:*</h5>
                <div className={'d-flex m-3 choices checkbox-container'}>
                    {specificulActivitatii.map((option: any, idx: number) => (
                        <span
                            key={idx}
                            style={{marginRight: '25px', marginBottom: '8px'}}
                            onClick={() => {
                                if (isEditing !== false) {
                                    setOptionActive(option);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={() => setOptionActive(option)}
                                onClick={() => {
                                    if (isEditing !== false) {
                                        setOptionActive(option);
                                    }
                                }}
                                value={option}
                                checked={option.active}
                                style={{marginRight: '4px'}}
                                className="create-account-checkbox"
                                disabled={isEditing === false}
                                // disabled={disabled}
                            />
                            <span style={{fontSize: '18px'}}>{option.title || option.name}</span>
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    const renderBasedOnRequestType = () => {
        if (requestType === 'N1' || requestType === 'N2') {
            return renderN1N2();
        } else return renderNotN1N2();
    };

    const renderN1N2 = () => {
        return <Fragment>
            <br></br>
            <h5>Pentru acest tip de cerere nu este necesară completarea de informații suplimenatare despre locul de consum. Te rugăm să continui cu informațiile privind locul de producere.</h5>
            <div className="d-flex flex-row justify-content-between">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStepN1N2}>Pasul următor</Button>
            </div>
            <br></br>
        </Fragment>;
    };

    const renderNotN1N2 = () => {
        return <Fragment>
            { tipLC === 1 && <Row className="mt-4 mb-4">
                <Col sm={12} md={4}>
                    <h5 id="DE">Data estimată solicitată pentru punerea sub tensiune finală a instalației de utilizare:*</h5>
                </Col>
                <Col sm={12} md={3}>
                    <DateTime
                        name={'data'}
                        label={'Data'}
                        itemTypeId={0}
                        value={dataSolicitata}
                        onChange={handleDataSolicitata}
                        disabled={isEditing === false}
                    />
                </Col>
            </Row> }
            { tipLC === 2 && <>
                <Row className="mt-4 mb-4">
                    <h5>Descrie modul actual de alimentare a obiectivului și modificările de natură tehnică propuse:*</h5>
                    <Col xs={12} sm={4} className="mt-2">
                        <Input
                            value={modAlimentare}
                            label="Mod alimentare"
                            onChange={(e) => handleModAlimentare(e.target.value)}
                            disabled={isEditing === false}
                        />
                    </Col>
                </Row>
            </> }

            <ChoiceComponent
                title={'Factorul de putere:*'}
                options={[{title: '0,9', id: '0,9'}, {title: 'Altă valoare', id: ''}]}
                onChange={handleTipFactorPutere}
                value={tipFactorPutere}
                disabled={isEditing === false}
                id="FP"
            />

            { tipFactorPutere === '' &&
            <Col xs={12} sm={4}>
                <Input
                    value={factorPutere}
                    label="Factorul de putere"
                    onChange={(e) => {
                        const re = /^[,.0-9\b]+$/;

                        if (e.target.value === '' || re.test(e.target.value)) {
                            handleFactorPutere(e.target.value);
                        }
                    }}
                    disabled={isEditing === false}
                />
            </Col>
            }

            <h5 id="DT">Date tehnice:*</h5>
            {tipLC === 1 && <TabelTipRacord isEditing={isEditing} tableData={dateTehnice} type={1} handleData={handleDateTehnice} tipPutere={1} id="DATE"/>}
            {tipLC === 2 && <TabelTipRacord isEditing={isEditing} tableData={dateTehnice} type={2} handleData={handleDateTehnice} tipPutere={1} id="DATE"/>}

            { TTOptions.length > 0 && <ChoiceComponent
                title={'Tip de tarif solicitat:*'}
                options={TTOptions}
                onChange={handleTipTarif}
                value={tipTarif}
                disabled={isEditing === false}
                id='tiptarif'
            /> }

            <ChoiceComponent
                title={'Tensiune de utilizare (kV):*'}
                options={[
                    {title: '0,23', id: '0.23'},
                    {title: '0,4', id: '0.4'},
                    {title: '6', id: '6'},
                    {title: '20', id: '20'},
                    {title: '110', id: '110'},
                    {title: 'Altă valoare', id: ''}
                ]}
                onChange={handleTipTensiune}
                value={tipTensiune}
                disabled={isEditing === false}
                id="TU"
            />
            { tipTensiune === '' &&
            <Col xs={12} sm={4}>
                <Input
                    value={tensiune}
                    label="Tensiune"
                    onChange={(e) => {
                        const re = /^[,.0-9\b]+$/;

                        if (e.target.value === '' || re.test(e.target.value)) {
                            handleTensiune(e.target.value);
                        }
                    }}
                    disabled={isEditing === false}
                />
            </Col>
            }

            { specificulActivitatii.length > 0 && renderSpecificulActivitatii() }
            { specificulActivitatii.length > 0 && specificulActivitatii[5].active === true &&
            <Col xs={12} sm={4}>
                <Input
                    label="Specificul activității"
                    value={otherSA}
                    onChange={(e) => handleOtherSA(e.target.value)}
                    disabled={isEditing === false}
                />
            </Col>
            }

            <h5>Regimul de lucru (dacă e cazul)</h5>
            <Row className="mt-2 mb-5">
                <Col xs={12} sm={4}>
                    <Input
                        label="Nr. de schimburi"
                        value={regimLucru.nrSchimburi}
                        onChange={(e) => {
                            const re = /^[0-9\b]+$/;

                            if (e.target.value === '' || re.test(e.target.value)) {
                                handleRegimLucru(e.target.value, 'nrSchimburi');
                            }
                        }}
                        disabled={isEditing === false}
                    />
                </Col>
                <Col xs={12} sm={4}>
                    <Input
                        label="Interval orar"
                        value={regimLucru.interval}
                        onChange={(e) => handleRegimLucru(e.target.value, 'interval')}
                        disabled={isEditing === false}
                    />
                </Col>
                <Col xs={12} sm={4}>
                    <Input
                        label="Nr. zile lucrătoare pe saptămână"
                        value={regimLucru.nrZile}
                        onChange={(e) => {
                            const re = /^[0-9\b]+$/;

                            if (e.target.value === '' || re.test(e.target.value)) {
                                handleRegimLucru(e.target.value, 'nrZile');
                            }
                        }}
                        disabled={isEditing === false}
                    />
                </Col>
                {workRegimeError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{workRegimeError}</p>}
            </Row>

            <h5>Surse de alimentare (altele decât surse regenerabile)</h5>
            <Row className="mt-2 mb-5">
                <Col xs={12} sm={3}>
                    <Input
                        label="Tip"
                        value={surseDeAlimentare.tip}
                        onChange={(e) => handleSurseDeAlimentare(e.target.value, 'tip')}
                        disabled={isEditing === false}
                    />
                </Col>
                <Col xs={12} sm={3}>
                    <Input
                        label="Putere (kW)"
                        value={surseDeAlimentare.putere}
                        onChange={(e) => {
                            const re = /^[,.0-9\b]+$/;

                            if (e.target.value === '' || re.test(e.target.value)) {
                                handleSurseDeAlimentare(e.target.value, 'putere');
                            }
                        }}
                        disabled={isEditing === false}
                    />
                </Col>
                <Col xs={12} sm={3}>
                    <Input
                        label="Timp de pornire (secunde)"
                        value={surseDeAlimentare.timpPornire}
                        onChange={(e) => handleSurseDeAlimentare(e.target.value, 'timpPornire')}
                        disabled={isEditing === false}
                    />
                </Col>
                <Col xs={12} sm={3}>
                    <Input
                        label="Durată max de funcționare (ore)"
                        value={surseDeAlimentare.durataFunc}
                        onChange={(e) => handleSurseDeAlimentare(e.target.value, 'durataFunc')}
                        disabled={isEditing === false}
                    />
                </Col>
                {sourcesError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{sourcesError}</p>}
            </Row>

            <h5>Timpul maxim de întrerupere acceptat de procesul tehnologic</h5>
            <h6>Se va completa în cazul în care se solicită un timp maxim de întrerupere mai mic decât cel prevăzut de standardul de performanță pentru serviciul de distribuție a energiei electrice.</h6>
            <Row className="mt-2 mb-5">
                <Col xs={12} sm={4}>
                    <Input
                        label="Timpul maxim de întrerupere (ore)"
                        value={timpIntrerupere}
                        onChange={(e) => {
                            const re = /^[0-9\b]+$/;

                            if (e.target.value === '' || re.test(e.target.value)) {
                                handleTimpIntrerupere(e.target.value);
                            }
                        }}
                        disabled={isEditing === false}
                    />
                </Col>
            </Row>

            <h5 id="LR">Lista receptoarelor cu precizarea puterii și a tensiunii nominale:*</h5>
            <h6>(la observații se va indica regimul generat de acestea: cu șocuri, deformat, cu sarcini dezechilibrate etc.)</h6>
            <DynamicTable format={listaReceptoare} data={dateLR} handler={handleDateLR} isEditing={isEditing}/>
            {LRError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{LRError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'În instalația de utilizare vor fi montate stații de încărcare a mașinilor electrice?*'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleSI}
                value={SI}
                disabled={isEditing === false}
                id="SI"
            />

            {SI === 1 && <DynamicTable format={statiiIncarcare} data={dateSI} handler={handleDateSI} isEditing={isEditing}/>}
            {SIError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{SIError}</p>}
            {SI === 1 && <div className="mt-2 mb-2">
                <br></br>
            </div>}

            <Row className="mt-4 mb-4 align-items-center">
                <Col sm={12} md={4}>
                    <h5>Putere total instalată (kW)</h5>
                </Col>
                <Col sm={12} md={2}>
                    <input
                        value={totalPI}
                        onChange={(e) => handleTotalPIUser(e.target.value)}
                        disabled={isEditing === false}
                        className="total-pi-input"
                    />
                </Col>
            </Row>

            {validationError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{validationError}</p>}
            {powerError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{powerError}</p>}
            {numbersError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{numbersError}</p>}
            <div className="d-flex flex-row justify-content-between">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </Fragment>;
    };

    return (
        <div className="prosumatori-informatii-tehnice-lc m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3">Date tehnice și energetice pentru locul de consum</h4>
            { renderBasedOnRequestType() }
        </div>
    );
};

export default InformatiiTehniceLC;