import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import {ErrorMessage, Field} from 'formik';
import {Input} from '@components/common/Input';

const DatePF = (props: any) => {
    let formikProps = props.props;
    let handleData = props.handleData;

    return (
        <Fragment>
            { props && <div>
                <Row>
                    <Col xs={12} sm={4} className="mb-3">
                        <Field label='nume' id="nume" name="nume" placeholder="Nume*">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.nume}
                                    label='Nume*'
                                    onBlur={() => formikProps.setFieldTouched('nume')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'nume');
                                        formikProps.setFieldValue('nume', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="nume" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                        <Field label='prenume' id="prenume" name="prenume" placeholder="Prenume*">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.prenume}
                                    label='Prenume*'
                                    onBlur={() => formikProps.setFieldTouched('prenume')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'prenume');
                                        formikProps.setFieldValue('prenume', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="prenume" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                        <Field label='cnp' id="cnp" name="cnp" placeholder="CNP (Cod Numeric Personal)">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.cnp}
                                    label='CNP (Cod Numeric Personal)'
                                    onBlur={() => formikProps.setFieldTouched('cnp')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'cnp');
                                        formikProps.setFieldValue('cnp', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="cnp" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} className="mb-3">
                        <Field label='telefon' id="telefon" name="telefon" placeholder="Telefon">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.telefon}
                                    label='Telefon'
                                    onBlur={() => formikProps.setFieldTouched('telefon')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'telefon');
                                        formikProps.setFieldValue('telefon', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="telefon" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                    <Col className="mb-3">
                        <Field label='email' id="email" name="email" placeholder="E-mail*">
                            {({
                                  //@ts-ignore
                                  meta: {touched, error}
                              }) =>
                                <Input
                                    mbZero
                                    value={formikProps.values.email}
                                    label='E-mail*'
                                    onBlur={() => formikProps.setFieldTouched('email')}
                                    onChange={(e: any) => {
                                        handleData(e.target.value, 'email');
                                        formikProps.setFieldValue('email', e.target.value);
                                    }}
                                />
                            }
                        </Field>
                        <ErrorMessage name="email" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                    </Col>
                </Row>
            </div>}
        </Fragment>
    );
};

export default DatePF;