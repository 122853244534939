import { GoogleMapsComponent } from '@components/dev/Components/GoogleMaps/GoogleMapsComponent';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleMapsKentico } from '@type/kentico-types';
import { useState } from 'react';

type GoogleMapsProps = {
  kentico: GoogleMapsKentico;
};

export const GoogleMaps = (props: GoogleMapsProps): JSX.Element => {
  const [show, setShow] = useState(false);

  const renderText = () => {
    return (
      <>
        <div
          onClick={() => setShow(true)}
          style={{
            background: '#f6f6f7',
            maxWidth: '1200px',
            width: '90%',
            display: 'flex',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            cursor: 'pointer'
          }}
        >
          <h4 style={{ margin: '0', padding: '10px', fontWeight: 'bold' }}>Click aici pentru a vizualiza harta locațiilor Delgaz Grid</h4>
          <FontAwesomeIcon icon={faMapMarkerAlt} size={'2x'} color="#ea1b0a" />
        </div>
      </>
    );
  };

  const renderMap = () => {
    return (
      <GoogleMapsComponent
        googleMapURL={
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAXE_ITlrBwfEB9xZR8BBTFryzQVvSAeko&v=3.exp&libraries=geometry,drawing,places'
        }
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={
          <div
            className="p-2"
            style={{
              height: '500px',
              maxWidth: '1200px',
              width: '100%',
              position: 'relative'
            }}
          />
        }
        mapElement={<div style={{ height: '100%' }} />}
        {...props}
      />
    );
  };

  return <DelgazWrapper>{!show ? renderText() : renderMap()}</DelgazWrapper>;
};

// const mapStateToProps = (state: IState) => ({ state: state});
//
// const GoogleMaps = connect(mapStateToProps, null)(GoogleMapsComp);
//
// export {GoogleMaps, GoogleMapsComp}
