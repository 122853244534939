import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ImportBulk from '@components/conventions/importBulk/ImportBulk';

export const CImportBulkModifyComponent = () => {
  return (
    <DelgazWrapper>
      <ImportBulk actionTypeId="2" />
    </DelgazWrapper>
  );
};
