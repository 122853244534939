import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faEnvelope, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { AdCardShareButtonKentico } from '@type/kentico-types';

const iconProp = faWhatsapp as IconProp;

const AdCardShareButtonComponent = (props: { kentico: AdCardShareButtonKentico }) => {
    return (
        <div className="ad-card-share-button">
            <Tippy
                content={
                    <div className="share-container">
                        <div>
                            <FontAwesomeIcon
                                className="share-icon"
                                icon={iconProp}
                                onClick={() => window.location.href = `https://api.whatsapp.com/send?text=${props.kentico.url}`}
                            />
                        </div>
                        <div>
                            <FontAwesomeIcon
                                className="share-icon"
                                icon={faEnvelope}
                                onClick={() => window.location.href = `mailto:?subject=Delgaz Imobiliare&body=${props.kentico.url}`}
                            />
                        </div>
                        <div>
                            <FontAwesomeIcon
                                className="share-icon"
                                icon={faCopy}
                                onClick={() => navigator.clipboard.writeText(props.kentico.url)}
                            />
                        </div>
                    </div>
                }
                duration={0}
                placement="top"
                trigger="click"
                interactive={true}
            >
                <div>
                    <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
                </div>
            </Tippy>
        </div>
    );
};

export default AdCardShareButtonComponent;