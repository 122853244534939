import { toggleLoader } from '@actions/LoaderActions';
import { getSubjectListFurnizor, verifyCPoint } from '@api/rss/rss';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import ConfirmModal from '@components/rss/inregistrareRSS/ConfirmModal';
import RSSModal from '@components/rss/inregistrareRSS/RSSModal';
import Verify from '@components/rss/inregistrareRSS/Verify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { convertNullsAndUndefinedToEmptyString } from '../../../helpers/object';

// let modalDataTest = {
//     'pod': 'EMO1809973',
//     'consumptionPointCode': '5001622028',
//     'partner': ' OMEGA NET S.R.L.',
//     'supplierCode': '1003400718',
//     'supplierName': 'F_ENEL',
//     'address': 'Botosani,Miron Costin,Miron Costin,200',
//     'street': 'Miron Costin',
//     'streetNo': null,
//     'postalCode': null,
//     'clientCnp': '1990620374518',
//     'clientCui': null,
//     'contactPhone': null,
//     'contactEmail': null,
//     'county': 'Botosani',
//     'countyCode': 'BT',
//     'locality': 'Miron Costin',
//     'localityCode': 717466,
//     'inPortofolio': true,
//     'inactive': false
// };

const InregistrareRSS = () => {
    const [error, setError] = useState('');
    const [showRSSModal, setShowRSSModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalType, setModalType] = useState<number | null>(null); // 1 = in portofolio; 2 = not in portofolio
    const [pod, setPod] = useState('');
    const [county, setCounty] = useState('');
    const [locality, setLocality] = useState<number>(0);
    const [categories, setCategories] = useState<any[]>([]);
    const [modalData, setModalData] = useState<any>(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if(modalData) {
            mountValues();
        }
    }, [modalData]);

    useEffect(() => {
        const url = new URL(window.location.href);
        // const url = new URL('https://dev.delgaz.ro/inregistrare-petitie?pod=303');
        const params = url.search;
        const searchParams = new URLSearchParams(params);
        if (searchParams.has('pod') && searchParams.has('countyCode') && searchParams.has('localityCode') ) {
            const podParam = searchParams.get('pod') || '';
            const countyCodeParam = searchParams.get('countyCode') || '';
            const localityCodeParam = searchParams.get('localityCode') || '';
            if (podParam && countyCodeParam) {
                setPod(podParam);
                setCounty(countyCodeParam);
                setLocality( parseInt(localityCodeParam));
                verify(podParam, countyCodeParam , parseInt(localityCodeParam));
            }
        }
    },[]);

    const getValues = (data: any) => {
        let arr: any[] = [];

        data.map((item: any) => {
            Object.keys(item).map((k) => {
                if (k === 'category') {
                    let already = false;

                    for (let i = 0; i < arr.length; ++i) {
                        if (arr[i].category === item[k]) {
                            already = true;
                        }
                    }

                    if (!already) {
                        arr.push({category: item[k], categorySapValue: item.categorySapValue, subjects: []});
                    }
                }
            });
        });

        arr.map((item: any) => {
           data.map((i: any) => {
               Object.keys(i).map((k) => {
                   if (k === 'subject') {
                       let already = false;

                       for (let j = 0; j <= item.subjects.length; ++j) {
                           if (item.subjects[j] && item.subjects[j].subject && item.subjects[j].subject === i[k]) {
                               already = true;
                           }
                       }

                       if (!already && (item.category === i['category'])) {
                           item.subjects.push({subject: i[k], subjectSapValue: i['subjectSapValue'], subSubjects: []});
                       }
                   }
               });
           });
        });

        arr.map((item: any) => {
            item.subjects.map((subject: any) => {
                data.map((i: any) => {
                    Object.keys(i).map((k) => {
                        if (k === 'subSubject') {
                            let already = false;

                            for (let j = 0; j <= subject.subSubjects.length; ++j) {
                                if (subject.subSubjects[j] && subject.subSubjects[j].subSubject && subject.subSubjects[j].subSubject === i[k]) {
                                    already = true;
                                }
                            }

                            if (!already && (subject.subject === i['subject']) && (item.category === i['category'])) {
                                subject.subSubjects.push({subSubject: i[k], subSubjectSapValue: i['subSubjectSapValue']});
                            }
                        }
                    });
                });
            });
        });

        let podToCheck;
        if(modalType == 1) {
            podToCheck = modalData.pod;
        } else {
            podToCheck = pod;
        }
        if (podToCheck[0] === 'E') {
            arr.map((itm: any, index: number) => {
                if (itm.categorySapValue === 'GN') {
                    arr.splice(index,1);
                }
            });
        } else if (podToCheck[0] === 'D') {
            arr.map((itm: any, index: number) => {
                if (itm.categorySapValue === 'EE') {
                    arr.splice(index,1);
                }
            });
        }

        //Remove Alte Subiecte option on RSS Supplier
        arr = arr.filter((category) => {
            if (category.category == 'Alte Subiecte' || category.categorySapValue == 'AS') {
            return false;
            }
            return true;
        });

        setCategories(arr);
    };

    const mountValues = async () => {
        dispatch(toggleLoader(true));

        await getSubjectListFurnizor().then((res) => {
            getValues(res.data);
        });

        dispatch(toggleLoader(false));
    };

    const handlePod = (value: string) => {
        setPod(value);
    };

    const handleCounty = (value: string) => {
        setCounty(value);
    };

    const handleLocality = (value: number) => {
        setLocality(value);
    };

    const verify = async (podParam?: string, countyParam?: string, localityParam?: any) => {
        setError('');
        dispatch(toggleLoader(true));

        let payload;
        if(podParam && countyParam) {
            payload = {
                pod: podParam,
                countyCode: countyParam,
                localityCode: localityParam
            };
        } else {
            payload = {
                pod: pod,
                countyCode: county,
                localityCode: locality
            };
        }

        await verifyCPoint(payload).then((res) => {

            if (res) {
                if (res.data && res.data.data && (res.data.status === 1 || res.data.status === 2)) {
                    if (res.data.data.inPortofolio === true) {
                    
                        setModalData(res.data.data);
                        setShowRSSModal(true);
                        setModalType(1);
                    } else if (res.data.data.inPortofolio === false) {
                        setShowConfirmModal(true);
                        setModalType(2);
                        const data = convertNullsAndUndefinedToEmptyString(res.data.data);
                        setModalData(data);
                    }
                } else if (res.data && res.data.data === null && res.data.status === 3) {
                    setError(res.data.message);
                }
            }
        }).catch((e) => {
            if (e && e.response && e.response.status && e.response.status === 403) {
                setError('Locul de consum nu a fost găsit.');
            } else if (e && e.response && e.response.status && e.response.status === 400 ) {
                setError('POD incorect. POD-ul trebuie sa fie de forma: DEGXXXXXXX pentru Gaze naturale, respectiv EMOXXXXXXX pentru Energie electrica.');
            }
             else {
                setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
            }
        });

        dispatch(toggleLoader(false));
    };

    const handleConfirmModal = (show: boolean) => {
        setShowConfirmModal(show);
    };

    const handleRSSModal = (show: boolean) => {
        setShowRSSModal(show);
    };

    const handleModalType = (type: number) => {
        setModalType(type);
    };

    const clearModalData = () => {
        setModalData(null);
    };

    const handleSuccessModal = (show: boolean) => {
        setShowSuccessModal(show);
    };

    const hideSuccessModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <div className="m-3 p-3">
            <Verify
                onClick={verify}
                pod={pod}
                handlePod={handlePod}
                county={county}
                handleCounty={handleCounty}
                locality={locality}
                handleLocality={handleLocality}
                withLocalityCode
            />
            { error !== '' && <p className="red fw-bold text-center">{error}</p> }
            <RSSModal
                show={showRSSModal}
                handleShow={handleRSSModal}
                type={modalType}
                categories={categories}
                pod={pod}
                modalData={modalData}
                clearModalData={clearModalData}
                LClocality={locality}
                LCCounty={county}
                handleSuccessModal={handleSuccessModal}
            />
            <ConfirmModal show={showConfirmModal} handleShow={handleConfirmModal} handleRSSModalShow={handleRSSModal} handleModalType={handleModalType}/>
            <ModalConfirm showModal={showSuccessModal} onClose={hideSuccessModal} modalTitle={'Succes'}>
                <p>Solicitarea s-a salvat cu succes.</p>
            </ModalConfirm>
        </div>
    );
};

export default InregistrareRSS;