import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { defaultEvent } from '@constants/Event';
import { ToString } from '@lib/Utils';

import { IState } from '@type/store';
import { HTMLInputTypes, ChangeStatusType } from '@type/input';
import { ChangeStatus } from '@constants/Utils';

export type InputProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  HTMLInputTypes & {
    error?: string;
    success?: string;
    alert?: string;
    label?: string;
    description?: string;
    containerClassName?: string;
    maxLength?: number;
    minLength?: number;
    name?: string;
    icon?: JSX.Element;
    status?: ChangeStatusType;
    statusKey?: ChangeStatus;
    additionalHtml?: JSX.Element;
    rows?: number;
    mbZero?: boolean;
    textAreaClass?: string;
  };

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const InputTextArea = (props: InputProps) => {
  const [validation, setValidation] = useState<'invalid' | 'valid' | ''>('');
  //eslint-disable-next-line
  const [inputLabel, setInputLabel] = useState<string>('');
  //eslint-disable-next-line
  let element: HTMLInputElement | null = null;
  const prevProps = usePrevious(props);

  useEffect(() => {
    updateValidation();
  }, [prevProps?.error]);

  const {
    id,
    label,
    error,
    success,
    alert,
    hidden,
    description,
    containerClassName,
    icon,
    placeholder,
    className,
    status,
    statusKey,
    additionalHtml,
    maxLength,
    minLength,
    name,
    value,
    rows,
    mbZero,
    textAreaClass
  } = props;

  const inputHasPlaceholder = placeholder ? 'input-with-placeholder' : '';
  const justPlaceholder = !label && placeholder ? 'input-just-placeholder' : '';

  if (hidden) return null;

  const statusClass = ToString(status && statusKey).toLowerCase();

  const _onChange = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event = event || defaultEvent;
    if (event.persist) event.persist();

    if (props.loader.isOpen) return undefined;

    const target = event.currentTarget || event.target || undefined;

    if (!target) return props.onChange && props.onChange((event as unknown) as React.ChangeEvent<HTMLInputElement>);

    const { selectionStart, selectionEnd } = target;

    // update the state and reset the caret
    if (props.onChange) await props.onChange((event as unknown) as React.ChangeEvent<HTMLInputElement>);

    if (selectionStart !== null && selectionEnd !== null) target.setSelectionRange(selectionStart, selectionEnd);
    return undefined;
  };

  const getValidation = (): '' | 'invalid' | 'valid' => {
    if (props.error) {
      return 'invalid';
    } else if (props.success) {
      return 'valid';
    }
    return '';
  };

  const updateValidation = () => {
    let state = getValidation();
    setValidation(state);
  };

  return (
    <div className={`field-group ${validation} ${mbZero ? 'mb-0' : ''} ${error ? 'error' : ''} ${ToString(containerClassName)} ${statusClass}`}>
      <span className="input">
        {additionalHtml}

        <textarea
          value={value}
          name={name}
          rows={rows}
          className={`${textAreaClass} ${inputHasPlaceholder} ${justPlaceholder} ${ToString(
            className
          )} ${value ? 'input-with-value' : ''}`}
          onChange={_onChange}
          id={id}
          hidden={hidden}
          ref={(ref) => (element = (ref as unknown) as HTMLInputElement)}
          maxLength={maxLength}
          minLength={minLength}
        />

        {(label || placeholder) && (
          <label htmlFor={id}>
            {label}
            {placeholder && <span>{placeholder}</span>}
          </label>
        )}
      </span>

      {icon}

      {description && <span>{description}</span>}

      {error && <span className="label-error">{error}</span>}
      {success && <span className={inputLabel}>{success}</span>}
      {alert && <span className={inputLabel}>{alert}</span>}
      {status && statusKey && (
        <div className="status">
          <p>{status[statusKey]}</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({ loader: state.loader });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InputTextArea);