import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import EditAccount from '@components/account/EditAccount/EditAccount';

export const Security = () => {
    return (
        <DelgazWrapper>
            <EditAccount />
        </DelgazWrapper>
    );
};