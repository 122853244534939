import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';

type SearchType = {
    utility: number | any;
    partnerCode: string;
    clc: string;
    pod: string;
    counties: any;
    county: string;
    localities: any;
    locality: string;
    clcChange: (value: any) => any;
    podChange: (value: any) => any;
    partnerCodeChange: (value: any) => any;
    handleCountyChange: (value: any) => any;
    handleLocalityChange: (value: any) => any;
    submitSearch: (utility: string) => any;
    reset: (utility: string) => any;
    hidePartnerCodeFilter?: boolean;
}

const Search = (props: SearchType) => {
    return (
        <div className="m-3">
            <Row className="justify-content-center">
                <Col xs={12} md={2}>
                    <Input label={`${props.utility == 1 ? 'Cod loc consum' : 'Numar Loc Consum'}`} value={props.clc} onChange={
                        (e) => {
                            e.preventDefault();
                            props.clcChange(e.target.value);
                        }} />
                </Col>
                <Col xs={12} md={2}>
                    <Input label={`${props.utility == 1 ? 'POD' : 'Cod Loc Consum'}`} value={props.pod} onChange={(e) => {
                        e.preventDefault();
                        props.podChange(e.target.value);
                    }} />
                </Col>

                {!props.hidePartnerCodeFilter && (
                    <Col xs={12} md={2}>
                        <Input
                        label="Cod partener"
                        value={props.partnerCode}
                        onChange={(e) => {
                            e.preventDefault();
                            props.partnerCodeChange(e.target.value);
                        }}
                        />
                    </Col>
                )}
                
                <Col xs={12} md={2}>
                    <Dropdown
                        options={props.counties.map((option: any, index: number) => (
                            {
                                id: option.countyCode,
                                name: option.countyName
                            }
                        )) as DropdownOptionType[]}
                        label={'Județ'}
                        defaultPlaceholder={'Alege județul'}
                        displaySearch={true}
                        onChange={(value) => {
                            props.handleCountyChange(value);
                        }}
                        value={props.county}
                    />
                </Col>
                {(props.county !== '' && props.localities && props.localities.length > 0) && <Col xs={12} md={2}>
                    <Dropdown
                        options={props.localities.map((option: any, index: number) => (
                            {
                                id: option.localityId,
                                name: option.localityName
                            }
                        )) as DropdownOptionType[]}
                        label={'Localitate'}
                        defaultPlaceholder={'Alege localitatea'}
                        displaySearch={true}
                        onChange={(value) => {
                            props.handleLocalityChange(value);
                        }}
                        value={props.locality}
                    />
                </Col>}
            </Row>
            <Row className="justify-content-center mb-3">
                <Col xs={12} sm={9}>
                    <div className="triplet-separator"></div>
                </Col>
            </Row>
            <Row>
                <div className="d-flex justify-content-center">
                    <Button onClick={() => props.submitSearch(props.utility)}>Caută</Button>
                    <Button style={{ marginLeft: '5px' }} onClick={() => props.reset(props.utility)}>Reset</Button>
                </div>
            </Row>
        </div>
    );
};

export default Search;