import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
    Button,
    Form,
    Row,
    Col
} from 'react-bootstrap';
import * as notificationPopup from '@lib/Notification';
import { Input } from '@components/common/Input';
import { AvailableDays, MeterReading, TimeSlot } from '@type/programari-citiri';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import InputTextArea from '@components/common/Input/InputTextArea';
import { saveReading } from '@api/programareCitiri';
import moment from 'moment-timezone';
import { Select } from '@components/common/Select';
import { ToString } from '@lib/Utils';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { CheckBox } from '@components/common/CheckBox';

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

export type NotificationModalProps = {
    notification: MeterReading,
    show: boolean,
    setShow: (state: boolean) => void;
    saveNotification: (inputs: NotificationInputs) => void;
    usedInBO?: boolean;
    kid?: string | any;
    errorNotification:() => void;
    onClose?: () => void;
    modalClassName?: string;
    modalTitle: string;
};

export class NotificationInputs {
    id: number | null = null;
    phoneNumber: string = '';
    comments: string = '';
    timeHour: string | null = null;
    timeName: string | null = null;
    gdpr?: boolean = false;
    date?: Date | null = undefined;
    kid?: string | any;
};
type ErrorsType = { [key: string]: string | undefined };


const NotificationModal = (props: NotificationModalProps): JSX.Element => {
    let { notification } = props;
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<ErrorsType>({});
    const [availableDayDates, setAvailableDayDates] = useState<any>([]);
    const [inputValues, setInputValues] = useState<NotificationInputs>(new NotificationInputs());
    const [daysAvailable, setDaysAvailable] = useState<boolean>(true);
    const [selectedTimeHour, setSelectedTimeHour] = useState<any>('');
    const [initialDate, setInitialDate] = useState<any>('');
    const [initialTimeHour, setInitialTimeHour] = useState<any>(-1);
    const [processPersonalDataAgree, setProcessPersonalDataAgree] = useState<boolean>(false);
    const [currentOffset, setCurrentOffset] = useState<number>(120);

    useEffect(() => {
        if(inputValues.date) {
            const dateOffset: number = moment(inputValues.date, 'YYYYMMDDHHmm').utcOffset();
            setCurrentOffset(dateOffset);
        }
    },[inputValues.date]);

    useEffect(() => {
        if(!inputValues.date) {
            setSelectedTimeHour('');
            setProcessPersonalDataAgree(false);
        }
    }, [inputValues.date]);

    //NO_DAYS_AVAILABLE_TO_ADD
    useEffect(() => {
        if(props.usedInBO && notification.status === 'NO_DAYS_AVAILABLE_TO_ADD') {
            setDaysAvailable(false);
        }
    },[props.usedInBO, notification]);

    useEffect(() => {
        if (notification.notificationId) {
            let date = notification.appointmentUtcDate ? moment(notification.appointmentUtcDate, 'YYYYMMDDHHmm').toDate() : null;
            setInitialDate(date);
            let timeHour = '';
            let timeName: any = '';

            if(date) {
                timeHour = `${((date.getHours() + ((currentOffset) / 60)) * 3600) + (date.getMinutes() * 60)}`;
                timeName = `${ToString((date.getHours() + ((currentOffset) / 60))).padStart(2, '0')}:${ToString(date.getMinutes()).padStart(2, '0')}`;
                setSelectedTimeHour(timeHour);
                setInitialTimeHour(timeHour);
            }

            setInputValues({
                ...inputValues,
                timeHour: timeHour,
                timeName: timeName,
            });
            setErrors({});
        }
    }, [currentOffset]);

    useEffect(() => {
        if (notification.notificationId) {
            let date = notification.appointmentUtcDate ? moment(notification.appointmentUtcDate, 'YYYYMMDDHHmm').toDate() : null;
            setInitialDate(date);
            let timeHour = '';
            let timeName: any = '';

            if(date) {
                timeHour = `${((date.getHours() + ((currentOffset) / 60)) * 3600) + (date.getMinutes() * 60)}`;
                timeName = `${ToString((date.getHours() + ((currentOffset) / 60))).padStart(2, '0')}:${ToString(date.getMinutes()).padStart(2, '0')}`;
                setSelectedTimeHour(timeHour);
                setInitialTimeHour(timeHour);
            }

            setInputValues({
                ...inputValues,
                id: notification.notificationId,
                phoneNumber: notification.phoneNumber ?? '',
                comments: notification.comments ?? '',
                date: date,
                timeHour: timeHour,
                timeName: timeName,
                kid: props.kid || null
            });
            setErrors({});
        }
    }, [notification]);

    useEffect(() => {
        if (notification.timeSlotMinutes && inputValues.date && notification.availableDays) {
            let selectedDate = moment(inputValues.date).format('YYYYMMDD');
            let timeSlotsForSelection: AvailableDays | undefined = notification.availableDays.find((day: AvailableDays) => day.date === selectedDate);
             if (timeSlotsForSelection && timeSlotsForSelection.timeSlots) {
                let availableDayDates = timeSlotsForSelection.timeSlots.map((slot: TimeSlot) => ({
                    id: `${((slot.hour + (currentOffset / 60)) * 3600) + (slot.minute * 60)}`,
                    name: `${ToString((slot.hour + (currentOffset / 60))).padStart(2, '0')}:${ToString(slot.minute).padStart(2, '0')}`
                }));

                if(!availableDayDates.find(d=> d.id === inputValues?.timeHour) && moment(initialDate).isSame(inputValues.date)) {
                    const index = availableDayDates.findIndex(d => d.id > initialTimeHour);
                    if(index == -1) {
                        //@ts-ignore
                        availableDayDates = [...availableDayDates, {id: initialTimeHour, name: inputValues?.timeName}];
                    } else {
                        //@ts-ignore
                        availableDayDates = [
                            ...availableDayDates.slice(0, index),
                            {id: initialTimeHour, name: inputValues?.timeName},
                            ...availableDayDates.slice(index)];
                    }
                }
                setAvailableDayDates(availableDayDates);
            }
        }
    },[notification, inputValues.date, currentOffset, initialTimeHour]);

    const saveNotification = () => {
        if (_validation()) {
            dispatch(toggleLoader(true));
            saveReading(inputValues).then(() => {
            props.saveNotification(inputValues);
            }).catch((e => {
                console.log(e);
            props.errorNotification();

            }));
            dispatch(toggleLoader(false));
        }
    };

    const isWeekday = (date: Date): boolean => {
        if (notification.availableDays) {
            return !!(notification.availableDays.find((day: AvailableDays) => {
                return moment(day.date, 'YYYYMMDD').toDate().getTime() === date.getTime();
            }));
        }

        return false;
    };


    const _validation = () => {
        const data = {
            date: '',
            timeHour: '',
            phoneNumber: '',
            comments: '',
            processPersonalDataAgree: ''
        } as ErrorsType;

        let isValid = true;

        if (!inputValues.phoneNumber || inputValues.phoneNumber === '') {
            data['phoneNumber'] = 'Numarul de telefon este obligatoriu';
            isValid = false;
        }

        if (inputValues.phoneNumber.length < 10 || inputValues.phoneNumber.length > 10) {
            data['phoneNumber'] = 'Numarul este format din 10 caractere';
            isValid = false;
        }

        //validare ca pe portal vechi
        if (!inputValues.phoneNumber.match(/0\d{9}/)) {
            data['phoneNumber'] = 'Numărul de telefon are formatul greșit';
            isValid = false;
        }

        if (!inputValues.comments) {
            data['comments'] = 'Campul observatii este obligatoriu';
            isValid = false;
        }

        if (inputValues.comments.length > 400) {
            data['comments'] = 'Introduceti maxim 400 de caractere';
            isValid = false;
        }

        if (!inputValues.date) {
            data['date'] = 'Data programarii este obligatorie';
            isValid = false;
        }

        if (notification.timeSlotMinutes > 0 && !inputValues.timeHour) {
            data['timeHour'] = 'Alegeti intervalul programarii';
            isValid = false;
        }

        if (!props.usedInBO && !processPersonalDataAgree) {
            data['processPersonalDataAgree'] = 'Nu puteți continua fără a vă exprima acordul';
            isValid = false;
        }

        setErrors({ ...data });

        if (!isValid)
            notificationPopup.error('Va rugam verificati datele introduse!');

        return isValid;
    };

    return (
        <ModalComponent
            title={props.modalTitle}
            show={props.show} setShow={(state: boolean) => {
                props.setShow(state);
            }}
            size={'lg'}
            footerAction={
                <Button className="btn btn-primary"
                    disabled={!daysAvailable}
                    onClick={(e) => {
                        saveNotification();
                    }} variant="primary">
                    Salvează
                </Button>}
            onClose={() => {
                props.onClose && props.onClose();
            }}
            applyFooterMobileSpacer
            modalClassName={props.modalClassName}
        >
            <Form
                // onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.onSubmit(e)}
                className="form">
                {props.modalClassName === 'rss-modal' &&
                <div className="client-type-section mb-4">
                    <Row className="client-type-section-header">
                        <Col xs={12} sm={6}>
                            <div className="section-red-border" />
                            <h5 className="m-0">Date de identificare loc de consum</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="separator" />
                        </Col>
                    </Row>
                </div>
                }
                <Row>
                    <Col xs={12} md={6}>
                        <Input
                            value={notification.clientName}
                            label={'Nume și prenume/Denumire client'}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Input
                            value={notification.consumptionPointCode}
                            label={'Cod loc consum'}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Input
                            value={notification.consumptionPointAddress}
                            label={'Adresa loc consum'}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Input
                            value={notification.equipment}
                            label={'Echipament'}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Input
                            value={notification.material}
                            label={'Material'}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Input
                            value={notification.series}
                            label={'Serie'}
                            disabled={true}
                        />
                    </Col>
                </Row>
          {daysAvailable ? (
          <Row className='mb-2'>
            <h5>Vă rugăm completaţi toate câmpurile de mai jos</h5>
            <Col xs={12} className="mb-3">
              <ReactDatePicker
                placeholderText={'Data programarii'}
                // maxDate={new Date()}
                selected={inputValues.date}
                className={errors.date && errors.date.length > 0 ? 'error invalid' : ''}
                filterDate={isWeekday}
                onChange={(date) => {
                  setInputValues({
                    ...inputValues,
                    date: date,
                    timeHour: null
                  });
                  setSelectedTimeHour('');
                }}
              />
            </Col>
            {notification.timeSlotMinutes && notification.timeSlotMinutes > 0 ? (
              <Col xs={12} className="mb-3">
                <Select
                  value={selectedTimeHour}
                  placeholder={'Alege ora'}
                  name={'timeHour'}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedTimeHour(event.target.value);
                    setInputValues({
                      ...inputValues,
                      timeHour: event.target.value
                    });
                  }}
                  error={errors?.timeHour}
                  options={availableDayDates}
                />
              </Col>
            ) : null}
            <Col xs={12}>
              <Input
                max={10}
                maxLength={10}
                value={inputValues.phoneNumber}
                error={errors?.phoneNumber}
                label={'Telefon'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValues({ ...inputValues, phoneNumber: event.target.value });
                }}
              />
            </Col>
            <Col xs={12}>
              <InputTextArea
                value={inputValues.comments}
                label={'Observatii'}
                error={errors?.comments}
                maxLength={400}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValues({ ...inputValues, comments: event.target.value });
                }}
              />
            </Col>
            {!props.usedInBO && 
                <Col xs={12}>
                    <CheckBox
                    name={'agree'}
                    checked={processPersonalDataAgree}
                    subtitle={'Odată cu salvarea datelor din câmpurile obligatorii, vă exprimaţi acordul dumneavoastră cu privire la utilizarea prelucrării datelor personale, în vederea efectuării programării. Dacă nu doriţi acest lucru, vă rugăm să folosiți celelalte canale de comunicare pe care le punem la dispoziţie. Pentru mai multe detalii privind prelucrarea datelor cu caracter personal de către DELGAZ GRID accesați secţiunea ,,Protecţia datelor’’.'}
                    required={true}
                    onChange={(e) => {setProcessPersonalDataAgree(!processPersonalDataAgree);}}
                    error={errors?.processPersonalDataAgree}
                    />
                </Col>}
          </Row>
        ) : (
          <Row>
            <Col xs={12}>
              <strong>Nu există zile disponibile pentru programare!</strong>
            </Col>
          </Row>
        )}
                
                <Row>
                    {props.usedInBO && 
                    <Col xs={12}>
                    <p><strong>Informare client: </strong>datele personale vor fi utilizate pentru executarea lucrărilor (citire/înlocuire)</p>
                    </Col> }
                    {notification.timeSlotMinutes && notification.timeSlotMinutes > 0 ? <></> : 
                    <Col xs={12}>
                        <p>Programarea la ora fixă nu este posibilă.</p>
                    </Col> }
                    
                    <Col xs={12}>
                        <p>Alte modificări ale programării pot fi efectuate doar în următoarele 2 ore. După acest interval, solicitarea va fi preluată de prestatorul de citire, iar eventuale modificări pot fi comunicate telefonic la numărul 0232.200.437.</p>
                    </Col>
                    <Col xs={12}>
                        <p>Orice modificare solicitată poate presupune o decalare a timpului de execuție.</p>
                    </Col>
                    <Col xs={12}>
                        <p>În dimineaţa zilei în care se va efectua citirea conform programării veți fi contactat telefonic și vi se va comunica un interval orar.</p>
                    </Col>
                </Row>
            </Form>
        </ModalComponent>

    );
};

export default NotificationModal;