import React, { FunctionComponent, Fragment } from 'react';
import { Image } from 'react-bootstrap';

export type OverlayProps = {
  title?: string;
  description?: string;
  link?: string;
  image: string;
  hasOverlay?: boolean;
  textColor?: string;
  displayModeRedContainer?: boolean;
  displayToLeft?: boolean;
};

const OverlayItem: FunctionComponent<OverlayProps> = (props: OverlayProps) => {
  let style = props.textColor ? { color: props.textColor, fontSize: '32px' } : { fontSize: '32px' };
  let pstyle = props.textColor ? { color: props.textColor } : {};

  return (
    <div className="image-overlay-wrapper">
      <Image className="image" src={props.image} />
      {props.displayModeRedContainer ? (
        <div className={`display-mode-red-container ${props.displayToLeft && 'display-mode-red-container__display-to-left'}`}>
          {props.title && <h2>{props.title}</h2>}
          {props.link && (
            <p>
              <a href={props.link}>Află mai multe</a>
            </p>
          )}
        </div>
      ) : (
        <Fragment>
          {props.hasOverlay && (
            <div className="overlay SliderightEffect">
              <div className="d-flex align-items-center justify-content-center flex-column h-100">
                {props.title && <h1 style={style}>{props.title}</h1>}
                {props.description && (
                  <p className="text-center" style={pstyle}>
                    {props.description}
                  </p>
                )}
                {props.link && (
                  <p className="text-center" style={{ fontSize: '16px' }}>
                    <a className="overlay-image-link" href={props.link}>
                      Află mai multe
                    </a>
                  </p>
                )}
              </div>
            </div>
          )}
          {props.hasOverlay === false && (props.title || props.description) && (
            <div className="overlay-image-center position-absolute d-flex align-items-center justify-content-center flex-wrap">
              {props.title && <h2>{props.title}</h2>}
              {props.description && <p className="text-center">{props.description}</p>}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default OverlayItem;
