import ModalComponent from '@components/dev/Components/Modal/Modal';
import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

type FrontEndDataViewerProps = {
  footer: string[] | any;
  header: string[] | any;
  tableGridStructure: any;
};

const FrontEndDataViewer = (props: FrontEndDataViewerProps) => {
  const [longContent, setLongContent] = useState('');
  const [showModalLongContent, setShowModalLongContent] = useState(false);

  return (
    <Fragment>
      <Row className="mt-2">
        {props.header &&
          props.header.map((header: any) => {
            return <div dangerouslySetInnerHTML={{ __html: header }}></div>;
          })}

        <Row className="mt-1">
          {props.tableGridStructure.gridRows &&
            props.tableGridStructure.gridRows.map((gridRow: any) => {
              return (
                <Col className="mt-1">
                  {gridRow.tables.map((table: any) => {
                    return (
                      <div className="general-table-container">
                        <table>
                          <thead>
                            <tr>
                              {table.description ? (
                                table.rows.length > 0 ? (
                                  table.rows[0].columns.map((column: any, index: number) => {
                                    return index == 0 ? (
                                      <th>
                                        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: table.description }}></div>{' '}
                                      </th>
                                    ) : (
                                      <th></th>
                                    );
                                  })
                                ) : (
                                  <th>
                                    <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: table.description }}></div>{' '}
                                  </th>
                                )
                              ) : (
                                table.header && (
                                  <>
                                    {table.header.columns.map((column: any, index: number) => {
                                      return (
                                        <th>
                                          <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: column }} />{' '}
                                        </th>
                                      );
                                    })}
                                  </>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {table.rows.map((row: any, index: number) => (
                              <tr key={index}>
                                {row.columns.map((column: any, idx: number) => {
                                  let displayInModal = false;
                                  if (table.displayValueInModalForColumns && table.displayValueInModalForColumns.length) {
                                    displayInModal = table.displayValueInModalForColumns.includes(table.header.columns[idx]);
                                  }
                                  if (displayInModal) {
                                    if (column) {
                                      return (
                                        <td>
                                          <a
                                            className="text-underline"
                                            onClick={() => {
                                              setLongContent(column);
                                              setShowModalLongContent(true);
                                            }}
                                          >
                                            Afișează
                                          </a>
                                        </td>
                                      );
                                    } else {
                                      return <td></td>;
                                    }
                                  } else return <td dangerouslySetInnerHTML={{ __html: column }} />;
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </Col>
              );
            })}
        </Row>

        {props.footer &&
          props.footer.map((footer: any) => {
            return <div dangerouslySetInnerHTML={{ __html: footer }}></div>;
          })}
      </Row>

      <ModalComponent
        title={'Conținut'}
        show={showModalLongContent}
        className="backoffice-modal overflow-auto pe-none"
        setShow={(state: boolean) => {
          setShowModalLongContent(false);
        }}
        size={'xl'}
        closeButttonLabel="Înapoi"
        minHeigth={560}
      >
        <div dangerouslySetInnerHTML={{ __html: longContent }} />
      </ModalComponent>
    </Fragment>
  );
};

export default FrontEndDataViewer;
