import React, { useEffect, useState } from 'react';
import StepOne from '@components/rss/formularContact/StepOne';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { getSubjectList } from '@api/rss/rss';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import RSSClientStepTwo from './RSSClientStepTwo';

const RSSClient = () => {
    const [step, setStep] = useState(1);
    const [categories, setCategories] = useState<any[]>([]);
    const [category, setCategory] = useState('');
    const [subject, setSubject] = useState('');
    const [subSubject, setSubSubject] = useState('');
    const [subjects, setSubjects] = useState<any[]>([]);
    const [subSubjects, setSubSubjects] = useState<any[]>([]);
    const [faqs, setFaqs] = useState<any[]>([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const dispatch = useDispatch();

    const handleCategory = (value: string) => {
        setCategory(value);
        setSubject('');
        setSubSubject('');
    };

    const handleSubject = (value: string) => {
        setSubject(value);
    };

    const handleSubSubject = (value: string) => {
        setSubSubject(value);
    };

    const handleSubjects = (value: any[]) => {
        setSubjects(value);
    };

    const handleSubSubjects = (value: any[]) => {
        setSubSubjects(value);
    };

    const handleFaqs = (value: any[]) => {
        setFaqs(value);
    };

    const handleStep = (value: number) => {
        setStep(value);
    };

    useEffect(() => {
        mountValues();
    }, []);

    const changeStep = (step: number) => {
        setStep(step);
    };

    const getValues = (data: any) => {
        let arr: any[] = [];

        data.map((item: any) => {
            Object.keys(item).map((k) => {
                if (k === 'category') {
                    let already = false;

                    for (let i = 0; i < arr.length; ++i) {
                        if (arr[i].category === item[k]) {
                            already = true;
                        }
                    }

                    if (!already) {
                        arr.push({ category: item[k], categorySapValue: item.categorySapValue, subjects: [] });
                    }
                }
            });
        });

        arr.map((item: any) => {
            data.map((i: any) => {
                Object.keys(i).map((k) => {
                    if (k === 'subject') {
                        let already = false;

                        for (let j = 0; j <= item.subjects.length; ++j) {
                            if (item.subjects[j] && item.subjects[j].subject && item.subjects[j].subject === i[k]) {
                                already = true;
                            }
                        }

                        if (!already && (item.category === i['category'])) {
                            item.subjects.push({ subject: i[k], subjectSapValue: i['subjectSapValue'], subSubjects: [] });
                        }
                    }
                });
            });
        });

        arr.map((item: any) => {
            item.subjects.map((subject: any) => {
                data.map((i: any) => {
                    Object.keys(i).map((k) => {
                        if (k === 'subSubject') {
                            let already = false;

                            for (let j = 0; j <= subject.subSubjects.length; ++j) {
                                if (subject.subSubjects[j] && subject.subSubjects[j].subSubject && subject.subSubjects[j].subSubject === i[k]) {
                                    already = true;
                                }
                            }

                            if (!already && (subject.subject === i['subject']) && (item.category === i['category'])) {
                                subject.subSubjects.push({ subSubject: i[k], subSubjectSapValue: i['subSubjectSapValue'], faqs: [] });
                            }
                        }
                    });
                });
            });
        });

        arr.map((item: any) => {
            item.subjects.map((subject: any) => {
                subject.subSubjects.map((subSubject: any) => {
                    data.map((i: any) => {
                        Object.keys(i).map((k) => {
                            if (k === 'subSubject' && i[k] === subSubject.subSubject && i['frequentQuestions'].length > 0) {
                                subSubject.faqs.push(i['frequentQuestions']);
                            }
                        });
                    });
                });
            });
        });

        let aux = arr[0];
        arr[0] = arr[1];
        arr[1] = aux;

        setCategories(arr);
    };

    const mountValues = async () => {
        dispatch(toggleLoader(true));

        await getSubjectList().then((res) => {
            getValues(res.data);
        });

        dispatch(toggleLoader(false));
    };

    const handleSuccessModal = (show: boolean) => {
        if (show) {
            setCategory('');
            setSubject('');
            setSubSubject('');
        }
        setShowSuccessModal(show);
    };

    const hideModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <div>
            <h2 className="text-center red">Suntem aici pentru tine!</h2>
            <div className="formular-contact m-4">
                {step === 1 &&
                    <StepOne
                        categories={categories}
                        subjects={subjects}
                        subSubjects={subSubjects}
                        step={changeStep}
                        category={category}
                        subject={subject}
                        subSubject={subSubject}
                        handleCategory={handleCategory}
                        handleSubject={handleSubject}
                        handleSubSubject={handleSubSubject}
                        handleSubjects={handleSubjects}
                        handleSubSubjects={handleSubSubjects}
                        faqs={faqs}
                        handleFaqs={handleFaqs}
                    />
                }
                {step === 2 &&
                    <RSSClientStepTwo 
                        handleStep={handleStep}
                        category={category}
                        subject={subject}
                        subSubject={subSubject}
                        handleSuccessModal={handleSuccessModal}
                    />
                }
                <ModalConfirm showModal={showSuccessModal} onClose={hideModal} modalTitle={'Succes'}>
                    <p>Petiția ta a fost trimisă pentru procesare și poate fi vizualizată în secțiunea Listă petiții.</p>
                </ModalConfirm>
            </div>
        </div>

    );
};

export default RSSClient;