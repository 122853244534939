import { toggleLoader } from '@actions/LoaderActions';
import { exportPDFEE } from '@api/iConnect/iConnectEE';
import { exportPDFGN } from '@api/iConnect/iConnectGaz';
import { exportPDF } from '@api/prosumatori/prosumatori';
import ButtonWithTag from '@components/common/Button/ButtonWithTag';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import PayInvoices from '@components/invoices/PayInvoices';
import Pagination from '@components/prosumatori/components/table/Pagination';
import { faDownload, faPen, faTimeline, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParam, removeQueryParam, setQueryParam } from '../../../../helpers/urlUtils';

const ShowRequests = (props: {
  list: any;
  delete: any;
  get: any;
  searchText: string;
  searchStatus: string;
  searchType: string;
  handleSearchText: any;
  handleSearchStatus: any;
  handleSearchType: any;
  requestStatuses: DropdownOptionType[];
  requestTypes: any[];
  error: string;
  utility: string;
  wasTabChanged: boolean;
}) => {
  const { get, searchText, searchStatus, searchType, utility, wasTabChanged } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const indexOfLastElement = currentPage * perPage;
  const indexOfFirstElement = indexOfLastElement - perPage;
  const currentElements = props.list.slice(indexOfFirstElement, indexOfLastElement);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);

  const RedirectURL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

  const [showModal, setShowModal] = useState(false);
  const [requestId, setRequestId] = useState<number | null>(null);
  const [requestType, setRequestType] = useState<string>('');

  //pay invoices
  const [showModalPayInvoices, setShowModalPayInvoices] = useState<boolean>(false);
  const [requestIdToPayInvoices, setRequestIdToPayInvoices] = useState<string>('');

  const [highlighted, setHighlighted] = useState<string | null>(null);
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let payInvoicesRequestId = getQueryParam('pay_invoices_request_id');
    if (payInvoicesRequestId) {
      setRequestIdToPayInvoices(payInvoicesRequestId);
      setShowModalPayInvoices(true);
    }
  }, []);

  useEffect(() => {
    props.list.length > 0 && setHighlightedRequest();
  }, [props.list]);

  const setHighlightedRequest = () => {
    const url = new URL(window.location.href);

    const params = url.search;
    const searchParams = new URLSearchParams(params);

    if (searchParams.has('id') && searchParams.has('tab') && !wasTabChanged) {
      if (!isHighlighted && searchParams.get('tab')?.toUpperCase() === utility) {
        setHighlighted(searchParams.get('id'));
        setIsHighlighted(true);

        props.list.map((item: any, index: number) => {
          if (item.requestId == searchParams.get('id')) { 
            setCurrentPage(Math.floor((index+10)/10));
          }
        });
      }
    }
  };

  const fileDownload = async (payload: any) => {
    console.log(payload);
    dispatch(toggleLoader(true));
    let res = '' as BlobPart;
    if (utility === 'EE') {
      if (payload.isProsumer === true) {
        res = await exportPDF(payload.requestId);
      } else {
        res = await exportPDFEE(payload.requestId);
      }
    } else {
      res = await exportPDFGN(payload.requestId);
    }

    try {
      setTimeout(() => {
        const blob: Blob = new Blob([res]);

        // const fileName: string = config ? config.exportButton.fileName.replace('{timestamp}', moment().format('YYMMDDHms')) : `export-${moment().format('YYMMDDHms')}.xlsx`;
        const fileName: string = `Cerere-${payload.requestId}.pdf`;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
        dispatch(toggleLoader(false));
      }, 500);
    } catch (error) {
      // onError();
      dispatch(toggleLoader(false));
    }

    dispatch(toggleLoader(true));
  };

  const renderModal = () => {
    return (
      <Modal style={{ marginTop: '10%' }} show={showModal}>
        <Modal.Title>
          <div className="p-3" style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
            <span>Șterge</span>
          </div>
        </Modal.Title>
        <Modal.Body>
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
            <span>Ești sigur că vrei să ștergi această cerere?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              style={{ marginRight: '12px' }}
              onClick={() => {
                props.delete(requestId, utility, requestType);
                setShowModal(false);
                setRequestId(null);
                setRequestType('');
              }}
            >
              Șterge
            </Button>
            <Button onClick={() => setShowModal(false)}>Anulează</Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const onDelete = (requestId: number, requestType: string) => {
    setRequestId(requestId);
    setRequestType(requestType);
    setShowModal(true);
  };

  const editLink = (request: any, canEdit: boolean) => {
    if (utility === 'GN') {
      return `${RedirectURL}/racordare-gaze-naturale?requestId=${request.requestId}&step=${request.currentStep}&edit=${canEdit}`;
    } else if (utility === 'EE') {
      if (request.isProsumer === true) {
        return `${RedirectURL}/cerere-prosumatori?requestId=${request.requestId}&step=${request.currentStep}&edit=${canEdit}`;
      } else {
        return `${RedirectURL}/racordare-energie-electrica?requestId=${request.requestId}&step=${request.currentStep}&edit=${canEdit}`;
      }
    }
  };

  return (
    <div className="mt-3 request-list">
      <div>
        <Row>
          <Col xs={12} sm={12} md={3}>
            <Input label="Client" value={searchText} onChange={(e) => props.handleSearchText(e.target.value)} />
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Dropdown
              options={props.requestStatuses}
              label="Status"
              defaultPlaceholder={'Alege o optiune'}
              displaySearch={true}
              onChange={(e) => props.handleSearchStatus(e)}
              value={searchStatus}
            />
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Dropdown
              options={props.requestTypes}
              label="Tip"
              defaultPlaceholder={'Alege o optiune'}
              displaySearch={true}
              onChange={(e) => props.handleSearchType(e)}
              value={searchType}
            />
          </Col>

          <Col className="d-flex flex-wrap align-items-start justify-content-md-end justify-content-sm-center justify-content-center">
            <Button
              className="btn-large ms-1 me-1 mb-2"
              style={{ minWidth: '146px' }}
              onClick={() => {
                get(utility, searchText, searchStatus, searchType);
                paginate(1);
              }}
            >
              Caută
            </Button>
            <Button
              className="btn-large ms-1 me-1 mb-2"
              style={{ minWidth: '146px' }}
              onClick={() => {
                props.handleSearchText('');
                props.handleSearchStatus('');
                props.handleSearchType('');
                get(utility, '', '', '');
                paginate(1);
              }}
            >
              Resetează
            </Button>
          </Col>
        </Row>
        <div className="general-table-container">
          {currentElements.length > 0 && (
            <table className="w-100 mb-4">
              <thead>
                <tr>
                  <th>Nr. cerere portal</th>
                  <th>Client</th>
                  <th>Tip cerere</th>
                  <th>Adresa locului de consum</th>
                  <th>Status</th>
                  <th>Acțiuni</th>
                  {/*<th style={{border: '1px solid red'}} className="p-3">Link descărcare</th>*/}
                </tr>
              </thead>
              <tbody>
                {currentElements.map((element: any, index: number) => (
                  <tr
                    key={index}
                    style={element.requestId == highlighted ? { fontWeight: 'bold', height: '25px' } : { height: '25px' }}
                    className={`${element.requestId == highlighted ? 'highlight-light-red' : ''}`}
                  >
                    <td>{element.requestId}</td>
                    <td>
                      {element.lastName} {element.firstName}
                    </td>
                    <td>
                      {element.tip_cerere !== null
                        ? element.tip_cerere
                        : utility === 'EE'
                        ? element.isProsumer
                          ? '-'
                          : '-'
                        : '-'}
                    </td>
                    <td>
                      {element.countyName && element.countyName}
                      {element.localityName && ', ' + element.localityName}
                      {element.villageName && ', ' + element.villageName}
                      {element.streetName && ', ' + element.streetName}
                      {element.streetNumber && ', ' + element.streetNumber}
                      {element.building && ', ' + element.building}
                      {element.entramceNumber && ', ' + element.entramceNumber}
                      {element.apartment && ', ' + element.apartment}
                    </td>
                    <td>
                      {element.isFinalStep === true ? (
                        <span className="sent">{element.processStatusName}</span>
                      ) : (
                        <span className="not-sent">Pasul {element.currentStep.slice(3)}</span>
                      )}
                    </td>
                    <td className="d-flex">
                      {element.isFinalStep === false ? (
                        <div className="d-flex">
                          <div style={{ marginRight: '6px' }}>
                            <Button style={{ width: '103px' }}>
                              <a
                                className="text-nowrap white text-decoration-none hover-white"
                                style={{}}
                                href={editLink(element, true)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <FontAwesomeIcon icon={faPen} />
                                <span>&nbsp;Editează</span>
                              </a>
                            </Button>
                          </div>

                          <div>
                            <Button style={{ width: '103px' }}>
                              <span
                                className="text-nowrap text-decoration-none pointer"
                                onClick={() => onDelete(element.requestId, element.tip_cerere)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                                <span>&nbsp;Șterge</span>
                              </span>
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex">
                          {utility === 'GN' && element.TipBransament === 7
                            ? ''
                            : ((utility === 'EE' && element.isProsumer === false) || utility === 'GN') && (
                                <div style={{ marginRight: '6px' }}>
                                  <Button style={{ width: '103px' }}>
                                    <a
                                      href={`${RedirectURL}/timeline?requestId=${element.requestId}&utility=${
                                        utility === 'EE' ? '1' : '2'
                                      }&processStatus=${element.processStatus}`}
                                      className="text-nowrap white text-decoration-none hover-white"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <FontAwesomeIcon icon={faTimeline} />
                                      <span className="white">&nbsp;Timeline</span>
                                    </a>
                                  </Button>
                                </div>
                              )}

                          {element.isMigrated === false && <div style={{ marginRight: '6px' }}>
                            <Button style={{ width: '103px' }}>
                              <a
                                download
                                onClick={() => fileDownload(element)}
                                className="text-decoration-none black hover-white text-nowrap pointer white"
                              >
                                <FontAwesomeIcon icon={faDownload} />
                                <span className="white">&nbsp;Descarcă</span>
                              </a>
                            </Button>
                          </div>}

                          {element.invoicesCount ? (
                            <div style={{ marginRight: '6px' }}>
                              <ButtonWithTag
                                label="Facturi"
                                tag={element.invoicesCount}
                                style={{ minWidth: '103px' }}
                                onClick={() => {
                                  setRequestIdToPayInvoices(element.requestId);
                                  setShowModalPayInvoices(true);
                                  setQueryParam('pay_invoices_request_id', element.requestId);
                                }}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                      {(element.isFinalStep === true && element.isMigrated === false && element.sourceType === 1) && (
                        <div style={{ marginRight: '6px' }}>
                          <Button style={{ width: '103px' }}>
                            <a
                              className="text-nowrap white text-decoration-none hover-white"
                              style={{}}
                              href={editLink(element, false)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>Detalii</span>
                            </a>
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {currentElements.length === 0 && props.error === '' && (
          <Row className="m-3 text-center">
            <p>Momentan nu există cereri pentru această utilitate.</p>
          </Row>
        )}
      </div>
      {currentElements.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalElements={props.list.length}
          perPage={perPage}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      )}
      {renderModal()}

      <ModalComponent
        show={showModalPayInvoices}
        title={'Facturi'}
        fullScreen="sm-down"
        size="xl"
        setShow={(value) => {
          setShowModalPayInvoices(value);
          if (!value) removeQueryParam('pay_invoices_request_id');
        }}
        backdropStatic
      >
        <PayInvoices showTotalToPay requestId={requestIdToPayInvoices} fetchInvoices={true} utility={utility === 'EE' ? 1 : 2} />
      </ModalComponent>
    </div>
  );
};

export default ShowRequests;
