import { Dispatch } from 'redux';

import { AuthPayload } from '@reducers/auth';
import { BEARER, MRSV_AUTH_TOKEN } from '@constants/Utils';
import { AUTH_USER, CLEANUP_AUTH } from '@constants/ActionTypes/PostTypes';

export function setAuthData(data?: AuthPayload) {
  if (data && data.token && `${data.tokenName}`.toLowerCase() === `${BEARER}`.toLowerCase()) {
    data.token = `${BEARER} ${data.token}`;
    data.tokenName = MRSV_AUTH_TOKEN;
  }

  return {
    type: AUTH_USER,
    payload: { ...data } as AuthPayload
  };
}

export function cleanUpAuth(payload?: Array<keyof AuthPayload>) {
  return (dispatch: Dispatch) => dispatch({ type: CLEANUP_AUTH, payload });
}
