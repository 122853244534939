import React from 'react';
import { Loader } from '@components/global/Loader';
// import { AemWrapper, Toastr, Loader, Modal, ReadSource, Settings } from '@components/global';
import { GlobalSettingsKentico } from '@type/kentico-types';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { Settings } from '@components/global/Options/Settings';

export type GlobalCompProps = {
  kentico: GlobalSettingsKentico;
};

export class GlobalComp extends React.Component<GlobalCompProps> {
  render() {
    return (
      <DelgazWrapper>
        <Loader />
        <Settings {...this.props}/>
      </DelgazWrapper>
    );
  }
}
