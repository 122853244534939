import Configuration from '@kentico-json/global/backOffice/posf.json';

type BackOffice = typeof Configuration;

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type RowAction = PartialBy<BackOffice['rowActions'][number], 'logicalConditionFieldName'>;
export type DisplayGroup = BackOffice['displayGroups'][number];

export enum DataTypes {
  String = 1,
  Int = 2,
  IntRange = 3,
  IntRangeFrom = 4,
  IntRangeTo = 5,
  Date = 6,
  DateTime = 7,
  DateRange = 8,
  DateRangeFrom = 9,
  DateRangeTo = 10,
  Dropdown = 11,
  StringList = 12,
  LocalitiesDropdown = 13,
  Url = 14,
  Bool = 15,
  HiddenString = 16,
  HiddenInt = 17,
  HiddenBool = 18,
  HTML = 20,
  ReadonlyString = 21,
  ReadonlyJson = 22,
  ReadonlyInt = 23,
  ReadonlyDate = 24,
  ReadonlyDateTime = 25,
  UrlList = 26,
  StringListEdit = 27,
  DropdownInt = 28,
  DropdownString = 29,
  Empty = 30,
  Base64File = 31,
  Base64FileList = 32,
  MultiSelectDropdownInt = 33,
  MultiSelectDropdownString = 34,
  DropdownIntSL = 35,
  GroupedFieldsList = 36,
  TableFieldsList = 37,
  FrontEndDataViewer = 38,
  YearMonthSelector = 40
}

export enum ActionTypes {
  ModalDetails = 'ModalDetails',
  ModalForm = 'ModalForm',
  CustomAction = 'CustomAction'
}

export enum CustomActionTypes {
  Timeline = 'Timeline',
  RSS = 'FrontEndImplementation'
}

export type DetailActionResponse = {
  [key: string]: string | any;
};

export enum ModalSize {
  Small = 1,
  Medium = 2,
  Large = 3,
  FullScreen = 4
}
