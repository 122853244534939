import React, { Fragment } from 'react';
import { ValueToSet } from '../PageWrapper';
import { Input } from '@components/common/Input';
import { Button, Row, Col } from 'react-bootstrap';

type StringListEditProps = {
  colspan: number;
  values: string[];
  name: string;
  label: string;
  itemTypeId: number;
  onChange?: (e: ValueToSet) => void;
};

const StringListEdit = ({ colspan, values, name, label, onChange, itemTypeId }: StringListEditProps) => {
  const saveChanges = (newValues: string[]) => {
    onChange && onChange({ name: name, value: newValues, itemTypeId } as ValueToSet);
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValues = [...values.slice(0, index), event.target.value as string, ...values.slice(index + 1)];
    saveChanges(newValues);
  };
  const deleteInput = (index: number) => {
    if (values.length > 1) {
      const stringListCopy = [...values];
      stringListCopy.splice(index, 1);
      saveChanges(stringListCopy);
    }
  };

  const addInput = () => {
    const newValues = [...values, ''] as string[];
    saveChanges(newValues);
  };

  return (
    <Fragment>
      <Col md={colspan == 2 ? 12 : 6}>
        {values
          ? values.map((str, index) => {
              const displayedIndex: number = index + 1;
              return (
                <Row key={index}>
                  <div className="d-flex flex-row">
                    <Input
                      name={name}
                      value={values[index]}
                      label={label + ' ' + displayedIndex}
                      isUsedInBackOffice={true}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onValueChange(event, index);
                      }}
                    />

                    <span style={{ minWidth: '90px' }}>
                      <Button
                        className="btn mt-2"
                        style={{ minWidth: '33px' }}
                        onClick={(e) => {
                          deleteInput(index);
                        }}
                      >
                        -
                      </Button>
                      {index === values?.length - 1 ? (
                        <Button
                          className="btn mt-2 ms-2"
                          style={{ minWidth: '33px' }}
                          onClick={(e) => {
                            addInput();
                          }}
                        >
                          +
                        </Button>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </Row>
              );
            })
          : ''}
      </Col>
    </Fragment>
  );
};

export default StringListEdit;
