import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BusinessCardKentico } from '@type/kentico-types';

export const BusinessCardComponent = (props: { kentico: BusinessCardKentico }) => {
    const renderBusinessCard = (item: any) => {
        return (
            <div
                className="business-card"
                style={
                    {
                        maxWidth: '425px',
                        width: '100%',
                        background: `${item.culoare}`,
                        color: `${(item.culoare === '#ea1b0a' || item.culoare === 'red') ? 'white' : 'black' }`,
                        padding: '18px'
                    }
                }
            >
                <h4 style={{margin: '0', marginBottom: '5px', fontSize: '29px', fontWeight: 'bold'}}>{item.nume}</h4>
                <p style={{marginBottom: '15px', fontSize: '18px'}}>{item.functie}</p>
                {item.telefon !== '' && item.telefon !== null && <div className="d-flex flex-row" style={{marginBottom: '5px'}}>
                    <FontAwesomeIcon
                        icon={faPhone}
                        style={{width: '20px', paddingTop: '3px', marginRight: '8px'}}
                        color={(item.culoare === '#ea1b0a' || item.culoare === 'red') ? 'white' : '#ea1b0a'}
                    />
                    <p style={{margin: '0', maxWidth: '75%', fontSize: '18px'}}>{item.telefon}</p>
                </div>}
                {item.email !== '' && item.email !== null && <div className="d-flex flex-row">
                    <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{width: '20px', paddingTop: '3px', marginRight: '8px'}}
                        color={(item.culoare === '#ea1b0a' || item.culoare === 'red') ? 'white' : '#ea1b0a'}
                    />
                    <p style={{margin: '0', maxWidth: '75%', fontSize: '18px'}}>{item.email}</p>
                </div>}
            </div>
        );
    };

    return (
        <div className={`m-${props.kentico.margin != null ? props.kentico.margin : '5'}`}>
            <div className="business-card-container">
                {renderBusinessCard(props.kentico)}
            </div>
        </div>
    );
};