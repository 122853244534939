import React, { useState } from 'react';
import { ResourcesKentico } from '@type/kentico-types';
import { Row } from 'react-bootstrap';
import { RxDownload } from "react-icons/rx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

export const RedesignedResourcesComp = (props: { kentico: ResourcesKentico }) => {
  const [expanded, setExpanded] = useState(false);
  const contentRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const renderResourceItem = (item: any) => {
    return (
      <div className="redesigned-resources">
        <h6 className="rr-title">{item.title}</h6>
        <div
          className="r-resource-content"
          ref={contentRef}
        >
          {item.links.map((link: any, index: any) => (
            <div className="r-resource-link" key={index}>
              <a href={link.link} key={index} className="rr-link">
                <div className="rr-link-icon">
                  <FontAwesomeIcon icon={faArrowDown} className="ad-icon" />
                </div>
                {link.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="m-5">
      <div className="resources-container">{renderResourceItem(props.kentico)}</div>
    </div>
  );
};