import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RSSFurnizor from '@components/rss/requests/RSSFurnizor';

export const AfisareRSS = () => {
    return (
        <DelgazWrapper>
            <RSSFurnizor />
        </DelgazWrapper>
    );
};