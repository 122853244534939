import { TOGGLE_LOADING_SCREEN, SET_CONTENT_LOADING_SCREEN } from '@constants/ActionTypes/PostTypes';
import { Action } from '.';
import { Reducer } from 'redux';

export class LoaderPayload {
  isOpen? = false;
  message?: string;
  loaderIsOpen: boolean[] = [];
  activeLoaders: string[] = [];
  key?: string = '';
}

const initialState = new LoaderPayload();

export const loader: Reducer<LoaderPayload, Action<LoaderPayload>> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_LOADING_SCREEN: {
      const loaderIsOpen = [...(state && state.loaderIsOpen)];

      const { key } = payload as any;
      const isOpen = !Array.isArray(payload) && payload && payload.isOpen;
      let activeLoaders = state.activeLoaders ? [...state.activeLoaders] : [];

      if (!Array.isArray(payload) && payload && payload.isOpen) loaderIsOpen.push(payload.isOpen);
      else loaderIsOpen.pop();

      if (key) {
        if (isOpen) {
          activeLoaders.push(key);
        } else {
          activeLoaders = activeLoaders.filter((l) => l != key);
        }
      }

      return { ...state, ...payload, loaderIsOpen, activeLoaders };
    }
    case SET_CONTENT_LOADING_SCREEN: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
