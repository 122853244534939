import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import GroupMapComponent from '@components/dev/Components/GroupMap/GroupMapComponent';
import {GroupMapKentico} from '@type/kentico-types';

export type GroupMapProps = { kentico: GroupMapKentico };

export const GroupMap = (props: GroupMapProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <GroupMapComponent {...props}/>
        </DelgazWrapper>
    );
};