import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { registerRSS } from '@api/rss/rss';
import {
    LoadCanvasTemplateNoReload,
    loadCaptchaEngine,
    validateCaptcha
} from '@components/auth/createAccount/CaptchaLib';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import InputTextArea from '@components/common/Input/InputTextArea';
import MaskedInput from '@components/common/Input/MaskedInput';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import DatePF from '@components/rss/formularContact/forms/DatePF';
import DatePJ from '@components/rss/formularContact/forms/DatePJ';
import { faChevronLeft, faCircleInfo, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ContactPF, ContactPJ } from '../../../helpers/schemas';

const PFValues = {
    cnp: '',
    nume: '',
    prenume: '',
    telefon: '',
    email: '',
    judet: '',
    localitate: '',
    strada: '',
    numar: '',
    codPostal: '',
};

const PJValues = {
    cui: '',
    numeCompanie: '',
    reprezentant: '',
    telefon: '',
    email: '',
    judet: '',
    localitate: '',
    strada: '',
    numar: '',
    codPostal: '',
};

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const StepTwo = (props: {category: any, subject: any, subSubject: any, handleStep: any, handleSuccessModal: any}) => {
    const [PFFormData, setPFFormData] = useState(JSON.parse(JSON.stringify(PFValues)));
    const [PJFormData, setPJFormData] = useState(JSON.parse(JSON.stringify(PJValues)));
    const [clientType, setClientType] = useState<number>(1);
    const [requestType, setRequestType] = useState<string | null>(null);
    const [requestTypeError, setRequestTypeError] = useState<string>('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState<string>('');
    const [fileList, setFileList] = useState<File[]>([] as File[]);
    const [racordat, setRacordat] = useState(true);
    const [racordatError, setRacordatError] = useState<string>('');
    const [pod, setPod] = useState('');
    const [podError, setPodError] = useState<string>('');
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    const [accord1, setAccord1] = useState(false);
    const [accord2, setAccord2] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [captchaError, setCaptchaError] = useState('');
    // Limita link-uri in mesaj
    //eslint-disable-next-line
    const [messageLinkCounter, setMessageLinkCounter] = useState(3);

    const { category, subject, subSubject, handleStep, handleSuccessModal } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        GetCounties().then(res => setCounties(res));
        loadCaptchaEngine('6', 'white', 'black', '', 'contact_form');
    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    const handleClientType = (type: number) => {
        setPFFormData(JSON.parse(JSON.stringify(PFValues)));
        setPJFormData(JSON.parse(JSON.stringify(PJValues)));
        setClientType(type);
    };

    const handleRequestType = (type: string) => {
        setRequestType(type);
    };

    const handleFileListChange = (list: File[]) => {
        setFileList(list);
    };

    const handlePFForm = (data: any, field: any) => {
        let newData = PFFormData;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setPFFormData(newData);
    };

    const handlePJForm = (data: any, field: any) => {
        let newData = PJFormData;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setPJFormData(newData);
    };

    const handleRacordat = (value: boolean) => {
        setRacordat(value);
    };

    // const handlePod = (value: string) => {
    //     setPod(value);
    // };

    const filePayload = () => {
        const files: any[] = [];

        fileList.map((file: File) => {
            let add = {
                NumeDocument: file.name,
                ContinutDocument: file.base64.split(',')[1]
            };

            files.push(add);
        });

        return files;
    };

    const searchForLinks = (text: string) => {
        return  (text.match(/http/g) || []).length + (text.match(/www./g) || []).length + (text.match(/<a>/g) || []).length;
    };

    const submit = (values: any) => {
        setRequestTypeError('');
        setMessageError('');
        setRacordatError('');
        setPodError('');
        setValidationError('');
        setSuccess('');
        setError('');
        setCaptchaError('');

        const atributeGenerice = {
            IdExtern: uuidv4(),
            Scenariu: racordat ? 'RSS_LOCCONSUM_ALTFURNIZOR' : 'RSS_LOCCONSUM_NO_POD',
            DocumenteSolicitare: filePayload()
        };

        const atributeSpecificeScenariului = {
            Judet: '',
            Localitate: '',
            CodJudet: values.judet,
            CodLocalitate: values.localitate,
            TipAdresa: requestType,
            CategorieSubiect: category,
            Subiect: subject,
            SubSubiect: subSubject,
            CNP: values.cnp || '',
            CUI: values.cui || '',
            Telefon: values.telefon,
            Descriere: message,
            AcordPrelucrareDateClient: 'DA',
            Utilitate: 'EE',
            Strada: values.strada,
            Numar: values.numar,
            CodPostal: values.codPostal,
            Email: values.email,
            Nume: values.nume || '',
            Prenume: values.prenume || '',
            NumeCompanie: values.denumire || '',
            NumeReprezentantLegal: values.reprezentant || '',
            ImobilRacordat: racordat,
            IsCompany: clientType == 2 ? true : false,
            ...(racordat && { POD: pod })
        };

        const payload = {
            atributeGenerice,
            atributeSpecificeScenariului
        };

        let wasValidated = true;
        let user_captcha = (document.getElementById('user_captcha_input') as HTMLInputElement).value;

        if (validateCaptcha(user_captcha, true, 'contact_form') === false) {
            setCaptchaError('Codul de securitate nu este valid.');
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
        }

        if (requestType === null) {
            setRequestTypeError('Acest câmp este obligatoriu.');
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
        }

        if (message === '') {
            setMessageError('Acest câmp este obligatoriu.');
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
        }

        if (message.length > 2000) {
            setMessageError('Ați depășit numărul de caractere permis!');
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
        }

        if (searchForLinks(message) > messageLinkCounter) {
            setMessageError('Ați introdus prea multe link-uri în mesaj!');
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
        }

        if (racordat && pod === '') {
            setRacordatError('Acest câmp este obligatoriu.');
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
        }

        if (category === 'AS') {
            if (pod.length !== 10 && racordat === true) {
                setPodError('Câmpul pod trebuie să fie de forma EMOXXXXXXX sau DEGXXXXXXX.');
                wasValidated = false;
            }
        }

        if (wasValidated && validateCaptcha(user_captcha, true, 'contact_form') === true) {
            apiCall(payload);
        }
    };

    const clearData = () => {
        handleClientType(2);
        handleClientType(1);
        setRequestType(null);
        setMessage('');
        setFileList([]);
        setRacordat(true);
        setPod('');
        setAccord1(false);
        setAccord2(false);
        (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
    };

    const apiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await registerRSS(payload, 'ContactForm')
            .then((response) => {
                if (response && response.data && response.data.status === 'Succes' && response.data.rssId && response.data.rssId !== 0) {
                    handleSuccessModal(true);
                    handleStep(1);
                    clearData();
                } else if (response && response.data && response.data.locuriDeConsumInvalide && response.data.locuriDeConsumInvalide.length > 0) {
                    setPodError('Locul de consum nu a fost găsit.');
                } else {
                    setError('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.');
                }
            })
            .catch((err) => setError('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    const renderCaptcha = () => {
        return (
            <div className="d-flex flex-column mb-2">
                <Row className="w-100 mt-3">
                    <Col sm={12} md={6}>
                        <div className="d-flex">
                            <LoadCanvasTemplateNoReload id='contact_form' />
                            <FontAwesomeIcon
                                icon={faRotate}
                                style={{width: '18px', height: '18px', cursor: 'pointer', color: '#ea1c0a'}}
                                onClick={() => loadCaptchaEngine('6', 'white', 'black', '', 'contact_form')}
                            />
                        </div>
                        <div><input placeholder="Cod securitate" id="user_captcha_input" name="user_captcha_input" type="text" /></div>
                        {captchaError !== '' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{captchaError}</div>}
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div>
            <div className="mb-2">
                <Button onClick={() => handleStep(1)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>&nbsp;Înapoi</span>
                </Button>
            </div>
            <Formik
                initialValues={clientType === 1 ? PFValues : PJValues}
                enableReinitialize={true}
                validationSchema={clientType === 1 ? ContactPF : ContactPJ}
                onSubmit={(values) => submit(values)}
            >
                {(props) => (
                    <Form>
                        <h4 className="red">Date de identificare</h4>
                        <Row className="p-3">
                            <ChoiceComponent
                                options={[{title: 'Persoană fizică', id: 1}, {title: 'Persoană juridică', id: 2}]}
                                onChange={handleClientType}
                                value={clientType}
                                mZero
                            />
                        </Row>

                        { clientType === 1 && <DatePF props={props} handleData={handlePFForm}/> }
                        { clientType === 2 && <DatePJ props={props} handleData={handlePJForm}/> }

                        <p className="fst-italic mt-4 mb-4">Îți respectăm viața privată, prin urmare datele tale personale vor fi prelucrate în conformitate cu prevederile legale privind protectia datelor cu caracter personal. Subliniem faptul că aceste  informaţii vor fi utilizate strict pentru a te identifica în mod corect în baza noastră de date în vederea soluționării cererii tale.</p>

                        <h4 className="red mt-4 mb-4">Mesajul tău</h4>
                        <span>Alege tipul interacțiunii:*</span>
                        <Row className="mt-4 mb-4 justify-content-between">
                            <Col>
                                <Row className="d-flex">
                                    <span className="d-flex justify-content-center mb-2" onClick={() => handleRequestType('Solicitare')}>
                                        <input
                                            type="radio"
                                            style={{marginRight: '5px'}}
                                            checked={requestType === 'Solicitare'}
                                            onChange={() => handleRequestType('Solicitare')}
                                        />
                                        Solicitare
                                        <Tippy
                                            content={
                                                <div className="p-3" style={{background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px'}}>
                                                    <b>Solicitare</b> - petiție prin intermediul căreia soliciți informații și/sau prestarea unor servicii ce țin de activitatea noastră, a operatorului de distribuție.
                                                </div>
                                            }
                                            duration={0}
                                            placement="bottom"
                                        >
                                            <div className="icon-div" style={{marginLeft: '5px'}}>
                                                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red"/>
                                            </div>
                                        </Tippy>
                                    </span>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="d-flex">
                                    <span className="d-flex justify-content-center" onClick={() => handleRequestType('Sesizare')}>
                                        <input
                                            type="radio"
                                            style={{marginRight: '5px'}}
                                            checked={requestType === 'Sesizare'}
                                            onChange={() => handleRequestType('Sesizare')}
                                        />
                                        Sesizare
                                        <Tippy
                                            content={
                                                <div className="p-3" style={{background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px'}}>
                                                    <b>Sesizare</b> - petiție prin intermediul căreia sesizezi diferite aspecte, nereguli legate de activitatea operatorului de distribuție.
                                                </div>
                                            }
                                            duration={0}
                                            placement="bottom"
                                        >
                                            <div className="icon-div" style={{marginLeft: '5px'}}>
                                                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red"/>
                                            </div>
                                        </Tippy>
                                    </span>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="d-flex">
                                    <span className="d-flex justify-content-center" onClick={() => handleRequestType('Reclamatie')}>
                                        <input
                                            type="radio"
                                            style={{marginRight: '5px'}}
                                            checked={requestType === 'Reclamatie'}
                                            onChange={() => handleRequestType('Reclamatie')}
                                        />
                                        Reclamație
                                        <Tippy
                                            content={
                                                <div className="p-3" style={{background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px'}}>
                                                    <b>Reclamație</b> - petiție prin intermediul căreia îți manifești nemulțumirea față de serviciul prestat și soliciți o explicație sau remedierea situației.
                                                </div>
                                            }
                                            duration={0}
                                            placement="bottom"
                                        >
                                            <div className="icon-div" style={{marginLeft: '5px'}}>
                                                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red"/>
                                            </div>
                                        </Tippy>
                                    </span>
                                </Row>
                            </Col>
                            { requestTypeError !== '' && <p className="fw-bold red">{requestTypeError}</p> }
                        </Row>

                        <div className="mb-4">
                            <InputTextArea
                                label="Mesaj*"
                                value={message}
                                placeholder='Scrie-ne cât mai detaliat cum anume te putem ajuta. În situatia în care subiectul nu este clar, lasă-ne numărul de telefon pentru a avea posibiltatea de a te contacta.'
                                onChange={(e) => setMessage(e.target.value)}
                                mbZero
                            />
                            <span className="property-label mb-3" style={{fontSize: '14px'}}>Maxim 2000 de caractere.</span>
                            { messageError !== '' && <p className="fw-bold red">{messageError}</p> }
                        </div>
                        <span>În câmpul de mai jos atașează documentele pe care dorești să ni le transmiți:</span>
                        <div className="p-2">
                            <UploadFile onSetFileList={handleFileListChange} fileList={fileList} index={0} acceptedExtensionsError={'Sunt acceptate următoarele formate de fișiere: '}/>
                        </div>
                        <span className="property-label" style={{fontSize: '14px'}}>Se acceptă documente în format BMP, GIF, JPG și PNG, fișiere .doc., pdf. si xls.</span>
                        <br></br>
                        <span className="property-label" style={{fontSize: '14px'}}>Documentele nu trebuie să depășească limita maximă de 10MB/fisier.</span>

                        <h4 className="red mt-4">Date de identificare ale locului de consum</h4>
                        <div>
                            <ChoiceComponent
                                options={[{title: 'Da', id: true}, {title: 'Nu', id: false}]}
                                onChange={handleRacordat}
                                value={racordat}
                                subtitle={'Imobilul este racordat la sistemul de distribuție Delgaz Grid?*'}
                            />
                        </div>
                        {racordat && <Fragment>
                            <Row>
                                <Col xs={12} sm={6}>
                                    {category === 'EE' && <MaskedInput
                                        value={pod}
                                        onChange={(e) => setPod(e.target.value)}
                                        utility={'EE'}
                                        mask={'0123333333'}
                                        label='Punct de măsurare (POD)*'
                                        mbZero
                                    />}
                                    {category === 'GN' && <MaskedInput
                                        value={pod}
                                        onChange={(e) => setPod(e.target.value)}
                                        utility={'GN'}
                                        mask={'0123333333'}
                                        label='Punct de măsurare (POD)*'
                                        mbZero
                                    />}
                                    {category === 'AS' && <Input
                                        value={pod}
                                        onChange={(e) => setPod(e.target.value)}
                                        label='Punct de măsurare (POD)*'
                                        mbZero
                                    />}
                                </Col>
                            </Row>
                            <span>Aceste informații le găsești pe factura primită de la furnizorul tău de energie.</span>
                            { podError !== '' && <p className="fw-bold red mb-0">{podError}</p> }
                            { racordatError !== '' && <p className="fw-bold red">{racordatError}</p>}
                        </Fragment>}
                        <Row className="mt-4">
                            <Col xs={12} sm={6} className="mb-3">
                                <Dropdown
                                    options={counties.map((option, index) => (
                                        {
                                            id: option.countyCode,
                                            name: option.countyName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Județ*'}
                                    defaultPlaceholder={'Alege județul'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        if (clientType === 1) {
                                            handlePFForm('judet', value);
                                        } else {
                                            handlePJForm('judet', value);
                                        }

                                        props.setFieldValue('judet', value);
                                        setCounty(value);
                                    }}
                                    value={props.values.judet}
                                    mbZero
                                />
                                <ErrorMessage name="judet" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Dropdown
                                    options={localities.map((option, index) => (
                                        {
                                            id: option.localityCode,
                                            name: option.localityName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Localitate*'}
                                    defaultPlaceholder={'Alege localitatea'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        if (clientType === 1) {
                                            handlePFForm('localitate', value);
                                        } else {
                                            handlePJForm('localitate', value);
                                        }

                                        props.setFieldValue('localitate', value);
                                    }}
                                    value={props.values.localitate}
                                    mbZero
                                />
                                <ErrorMessage name="localitate" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={4} className="mb-3">
                                <Field label='strada' id="strada" name="strada" placeholder="Stradă*">
                                    {({
                                          //@ts-ignore
                                          meta: {touched, error}
                                      }) =>
                                        <Input
                                            mbZero
                                            value={props.values.strada}
                                            label='Stradă*'
                                            onBlur={() => props.setFieldTouched('strada')}
                                            onChange={(e: any) => {
                                                if (clientType === 1) {
                                                    handlePFForm(e.target.value, 'strada');
                                                } else {
                                                    handlePJForm(e.target.value, 'strada');
                                                }
                                                props.setFieldValue('strada', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="strada" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col xs={12} sm={4} className="mb-3">
                                <Field label='numar' id="numar" name="numar" placeholder="Număr*">
                                    {({
                                          //@ts-ignore
                                          meta: {touched, error}
                                      }) =>
                                        <Input
                                            mbZero
                                            value={props.values.numar}
                                            label='Număr*'
                                            onBlur={() => props.setFieldTouched('numar')}
                                            onChange={(e: any) => {
                                                if (clientType === 1) {
                                                    handlePFForm(e.target.value, 'numar');
                                                } else {
                                                    handlePJForm(e.target.value, 'numar');
                                                }
                                                props.setFieldValue('numar', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="numar" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col xs={12} sm={4}>
                                <Field label='codPostal' id="codPostal" name="codPostal" placeholder="Cod poștal*">
                                    {({
                                          //@ts-ignore
                                          meta: {touched, error}
                                      }) =>
                                        <Input
                                            mbZero
                                            value={props.values.codPostal}
                                            label='Cod poștal*'
                                            onBlur={() => props.setFieldTouched('codPostal')}
                                            onChange={(e: any) => {
                                                if (clientType === 1) {
                                                    handlePFForm(e.target.value, 'codPostal');
                                                } else {
                                                    handlePJForm(e.target.value, 'codPostal');
                                                }
                                                props.setFieldValue('codPostal', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="codPostal" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                        </Row>

                        <Row className="mt-4 mb-4">
                            <span>Completarea formularului de mai sus presupune faptul ca ești îndreptățit să folosești datele comunicate.  Dacă nu ești destinatarul datelor solicitate te informăm că orice divulgare, copiere, distribuire sau luarea de măsuri în dependență de conţinutul acestor informaţii realizata cu rea credinţă este strict interzisă şi poți suporta consecinţele legale.</span>
                        </Row>

                        <Row className="mt-3 mb-3">
                            <Col>
                                <input type="checkbox" checked={accord1} onChange={() => setAccord1(!accord1)}/>
                                <span onClick={() => setAccord1(!accord1)} style={{marginLeft: '5px'}}>Sunt de acord cu </span><a className="red hover-red pointer" href="https://delgaz.ro/conditii-de-utilizare">Conditiile de utilizare ale site-ului.</a>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col>
                                <input type="checkbox" checked={accord2} onChange={() => setAccord2(!accord2)}/>
                                <span  onClick={() => setAccord2(!accord2)} style={{marginLeft: '5px'}}>Am luat cunoștință de informațiile privind </span><a className="red hover-red pointer" href="https://delgaz.ro/protectia-datelor">Protecția și confidențialitatea datelor.</a>
                            </Col>
                        </Row>

                        {renderCaptcha()}

                        { validationError !== '' && <p className="red fw-bold">{validationError}</p> }
                        { error !== '' && <p className="red fw-bold">{error}</p> }
                        { success !== '' && <p className="red fw-bold">{success}</p> }

                        <Row className="justify-content-center mt-4">
                            <Button className="w-auto" type="submit" disabled={!accord1 || !accord2}>Trimite</Button>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default StepTwo;