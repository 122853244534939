import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Input } from '@components/common/Input';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConsultantSchema } from '../../../helpers/schemas';

const Consultant = (props: { isEditing: any, formData: any, handleFormData: any, innerRef: any }) => {
    const { isEditing, formData, handleFormData, innerRef } = props;

    return (
        <Row className="mt-2">
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                onSubmit={(values) => (values)}
                innerRef={innerRef}
                validateOnMount={true}
                validationSchema={ConsultantSchema}
            >
                {(props) => (
                    <Form>
                        <Row>
                            <Col className="mb-3" xs={12} sm={3}>
                                <Field label='nume_consultant' id="nume_consultant" name="nume_consultant" placeholder="Nume consultant">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            value={formData.nume_consultant}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'nume_consultant');
                                                props.setFieldValue('nume_consultant', e.target.value);
                                            }}
                                            label={'Nume consultant'}
                                            disabled={isEditing === false}
                                            mbZero
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="nume_consultant" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={3}>
                                <Field label='prenume_consultant' id="prenume_consultant" name="prenume_consultant" placeholder="Prenume consultant">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            value={formData.prenume_consultant}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'prenume_consultant');
                                                props.setFieldValue('prenume_consultant', e.target.value);
                                            }}
                                            label={'Prenume consultant'}
                                            disabled={isEditing === false}
                                            mbZero
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="prenume_consultant" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={3}>
                                <Field label='telefon_consultant' id="telefon_consultant" name="telefon_consultant" placeholder="Telefon consultant">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            value={formData.telefon_consultant}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'telefon_consultant');
                                                props.setFieldValue('telefon_consultant', e.target.value);
                                            }}
                                            label={'Telefon consultant'}
                                            disabled={isEditing === false}
                                            mbZero
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="telefon_consultant" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={3}>
                                <Field label='email_consultant' id="email_consultant" name="email_consultant" placeholder="Email consultant">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            value={formData.email_consultant}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'email_consultant');
                                                props.setFieldValue('email_consultant', e.target.value);
                                            }}
                                            label={'Email consultant'}
                                            disabled={isEditing === false}
                                            mbZero
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="email_consultant" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Row>
    );
};

export default Consultant;