import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {AdCardGoogleMaps} from '@kentico/global/AdCard/AdCardGoogleMaps';
import { AdCardGoogleMapsKentico } from '@type/kentico-types';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const AdCardMapButtonComponent = (props: {kentico: AdCardGoogleMapsKentico}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <FontAwesomeIcon className="marker-icon" icon={faMapMarkerAlt} onClick={handleShow}/>
            <div className="modal-container">
                <Modal show={show} onHide={handleClose} dialogClassName="ad-card-modal">
                    <Modal.Body className="ad-card-modal-body">
                        <AdCardGoogleMaps kentico={{latitude: props.kentico.latitude, longitude: props.kentico.longitude}} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Inchide
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default AdCardMapButtonComponent;