import { AUTH_USER, CLEANUP_AUTH } from '@constants/ActionTypes/PostTypes';
import { Action } from '.';

export class AuthPayload {
  email?: string;
  token?: string;
  tokenName?: string;
  expireAt?: number = 0;
  mylineAccountExist?: boolean;
  mylineDetails?: string;
  mylineError?: string;
  redirectTo?: string;
  fbCode?: string;
  appleCode?: string;
  appleUser?: { [key: string]: string | undefined };
  salesFlow?: boolean = false;
}

const initialState = new AuthPayload();

export function auth(state = initialState, action = {} as Action<AuthPayload>) {
  const { type, payload } = action;

  switch (type) {
    case AUTH_USER: {
      return { ...state, ...payload };
    }
    case CLEANUP_AUTH: {
      const stateToKeep = new AuthPayload();

      if (Array.isArray(payload)) {
        payload.forEach((key) => (stateToKeep[key] = state[key] as keyof AuthPayload[typeof key]));
      }

      return { ...initialState, ...stateToKeep };
    }
    default:
      return state;
  }
}
