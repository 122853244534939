import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const DatePFInPortofolio = (props: any) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    // const [locality, setLocality] = useState<number | null>(null);

    // const [selectedCounty, setSelectedCounty] = useState('');
    // const [selectedLocality, setSelectedLocality] = useState(0);
    
    //eslint-disable-next-line
    const { formData, handleData } = props;
    let formikProps = props.props;

    const dispatch = useDispatch();

    useEffect(() => {
        mountCounties();
    }, []);


    useEffect(() => {
        county !== '' && mountLocalities();
    }, [county]);

    useEffect(() => {
        setCounty(formikProps.values.county);
    }, [formikProps.values]);

    const mountCounties = async () => {
        dispatch(toggleLoader(true));

        await GetCounties().then(res => {
            setCounties(res);
        }).catch(err => console.log(err));

        dispatch(toggleLoader(false));
    };

    const mountLocalities = async () => {
        dispatch(toggleLoader(true));

        await GetLocalities(county).then((res) => setLocalities(res)).catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    return (
        <div className="mt-4">
            <Row>
                <Col xs={12} sm={6} className="mb-3">
                    <Field label='Nume' id="lastName" name="lastName" placeholder='Nume'>
                        {({
                            //@ts-ignore
                            meta: { touched, error }
                        }) =>
                            <Input
                                mbZero
                                value={formikProps.values.lastName || ''}
                                label='Nume'
                                onBlur={() => formikProps.setFieldTouched('lastName')}
                                onChange={(e: any) => {
                                    formikProps.setFieldValue('lastName', e.target.value);
                                    handleData(e.target.value, 'lastName');
                                }}
                                disabled
                            />
                        }
                    </Field>
                    <ErrorMessage name="lastName" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Field label='Nume' id="firstName" name="firstName" placeholder='Prenume'>
                        {({
                            //@ts-ignore
                            meta: { touched, error }
                        }) =>
                            <Input
                                mbZero
                                value={formikProps.values.firstName || ''}
                                label='Prenume'
                                onBlur={() => formikProps.setFieldTouched('firstName')}
                                onChange={(e: any) => {
                                    formikProps.setFieldValue('firstName', e.target.value);
                                    handleData(e.target.value, 'firstName');
                                }}
                                disabled
                            />
                        }
                    </Field>
                    <ErrorMessage name="firstName" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className="mb-3">
                    <Field label='Telefon' id="phone" name="phone" placeholder='Telefon*'>
                        {({
                            //@ts-ignore
                            meta: { touched, error }
                        }) =>
                            <Input
                                mbZero
                                value={formikProps.values.phone || ''}
                                label='Telefon*'
                                onBlur={() => formikProps.setFieldTouched('phone')}
                                onChange={(e: any) => {
                                    formikProps.setFieldValue('phone', e.target.value);
                                    handleData(e.target.value, 'phone');
                                }}
                            />
                        }
                    </Field>
                    <ErrorMessage name="phone" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Field label='Email' id="email" name="email" placeholder='Email'>
                        {({
                            //@ts-ignore
                            meta: { touched, error }
                        }) =>
                            <Input
                                mbZero
                                value={formikProps.values.email || ''}
                                label='Email'
                                onBlur={() => formikProps.setFieldTouched('email')}
                                onChange={(e: any) => {
                                    formikProps.setFieldValue('email', e.target.value);
                                    handleData(e.target.value, 'email');
                                }}
                                disabled
                            />
                        }
                    </Field>
                    <ErrorMessage name="email" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xs={12} sm={6} className="mb-3">
                    <Dropdown
                        options={counties.map((option, index) => (
                            {
                                id: option.countyCode,
                                name: option.countyName
                            }
                        )) as DropdownOptionType[]}
                        label={'Județ'}
                        defaultPlaceholder={'Alege județul'}
                        displaySearch={true}
                        onChange={(value) => {
                            value && formikProps.setFieldValue('county', value);
                            value && handleData(value, 'county');
                            value.length === 0 && formikProps.setFieldValue('county', '');
                            setCounty(value);
                        }}
                        value={formikProps.values.county}
                        mbZero
                        disabled
                    />
                    <ErrorMessage name="county" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Dropdown
                        options={localities.map((option, index) => (
                            {
                                id: option.localityCode,
                                name: option.localityName
                            }
                        )) as DropdownOptionType[]}
                        label={'Localitate'}
                        defaultPlaceholder={'Alege localitatea'}
                        displaySearch={true}
                        onChange={(value) => {
                            value && formikProps.setFieldValue('locality', value);
                            value && handleData(value, 'locality');
                            value.length === 0 && formikProps.setFieldValue('locality', '');
                        }}
                        value={formikProps.values.locality}
                        mbZero
                        disabled
                    />
                    <ErrorMessage name="locality" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={4} className="mb-3">
                    <Field label='Stradă' id="street" name="street" placeholder='Stradă'>
                        {({
                            //@ts-ignore
                            meta: { touched, error }
                        }) =>
                            <Input
                                mbZero
                                value={formikProps.values.street || ''}
                                label='Stradă'
                                onBlur={() => formikProps.setFieldTouched('street')}
                                onChange={(e: any) => {
                                    formikProps.setFieldValue('street', e.target.value);
                                    handleData(e.target.value, 'street');
                                }}
                                disabled
                            />
                        }
                    </Field>
                    <ErrorMessage name="street" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
                <Col xs={12} sm={4} className="mb-3">
                    <Field label='Număr' id="number" name="number" placeholder='Număr'>
                        {({
                            //@ts-ignore
                            meta: { touched, error }
                        }) =>
                            <Input
                                mbZero
                                value={formikProps.values.number || ''}
                                label='Număr'
                                onBlur={() => formikProps.setFieldTouched('number')}
                                onChange={(e: any) => {
                                    formikProps.setFieldValue('number', e.target.value);
                                    handleData(e.target.value, 'number');
                                }}
                                disabled
                            />
                        }
                    </Field>
                    <ErrorMessage name="number" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
                <Col xs={12} sm={4} className="mb-3">
                    <Field label='Cod poștal' id="postalCode" name="postalCode" placeholder='Cod poștal'>
                        {({
                            //@ts-ignore
                            meta: { touched, error }
                        }) =>
                            <Input
                                mbZero
                                value={formikProps.values.postalCode || ''}
                                label='Cod poștal'
                                onBlur={() => formikProps.setFieldTouched('postalCode')}
                                onChange={(e: any) => {
                                    formikProps.setFieldValue('postalCode', e.target.value);
                                    handleData(e.target.value, 'postalCode');
                                }}
                                disabled
                            />
                        }
                    </Field>
                    <ErrorMessage name="postalCode" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
            </Row>
        </div>
    );
};

export default DatePFInPortofolio;