import React from 'react';

import { DelgazWrapper } from '@components/global/DelgazWrapper';
import DisplayMeasuredValues from '@components/delgaz/PartenerValoriMasurate/DisplayMeasuredValues';

export type DisplayMeasuredValuesComponentProps = {
  kentico: any;
};

export const DisplayMeasuredValuesComponent = (props: DisplayMeasuredValuesComponentProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <DisplayMeasuredValues />
    </DelgazWrapper>
  );
};
