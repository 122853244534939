import { toggleLoader } from '@actions/LoaderActions';
import { postStepOne } from '@api/iConnect/iConnectEE';
import { getTypes } from '@api/prosumatori/prosumatori';
import { Select } from '@components/common/Select';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Adress from '../forms/Adress';
import Consultant from '../forms/Consultant';
import DateDeIdentificarePF from '../forms/DateDeIdentificarePF';
import DateDeIdentificarePJ from '../forms/DateDeIdentificarePJ';
import DateIdentificarePFOptEmail from '../forms/DateIdentificarePFOptEmail';
import DateIdentificarePJOptEmail from '../forms/DateIdentificarePJOptEmail';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const ClientFinal = (props: {
    modalOption: any,
    isEditing: any,
    requestId: any,
    handleRequestId: any,
    startingModalOption: any,
    setStep: any,
    progress: any,
    currentStep: any,
    setProgress: any,
    formData: any,
    handleFormData: any,
    clientType: any,
    handleClientType: any,
    adressType: any,
    handleAdressType: any
    adressData: any,
    handleAdressData: any,
    dateConsultant: any,
    handleDateConsultant: any,
}) => {
    const {
        // modalOption,
        isEditing,
        requestId,
        handleRequestId,
        startingModalOption,
        setStep,
        progress,
        currentStep,
        setProgress,
        handleFormData,
        formData,
        clientType,
        handleClientType,
        adressType,
        // handleAdressType,
        adressData,
        handleAdressData,
        dateConsultant,
        handleDateConsultant,
    } = props;

    const [clientTypes, setClientTypes] = useState([]);
    const [clientTypeError, setClientTypeError] = useState('');
    const [adressTypeError, setAdressTypeError] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const dateIdentifPFRef = useRef(null);
    const dateIdentifPJRef = useRef(null);
    const adressRef = useRef(null);
    const consRef = useRef(null);

    const dispatch = useDispatch();

    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    useEffect(() => {
        window.scrollTo(0, 0);

        getOptionTypes(['ApplicantType']).then((res) => {
            console.log('client types: ', res);
            setClientTypes(res);
            startingModalOption === 1 && jwt.groups.includes('ClientFizic') && handleClientType('1');
            startingModalOption === 1 && jwt.groups.includes('ClientJuridic') && handleClientType('2');
        });
    }, []);

    const getOptionTypes = async (keys: string[]) => {
        return await getTypes(keys).then((res: any) => {
            return res[keys[0]];
        });
    };

    const constructPayload = () => {
        const adresa = {
            Judet: formData.Judet,
            Localitate: formData.Localitate,
            Comuna: formData.Comuna,
            Strada: formData.Strada,
            Numar: formData.Numar,
            Bloc: formData.Bloc,
            Etaj: formData.Etaj,
            Scara: formData.Scara,
            Ap: formData.Ap
        };

        let adr;
        if (startingModalOption === 2) {
            adr = adresa;
        } else {
            if (adressType === 'AU') {
                adr = adresa;
            } else {
                adr = adressData;
            }
        }

        if (clientType === '2') {
            formData.CIData = null;
        }

        const payload = {
            TipClient: clientType === '1' ? 'PF' : 'PJ',
            DateIdentificare: {
                ...formData,
                Adresa: adresa
            },
            TipCorespondenta: adressType,
            AdresaCorespondenta: adr,
            ...(requestId !== null && { requestId: requestId }),
            reprezentant: startingModalOption !== 1,
            consultant: dateConsultant
        };

        ['Judet', 'Localitate', 'Comuna', 'Strada', 'Numar', 'Bloc', 'Etaj', 'Scara', 'Ap'].forEach(e => delete payload.DateIdentificare[e]);

        return payload;
    };

    const stepOneApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepOne(payload)
            .then((response: any) => {
                if (response) {
                    if (requestId === null) {
                        handleRequestId(response);
                    }
                    setShowModal(true);
                    // setStep(currentStep + 1);
                    // currentStep === progress && setProgress(currentStep + 1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setShowModal(true);
                    // setStep(currentStep + 1);
                    // currentStep === progress && setProgress(currentStep + 1);
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            let ac = document.getElementById('ac');
            ac?.classList.remove('red');
            setClientTypeError('');
            setAdressTypeError('');
            setError('');

            if (clientType === '') {
                setClientTypeError('Te rugăm să alegi tipul de client.');
            }

            //@ts-ignore
            dateIdentifPFRef.current?.submitForm();
            //@ts-ignore
            dateIdentifPJRef.current?.submitForm();

            // if (startingModalOption === 1 && clientType !== '') {
            //     if (adressType === '') {
            //         setAdressTypeError('Te rugăm să alegi adresa de corespondență.');
            //         let err = document.getElementById('ac');
            //         err?.classList.add('red');
            //     } else {
            //         //@ts-ignore
            //         adressRef.current?.submitForm();
            //     }
            // }

            //@ts-ignore
            if (dateIdentifPFRef.current && dateIdentifPFRef.current?.isValid === true) {
                // if (startingModalOption === 2) {
                //     stepOneApiCall(constructPayload());
                // } else {
                //     //@ts-ignore
                //     if (adressRef.current && adressRef.current?.isValid === true) {
                //         stepOneApiCall(constructPayload());
                //     } else if (!adressRef.current) {
                //         // call endpoint
                //         stepOneApiCall(constructPayload());
                //     }
                // }
                stepOneApiCall(constructPayload());
                //@ts-ignore
            } else if (dateIdentifPJRef.current && dateIdentifPJRef.current?.isValid === true) {
                // if (startingModalOption === 2) {
                //     stepOneApiCall(constructPayload());
                // } else {
                //     //@ts-ignore
                //     if (adressRef.current && adressRef.current?.isValid === true) {
                //         stepOneApiCall(constructPayload());
                //     } else if (!adressRef.current) {
                //         //@ts-ignore
                //         consRef.current?.submitForm();
                        
                //         //@ts-ignore
                //         if (consRef.current && consRef.current?.isValid === true) {
                //             stepOneApiCall(constructPayload());
                //         }
                //     }
                // }
                stepOneApiCall(constructPayload());
            }
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    const renderModal = () => {
        return <Modal 
            show={showModal} 
            centered 
            onHide={() => {
                setShowModal(false);
                // setStep(currentStep + 1);
                // currentStep === progress && setProgress(currentStep + 1);
            }} 
            onClose={() => {
                setShowModal(false);
                setStep(currentStep + 1);
                currentStep === progress && setProgress(currentStep + 1);
            }}
            >
            <Modal.Header closeButton>
                <span style={{height: '30px'}}></span>
            </Modal.Header>
            <Modal.Body>
                <p>Prin apăsarea butonului <b>Pasul următor</b> se salvează informațiile completate până acum. Poți reveni oricând în pasul salvat, pentru editare, sau poți continua cererea din secțiunea <b>Listă cereri racordare</b> din meniul din stânga.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setStep(currentStep + 1);
                        currentStep === progress && setProgress(currentStep + 1);
                    }
                    }
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>;
    };

    useEffect(() => {
        console.log('(1) PF logat / PF slectat / Fara reprezentant: ', clientType === '1' && jwt.groups.includes('ClientFizic') && startingModalOption === 1);
        console.log('(2) PF logat / PF slectat / Cu reprezentant: ', clientType === '1' && jwt.groups.includes('ClientFizic') && startingModalOption === 2);
        console.log('(3) PF logat / PJ slectat / Fara reprezentant: ', clientType === '2' && jwt.groups.includes('ClientFizic') && startingModalOption === 1);
        console.log('(4) PF logat / PJ slectat / Cu reprezentant: ', clientType === '2' && jwt.groups.includes('ClientFizic') && startingModalOption === 2);

        console.log('(5) PJ logat / PF slectat / Fara reprezentant: ', clientType === '1' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1);
        console.log('(6) PJ logat / PF slectat / Cu reprezentant: ', clientType === '1' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2);
        console.log('(7) PJ logat / PJ slectat / Fara reprezentant: ', clientType === '2' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1);
        console.log('(8) PJ logat / PJ slectat / Cu reprezentant: ', clientType === '2' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2);
    }, [clientType]);

    return (
        <div className="prosumatori-client-final m-3 w-100 d-flex align-self-center flex-column">
            {clientTypes.length > 0 && <Row>
                <h4 className="register-section-title mt-3">Date de identificare ale solicitantului (clientul final)</h4>
                <Col xs={12} sm={6}>
                    <Select
                        onChange={(e: any) => {
                            setClientTypeError('');
                            if (e.target.value === '1' && jwt.groups.includes('ClientJuridic')) handleFormData('', 'Email');
                            if (e.target.value === '2' && jwt.groups.includes('ClientFizic')) handleFormData('', 'Email');
                            if (e.target.value === '1' && jwt.groups.includes('ClientFizic') && startingModalOption === 1) handleFormData(jwt.sub, 'Email');
                            if (e.target.value === '2' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1) handleFormData(jwt.sub, 'Email');
                            handleClientType(e.target.value);
                        }}
                        options={clientTypes}
                        placeholder={'Alege tipul de client'}
                        value={clientType}
                        mbZero
                        disabled={isEditing === false || startingModalOption === 1}
                    />
                    {clientTypeError !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{clientTypeError}</p>}
                </Col>
            </Row>}
            
            {(clientType === '1' && jwt.groups.includes('ClientFizic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePF isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={true} />
                </Row>
            }

            {(clientType === '1' && jwt.groups.includes('ClientFizic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateIdentificarePFOptEmail isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            {(clientType === '2' && jwt.groups.includes('ClientFizic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePJ isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={false} />
                </Row>
            }

            {(clientType === '2' && jwt.groups.includes('ClientFizic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateIdentificarePJOptEmail isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            {(clientType === '1' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePF isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={false} />
                </Row>
            }

            {(clientType === '1' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateIdentificarePFOptEmail isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            {(clientType === '2' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePJ isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={true} />
                </Row>
            }

            {(clientType === '2' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateIdentificarePJOptEmail isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            <br></br>
            {clientType === '2' && <Fragment>
                <h5>Consultant de specialitate (dacă este cazul)</h5>
                <Consultant isEditing={isEditing} formData={dateConsultant} handleFormData={handleDateConsultant} innerRef={consRef}/>
            </Fragment>}

            {/* {(clientType !== null && clientType !== '' && startingModalOption === 1) &&
                <ChoiceComponent
                    title={'Adresa de corespondență este:*'}
                    options={[{ title: 'Adresa clientului final', id: 'AU' }, { title: 'Altă adresă', id: 'AA' }]}
                    onChange={(option: number) => {
                        setAdressTypeError('');
                        handleAdressType(option);
                    }}
                    value={adressType}
                    disabled={isEditing === false}
                    id="ac"
                />
            } */}

            {(adressTypeError !== '' && startingModalOption === 1) && <p style={{ color: 'red', fontWeight: 'bold' }}>{adressTypeError}</p>}
            {(clientType !== null && clientType !== '' && adressType === 'AA' && startingModalOption === 1) &&
                <Adress isEditing={isEditing} innerRef={adressRef} formData={adressData} handleFormData={handleAdressData} />
            }

            {(error !== '') && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
            <div className="d-flex flex-row justify-content-between mt-3 mb-3">
                <Button className="d-flex" disabled>Pasul anterior</Button>
                <Button className="d-flex" onClick={() => handleNextStep()}>Pasul următor</Button>
            </div>

            {renderModal()}
        </div>
    );
};

export default ClientFinal;