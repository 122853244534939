import React, {Fragment, useState, useEffect} from 'react';
import Steps from '@components/prosumatori/components/Steps';
import {stepsArrayIConnect} from '@constants/iConnect/Steps';
import ClientFinal from '@components/iConnect/steps/ClientFinal';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import ReprezentantLegal from '@components/iConnect/steps/ReprezentantLegal';
import AdresaObiectiv from '@components/iConnect/steps/AdresaObiectiv';
import InformatiiTehnice from '@components/iConnect/steps/InformatiiTehnice';
import CanaleComunicare from '@components/iConnect/steps/CanaleComunicare';
import DocumenteAnexate from '@components/iConnect/steps/DocumenteAnexate';
import InformareFinala from '@components/iConnect/steps/InformareFinala';
import {File} from '@components/common/UploadFile/UploadFile';
import {
    getStepFive,
    getStepFour,
    getStepOne,
    getStepSeven,
    getStepSix,
    getStepThree,
    getStepTwo,
    getUserDetails, 
} from '@api/iConnect/iConnectGaz';
import {useDispatch, useSelector} from 'react-redux';
import {toggleLoader} from '@actions/LoaderActions';
import {getTypes} from '@api/prosumatori/prosumatori';
import {IState} from '@type/store';
import {jwtDecode} from 'jwt-decode';
import { Button } from 'react-bootstrap';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
  }

const modalData = {
    title: 'Pentru cine dorești să depui cererea?',
    options: [
        {title: 'Vreau să depun o cerere în numele meu', id: 1},
        {title: 'Vreau să depun o cerere în numele altcuiva', id: 2}
    ]
};

const dateIde = {
    Nume: '',
    Prenume: '',
    CNP: '',
    Denumire: '',
    CUI: '',
    NrReg: '',
    Telefon: '',
    Email: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    Cont: '',
    Banca: '',
    Reprezentant: '',
    InCalitateDe: '',
    SerieCI: '',
    NumarCI: '',
    CIEliberatDe: '',
    CIData: ''
};

const adr = {
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    Email: '',
    Telefon: ''
};

const imp = {
    Nume: '',
    Prenume: '',
    CNP: '',
    Denumire: '',
    CUI: '',
    Telefon: '',
    Email: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    SerieCI: '',
    NumarCI: '',
    CIEliberatDe: '',
    CIData: '',
    NrAutorizare: '',
    ActEmisDe: '',
    NrReg: ''
};

const adresaObiectivului = {
    POD: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    nrCF: '',
    nrCad: ''
};

const date_obtinere_aviz = {
    Judet: '',
    Comuna: '',
    Localitate: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Scara: '',
    Ap: '',
};

const date_racordare = {
    Judet: '',
    Comuna: '',
    Localitate: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Scara: '',
    Ap: '',
};

const consumatori = {
    columns: [
        {
            name: 'DomesticConsumerName',
            type: 'input',
            dataType: 'string',
            colName: 'Denumire aparat'
        },
        {
            name: 'DomesticConsumerCount',
            type: 'input',
            dataType: 'numeric',
            colName: 'Nr. de aparate'
        },
        {
            name: 'ConsumptionPower',
            type: 'input',
            dataType: 'numeric',
            colName: 'Debit nominal (mc/h)'
        },
        {
            name: 'TotalConsumptionPower',
            type: 'readOnly',
            dataType: 'numeric',
            colName: 'Debit total (mc/h)',
        },
    ],
    totalCol: [3]
};

const dateCons = [
    {
        DomesticConsumerName: 'Aragaz',
        DomesticConsumerCount: 1,
        ConsumptionPower: 0.67,
        TotalConsumptionPower: 0.67
    },
    {
        DomesticConsumerName: 'Centrală termică',
        DomesticConsumerCount: 1,
        ConsumptionPower: 2.8,
        TotalConsumptionPower: 2.8
    }
];

const dm = {
    L1: '',
    L2: '',
    L3: '',
    L4: '',
    L5: ''
};

const CerereIConnectGaz = (props: any) => {
    const [requestId, setRequestId] = useState(-1);
    const [initiated, setInitiated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean | null>(null);
    // If request does not exist
    const [noRequest, setNoRequest] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);

    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    const dispatch = useDispatch();

    const handleRequestId = (id: number) => {
        setRequestId(id);
    };

    const setStepOneFormData = async () => {
        dispatch(toggleLoader(true));

        await getUserDetails()
            .then((response) => {
                if (jwt.groups.includes('ClientFizic')) {
                    handleDateIdentificare(response.contactFirstName, 'Prenume');
                    handleDateIdentificare(response.contactLastName, 'Nume');
                    handleDateIdentificare(response.email, 'Email');
                    // handleDateIdentificare(response.mobilePhone, 'Telefon');
                } else if (jwt.groups.includes('ClientJuridic')) {
                    handleDateIdentificare(response.companyName, 'Denumire');
                    handleDateIdentificare(response.cui, 'CUI');
                    handleDateIdentificare(response.orc, 'NrReg');
                    handleDateIdentificare(response.email, 'Email');
                    // handleDateIdentificare(response.mobilePhone, 'Telefon');
                }

                setStartingModalOption(1);
                stepsArrayIConnect.splice(1, 1);
                setSteps(stepsArrayIConnect);
                setCurrentStep(0);
                currentStep === progress && setProgress(0);

                setShowStartingModal(false);
            })
            .catch((err) => console.log(err));
        
        dispatch(toggleLoader(false));
    };

    const setStepTwoFormData = async () => {
        dispatch(toggleLoader(true));

        await getUserDetails()
            .then((response) => {
                if (jwt.groups.includes('ClientFizic')) {
                    handleImputernicit(response.contactFirstName, 'Prenume');
                    handleImputernicit(response.contactLastName, 'Nume');
                    handleImputernicit(response.email, 'Email');
                    // handleImputernicit(response.mobilePhone, 'Telefon');
                } else if (jwt.groups.includes('ClientJuridic')) {
                    handleImputernicit(response.companyName, 'Denumire');
                    handleImputernicit(response.cui, 'CUI');
                    handleImputernicit(response.orc, 'NrReg');
                    handleImputernicit(response.email, 'Email');
                    // handleImputernicit(response.mobilePhone, 'Telefon');
                }

                setStartingModalOption(2);
                setSteps(stepsArrayIConnect);
                setCurrentStep(0);
                currentStep === progress && setProgress(0);
  
                setShowStartingModal(false);
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const [showStartingModal, setShowStartingModal] = useState(true);
    const [startingModalOption, setStartingModalOption] = useState<number | null>(null);
    //eslint-disable-next-line
    const [steps, setSteps] = useState<any[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [progress, setProgress] = useState(0);

    const handleCloseModal = (option: any) => {
        if (option.id === 1) {
            setStepOneFormData();
        } else {
            setStepTwoFormData();
        }
    };

    const handleStepChange = (step: number) => {
        setCurrentStep(step);
    };

    const handleProgress = (step: number) => {
        setProgress(step);
    };

    // Pasul 1 - Client Final
    const [clientType, setClientType] = useState('');
    const [type, setType] = useState<number>(-1);
    const [group, setGroup] = useState<boolean | null>(null);
    const [dateIdentificare, setDateIdentificare] = useState<any>(dateIde);
    const [newClient, setNewClient] = useState<boolean | null>(null);
    const [dezvoltator, setDezvoltator] = useState<boolean | null>(null);
    const [adressType, setAdressType] = useState('');
    const [adress, setAdress] = useState(adr);

    const handleClientType = (type: string) => {
        setClientType(type);
    };

    const handleType = (type: string) => {
        setType(Number(type));
    };

    const handleGroup = (type: boolean) => {
        setGroup(type);
    };

    const handleAdressType = (type: string) => {
        setAdressType(type);
    };

    const handleDateIdentificare = (data: any, field: any) => {
        let newData = dateIdentificare;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setDateIdentificare(newData);
    };

    const handleNewClient = (type: boolean) => {
        setNewClient(type);
    };

    const handleDezvoltator = (type: boolean) => {
        setDezvoltator(type);
    };

    const handleAdressData = (data: any, field: any) => {
        let newData = adress;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setAdress(newData);
    };

    // Pasul 2 - Reprezentat Legal
    const [tipImputernicit, setTipImputernicit] = useState('');
    const [imputernicit, setImputernicit] = useState<object | null>(imp);

    const handleTipImputernicit = (type: string) => {
        setTipImputernicit(type);
    };

    const handleImputernicit = (data: any, field: any) => {
        let newData = imputernicit;

        newData && Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setImputernicit(newData);
    };

    // Pasul 3 - Adresa obiectivului
    const [solicit, setSolicit] = useState<number | null>(null);
    const [tipLC, setTipLC] = useState<number | null>(null);
    const [connectingPipe, setConnectingPipe] = useState<number>(0);
    const [construit, setConstruit] = useState<boolean | null>(null);
    const [adresaOb, setAdresaOb] = useState(adresaObiectivului);
    const [tipAdresa, setTipAdresa] = useState<string>('');
    const [wasDataVerified, setWasDataVerified] = useState(false);
    const [obtinereAviz, setObtinereAviz] = useState(date_obtinere_aviz);
    const [dateRacordare, setDateRacordare] = useState(date_racordare);

    const handleTipLC = (type: number) => {
        setTipLC(type);

        let newData = adresaOb;

        if (type === 2) {
            setTipAdresa('AA');
            
            Object.keys(newData).forEach(k => {
                // @ts-ignore
                newData[k] = '';
            });
    
            setAdresaOb(newData);
        } else if (type === 1) {
            handleTipAdresa(tipAdresa);
        }

        setWasDataVerified(false);
    };

    const handleTipAdresa = (type: string, fromStep?: false) => {
        if (type === 'AU') {
            let adresa = adresaOb;

            adresa.Judet = dateIdentificare.Judet;
            adresa.Localitate = dateIdentificare.Localitate;
            adresa.Comuna = dateIdentificare.Comuna;
            adresa.Strada = dateIdentificare.Strada;
            adresa.Numar = dateIdentificare.Numar;
            adresa.Bloc = dateIdentificare.Bloc;
            adresa.Etaj = dateIdentificare.Etaj;
            adresa.Scara = dateIdentificare.Scara;
            adresa.Ap = dateIdentificare.Ap;

            setAdresaOb(adresa);
        } else if (type === 'AA' && fromStep) {
            let adresa = adresaOb;

            adresa.Judet = '';
            adresa.Localitate = '';
            adresa.Comuna = '';
            adresa.Strada = '';
            adresa.Numar = '';
            adresa.Bloc = '';
            adresa.Etaj = '';
            adresa.Scara = '';
            adresa.Ap = '';

            setAdresaOb(adresa);
        }
        setTipAdresa(type);
    };

    const handleConnectingPipe = (type: number) => {
        setConnectingPipe(type);
    };

    const handleWasDataVerified = (value: boolean) => {
        setWasDataVerified(value);
    };

    const handleAdresaOb = (data: any, field: any) => {
        let newData = adresaOb;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setAdresaOb(newData);
    };

    const handleSolicit = (type: number) => {
        setSolicit(type);
    };

    const handleConstruit = (type: boolean) => {
        setConstruit(type);
    };

    const handleObtinereAviz = (data: any, field: any) => {
        let newData = obtinereAviz;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setObtinereAviz(newData);
    };

    const handleDateRacordare = (data: any, field: any) => {
        let newData = dateRacordare;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setDateRacordare(newData);
    };

    // Pasul 4 - Informatii tehnice
    const [lucrari, setLucrari] = useState<number | null>(null);
    //eslint-disable-next-line
    const [aparate, setAparate] = useState(consumatori);
    const [dateConsumatori, setDateConsumatori] = useState([...dateCons]);
    const [conducta, setConducta] = useState<number | null>(null);
    const [alimentareGaz, setAlimentareGaz] = useState<number | null>(null);
    const [dimensiuni, setDimensiuni] = useState(dm);
    const [tipDrum, setTipDrum] = useState<number | null>(null);
    const [tipTrotuar, setTipTrotuar] = useState<number | null>(null);
    const [pozFirida, setPozFirida] = useState<number | null>(null);
    const [distanta, setDistanta] = useState<string>('');
    const [drum, setDrum] = useState<number | null>(null);
    const [asfaltat, setAsfaltat] = useState<number | null>(null);
    const [infoAsflatare, setInfoAsfaltare] = useState<number | null>(null);
    const [cerinteSpecifice, setCerinteSpecifice] = useState<string>('');
    const [debitTotal, setDebitTotal] = useState<number>(0);
    const [pozProprAlimentate, setPozProprAlimentate] = useState<number | null>(null);

    const handleLucrari = (type: number) => {
        setLucrari(type);
    };

    const handleDateConsumatori = (data: any) => {
        data.map((row: any, idx: number) => {
            if (row['ConsumptionPower'] && row['DomesticConsumerCount']) {
                row['TotalConsumptionPower'] = Number(row['ConsumptionPower'] * row['DomesticConsumerCount']).toFixed(2);
            }

            if (row['ConsumptionPower'] === '' || row['DomesticConsumerCount'] === '') {
                row['TotalConsumptionPower'] = '0';
            }
        });

        let debitTotal = 0;

        data.map((row: any, idx: number) => {
            if (row['TotalConsumptionPower']) {
                debitTotal += Number(row['TotalConsumptionPower']);
            }
        });

        setDebitTotal(Number(debitTotal.toFixed(2)));
        setDateConsumatori(data);
    };

    const handleConducta = (type: number) => {
        setConducta(type);
    };

    const handleAlimentareGaz = (type: number) => {
        setAlimentareGaz(type);
    };

    const handleDimensiuni = (data: string, field: string) => {
        let newData = dimensiuni;

        Object.keys(newData).map((key: string, index) => {
            if (key === field && !isNaN(Number(data))) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setDimensiuni({...newData});
    };

    const handleTipDrum = (type: number) => {
        setTipDrum(type);
    };

    const handleTipTrotuar = (type: number) => {
        setTipTrotuar(type);
    };

    const handlePozFirida = (type: number) => {
        setPozFirida(type);
    };

    const handlePozProprAlimentate = (type: number) => {
        setPozProprAlimentate(type);
    };

    const handleDistanta = (type: string) => {
        setDistanta(type);
    };

    const handleDrum = (type: number) => {
        setDrum(type);
    };

    const handleAsfaltat = (type: number) => {
        setAsfaltat(type);
    };

    const handleInfoAsfaltare = (type: number) => {
        setInfoAsfaltare(type);
    };

    const handleCerinteSpecifice = (text: string) => {
        setCerinteSpecifice(text);
    };

    const handleDebitTotal = (number: number) => {
        setDebitTotal(number);
    };

    // Pasul 5 - Canale de comunicare
    const [raspuns, setRaspuns] = useState<number | null>(null);
    const [comunic, setComunic] = useState<any[]>([]);
    const [detaliiComunicare, setDetaliiComunicare] = useState('');
    const [publicare, setPublicare] = useState<number | null>(1);
    const [communicationOptionLoaded, setCommunicationOptionLoaded] = useState<boolean>(false);

    const handleRaspuns = (type: number) => {
        setRaspuns(type);
    };

    const handleComunic = (data: any) => {
        setComunic([...data]);
    };

    const handleDetaliiComunicare = (text: string) => {
        setDetaliiComunicare(text);
    };

    const handlePublicare = (type: number) => {
        setPublicare(type);
    };

    const handleCommunicationOptionLoaded = (value: boolean) => {
        setCommunicationOptionLoaded(value);
    };

    // Pasul 6- Documente Anexate
    const [files, setFiles] = useState<any []>([]);
    const [wereFilesMounted, setWereFilesMounted] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([] as File[]);
    const [alreadyUploaded, setAlreadyUploaded] = useState<any[]>([]);

    const handleSetFiles = (files: File[]) => {
        let addEmptyFiles: any = [];

        files.map((f: any, i: number) => {
            addEmptyFiles.push([]);
        });

        setUploadedFiles(addEmptyFiles);
        setFiles(files);
    };

    const handleShowExtraFiles = (fileName: string) => {
        let newArray: any = [];
        let newItem: any = null;
        let index = null;

        files.map((f: any, i: number) => {
            let item = f;

            if (item.name === fileName) {
                // item.show = true;
                newItem = item;
                index = i;
            }

            newArray.push(item);
        });

        newItem.show = true;
        newArray.splice(index, 1, newItem);

        setFiles(newArray);
    };

    const handleWereFilesMounted = (value: boolean) => {
        setWereFilesMounted(value);
    };

    const handleInitalFilesToUpload = (files: File[]) => {
        setUploadedFiles(files);
    };

    const uploadFile = (fileArr: File[], index: number, isMounting: boolean) => {
        let newData = uploadedFiles;

        newData.map((f: File, idx: number) => {
            if (idx === index) {
                newData[idx] = fileArr;
            }
        });

        setUploadedFiles([...newData]);
    };

    // Pasul 7 - Informare finala
    const [checkedOne, setCheckedOne] = useState(false);
    const [checkedTwo, setCheckedTwo] = useState(false);
    const [checkedThree, setCheckedThree] = useState(false);
    const [checkedFour, setCheckedFour] = useState(false);
    const [checkedFive, setCheckedFive] = useState(false);

    const handleCheckedOne = (value: boolean) => {
        setCheckedOne(value);
    };

    const handleCheckedTwo = (value: boolean) => {
        setCheckedTwo(value);
    };

    const handleCheckedThree = (value: boolean) => {
        setCheckedThree(value);
    };

    const handleCheckedFour = (value: boolean) => {
        setCheckedFour(value);
    };

    const handleCheckedFive = (value: boolean) => {
        setCheckedFive(value);
    };

    const getNotificationTypes = async (keys: ['ClientNotificationType']) => {
        return await getTypes(keys, 'GN').then((res: any) => {
            return res;
        });
    };

    useEffect(() => {
        console.log(adresaOb);
    }, [adresaOb]);

    const initiateRequest = () => {
        // const url = new URL('http://localhost:3000/cerere-prosumatori?requestId=806&step=Pas4&edit=true');
        const url = new URL(window.location.href);

        const params = url.search;
        const searchParams = new URLSearchParams(params);

        if (searchParams.has('requestId')) {
            let req_Id = searchParams.get('requestId');
            let stepProgress = searchParams.get('step');
            let edit = searchParams.get('edit');

            if (stepProgress) {
                stepProgress = stepProgress.slice(3);
            }

            let p = Number(stepProgress);
            let reqId = Number(req_Id);

            setIsEditing(edit === 'true');
            setRequestId(reqId);

            p >= 1 && stepOneGET({requestId: reqId}, p);

            setCurrentStep(0);
            setProgress(0);

            p >= 2 && stepTwoGET({requestId: reqId}, p);
            p >= 3 && stepThreeGET({requestId: reqId}, p);
            p >= 4 && stepFourGET({requestId: reqId}, p);
            p >= 5 && stepFiveGET({requestId: reqId}, p);
            p >= 6 && stepSixGET({requestId: reqId}, p);
            p >= 7 && stepSevenGET({requestId: reqId}, p);

            if (p === 8) {
                setShowStartingModal(false);
            }

            setRequestId(reqId);

            // setShowStartingModal(false);
        }

        setInitiated(true);
    };

    useEffect(() => {
        if (initiated === false) {
            initiateRequest();
        }
    }, []);

    const stepOneGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepOne(payload)
            .then((response) => {
                if (response.cerere_finalizata === true) {
                    setIsFinalized(true);
                }

                let date = response.DateIdentificare;
                let adresa = response.DateIdentificare.Adresa;

                if (response.reprezentant === false) {
                    stepsArrayIConnect.splice(1, 1);
                    setSteps(stepsArrayIConnect);

                    if (step === 8) {
                        setCurrentStep(0);
                        setProgress(step-2);
                    } else if (step !== 1) {
                        setCurrentStep(step-2);
                        setProgress(step-2);
                    } else if (step === 1) {
                        setCurrentStep(0);
                        setProgress(0);
                    }

                    setStartingModalOption(1);
                } else {
                    getUserDetails()
                        .then((response) => {
                            const url = new URL(window.location.href);

                            const params = url.search;
                            const searchParams = new URLSearchParams(params);

                            if (jwt.groups.includes('ClientFizic') && searchParams.has('step') && searchParams.get('step') !== 'Pas8') {
                                handleImputernicit(response.contactFirstName, 'Prenume');
                                handleImputernicit(response.contactLastName, 'Nume');
                                handleImputernicit(response.email, 'Email');
                                // handleImputernicit(response.mobilePhone, 'Telefon');
                            } else if (jwt.groups.includes('ClientJuridic') && searchParams.has('step') && searchParams.get('step') !== 'Pas8') {
                                handleImputernicit(response.companyName, 'Denumire');
                                handleImputernicit(response.cui, 'CUI');
                                handleImputernicit(response.orc, 'NrReg');
                                handleImputernicit(response.email, 'Email');
                                // handleImputernicit(response.mobilePhone, 'Telefon');
                            }
                        })
                        .catch((err) => console.log(err));

                    setSteps(stepsArrayIConnect);

                    if (step === 8) {
                        setCurrentStep(0);
                        setProgress(step-1);
                    } else if (step !== 1) {
                        setCurrentStep(step-1);
                        setProgress(step-1);
                    } else if (step === 1) {
                        setCurrentStep(0);
                        setProgress(0);
                    }

                    setStartingModalOption(2);
                }

                delete date['Adresa'];

                if (response.AdresaCorespondenta !== null) {
                    if (response.TipCorespondenta === 'AA') {
                        setAdress(response.AdresaCorespondenta);
                    }
                }

                setAdressType(response.TipCorespondenta);

                let dateID = {
                    Nume: date.Nume !== null ? date.Nume : '',
                    Prenume: date.Prenume !== null ? date.Prenume : '',
                    CNP: date.CNP !== null ? date.CNP : '',
                    CUI: date.CUI !== null ? date.CUI : '',
                    Denumire: date.Denumire !== null ? date.Denumire : '',
                    NrReg: date.NrReg !== null ? date.NrReg : '',
                    Telefon: date.Telefon !== null ? date.Telefon : '',
                    Email: date.Email !== null ? date.Email : '',
                    Cont: date.Cont !== null ? date.Cont : '',
                    Banca: date.Banca !== null ? date.Banca : '',
                    Reprezentant: date.Reprezentant !== null ? date.Reprezentant : '',
                    InCalitateDe: date.InCalitateDe !== null ? date.InCalitateDe : '',
                    SerieCI: date.SerieCI !== null ? date.SerieCI : '',
                    NumarCI: date.NumarCI !== null ? date.NumarCI : '',
                    CIEliberatDe: date.CIEliberatDe !== null ? date.CIEliberatDe : '',
                    CIData: date.CIData !== null ? date.CIData : ''
                };

                Object.assign(dateID, adresa);
                setDateIdentificare(dateID);
                setClientType(response.TipSolicitant);
                setDezvoltator(response.Dezvoltator);
                setNewClient(response.ClientNou);
                setType(response.tip_client);
                setGroup(response.grup_persoane);
            })
            .catch((error) => {
                if (error === 'Request does not exists') {
                    setNoRequest(true);
                }
            });

        if (step === 1) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepTwoGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepTwo(payload)
            .then((response) => {
                let date = response.Imputernicit;
                let adresa = response.Imputernicit.Adresa;

                delete date['Adresa'];
                Object.assign(date, adresa);

                if (date.CIData === null) {
                    date.CIData = '';
                };

                if (date.NrReg === null) {
                    date.NrReg = '';
                };

                setImputernicit(date);
                setAdressType(response.TipCorespondenta);
                setTipImputernicit(response.TipImputernicit);
            })
            .catch((error) => {
                if ((error && error.StatusCode && error.StatusCode === 500) || (error && error.status && error.status === 204)) {
                    setImputernicit(null);
                }
            });

        if (step === 2) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepThreeGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepThree(payload)
            .then((response) => {
                if (response.AdresaObiectiv !== null) {
                    setConnectingPipe(response.TipBransament);
                    setTipLC(response.TipLC);
                    setConstruit(response.ImobilConstruit);
    
                    let adr = response.AdresaObiectiv;
    
                    adr = {...adr, nrCF: response.nrCF};
                    adr = {...adr, nrCad: response.nrCad};
                    adr = {...adr, POD: response.POD === null ? '' : response.POD};

                    if (response.TipBransament == 6) {
                        setObtinereAviz(adr);
                    } else if (response.TipBransament == 7) {
                        setDateRacordare(adr);
                    } else {
                        setAdresaOb(adr);
                    }
                    
                    // setAdresaOb(adr);

                    // if ((response.AdresaObiectiv.Judet === adress.Judet) && (response.AdresaObiectiv.Localitate === adress.Localitate)) {
                    //     setTipAdresa('AU');
                    // } else {
                    //     setTipAdresa('AA');
                    // }

                    setTipAdresa(response.TipAdresaObiectiv);

                } else {
                    getStepOne(payload).then((res) => {
                        let adresaObiectiv = {
                            Judet: res.DateIdentificare.Adresa.Judet,
                            Localitate: res.DateIdentificare.Adresa.Localitate,
                            Comuna: res.DateIdentificare.Adresa.Comuna,
                            Strada: res.DateIdentificare.Adresa.Strada,
                            Numar: res.DateIdentificare.Adresa.Numar,
                            Bloc: res.DateIdentificare.Adresa.Bloc,
                            Etaj: res.DateIdentificare.Adresa.Etaj,
                            Scara: res.DateIdentificare.Adresa.Scara,
                            Ap: res.DateIdentificare.Adresa.Ap,
                            nrCF: response.nrCF,
                            nrCad: response.nrCad,
                            POD: '',
                            cod_loc_consum: '',
                        };

                    setConnectingPipe(response.TipBransament);
                    setTipLC(response.TipLC);
                    setConstruit(response.ImobilConstruit);

                    setAdresaOb(adresaObiectiv);
                    setTipAdresa(response.TipAdresaObiectiv);
                    
                    });
                }

                if (response.POD !== null) {
                    setWasDataVerified(true);
                }
                
            })
            .catch((error) => {
                console.log(error);
            });

        if (step === 3) {
            setShowStartingModal(false);
        }
    
        dispatch(toggleLoader(false));
    };

    const stepFourGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepFour(payload)
        .then((response) => {
            setLucrari(response.executant);
            setCerinteSpecifice(response.cerinte_specifice);
            setDebitTotal(response.consum_total);
            setConducta(response.ConductaGaze);
            setAlimentareGaz(response.ProprietatiAlimentate === true ? 1 : 2);
            setPozProprAlimentate(response.PozitieProprietatiAlimentate[0]?.Pozitie || null);
            let dim = {
                L1: response.L1,
                L2: response.L2,
                L3: response.L3,
                L4: response.L4,
                L5: response.L5
            };
            setDimensiuni(dim);
            setTipDrum(response.TipTeren.Drum);
            setTipTrotuar(response.TipTeren.Trotuar);
            setDistanta(response.Distanta);
            setDrum(response.Drum);
            setAsfaltat(response.Asfaltat);
            setInfoAsfaltare(response.Lucrari_asfalt);
            setPozFirida(response.Firida);

            let consumatori = response.consumatori;
            consumatori.forEach(function(v: any){ delete v.requestDomesticConsumerId; });
            consumatori.forEach(function(v: any){ delete v.IsTotalLine; });
            consumatori.forEach(function(v: any){ delete v.tip_receptor; });
            consumatori.forEach(function(v: any){ delete v.DomesticConsumerTypeId; });

            setDateConsumatori(consumatori);
        });

        if (step === 4) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepFiveGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepFive(payload)
        .then((response) => {
            setRaspuns(response.Raspuns);
            setPublicare(response.Acord === true ? 1 : 2);

            getNotificationTypes(['ClientNotificationType'])
                .then((resp) => {
                    let data = resp.clientnotificationtype;

                    data.map((el: any, i: number) => {
                        el.active = false;

                        response.Comunic.map((item: any, idx: number) => {
                            if (el.id === item.id_comunicare) {
                                el.active = true;
                            }

                            if (item.id_comunicare === 12) {
                                setDetaliiComunicare(item.detalii_comunicare);
                            }
                        });
                    });

                    setComunic(data);
                    setCommunicationOptionLoaded(true);

                    if (step === 5) {
                        setShowStartingModal(false);
                    }
                });
        })
        .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const stepSixGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepSix(payload)
            .then((response) => {
                setAlreadyUploaded(response.requestDocuments);
            });

        if (step === 6) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepSevenGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepSeven(payload)
            .then((response) => {
                setCheckedOne(response.Acord1);
                setCheckedTwo(response.Acord2);
                setCheckedThree(response.Acord3);
                setCheckedFour(response.Acord4);
                setCheckedFive(response.Acord5);
            });

        if (step === 7) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const reloadAlreadyUploadedDocuments = async () => {
        dispatch(toggleLoader(true));

        await getStepSix({requestId: requestId})
            .then((response) => {
                setAlreadyUploaded(response.requestDocuments);
            });

        dispatch(toggleLoader(false));
    };
 
    const renderModal = () => {
        return <ChoiceModal 
                    showModal={showStartingModal} 
                    modalTitle={modalData.title} 
                    options={modalData.options}
                    onClose={handleCloseModal} 
                    onHide={() => window.location.href = `${OLD_SITE_URL}/solicitari`}
                >
                        <h5>IConnect GN</h5>
                </ChoiceModal>;
    };

    const renderSteps = () => {
        return <Fragment>
            <Steps steps={stepsArrayIConnect} currentStep={currentStep} changeStep={handleStepChange} progress={progress} isEditing={isEditing} />
            { currentStep === 0 &&
                <ClientFinal
                    isEditing={isEditing}
                    requestId={requestId}
                    handleRequestId={handleRequestId}
                    startingModalOption={startingModalOption}
                    handleFormData={handleDateIdentificare}
                    formData={dateIdentificare}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    clientType={clientType}
                    handleClientType={handleClientType}
                    newClient={newClient}
                    handleNewClient={handleNewClient}
                    dezvoltator={dezvoltator}
                    handleDezvoltator={handleDezvoltator}
                    adressType={adressType}
                    handleAdressType={handleAdressType}
                    adressData={adress}
                    handleAdressData={handleAdressData}
                    type={type}
                    handleType={handleType}
                    group={group}
                    handleGroup={handleGroup}
                />
            }
            { (currentStep === 1 && startingModalOption === 2) &&
                <ReprezentantLegal
                    isEditing={isEditing}
                    requestId={requestId}
                    startingModalOption={startingModalOption}
                    tipImputernicit={tipImputernicit}
                    handleTipImputernicit={handleTipImputernicit}
                    handleFormData={handleImputernicit}
                    formData={imputernicit}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    adressData={adress}
                    handleAdressData={handleAdressData}
                    adressType={adressType}
                    handleAdressType={handleAdressType}
                    dateId={dateIdentificare}
                />
            }
            { ((currentStep === 2 && startingModalOption === 2) || (currentStep === 1 && startingModalOption === 1)) &&
                <AdresaObiectiv
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    connectingPipe={connectingPipe}
                    handleConnectingPipe={handleConnectingPipe}
                    solicit={solicit}
                    handleSolicit={handleSolicit}
                    construit={construit}
                    handleConstruit={handleConstruit}
                    adresaOb={adresaOb}
                    handleAdresaOb={handleAdresaOb}
                    tipLC={tipLC}
                    handleTipLC={handleTipLC}
                    wasDataVerified={wasDataVerified}
                    handleWasDataVerified={handleWasDataVerified}
                    tipAdresa={tipAdresa}
                    handleTipAdresa={handleTipAdresa}
                    dateObtinereAviz={obtinereAviz}
                    dateRacordare={dateRacordare}
                    handleDateObtinereAviz={handleObtinereAviz}
                    handleDateRacordare={handleDateRacordare}
                    dateIdentificare={dateIdentificare}
                />
            }
            { ((currentStep === 3 && startingModalOption === 2) || (currentStep === 2 && startingModalOption === 1)) &&
                <InformatiiTehnice
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    connectingPipe={connectingPipe}
                    lucrari={lucrari}
                    handleLucrari={handleLucrari}
                    aparate={aparate}
                    consumatori={consumatori}
                    dateConsumatori={dateConsumatori}
                    handleDateConsumatori={handleDateConsumatori}
                    conducta={conducta}
                    handleConducta={handleConducta}
                    alimentareGaz={alimentareGaz}
                    handleAlimentareGaz={handleAlimentareGaz}
                    dimensiuni={dimensiuni}
                    handleDimensiuni={handleDimensiuni}
                    tipDrum={tipDrum}
                    handleTipDrum={handleTipDrum}
                    tipTrotuar={tipTrotuar}
                    handleTipTrotuar={handleTipTrotuar}
                    pozFirida={pozFirida}
                    handlePozFirida={handlePozFirida}
                    distanta={distanta}
                    handleDistanta={handleDistanta}
                    drum={drum}
                    handleDrum={handleDrum}
                    asfaltat={asfaltat}
                    handleAsfaltat={handleAsfaltat}
                    infoAsfaltare={infoAsflatare}
                    handleInfoAsfaltare={handleInfoAsfaltare}
                    cerinteSpecifice={cerinteSpecifice}
                    handleCerinteSpecifice={handleCerinteSpecifice}
                    debitTotal={debitTotal}
                    handleDebitTotal={handleDebitTotal}
                    pozProprAlimentate={pozProprAlimentate}
                    handlePozProprAlimentate={handlePozProprAlimentate}
                />
            }
            { ((currentStep === 4 && startingModalOption === 2) || (currentStep === 3 && startingModalOption === 1)) &&
                <CanaleComunicare
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    raspuns={raspuns}
                    handleRaspuns={handleRaspuns}
                    comunic={comunic}
                    handleComunic={handleComunic}
                    publicare={publicare}
                    handlePublicare={handlePublicare}
                    detaliiComunicare={detaliiComunicare}
                    handleDetaliiComunicare={handleDetaliiComunicare}
                    communicationOptionLoaded={communicationOptionLoaded}
                    handleCommunicationOptionLoaded={handleCommunicationOptionLoaded}
                />
            }
            { ((currentStep === 5 && startingModalOption === 2) || (currentStep === 4 && startingModalOption === 1)) &&
                <DocumenteAnexate
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    files={files}
                    handleFiles={handleSetFiles}
                    wereFilesMounted={wereFilesMounted}
                    handleWereFilesMounted={handleWereFilesMounted}
                    uploadedFiles={uploadedFiles}
                    handleInitalFilesToUpload={handleInitalFilesToUpload}
                    uploadFile={uploadFile}
                    alreadyUploaded={alreadyUploaded}
                    handleShowExtraFiles={handleShowExtraFiles}
                    reloadAlreadyUploadedDocuments={reloadAlreadyUploadedDocuments}
                />
            }
            { ((currentStep === 6 && startingModalOption === 2) || (currentStep === 5 && startingModalOption === 1)) &&
                <InformareFinala
                    isEditing={isEditing}
                    requestId={requestId}
                    checkedOne={checkedOne}
                    checkedTwo={checkedTwo}
                    checkedThree={checkedThree}
                    checkedFour={checkedFour}
                    checkedFive={checkedFive}
                    handleCheckOne={handleCheckedOne}
                    handleCheckTwo={handleCheckedTwo}
                    handleCheckThree={handleCheckedThree}
                    handleCheckFour={handleCheckedFour}
                    handleCheckFive={handleCheckedFive}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                />
            }
        </Fragment>;
    };

    return (
        <div className="d-flex justify-content-center flex-column">
            { !noRequest ? (showStartingModal ? renderModal() : (!isFinalized || !isEditing) && renderSteps()) : <p className="red d-flex justify-content-center fw-bold">A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.</p>}
            { (isFinalized && isEditing) && <Fragment>
                <p className="red d-flex justify-content-center fw-bold mb-3">Cererea a fost deja finalizată.</p> 
                <Button style={{margin: '0 auto'}} onClick={() => window.location.href = `${OLD_SITE_URL}/lista-cereri-racordare?tab=GN`}>Listă cereri racordare</Button>
            </Fragment>}
        </div>
    );
};

export default CerereIConnectGaz;