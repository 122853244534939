import { Input } from '@components/common/Input';
import {
    ErrorMessage,
    Field,
    Form,
    Formik
} from 'formik';
import { Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { DataVerifySchema } from '../../../../helpers/schemas';

const VerificareDate = (props: {utility: number, verify: any, error: string}) => {
    const util = props.utility;
    const vError = props.error;

    return (
        <Fragment>
            <Formik
                initialValues={{
                    licence: '',
                    supplierCode: '',
                    email: '',
                    emailConfirmation: ''
                }}
                validationSchema={DataVerifySchema}
                onSubmit={(values) => props.verify(values, util)}
            >
                {(props) => (
                    <Form>
                    <div className="form-container">
                        <div className="mt-4 mb-4 d-flex flex-column">
                            {util === 2 ?
                                <h5 className="register-section-title">Date furnizare gaz natural</h5> :
                                <h5 className="register-section-title">Date furnizare energie electrică</h5>
                            }
                            <Row className="mt-3">
                                <Col className="mb-3"  xs={12} md={6}>
                                    <Field id="licence" name="licence" placeholder="Număr licență" render = {() => 
                                    <Input label='Număr licență' mbZero onBlur={()=> {props.setFieldTouched('licence');}}
                                      onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('licence', e.target.value); 
                                      e.target.value.length === 0 && props.setFieldValue('licence', '');}}/>}/>
                                    <ErrorMessage name="licence" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                </Col>
                                <Col className="mb-3" xs={12} md={6}>
                                    <Field id="supplierCode" name="supplierCode" placeholder="Cod client" render = {() => 
                                    <Input label='Cod client' mbZero onBlur={()=> {props.setFieldTouched('supplierCode');}}
                                      onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('supplierCode', e.target.value); 
                                      e.target.value.length === 0 && props.setFieldValue('supplierCode', '');}}/>}/>
                                    <ErrorMessage name="supplierCode" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mb-3" sm={12} md={6}>
                                    <Field id="email" name="email" placeholder="Email" render = {() => 
                                    <Input label='Email' mbZero onBlur={()=> {props.setFieldTouched('email');}}
                                      onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('email', e.target.value); 
                                      e.target.value.length === 0 && props.setFieldValue('email', '');}}/>}/>
                                    <ErrorMessage name="email" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                </Col>
                                <Col className="mb-3" sm={12} md={6}>
                                    <Field id="emailConfirmation" name="emailConfirmation" placeholder="Confirmă Email" render = {() => 
                                    <Input label='Confirmă Email' mbZero onBlur={()=> {props.setFieldTouched('emailConfirmation');}}
                                      onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('emailConfirmation', e.target.value); 
                                      e.target.value.length === 0 && props.setFieldValue('emailConfirmation', '');}}/>}/>
                                    <ErrorMessage name="emailConfirmation" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/></Col>
                            </Row>

                            {vError !== '' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{vError}</div>}
                            <div className="mb-4 mt-2 buttons-container d-flex flex-row align-items-center">
                                <Button type="submit">Verificare date</Button>
                            </div>

                        </div>
                    </div>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};

export default VerificareDate;