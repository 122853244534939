import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { PressComponent } from '@components/dev/Components/Press/PressComponent';
import { PressKentico } from '@type/kentico-types';

export type PressProps = { kentico: PressKentico };

export const Press = (props: PressProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <PressComponent {...props}/>
        </DelgazWrapper>
    );
};