import { getAnexaStepThree, postAnexaStepThree } from '@api/revizii/revizii';
import { DropdownOptionType } from '@components/common/Dropdown';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import RadioDaNuNuEsteCazul from './components/RadioDaNuNuEsteCazul';
import DynamicTable from '@components/common/DynamicTable/DynamicTable';
import { toggleLoader } from '@actions/LoaderActions';
import { TYPE_REVIZIE, TYPE_VERIFICARE } from '../CreareFisa';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';

type RVStepThreeProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  idAnexa: string;
};

type StepThreeType = {
  id_anexa: number;
  instructionReceivedConfirmation: boolean;
  operationsExecutionConfirmation: boolean;
  consumatoare_demontate: any[];
  adjustingEquipmentChecking: number;
  burnerChecking: number;
  cleaningDocumentationChecking: number;
  commonInstallationChecking: number;
  detectorsValidityChecking: number;
  fireplacesConditionChecking: number;
  flexibleConnectionChecking: number;
  instructionsExistenceChecking: number;
  detectorTested: number;
  measuringDevicesChecking: number;
  pipeSealingChecking: number;
  pressureResistanceTest: number;
  pressureSealingTest: number;
  stabilityChecking: number;
  stationsConditionChecking: number;
  technicalVerificationDocumentationChecking: number;
  unpluggingDevices: number;
};

const RVStepThree = (props: RVStepThreeProps) => {
  const dispatch = useDispatch();
  const { setStep, currentStep, progress, setProgress } = props;
  const [tipuriSarcini, setTipuriSarcini] = useState<DropdownOptionType[]>([]);

  const [verificareArzatoare, setVerificareArzatoare] = useState<any>('');
  const [verificareStabilitateConducte, setVerificareStabilitateConducte] = useState<any>('');
  const [devicesDemontate, setDevicesDemontate] = useState<any[]>([]);

  const [verificareEtanseitateConducte, setVerificareEtanseitateConducte] = useState<any>('');
  const [verificareFunctionareAparateMasura, setVerificareFunctionareAparateMasura] = useState<any>('');
  const [demontareAparate, setDemontareAparate] = useState<any>('');
  const [verificareEchipamentReglare, setVerificareEchipamentReglare] = useState<any>('');
  const [verificareStareRasuflareCamine, setVerificareStareRasuflareCamine] = useState<any>('');
  const [verificareDocumenteClientCuratareCosuri, setVerificareDocumenteClientCuratareCosuri] = useState<any>('');
  const [verificareStareConstructii, setVerificareStareConstructii] = useState<any>('');
  const [verificareDocumenteClientVerificareTehnica, setVerificareDocumenteClientVerificareTehnica] = useState<any>('');
  const [probaRezistentaLaPresiune, setProbaRezistentaLaPresiune] = useState<any>('');
  const [probaEtanseitateLaPresiune, setProbaEtanseitateLaPresiune] = useState<any>('');
  const [verificareRacordFlexibil, setVerificareRacordFlexibil] = useState<any>('');
  const [verificareDetectorGaze, setVerificareDetectorGaze] = useState<any>('');
  const [reviziaTehnicaComuna, setReviziaTehnicaComuna] = useState<any>('');
  const [verificareExistentaInstructiuniDeUtilizare, setVerificareExistentaInstructiuniDeUtilizare] = useState<any>('');
  const [testareFunctionareDetectoare, setTestareFunctionareDetectoare] = useState<any>('');
  const [confirmareEfectuareOperatiuni, setConfirmareEfectuareOperatiuni] = useState(false);
  const [confirmarePrimireExemplarInstructiuni, setConfirmarePrimireExemplarInstructiuni] = useState(false);

  //errors
  const [error, setError] = useState('');
  const [errorVerificareArzatoare, setErrorVerificareArzatoare] = useState(false);
  const [errorVerificareStabilitateConducte, setErrorVerificareStabilitateConducte] = useState(false);
  const [errorDevicesDemontate, setErrorDevicesDemontate] = useState(false);

  const [errorVerificareEtanseitateConducte, setErrorVerificareEtanseitateConducte] = useState(false);
  const [errorVerificareFunctionareAparateMasura, setErrorVerificareFunctionareAparateMasura] = useState(false);
  const [errorDemontareAparate, setErrorDemontareAparate] = useState(false);

  const [errorVerificareEchipamentReglare, setErrorVerificareEchipamentReglare] = useState(false);
  const [errorVerificareStareRasuflareCamine, setErrorVerificareStareRasuflareCamine] = useState(false);
  const [errorVerificareDocumenteClientCuratareCosuri, setErrorVerificareDocumenteClientCuratareCosuri] = useState(false);
  const [errorVerificareStareConstructii, setErrorVerificareStareConstructii] = useState(false);
  const [errorVerificareDocumenteClientVerificareTehnica, setErrorVerificareDocumenteClientVerificareTehnica] = useState(false);
  const [errorProbaRezistentaLaPresiune, setErrorProbaRezistentaLaPresiune] = useState(false);
  const [errorProbaEtanseitateLaPresiune, setErrorProbaEtanseitateLaPresiune] = useState(false);
  const [errorVerificareRacordFlexibil, setErrorVerificareRacordFlexibil] = useState(false);
  const [errorVerificareDetectorGaze, setErrorVerificareDetectorGaze] = useState(false);
  const [errorReviziaTehnicaComuna, setErrorReviziaTehnicaComuna] = useState(false);
  const [errorVerificareExistentaInstructiuniDeUtilizare, setErrorVerificareExistentaInstructiuniDeUtilizare] = useState(false);
  const [errorTestareFunctionareDetectoare, setErrorTestareFunctionareDetectoare] = useState(false);
  const [errorConfirmareEfectuareOperatiuni, setErrorConfirmareEfectuareOperatiuni] = useState(false);
  const [errorConfirmarePrimireExemplarInstructiuni, setErrorConfirmarePrimireExemplarInstructiuni] = useState(false);

  const dynamicTableFormat = {
    columns: [
      { name: 'nrCrt', type: 'id', dataType: '', colName: 'Nr. CRT' },
      { name: 'tip', type: 'select', options: tipuriSarcini, dataType: 'numeric', colName: 'Tip', width: '8rem' },
      { name: 'numar', type: 'input', dataType: 'string', colName: 'Numar', width: '8rem' },
      { name: 'debit', type: 'input', dataType: 'numeric', floatPrecision: 2, colName: 'Debit', width: '8rem' },
      { name: 'descriere', type: 'input', dataType: 'string', colName: 'Descriere', width: '10rem' }
    ]
  };

  useEffect(() => {
    if (tipuriSarcini.length > 0) {
      if (props.idAnexa) {
        dispatch(toggleLoader(true));
        getAnexaStepThree(props.idAnexa)
          .then((res) => {
            dispatch(toggleLoader(false));
            initializeData(res);
          })
          .catch((err) => {
            dispatch(toggleLoader(false));
          });
      }
    }
  }, [tipuriSarcini]);

  useEffect(() => {
    if (props.dictionare) {
      setTipuriSarcini(props?.dictionare?.DeviceType);
    }
  }, [props.dictionare]);

  useEffect(() => {
    if (demontareAparate == 'DA') {
      let valid = true;
      devicesDemontate.forEach((device) => {
        if (!device.tip || !device.debit || !device.descriere || !device.numar) {
          valid = false;
        }
      });
      if (valid) {
        setErrorDevicesDemontate(false);
      }
    } else if (devicesDemontate.length > 0) {
      setDevicesDemontate([]);
    }
  }, [devicesDemontate, demontareAparate]);

  useEffect(() => {
    if (verificareArzatoare) {
      setErrorVerificareArzatoare(false);
    }
    if (verificareStabilitateConducte) {
      setErrorVerificareStabilitateConducte(false);
    }
    if (verificareEtanseitateConducte) {
      setErrorVerificareEtanseitateConducte(false);
    }
    if (verificareFunctionareAparateMasura) {
      setErrorVerificareFunctionareAparateMasura(false);
    }
    if (demontareAparate) {
      setErrorDemontareAparate(false);
    }
    if (verificareEchipamentReglare) {
      setErrorVerificareEchipamentReglare(false);
    }
    if (verificareStareRasuflareCamine) {
      setErrorVerificareStareRasuflareCamine(false);
    }
    if (verificareDocumenteClientCuratareCosuri) {
      setErrorVerificareDocumenteClientCuratareCosuri(false);
    }
    if (verificareStareConstructii) {
      setErrorVerificareStareConstructii(false);
    }
    if (verificareDocumenteClientVerificareTehnica) {
      setErrorVerificareDocumenteClientVerificareTehnica(false);
    }
    if (probaRezistentaLaPresiune) {
      setErrorProbaRezistentaLaPresiune(false);
    }
    if (probaEtanseitateLaPresiune) {
      setErrorProbaEtanseitateLaPresiune(false);
    }
    if (verificareRacordFlexibil) {
      setErrorVerificareRacordFlexibil(false);
    }
    if (verificareDetectorGaze) {
      setErrorVerificareDetectorGaze(false);
    }
    if (reviziaTehnicaComuna) {
      setErrorReviziaTehnicaComuna(false);
    }
    if (verificareExistentaInstructiuniDeUtilizare) {
      setErrorVerificareExistentaInstructiuniDeUtilizare(false);
    }
    if (testareFunctionareDetectoare) {
      setErrorTestareFunctionareDetectoare(false);
    }
    if (confirmareEfectuareOperatiuni) {
      setErrorConfirmareEfectuareOperatiuni(false);
    }
    if (confirmarePrimireExemplarInstructiuni) {
      setErrorConfirmarePrimireExemplarInstructiuni(false);
    }
  }, [
    verificareArzatoare,
    verificareStabilitateConducte,
    verificareEtanseitateConducte,
    verificareFunctionareAparateMasura,
    demontareAparate,
    verificareEchipamentReglare,
    verificareStareRasuflareCamine,
    verificareDocumenteClientCuratareCosuri,
    verificareStareConstructii,
    verificareDocumenteClientVerificareTehnica,
    probaRezistentaLaPresiune,
    probaEtanseitateLaPresiune,
    verificareRacordFlexibil,
    verificareDetectorGaze,
    reviziaTehnicaComuna,
    verificareExistentaInstructiuniDeUtilizare,
    confirmareEfectuareOperatiuni,
    confirmarePrimireExemplarInstructiuni,
    testareFunctionareDetectoare
  ]);

  const mapValueToInt = (value: string) => {
    if (value == 'DA') {
      return 1;
    } else if (value == 'NU') {
      return 2;
    } else if (value == 'NU_ESTE_CAZUL') {
      return 3;
    }
  };

  const mapValueToString = (value: number) => {
    if (value == 1) {
      return 'DA';
    } else if (value == 2) {
      return 'NU';
    } else if (value == 3) {
      return 'NU_ESTE_CAZUL';
    }
  };

  const initializeData = (values: StepThreeType) => {
    setVerificareArzatoare(mapValueToString(values.burnerChecking));
    setVerificareStabilitateConducte(mapValueToString(values.stabilityChecking));
    setVerificareEtanseitateConducte(mapValueToString(values.pipeSealingChecking));
    setVerificareFunctionareAparateMasura(mapValueToString(values.measuringDevicesChecking));
    setDemontareAparate(mapValueToString(values.unpluggingDevices));

    setVerificareEchipamentReglare(mapValueToString(values.adjustingEquipmentChecking));
    setVerificareStareRasuflareCamine(mapValueToString(values.fireplacesConditionChecking));
    setVerificareDocumenteClientCuratareCosuri(mapValueToString(values.cleaningDocumentationChecking));
    setVerificareStareConstructii(mapValueToString(values.stationsConditionChecking));
    setVerificareDocumenteClientVerificareTehnica(mapValueToString(values.technicalVerificationDocumentationChecking));
    setReviziaTehnicaComuna(mapValueToString(values.commonInstallationChecking));
    setVerificareRacordFlexibil(mapValueToString(values.flexibleConnectionChecking));
    setVerificareDetectorGaze(mapValueToString(values.detectorsValidityChecking));
    setVerificareExistentaInstructiuniDeUtilizare(mapValueToString(values.instructionsExistenceChecking));
    setTestareFunctionareDetectoare(mapValueToString(values.detectorTested));
    setProbaRezistentaLaPresiune(mapValueToString(values.pressureResistanceTest));
    setProbaEtanseitateLaPresiune(mapValueToString(values.pressureSealingTest));
    setConfirmareEfectuareOperatiuni(values.operationsExecutionConfirmation);
    setConfirmarePrimireExemplarInstructiuni(values.instructionReceivedConfirmation);

    const initialDevicesDemontate = values.consumatoare_demontate.map((device) => {
      return {
        annexeDeviceId: device.annexeDeviceId ?? 0,
        tip: tipuriSarcini.find((ts) => ts.id == device.tip)?.name ?? '',
        numar: device.numar,
        debit: device.debit,
        descriere: device.descriere
      };
    });
    setDevicesDemontate(initialDevicesDemontate);
  };

  const submit = () => {
    if (validateValues()) {
      dispatch(toggleLoader(true));
      let payload: any = {
        burnerChecking: mapValueToInt(verificareArzatoare),
        stabilityChecking: mapValueToInt(verificareStabilitateConducte),
        pipeSealingChecking: mapValueToInt(verificareEtanseitateConducte),
        measuringDevicesChecking: mapValueToInt(verificareFunctionareAparateMasura),
        unpluggingDevices: mapValueToInt(demontareAparate),
        adjustingEquipmentChecking: mapValueToInt(verificareEchipamentReglare),
        fireplacesConditionChecking: mapValueToInt(verificareStareRasuflareCamine),
        cleaningDocumentationChecking: mapValueToInt(verificareDocumenteClientCuratareCosuri),
        stationsConditionChecking: mapValueToInt(verificareStareConstructii),
        technicalVerificationDocumentationChecking: mapValueToInt(verificareDocumenteClientVerificareTehnica),
        commonInstallationChecking: mapValueToInt(reviziaTehnicaComuna),
        flexibleConnectionChecking: mapValueToInt(verificareRacordFlexibil),
        detectorsValidityChecking: mapValueToInt(verificareDetectorGaze),
        instructionsExistenceChecking: mapValueToInt(verificareExistentaInstructiuniDeUtilizare),
        detectorTested: mapValueToInt(testareFunctionareDetectoare),
        pressureResistanceTest: mapValueToInt(probaRezistentaLaPresiune),
        pressureSealingTest: mapValueToInt(probaEtanseitateLaPresiune),
        operationsExecutionConfirmation: confirmareEfectuareOperatiuni,
        instructionReceivedConfirmation: confirmarePrimireExemplarInstructiuni,
        consumatoare_demontate: devicesDemontate.map((device) => {
          return {
            // annexeDeviceId: 0,
            tip: tipuriSarcini.find((ts) => ts.name == device.tip)?.id ?? '',
            numar: device.numar,
            debit: device.debit,
            descriere: device.descriere
          };
        })
      };
      if (props.idAnexa) {
        payload.id_anexa = props.idAnexa;
      }

      postAnexaStepThree(payload)
        .then((res) => {
          props.setStep(props.currentStep + 1);
          props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            props.setStep(props.currentStep + 1);
            props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const validateValues = () => {
    let valid = true;
    if (!verificareArzatoare) {
      setErrorVerificareArzatoare(true);
      valid = false;
    }
    if (!verificareStabilitateConducte) {
      setErrorVerificareStabilitateConducte(true);
      valid = false;
    }
    if (!verificareEtanseitateConducte) {
      setErrorVerificareEtanseitateConducte(true);
      valid = false;
    }
    if (!verificareFunctionareAparateMasura) {
      setErrorVerificareFunctionareAparateMasura(true);
      valid = false;
    }
    if (!demontareAparate) {
      setErrorDemontareAparate(true);
      valid = false;
    }
    if (!verificareEchipamentReglare) {
      setErrorVerificareEchipamentReglare(true);
      valid = false;
    }
    if (!verificareStareRasuflareCamine) {
      setErrorVerificareStareRasuflareCamine(true);
      valid = false;
    }
    if (!verificareDocumenteClientCuratareCosuri) {
      setErrorVerificareDocumenteClientCuratareCosuri(true);
      valid = false;
    }
    if (!verificareStareConstructii) {
      setErrorVerificareStareConstructii(true);
      valid = false;
    }
    if (!verificareDocumenteClientVerificareTehnica) {
      setErrorVerificareDocumenteClientVerificareTehnica(true);
      valid = false;
    }
    if (props.type == TYPE_REVIZIE && !probaRezistentaLaPresiune) {
      setErrorProbaRezistentaLaPresiune(true);
      valid = false;
    }
    if (props.type == TYPE_REVIZIE && !probaEtanseitateLaPresiune) {
      setErrorProbaEtanseitateLaPresiune(true);
      valid = false;
    }
    if (!verificareRacordFlexibil) {
      setErrorVerificareRacordFlexibil(true);
      valid = false;
    }
    if (!verificareDetectorGaze) {
      setErrorVerificareDetectorGaze(true);
      valid = false;
    }
    if (!reviziaTehnicaComuna) {
      setErrorReviziaTehnicaComuna(true);
      valid = false;
    }
    if (!verificareExistentaInstructiuniDeUtilizare) {
      setErrorVerificareExistentaInstructiuniDeUtilizare(true);
      valid = false;
    }
    if (!testareFunctionareDetectoare) {
      setErrorTestareFunctionareDetectoare(true);
      valid = false;
    }
    if (!confirmareEfectuareOperatiuni) {
      setErrorConfirmareEfectuareOperatiuni(true);
      valid = false;
    }
    if (!confirmarePrimireExemplarInstructiuni) {
      setErrorConfirmarePrimireExemplarInstructiuni(true);
      valid = false;
    }
    if (demontareAparate == 'DA') {
      devicesDemontate.forEach((device) => {
        if (!device.tip || !device.debit || !device.descriere || !device.numar) {
          setErrorDevicesDemontate(true);
          valid = false;
        }
      });
    }

    return valid;
  };

  return (
    <Fragment>
      {props.type == TYPE_REVIZIE ? (
        <h4 className="red mt-2">
          Operaţiunile care s-au realizat în cazul reviziei tehnice a instalaţiei de utilizare a gazelor naturale
        </h4>
      ) : (
        <h4 className="red mt-2">
          Operaţiunile care s-au realizat în cazul verificării tehnice a instalaţiei de utilizare a gazelor naturale
        </h4>
      )}

      <Row>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={'Verificarea arzătoarelor şi a stării îmbinărilor şi garniturilor de etanşare aferente*'}
            setValue={setVerificareArzatoare}
            currentValue={verificareArzatoare}
          />
          {errorVerificareArzatoare && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={'Verificarea stabilităţii conductelor montate aparent pe suporturi*'}
            setValue={setVerificareStabilitateConducte}
            currentValue={verificareStabilitateConducte}
          />
          {errorVerificareStabilitateConducte && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Verificarea etanşeităţii îmbinării conductelor şi armăturilor la presiunea de lucru a gazului din instalaţie, cu spumă de apă cu săpun sau cu alte tehnologii de verificare a etanşeităţii*'
            }
            setValue={setVerificareEtanseitateConducte}
            currentValue={verificareEtanseitateConducte}
          />
          {errorVerificareEtanseitateConducte && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={'Verificarea funcţionării aparatelor de măsurare, control, reglare şi de siguranţă*'}
            setValue={setVerificareFunctionareAparateMasura}
            currentValue={verificareFunctionareAparateMasura}
          />
          {errorVerificareFunctionareAparateMasura && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Demontarea/Debranşarea aparatelor consumatoare de combustibili gazoşi fără aprobare legală şi a instalaţiilor de utilizare a gazelor naturale aferente*'
            }
            setValue={setDemontareAparate}
            currentValue={demontareAparate}
          />
          {errorDemontareAparate && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}

          {demontareAparate == 'DA' && (
            <>
              <DynamicTable
                format={dynamicTableFormat}
                data={devicesDemontate}
                handler={(devices: any) => {
                  setDevicesDemontate(devices);
                }}
                isEditing={true}
                mbZero
              />
              {errorDevicesDemontate && (
                <div style={{ color: 'red', fontWeight: 'bold' }}>Vă rugăm să completați toate câmpurile din tabel!</div>
              )}
            </>
          )}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={'Verificarea funcţionării echipamentului de reglare din instalaţiile de utilizare*'}
            setValue={setVerificareEchipamentReglare}
            currentValue={verificareEchipamentReglare}
          />
          {errorVerificareEchipamentReglare && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={'Verificarea stării răsuflătorilor şi a căminelor existente*'}
            setValue={setVerificareStareRasuflareCamine}
            currentValue={verificareStareRasuflareCamine}
          />
          {errorVerificareStareRasuflareCamine && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Verificarea documentelor prezentate de client, din care să reiasă că a fost efectuată curaţarea coşurilor şi a canalelor de evacuare a gazelor de ardere de către operatorii economici autorizaţi, emise cu maximum 6 luni înainte de data verificării/reviziei tehnice a instalatiei de utilizare a gazelor naturale*'
            }
            setValue={setVerificareDocumenteClientCuratareCosuri}
            currentValue={verificareDocumenteClientCuratareCosuri}
          />
          {errorVerificareDocumenteClientCuratareCosuri && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={'Verificarea stării construcţiilor care adăpostesc staţiile şi posturi de reglare sau reglare­ măsurare*'}
            setValue={setVerificareStareConstructii}
            currentValue={verificareStareConstructii}
          />
          {errorVerificareStareConstructii && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Verificarea documentelor, prezentate de client, care să ateste efectuarea în termen a verificării tehnice periodice a aparatelor consumatoare de combustibili gazoşi de către operatorii economici autorizaţi de lnspecţia de Stat pentru Controlul Cazanelor, Recipientelor sub Presiune şi lnstalaţiilor de Ridicat*'
            }
            setValue={setVerificareDocumenteClientVerificareTehnica}
            currentValue={verificareDocumenteClientVerificareTehnica}
          />
          {errorVerificareDocumenteClientVerificareTehnica && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>

        {props.type == TYPE_REVIZIE && (
          <Col xs={12} md={12} className="mt-3">
            <RadioDaNuNuEsteCazul
              title={
                'Efectuarea probei de rezistenţă la presiune, conform prevederilor normelor tehnice din domeniul gazelor naturale, numai pentru partea de instalaţie la care s-au făcut înlocuiri şi/sau modificări*'
              }
              setValue={setProbaRezistentaLaPresiune}
              currentValue={probaRezistentaLaPresiune}
            />
            {errorProbaRezistentaLaPresiune && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
            <hr className="m-0 " />
          </Col>
        )}

        {props.type == TYPE_REVIZIE && (
          <Col xs={12} md={12} className="mt-3">
            <RadioDaNuNuEsteCazul
              title={
                'Efectuarea probei de etanşeitate la presiune, conform prevederilor normele tehnice din domeniul gazelor naturale, a întregii instalaţii de utilizare a gazelor naturale*'
              }
              setValue={setProbaEtanseitateLaPresiune}
              currentValue={probaEtanseitateLaPresiune}
            />
            {errorProbaEtanseitateLaPresiune && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
            <hr className="m-0 " />
          </Col>
        )}

        {props.type == TYPE_VERIFICARE && (
          <Col xs={12} md={12} className="mt-3">
            <RadioDaNuNuEsteCazul
              title={'Verificarea tehnică a instalaţiei comune de utilizare a gazelor naturale care deserveşte mai mulţi clienţi finali, cuprinsă între staţia sau postul de reglare şi sistemele/mijloacele de măsurare a gazelor naturale*'}
              setValue={setReviziaTehnicaComuna}
              currentValue={reviziaTehnicaComuna}
            />
            {errorReviziaTehnicaComuna && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
            <hr className="m-0 " />
          </Col>
        )}

        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Verificarea faptului că racordul flexibil montat în instalatia de utilizare este în termen de valabilitate, având în vedere durata normată de utilizare specificată în prescripţiile tehnice ale producătorului*'
            }
            setValue={setVerificareRacordFlexibil}
            currentValue={verificareRacordFlexibil}
          />
          {errorVerificareRacordFlexibil && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Verificarea faptului că detectorul/detectoarele automat/automate de gaze montat/montate la locul de consum este/sunt în termen de valabilitate, având în vedere durata normată de utilizare specificată în prescripţiile tehnice ale producătorului*'
            }
            setValue={setVerificareDetectorGaze}
            currentValue={verificareDetectorGaze}
          />
          {errorVerificareDetectorGaze && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>

        {props.type == TYPE_REVIZIE && (
          <Col xs={12} md={12} className="mt-3">
            <RadioDaNuNuEsteCazul
              title={'Revizia tehnică a instalaţiei comune de utilizare a gazelor naturale care deserveşte mai mulţi clienţi finali, cuprinsă între staţia sau postul de reglare şi sistemele/mijloacele de măsurare a gazelor naturale*'}
              setValue={setReviziaTehnicaComuna}
              currentValue={reviziaTehnicaComuna}
            />
            {errorReviziaTehnicaComuna && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
            <hr className="m-0 " />
          </Col>
        )}

        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Verificarea existenței instrucțiunilor de utilizarea a gazelor naturale, întocmite conform prevederilor Procedurii privind proiectarea, verificarea proiectului tehnic, execuția, recepția și punerea în funcțiune a instalațiilor de utilizare a gazelor naturale, aprobată prin Ordinul ANRE nr. 156/2020*'
            }
            setValue={setVerificareExistentaInstructiuniDeUtilizare}
            currentValue={verificareExistentaInstructiuniDeUtilizare}
          />
          {errorVerificareExistentaInstructiuniDeUtilizare && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <RadioDaNuNuEsteCazul
            title={
              'Testarea funcționării detectorului/detectoarelor automat/automate de gaze la locul de consum și a electroventilului/electroventilelor prin acționarea butonului de „Test” al detectorului*'
            }
            setValue={setTestareFunctionareDetectoare}
            currentValue={testareFunctionareDetectoare}
          />
          {errorTestareFunctionareDetectoare && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12} className="mt-3">
          <div onClick={() => setConfirmareEfectuareOperatiuni(!confirmareEfectuareOperatiuni)} className="checkbox-container pointer mb-2">
            <input
              className="create-account-checkbox pointer"
              type="checkbox"
              checked={confirmareEfectuareOperatiuni}
              onChange={() => setConfirmareEfectuareOperatiuni(!confirmareEfectuareOperatiuni)}
            />
            <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
              Confirm că au fost efectuate toate operaţiile enumerate în tabelul din pasul #3
            </span>
          </div>
          {errorConfirmareEfectuareOperatiuni && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <div
            onClick={() => setConfirmarePrimireExemplarInstructiuni(!confirmarePrimireExemplarInstructiuni)}
            className="checkbox-container pointer mb-2"
          >
            <input
              className="create-account-checkbox pointer"
              type="checkbox"
              checked={confirmarePrimireExemplarInstructiuni}
              onChange={() => setConfirmarePrimireExemplarInstructiuni(!confirmarePrimireExemplarInstructiuni)}
            />
            <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
              Confirm că am primit un exemplar al instrucţiunilor de utilizare a gazelor naturale
            </span>
          </div>
          {errorConfirmarePrimireExemplarInstructiuni && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu.</div>}
          <hr className="m-0 " />
        </Col>
      </Row>

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      <StepsNavigationButtons
        className="mb-3"
        onClickPrevious={() => {
          setStep(currentStep - 1);
          currentStep === progress && setProgress(currentStep - 1);
        }}
        onClickNext={() => {
          submit();
        }}
      />
    </Fragment>
  );
};

export default RVStepThree;
