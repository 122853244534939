import { proxy, Proxy } from '@api/azureProxy';
import { store } from '@lib/store';
import axios from 'axios';

export async function pvcSaveAgreement(payloadFormData: any) {
  const state = store.getState();

  return axios.post(`${state.options.apiHost}/meter-reading/PVC/save_agreement`, payloadFormData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export async function pvcSendSmsValidationCode(payload: any) {
  const state = store.getState();

  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/meter-reading/PVC/validate_agreement',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function PVCList(payload: any) {
  const state = store.getState();

  return axios.get(`${state.options.apiHost}/meter-reading/PVCSupplier/list?${payload.join('&')}`, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function downloadPVC(payload: any) {
  const state = store.getState();

  return axios.get(`${state.options.apiHost}/meter-reading/PVCSupplier/download?supplierDocumentId=${payload}`, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}
