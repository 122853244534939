import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import DetaliiIncarcareInMasa from '@components/revizii/DetaliiIncarcareInMasa/DetaliiIncarcareInMasa';

export const DetaliiIncarcareInMasaComponent = () => {
  return (
    <DelgazWrapper>
      <DetaliiIncarcareInMasa />
    </DelgazWrapper>
  );
};
