import React, { useState, useEffect } from 'react';
import { PVAEList, downloadPVAE } from '@api/pvae';
import { Button, Col, Row } from 'react-bootstrap';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { Input } from '@components/common/Input';
import ReactDatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import { ro } from 'date-fns/locale';
import moment from 'moment';
import * as notification from '@lib/Notification';
import Pagination from '@components/common/Pagination/Pagination';

registerLocale('ro', ro);

const InfoPVRFurnizor = () => {
  const [list, setList] = useState([]);
  const [pod, setPod] = useState('');
  const [year, setYear] = useState<Date | null>(null);
  const [month, setMonth] = useState<Date | null>(null);
  const [err, setErr] = useState('');

  //paginare
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getList(false);
  }, [currentPage, perPage]);

  const getList = async (isReset: boolean) => {
    setErr('');

    dispatch(toggleLoader(true));

    let payload: any;
    if(!isReset) {
      payload = [
        pod !== '' ? `pod=${pod}` : '',
        year ? `year=${year?.getFullYear()}` : '',
        month ? `month=${month?.getMonth() + 1}` : '',
        `pageNumber=${currentPage}`,
        `pageSize=${perPage}`,
        'utility=2'
      ];
    } else {
      payload = [
        `pageNumber=${currentPage}`,
        `pageSize=${perPage}`,
        'utility=2'
      ];
    }

    await PVAEList(payload)
      .then((response) => {
        if (response.data.items) {
          setList(response.data.items);
          setTotalItemsCount(response.data.metadata.totalItemsCount);
        } else {
          setErr('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      })
      .catch((err) => setErr('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.'));

    dispatch(toggleLoader(false));
  };

  const download = async (id: string) => {
    dispatch(toggleLoader(true));
    let isError = false;
    let res = '' as BlobPart;
    res = await downloadPVAE(id).catch((error) => {
      isError = true;
      dispatch(toggleLoader(false));
      notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
    });

    if (!isError) {
      try {
        setTimeout(() => {
          const blob: Blob = new Blob([res]);

          const fileName: string = `Document-PVR-${id}.pdf`;
          const objectUrl: string = URL.createObjectURL(blob);
          const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

          a.href = objectUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(objectUrl);
          dispatch(toggleLoader(false));
        }, 500);
      } catch (error) {
        dispatch(toggleLoader(false));
      }
    }

    dispatch(toggleLoader(false));
  };

  return (
    <div>
      <Row>
        <Col xs={12} sm={4} md={2} className="m-0">
          <Input label="POD" value={pod} onChange={(e) => setPod(e.target.value)} />
        </Col>
        <Col xs={12} sm={4} md={2} className="mb-4">
          <ReactDatePicker selected={year} placeholderText={'Anul'} onChange={(date) => setYear(date)} showYearPicker dateFormat="yyyy" />
        </Col>
        <Col xs={12} sm={4} md={2} className="mb-4">
          <ReactDatePicker
            selected={month}
            placeholderText={'Luna'}
            onChange={(date) => setMonth(date)}
            dateFormat="MMM"
            showMonthYearPicker
            renderCustomHeader={() => <div className="fw-bold">{year !== null && moment(year).format('YYYY')}</div>}
            locale="ro"
          />
        </Col>
        <Col xs={12} md={3} className="m-0 p-1 justify-content-center">
          <div className="d-flex justify-content-center w-100">
            <Button
              style={{ marginRight: '5px' }}
              onClick={() => {
                setCurrentPage(1);
                getList(false);
              }}
            >
              Caută
            </Button>
            <Button
              onClick={() => {
                setPod('');
                setYear(null);
                setMonth(null);
                getList(true);
              }}
            >
              Resetează
            </Button>
          </div>
        </Col>
      </Row>
      <div className="general-table-container mb-3">
        <table className="w-100" style={{ margin: '0 auto' }}>
          <thead>
            <tr>
              <th>POD</th>
              <th>Dată document PVR</th>
              <th>Descarcă document PVR</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item: any, index) => (
              <tr key={index} style={{ height: '25px' }}>
                <td>{item.pod}</td>
                <td>{item.pvaeDate ? moment(item.pvaeDate, 'YYYYMMDD').format('DD.MM.YYYY') : '-'}</td>
                <td>
                  <Button onClick={() => download(item.supplierDocumentId)}>Descarcă</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalElements={totalItemsCount}
        perPage={perPage}
        paginate={paginate}
        setElementsPerPage={setElementsPerPage}
      />

      {err !== '' && <p className="red fw-bold text-center">{err}</p>}
    </div>
  );
};

export default InfoPVRFurnizor;
