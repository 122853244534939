import { toggleLoader } from '@actions/LoaderActions';
import { exportSupplierConventiiConsum } from '@api/conventions/conventions';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { getClientPortofolio, getSupplierEEPortofolio, getSupplierGNPortofolio, postClientPortofolioExport, postSupplierAnexa2Export, postSupplierEEPortofolioExport, postSupplierGNPortofolioExport } from '@api/user/user';
import TabEE from '@components/account/MyAccount/ConsumptionPointsList/TabEE';
import TabGN from '@components/account/MyAccount/ConsumptionPointsList/TabGN';
import Pagination from '@components/common/Pagination/Pagination';
import { IDENTITY_ROLE_INTERNAL_EVERYONE } from '@constants/Roles/RolesIdentity';
import * as notification from '@lib/Notification';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const TAB_EE = 'tabEE';
const TAB_GN = 'tabGN';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
    SupplierId: string;
    Utilitate: string;
}

type ConsumptionPoint = {
    clientCode: string,
    clientName: string,
    consumptionCode: string,
    pod: string,
    address: string,
    contractStatus: string,
    meterNumber: string,
    connectionStatus: string,
    tariffCategory: string,
    srmp: string,
    minPressure: string,
    maxPressure: string,
    technicalMinPressure: string,
    contractCapacity: number,
    contractDuration: string,
    supplierCode: string,
    partnerId: string,
    invoiceClass: string,
    tariff: string,
    contractNo: string
};

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

type payloadTemplate = {
    pageSize?: number;
    pageNumber?: number;
    userId?: string,
    supplierId?: string,
    utility?: number,
    pod?: string,
    clc?: string,
    countyId?: number,
    localityId?: number,
    partnerCode?: string
}

const ConsumptionPointsList = (props: any) => {
    const [selectedTab, setSelectedTab] = useState('');

    const [consumptionPointsEE, setConsumptionPointsEE] = useState<ConsumptionPoint[] | null>(null);
    const [consumptionPointsGN, setConsumptionPointsGN] = useState<ConsumptionPoint[] | null>(null);
    const [searchCLC, setSearchCLC] = useState('');
    const [searchPod, setSearchPod] = useState('');
    const [searchPartnerCode, setSearchPartnerCode] = useState('');
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>();
    const [locality, setLocality] = useState('');
    const [didSearch, setDidSearch] = useState(false);
    const [didSearchGN, setDidSearchGN] = useState(false);
    const dispatch = useDispatch();
    const [isGN, setIsGN] = useState(false);
    const [isEE, setIsEE] = useState(false);
    const [gnSupplierId, setGNSupplierId] = useState('');
    const [eeSupplierId, setEESupplierId] = useState('');
    const [clientId, setClientId] = useState('');
    const [showExportAnexa2Button, setShowExportAnexa2Button] = useState(false);

    const [totalItemsCountEE, setTotalItemsCountEE] = useState(0);
    const [totalItemsCountGN, setTotalItemsCountGN] = useState(0);

    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;

    //paginare EE
    const [currentPageEE, setCurrentPageEE] = useState(1);
    const [perPageEE, setPerPageEE] = useState(10);
    const paginateEE = (pageNumber: number) => setCurrentPageEE(pageNumber);
    const setElementsPerPageEE = (number: number) => setPerPageEE(number);

    //paginare GN
    const [currentPageGN, setCurrentPageGN] = useState(1);
    const [perPageGN, setPerPageGN] = useState(10);
    const paginateGN = (pageNumber: number) => setCurrentPageGN(pageNumber);
    const setElementsPerPageGN = (number: number) => setPerPageGN(number);

    const [isFurnizor, setIsFurnizor] = useState(false);

    useEffect(() => {
        if(isEE && selectedTab == TAB_EE) {
            submitSearchKeepCurrentPageNumber(1);
        }
      }, [currentPageEE, perPageEE, selectedTab]);

      useEffect(() => {
        if(isGN && selectedTab == TAB_GN) {
            submitSearchKeepCurrentPageNumber(2);
        }
      }, [currentPageGN, perPageGN, selectedTab]);
    
    useEffect(() => {
        if(eeSupplierId) {
            setShowExportAnexa2Button(true);
        }
    },[eeSupplierId]);

    useEffect(() => {
        if(clientId) {
                mountConsumptionPoints(1, '', '', '', '', '');
                mountConsumptionPoints(2, '', '', '', '', '');
        }
    }, [clientId]);

    useEffect(() => {
        GetCounties().then(res => setCounties(res));
    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    useEffect(() => {
        if(token) {
            let jwt: JWT = jwtDecode(token);
            let supplierId = jwt.SupplierId;
            let utility = jwt.Utilitate;

            if(supplierId && supplierId.includes(';')) {
                //Furnizor 2 utilitati
                const supplierIds = supplierId.split(';');
                const utilities = utility.split(';');
                setSelectedTab(TAB_EE);

                if(utilities[0] === '1') {
                    setIsEE(true);
                    setEESupplierId(supplierIds[0]);
                } else if (utilities[0] === '2') {
                    setIsGN(true);
                    setGNSupplierId(supplierIds[0]);
                }
                if(utilities[1] === '1') {
                    setIsEE(true);
                    setEESupplierId(supplierIds[1]);
                } else if (utilities[1] === '2') {
                    setIsGN(true);
                    setGNSupplierId(supplierIds[1]);
                }
            } else if (supplierId) {
                //Furnizor o utilitate
                if(jwt.Utilitate === '1') {
                    setEESupplierId(supplierId);
                    setIsEE(true);
                    setSelectedTab(TAB_EE);
                } else {
                    setGNSupplierId(supplierId);
                    setIsGN(true);
                    setSelectedTab(TAB_GN);
                }
            } else {
                //Client
                setClientId(jwt.userid);
                    setIsEE(true);
                    setIsGN(true);
                setSelectedTab(TAB_EE);
            }
        }
    },[token]);

    const handleLocalityChange = (locality: any) => {
        setLocality(locality);
    };

    const handleSearchCLC = (clc: any) => {
        setSearchCLC(clc);
    };

    const handleSearchPod = (pod: any) => {
        setSearchPod(pod);
    };

    const handleSearchPartnerCode = (partnerCode: any) => {
        setSearchPartnerCode(partnerCode);
    };

    const handleCountyChange = (county: any) => {
        setCounty(county);
    };

    const submitSearch = (utility: number) => {
        if (utility === 1) {
          setDidSearch(true);
        } else if (utility === 2) {
          setDidSearchGN(true);
        }
        mountConsumptionPoints(utility, county, locality, searchPod, searchCLC, searchPartnerCode, true);
      };

      const submitSearchKeepCurrentPageNumber = (utility: number) => {
        utility === 1 && setDidSearch(true);
        utility === 2 && setDidSearchGN(true);
        mountConsumptionPoints(utility, county, locality, searchPod, searchCLC, searchPartnerCode);
      };

    const resetSearch = (utility: number) => {
        setCounty('');
        setLocality('');
        setSearchPod('');
        setSearchCLC('');
        setSearchPartnerCode('');
        mountConsumptionPoints(utility, '', '', '', '', '', true);
    };

    const mountConsumptionPoints = async (utility?: any, county?: any, locality?: any, pod?: any, clc?: any, partnerCode?: any ,firstPage?: boolean) => {
        dispatch(toggleLoader(true));
        let jwt: JWT = jwtDecode(token);
        const role = jwt.groups.filter(role => role !== IDENTITY_ROLE_INTERNAL_EVERYONE)[0];

        //set supplier id from token
        let payload: payloadTemplate = { supplierId: jwt.SupplierId };
        payload.userId = jwt.userid;

        if (pod !== '') {
            payload.pod = pod;
        }
        if (clc !== '') {
            payload.clc = clc;
        }
        if (partnerCode !== '') {
            payload.partnerCode = partnerCode;
        }
        if (county !== '') {
            let id = 0;

            counties.map((item) => {
                if (item.countyCode === county) {
                    id = item.countyId;
                }
            });

            payload.countyId = id;
        }

        if (locality !== '' ) {
            payload.localityId = Number(locality);
        }

        if (utility !== null) {
            payload.utility = utility;
            if(firstPage) {
                payload.pageNumber = 1;
                payload.pageSize = 10;
            } else {
                if(utility == 1) {
                    payload.pageNumber = currentPageEE;
                    payload.pageSize = perPageEE;
                } else {
                    payload.pageNumber = currentPageGN;
                    payload.pageSize = perPageGN;
                }
            }
        }

        let newListEE: ConsumptionPoint[] = [];
        let newListGN: ConsumptionPoint[] = [];

        if (role === 'ClientFizic' || role === 'ClientJuridic') {
            await getClientPortofolio(payload).then((res) => {
                res && res.items && res.items.map((item: ConsumptionPoint) => {
                    if (utility === 1) {
                        setTotalItemsCountEE(res.metadata.totalItemsCount);
                        newListEE.push(item);
                    }
                    if (utility === 2) {
                        setTotalItemsCountGN(res.metadata.totalItemsCount);
                        newListGN.push(item);
                    }
                });
            }).catch((e: any) => {});
        } else if (role === 'Furnizor') {
            setIsFurnizor(true);
            if (isEE && selectedTab == TAB_EE) {
                payload.supplierId = eeSupplierId;
                payload.utility = 1;
                await getSupplierEEPortofolio(payload).then((res) => {
                    if(res && res.items) {
                    setTotalItemsCountEE(res.metadata.totalItemsCount);
                    res.items.map((item: ConsumptionPoint) => {
                            newListEE.push(item);
                        });
                    }
                     
                }).catch((e: any) => {});
            }
            if (isGN && selectedTab == TAB_GN) {
                payload.supplierId = gnSupplierId;
                payload.utility = 2;
                await getSupplierGNPortofolio(payload).then((res) => {
                    if(res && res.items) {
                    setTotalItemsCountGN(res.metadata.totalItemsCount);
                    res.items.map((item: ConsumptionPoint) => {
                            newListGN.push(item);
                        });
                    }
                    
                }).catch((e: any) => {});
            }
    }

        if (utility === 1) {
            setConsumptionPointsEE(newListEE);
        } else {
            setConsumptionPointsGN(newListGN);
        }

        if(firstPage) {
            if(utility == 1) {
                setCurrentPageEE(1);
            } else {
                setCurrentPageGN(1);
            }
        }
        dispatch(toggleLoader(false));
    };

    const getCountyId = (code: string) => {
        let id = 0;
        counties.map((county, index) => {
            if (county.countyCode === code) {
                id = county.countyId;
            }
        });
        return id;
    };

    const  onExportList = async (utility: string) => {
        dispatch(toggleLoader(true));

        let payload: payloadTemplate = {};
        payload.countyId = getCountyId(county);
        payload.localityId = Number(locality);
        payload.utility = Number(utility);
        payload.pod = searchPod;
        payload.clc = searchCLC;
        payload.partnerCode = searchPartnerCode;

        let res = '' as BlobPart;

        if (isFurnizor) {
            if (utility === '2' && isGN) {
              payload.supplierId = gnSupplierId;
              res = await postSupplierGNPortofolioExport(payload);
            } else if (utility === '1' && isEE) {
              payload.supplierId = eeSupplierId;
              res = await postSupplierEEPortofolioExport(payload);
            }
          } else {
            res = await postClientPortofolioExport(payload);
          }

        try {
            setTimeout(() => {
                const blob: Blob = new Blob([res]);

                // const fileName: string = config ? config.exportButton.fileName.replace('{timestamp}', moment().format('YYMMDDHms')) : `export-${moment().format('YYMMDDHms')}.xlsx`;
                const fileName: string = `export-lista-locuri-de-consum-${moment().format('YYMMDDHms')}.xlsx`;
                const objectUrl: string = URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                dispatch(toggleLoader(false));
              }, 500);

          } catch (error) {
            // onError();
            dispatch(toggleLoader(false));
          }
    };

    const  onExportAnexa2 = async () => {
        dispatch(toggleLoader(true));

        let payload: payloadTemplate = { supplierId: eeSupplierId };
        payload.countyId = getCountyId(county);
        payload.localityId = Number(locality);
        payload.utility = 1;
        payload.pod = searchPod;
        payload.clc = searchCLC;

        let res = '' as BlobPart;
        res = await postSupplierAnexa2Export(payload);

        try {
            setTimeout(() => {
                const blob: Blob = new Blob([res]);

                // const fileName: string = config ? config.exportButton.fileName.replace('{timestamp}', moment().format('YYMMDDHms')) : `export-${moment().format('YYMMDDHms')}.xlsx`;
                const fileName: string = `export-anexa-2-${moment().format('YYMMDDHms')}.xlsx`;
                const objectUrl: string = URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                dispatch(toggleLoader(false));
              }, 500);

          } catch (error) {
            // onError();
            dispatch(toggleLoader(false));
          }
    };

    const onExportConventiiConsum = async () => {
        dispatch(toggleLoader(true));
        let payload: any = {};
        payload.countyId = getCountyId(county);
        payload.localityId = Number(locality);
        payload.pod = searchPod;
        payload.clc = searchCLC;

        let res = '' as BlobPart;

        try {
            res = await exportSupplierConventiiConsum(payload);
            setTimeout(() => {
                const blob: Blob = new Blob([res]);
                const fileName: string = `export-conventii-consum-${moment().format('YYMMDDHms')}.xlsx`;
                const objectUrl: string = URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                dispatch(toggleLoader(false));
              }, 500);

          } catch (error) {
            notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
            dispatch(toggleLoader(false));
          }
    };

    const onSelectTab = (selectedTab: string | null) => {
        setSelectedTab(selectedTab ?? '');
        //reset filters
        setCounty('');
        setLocality('');
        setSearchCLC('');
        setSearchPod('');
        setSearchPartnerCode('');
    };

    return (
        <div>
            <Tabs className="my-account-tabs" mountOnEnter={true} unmountOnExit={true} onSelect={onSelectTab}>
                {
                    isEE &&
                    <Tab eventKey={TAB_EE} title="Portofoliu Energie Electrica">
                    <TabEE
                        consumptionPoints={consumptionPointsEE}
                        countyChange={handleCountyChange}
                        counties={counties}
                        localityChange={handleLocalityChange}
                        localities={localities}
                        submitSearch={submitSearch}
                        county={county}
                        locality={locality}
                        clcChange={handleSearchCLC}
                        podChange={handleSearchPod}
                        partnerCodeChange={handleSearchPartnerCode}
                        reset={resetSearch}
                        searchPod={searchPod}
                        searchCLC={searchCLC}
                        searchPartnerCode={searchPartnerCode}
                        didSearch={didSearch}
                        exportList={onExportList}
                        exportAnexa2={onExportAnexa2}
                        exportConventiiConsum={onExportConventiiConsum}
                        showExportAnexa2Button={showExportAnexa2Button}
                        isFurnizor={isFurnizor}
                    />
                    {consumptionPointsEE && consumptionPointsEE.length > 0 && (
                    <Pagination
                        currentPage={currentPageEE}
                        totalElements={totalItemsCountEE}
                        perPage={perPageEE}
                        paginate={paginateEE}
                        setElementsPerPage={setElementsPerPageEE}
                    />
                    )}
                </Tab>
                }
                {
                    isGN && 
                    <Tab eventKey={TAB_GN} title="Portofoliu Gaze Naturale">
                    <TabGN
                        consumptionPoints={consumptionPointsGN}
                        countyChange={handleCountyChange}
                        counties={counties}
                        localityChange={handleLocalityChange}
                        localities={localities}
                        submitSearch={submitSearch}
                        county={county}
                        locality={locality}
                        clcChange={handleSearchCLC}
                        podChange={handleSearchPod}
                        partnerCodeChange={handleSearchPartnerCode}
                        reset={resetSearch}
                        searchPod={searchPod}
                        searchCLC={searchCLC}
                        searchPartnerCode={searchPartnerCode}
                        didSearch={didSearchGN}
                        exportList={onExportList}
                        isFurnizor={isFurnizor}
                    />
                    {consumptionPointsGN && consumptionPointsGN.length > 0 && (
                    <Pagination
                        currentPage={currentPageGN}
                        totalElements={totalItemsCountGN}
                        perPage={perPageGN}
                        paginate={paginateGN}
                        setElementsPerPage={setElementsPerPageGN}
                    />
                    )}
                </Tab>
                }
            </Tabs>
        </div>
    );
};

export default ConsumptionPointsList;
