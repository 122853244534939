import { toggleLoader } from '@actions/LoaderActions';
import { verifyCP } from '@api/checkDisturbance';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import MaskedInput from '@components/common/Input/MaskedInput';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CheckDisturbanceTiketStatusSchema } from '../../../../helpers/schemas';

type VerificaStareaUnuiDeranjamentProps = {
  submit: (i: any) => void;
  verify: (i: any) => void;
  error: string | any;
  success: string | any;
  syncErrors: string[] | any;
  uuid: string;
  setActiveTabKey: (i: any) => void;
  resetUuid: () => void;
};

const CREATED = 'created';
const STARTED = 'started';
const IN_PROGRESS = 'in_progress';
const FINISHED = 'finished';

const ANUNTA_DERANJAMENT_TAB_KEY = 'cpf';

//eslint-disable-next-line
const formatChars = {
  e: '[Ee]',
  m: '[Mm]',
  o: '[Oo]',
  '9': '[0123456789]'
};

const VerificaStareaUnuiDeranjament = (props: VerificaStareaUnuiDeranjamentProps) => {
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [county, setCounty] = useState('');
  const [localities, setLocalities] = useState<LocalityTemplate[]>();
  //eslint-disable-next-line
  const [locality, setLocality] = useState('');
  const [measurementPointCode, setMeasurementPointCode] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState<string | any>('');
  const [responseCPCheck, setResponseCPCheck] = useState({
    locality: '',
    street: '',
    omsStatus: '',
    progressStatus: '',
    type: '',
    solvingDate: '',
    wasFetched: false
  });
  const {resetUuid} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (responseCPCheck.locality && responseCPCheck.progressStatus) {
      switch (responseCPCheck.progressStatus) {
        case CREATED:
          if (responseCPCheck.solvingDate) {
            setMessage(
              <p>
                Există evenimente înregistrate pe locul de consum <span style={{ color: '#ea1c0a' }}>{measurementPointCode}</span>.
                <br />
                Colegii noștri au luat la cunoștință și se străduiesc să reducă durata intervenției! Data estimativă pentru realimentarea cu
                energie electrică în localitatea <span style={{ color: '#ea1c0a' }}>{responseCPCheck.locality}</span>, str. <span style={{ color: '#ea1c0a' }}>{responseCPCheck.street}</span>, este{' '}
                <span style={{ color: '#ea1c0a' }}>{moment(responseCPCheck?.solvingDate).format('DD/MM/YYYY HH:mm')}</span>.
                <br />
                Vă mulțumim pentru implicare și înțelegere!
              </p>
            );
          } else {
            setMessage(
              <p>
                Sesizarea dvs a fost înregistrată. Vom face toate demersurile necesare pentru restabilirea alimentării cu energie electrică{' '}
                în cel mai scurt timp.
                <br />
                Vă mulțumim pentru ințelegere.
              </p>
            );
          }
          break;
        case STARTED:
          if (responseCPCheck.solvingDate) {
            setMessage(
              <p>
                Echipa noastră tehnică a preluat solicitarea și se indreaptă catre dvs.
                <br />
                Data estimativă pentru realimentarea cu energie electrică în localitatea <span style={{ color: '#ea1c0a' }}>{responseCPCheck.locality}</span>, str.{' '}
                <span style={{ color: '#ea1c0a' }}>{responseCPCheck.street}</span>, este <span style={{ color: '#ea1c0a' }}>{moment(responseCPCheck?.solvingDate).format('DD/MM/YYYY HH:mm')}</span>.
              </p>
            );
          } else {
            setMessage(<p>Echipa noastră tehnică a preluat solicitarea și se indreaptă catre dvs.</p>);
          }
          break;
        case IN_PROGRESS:
          if (responseCPCheck.solvingDate) {
            setMessage(
              <p>
                Echipa noastră tehnică se află la locul incidentului și lucrează la restabilirea alimentării cu energie.
                <br />
                Data estimativă pentru realimentarea cu energie electrică în localitatea <span style={{ color: '#ea1c0a' }}>{responseCPCheck.locality}</span>, str.{' '}
                <span style={{ color: '#ea1c0a' }}>{responseCPCheck.street}</span>, este <span style={{ color: '#ea1c0a' }}>{moment(responseCPCheck?.solvingDate).format('DD/MM/YYYY HH:mm')}</span>.
              </p>
            );
          } else {
            setMessage(<p>Echipa noastră tehnică se află la locul incidentului și lucrează la restabilirea alimentării cu energie.</p>);
          }
          break;
        case FINISHED:
          setMessage(
            <p>
              În momentul de față nu avem deranjament în lucru pentru acest loc de consum. Pentru a înregistra un deranjament click{' '}
              <a onClick={() => props.setActiveTabKey(ANUNTA_DERANJAMENT_TAB_KEY)}>aici</a> sau accesați secțiunea “Anunță deranjament”.
            </p>
          );
          break;
      }
    } else {
      if (responseCPCheck.wasFetched) {
        setMessage(
          <p>
            În momentul de față nu avem deranjament în lucru pentru acest loc de consum. Pentru a înregistra un deranjament click{' '}
            <a
              style={{ color: '#0D6EFD', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => props.setActiveTabKey(ANUNTA_DERANJAMENT_TAB_KEY)}
            >
              aici
            </a>{' '}
            sau accesați secțiunea “Anunță deranjament”.
          </p>
        );
      }
    }
  }, [responseCPCheck]);

  useEffect(() => {
    GetCounties('EE').then((res: any) => {
      setCounties(res);
    });
  }, []);

  useEffect(() => {
    county !== '' &&
      GetLocalities(county).then((res: any) => {
        setLocalities(res);
      });
  }, [county]);

  const submit = (values: any) => {
    dispatch(toggleLoader(true));
    // values.uuid = props.uuid;
    // values.isCreate = false;

    dispatch(toggleLoader(true));
    verifyCP(values)
      .then((res: any) => {
        res.wasFetched = true;
        setResponseCPCheck(res);
        setError('');
        dispatch(toggleLoader(false));
      })
      .catch((er) => {
        try {
          if (er.includes('Consumption point could not be found')) {
            setError('Date identificare client greșite!');
          } else {
            setError('A intervenit o eroare in procesul de verificare a punctului de măsurare. Va rugam să reveniți!');
          }
        } catch (ex) {
          setError('A intervenit o eroare in procesul de verificare a punctului de măsurare. Va rugam să reveniți!');
        }
        
        dispatch(toggleLoader(false));
      });
  };

  return (
    <div className="check-disturbance-form-container">
      <Formik
        initialValues={{
          localityCode: '',
          countyCode: '',
          measurementPointCode: ''
        }}
        validationSchema={CheckDisturbanceTiketStatusSchema}
        onSubmit={(values) => submit(values)}
      >
        {(props) => (
          <Form>
            <div className="form-container">
              <div className="mt-4 mb-4 ml-4 d-flex flex-column">
                <Row className="w-100 mb-2 mt-3">
                  <p>Pentru verificarea statusului unui tichet, vă rugăm să completați câmpurile de mai jos.</p>
                </Row>
                <Row className="w-100 mt-3 mb-1">
                  <span>
                    <strong>Codul punctului de măsurare</strong> este de forma <strong>EMOxxxxxxx</strong> și îl regăsiți pe factură la
                    rubrica - Cod punct de măsurare
                  </span>
                </Row>
                <Row className="w-100 mb-2">
                  <Col xs={12} md={4} className='mt-2'>
                    <Dropdown
                      style={{ marginBottom: '0.25rem' }}
                      options={
                        counties.map((option, index) => ({
                          id: option.countyCode,
                          name: option.countyName
                        })) as DropdownOptionType[]
                      }
                      label={'Județ'}
                      defaultPlaceholder={'Alege județul'}
                      displaySearch={true}
                      onChange={(value) => {
                        value && props.setFieldValue('countyCode', value);
                        value.length === 0 && props.setFieldValue('countyCode', '');
                        setCounty(value);
                      }}
                    />
                    <ErrorMessage name="countyCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>

                  <Col xs={12} md={4} className='mt-2'>
                    {/* locality select */}
                    {county ? (
                      <Dropdown
                        style={{ marginBottom: '0.25rem' }}
                        options={
                          localities
                            ? (localities.map((option, index) => ({
                                id: option.localityCode,
                                name: option.localityName
                              })) as DropdownOptionType[])
                            : []
                        }
                        label={'Localitate'}
                        defaultPlaceholder={'Alege localitatea'}
                        displaySearch={true}
                        onChange={(value) => {
                          value && props.setFieldValue('localityCode', value);
                          value.length === 0 && props.setFieldValue('localityCode', '');
                          setLocality(value);
                        }}
                      />
                    ) : (
                      <>
                        <Dropdown style={{ marginBottom: '0.25rem' }} label={'Localitate'} disabled />
                      </>
                    )}
                    <ErrorMessage name="localityCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>

                  <Col xs={12} md={4} className='mt-2'>
                    <Field
                      id="measurementPointCode"
                      name="measurementPointCode"
                      placeholder="Cod punct de măsurare"
                      render={() => (
                          <MaskedInput
                              value={measurementPointCode}
                              mbZero
                                onChange={(e: any) => {
                                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                  e.target.value.length !== 0 && props.setFieldValue('measurementPointCode', currentValue);
                                  e.target.value.length === 0 && props.setFieldValue('measurementPointCode', '');
                                  setMeasurementPointCode(currentValue);
                                }}
                              onBlur={() => {
                                  props.setFieldTouched('measurementPointCode');
                              }}
                              utility='EE'
                              label='Cod punct de măsurare'
                              mask={'0123333333'}
                          />
                        // <Input
                        //   label="Cod punct de masurare"
                        //   mbZero
                        //   onBlur={() => {
                        //     props.setFieldTouched('measurementPointCode');
                        //   }}
                        //   onChange={(e: any) => {
                        //     const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                        //     e.target.value.length !== 0 && props.setFieldValue('measurementPointCode', currentValue);
                        //     e.target.value.length === 0 && props.setFieldValue('measurementPointCode', '');
                        //     setMeasurementPointCode(currentValue);
                        //   }}
                        // />
                      )}
                    />
                    <ErrorMessage
                      name="measurementPointCode"
                      render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                    />
                  </Col>
                </Row>

                {error && (
                  <Row>
                    <div className="mt-2">
                      <p style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{error}</p>
                    </div>
                  </Row>
                )}

                {message && !error && message}

                <Row>
                  <Col md={6} className="mt-1">
                    <Button type="submit" onClick={() => resetUuid()}>Verifică status tichet</Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerificaStareaUnuiDeranjament;
