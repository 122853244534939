import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RomcardCallback from '@components/invoices/RomcardCallback';

export const RomcardCallbackComponent = (props: any): JSX.Element => {
  return (
    <DelgazWrapper>
      <RomcardCallback {...props} />
    </DelgazWrapper>
  );
};
