import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '@type/store';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { ChangeEmailSchema } from '../../../../helpers/schemas';
import { login, chageUsernameRequest } from '@api/user/user';
import { toggleLoader } from '@actions/LoaderActions';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { Input } from '@components/common/Input';

const ChangeEmailGenerateEmail = (props: any) => {
  const [error, setError] = useState<string>('');
  const [showModalConfirmChangeEmail, setShowModalConfirmChangeEmail] = useState<boolean>(false);
  const [showModalEmailWasGenerated, setShowModalEmailWasGenerated] = useState<boolean>(false);
  const [values, setValues] = useState<any>(null);

  const deviceId: string | undefined = useSelector<IState>((state) => state?.device?.deviceId) as string;
  const dispatch = useDispatch();

  const submitEmailChange = async (values: any) => {
    dispatch(toggleLoader(true));

    if (values.currentEmail === values.email) {
      setError('Noua adresă de e-mail introdusă este identică cu adresa actuală!');
    } else {
      await login({ userEmail: values.currentEmail, deviceId: deviceId, password: values.password })
        .then((response: any) => {
          if (response && !response.success && response.errorCode === 2) {
            setError('Parola este gresita.');
          }
          if (response && !response.changePasswordRequired && !response.otpRequired && response.success && response.jwt) {
            //login valid
            setError('');
            setValues(values);
            setShowModalConfirmChangeEmail(true);
          }
        })
        .catch(function (error: any) {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        });
    }
    dispatch(toggleLoader(false));
  };

  const handleConfirmChangeEmail = async () => {
    dispatch(toggleLoader(true));
    setShowModalConfirmChangeEmail(false);
    const payload = {
      oldUsername: values.currentEmail,
      newUsername: values.email,
      password: values.password
    };
    await chageUsernameRequest(payload)
      .then((res: any) => {
        if (res.success) {
          setError('');
          setShowModalEmailWasGenerated(true);
        } else {
          if (res.errors[0].errorDescription === 'ExistingUser') {
            setError(`Adresa de e-mail ${payload.newUsername} este deja asociată unui utilizator.`);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
        }
      })
      .catch(function (error: any) {
        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      });
    dispatch(toggleLoader(false));
  };

  const handleCloseEmailWasGeneratedModal = async () => {
    setShowModalEmailWasGenerated(false);
    window.location.href = '/editare-cont';
  };

  const renderChangePasswordForm = () => {
    return (
      <Fragment>
        <Formik
          initialValues={{
            currentEmail: '',
            email: '',
            confirmNewEmail: ''
          }}
          validateOnMount={true}
          onSubmit={(values) => submitEmailChange(values)}
          validationSchema={ChangeEmailSchema}
        >
          {(formProps) => (
            <Fragment>
              <Form>
                <div className="form-container">
                  <div className="w-100 mb-2 mt-3">
                    <h4 className="red">Modifică adresa de e-mail</h4>
                    <Row>
                      <Col xs={12} md={6} className="mt-2 mb-2">
                        <Field
                          className="input-field-register"
                          id="currentEmail"
                          name="currentEmail"
                          placeholder="Adresă de e-mail veche"
                          type="text"
                          render={() => (
                            <Input
                              label="Adresă de e-mail veche"
                              mbZero
                              onBlur={() => {
                                formProps.setFieldTouched('currentEmail');
                              }}
                              onChange={(e: any) => {
                                e.target.value.length !== 0 && formProps.setFieldValue('currentEmail', e.target.value);
                                e.target.value.length === 0 && formProps.setFieldValue('currentEmail', '');
                              }}
                            />
                          )}
                        />
                        <ErrorMessage name="currentEmail" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mt-2 mb-2" xs={12} md={6}>
                        <Field
                          className="input-field-register"
                          id="email"
                          name="email"
                          placeholder="Adresă de e-mail nouă"
                          type="text"
                          render={() => (
                            <Input
                              label="Adresă de e-mail nouă"
                              mbZero
                              onBlur={() => {
                                formProps.setFieldTouched('email');
                              }}
                              onChange={(e: any) => {
                                e.target.value.length !== 0 && formProps.setFieldValue('email', e.target.value);
                                e.target.value.length === 0 && formProps.setFieldValue('email', '');
                              }}
                            />
                          )}
                        />
                        <ErrorMessage name="email" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      </Col>
                      <Col className="mt-2 mb-2" xs={12} md={6}>
                        <Field
                          className="input-field-register"
                          id="confirmNewEmail"
                          name="confirmNewEmail"
                          placeholder="Confirmă noua adresă de e-mail"
                          type="text"
                          render={() => (
                            <Input
                              label="Confirmă noua adresă de e-mail"
                              mbZero
                              onBlur={() => {
                                formProps.setFieldTouched('confirmNewEmail');
                              }}
                              onChange={(e: any) => {
                                e.target.value.length !== 0 && formProps.setFieldValue('confirmNewEmail', e.target.value);
                                e.target.value.length === 0 && formProps.setFieldValue('confirmNewEmail', '');
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name="confirmNewEmail"
                          render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                        />
                      </Col>
                    </Row>

                    <div className="mt-3 d-flex flex-column">
                      <h4 className="red">Parolă</h4>

                      <Row>
                        <Col className="mb-2 mt-2" sm={12} md={6}>
                          <Field
                            name="password"
                            placeholder="Parola"
                            type="password"
                            autoComplete="new-password"
                            render={() => (
                              <Input
                                label="Parola"
                                type="password"
                                autoComplete="new-password"
                                mbZero
                                onBlur={() => {
                                  formProps.setFieldTouched('password');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && formProps.setFieldValue('password', e.target.value);
                                  e.target.value.length === 0 && formProps.setFieldValue('password', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="password"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-2 mt-2" sm={12} md={6}>
                          <Field
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirmă Parola"
                            render={() => (
                              <Input
                                label="Confirmă Parola"
                                type="password"
                                autoComplete="new-password"
                                mbZero
                                onBlur={() => {
                                  formProps.setFieldTouched('confirmPassword');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && formProps.setFieldValue('confirmPassword', e.target.value);
                                  e.target.value.length === 0 && formProps.setFieldValue('confirmPassword', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                    </div>

                    {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
                    <div className="mb-4 mt-1 buttons-container d-flex justify-content-center justify-content-sm-start">
                      <Button type="submit" disabled={!formProps.isValid}>
                        Modifică e-mail
                      </Button>
                      <Button style={{ marginLeft: '8px' }} onClick={() => props.setShowChangeEmailForm(false)}>
                        Inapoi
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Fragment>
          )}
        </Formik>
        <ModalConfirm
          cancelButton
          cancelButtonText={'Nu'}
          okButtonText={'Da'}
          setShowModal={(value) => setShowModalConfirmChangeEmail(value)}
          showModal={showModalConfirmChangeEmail}
          onClose={handleConfirmChangeEmail}
        >
          <p> Ești sigur că vrei să modifici adresa de Email?</p>
        </ModalConfirm>

        <ModalConfirm modalTitle="Succes!" showModal={showModalEmailWasGenerated} onClose={handleCloseEmailWasGeneratedModal}>
          <p>Pentru modificarea adresei de Email, acceseaza link-ul din Emailul transmis la adresa {values && values.email}</p>
        </ModalConfirm>
      </Fragment>
    );
  };

  return renderChangePasswordForm();
};

export default ChangeEmailGenerateEmail;
