import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AdCardShareButtonComponent from '@components/dev/Components/AdCard/AdCardShareButtonComponent';
import { AdCardShareButtonKentico } from '@type/kentico-types';

export type AdCardShareButtonProps = { kentico: AdCardShareButtonKentico };

export const AdCardShareButton = (props: AdCardShareButtonProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <AdCardShareButtonComponent {...props}/>
        </DelgazWrapper>
    );
};