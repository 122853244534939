import ReactHabitat from 'react-habitat';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@components/assets/scss/main.scss';

import { DelgazMenuUserComp } from './kentico/menu/MenuUserComp';
import { FixedMenuComponent } from './kentico/menu/FixedMenuComponent';
import {FooterUserComp} from '@kentico/footer/FooterUserComp';
import {SocialMediaComp} from '@kentico/footer/SocialMediaComp';
import { CardsList } from '@kentico/global/CardsList';
import { Slider } from '@kentico/global/Slider';
import { TabsComponent } from '@kentico/global/Tabs/Tabs';
import { MultiTabsComponent } from '@kentico/global/Tabs/MultiTabs';
import { OverlayImageComponent } from '@kentico/global/Image/OverlayImageComponent';
import FigureComponent from '@kentico/global/Figures/Figure';
import './kentico-clientlibs/CollapsibleListCompLib';
import './kentico-clientlibs/BootstrapLib';
import { Press } from '@kentico/global/Press/Press';
import { GoogleMaps } from '@kentico/global/GoogleMaps/GoogleMaps';
import { GroupMap } from '@kentico/global/GroupMap/GroupMap';
import { Pagination } from '@kentico/global/Pagination/Pagination';
import { GlobalComp } from '@kentico';
import { SearchByClcComponent, SearchByLocalityComponent, SearchByPodComponent } from '@kentico/account/superiorCaloricValue';
import { CautareProgramareComp } from '@kentico/system/programariCitiri';
import { Resources } from '@kentico/global/Resources/Resources';
import { Auth } from '@components/auth/Auth';
import { BusinessCard } from '@kentico/global/BusinessCard/BusinessCard';
import { RichText } from '@kentico/global/RichText/RichText';
import { RomaniaMap } from '@kentico/global/RomaniaMap/RomaniaMap';
import { CreateAccount } from '@components/auth/CreateAccount';
import { ChangePassword } from '@components/auth/ChangePassword';
import { ResetPassword } from '@components/auth/ResetPassword';
import { ActivateAccountComponent } from '@components/auth/ActivateAccount';
import { RequestResetPaswordComponent } from '@components/auth/RequestResetPassword';
import { BackOfficeComponent } from '@kentico/backOffice';
import { Details } from '@kentico/global/AdCard/AdCardDetails';
import { AdCardMapButton } from '@kentico/global/AdCard/AdCardMapButton';
import { AdCardShareButton } from '@kentico/global/AdCard/AdCardShareButton';
import { MyAccountMenu } from '@kentico/menu/MyAccountMenu';
import { TwoFA } from '@components/account/TwoFA';
import { Security } from '@components/account/Security';
import { AddCPoint } from '@components/account/MyAccount/AddCPoint';
import { ConsumptionPoints } from '@components/account/MyAccount/ConsumptionPoints';
import { UnlockAccountComponent } from '@components/auth/UnlockAccount';
import { CheckDisturbanceComponent } from '@kentico/energieElectrica';
import { ChangeEmailAccesEmailComponent } from '@components/account/EditAccount/ChangeEmailAccesEmailComponent';
import { DisplayMeasuredValuesComponent } from '@kentico/PartenerValoriMasurate/DisplayMeasuredValuesComponent';
import {Detalii} from '@kentico/global/Achizitii/DetaliiAchizitie';
import {FormAchizitie} from '@kentico/global/Achizitii/FormAchizitie';
import {OferteAchizitie} from '@kentico/global/Achizitii/OferteAchizitie';
import {Cerere} from '@kentico/global/Prosumatori/CerereProsumatori';
import {ListaCereri} from '@kentico/global/Prosumatori/ListaCereri';
import { AgreementPVAEComponent } from '@kentico/agreementPVAE';
import {InregistrareRSSFurnizor} from './kentico/rss/InregistrareRSSFurnizor';
import {AfisareRSS} from '@kentico/rss/afisareRSS';
import {RSSEmailLinkDetails} from '@kentico/rss/emailLinkDetails';
import {FormularContactRSS} from '@kentico/rss/formularContact';
import {RSSEmailLinkDetailsFurnizor} from '@kentico/rss/emailLinkDetailsFurnizor';
import { EditRSS } from '@kentico/rss/editareRSS';
import { RequestsComponent } from '@kentico/requests';
import { OrderForm } from '@kentico/formularComanda/OrderForm';
import { PVAEInfo } from '@kentico/PVAEInfo/PVAEInfo';
import { PVAEInfoClient } from '@kentico/PVAEInfo/PVAEInfoClient';
import {CList} from '@kentico/conventions/list';
import {CView} from '@kentico/conventions/view';
import {CDetails} from '@kentico/conventions/details';
import {CModify} from '@kentico/conventions/modify';
import { CImportBulkVerifyComponent } from '@kentico/conventions/importBulkVerify';
import { CImportBulkModifyComponent } from '@kentico/conventions/importBulkModify';
import { AgreementPVCComponent } from '@kentico/agreementPVC';
import { InregistrareRSSClient } from '@kentico/rss/inregistrareRSSClient';
import { ProgramariCitiriCont } from '@kentico/programariCitiri/ProgramariCitiriContoare';
import { IncarcareFisaNouaComponent } from '@kentico/revizii/IncarcareFisaNouaComponent';
import { IstoricIncarcariFiseComponent } from '@kentico/revizii/IstoricIncarcariFiseComponent';
import { RaportAnexaComponent } from '@kentico/revizii/RaportAnexaComponent';
import { ListaFiseDraftComponent } from '@kentico/revizii/ListaFiseDraftComponent';
import { CreareFisaComponent } from '@kentico/revizii/CreareFisaComponent';
import { DetaliiIncarcareInMasaComponent } from '@kentico/revizii/DetaliiIncarcareInMasaComponent';
import { IncarcareInMasaComponent } from '@kentico/revizii/IncarcareInMasaComponent';
import { IstoricIncarcareInMasaComponent } from '@kentico/revizii/IstoricIncarcareInMasaComponent';
import { PVRInfoClient } from '@kentico/PVRInfo/PVRInfoClient';
import { PVRInfoFurnizor } from '@kentico/PVRInfo/PVRInfoFurnizor';
import { RomcardCallbackComponent } from '@kentico/invoices/RomcardCallbackComponent';
import { TimelineComp } from '@kentico/timeline/TimelineComp';
import { AlocareCerereClientComponent } from '@kentico/iConnect/AlocareCerereClientComponent';
import {CerereGaz} from '@kentico/iConnect/CerereGaz';
import { CerereEnergieElectrica } from '@kentico/iConnect/CerereEnergieElectrica';
import { RegistruCereriGN } from '@kentico/registruCereriRacordare/RegistruCereriGN';
import { IntreruperiNeprogramateComponent } from '@kentico/intreruperiNeprogramate/IntreruperiNeprogramate';
import { Headline } from '@kentico/global/HeadlineRacordare/Headline';
import { TabsRacordareComponent } from '@kentico/global/Tabs/TabsRacordare';
import { RedesignedResources } from '@kentico/global/Resources/RedesignedResources';
import { Popup } from '@kentico/global/popupLogin/Popup';
import { PublicRelationsCenterMapComponent } from '@kentico/publicRelationsCenterMap/PublicRelationsCenterMap';
import { Popup as PopupWidget } from '@kentico/global/Popup/Popup';
import { UserNotificationList } from '@kentico/notifications/NotificationList';
import { UserNotificationDetails } from '@kentico/notifications/NotificationDetails';

class App extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    // Create a new container builder:
    const builder = new ReactHabitat.ContainerBuilder();

    builder.register(DelgazMenuUserComp).as('DelgazMenuUserComp');
    builder.register(FixedMenuComponent).as('FixedMenuComponent');
    builder.register(FooterUserComp).as('FooterUserComp');
    builder.register(SocialMediaComp).as('SocialMediaComp');
    builder.register(Slider).as('Slider');
    builder.register(CardsList).as('CardsList');
    builder.register(TabsComponent).as('TabsComponent');
    builder.register(MultiTabsComponent).as('MultiTabsComponent');
    builder.register(OverlayImageComponent).as('OverlayImageComponent');
    builder.register(FigureComponent).as('FigureComponent');
    builder.register(Press).as('Press');
    builder.register(GoogleMaps).as('GoogleMaps');
    builder.register(GroupMap).as('GroupMap');
    builder.register(Pagination).as('Pagination');
    builder.register(GlobalComp).as('SettingsComponent');
    builder.register(SearchByLocalityComponent).as('SearchByLocalityComponent');
    builder.register(SearchByPodComponent).as('SearchByPodComponent');
    builder.register(SearchByClcComponent).as('SearchByClcComponent');
    builder.register(CautareProgramareComp).as('CautareProgramareComp');
    builder.register(Resources).as('Resurse');
    builder.register(Auth).as('LoginComponent');
    builder.register(BusinessCard).as('BusinessCard');
    builder.register(RichText).as('RichText');
    builder.register(RomaniaMap).as('RomaniaMap');
    builder.register(CreateAccount).as('CreateAccount');
    builder.register(ChangePassword).as('ChangePassword');
    builder.register(ResetPassword).as('ResetPassword');
    builder.register(ActivateAccountComponent).as('ActivateAccount');
    builder.register(RequestResetPaswordComponent).as('RequestResetPassword');
    builder.register(BackOfficeComponent).as('BackOfficeComponent');
    builder.register(Details).as('DetaliiAnunturi');
    builder.register(AdCardMapButton).as('PinHartaAnunturi');
    builder.register(AdCardShareButton).as('ShareAnunturi');
    builder.register(MyAccountMenu).as('MeniuCont');
    builder.register(TwoFA).as('TwoFA');
    builder.register(Security).as('EditareCont');
    builder.register(ConsumptionPoints).as('LocuriConsum');
    builder.register(AddCPoint).as('AdaugaLocConsum');
    builder.register(UnlockAccountComponent).as('DeblocareCont');
    builder.register(CheckDisturbanceComponent).as('CheckDisturbanceComponent');
    builder.register(ChangeEmailAccesEmailComponent).as('ChangeEmailAccesLinkComponent');
    builder.register(DisplayMeasuredValuesComponent).as('DisplayMeasuredValuesComponent');
    builder.register(Detalii).as('DetaliiAchizitie');
    builder.register(FormAchizitie).as('FormAchizitie');
    builder.register(OferteAchizitie).as('OferteAchizitie');
    builder.register(Cerere).as('CerereProsumatori');
    builder.register(ListaCereri).as('ListaCereri');
    builder.register(AgreementPVAEComponent).as('AgreementPVAEComponent');
    builder.register(InregistrareRSSFurnizor).as('InregistrareRSSFurnizor');
    builder.register(AfisareRSS).as('AfisareRSSFurnizor');
    builder.register(RSSEmailLinkDetails).as('DetaliiRSSLink');
    builder.register(FormularContactRSS).as('FormularContact');
    builder.register(RSSEmailLinkDetailsFurnizor).as('DetaliiRSSLinkFurnizor');
    builder.register(EditRSS).as('EditareRSS');
    builder.register(RequestsComponent).as('RequestsComponent');
    builder.register(OrderForm).as('FormularComanda');
    builder.register(PVAEInfo).as('InfoPVAEFurnizor');
    builder.register(PVAEInfoClient).as('InfoPVAEClient');
    builder.register(CList).as('GridVizualizareCC');
    builder.register(CModify).as('SolicitareModificareCC');
    builder.register(CDetails).as('AfisareDetaliiCC');
    builder.register(CView).as('SolicitareVizualizareCC');
    builder.register(CImportBulkVerifyComponent).as('ImportBulkVerifyCC');
    builder.register(CImportBulkModifyComponent).as('ImportBulkModifyCC');
    builder.register(AgreementPVCComponent).as('AgreementPVCComponent');
    builder.register(InregistrareRSSClient).as('InregistrareRSSClient');
    builder.register(ProgramariCitiriCont).as('ProgramariCitiriContoare');
    builder.register(IncarcareFisaNouaComponent).as('RVIncarcareFisaNouaComponent');
    builder.register(IstoricIncarcariFiseComponent).as('RVIstoricIncarcariFiseComponent');
    builder.register(RaportAnexaComponent).as('RVRaportAnexaComponent');
    builder.register(ListaFiseDraftComponent).as('RVListaFiseDraftComponent');
    builder.register(CreareFisaComponent).as('RVCreareFisaComponent');
    builder.register(DetaliiIncarcareInMasaComponent).as('RVDetaliiIncarcareInMasaComponent');
    builder.register(IncarcareInMasaComponent).as('RVIncarcareInMasaComponent');
    builder.register(IstoricIncarcareInMasaComponent).as('RVIstoricIncarcareInMasaComponent');
    builder.register(PVRInfoClient).as('PVRInfoClient');
    builder.register(PVRInfoFurnizor).as('PVRInfoFurnizor');
    builder.register(RomcardCallbackComponent).as('RomcardCallbackComponent');
    builder.register(TimelineComp).as('TimelineComponent');
    builder.register(AlocareCerereClientComponent).as('AlocareCerereClientComponent');
    builder.register(CerereGaz).as('CerereIConnectGaz');
    builder.register(CerereEnergieElectrica).as('IConnectEE');
    builder.register(RegistruCereriGN).as('RegistruCereriRacordareGN');
    builder.register(IntreruperiNeprogramateComponent).as('IntreruperiNeprogramateComponent');
    builder.register(Headline).as('HeadlineRacordare');
    builder.register(TabsRacordareComponent).as('MultiTabsRacordare');
    builder.register(RedesignedResources).as('ResurseRacordare');
    builder.register(Popup).as('PopupLogin');
    builder.register(PublicRelationsCenterMapComponent).as('PublicRelationsCenterMapComponent');
    builder.register(PopupWidget).as('Popup');
    builder.register(UserNotificationList).as('Notificari');
    builder.register(UserNotificationDetails).as('DetaliiNotificare');
    // Object.keys(KenticoComponents).forEach((name: any) => {
    //   console.log(KenticoComponents)
    //   builder.register(KenticoComponents[name as unknown]).as(name);
    // });

    // Finally, set the container:
    this.setContainer(builder.build());
    this.update();

    //@ts-ignore
    window.updateHabitat = this.update.bind(this);
  }
}

// Always export a 'new' instance so it immediately evokes:
const app = new App();
export default app;
