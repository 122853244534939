import { faBolt, faFire, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

const PartnerCPTableRow = (props: { item: any }) => {
    const { item } = props;

    return (
        <tr>
            <td className="p-3">
                <div className="d-flex align-items-center">
                    <div>
                        <FontAwesomeIcon icon={item.pod[0] === 'E' ? faBolt : faFire} className="red" style={{width: '20px', height: '20px'}} />
                    </div>
                    <span style={{marginLeft: '12px'}}>{item.address}, {item.pod}</span>
                </div>
            </td>
            <td className="p-3">
                <div className="d-flex justify-content-end w-100 h-100">
                    <div 
                        className="d-flex align-items-center" 
                    >
                        <Button className="btn btn-sm" style={{visibility: 'hidden'}}>
                            <FontAwesomeIcon icon={faTrash} color="white"/>
                            <span style={{marginLeft: '4px'}}>Ștergeți</span>
                        </Button>
                        
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default PartnerCPTableRow;