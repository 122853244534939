import React, {useEffect, useState} from 'react';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import {Button, Col} from 'react-bootstrap';
import {getTypes} from '@api/prosumatori/prosumatori';
import {postStepFive} from '@api/iConnect/iConnectGaz';
import {toggleLoader} from '@actions/LoaderActions';
import {useDispatch} from 'react-redux';
import { Input } from '@components/common/Input';

const CanaleComunicare = (props: {
    isEditing: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    raspuns: any,
    handleRaspuns: any,
    comunic: any,
    handleComunic: any,
    publicare: any,
    handlePublicare: any,
    detaliiComunicare: any,
    handleDetaliiComunicare: any,
    communicationOptionLoaded: any,
    handleCommunicationOptionLoaded: any
}) => {
    const {
        isEditing,
        requestId,
        setStep,
        currentStep,
        progress,
        setProgress,
        raspuns,
        handleRaspuns,
        comunic,
        handleComunic,
        publicare,
        handlePublicare,
        detaliiComunicare,
        handleDetaliiComunicare,
        communicationOptionLoaded,
        handleCommunicationOptionLoaded
    } = props;

    const [validationError, setValidationError] = useState('');
    const [ROptions, setROptions] = useState([]);
    //eslint-disable-next-line
    const [CCOptions, setCCOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0,0);

        getOptionTypes(['CommunicationTypeGN']).then((res) => {
            setROptions(res);
            if (raspuns === null) handleRaspuns(res[0].id);
        });

        comunic.length === 0 && getNotificationTypes(['ClientNotificationType']).then((res) => {
            if (res && res.clientnotificationtype) {
                let data = res.clientnotificationtype;

                data.map((el: any, i: number) => {
                    el.active = false;
                });

                handleComunic(data);
                setCCOptions(res.clientnotificationtype);
            }
        });
    }, []);

    useEffect(() => {
        if (comunic && comunic.length > 0 && communicationOptionLoaded === false && !isEditing && comunic[0].active === false) {
            setOptionActive(comunic[0]);
            handleCommunicationOptionLoaded(true);
        }
    }, [comunic]);

    const getOptionTypes = async (keys: string[]) => {
        return await getTypes(keys, 'GN').then((res: any) => {
            return res[keys[0]];
        });
    };

    const getNotificationTypes = async (keys: ['ClientNotificationType']) => {
        return await getTypes(keys, 'GN').then((res: any) => {
            return res;
        });
    };

    const setOptionActive = (option: any) => {
        let op = comunic;

        op.map((el: any, i: number) => {
            if (el === option) {
                el.active = !el.active;
            }
        });

        handleComunic(op);
    };

    const constructCC = () => {
        let list: any[] = [];

        comunic.map((el: any) => {
            if (el.active === true) {
                if (el.id === 12) {
                    list.push({id_comunicare: el.id, detalii_comunicare: detaliiComunicare});
                } else {
                    list.push({id_comunicare: el.id, detalii_comunicare: ''});
                }
            }
        });

        return list;
    };

    const constructPayload = () => {
        return {
            requestId: requestId,
            Raspuns: raspuns,
            Comunic: constructCC(),
            Acord: publicare === 1
        };
    };

    const stepFiveApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepFive(payload)
            .then((response) => {
                if (response && response.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                } else if (error === 'Request is completed') {
                    setValidationError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handlePrevStep = () => {
        setStep(currentStep-1);
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setValidationError('');
            let wasValidated = true;
            let lc = document.getElementById('rasp');
            lc?.classList.remove('red');
            let dec = document.getElementById('com');
            dec?.classList.remove('red');
            let sit = document.getElementById('publicare');
            sit?.classList.remove('red');

            if (raspuns === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('rasp');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (constructCC().length === 0) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('com');
                err?.classList.add('red');
                wasValidated = false;
            }

            comunic.map((option: any, i: number) => {
                if (option.id === 12 && option.active === true) {
                    if (detaliiComunicare === '') {
                        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                        wasValidated = false;
                    }
                }
            });

            if (publicare === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('publicare');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (wasValidated) {
                stepFiveApiCall(constructPayload());
            }
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    return (
        <div className="iconnect-cc m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3">Canale de comunicare</h4>

            {ROptions.length > 0 &&<ChoiceComponent
                title={'Răspunsul la prezenta cerere se transmite prin:*'}
                options={ROptions}
                onChange={handleRaspuns}
                value={raspuns}
                id="rasp"
                disabled={isEditing === false}
            />}

            { comunic.length > 0 && <div className="mt-3 mb-3">
                <h5 className="m-0" id="com">Comunicările cu operatorul de distribuție se realizează prin:*</h5>
                <div className={'d-flex m-3 choices checkbox-container flex-column'}>
                    {comunic.map((option: any, idx: number) => (
                        <span
                            key={idx}
                            style={{marginRight: '25px', marginBottom: '8px'}}
                            onClick={() => {
                                if (isEditing !== false) {
                                    setOptionActive(option);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={() => setOptionActive(option)}
                                onClick={() => {
                                    if (isEditing !== false) {
                                        setOptionActive(option);
                                    }
                                }}
                                value={option}
                                checked={option.active}
                                style={{marginRight: '4px'}}
                                className="create-account-checkbox"
                                disabled={isEditing === false}
                            />
                            <span style={{fontSize: '18px'}}>{option.title || option.name}</span>
                        </span>
                    ))}
                    <br></br>
                    { comunic.map((option: any, i: number) => {
                        if (option.id === 12 && option.active === true) {
                            return <Col xs={12} sm={4} key={i}>
                                    <Input value={detaliiComunicare} onChange={(e) => handleDetaliiComunicare(e.target.value)} label="Detalii*" disabled={isEditing === false} />
                                </Col>;
                        }
                    })
                        
                    }
                </div>
            </div> }

            <ChoiceComponent
                title={'Pentru publicarea pe pagina de internet a operatorului de distribuție a informaţiilor cu privire la datele de contact şi adresa locului de consum*'}
                options={[{title: 'Sunt de acord cu publicarea', id: 1}, {title: 'Nu sunt de acord cu publicarea', id: 2}]}
                onChange={handlePublicare}
                value={publicare}
                id="publicare"
                disabled={isEditing === false}
            />

            {validationError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{validationError}</p>}
            <div className="d-flex flex-row justify-content-between mt-3 mb-3">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </div>
    );
};

export default CanaleComunicare;