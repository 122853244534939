import React, { useState, useEffect } from 'react';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { Input } from '@components/common/Input';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { clientVerifyPartner } from '@api/user/partners';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const AddPartnerCP = (props: any) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    const [pod, setPod] = useState('');
    const [county, setCounty] = useState('');
    const [locality, setLocality] = useState('');
    const [response, setResponse] = useState('');
    //eslint-disable-next-line
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [showConfirmAddModal, setShowConfirmAddModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        mountCounties();
    }, []);


    useEffect(() => {
        county !== '' && mountLocalities();
    }, [county]);

    const mountCounties = async () => {
        dispatch(toggleLoader(true));

        await GetCounties()
            .then((res) => setCounties(res))
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const mountLocalities = async () => {
        dispatch(toggleLoader(true));

        await GetLocalities(county).then((res) => setLocalities(res)).catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const save = async (savePartner: boolean) => {
        setResponse('');

        dispatch(toggleLoader(true));

        await clientVerifyPartner(pod, getCountyId(county), locality, savePartner)
            .then((res) => {
                if (res && res.data && res.data.value) {
                    if (savePartner === false) {
                        if (res.data.value !== 'OK!') {
                            setResponse(res.data.value);
                        } else if (res.data.value === 'OK!') {
                            setButtonEnabled(true);
                            setShowConfirmAddModal(true);
                        }
                    } else if (savePartner === true) {
                        if (res.data.value !== 'OK!') {
                            setResponse(res.data.value);
                        } else if (res.data.value === 'OK!') {
                            props.getList();
                            props.handleSuccessModal(true);
                            setButtonEnabled(false);
                        }
                    }

                }
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const getCountyId = (county: any) => {
        let id = 0;

        counties.map((c: any, i: number) => {
            if (c.countyCode === county) {
                id = c.countyId;
            }
        });

        return id;
    };

    const renderConfirmAddModal = () => {
        return <Modal show={showConfirmAddModal} onHide={() => setShowConfirmAddModal(false)} centered backdrop={'static'} className="choice-modal">
            <Modal.Header closeButton>
                <div>
                    <h5 className="red">Confirmă adaugarea</h5>
                </div>
            </Modal.Header>
            <Modal.Footer className="justify-content-center">
                <Row className="d-flex flex-row justify-content-around">
                    <Col xs={12} sm={6}>
                        <Button onClick={() => save(true)}>Da</Button>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Button onClick={() => setShowConfirmAddModal(false)}>Nu</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>;
    };

    return (
        <div>
            <Row className="d-flex justify-content-center">
                <Col xs={12} sm={3}>
                    <Input label="Cod punct de măsurare*" value={pod} onChange={(e) => setPod(e.target.value)} />
                </Col>
                <Col xs={12} sm={3}>
                    <Dropdown
                        options={counties.map((option, index) => (
                            {
                                id: option.countyCode,
                                name: option.countyName,
                            }
                        )) as DropdownOptionType[]}
                        onChange={(value) => {
                            console.log(value);
                            setCounty(value);
                        }}
                        label={'Județ*'}
                        defaultPlaceholder={'Alege județ'}
                        displaySearch={true}
                        value={county}
                    />
                </Col>
                <Col xs={12} sm={3}>
                    <Dropdown
                        options={localities.map((option, index) => (
                            {
                                id: option.localityId,
                                name: option.localityName,
                                code: option.localityCode
                            }
                        )) as DropdownOptionType[]}
                        label={'Localitate*'}
                        onChange={(value) => {
                            setLocality(value);
                        }}
                        defaultPlaceholder={'Alege localitatea'}
                        displaySearch={true}
                        value={locality}
                    />
                </Col>
            </Row>
            <Row className="justify-content-center mb-3">
                <Col xs={12} sm={9}>
                    <div className="triplet-separator"></div>
                </Col>
            </Row>

            <Row>
                <div className="d-flex justify-content-center mb-1">
                    {/* <Button onClick={() => save(false)} disabled={pod === '' || county === '' || locality === ''} style={{marginRight: '8px'}}>Salvează</Button> */}
                    <Button onClick={() => save(false)} disabled={pod === '' || county === '' || locality === ''}>Adaugă</Button>
                </div>
            </Row>
            {response !== '' && <p className="fw-bold text-center red">{response}</p>}
            {renderConfirmAddModal()}
        </div>
    );
};

export default AddPartnerCP;