import React, { useEffect, useState } from 'react';
import Filters from './Filters';
import Table from './Table';
import { getElectronicRegistryList } from '@api/registruElectronicCereri';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';

const RegistruCereriRacordareGN = () => {
  const [list, setList] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState('RequestDate');
  const [sortType, setSortType] = useState('ASC');
  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [requestNo, setRequestNo] = useState('');
  const [error, setError] = useState('');
  const [mountType, setMountType] = useState('mount');
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (mountType !== 'reset' && initialized) && getList(constructPayload());
  }, [sortBy, sortType, pageNumber, pageSize]);

  const constructPayload = () => {
    return [
      `sortBy=${sortBy}`,
      `sortType=${sortType}`,
      `pageNumber=${pageNumber}`,
      `pageSize=${pageSize}`,
      county !== '' ? `countyCode=${county}` : '',
      locality !== '' ? `localityCode=${locality}` : '',
      startDate !== '' ? `startDate=${startDate}` : '',
      endDate !== '' ? `endDate=${endDate}` : '',
      requestNo !== '' ? `requestNumber=${requestNo}` : ''
    ];
  };

  const getList = async (payload: any) => {
    dispatch(toggleLoader(true));

    await getElectronicRegistryList(payload)
      .then((response) => {
        if (response && response.data) {
          setList(response.data.items);
          setTotalItems(response.data.metadata.totalItemsCount);
          setPageNumber(response.data.metadata.pageIndex);
          setPageSize(response.data.metadata.pageSize);

          !initialized && setInitialized(true);
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const submit = () => {
    setError('');

    if (county !== '' && locality !== '') {
      setMountType('mount');
      setPageNumber(1);
      setPageSize(10);
      setSortBy('requestId');
      setSortType('DESC');
  
      let payload = [
        `sortBy=${'requestId'}`,
        `sortType=${'DESC'}`,
        `pageNumber=${1}`,
        `pageSize=${10}`,
        county !== '' ? `countyCode=${county}` : '',
        locality !== '' ? `localityCode=${locality}` : '',
        startDate !== '' ? `startDate=${startDate}` : '',
        endDate !== '' ? `endDate=${endDate}` : '',
        requestNo !== '' ? `requestNumber=${requestNo}` : ''
      ];
  
      getList(payload);
    } else {
      setError('Câmpurile marcate cu * sunt obligatorii.');
    }
  };

  const reset = () => {
    setMountType('reset');
    setCounty('');
    setLocality('');
    setStartDate('');
    setEndDate('');
    setRequestNo('');
    setInitialized(false);
    // setPageNumber(1);
    // setPageSize(10);
    // setSortBy('requestId');
    // setSortType('DESC');

    // let payload = [`sortBy=${sortBy}`, `sortType=${sortType}`, `pageNumber=${1}`, `pageSize=${10}`];

    // getList(payload);
  };

  const setElementsPerPage = (elements: number) => {
    setMountType('mount');
    setPageSize(elements);
  };

  const paginate = (pageNumber: number) => {
    setMountType('mount');
    setPageNumber(pageNumber);
  };

  return (
    <div className="registru-cereri-racordare m-3">
      <Filters
        county={county}
        handleCounty={(value: string) => setCounty(value)}
        locality={locality}
        handleLocality={(value: string) => setLocality(value)}
        startDate={startDate}
        handleStartDate={(value: string) => setStartDate(value)}
        endDate={endDate}
        handleEndDate={(value: string) => setEndDate(value)}
        requestNo={requestNo}
        handleRequestNo={(value: string) => setRequestNo(value)}
        submit={submit}
        reset={reset}
      />
      { error !== '' && <p className="red fw-bold">{error}</p> }
      {initialized && <Table
        list={list}
        currentPage={pageNumber}
        perPage={pageSize}
        paginate={paginate}
        setElementsPerPage={setElementsPerPage}
        totalItems={totalItems}
        sortBy={sortBy}
        handleSortBy={(value: string) => {setMountType('mount'); setSortBy(value);}}
        sortType={sortType}
        handleSortType={(value: string) => {setMountType('mount'); setSortType(value);}}
      />}
    </div>
  );
};

export default RegistruCereriRacordareGN;