import { Input } from '@components/common/Input';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { GetCounties } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { getConsumptionPointsBO } from '@api/rss/rss';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

const Search = (props: { 
    county: string, 
    handleCounty: any, 
    pod: string, 
    cui: string, 
    cnp: string, 
    handlePod: any, 
    handleCnp: any, 
    handleCui: any,
    handleData: any,
}) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const {
        county,
        handleCounty,
        pod,
        handlePod,
        cnp,
        handleCnp,
        cui,
        handleCui,
        handleData
    } = props;
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    useEffect(() => {
        mountCounties();
    }, []);

    const mountCounties = async () => {
        dispatch(toggleLoader(true));

        await GetCounties().then(res => {
            setCounties(res);
        });

        dispatch(toggleLoader(false));
    };

    const submit = () => {
        setError('');
        let isValid = true;

        if (county === '') {
            isValid = false;
            setError('Județul este obligatoriu.');
        } else if (pod === '' && cnp === '' && cui === '') {
            isValid = false;
            setError('Cel puțin unul dintre câmpurile POD/CNP/CUI este obligatoriu.');
        } else if (pod !== '' && pod.length !== 10) {
            isValid = false;
            setError('Câmpul POD este format din 10 caractere.');
        } else if (cnp !== '' && cnp.length !== 13) {
            isValid = false;
            setError('Câmpul CNP este format din 13 caractere.');
        } else if (isValid) {
            search();
        }
    };

    const search = async () => {
        dispatch(toggleLoader(true));
        handleData(null);
        setError('');

        await getConsumptionPointsBO({county, pod, cnp, cui})
            .then((response) => {
                if (response && response.data && response.data.data && response.status && response.status === 200) {
                    handleData(response.data.data[0]);
                } else if (response && response.data && response.data.data === null && response.data.message) {
                    setError(response.data.message);
                } else {
                    setError('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.');
                }
            })
            .catch((err) => setError('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    return (
        <div>
            <Row>
                <Col xs={12} sm={6} md={3}>
                    <Dropdown
                        options={counties.map((option, index) => (
                            {
                                id: option.countyCode,
                                name: option.countyName
                            }
                        )) as DropdownOptionType[]}
                        onChange={(value) => {
                            handleCounty(value);
                        }}
                        label={'Județ*'}
                        defaultPlaceholder={'Alege județ'}
                        displaySearch={true}
                        value={county}
                    />
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <Input
                        value={pod}
                        label={'POD'}
                        onChange={(e) => handlePod(e.target.value)}
                    />
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <Input
                        value={cnp}
                        label={'CNP'}
                        onChange={(e) => handleCnp(e.target.value)}
                    />
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <Input
                        value={cui}
                        label={'CUI'}
                        onChange={(e) => handleCui(e.target.value)}
                    />
                </Col>
            </Row>
            {error && <p className="red fw-bold">{error}</p>}
            <Row className="mb-4">
                <Col xs={3}>
                    <Button onClick={() => submit()}>Căutare</Button>
                </Col>
            </Row>
        </div>
    );
};

export default Search;