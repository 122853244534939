import { FETCH_CATEGORIES_AND_SUBJECTS, FETCH_SUCCESS } from '@constants/ActionTypes/PostTypes';
import { Action } from '.';

export type CategoryType = {
  code: string,
  name: string
}

export type Subject = {
  code: string,
  name: string
}

export type SubSubject = {
  code: string,
  name: string
}

export class RssPayload {
  categories: CategoryType[] = [];
  subjects: Subject[] = [];
  subSubjects: SubSubject[] = [];
}

const initialState = new RssPayload();

export function rss(state = initialState, action = {} as Action<RssPayload>) {
  const { type, payload, initialType } = action;

  switch (type) {
    case FETCH_SUCCESS: {

      if (initialType === FETCH_CATEGORIES_AND_SUBJECTS) {
        return {
          ...state,
          ...payload
        };
      }

      return state;
    }
    default:
      return state;
  }
}
