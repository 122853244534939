import React from 'react';

import { Modal, Button } from 'react-bootstrap';

export type CustomModalProps = {
  showModal: boolean;
  modalTitle?: string;
  setShowModal?: (value: any) => void;
  onClose?: () => void;
  onHide?: () => void;
  children?: JSX.Element;
  okButtonText?: string;
  cancelButton?: boolean;
  cancelButtonText?: string;
  mtZero?: boolean;
};

const CustomModal = ({
  showModal,
  setShowModal,
  modalTitle,
  children,
  onClose,
  okButtonText,
  cancelButton,
  cancelButtonText,
  onHide,
  mtZero
}: CustomModalProps) => {
  const handleCloseModal = () => {
    onClose && onClose();
  };

  return (
    <Modal style={{ marginTop: `${mtZero ? '' : '30vh'}` }} show={showModal} onHide={onHide ? onHide : handleCloseModal}>
      <Modal.Title>
        <div className="p-3" style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
          <span>{modalTitle}</span>
        </div>
      </Modal.Title>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {cancelButton && (
          <Button variant="primary" onClick={() => setShowModal && setShowModal(false)}>
            {cancelButtonText ?? 'Inapoi'}
          </Button>
        )}
        <Button variant="primary" onClick={handleCloseModal}>
          {okButtonText ?? 'OK'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
