import { Fragment } from 'react';

const TotalColRow = (props: any) => {
    const {rows, totalCol} = props;

    const calcRowTotal = (index: number) => {
        let total = 0;

        rows.map((row: any, idx: number) => (
            Object.keys(row).map((key: any, i: number) => {
                if (totalCol.includes(index) && index === i) {
                    total += Number(row[key]);
                }
            })
        ));
        
        total = Number(total.toFixed(2));
        return total;
    };

    const calcSpanRowTotal = (row: any) => {
        let total = 0;

        Object.keys(row).map((key: any, idx) => (
            total += calcRowTotal(idx)
        ));

        return total;
    };

    return (
        <Fragment>
            {rows.map((row: any, index: number) => {
                if (index === 0) {
                    if (props.colSpan) {
                        return <tr key={index} style={{height: '25px'}}>
                            <td style={{border: '1px solid grey', background: 'lightgray', textAlign: 'center', fontSize: '16px', fontWeight: 'bold'}} colSpan={props.colSpan}>
                                <span>{props.totalColName}</span>
                            </td>
                            <td style={{border: '1px solid grey', background: 'lightgray'}}>
                                <input disabled className="table-input disabled" value={calcSpanRowTotal(row) > 0 ? 'Total: ' + String(calcSpanRowTotal(row)) : ''}/>
                            </td>
                        </tr>;
                    } else {
                        return <tr key={index} style={{height: '25px'}}>
                        {Object.keys(row).map((key: any, idx) => {
                            return <td style={{border: '1px solid grey', background: 'lightgray'}} key={idx}>
                                <input disabled className="table-input disabled" value={calcRowTotal(idx) > 0 ? 'Total: ' + String(calcRowTotal(idx)) : ''}/>
                            </td>;
                        })}
                    </tr>;
                    }
                    
                }
            })}
        </Fragment>
    );
};

export default TotalColRow;