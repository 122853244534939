import React, { FormEvent, useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    Row
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { fetchCounties, fetchLocalities } from '@actions/GeoActions';
import { toggleLoader } from '@actions/LoaderActions';
import { searchSuperiorCalorificPower } from '@api/superiorCalorificValue';
import ResultListing from '@components/delgaz/Account/SuperiorCaloricValue/ResultListing';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { County, Locality } from '@reducers/geo';
import { IState } from '@type/store';
import { SuperiorCalorificTemplate } from '@type/superiorCalorificPower';
import moment from 'moment';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '@components/common/Date/DatePicker';

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

// type ErrorsType = { [key: string]: string | undefined };

export class SuperiorCalorificValues {
    county: Option[] = [];
    locality: Option[] = [];
    startDate?: Date | null = null;
    endDate?: Date | null = null;
};

const SearchByLocality = () => {
    const dispatch = useDispatch();
    const [inputValues, setInputValues] = useState<SuperiorCalorificValues>(new SuperiorCalorificValues());
    // const [errors, setErrors] = useState<ErrorsType>({});
    const [hasApiError, setHasApiError] = useState<boolean>(false);
    const [apiLoaded, setApiLoaded] = useState<boolean>(false);
    const counties: County[] = useSelector<IState>((state) => state.geo.counties) as County[];
    const localities: Locality[] = useSelector<IState>((state) => state.geo.localities) as Locality[];

    const [calorificPowers, setCalorificPowers] = useState<SuperiorCalorificTemplate[]>([]);

    useEffect(() => {
        dispatch(fetchCounties());
    }, [dispatch]);

    const fetchLocalitiesByCounty = (county: County) => {
        if (county) {
            dispatch(fetchLocalities(county));
        }
    };

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        dispatch(toggleLoader(true));
        setHasApiError(false);
        setCalorificPowers([]);
        setApiLoaded(false);
        try {
            let calorificPowers: SuperiorCalorificTemplate[] = await searchSuperiorCalorificPower(inputValues);
            setCalorificPowers(calorificPowers);
        } catch (err) {
            setHasApiError(true);
        }
        setApiLoaded(true);
        dispatch(toggleLoader(false));
    };

    return (
        <>
            <Form className='form' onSubmit={(event) => onSubmit(event)}>
                <Row>
                    <Col md={3} sm={6} className="mt-2 mt-md-0">
                        <Form.Group>
                            <div className="custom-dropdown mb-2">
                                <FontAwesomeIcon
                                    icon={faAngleDown}
                                    style={{
                                        textAlign: 'center',
                                        width: '12px',
                                        lineHeight: '10px',
                                        zIndex: 1
                                    }}
                                />
                                <Typeahead
                                    id='county-typeahead-single'
                                    labelKey='name'
                                    onChange={(value) => {
                                        setInputValues({
                                            ...inputValues,
                                            county: value,
                                            locality: []
                                        });

                                        fetchLocalitiesByCounty(value[0] as County);
                                    }}
                                    options={counties}
                                    placeholder='Selectează județ'
                                    selected={inputValues['county']}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={6} className="mt-2 mt-md-0">
                        <Form.Group>
                            <div className="custom-dropdown mb-2">
                                <FontAwesomeIcon
                                    icon={faAngleDown}
                                    style={{
                                        textAlign: 'center',
                                        width: '12px',
                                        lineHeight: '10px',
                                        zIndex: 1
                                    }}
                                />
                                <Typeahead
                                    id='locality-typeahead-single'
                                    labelKey='name'
                                    onChange={(value) => {
                                        setInputValues({
                                            ...inputValues, locality: value
                                        });
                                    }}
                                    options={localities}
                                    placeholder='Selectează localitate'
                                    selected={inputValues['locality']}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={6} xs={6} className="mt-2 mt-md-0">
                        <DatePicker
                            label="Dată început"
                            name="Dată început"
                            selected={inputValues['startDate']}
                            onChange={(date) => {
                                setInputValues({
                                ...inputValues, startDate: date
                                });
                            }}
                            maxDate={new Date()}
                            mbZero
                        />
                    </Col>
                    <Col md={3} sm={6} xs={6} className="mt-2 mt-md-0">
                        <DatePicker
                            disabled={!!inputValues['startDate'] === false}
                            label="Dată sfârșit"
                            name="Dată sfârșit"
                            selected={inputValues['endDate']}
                            onChange={(date) => {
                                setInputValues({
                                ...inputValues, endDate: date
                                });
                            }}
                            minDate={inputValues['startDate']}
                            maxDate={moment().toDate()}
                            mbZero
                        />
                    </Col>
                </Row>
                <Button type='submit'
                    disabled={!!(Object.keys(inputValues).find(key => !(!!(inputValues[key as keyof SuperiorCalorificValues]))))}
                    className='btn btn-primary mt-2 mb-2 btn-md btn-submit'>Caută</Button>
            </Form>
            <ResultListing
                searchValue={inputValues['locality'].length > 0 ? [inputValues['locality'][0] as Locality][0].name : ''}
                searchType={'locality'}
                superiorCaloricValues={calorificPowers}
                showError={hasApiError}
                isDataLoaded={apiLoaded}
            />
        </>
    );
};

export default SearchByLocality;