import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { useState } from 'react';
import { ValueToSet } from '../PageWrapper';

type Base64FileListProps = {
  name: string;
  value?: string | number;
  label: string;
  itemTypeId: number;
  onChange?: (e: ValueToSet) => void;
  viewFilesValues?: any;
  allowedFileExtensions: string[] | any;
  allowedMaxTotalFileSizeMB: number;
};

const Base64File = ({
  name,
  label,
  onChange,
  itemTypeId,
  value,
  viewFilesValues,
  allowedFileExtensions,
  allowedMaxTotalFileSizeMB
}: Base64FileListProps) => {
  const [fileList, setFileList] = useState<File[]>([] as File[]);

  const handleFileListChange = (list: File[]) => {
    setFileList(list);

    onChange &&
      onChange({
        name: name,
        value: list.map((li) => {
          const base64 = li.base64.slice(li.base64.lastIndexOf('base64,') + 7);
          return {
            NumeDocument: li.name,
            ContinutDocument: base64
          };
        })[0],
        itemTypeId
      } as ValueToSet);
  };

  return (
    <>
      <label style={{ position: 'relative', left: '5px' }}>
        <div>{label}</div>
      </label>
      <UploadFile
        acceptedExtensions={allowedFileExtensions ? allowedFileExtensions : null}
        uploadedFilesLimit={1}
        index={0}
        fileList={fileList}
        onSetFileList={handleFileListChange}
        totalSizeLimit={allowedMaxTotalFileSizeMB}
      />
    </>
  );
};

export default Base64File;
