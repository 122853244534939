import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IntreruperiNeprogramate from '@components/intreruperiNeprogramate/IntreruperiNeprogramate';

type Interruption = {
  county: string;
  startDate: string;
  endDate: string;
  details: string;
  title: string;
};

export type IntreruperiNeprogramateProps = { kentico: Interruption[] };

export const IntreruperiNeprogramateComponent = (props: IntreruperiNeprogramateProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <IntreruperiNeprogramate {...props} />
    </DelgazWrapper>
  );
};
