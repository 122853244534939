import React, { Fragment } from 'react';

const DA = 'DA';
const NU = 'NU';
const NU_ESTE_CAZUL = 'NU_ESTE_CAZUL';

type RadioDaNuNuEsteCazulProps = {
  title: string;
  setValue: (val: string) => void;
  currentValue: string;
};

const RadioDaNuNuEsteCazul = (props: RadioDaNuNuEsteCazulProps) => {
  const { title, setValue, currentValue } = props;
  return (
    <Fragment>
      <p className="p-gray m-0 mb-1">{title}</p>
      <div className="d-flex mb-1" style={{ flexDirection: 'row' }}>
        <span className="me-2" onClick={() => setValue(DA)}>
          <input
            className="custom-radio-button pointer"
            type="radio"
            value={1}
            checked={currentValue == DA}
            onChange={() => setValue(DA)}
            style={{ marginRight: '6px' }}
          />
          <span className="fw-bold pointer">Da</span>
        </span>

        <span className="me-2" onClick={() => setValue(NU)}>
          <input
            className="custom-radio-button pointer"
            type="radio"
            value={1}
            checked={currentValue == NU}
            onChange={() => setValue(NU)}
            style={{ marginRight: '6px' }}
          />
          <span className="fw-bold pointer">Nu</span>
        </span>

        <span onClick={() => setValue(NU_ESTE_CAZUL)}>
          <input
            className="custom-radio-button pointer"
            type="radio"
            value={1}
            checked={currentValue == NU_ESTE_CAZUL}
            onChange={() => setValue(NU_ESTE_CAZUL)}
            style={{ marginRight: '6px' }}
          />
          <span className="fw-bold pointer">Nu este cazul</span>
        </span>
      </div>
    </Fragment>
  );
};

export default RadioDaNuNuEsteCazul;
