import React, { Fragment } from 'react';

const CPTableHead = (props: any) => {
  return (
    <Fragment>
      {(props.utility === 2 && props.isFurnizor === true) &&
        <thead>
          <tr>
            <th>Actiune</th>
            <th>Denumirea furnizorului</th>
            <th>Cod partener</th>
            <th>Denumire partener</th>
            <th>Numar Loc Consum</th>
            <th>Cod Loc Consum</th>
            <th>Judet</th>
            <th>Localitate</th>
            <th>Adresă</th>
            <th>Status Contract</th>
            <th>Serie contor</th>
            <th>Status conectare</th>
            <th>Categorie de consum</th>
            <th>SRMP(Statie reglare masurare presiune)</th>
            <th>Presiune minima</th>
            <th>Presiune maxima</th>
            <th>Presiune minima technica/avarie</th>
            <th>Cantitatea contactata</th>
            <th>Durata contract furnizare</th>
          </tr>
        </thead>
      }

      {(props.utility === 1 && props.isFurnizor === true) &&
        <thead>
          <tr>
            <th>Actiune</th>
            {props.isFurnizor && <th>Documente <br /> tehnice</th>}
            <th>Denumirea furnizorului</th>
            <th>Denumirea clientului final</th>
            <th>Denumirea locului de consum</th>
            <th>Codul locului de consum</th>
            <th>Codul de identificare a punctului de măsurare</th>
            <th>Adresa locului de consum</th>
            <th>Data includere in contract</th>
            <th>Status Loc de consum</th>
            <th>Cod CAEN</th>
            <th>Număr Certificat de Racordare/Aviz Tehnic de Racordare</th>
          </tr>
        </thead>
      }

      {(props.utility === 2 && !props.isFurnizor) &&
        <thead>
          <tr>
            <th>Cod partener</th>
            <th>Denumire partener</th>
            <th>Denumire furnizor</th>
            <th>Numar Loc Consum</th>
            <th>Cod Loc Consum</th>
            <th>Adresa</th>
            <th>Status Contract</th>
            <th>Serie contor</th>
            <th>Status conectare</th>
            <th>Categorie de consum</th>
            <th>SRMP(Statie reglare masurare presiune)</th>
            <th>Presiune minima</th>
            <th>Presiune maxima</th>
            <th>Presiune minima technica/avarie</th>
            <th>Cantitatea contactata</th>
            <th>Durata contract furnizare</th>
          </tr>
        </thead>}
      {(props.utility === 1 && !props.isFurnizor) &&
        <thead>
          <tr>
            <th>Cod partener</th>
            <th>Denumire partener</th>
            <th>Denumire furnizor</th>
            <th>Codul locului de consum</th>
            <th>POD</th>
            <th>Adresa</th>
            <th>Status Contract</th>
            <th>Serie contor</th>
            <th>Status conectare</th>
            <th>Nivel de tensiune</th>
            <th>Putere maxima admisa</th>
            <th>Data PRE</th>
          </tr>
        </thead>
      }
    </Fragment>
  );
};

export default CPTableHead;