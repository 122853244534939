import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import CreareFisa from '@components/revizii/CreareFisa/CreareFisa';

export const CreareFisaComponent = () => {
  return (
    <DelgazWrapper>
      <CreareFisa />
    </DelgazWrapper>
  );
};
