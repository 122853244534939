import { toggleLoader } from '@actions/LoaderActions';
import { GetConfig, downloadMergedExport, getRequestDetails, listRequests, getReport } from '@api/requests/requests';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import Pagination from '@components/common/Pagination/Pagination';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import { IDENTITY_ROLE_INTERNAL_EVERYONE } from '@constants/Roles/RolesIdentity';
import { DISPLAY_MODE_DETAILS, DISPLAY_MODE_DETAILS_AND_EXPORT } from '@constants/requests/RequestsConstants';
import { faChartSimple, faCircleInfo, faCircleMinus, faCirclePlus, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequestsComponentProps } from '@kentico/requests';
import * as notification from '@lib/Notification';
import Tippy from '@tippyjs/react';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isObjectEmpty } from '../../../helpers/object';
import NewRequest from './NewRequest';
import * as XLSX from 'xlsx';
import ChartModal from './ChartModal';
import MeasuredChartModal from './MeasuredChartModal';

// const CP_LOCATION_FROM_PORTFOLIO = 'CP_LOCATION_FROM_PORTFOLIO';
// const CP_LOCATION_NOT_FROM_PORTFOLIO = 'CP_LOCATION_NOT_FROM_PORTFOLIO';

const STATUS_IDS = [
  { id: 1, name: 'În procesare' },
  { id: 3, name: 'Finalizată' },
  { id: 4, name: 'Respinsă' }
];

const STATUS_FINALIZATA = 'Finalizată';
// const STATUS_RESPINSA = 'Respinsă';
const STATUS_IN_PROCESARE = 'În procesare';

type JWT = {
  aud: string;
  aut: string;
  azp: string;
  deviceid: string;
  exp: number;
  given_name: string;
  groups: string[];
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  scope: string;
  sub: string;
  userid: string;
  SupplierId: string;
  Utilitate: string;
};

export type ConfigType = {
  displayName: string;
  utilityStr: string;
  utility: number;
  displayModeStr: string;
  displayMode: number;
  reportDateRangeTypeStr: string;
  reportDateRangeType: number;
  allowConsumptionPointsOutsidePortfolio: boolean;
  consumptionPointsTypeStr: string;
  consumptionPointsType: number;
  metterTypes: string[] | any;
  mmrOnly: boolean;
  reportTypes: string[];
  groupOrders: boolean;
};

export const Requests = (props: RequestsComponentProps) => {
  const [baseUrl, setBaseUrl] = useState('');

  //config
  const [config, setConfig] = useState<ConfigType>({} as ConfigType);
  const [list, setList] = useState<any>([]);

  //user
  const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
  //eslint-disable-next-line
  const [role, setRole] = useState('');
  // const [utility, setUtility] = useState(0);
  const [supplierId, setSupplierId] = useState('');
  const [isClient, setIsClient] = useState(false);

  //new request
  const [showNewRequestPage, setShowNewRequestPage] = useState(false);
  const [lastCreatedRequest, setLastCreatedRequest] = useState<any>(null);

  //paginare
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortAscending, setSortAscending] = useState(false);

  //search
  const [selectedStatusId, setSelectedStatusId] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(false);

  //details
  const [showModalRequestDetails, setShowModalRequestDetails] = useState(false);
  const [selectedRequestDetails, setSelectedRequestDetails] = useState<any>(null);

  //request groups
  const [ordersGroups, setOrdersGroups] = useState<any>([]);

  //chart
  const [showChartModal, setShowChartModal] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>([]);
  const [showMeasuredChartModal, setShowMeasuredChartModal] = useState<boolean>(false);
  const [mChartData, setMChartData] = useState<any>([]);
  const [showChartErrorModal, setShowChartErrorModal] = useState(false);

  const dispatch = useDispatch();
  // const [error, setError] = useState('');

  useEffect(() => {
    if (list.items && list.items.length > 0) {
      setOrdersGroups(
        list.items.map((li: any) => {
          return { ...li, toggled: false };
        })
      );
    } else {
      setOrdersGroups([]);
    }
  }, [list]);

  useEffect(() => {
    setBaseUrl(props.kentico.baseUrl);
  }, []);

  useEffect(() => {
    if (!searchDisabled) {
      if (baseUrl && isObjectEmpty(config)) {
        getConfigAndList();
      } else if (baseUrl) {
        getList();
      }
    }
  }, [currentPage, perPage, sortColumn, sortAscending, baseUrl, searchDisabled]);

  useEffect(() => {
    if (token && config.utility) {
      let jwt: JWT = jwtDecode(token);
      let supplierId = jwt.SupplierId;
      let utility = jwt.Utilitate;
      setRole(jwt.groups.filter((role) => role !== IDENTITY_ROLE_INTERNAL_EVERYONE)[0]);

      if (supplierId && supplierId.includes(';')) {
        //Furnizor 2 utilitati
        const supplierIds = supplierId.split(';');
        const utilities = utility.split(';');

        if (config.utility == 1) {
          if (utilities[0] === '1') {
            setSupplierId(supplierIds[0]);
          } else if (utilities[1] === '1') {
            setSupplierId(supplierIds[1]);
          }
        } else if (config.utility == 2) {
          if (utilities[0] === '2') {
            setSupplierId(supplierIds[0]);
          } else if (utilities[1] === '2') {
            setSupplierId(supplierIds[1]);
          }
        }
      } else if (supplierId) {
        //Furnizor o utilitate
        setSupplierId(supplierId);
      } else {
        //Client
        setIsClient(true);
      }
    }
  }, [token, config]);

  useEffect(() => {
    setTimmerToRemoveLastCreatedRequest();
  }, [lastCreatedRequest]);

  const getConfigAndList = async () => {
    dispatch(toggleLoader(true));
    let result = await GetConfig(baseUrl).catch((err) => {
      notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
    });
    setConfig(result);

    let listPayload: any = { pageNumber: currentPage, pageSize: perPage, sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc' };
    let list = await listRequests(baseUrl, listPayload).catch((err) => {
      notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
    });
    setList(list);
    setTotalItemsCount(list.metadata.totalItemsCount);
    dispatch(toggleLoader(false));
  };

  const getList = async () => {
    dispatch(toggleLoader(true));
    let listPayload: any = { pageNumber: currentPage, pageSize: perPage, sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc' };
    if (selectedStatusId) {
      listPayload.statusId = selectedStatusId;
    }
    let list = await listRequests(baseUrl, listPayload);
    setList(list);
    setTotalItemsCount(list.metadata.totalItemsCount);
    dispatch(toggleLoader(false));
  };

  const onSort = (column: string) => {
    setSortColumn(column);
    if (sortColumn == column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  const submitSearch = async () => {
    dispatch(toggleLoader(true));
    let listPayload: any = { pageNumber: 1, pageSize: perPage, sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc' };
    if (selectedStatusId) {
      listPayload.statusId = selectedStatusId;
    }
    let list = await listRequests(baseUrl, listPayload);
    setList(list);
    setTotalItemsCount(list.metadata.totalItemsCount);
    dispatch(toggleLoader(false));
  };

  const reset = async () => {
    setSelectedStatusId('');
    dispatch(toggleLoader(true));
    let listPayload: any = { pageNumber: 1, pageSize: perPage, sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc' };
    let list = await listRequests(baseUrl, listPayload);
    setList(list);
    setTotalItemsCount(list.metadata.totalItemsCount);
    dispatch(toggleLoader(false));
    setCurrentPage(1);
  };

  const getDownload = (item: any) => {
    if (item.exportXlsFile) {
      return item.exportXlsFile;
    } else if (item.exportPdfFile) {
      return item.exportPdfFile;
    } else if (item.exportDocFile) {
      return item.exportDocFile;
    } else if (item.exportCsvFile) {
      return item.exportCsvFile;
    } else return null;
  };

  const onSelectedRequest = async (item: any) => {
    dispatch(toggleLoader(true));
    setSelectedRequestDetails(null);
    const requestDetails = await getRequestDetails(baseUrl, { id: item.id });

    setSelectedRequestDetails(requestDetails);
    setShowModalRequestDetails(true);
    dispatch(toggleLoader(false));
  };

  const isGroupToggled = (item: any) => {
    let toggled: any = false;
    let group =
      ordersGroups.find((group: any) => {
        return group.id == item.id;
      }) ?? null;
    //@ts-ignore
    if (!!group && group.toggled) {
      toggled = true;
    }
    return toggled;
  };

  const toggleGroup = (item: any, index: number) => {
    let currentGroupToggled = { ...item, toggled: !item.toggled };

    let newToggledGroups = [...ordersGroups.slice(0, index), currentGroupToggled, ...ordersGroups.slice(index + 1)];
    setOrdersGroups(newToggledGroups);
  };

  const getGroupStatus = (group: any) => {
    let status = STATUS_FINALIZATA;
    group.consumptionPoints.forEach((cp: any) => {
      //In procesare
      if (cp.status == STATUS_IN_PROCESARE) {
        status = STATUS_IN_PROCESARE;
      }
    });
    return status;
  };

  const handleDownloadMergedExport = async (group: any) => {
    dispatch(toggleLoader(true));

    await downloadMergedExport(baseUrl, group.id)
      .then((res: any) => {
        if (res.status == 208) {
          const decoder = new TextDecoder('utf-8');
          const decodedString = decoder.decode(res.data);
          const decodedResponse = JSON.parse(decodedString);

          const fileName: string = decodedResponse.fileName;
          const objectUrl: string = decodedResponse.documentLink;
          const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

          a.href = objectUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(objectUrl);

          const excelData = new Uint8Array(res.data);
          const workbook = XLSX.read(excelData, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          let string = JSON.stringify(jsonData, null, 2);
          //eslint-disable-next-line
          string = JSON.parse(string);
        } else {
          const arrayBuffer: ArrayBuffer = res.data;
          const blob: Blob = new Blob([arrayBuffer]);

          const fileName: string = `Export-solicitare-${group.id}.xlsx`;
          const objectUrl: string = URL.createObjectURL(blob);
          const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

          a.href = objectUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(objectUrl);
        }
        dispatch(toggleLoader(false));
      })
      .catch((err: any) => {
        dispatch(toggleLoader(false));
        notification.error('A apărut o eroare. Vă rugăm să reveniți mai târziu.');
      });
  };

  const handleFileFetch = async (group: any) => {
    dispatch(toggleLoader(true));

    await downloadMergedExport(baseUrl, group.id).then((res) => {
      const excelData = new Uint8Array(res.data);
      const workbook = XLSX.read(excelData, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // console.log(jsonData);
      let string = JSON.stringify(jsonData, null, 2);
      // console.log(string);
      let rawData = JSON.parse(string);

      const clcArray: any[] = [];
      const formattedArray = [];

      for (let i = 1; i < rawData.length; i++) {
        if (!clcArray.includes(rawData[i][0])) {
          clcArray.push(rawData[i][0]);
        }
      }

      for (let i = 0; i < clcArray.length; i++) {
        let item: any = { clc: clcArray[i], data: [] };

        for (let j = 1; j < rawData.length; j++) {
          if (rawData[j][0] === clcArray[i]) {
            item.data.push({
              year: rawData[j][2],
              month: rawData[j][3],
              day: rawData[j][4],
              consumption: rawData[j][5]
            });
          }
        }

        formattedArray.push(item);
      }

      setChartData(formattedArray);
      setShowChartModal(true);
    });

    dispatch(toggleLoader(false));
  };

  const getReportType = (group: any, type: string) => {
    if (baseUrl.includes('OrdersRapoarteDateMasuraEE')) {
      getMeasuredDataReport(group, type);
    } else {
      getReportData(group);
    }
  };

  const getDataFromMeasuredReport = (data: any, type: string) => {
    // data = response.exportData.tableGridStructure.gridRows
    let chartData: any[] = [];
    let registerData: any[] = [];

    for (let i = 0; i < data.length; i++) {

      if (data[i].tables[0].header !== null && data[i].tables[0].header.columns.includes('Serie Contor')) {
        for (let j = 0; j < data[i].tables[0].rows.length; j++) {
          let item: any = {};

          for (let k = 0; k < data[i].tables[0].header.columns.length; k++) {
            let label = data[i].tables[0].header.columns[k];
            let value = data[i].tables[0].rows[j].columns[k];

            item[label] = value;
          }

          if (!data[i].tables[0].rows[j].columns[0].includes('<')) {
            registerData.push(item);
          }
        }
      }

      if (data[i].tables[0].header !== null && data[i].tables[0].header.columns.includes('Data citirii')) {
        for (let j = 0; j < data[i].tables[0].rows.length; j++) {
          let item: any = {};

          for (let k = 0; k < data[i].tables[0].header.columns.length; k++) {
            let label = data[i].tables[0].header.columns[k];
            let value = data[i].tables[0].rows[j].columns[k];

            item[label] = value;
          }

          if (!data[i].tables[0].rows[j].columns[0].includes('<')) {
            chartData.push(item);
          }
        }
      }
    }

    if (chartData.length === 0) {
      setShowChartErrorModal(true);
    } else {
      chartData.map((item, index) => {
        Object.keys(item).forEach((key, idx) => {
          if (key.includes('Total')) {
            item[key.replace('Total', 'Cantitate')] = item[key];
            delete item[key];
          }
        })
      })

      setMChartData([{ data: chartData, registerData: registerData, type: type }]);
      setShowMeasuredChartModal(true);
    }
  };

  const getMeasuredDataReport = async (group: any, type: string) => {
    dispatch(toggleLoader(true));

    await getRequestDetails(baseUrl, { id: typeof group === 'number' ? group : group.consumptionPoints[0].id })
      .then((response) => {
        getDataFromMeasuredReport(response.exportData.tableGridStructure.gridRows, type);
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const getReportData = async (group: any) => {
    dispatch(toggleLoader(true));

    await getReport(baseUrl, group.id)
      .then((response) => {
        if (response && response.status === 200) {
          let data = JSON.parse(response.data);

          if (data.length === 0) {
            setShowChartErrorModal(true);
          } else {
            let formattedArray = [];

            for (let i = 0; i < data.length; i++) {
              let item = {
                year: data[i].An,
                month: data[i].Luna,
                day: data[i].Zi || '',
                consumption: data[i].Consum
              };

              formattedArray.push(item);
            }

            setChartData([{ clc: data[0].LocConsum, data: formattedArray }]);
            setShowChartModal(true);
          }
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const baseUrlChartCheck = (url: any) => {
    if (url.includes('OrdersRapoarteDateMasuraEE') || url.includes('OrdersConsumuriFacturateLunarEE') ||
    url.includes('OrdersConsumuriFacturateLunarGN') || url.includes('OrdersConsumuriZilniceGN')) {
      return true;
    } else {
      return false;
    }
  }

  const checkForEmptyChartData = (data: any) => {
    let isEmpty = true;

    for (let i = 0; i < data.length; i++) {
      let item = JSON.parse(data[i]);

      if (item.length > 0) {
        isEmpty = false;
      }
    }

    return isEmpty;
  };

  const getReportDataGroup = async (group: any) => {
    dispatch(toggleLoader(true));

    await getReport(baseUrl, group.id)
      .then((response) => {
        if (response && response.status === 200) {
          if (checkForEmptyChartData(response.data)) {
            setShowChartErrorModal(true);
          } else {
            let chart_data = [];

            for (let i = 0; i < response.data.length; i++) {
              let data = JSON.parse(response.data[i]);
              let formattedArray = [];

              for (let j = 0; j < data.length; j++) {
                let item = {
                  year: data[j].An,
                  month: data[j].Luna,
                  day: data[j].Zi || '',
                  consumption: data[j].Consum
                };

                formattedArray.push(item);
              }

              chart_data.push({ clc: data[0].LocConsum, data: formattedArray });
            }

            setChartData(chart_data);
            setShowChartModal(true);
          }
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const getExistsAtListOneCompletedRequestInGroup = (group: any) => {
    let exists = false;
    group.consumptionPoints.forEach((cp: any) => {
      //Finalizata
      if (cp.idStatus == 3) {
        exists = true;
      }
    });
    return exists;
  };

  const setTimmerToRemoveLastCreatedRequest = () => {
    setTimeout(() => {
      setLastCreatedRequest(null);
    }, 5000);
  };

  const handleSuccesCreateRequest = async () => {
    dispatch(toggleLoader(true));
    window.scrollTo(0, 0);
    setShowNewRequestPage(false);
    listRequests(baseUrl, { pageNumber: 1, pageSize: perPage, sortBy: 'id', sortType: 'desc' })
      .then((res) => {
        setLastCreatedRequest(res.items[0]);
      })
      .catch((err) => {});

    setSearchDisabled(true);
    setSortColumn('id');
    setSortAscending(false);
    setCurrentPage(1);
    setSearchDisabled(false);
  };

  const renderChartErrorModal = () => {
    return (
      <Modal
        show={showChartErrorModal}
        centered
        backdrop="static"
        onHide={() => setShowChartErrorModal(false)}
        onExit={() => setShowChartErrorModal(false)}
      >
        <Modal.Header closeButton>
          <h5 className="red">Eroare</h5>
        </Modal.Header>
        <Modal.Body>Nu există date disponibile.</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowChartErrorModal(false)}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {showNewRequestPage ? (
        <NewRequest
          config={config}
          supplierId={supplierId}
          isClient={isClient}
          onSuccesCreateRequest={handleSuccesCreateRequest}
          baseUrl={baseUrl}
          setShowNewRequestPage={setShowNewRequestPage}
        />
      ) : (
        <div>
          <div className="orders_list_container">
            <div className="mb-3 d-flex justify-content-center flex-wrap justify-content-sm-end">
              <ButtonFilters className="ms-3 mb-2" toggled={toggleSearch} onClick={() => setToggleSearch((prev) => !prev)} />
              <Button className="ms-3 mb-2 btn-wide" style={{ minWidth: '172px' }} onClick={() => setShowNewRequestPage(true)}>
                Solicitare nouă
              </Button>
            </div>

            {toggleSearch && (
              <div className="m-3">
                <Row className="mb-2 justify-content-center">
                  <Col xs={12} md={{ span: 3 }}>
                    <Dropdown
                      options={STATUS_IDS as DropdownOptionType[]}
                      label={'Status'}
                      // defaultPlaceholder={`Alege status`}
                      onChange={(value) => {
                        setSelectedStatusId(value);
                      }}
                      value={selectedStatusId}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col xs={12} md={{ span: 3 }}>
                    <div className="triplet-separator"></div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center">
                  <Button onClick={() => submitSearch()}>Caută</Button>
                  <Button style={{ marginLeft: '5px' }} onClick={() => reset()}>
                    Reset
                  </Button>
                </div>
              </div>
            )}

            {selectedStatusId && list.items.length == 0 ? (
              <div style={{ color: 'red', fontWeight: 'bold' }}>Nu s-au identificat rezultate conform criteriilor de cautare.</div>
            ) : (
              <>
                <div className="general-table-container mb-3">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div style={{ cursor: 'pointer' }} onClick={() => onSort('id')}>
                            Număr solicitare{' '}
                            {sortColumn != 'id' ? (
                              <FontAwesomeIcon icon={faSort} size="sm" />
                            ) : sortAscending ? (
                              <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                            )}
                          </div>
                        </th>
                        <th>
                          <div style={{ cursor: 'pointer' }} onClick={() => onSort('entryDate')}>
                            Dată depunere{' '}
                            {sortColumn != 'entryDate' ? (
                              <FontAwesomeIcon icon={faSort} size="sm" />
                            ) : sortAscending ? (
                              <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                            )}
                          </div>
                        </th>
                        <th>
                          <div style={{ cursor: 'pointer' }} onClick={() => onSort('pod')}>
                            {config.utility == 1 ? 'POD' : 'CLC'}{' '}
                            {sortColumn != 'pod' ? (
                              <FontAwesomeIcon icon={faSort} size="sm" />
                            ) : sortAscending ? (
                              <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                            )}
                          </div>
                        </th>
                        <th>Denumire partener</th>
                        <th>
                          <div style={{ cursor: 'pointer' }} onClick={() => onSort('inPortfolio')}>
                            Din portofoliu{' '}
                            {sortColumn != 'inPortfolio' ? (
                              <FontAwesomeIcon icon={faSort} size="sm" />
                            ) : sortAscending ? (
                              <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                            )}
                          </div>
                        </th>
                        <th>
                          <div style={{ cursor: 'pointer' }} onClick={() => onSort('status')}>
                            Status{' '}
                            {sortColumn != 'status' ? (
                              <FontAwesomeIcon icon={faSort} size="sm" />
                            ) : sortAscending ? (
                              <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                            )}
                          </div>
                        </th>
                        {config.reportTypes && (
                          <th>
                            <div style={{ cursor: 'pointer' }} onClick={() => onSort('reportTypeId')}>
                              Tip raport{' '}
                              {sortColumn != 'reportTypeId' ? (
                                <FontAwesomeIcon icon={faSort} size="sm" />
                              ) : sortAscending ? (
                                <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                              ) : (
                                <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                              )}
                            </div>
                          </th>
                        )}
                        <th>Acțiuni</th>
                      </tr>
                    </thead>

                    <tbody>
                      <>
                        {ordersGroups &&
                          ordersGroups.map((group: any, index: number) => (
                            <Fragment key={index}>
                              {group.consumptionPoints.length > 1 ? (
                                <>
                                  <tr
                                    key={`G-${index}`}
                                    className={`${lastCreatedRequest ? (group.id == lastCreatedRequest.id ? 'highlight' : '') : ''}`}
                                  >
                                    <td style={{ maxWidth: '60px', minWidth: '60px' }}>
                                      <div className="d-flex justify-content-center">
                                        <span
                                          style={{ cursor: 'pointer', color: `${!isGroupToggled(group) ? '#ea1b0a' : '#8f9192'}` }}
                                          onClick={() => toggleGroup(group, index)}
                                        >
                                          {isGroupToggled(group) ? (
                                            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
                                          ) : (
                                            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
                                          )}
                                        </span>
                                      </div>
                                    </td>

                                    <td className="fw-bold">-</td>
                                    <td className="fw-bold">{moment(group.entryDate, 'YYYYMMDD').format('DD.MM.YYYY')}</td>
                                    <td className="fw-bold">-</td>
                                    <td className="fw-bold">-</td>
                                    <td className="fw-bold">{group.inPortfolio ? 'Da' : 'Nu'}</td>
                                    <td className="fw-bold">{getGroupStatus(group)}</td>
                                    {config.reportTypes && <td className="fw-bold">{group.reportTypeName ?? '-'}</td>}
                                    <td className="fw-bold">
                                      {getExistsAtListOneCompletedRequestInGroup(group) && config.groupOrders && (
                                        <>
                                          {group.inPortfolio && group.exportXlsFile ? (
                                            <a className="bold-link" href={group.exportXlsFile.documentLink}>
                                              Descarcă fișier
                                            </a>
                                          ) : (
                                            group.inPortfolio && (
                                              <a
                                                className="bold-link"
                                                href={'#'}
                                                onClick={() => {
                                                  handleDownloadMergedExport(group);
                                                }}
                                              >
                                                Descarcă fișier
                                              </a>
                                            )
                                          )}
                                          {baseUrlChartCheck(baseUrl) && <FontAwesomeIcon
                                            icon={faChartSimple}
                                            className="bold-link"
                                            style={{ marginLeft: '12px' }}
                                            onClick={() => getReportDataGroup(group)}
                                          />}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                  {isGroupToggled(group) &&
                                    group.consumptionPoints &&
                                    group.consumptionPoints.map((item: any, index: number) => (
                                      <tr key={`I-${index}`}>
                                        <td style={{ maxWidth: '60px', minWidth: '60px' }}></td>
                                        <td>{item.id}</td>
                                        <td>{moment(group.entryDate, 'YYYYMMDD').format('DD.MM.YYYY')}</td>
                                        <td>{item.pod}</td>
                                        <td>{item.consumptionPointClientName}</td>
                                        <td>{group.inPortfolio ? 'Da' : 'Nu'}</td>
                                        <td>
                                          <span style={{ display: 'flex', flexDirection: 'row' }}>
                                            <span className="me-1">{item.status}</span>
                                            {item.backOfficeRejectReason && (
                                              <span>
                                                <Tippy
                                                  content={
                                                    <div
                                                      className="p-3"
                                                      style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}
                                                    >
                                                      <p className="mb-1">Motiv:</p>
                                                      <p className="m-0">{item.backOfficeRejectReason}</p>
                                                    </div>
                                                  }
                                                  duration={0}
                                                  placement="bottom"
                                                >
                                                  <div className="icon-div">
                                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                                  </div>
                                                </Tippy>
                                              </span>
                                            )}
                                            {item.cancelledWithTechnicalProblem && (
                                              <span>
                                                <Tippy
                                                  content={
                                                    <div
                                                      className="p-3"
                                                      style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}
                                                    >
                                                      <p className="mb-1">Motiv:</p>
                                                      <p className="m-0">A aparut o eroare tehnică</p>
                                                    </div>
                                                  }
                                                  duration={0}
                                                  placement="bottom"
                                                >
                                                  <div className="icon-div">
                                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                                  </div>
                                                </Tippy>
                                              </span>
                                            )}
                                          </span>
                                        </td>
                                        {config.reportTypes && <td>{group.reportTypeName ?? '-'}</td>}
                                        <td>
                                          {item.status == STATUS_FINALIZATA && (
                                            <>
                                              {config.displayModeStr == DISPLAY_MODE_DETAILS.name ||
                                              config.displayModeStr == DISPLAY_MODE_DETAILS_AND_EXPORT.name ? (
                                                <Button
                                                  onClick={() => {
                                                    onSelectedRequest(item);
                                                  }}
                                                >
                                                  Detalii
                                                </Button>
                                              ) : (
                                                <a className="bold-link" href={getDownload(item) ? getDownload(item).documentLink : null}>
                                                  {/* {getDownload(item) ? getDownload(item).fileName : ''} */}
                                                  Descarcă fișier
                                                </a>
                                              )}
                                              {baseUrlChartCheck(baseUrl) && <FontAwesomeIcon
                                                icon={faChartSimple}
                                                className="bold-link"
                                                style={{ marginLeft: '12px' }}
                                                onClick={() => getReportType(item.id, group.reportTypeName)}
                                              />}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </>
                              ) : (
                                <>
                                  <tr
                                    key={`G0-${index}`}
                                    className={`${lastCreatedRequest ? (group.id == lastCreatedRequest.id ? 'highlight' : '') : ''}`}
                                  >
                                    <td style={{ maxWidth: '60px', minWidth: '60px' }}>
                                      <div className="d-flex justify-content-center">
                                        <span style={{ color: '#8f9192' }}>
                                          <FontAwesomeIcon icon={faCircleMinus} size="lg" />
                                        </span>
                                      </div>
                                    </td>
                                    <td>{group.consumptionPoints[0].id}</td>
                                    <td>{moment(group.entryDate, 'YYYYMMDD').format('DD.MM.YYYY')}</td>
                                    <td>{group.consumptionPoints[0].pod}</td>
                                    <td>{group.consumptionPoints[0].consumptionPointClientName}</td>
                                    <td>{group.inPortfolio ? 'Da' : 'Nu'}</td>
                                    <td>
                                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                                        <span className="me-1">{group.consumptionPoints[0].status}</span>
                                        {group.consumptionPoints[0].backOfficeRejectReason && (
                                          <span>
                                            <Tippy
                                              content={
                                                <div
                                                  className="p-3"
                                                  style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}
                                                >
                                                  <p className="mb-1">Motiv:</p>
                                                  <p className="m-0">{group.consumptionPoints[0].backOfficeRejectReason}</p>
                                                </div>
                                              }
                                              duration={0}
                                              placement="bottom"
                                            >
                                              <div className="icon-div">
                                                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                              </div>
                                            </Tippy>
                                          </span>
                                        )}

                                        {group.consumptionPoints[0].cancelledWithTechnicalProblem && (
                                          <span>
                                            <Tippy
                                              content={
                                                <div
                                                  className="p-3"
                                                  style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}
                                                >
                                                  <p className="mb-1">Motiv:</p>
                                                  <p className="m-0">A aparut o eroare tehnică</p>
                                                </div>
                                              }
                                              duration={0}
                                              placement="bottom"
                                            >
                                              <div className="icon-div">
                                                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                              </div>
                                            </Tippy>
                                          </span>
                                        )}
                                      </span>
                                    </td>
                                    {config.reportTypes && <td>{group.reportTypeName ?? '-'}</td>}
                                    <td>
                                      {group.consumptionPoints[0].status == STATUS_FINALIZATA && (
                                        <>
                                          {config.displayModeStr == DISPLAY_MODE_DETAILS.name ||
                                          config.displayModeStr == DISPLAY_MODE_DETAILS_AND_EXPORT.name ? (
                                            <Button
                                              onClick={() => {
                                                onSelectedRequest(group.consumptionPoints[0]);
                                              }}
                                            >
                                              Detalii
                                            </Button>
                                          ) : (
                                            <a
                                              className="bold-link"
                                              href={
                                                getDownload(group.consumptionPoints[0])
                                                  ? getDownload(group.consumptionPoints[0]).documentLink
                                                  : null
                                              }
                                            >
                                              {/* {getDownload(item) ? getDownload(item).fileName : ''} */}
                                              Descarcă fișier
                                            </a>
                                          )}
                                          {baseUrlChartCheck(baseUrl) && <FontAwesomeIcon
                                            icon={faChartSimple}
                                            className="bold-link"
                                            style={{ marginLeft: '12px' }}
                                            onClick={() => getReportType(group, group.reportTypeName)}
                                          />}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </Fragment>
                          ))}
                      </>
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  totalElements={totalItemsCount}
                  perPage={perPage}
                  paginate={paginate}
                  setElementsPerPage={setElementsPerPage}
                />
                <hr className="m-0" />
              </>
            )}
          </div>
          <br />
          <ModalComponent
            show={showModalRequestDetails}
            title={'Detalii solicitare'}
            size="xl"
            setShow={(value) => setShowModalRequestDetails(value)}
          >
            <div className="p-2">
              {selectedRequestDetails && (
                <>
                  <Row>
                    <div className="mb-2 mr-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {selectedRequestDetails.exportCsvFile && (
                        <a
                          className="link link--download red download-link-text hover-black"
                          href={selectedRequestDetails.exportCsvFile.documentLink}
                        >
                          Export CSV
                        </a>
                      )}
                      {selectedRequestDetails.exportDocFile && (
                        <a
                          className="ms-3 link link--download red download-link-text hover-black"
                          href={selectedRequestDetails.exportDocFile.documentLink}
                        >
                          Export DOC
                        </a>
                      )}
                      {selectedRequestDetails.exportPdfFile && (
                        <a
                          className="ms-3 link link--download red download-link-text hover-black"
                          href={selectedRequestDetails.exportPdfFile.documentLink}
                        >
                          Export PDF
                        </a>
                      )}
                      {selectedRequestDetails.exportXlsFile && (
                        <a
                          className="ms-3 link link--download red download-link-text hover-black"
                          href={selectedRequestDetails.exportXlsFile.documentLink}
                        >
                          Export XLS
                        </a>
                      )}
                    </div>
                    <Col xs={12} md={6}>
                      <div className="details-block h-100">
                        <div className="block-header">
                          <span className="block-title-border" />
                          <span className="block-title">Solicitare</span>
                        </div>
                        <div className="block-body">
                          <div className="property-container">
                            <span className="property-key">Număr solicitare</span>
                            <span className="property-value">{selectedRequestDetails.id}</span>
                          </div>

                          <div className="property-container">
                            <span className="property-key">Dată depunere</span>
                            <span className="property-value">
                              {moment(selectedRequestDetails.entryDate, 'YYYYMMDD').format('DD.MM.YYYY')}
                            </span>
                          </div>

                          <div className="property-container">
                            <span className="property-key">{config.utility == 1 ? 'POD' : 'CLC'}</span>
                            <span className="property-value">{selectedRequestDetails.pod}</span>
                          </div>

                          <div className="property-container">
                            <span className="property-key">Din portofoliu</span>
                            <span className="property-value">{selectedRequestDetails.inPortfolio ? 'Da' : 'Nu'}</span>
                          </div>

                          <div className="property-container">
                            <span className="property-key">Status</span>
                            <span className="property-value">{selectedRequestDetails.status}</span>
                          </div>
                          {config.reportTypes && (
                            <div className="property-container">
                              <span className="property-key">Tip raport</span>
                              <span className="property-value">{selectedRequestDetails.reportTypeName}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {selectedRequestDetails.exportData && (
                    <Row className="mt-2">
                      {selectedRequestDetails.exportData.header &&
                        selectedRequestDetails.exportData.header.map((header: any) => {
                          return <div dangerouslySetInnerHTML={{ __html: header }}></div>;
                        })}

                      <Row className="mt-1">
                        {selectedRequestDetails.exportData.tableGridStructure.gridRows &&
                          selectedRequestDetails.exportData.tableGridStructure.gridRows.map((gridRow: any) => {
                            return (
                              <Col className="mt-1">
                                {gridRow.tables.map((table: any, index: number) => {
                                  return (
                                    <div className="general-table-container" key={index}>
                                      <table>
                                        <thead>
                                          <tr>
                                            {table.description
                                              ? table.rows[0].columns.map((column: any, index: number) => {
                                                  return index == 0 ? (
                                                    <th>
                                                      <div
                                                        style={{ width: '100%' }}
                                                        dangerouslySetInnerHTML={{ __html: table.description }}
                                                      ></div>{' '}
                                                    </th>
                                                  ) : (
                                                    <th></th>
                                                  );
                                                })
                                              : table.header && (
                                                  <>
                                                    {table.header.columns.map((column: any, index: number) => {
                                                      return (
                                                        <th>
                                                          <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: column }} />{' '}
                                                        </th>
                                                      );
                                                    })}
                                                  </>
                                                )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {table.rows.map((row: any, index: number) => (
                                            <tr key={index}>
                                              {row.columns.map((column: any) => {
                                                return <td dangerouslySetInnerHTML={{ __html: column }} />;
                                              })}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  );
                                })}
                              </Col>
                            );
                          })}
                      </Row>

                      {selectedRequestDetails.exportData.footer &&
                        selectedRequestDetails.exportData.footer.map((footer: any) => {
                          return <div dangerouslySetInnerHTML={{ __html: footer }}></div>;
                        })}
                    </Row>
                  )}
                </>
              )}
            </div>
          </ModalComponent>
          {renderChartErrorModal()}
          {chartData.length > 0 && showChartModal && (
            <ChartModal show={showChartModal} setShow={(value: boolean) => setShowChartModal(value)} chartData={chartData} />
          )}
          {mChartData.length > 0 && showMeasuredChartModal && (
            <MeasuredChartModal
              show={showMeasuredChartModal}
              setShow={(value: boolean) => setShowMeasuredChartModal(value)}
              chartData={mChartData}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Requests;