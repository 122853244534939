import React, { useEffect, useState, useRef } from 'react';
import { ValueToSet } from '../PageWrapper';
import { File } from '@components/common/UploadFile/UploadFile';
import { UploadFilesBulk } from '@components/common/UploadFilesBulk';
import { v1 as uuid } from 'uuid';
import { isArray } from 'lodash';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';

type Base64FileListProps = {
  name: string;
  value?: string | number | any;
  label: string;
  itemTypeId: number;
  onChange?: (e: ValueToSet) => void;
  viewFilesValues?: any;
  allowedFileExtensions: string[] | any;
  allowedMaxTotalFileSizeMB: number;
};

const Base64FileList = ({
  name,
  label,
  onChange,
  itemTypeId,
  value,
  viewFilesValues,
  allowedFileExtensions,
  allowedMaxTotalFileSizeMB
}: Base64FileListProps) => {
  const [files, setFiles] = useState<any>([]);
  const [filesEdit, setFilesEdit] = useState<any>([]);
  const [uploadFilesBulkKey, setUploadFilesBulkKey] = useState(0);
  const [selectedTab, setSelectedTab] = useState<string>('');

  const valueReference = useRef();
  valueReference.current = value;

  const dispatch = useDispatch();

  //edit case
  useEffect(() => {
    if (viewFilesValues && viewFilesValues.length > 0) {
      let list: File[] = [];
      viewFilesValues.forEach((file: any) => {
        list.push({
          uuid: file.blobName,
          fileUrl: file.documentUrl,
          name: file.originalFileName,
          containerName: file.containerName,
          date: file.fileCreatedDate
        });
      });
      setFilesEdit(list);
    }
  }, [viewFilesValues]);

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileListChange = async (list: any[]) => {
    dispatch(toggleLoader(true));
    let mapedList = await Promise.all(
      list.map(async (li) => {
        let file: File = {};
        file.uuid = uuid();
        file.name = li.file.name;

        if (li.file) {
          let base64: string | any = await convertToBase64(li.file);
          base64 = base64.slice(base64.lastIndexOf('base64,') + 7);
          file.file = base64;
        }
        return file;
      })
    );

    mapedList = mapedList.map((li) => {
      return {
        NumeDocument: li.name,
        ContinutDocument: li.file
      };
    });

    let listWithDeletedFiles = [...mapedList];
    if (isArray(valueReference.current)) {
      //@ts-ignore
      const filtered = valueReference.current?.filter((vr) => {
        return !!vr.Delete;
      });
      listWithDeletedFiles = [...listWithDeletedFiles, ...filtered];
    }

    onChange &&
      onChange({
        name: name,
        value: listWithDeletedFiles,
        itemTypeId
      } as ValueToSet);

    setFiles(list);
    dispatch(toggleLoader(false));
  };

  const deleteOneEditFile = (fileToDelete: any) => {
    const filesFiltered = filesEdit.filter((fe: File) => fe.uuid !== fileToDelete.uuid);
    setFilesEdit(filesFiltered);

    const list = valueReference.current
      ? [
          ...valueReference.current,
          {
            Delete: {
              ContainerName: fileToDelete.containerName,
              BlobName: fileToDelete.uuid
            },
            NumeDocument: null,
            ContinutDocument: null
          }
        ]
      : [
          {
            Delete: {
              ContainerName: fileToDelete.containerName,
              BlobName: fileToDelete.uuid
            },
            NumeDocument: null,
            ContinutDocument: null
          }
        ];

    onChange &&
      onChange({
        name: name,
        value: list,
        itemTypeId
      } as ValueToSet);
  };

  const deleteAllEditFiles = () => {
    const filesToDelete = filesEdit.map((fe: any) => {
      return {
        Delete: {
          ContainerName: fe.containerName,
          BlobName: fe.uuid
        },
        NumeDocument: null,
        ContinutDocument: null
      };
    });

    let list = [...filesToDelete];

    if (isArray(valueReference.current)) {
      //@ts-ignore
      const alreadyDeletedList = valueReference.current?.filter((vr) => {
        return !!vr.Delete;
      });
      list = [...list, ...alreadyDeletedList];
    }

    onChange &&
      onChange({
        name: name,
        value: list,
        itemTypeId
      } as ValueToSet);

    setFilesEdit([]);
  };

  return (
    <>
      <label style={{ position: 'relative', left: '5px' }}>
        <div>{label}</div>
      </label>
      <UploadFilesBulk
        key={uploadFilesBulkKey}
        files={files}
        setFiles={handleFileListChange}
        filesEdit={filesEdit}
        deleteOneEditFile={deleteOneEditFile}
        deleteAllEditFiles={deleteAllEditFiles}
        requiredDeleteConfirm
        allowedFileExtensions={allowedFileExtensions}
        reloadComponent={() => setUploadFilesBulkKey(uploadFilesBulkKey + 1)}
        setExternalSelectedTab={setSelectedTab}
        externalSelectedTab={selectedTab}
        totalSizeLimit={allowedMaxTotalFileSizeMB}
      />
    </>
  );
};

export default Base64FileList;
