import { defaultEvent } from '@constants/Event';
import { ToLowerCase } from '@lib/Utils';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DropdownOption from '../Dropdown/DropdownOption';

type DropdownOptionType = {
  id: string | number;
  name: string;
  disabled?: boolean;
  extraPropertyName?: string;
  extraPropertyValue?: string | number;
};

type MultiselectProps = {
  options: DropdownOptionType[];
  disabled?: boolean;
  height?: number;
  placeholder?: string;
  name?: string;
  label?: string;
  onChange: (option: any) => void;
  error?: string;
  mbZero?: boolean;
  initialSelectedOptions?: DropdownOptionType[];
};

const Multiselect = forwardRef(({ options, disabled, height, placeholder, onChange, ...props }: MultiselectProps, ref) => {
  const [selectedOptions, setSelectedOptions] = useState<DropdownOptionType[]>([]);
  const [search, setSearch] = useState<String>('');
  const [collapsed, setCollapsed] = useState<boolean>(true);
  //eslint-disable-next-line
  const [invalid, setInvalid] = useState<boolean>(false);

  const dropdownButtonRef = useRef(null);
  const searchInputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    toggleOptionList
  }));

  useEffect(() => {
    if (props.initialSelectedOptions) {
      setSelectedOptions(props.initialSelectedOptions);
    }
  }, [props.initialSelectedOptions]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  }, []);

  const handleSelect = (option: DropdownOptionType) => {
    // console.log(option, selectedOptions);
    let updatedOptions;
    if (!selectedOptions.find((selected) => selected.id === option.id)) {
      updatedOptions = [...selectedOptions, option];
      setSelectedOptions(updatedOptions);
    } else {
      updatedOptions = selectedOptions.filter((selected) => selected.id !== option.id);
      setSelectedOptions(updatedOptions);
    }
    onChange(updatedOptions);
  };

  const toggleOptionList = () => {
    setCollapsed(!collapsed);
    setTimeout(() => {
      //@ts-ignore
      searchInputRef.current?.focus();
    }, 100);
  };

  const handleOutsideClick = (event: any) => {
    event = event || defaultEvent;
    // If the clicked element it's inside dropdown, don't collapse the dropdown
    //@ts-ignore
    if (dropdownButtonRef?.current?.contains(event?.target)) return;
    setCollapsed(true);
  };

  const renderNonTouchWithSearch = () => {
    if (!options) return;
    let filtereOptions = options.filter((option) => option.id !== '-1');
    filtereOptions = filtereOptions.filter((option) => ToLowerCase(option.name).includes(ToLowerCase(search)));

    return (
      <div className={`delgazui-dropdown-field-group field-group ${props.mbZero && 'mb-0'}`} id="drop-down-delgaz">
        {/* <div className={`delgazui-dropdown-wrapper ${!!selectedOption && selectedOption.name ? 'delgazui-touched' : ''}`}> */}
        <div className={`delgazui-dropdown-wrapper ${selectedOptions.map((so) => so.name).join(', ') ? 'delgazui-touched' : ''}`}>
          <div
            role="button"
            tabIndex={0}
            onClick={toggleOptionList}
            ref={dropdownButtonRef}
            className={`delgazui-dropdown-button ${!!disabled ? 'delgazui-disabled' : ''} ${invalid ? 'delgazui-invalid' : ''} ${
              collapsed ? 'delgazui-collapsed' : ''
            }`}
          >
            {/* <div className="delgazui-option-label-wrapper">{selectedOption && selectedOption.name}</div> */}
            <div className="delgazui-option-label-wrapper">{selectedOptions.map((so) => so.name).join(', ')}</div>
            <div className="delgazui-icon-background">
              <svg className="delgazui-icon" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 12a1 1 0 011.828-.561C4.046 14.712 6.12 17.212 8 18.875c1.88-1.665 3.956-4.165 6.172-7.436a1 1 0 011.656 1.122c-2.45 3.615-4.666 6.248-6.777 8.05-.605.52-1.5.52-2.104-.001-2.11-1.801-4.326-4.434-6.776-8.05a.998.998 0 01-.164-.44L0 12z"
                  fillRule="evenodd"
                />
              </svg>
            </div>

            <div className={`delgazui-option-list search-list ${collapsed ? 'delgazui-collapsed' : ''}`} style={{ height: height }}>
              <div className="drp-search-cont" onClick={(event) => event?.stopPropagation()}>
                <div className="search-box">
                  <div className="search-input">
                    <input
                      ref={searchInputRef}
                      type="search"
                      value={search as string}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <p>
                  <b>{placeholder}</b>
                </p>
              </div>
              <div className="scroll-content">
                {filtereOptions &&
                  filtereOptions.map((option, i) => (
                    <DropdownOption
                      key={`${option.id}-${i}`}
                      value={option.id}
                      label={option.name}
                      disabled={!!option.disabled}
                      // selected={!!selectedOption && selectedOption.name === option.name}
                      selected={
                        !!selectedOptions.find((op: any) => {
                          return op.name === option.name;
                        })
                      }
                      // hasFocus={this._isFocused(i)}
                      onClick={(event) => {
                        event.preventDefault();
                        if (option.disabled) {
                          return event.stopPropagation();
                        } else {
                          event.stopPropagation();
                          // return setSelectedOption({ id: option.id, name: option.name });
                          handleSelect(option);
                        }
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
          <label htmlFor={props.name}>{props.label}</label>

          {props.error && <span className="label-error">{props.error}</span>}
        </div>
      </div>
    );
  };

  return <>{renderNonTouchWithSearch()}</>;
});

export default Multiselect;
