import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import InfoPVRFurnizor from '@components/pvr/InfoPVRFurnizor';

export const PVRInfoFurnizor = () => {
    return (
        <DelgazWrapper>
            <InfoPVRFurnizor />
        </DelgazWrapper>
    );
};