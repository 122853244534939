
import { AnyAction } from 'redux';
import { store, persistor } from '@lib/store';
import { Action } from '@reducers';
import { allowedInputType, InputTypes } from '@constants/InputTypes';
import { replacePath, replacePathAndHome, replacePathBase } from '@constants/Replace';
import {
  HOME_PAGE
} from '@constants/Utils';

declare var window: Window & { document: any; DocumentTouch: any };

export function Timeout<T = void>(ms = 0, data?: T) {
  return new Promise<T>((resolve) => setTimeout(resolve, ms, data));
}

export function ToRounded(value: number | null, decimals: number = 0) {
  if (value === null) return 0;

  return parseFloat(value.toFixed(decimals));
}

export function ToNumber(value: any = '0', defaultValue = 0) {
  const newValue = parseInt(ToString(value), 10);

  if (!newValue || isNaN(newValue)) {
    return defaultValue;
  }

  return newValue;
}

export function ToFloat(value: any = '0') {
  const newValue = parseFloat(ToString(value));

  if (isNaN(newValue)) {
    return 0;
  }
  return newValue;
}

/**
 * Capitalize each word from a string. Separators: whitespace, hyphen.
 * @param str
 */
export function CapitalizeEachWord(str?: string) {
  if (!str) return '';

  return ToString(str)
    .toLowerCase()
    .replace(/(?:^|[\s-])\w/g, (match) => match.toUpperCase());
}

export function ToString(value: any = '', defaultValue = '') {
  if (!value) return defaultValue || '';
  if (typeof value === 'object') return JSON.stringify(value);

  return `${value}`;
}

export function ToLowerCase(value: any = '') {
  return ToString(value).toLowerCase();
}
export function ToUpperCase(value: any = '') {
  return ToString(value).toUpperCase();
}

export function StringToBoolean(value: string): boolean {
  return JSON.parse(value);
}
export function GetInputType(type?: InputTypes, defaultType = InputTypes.TEXT) {
  if (!type || allowedInputType.indexOf(type) === -1) {
    return defaultType;
  }

  return type;
}

export function AddSpace(value?: any, after = 3) {
  let val = ToString(value);

  if(val.length > 155) {
    return val;
  }

  return ToString(value)
    .replace(new RegExp(`(\\d{${after}})`, 'g'), '$1 ')
    .replace(/(^\s+|\s+$)/, '');
}

export function IsObjectEmpty(obj = {}) {
  try {
    return JSON.stringify(obj) === '{}';
  } catch (e) {
    return true;
  }
}

export function replaceLink(link = '') {
  let replacedLink = link;
  if (link && link.indexOf(replacePathBase) !== -1 && !link.endsWith('/') && !link.endsWith('.html')) {
    // Add html extension to the end of the links
    replacedLink = link + '.html';
  }
  if (window) {
    replacedLink = window.location.href.includes(replacePathAndHome) ? link : link.replace(replacePath, '/');
  }
  return replacedLink;
}

// export function replaceHomeLink(link = '') {
//   let replacedLink = link;

//   if (window) {
//     replacedLink = window.location.href.includes(replacePathBase) ? link : ToString(HOME_PAGE);
//   }

//   return replacedLink;
// }

// export function goToHomePage() {
//   const state = store.getState();

//   return window.open(replaceHomeLink((state && state.options && state.options.homepageLink) || ToString(HOME_PAGE)), '_self');
// }

export function goToDashboardPage() {
  const state = store.getState();

  const dashboardLink = state && state.options && state.options.dashboardLink;

  return window.open(replaceLink(dashboardLink || ToString(HOME_PAGE)), '_self');
}

// export function goToWelcomePage() {
//   const welcomeLink = ToString(MYLINE_WELCOME_PAGE);

//   return window.open(replaceLink(welcomeLink), '_self');
// }

// export function goToLoginPage() {
//   const state = store.getState();

//   return window.open(replaceLink((state && state.options && state.options.loginPageLink) || ToString(HOME_PAGE)), '_self');
// }

// export function goToLogoutPage() {
//   const state = store.getState();
//   let link = replaceLink((state && state.options && state.options.logoutPageLink) || ToString(MYLINE_LOGOUT_PAGE));

//   // Set environment redirect for Myline Test Legacy logout flow
//   if (link.indexOf(QA_MYLINE_PAGE) !== -1) {
//     const aemEnv = window.location ? window.location.host.match(AEM_ENV_FROM_HOST_PATTERN) : null;
//     const queryParams = aemEnv ? [`?redirect=${aemEnv}`] : undefined;

//     if (queryParams) {
//       link = link.concat(queryParams.join('&'));
//     }
//   }

//   return window.open(link, '_self');
// }

// /**
//  * Go to internal link with default context
//  */
// export function goToInternalLinkWithDefaultContext(link = ToString(HOME_PAGE), forceRoot = false): Window | null {
//   if (forceRoot && link && link.indexOf('/') !== 0) link = `/${link}`;

//   return window.opener ? window.opener.open(replaceLink(link), '_self') : window.open(replaceLink(link), '_self');
// }

// /**
//  * Go to internal link with binding the context
//  *
//  * If you want to open an link in opener window you will need to bind "this" argument first
//  * ex. goToInternalLink.apply(window.opener: Window, [link: string, forceRoot: boolean])
//  */
// export let goToInternalLink = function (this: Window, link = ToString(HOME_PAGE), forceRoot = false): Window | null {
//   if (forceRoot && link && link.indexOf('/') !== 0) link = `/${link}`;

//   return this.open(replaceLink(link), '_self');
// }.bind(window);

// export async function goToMylineLink(link = ToString(HOME_PAGE)) {
//   if (!link) return undefined;

//   let transferToken = '';
//   Logger.verbose('[Utils] - goToMylineLink - start');

//   let state = store.getState() || {};

//   Logger.verbose(`[Utils] - goToMylineLink - link: ${link}`);

//   if (link.indexOf('/fast/redirect') !== -1) {
//     try {
//       await store.dispatch(toggleLoader(true));

//       if (!(await isClientAuth())) {
//         Logger.verbose('[Utils] - goToMylineLink - !isClientAuth');
//         throw new Error();
//       }

//       Logger.verbose('[Utils] - goToMylineLink - GetMylineTransToken - start');

//       const response = await UserApi.GetMylineTransToken({
//         migrated: true,
//         section: (state.user && state.user.section) || SECTION_TYPE.MY_HOUSE,
//         accountContract:
//           state.accountContracts &&
//           state.accountContracts.selectedAccountContract &&
//           state.accountContracts.selectedAccountContract.accountContract
//       });

//       Logger.verbose('[Utils] - goToMylineLink - GetMylineTransToken - done');

//       transferToken = (response && response.transferToken) || 'transfer_token';
//     } catch (error) {
//       const errorCode = ToString(error && error.code);

//       Logger.verbose(`[Utils] - goToMylineLink - errorCode: ${errorCode}`);

//       await store.dispatch(toggleLoader());

//       Logger.verbose('[Utils] - goToMylineLink - goToLoginPage');
//       return goToLoginPage();
//     }
//   }

//   state = store.getState() || {};
//   Logger.verbose('[Utils] - goToMylineLink - updateState');

//   const mylinePage = (state.options && state.options.mylinePage) || PROD_MYLINE_PAGE;
//   const aemEnv = window.location ? window.location.host.match(AEM_ENV_FROM_HOST_PATTERN) : null;

//   const mylineLinkArr = [mylinePage, link, transferToken];
//   const mylineLinkQueryParams = link.indexOf('/logout') !== -1 && aemEnv ? [`?redirect=${aemEnv}`] : undefined;
//   const mylineLink = concatLink(mylineLinkArr, mylineLinkQueryParams);

//   Logger.verbose(`[Utils] - goToMylineLink - done, link: ${mylineLink}`);
//   return window.open(mylineLink, '_self');
// }

// export function concatLink(links: Array<string | undefined>, queryParams?: Array<string | undefined>) {
//   if (!links || !links.length) return '';
//   const splitLinks = links.filter(Boolean).join('/').split('://');

//   let mylineLink = splitLinks.map((link) => ToString(link).replace(/\/{1,}/g, '/')).join('://');

//   if (queryParams) mylineLink = mylineLink.concat(queryParams.join('&'));

//   return mylineLink;
// }

// export function clearEndpoint(link = '') {
//   return ToString(link)
//     .replace(/\/{1,}/g, '')
//     .replace(/.html/g, '');
// }

// export function isProductSet(product: Product) {
//   return !!(product && (product.name || product.productName));
// }

// export function convertMWToKW(price = 0) {
//   const parsePrice = ToFloat(price);

//   return ToFloat((parsePrice / 1000).toFixed(5));
// }

// export function convertKWToMW(price = 0) {
//   const parsePrice = ToFloat(price);

//   return ToFloat((parsePrice * 1000).toFixed(5));
// }

// export function replaceWithEmpty(string = '', regExp = /(?:)/g) {
//   return string.replace(regExp, '');
// }

// export function getFileType(file = '') {
//   const fileBase = file.split(';base64,');

//   const filetype = fileType(new Buffer(ToString(fileBase && (fileBase[1] || fileBase[0])), 'base64'));

//   return filetype || { ext: '', mime: '' };
// }

// export function getSignatureName(fileName = '', ext = '') {
//   return `signature_${getFileName(fileName)}.${ext}`;
// }

// export function getSignatureType(fileName = '') {
//   let signatureType = contractSignature;
//   const fileNameMap = fileNamesMap();
//   const key = Object.keys(fileNameMap).find((file) => fileName.indexOf(file) !== -1);

//   if (!key) {
//     return signatureType;
//   }

//   switch (fileNameMap[key]) {
//     case CHANGE_SUPPLIER:
//       signatureType = changeSupplierSignature;
//       break;
//     case COMMERCIAL_ANNEX:
//       signatureType = commercialAnnexSignature;
//       break;
//     case CONTRACT_SERVEXPRESS:
//       signatureType = servExpressSignature;
//       break;
//     case CONTRACT:
//       signatureType = contractSignature;
//       break;
//     case NOTIFICATION_GDPR:
//       signatureType = notificationGdprSignature;
//       break;
//     case REQUEST_FOR_CONNECTION_EE:
//       signatureType = requestForConnectionEeSignature;
//       break;
//     case REQUEST_FOR_CONNECTION_GN:
//       signatureType = requestForConnectionGnSignature;
//       break;
//     case REVOCATION:
//       signatureType = revocationSignature;
//       break;
//     case SPACE_STATEMENT:
//       signatureType = spaceStatementSignature;
//       break;
//     default:
//       signatureType = contractSignature;
//   }
//   return signatureType;
// }

// export function getFileName(fileName = '') {
//   const fileNameMap = fileNamesMap();

//   const key = Object.keys(fileNameMap).find((file) => fileName.indexOf(file) !== -1);
//   if (!key) {
//     return undefined;
//   }

//   return fileNameMap[key as keyof typeof fileNameMap];
// }

// export function fileNamesMap() {
//   return {
//     anexa_contract_altex_duo: COMMERCIAL_ANNEX,
//     anexa_contract_altex_electric: COMMERCIAL_ANNEX,
//     anexa_contract_assist_electric: COMMERCIAL_ANNEX,
//     anexa_contract_assist_electric_plus: COMMERCIAL_ANNEX,
//     anexa_contract_assist_gas_plus: COMMERCIAL_ANNEX,
//     anexa_contract_duo_assist: COMMERCIAL_ANNEX,
//     anexa_contract_duo_assist_pro: COMMERCIAL_ANNEX,
//     anexa_contract_duo: COMMERCIAL_ANNEX,
//     anexa_contract_GN_CRM: COMMERCIAL_ANNEX,
//     anexa_contract_GN_CRM_Promo_Gas_Digital: COMMERCIAL_ANNEX,
//     anexa_contract_GN_Promo_Gas_Digital: COMMERCIAL_ANNEX,
//     anexa_contract_green_home: COMMERCIAL_ANNEX,
//     anexa_contract_home_electric_liberalizare: COMMERCIAL_ANNEX,
//     anexa_contract_home_electric: COMMERCIAL_ANNEX,
//     anexa_contract_home_gas_egfr: COMMERCIAL_ANNEX,
//     anexa_contract_home_gas_liberalizare: COMMERCIAL_ANNEX,
//     anexa_contract_home_gas: COMMERCIAL_ANNEX,
//     anexa_contract_retention_assist_electric: REVOCATION,
//     anexa_contract_retention_EE: REVOCATION,
//     anexa_contract_retention_GN: REVOCATION,
//     anexa_contract_start_electric: COMMERCIAL_ANNEX,
//     anexa_contract_start_electric_liberalizare: COMMERCIAL_ANNEX,
//     anexa_declaratie_pe_propria_raspundere: COMMERCIAL_ANNEX,
//     anexa_notificare_revocare: REVOCATION,
//     anexa_schimbare_furnizor_ee: CHANGE_SUPPLIER,
//     anexa_schimbare_furnizor_gn: CHANGE_SUPPLIER,
//     cerere_atr_casnic: REQUEST_FOR_CONNECTION_EE,
//     cerere_de_racordare_la_sistemul_de_distributie_gn: REQUEST_FOR_CONNECTION_GN,
//     contract_Altex_Duo: CONTRACT,
//     contract_Altex_Electric: CONTRACT,
//     contract_Assist_Electric_BestDeal: CONTRACT,
//     contract_Assist_Electric_Plus: CONTRACT,
//     contract_Assist_Gas_Plus: CONTRACT,
//     contract_BestDeal_Electric: CONTRACT,
//     contract_BestDeal_Gas: CONTRACT,
//     contract_Cost_Control_Gas: CONTRACT,
//     contract_Duo_Assist_Pro: CONTRACT,
//     contract_Duo_Assist: CONTRACT,
//     contract_Duo: CONTRACT,
//     contract_Green_Home: CONTRACT,
//     contract_Home_Electric_Liberalizare: CONTRACT,
//     contract_Home_Electric: CONTRACT,
//     contract_Home_Gas_Liberalizare: CONTRACT,
//     contract_Home_Gas: CONTRACT,
//     contract_Promo_Gas_Digital: CONTRACT,
//     contract_servexpress: CONTRACT_SERVEXPRESS,
//     contract_servexpress_iunie: CONTRACT_SERVEXPRESS,
//     contract_servexpress_mai: CONTRACT_SERVEXPRESS,
//     contract_Start_Electric_Liberalizare: CONTRACT,
//     contract_Start_Electric: CONTRACT,
//     contract_Start_Gas: CONTRACT,
//     contract_Super_Electric: CONTRACT,
//     contract_Super_Gas: CONTRACT,
//     contract_unic_utilitati_egfr: CONTRACT,
//     contract_unic_utilitati: CONTRACT,
//     declaratie_pe_propria_raspundere: SPACE_STATEMENT,
//     GDPR_Nota_informare_client: NOTIFICATION_GDPR,
//     notificare_revocare: CHANGE_SUPPLIER,
//     notificare_trascriere_cu_schimbare_furnizor: CHANGE_SUPPLIER
//   };
// }

// export function monthlyToAnnually(value = 0) {
//   if (isNaN(value)) {
//     return 0;
//   }

//   return value * 12;
// }

// export function annuallyToMonthly(value = 0) {
//   if (isNaN(value) || value <= 0) {
//     return 0;
//   }

//   return value / 12;
// }

// export function getUploadSteps({
//   products = [] as Product[],
//   steps = [] as any[],
//   accountContract = undefined as AccountContract | undefined,
//   isCnpValid = false,
//   isOwner = false,
//   utilityType = undefined as UtilityType | undefined,
//   isTranscriptionOwnerDataSame = false
// }) {
//   const utilityTypeAccountContracts = accountContract ? SectorIdToUtilityType[accountContract.utilityType] : undefined;

//   const utilityTypeExist =
//     utilityTypeAccountContracts && utilityType ? MoreInfoForUtility[utilityType][utilityTypeAccountContracts] : undefined;

//   return steps
//     .filter((step) =>
//       (isOwner && step.requiredNotOwner) ||
//       (isCnpValid && accountContract && step.id === 1) ||
//       (isTranscriptionOwnerDataSame && step.id === 1) ||
//       (step.utilityType && step.utilityType.length && accountContract && !utilityTypeExist)
//         ? false
//         : (step.productsId as []).find(
//             (productId) =>
//               !!products.find((product) =>
//                 accountContract && step.utilityType && step.utilityType.length
//                   ? step.utilityType.indexOf(ToString(utilityTypeExist)) > -1
//                   : productId === product.productName
//               )
//           )
//     )
//     .map((step) => {
//       const key = Object.keys(UploadParameters).find((existKey) => UploadParameters[existKey].id === step.id);

//       return key ? { ...step, ...UploadParameters[key] } : step;
//     });
// }

// export function isDuoProduct(product: Product) {
//   return !!(product && product.utilityType === DUAL);
// }

// export function isServExpressProduct(product: Product) {
//   return !!(product && (isServExpressProductName(product.productName) || isServExpressProductPortfolio(product.portfolio)));
// }

// export function isServExpressProductName(prodName: string) {
//   const name = ToLowerCase(prodName);
//   return !!(name && name.indexOf('serv') !== -1 && name.indexOf('express') !== -1 && name.indexOf('mmc') === -1);
// }

// export function isServExpressProductPortfolio(prodPortfolio: string) {
//   const portfolio = ToLowerCase(prodPortfolio);
//   return !!(portfolio && portfolio.indexOf('serv') !== -1 && portfolio.indexOf('express') !== -1 && portfolio.indexOf('mmc') !== -1);
// }

// export function isLifeProductName(prodName: string) {
//   const name = ToLowerCase(prodName);
//   return !!(name && name.indexOf('life') !== -1 && name.indexOf('mmc') === -1);
// }

// export function isAssistProductName(prodName: string) {
//   const name = ToLowerCase(prodName);
//   return !!(name && name.indexOf('assist') !== -1);
// }

// export function isAssistProductByName(product: Product) {
//   return !!(product && ToString(product.productName).toLocaleLowerCase().indexOf('assist') !== -1);
// }

// export function isFlatFeeProductName(prodName?: string) {
//   return prodName && CRM_FLAT_FEE_PRODUCTS.includes(prodName) ? true : false;
// }

// export function isOwnSupplier(supplierName = '') {
//   return ToString(supplierName).replace(/[\\.]/g, '').toLocaleLowerCase().indexOf('eon') !== -1;
// }

// export function encodeURI(uri = '') {
//   return ToString((global || window).encodeURI(uri));
// }

// export function loadURIParam(param?: undefined): queryString.ParsedQuery | undefined;
// export function loadURIParam(param: string): string | undefined;
// export function loadURIParam(param?: string): string | string[] | undefined | queryString.ParsedQuery {
//   const parsed = queryString.parse(window.location.search);

//   if (!parsed) return undefined;
//   else if (!param) return parsed;

//   return parsed[param] || undefined;
// }

// export function getSignSteps(
//   products = [] as any[],
//   steps = [] as any[],
//   isOwner = false,
//   isSupplierChanged?: SupplierChangeType,
//   isRecontractin?: boolean
// ) {
//   return steps.filter((step) => {
//     if (!step.documents) {
//       return false;
//     } else if (isOwner && step.requiredNotOwner) {
//       return false;
//     } else if (step.requiredNotRecontracting && isRecontractin) {
//       return false;
//     }

//     const checkSupplierChanged = step.requiredSupplierChanged && isSupplierChanged;

//     step.documents = step.documents.filter((document: any) => {
//       return document.productsId.find((productId: string) => {
//         const isSameProduct = products.find((product) => product.productName === productId);

//         return isSameProduct && checkSupplierChanged
//           ? document.utilityType?.find((utilityType: string) => checkSupplierChanged[utilityType])
//           : isSameProduct;
//       });
//     });

//     return step.documents && step.documents.length;
//   });
// }

// export function displayIdCardNumber(idCardNumber?: string) {
//   if (!idCardNumber) return '';
//   return `${idCardNumber.slice(0, 2)} ${idCardNumber.slice(2)}`;
// }

// type BuildAddressLineOptions = {
//   showCounty?: boolean;
//   showLocality?: boolean;
//   showPostal?: boolean;
// };

// export function BuildAddressLine(address = {} as Address | FormAddress, options?: BuildAddressLineOptions) {
//   options = options || {};
//   address = address || {};

//   const state = store.getState();

//   const { labels } = state.options;

//   const formatted = [];

//   if (labels && labels.address) {
//     const { countyName, locality, street: streetPlaceholder, streetNumber, building, floor, apartment, postalCode } = labels.address;

//     if (GetStreetName(address, streetPlaceholder)) formatted.push(GetStreetName(address, streetPlaceholder));
//     if (address.streetNumber) formatted.push(streetNumber.replace('{STREET_NUMBER}', address.streetNumber));
//     if (address.building) formatted.push(building.replace('{BUILDING}', address.building));
//     if (address.floor) formatted.push(floor.replace('{FLOOR}', address.floor));
//     if (address.apartment) formatted.push(apartment.replace('{APARTMENT}', address.apartment));
//     if (options.showLocality && GetLocalityName(address)) formatted.push(locality.replace('{LOCALITY_NAME}', GetLocalityName(address)));
//     if (options.showCounty && GetCountyName(address)) formatted.push(countyName.replace('{COUNTY_NAME}', GetCountyName(address)));
//     if (options.showPostal && GetPostalCode(address)) formatted.push(postalCode.replace('{POSTAL_CODE}', GetPostalCode(address)));
//   } else {
//     if (GetStreetName(address)) formatted.push(GetStreetName(address));
//     if (address.streetNumber) formatted.push(address.streetNumber);
//     if (address.building) formatted.push(address.building);
//     if (address.floor) formatted.push(address.floor);
//     if (address.apartment) formatted.push(address.apartment);
//     if (options.showLocality) formatted.push(GetLocalityName(address));
//     if (options.showCounty) formatted.push(GetCountyName(address));
//     if (options.showPostal) formatted.push(GetPostalCode(address));
//   }

//   const delimiter = ToString(labels && labels.address && labels.address.delimiter);

//   return formatted.filter(Boolean).join(`${delimiter} `).trim();
// }

// export function formatCurrencyValue(value: string | number) {
//   const state = store.getState();
//   const { labels } = state.options;

//   if (!labels) return '';

//   const currencyStringValue = ToString(value, labels.defaultCurrencyValue);
//   const currencyNumberValue = currency(currencyStringValue, { precision: 5 }).value; // convert value to number
//   const numberFormat = new Intl.NumberFormat(CURRENCY_LOCALE, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
//   const formattedValue = numberFormat.format(currencyNumberValue);

//   return ToString(labels.currency).replace('{VALUE}', formattedValue);
// }

// export function formatNumber(value?: number | null) {
//   if (typeof value === 'undefined' || value === null) return '';

//   const numberFormat = new Intl.NumberFormat(CURRENCY_LOCALE);
//   return ToString(numberFormat.format(value));
// }

// export function formatNumberWithDecimals(value?: number | null, minimumFractionDigits: number = 2, maximumFractionDigits: number = 5) {
//   if (typeof value === 'undefined' || value === null) return '';
//   if (ToFloat(value) === 0.0) return '0';

//   const numberFormat = new Intl.NumberFormat(CURRENCY_LOCALE, { minimumFractionDigits, maximumFractionDigits });
//   return ToString(numberFormat.format(value));
// }

// export function iOSPlatform() {
//   const parser = new UAParser();
//   const os = parser.getOS();

//   return os?.name === 'iOS';
// }

// export function AndroidPlatform() {
//   const parser = new UAParser();
//   const os = parser.getOS();

//   return os?.name === 'Android';
// }

// export function HuaweiPlatform() {
//   const parser = new UAParser();
//   const device = parser.getDevice();

//   return device?.vendor === 'Huawei';
// }

// export function StepIsComplete() {
//   const state = store.getState();

//   if (ToNumber(state.flowSteps.doneStep) < ToNumber(state.flowSteps.flowStep)) {
//     return store.dispatch(setFlowStep({ doneStep: state.flowSteps.flowStep }));
//   }

//   return undefined;
// }

// export function StepIsIncomplete() {
//   const state = store.getState();

//   const flowStep = ToNumber(state.flowSteps.flowStep);
//   // return store.dispatch(setFlowStep({ doneStep: flowStep > 0 ? flowStep - 1 : 0 }));
//   return store.dispatch(setFlowStep({ doneStep: flowStep >= 0 ? flowStep - 1 : -1 }));
// }

// export function GetServExpressProductName(contracts?: ContractType[]) {
//   if (!contracts || !contracts.length) return undefined;

//   return contracts
//     .map((contract) => {
//       if (!contract) return undefined;

//       return isServExpressProductName(contract.productName) ? contract.productName : undefined;
//     })
//     .find(Boolean);
// }

// export function getPortfolioGroupByProductName(productName: string, isLiberalization: boolean = false) {
//   if (!productName) return undefined;
//   if (isLiberalization) return productName;
//   return isServExpressProductName(productName) ? ProductPortfolioGroup.NON_COMMODITY : ProductPortfolioGroup.COMMODITY;
// }

// export function GetLiberalizationProductName(contracts?: ContractType[]) {
//   if (!contracts || !contracts.length) return undefined;

//   return contracts
//     .map((contract) => {
//       if (!contract) return undefined;

//       return contract.status === ContractStatus.LIBERALIZATION ? contract.productName : undefined;
//     })
//     .find(Boolean);
// }

// export function formatInputLabel({ label = '', mandatory = false }) {
//   const newLabel = ToString(label);

//   if (/\*$/.test(newLabel)) {
//     if (mandatory) return newLabel;

//     return newLabel.split('*')[0];
//   }

//   return newLabel.concat(ToString(mandatory && '*'));
// }

// export function GetMockAddress(address?: Address | null) {
//   if (address && !IsObjectEmpty(address)) return address;

//   return ({
//     apartment: null,
//     building: null,
//     countyCode: null,
//     streetCode: null,
//     streetName: null,
//     streetNumber: null
//   } as unknown) as Address;
// }

// export function removeLastStringPart(url = '', separator = '/') {
//   const removeLastPart = ToString(url).split(separator);

//   removeLastPart.pop();

//   return removeLastPart.join(separator);
// }

// export function getPhoneNumberFromString(data = '') {
//   const startsWithPlus = ToString(data).startsWith('+');
//   const groups = ToString(data).match(DECIMAL_REGEXP);

//   if (!groups) return '';
//   return startsWithPlus ? `+${groups.join('')}` : groups.join('');
// }

// export function replaceTitle(placeholder: string | RegExp = '', value = '') {
//   return (document.title = ToString(document.title).replace(placeholder, value));
// }

// export async function goToPage(redirectPage = '') {
//   const page = ToString(redirectPage);
//   const state = store.getState() || {};

//   const redirectTo = state && state.auth && state.auth.redirectTo;

//   if (redirectTo) {
//     await setImmediatelyToStore(setAuthData({ redirectTo: undefined }));
//     if (redirectTo.indexOf(QA_MYLINE_PAGE) !== -1 || redirectTo.indexOf(PROD_MYLINE_PAGE) !== -1) {
//       return goToMylineLink(redirectTo);
//     }
//     return goToInternalLink(redirectTo);
//   } else if (page.indexOf('/legacy') !== -1 || page.indexOf('/fast/redirect') !== -1) {
//     return goToMylineLink(page.replace('/legacy', ''));
//   }
//   return goToInternalLink(page);
// }

// export type FormatUserName = {
//   firstName?: string;
//   lastName?: string;
// };

// export function formatUserName(user: FormatUserName = {}) {
//   return CapitalizeEachWord(`${ToString(user.firstName)} ${ToString(user.lastName)}`).trim();
// }

export function setImmediatelyToStore<T = AnyAction>(action?: Action<T> | Array<Action<T>>) {
  if (action) {
    if (Array.isArray(action)) action.map(store.dispatch);
    else store.dispatch(action);
  }

  return persistor.flush();
}

// export function groupInputs<T = any>(inputs: T[], options?: InputFieldsOptions) {
//   options = options || { index: -1, row: 0, columns: 2 };

//   let index = typeof options.index === 'number' ? options.index : -1;
//   let row = typeof options.row === 'number' ? options.row : 0;
//   const columns = typeof options.columns === 'number' ? options.columns : 2;

//   return groupBy(inputs, () => {
//     if (!options) return undefined;

//     index++;

//     if (!(index % columns)) row++;

//     return row;
//   });
// }

export function isMaintenanceDebugEnabled() {
  const state = store.getState() || {};

  return state.options && state.options.maintenanceMode && state.options.maintenanceMode.debugEnabled;
}

export function isMaintenanceMode() {
  const state = store.getState() || {};

  return (
    state.options &&
    state.options.maintenanceMode &&
    state.options.maintenanceMode.services &&
    state.options.maintenanceMode.services.msrvDisabled
  );
}

export function isFacebookDisabled() {
  const state = store.getState() || {};

  return (
    state.options &&
    state.options.maintenanceMode &&
    state.options.maintenanceMode.services &&
    state.options.maintenanceMode.services.fbDisabled
  );
}

export function isAppleDisabled() {
  const state = store.getState() || {};

  return (
    state.options &&
    state.options.maintenanceMode &&
    state.options.maintenanceMode.services &&
    state.options.maintenanceMode.services.appleDisabled
  );
}

// /**
//  * Check if browser is Internet Explorer
//  */
// export function isIEBrowser() {
//   const msie = navigator.userAgent.indexOf('MSIE'); // IE 10 or older
//   const trident = navigator.userAgent.indexOf('Trident/'); // IE 11

//   return msie !== -1 || trident !== -1;
// }

// /**
//  * Check if browser is Microsoft Edge
//  */
// export function isEdgeBrowser() {
//   return navigator.userAgent.indexOf('Edge/') !== -1;
// }

// /**
//  * Check URL if it's a Myline page.
//  * It looks for the Myline prefix in the URL.
//  */
// export function isMylinePage() {
//   return window.location.href.indexOf(MYLINE_URL_PREFIX) !== -1;
// }

// export function isMylineDashboardPage() {
//   return window.location.href.indexOf(MYLINE_DASHBOARD_PAGE) !== -1;
// }

// /**
//  * Check if page is Casnic or Business from url or page template
//  */
// export function isB2BPage() {
//   if (window.location.href.indexOf('/clienti-business') !== -1) return true;
//   const template = window.document && window.document.querySelector && window.document.querySelector("meta[name='template']");
//   if (template && template.getAttribute('content')) return template.getAttribute('content').indexOf('b2b') !== -1;
//   return false;
// }

// /**
//  * Creates an anchor element `<a></a>` with
//  * the base64 source and a filename with the
//  * HTML5 `download` attribute then clicks on it.
//  */
// export async function downloadFile(file: Blob | string = '', fileName = '', options: { type?: string } = {}) {
//   if (!file) throw new Error(InvalidFile);

//   try {
//     const link = document.createElement('a');
//     const blob = typeof file === 'string' ? blobFromBase64String(file, options) : file;

//     if (navigator.msSaveBlob) {
//       return navigator.msSaveBlob(blob, fileName);
//     }

//     link.href = URL.createObjectURL(blob);
//     link.classList.add('d-none');
//     link.setAttribute('download', fileName);

//     document.body.appendChild(link);

//     link.click();

//     await Timeout(100);

//     return document.body.removeChild(link);
//   } catch (error) {
//     return Promise.reject(error || {});
//   }
// }

// /**
//  * Create a Blob from a base64-encoded string
//  */
// export function blobFromBase64String(base64String: string, options: { type?: string } = {}) {
//   const byteArray = Uint8Array.from(
//     atob(base64String)
//       .split('')
//       .map((char) => char.charCodeAt(0))
//   );
//   return new Blob([byteArray], options);
// }

// /**
//  * Preventing display of orphans (single word on last line) in text
//  */
// export function textNoOrphans(text: string = '') {
//   if (text) text = text.replace(/\s((?=(([^\s<>]|<[^>]*>)+))\2)\s*$/, '&nbsp;$1');
//   return text;
// }

// /**
//  * Remove leading zeros from string
//  */
// export function removeLeadingZerosFromString(string: string = '') {
//   return ToString(string).replace(/^0+/, '');
// }

// export async function cleanUpSalesFlowStart() {
//   const actions: any[] = [
//     cleanUpFlowStep(),
//     cleanUpFormData([CONSUMPTION_ADDRESS]),
//     cleanUpLead(['isEonClient', 'product']),
//     cleanUpStepUpload(),
//     cleanUpProducts(),
//     cleanUpPartner(['selectedPartner', 'userPartners']),
//     cleanUpGdprData()
//   ];

//   return setImmediatelyToStore(actions);
// }

// export async function cleanUpSalesFlowEnded() {
//   const actions: any[] = [
//     cleanUpEstimator(),
//     cleanUpFlowStep(),
//     cleanUpFormData([AGENT_NAME]),
//     cleanUpLead(['isEonClient', 'product']),
//     cleanUpStepUpload(),
//     cleanUpProducts(),
//     cleanUpPartner(['selectedPartner', 'userPartners'])
//   ];

//   if (isGuestAuth()) {
//     actions.push(cleanUpAuth());
//     actions.push(cleanUpPartner());
//   }

//   return setImmediatelyToStore(actions);
// }

// export async function cleanSalesFlowWithLogout() {
//   return setImmediatelyToStore([
//     cleanUpAuth() as any,
//     cleanUpPartner() as any,
//     cleanUpAddressData() as any,
//     cleanUpAnalytics() as any,
//     cleanupAuthenticationBox() as any,
//     cleanUpCollectStandardLead() as any,
//     cleanUpEstimator() as any,
//     cleanUpFlowStep() as any,
//     cleanUpFormData([AGENT_NAME]) as any,
//     cleanUpLead() as any,
//     cleanUpProducts() as any,
//     cleanUpStepUpload() as any,
//     cleanUpToastr() as any,
//     cleanUpNotifications() as any,
//     cleanLifeContracting() as any,
//     cleanLifeEstimator() as any,
//     cleanUpTutorials() as any,
//     cleanUpInvoices() as any,
//     cleanUpUser() as any
//   ]);
// }

// export async function cleanUpVersionChanged() {
//   /*
//     FAST-6616: do NOT enable user clean data !
//     await cleanUpUserData();
//   */

//   return setImmediatelyToStore([
//     cleanUpAddressData() as any,
//     cleanUpAnalytics() as any,
//     cleanupAuthenticationBox() as any,
//     cleanUpCollectStandardLead() as any,
//     cleanUpEstimator() as any,
//     cleanUpFlowStep() as any,
//     cleanUpProducts() as any,
//     cleanUpStepUpload() as any,
//     cleanUpToastr() as any,
//     cleanUpNotifications() as any,
//     cleanLifeContracting() as any,
//     cleanLifeEstimator() as any,
//     cleanUpTutorials() as any,
//     cleanUpInvoices() as any
//   ]);
// }

// export async function cleanUpGuestAuth() {
//   const actions: any[] = [cleanUpAuth(), cleanUpPartner()];

//   return setImmediatelyToStore(actions);
// }

// export function isVisibleForCondition(inputConfig: AllInputs, aemFields?: any, formValues?: FormPayload) {
//   if (inputConfig.visibleForCondition !== true) return false;

//   if (aemFields && formValues) {
//     const aemInput = aemFields[inputConfig.name];

//     if (!aemInput.visibleForField || !aemInput.visibleForValue) return false;

//     if (
//       formValues[aemInput.visibleForField as keyof FormPayload] instanceof Object &&
//       formValues[aemInput.visibleForField as keyof FormPayload].id
//     )
//       return ToLowerCase(formValues[aemInput.visibleForField as keyof FormPayload].id) !== ToLowerCase(aemInput.visibleForValue);

//     return ToLowerCase(formValues[aemInput.visibleForField as keyof FormPayload]) !== ToLowerCase(aemInput.visibleForValue);
//   }

//   return true;
// }

// export function removeAccents(string?: string) {
//   return ToString(removeAccentsLib(ToString(string)));
// }

// export function encapsulateToSpan(value: any = '', className = '') {
//   return `<span class="${className}">${ToString(value)}</span>`;
// }

// /**
//  * Escape HTML entities from string
//  */
// export function escapeHTML(string?: string) {
//   if (!string) return '';

//   return string.replace(
//     /[&<>'"]/g,
//     (tag) =>
//       (({
//         '&': '&amp;',
//         '<': '&lt;',
//         '>': '&gt;',
//         "'": '&#39;',
//         '"': '&quot;'
//       } as any)[tag] || tag)
//   );
// }

export function formatPhoneNumberAddDialCode(val: any, dialCode: string) {
  return `+${dialCode}${val}`;
}
export function formatPhoneNumberRemoveDialCode(val: any, dialCode: string) {
  return ToString(val).replace(`+${dialCode}`, '');
}

// // Format value to remove all special characters from phone number
export function formatValuePhoneNumber(val: any) {
  return ToString(val).replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/\//, '').split(' ').join('');
}

export function preFormatValuePhoneNumber(val: any, dialCode?: string) {
  const startsWithPlus = val[0] === '+';

  val = val.replace(/\D/g, '');
  if (startsWithPlus) val = `+${val}`;

  let phoneNumber = formatValuePhoneNumber(val);
  if (dialCode) phoneNumber = formatPhoneNumberRemoveDialCode(phoneNumber, dialCode);

  if (phoneNumber && phoneNumber.length > 9) {
    if (phoneNumber.startsWith('004')) {
      phoneNumber = phoneNumber.replace('004', '+4');
    } else if (phoneNumber.startsWith('0')) {
      phoneNumber = phoneNumber.substring(1, phoneNumber.length);
    }
  }

  if (phoneNumber.length >= 13) phoneNumber = phoneNumber.substring(0, 13);

  return AddSpace(phoneNumber, 3);
}

// export function afterAnYear(date = moment()) {
//   return date.year(date.year() + 1);
// }

export const isTouchDevice = (): boolean => {
  return !!(
    typeof window !== 'undefined' &&
    ('ontouchstart' in window || (window.DocumentTouch && typeof document !== 'undefined' && document instanceof window.DocumentTouch))
  );
};

// export function isEonOwnerDuoFlow() {
//   const state = store.getState() || {};

//   const isOwner = state.lead.isOwner;
//   const isOwnerEE = state.lead.isOwner?.ee;
//   const isOwnerGN = state.lead.isOwner?.gn;
//   const utilityType = state.estimator.selectedProduct?.utilityType;

//   const supplierElectricity = state.form.supplierElectricity?.id;
//   const supplierGas = state.form.supplierGas?.id;

//   if (
//     (isClientAuth() || isGuestAuth()) &&
//     ((!supplierGas && supplierElectricity === 0) || (!supplierElectricity && supplierGas === 0)) &&
//     utilityType === DUAL &&
//     isOwner &&
//     isOwnerEE &&
//     isOwnerGN
//   )
//     return true;
//   return false;
// }

// export function isEonOwnerDuoFlowWithValidatedCnp() {
//   const state = store.getState() || {};

//   return state.flowSteps.eonOwnerDuoValidatedCnp;
// }
// export function isEonOwnerDuoFlowWithAccountContractSelected() {
//   const state = store.getState() || {};

//   return state.flowSteps.eonOwnerDuoSelectedAccountContract;
// }

// export function sameAddress(address1?: Address, address2?: Address) {
//   if (
//     address1 &&
//     address2 &&
//     address1.locality?.localityCode === address2.locality?.localityCode &&
//     address1.street?.streetCode === address2.street?.streetCode &&
//     address1.street?.freeTextAddress === address2.street?.freeTextAddress &&
//     address1.streetNumber === address2.streetNumber &&
//     address1.postalCode === address2.postalCode &&
//     address1.apartment === address2.apartment &&
//     address1.building === address2.building &&
//     address1.flat === address2.flat &&
//     address1.floor === address2.floor
//   )
//     return true;

//   return false;
// }

// export function findByKeyInNestedObject(obj: any, key: string, parentKey: string, list: [any]): any {
//   var result = [...list] as any;

//   if (obj instanceof Array) {
//     for (var i = 0; i < obj.length; i++) {
//       result = { ...result, ...findByKeyInNestedObject(obj[i], key, parentKey, list) };
//     }
//   } else {
//     for (var prop in obj) {
//       if (prop === key) {
//         result = { ...result, [parentKey]: obj };
//       }
//       if (obj[prop] instanceof Object || obj[prop] instanceof Array) {
//         result = { ...result, ...findByKeyInNestedObject(obj[prop], key, prop, list) };
//       }
//     }
//   }
//   return result;
// }
