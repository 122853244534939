import React from 'react';

import { ToString } from '@lib/Utils';
import { HTMLInputTypes } from '@type/input';
import { defaultEvent } from '@constants/Event';
import { INVALID } from '@constants/Form';

export type CheckBoxProps = HTMLInputTypes & {
  labelText?: string;
  title?: string;
  subtitle?: string;
  subtitleElement?: JSX.Element;
  containerClassName?: string;
  error?: string;
  colorClassName?: string;
};

export type CheckBoxState = {};

const CheckBox = (props: CheckBoxProps) => {

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event = event || defaultEvent;

    return props.onChange && props.onChange(event);
  };

  const {
    labelText,
    title,
    subtitle,
    subtitleElement,
    className,
    containerClassName,
    colorClassName,
    checked,
    error,
    required,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <div className={`checkbox ${ToString(containerClassName)} ${error ? INVALID : ''}`} id={rest.name}>
        <label className={`bg-eon-red ${ToString(colorClassName)} ${checked ? 'checked' : ''}`}>
          {labelText}
          <input
            {...rest}
            onChange={_onChange}
            type="checkbox"
            checked={checked}
            className={`eon-checkbox ${ToString(className)}`}
          />
        </label>
        {(title || subtitle || subtitleElement) && (
          <div>
            {title && <b dangerouslySetInnerHTML={{ __html: title }} />}
            {subtitle}
            {/* {subtitle && ( <i dangerouslySetInnerHTML={{ __html: subtitle }} />)} */}
            {subtitleElement}
          </div>
        )}
      </div>
      {error && <div className="label-error">{error}</div>}
    </React.Fragment>
  );
};


export default CheckBox;
