import { store } from './store';

export function isClientAuth() {
  const state = store.getState();

  return !!(state.auth?.email);
}

export function isGuestAuth() {
  const state = store.getState();

  return !!(state.auth?.token && state.auth?.expireAt && !state.auth.mylineAccountExist);
}

export function isSalesAuth() {
  return isClientAuth() || isGuestAuth();
}

export function isExpiredToken() {
  const state = store.getState();

  return state.auth.expireAt && state.auth.expireAt < Date.now();
}
