import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import Details from '@components/rss/requests/Details';

export const RSSEmailLinkDetailsFurnizor = () => {
    return (
        <DelgazWrapper>
            <Details />
        </DelgazWrapper>
    );
};