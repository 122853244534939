import { toggleLoader } from '@actions/LoaderActions';
import { getSubjectList } from '@api/rss/rss';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const DatePetitie = (props: { category: any, handleCategory: any, subject: any, handleSubject: any, subSubject: any, handleSubSubject: any, pod?: string | null }) => {
    const [categories, setCategories] = useState<any[]>([]);
    const [subjects, setSubjects] = useState<any[]>([]);
    const [subSubjects, setSubSubjects] = useState<any[]>([]);
    // const [faqs, setFaqs] = useState<any[]>([]);

    const dispatch = useDispatch();

    const { category, handleCategory, subject, handleSubject, subSubject, handleSubSubject, pod } = props;

    useEffect(() => {
        mountValues();
    }, []);

    const getValues = (data: any) => {
        let arr: any[] = [];

        data.map((item: any) => {
            Object.keys(item).map((k) => {
                if (k === 'category') {
                    let already = false;

                    for (let i = 0; i < arr.length; ++i) {
                        if (arr[i].category === item[k]) {
                            already = true;
                        }
                    }

                    if (!already) {
                        arr.push({ category: item[k], categorySapValue: item.categorySapValue, subjects: [] });
                    }
                }
            });
        });

        arr.map((item: any) => {
            data.map((i: any) => {
                Object.keys(i).map((k) => {
                    if (k === 'subject') {
                        let already = false;

                        for (let j = 0; j <= item.subjects.length; ++j) {
                            if (item.subjects[j] && item.subjects[j].subject && item.subjects[j].subject === i[k]) {
                                already = true;
                            }
                        }

                        if (!already && (item.category === i['category'])) {
                            item.subjects.push({ subject: i[k], subjectSapValue: i['subjectSapValue'], subSubjects: [] });
                        }
                    }
                });
            });
        });

        arr.map((item: any) => {
            item.subjects.map((subject: any) => {
                data.map((i: any) => {
                    Object.keys(i).map((k) => {
                        if (k === 'subSubject') {
                            let already = false;

                            for (let j = 0; j <= subject.subSubjects.length; ++j) {
                                if (subject.subSubjects[j] && subject.subSubjects[j].subSubject && subject.subSubjects[j].subSubject === i[k]) {
                                    already = true;
                                }
                            }

                            if (!already && (subject.subject === i['subject']) && (item.category === i['category'])) {
                                subject.subSubjects.push({ subSubject: i[k], subSubjectSapValue: i['subSubjectSapValue'], faqs: [] });
                            }
                        }
                    });
                });
            });
        });

        //Filter categories by utility 
        if (pod) {
            if (pod[0] === 'E') {
                arr.map((itm: any, index: number) => {
                    if (itm.categorySapValue === 'GN') {
                        arr.splice(index, 1);
                    }
                });
            } else if (pod[0] === 'D') {
                arr.map((itm: any, index: number) => {
                    if (itm.categorySapValue === 'EE') {
                        arr.splice(index, 1);
                    }
                });
            }
        }

        arr.map((item: any) => {
            item.subjects.map((subject: any) => {
                subject.subSubjects.map((subSubject: any) => {
                    data.map((i: any) => {
                        Object.keys(i).map((k) => {
                            if (k === 'subSubject' && i[k] === subSubject.subSubject && i['frequentQuestions'].length > 0) {
                                subSubject.faqs.push(i['frequentQuestions']);
                            }
                        });
                    });
                });
            });
        });

        setCategories(arr);
    };

    const mountValues = async () => {
        dispatch(toggleLoader(true));

        await getSubjectList().then((res) => {
            getValues(res.data);
        });

        dispatch(toggleLoader(false));
    };

    const mountSubjects = () => {
        let subjects = [];

        subjects = categories.filter((i: any) => i.categorySapValue === category).map((c: any) => (
            c.subjects.map((item: any) => ({
                id: item.subjectSapValue,
                name: item.subject
            }))
        ));

        return subjects;
    };

    const mountSubSubjects = () => {
        let subSubjects: any[] = [];

        subSubjects = categories.filter((i: any) => i.categorySapValue === category);
        subSubjects = subSubjects.map((c: any) => c.subjects.filter((s: any) => s.subjectSapValue === subject).map((subj: any) => subj.subSubjects.map((subSubj: any) => ({
            id: subSubj.subSubjectSapValue,
            name: subSubj.subSubject
        }))));

        return subSubjects.flat(2);
    };

    useEffect(() => {
        setSubjects(mountSubjects);
        setSubSubjects([]);
    }, [category]);

    useEffect(() => {
        setSubSubjects(mountSubSubjects);
    }, [subject]);

    return (
        <div>
            <Row className="mt-4">
                <Col xs={12}>
                    <Dropdown
                        label="Categorie subiect*"
                        defaultPlaceholder="Alege o opțiune"
                        options={categories.map((item: any) => ({
                            id: item.categorySapValue,
                            name: item.category
                        })) as DropdownOptionType[]}
                        onChange={(value) => {
                            handleCategory(value);
                        }}
                        value={category}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <Dropdown
                        label="Subiect*"
                        defaultPlaceholder="Alege subiectul despre care vrei să afli mai multe"
                        options={subjects[0] as DropdownOptionType[]}
                        onChange={(value) => handleSubject(value)}
                        value={subject}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <Dropdown
                        label="Motivul interacțiunii (subiectul detaliat)*"
                        defaultPlaceholder="Alege motivul pentru care dorești să ne contactezi"
                        options={subSubjects as DropdownOptionType[]}
                        onChange={(value) => handleSubSubject(value)}
                        value={subSubject}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default DatePetitie;