import { CustomPost } from '@api/backOffice/fetchResults';
import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';
import { faCircleInfo, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Select } from '@components/common/Select';
import { saveEventDocumentsEE, saveEventDocumentsGN } from '@api/timeline';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import * as notification from '@lib/Notification';
import { UploadFile } from '@components/common/UploadFile';
import { ICONNECT_UPLOADED_FILES_LIMIT } from '@constants/Upload';

type UploadDocumentsTimelineProps = {
  isBo?: boolean;
  utility: number;
  boBasePath: string | any;
  documents: Document[];
  idEvent: string | number;
  requestId: string | any;
  reloadTimeline: () => void;
  documentTypes: DocumentType[];
  isLoading: boolean;
  toggleLoading: (val: any) => void;
};

type Document = {
  id: number;
  idEvent: number;
  docTypeID: number;
  docTypeName: string;
  dms_Code: string;
  docLink: string;
  fileName: string;
  fileType: string;
};

type DocumentType = {
  documentTypeId: number;
  requestEventTypeId: number;
  name: string;
  description: string;
  code: string;
  required: boolean;
  setOtherDocumentTypesAsOptionalOnValue: boolean;
  show: boolean; //
};

type File = {
  [key: string]: any;
  fileUrl?: string | any;
  containerName?: string | any;
};

const UploadDocumentsTimeline = (props: UploadDocumentsTimelineProps) => {
  const [files, setFiles] = useState<any[]>([]);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>(props.documentTypes);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [requiredDocTypesDisplayed, setRequiredDocTypesDisplayed] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [otherDocumentTypesAsOptionalOnValue, setOtherDocumentTypesAsOptionalOnValue] = useState<any>(false);

  const [error, setError] = useState<string | any>('');

  const dispatch = useDispatch();

  useEffect(() => {
    props.toggleLoading(false);
  }, [files]);

  useEffect(() => {
    if (otherDocumentTypesAsOptionalOnValue && documentTypes && documentTypes.length && files && files.length) {
      handleSetOtherDocumentTypesAsOptionalOnValue();
    }
  }, [files, otherDocumentTypesAsOptionalOnValue, props.documents]);

  useEffect(() => {
    if (documentTypes && documentTypes.length && !requiredDocTypesDisplayed) {
      initializeEmptyFileArraysForDocTypes(documentTypes);

      let otherDocumentTypesAsOptionalOnValue = false;
      let oneOfTheRequiredDocTypesIsUploaded = false;

      documentTypes.map((documentType: DocumentType, index: number) => {
        if (documentType.setOtherDocumentTypesAsOptionalOnValue) {
          otherDocumentTypesAsOptionalOnValue = true;

          if (props.documents && props.documents.length) {
            let isUploaded = props.documents.find((document: Document) => {
              return document.docTypeID == documentType.documentTypeId;
            });
            if (isUploaded) {
              oneOfTheRequiredDocTypesIsUploaded = true;
            }
          }
        }
      });
      setOtherDocumentTypesAsOptionalOnValue(otherDocumentTypesAsOptionalOnValue);

      const newDocumentTypes = documentTypes.map((documentType: DocumentType, index: number) => {
        if (documentType.required) {
          let alreadyUploaded: boolean | any = false;

          //if doc is already uploaded set as optional
          if (props.documents && props.documents.length) {
            alreadyUploaded = props.documents.find((document: Document) => {
              return document.docTypeID == documentType.documentTypeId;
            });
          }

          if (!alreadyUploaded) {
            if (documentType.setOtherDocumentTypesAsOptionalOnValue && oneOfTheRequiredDocTypesIsUploaded) {
              documentType.show = false;
            } else documentType.show = true;
          } else {
            documentType.required = false;
            documentType.show = false;
          }
        }
        return documentType;
      });

      setDocumentTypes(newDocumentTypes);
      setRequiredDocTypesDisplayed(true);
    }
  }, [documentTypes, requiredDocTypesDisplayed]);

  useEffect(() => {
    submited && validateValues();
  }, [files, submited]);

  const uploadFile = (fileArr: File[], index: number) => {
    let newData = files;
    newData.map((item: any, idx: number) => {
      if (idx === index) {
        newData[idx] = [...fileArr];
      }
    });
    setFiles([...newData]);
  };

  const handleSetOtherDocumentTypesAsOptionalOnValue = () => {
    //one of the doc types with setOtherDocumentTypesAsOptionalOnValue on true is uploaded
    let oneOfTheRequiredDocTypesIsUploaded = false;

    documentTypes.forEach((documentType: DocumentType, index: number) => {
      if (documentType.setOtherDocumentTypesAsOptionalOnValue) {
        files.map((item: any, idx: number) => {
          if (idx === index && files[idx] && files[idx].length) {
            oneOfTheRequiredDocTypesIsUploaded = true;
          }
        });

        if (props.documents && props.documents.length) {
          let isUploaded = props.documents.find((document: Document) => {
            return document.docTypeID == documentType.documentTypeId;
          });
          if (isUploaded) {
            oneOfTheRequiredDocTypesIsUploaded = true;
          }
        }
      }
    });

    setDocumentTypes([
      ...documentTypes.map((documentType: DocumentType) => {
        let required = documentType.required;
        if (documentType.setOtherDocumentTypesAsOptionalOnValue) {
          if (oneOfTheRequiredDocTypesIsUploaded) {
            required = false;
          } else {
            required = true;
          }
        }

        return {
          ...documentType,
          required: required
        };
      })
    ]);
  };

  const handleShowExtraFiles = (docType: string) => {
    let newArray: any = [];
    let newItem: any = null;
    let index: any = null;

    documentTypes.forEach((doc: DocumentType, i: number) => {
      let item = doc;
      if (item.name === docType) {
        newItem = item;
        index = i;
      }
      newArray.push(item);
    });

    newArray.splice(index, 1);
    newItem.show = true;
    newArray.push(newItem);

    const newFiles = [...files];
    newFiles.splice(index, 1);
    newFiles.push([]);

    setDocumentTypes(newArray);
    setFiles(newFiles);
  };

  const initializeEmptyFileArraysForDocTypes = (docTypes: DocumentType[]) => {
    let emptyFileArrays: any = [];
    docTypes.map(() => {
      emptyFileArrays.push([]);
    });
    setFiles(emptyFileArrays);
  };

  const validateValues = () => {
    let valid = true;

    files.forEach((docUploadedFiles: any[], index: number) => {
      let docType = documentTypes[index];
      if (docType.required && !docUploadedFiles.length) {
        valid = false;
      }
    });

    if (!valid) {
      setError('Câmpurile marcate cu * sunt obligatorii.');
    } else setError('');

    return valid;
  };

  const resetFields = () => {
    setSubmited(false);
    setIsAdding(false);
    setDocumentTypes(
      documentTypes.map((docType) => {
        return { ...docType, show: false, required: false };
      })
    );
    initializeEmptyFileArraysForDocTypes(documentTypes);
  };

  const submit = async () => {
    setSubmited(true);
    if (!validateValues()) return;

    let eventDocuments: any[] = [];

    files.forEach((docUploadedFiles: any[], index: number) => {
      if (docUploadedFiles && docUploadedFiles.length) {
        let docType = documentTypes[index];

        let ed: any = {
          eventDocumentTypeId: docType.documentTypeId,
          documents: []
        };

        docUploadedFiles.forEach((file) => {
          if (file.base64) {
            ed.documents.push({
              name: file.name,
              content: file.base64 ? file.base64.slice(file.base64.lastIndexOf('base64,') + 7) : ''
            });
          }
        });

        if (ed.documents.length) {
          eventDocuments.push(ed);
        }
      }
    });

    await saveDocuments({
      requestEventId: props.idEvent,
      eventDocuments: eventDocuments
    });

    resetFields();
    props.reloadTimeline();
  };

  const saveDocuments = async (payload: any) => {
    dispatch(toggleLoader(true));

    let succes = false;

    if (props.isBo) {
      await CustomPost(`${props.boBasePath}/save_event_document_list`, payload)
        .then((res) => {
          succes = true;
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            succes = true;
          }
          dispatch(toggleLoader(false));
        });
    } else {
      if (props.utility == UTILITY_EE) {
        await saveEventDocumentsEE(payload)
          .then((res) => {
            succes = true;
            dispatch(toggleLoader(false));
          })
          .catch((err) => {
            if (err.status == 200) {
              succes = true;
            }
            dispatch(toggleLoader(false));
          });
      } else if (props.utility == UTILITY_GN) {
        await saveEventDocumentsGN(payload)
          .then((res) => {
            succes = true;
            dispatch(toggleLoader(false));
          })
          .catch((err) => {
            if (err.status == 200) {
              succes = true;
            }
            dispatch(toggleLoader(false));
          });
      }
    }

    if (succes) {
      notification.success('Fișierele au fost încărcate cu succes!', '', { closeDuration: 2000 });
    } else {
      notification.error('A apăruut o eroare la încărcarea fișierelor!', '', { closeDuration: 2000 });
    }
  };

  const calculateUploadedFilesLimit = (documentType: DocumentType) => {
    const uploadedFilesPerDocumentLength = props.documents.filter((d) => d.docTypeID == documentType.documentTypeId).length;

    if (uploadedFilesPerDocumentLength >= ICONNECT_UPLOADED_FILES_LIMIT) {
      return 0;
    } else {
      return ICONNECT_UPLOADED_FILES_LIMIT - uploadedFilesPerDocumentLength;
    }
  };

  const generateCustomFilesLimitErrorMessage = (documentType: DocumentType) => {
    let uploadedFilesPerDocumentLength = calculateUploadedFilesLimit(documentType);

    if (uploadedFilesPerDocumentLength == 1) {
      return `Pentru tipul de document ${documentType.name} se mai poate încărca un singur fișier.`;
    } else if (uploadedFilesPerDocumentLength == ICONNECT_UPLOADED_FILES_LIMIT) {
      return `Pentru tipul de document ${documentType.name} se pot încărca maxim ${uploadedFilesPerDocumentLength} fișiere.`;
    } else {
      return `Pentru tipul de document ${documentType.name} se mai pot încărca maxim ${uploadedFilesPerDocumentLength} fișiere.`;
    }
  };

  return (
    <Fragment>
      <div className="d-flex align-self-center flex-column mt-3 prosumatori-documente">
        {!!(documentTypes && files.length && documentTypes.length == 1) && (
          <>
            <h5 className="m-0 mt-3 mb-2">{documentTypes[0].description || documentTypes[0].name}</h5>
            {props.isBo ? (
              <UploadFile
                disabled={true}
                index={0}
                onClick={() => {
                  notification.warning('Acțiunea este accesibilă doar clienților!');
                  return;
                }}
                onSetFileList={uploadFile}
                fileList={files[0]}
                acceptedExtensions={['.bmp', '.gif', '.jpg', '.jpeg', '.tiff', '.tif', '.png', '.doc', '.docx', '.pdf']}
                hideDownloadButton
              />
            ) : (
              <>
                {calculateUploadedFilesLimit(documentTypes[0]) > 0 ? (
                  <UploadFile
                    index={0}
                    onSetFileList={uploadFile}
                    fileList={files[0]}
                    hideDownloadButton
                    acceptedExtensions={['.bmp', '.gif', '.jpg', '.jpeg', '.tiff', '.tif', '.png', '.doc', '.docx', '.pdf']}
                    multiselect
                    uploadedFilesLimit={calculateUploadedFilesLimit(documentTypes[0])}
                    customFilesLimitErrorMessage={generateCustomFilesLimitErrorMessage(documentTypes[0])}
                    isLoading={props.isLoading}
                    toggleLoading={props.toggleLoading}
                  />
                ) : (
                  <UploadFile
                    disabled={true}
                    index={0}
                    onClick={() => {
                      notification.warning(`Pentru tipul de document ${documentTypes[0].name} nu se mai pot încărca fișiere.`);
                      return;
                    }}
                    onSetFileList={uploadFile}
                    fileList={files[0]}
                    hideDownloadButton
                  />
                )}
              </>
            )}
          </>
        )}

        {!!(!props.isBo && documentTypes && files.length && documentTypes.length > 1) &&
          documentTypes.map((doc: DocumentType, index: number) => {
            if (doc.show) {
              return (
                <Row className="mt-1 mb-1 document-row" key={index}>
                  <Col xs={12} md={4} className="align-items-center d-flex text-col">
                    <h5 className="m-0">
                      {doc.name}
                      {doc.required ? ' *' : ''}
                    </h5>
                    {doc.description && (
                      <Tippy
                        content={
                          <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                            {doc.description}
                          </div>
                        }
                        duration={0}
                        placement="bottom"
                      >
                        <div className="icon-div">
                          <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                        </div>
                      </Tippy>
                    )}
                  </Col>
                  <Col xs={12} md={8}>
                    {calculateUploadedFilesLimit(doc) > 0 ? (
                      <UploadFile
                        index={index}
                        onSetFileList={uploadFile}
                        fileList={files[index]}
                        hideDownloadButton
                        acceptedExtensions={['.bmp', '.gif', '.jpg', '.jpeg', '.tiff', '.tif', '.png', '.doc', '.docx', '.pdf']}
                        multiselect
                        uploadedFilesLimit={calculateUploadedFilesLimit(doc)}
                        customFilesLimitErrorMessage={generateCustomFilesLimitErrorMessage(doc)}
                        isLoading={props.isLoading}
                        toggleLoading={props.toggleLoading}
                      />
                    ) : (
                      <UploadFile
                        disabled={true}
                        index={0}
                        onClick={() => {
                          notification.warning(`Pentru tipul de document ${doc.name} nu se mai pot încărca fișiere.`);
                          return;
                        }}
                        onSetFileList={uploadFile}
                        fileList={files[0]}
                        hideDownloadButton
                      />
                    )}
                  </Col>
                </Row>
              );
            }
          })}

        <p className="m-0 mt-2">
          Se acceptă documente în format BMP, GIF, JPG, JPEG, TIFF, TIF, PNG și fișiere DOC, DOCX, PDF. Documentele nu trebuie să depășească
          limita maximă de 10 MB/fișier.
        </p>

        {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

        <div className="d-flex flex-row justify-content-between mt-2 align-items-center flex-wrap" style={{ minHeight: '60px' }}>
          {!isAdding && documentTypes && documentTypes.length > 1 && (
            <Button
              className="btn-wide mb-2 me-2"
              onClick={() => {
                if (props.isBo) {
                  notification.warning('Acțiunea este accesibilă doar clienților!');
                  return;
                }
                setIsAdding(true);
              }}
            >
              Adaugă document
            </Button>
          )}

          {isAdding && (
            <div className="d-flex mb-2 me-2">
              <Col xs={12} className="d-flex flex-row" style={{ maxWidth: '18rem' }}>
                <Select
                  placeholder={'Alege tipul de document'}
                  onChange={(e) => {
                    handleShowExtraFiles(e.target.value);
                  }}
                  options={documentTypes
                    .filter((doc: DocumentType) => !doc.show)
                    .map((doc: DocumentType, index: number) => ({
                      id: doc.name,
                      name: doc.name
                    }))}
                  value={''}
                  mbZero={true}
                />
                <div className="d-flex align-items-center justify-content-center">
                  <span className="d-flex align-items-center justify-content-center">
                    <Button onClick={() => setIsAdding(false)}>
                      <FontAwesomeIcon icon={faX} />
                    </Button>
                  </span>
                </div>
              </Col>
            </div>
          )}

          <Button className="btn-wide mb-2" onClick={submit} disabled={!files.find((i) => i[0])}>
            Trimite
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default UploadDocumentsTimeline;
