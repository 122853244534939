import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import HeadlineRacordare from '@components/dev/Components/HeadlineRacordare/HeadlineRacordare';

export const Headline = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <HeadlineRacordare />
        </DelgazWrapper>
    );
};