import { toggleLoader } from '@actions/LoaderActions';
import { postStepFive } from '@api/prosumatori/prosumatori';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import DynamicTable from '@components/prosumatori/components/table/DynamicTable';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const LPNotificare2 = (props: {
    isEditing: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    dateTehniceLP: any,
    handleDateTehniceLP: any,
    generatoare: number | null,
    handleGeneratoare: any,
    tabelDateGen: any,
    dateGen: any,
    handleDateGen: any,
    mijloaceComp: number | null,
    handleMijloaceComp: any,
    tabelMC: any,
    handleDateMC: any,
    fotovoltaic: number | null,
    handleFotovoltaic: any,
    tabelDateFoto: any,
    handleDateFoto: any,
    invertoare: number | null,
    handleInvertoare: any,
    tabelDateInv: any,
    handleDateInv: any,
    psi: any,
    handlePSI: any,
    pmsa: any,
    handlePMSA: any,
    surseRegen: number | null,
    handleSurseRegen: any,
    detaliiSchema: any,
    handleDetaliiSchema: any,
    capacitate: any,
    handleCapacitate: any,
    masurare: number | null,
    handleMasurare: any,
    tabelDateEM: any,
    handleDateEM: any,
    dateMC: any,
    dateFoto: any,
    dateInv: any,
    dateEM: any,
}) => {
    const {
        isEditing,
        requestId,
        setStep,
        currentStep,
        progress,
        setProgress,
        // dateTehniceLP,
        // handleDateTehniceLP,
        // generatoare,
        // handleGeneratoare,
        // tabelDateGen,
        // dateGen,
        // handleDateGen,
        // mijloaceComp,
        // handleMijloaceComp,
        // tabelMC,
        // handleDateMC,
        // fotovoltaic,
        // handleFotovoltaic,
        // tabelDateFoto,
        // handleDateFoto,
        // invertoare,
        // handleInvertoare,
        // tabelDateInv,
        // handleDateInv,
        // psi,
        // handlePSI,
        // pmsa,
        // handlePMSA,
        surseRegen,
        handleSurseRegen,
        detaliiSchema,
        handleDetaliiSchema,
        capacitate,
        handleCapacitate,
        masurare,
        handleMasurare,
        tabelDateEM,
        handleDateEM,
        // dateMC,
        // dateFoto,
        // dateInv,
        dateEM,
    } = props;
    const [validationError, setValidationError] = useState('');
    const [SRError, setSRError] = useState('');
    const [EMError, setEMError] = useState('');
    const [error, setError] = useState('');
    const [numbersError, setNumbersError] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const constructPayload = () => {
        return {
            requestId: requestId,
            date_tehnice_old: {
                KW: null,
                KVA: null
            },
            date_tehnice_new: {
                KW: null,
                KVA: null
            },
            exista_generatoare: false,
            generatoare: [],
            exista_mijl_comp: false,
            mijl_comp: [],
            exista_fotovoltaice: false,
            fotovoltaice: [],
            exista_invertoare: false,
            invertoare: [],
            servicii_interne: {
                psi: null,
                pmsa: null,
            },
            exista_surse_regen: surseRegen === 1,
            exista_echipamente_masurare: masurare === 1,
            ...(surseRegen === 1 ?
                {surse_regen: {detalii_schema_alimentare: detaliiSchema !== '' ? detaliiSchema : null, capacitate_baterii: capacitate !== '' ? capacitate : null}} :
                {surse_regen: {detalii_schema_alimentare: null, capacitate_baterii: null}}),
            ...(masurare === 1 ? {echipamente: dateEM} : {echipamente: []})
        };
    };

    const stepFiveApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepFive(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                } else if (error && error.StatusCode === 500) {
                    setError('A apărut o problemă tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setValidationError('');
            setSRError('');
            setEMError('');
            setError('');
            setNumbersError('');
            let reg = document.getElementById('reg');
            reg?.classList.remove('red');
            let em = document.getElementById('em');
            em?.classList.remove('red');
            let wasValidated = true;

            if (masurare === 1 && !validateTable(dateEM, null)) {
                setEMError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('em');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }
            if ((surseRegen === 1 && detaliiSchema === '') || (surseRegen === 1 && capacitate === '')) {
                setSRError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('reg');
                err?.classList.add('red');
                wasValidated = false;
            }
            if (surseRegen === 1 && isNaN(Number(capacitate.replace(',','.')))) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('reg');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (wasValidated) {
                if (surseRegen === null || masurare === null) {
                    setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                } else {
                    stepFiveApiCall(constructPayload());
                }
            }
        } else {
            setStep(currentStep+1);
            currentStep === progress && setProgress(currentStep+1);
        }
    };

    const validateTable = (table: any, indexException: number | null) => {
        let validated = true;
        table.map((row: any, index: number) => {
            Object.keys(row).map((column: any, idx: number) => {
                if (idx !== indexException) {
                    if (row[column] === '' || row[column] === null) {
                        validated = false;
                    }
                }
            });
        });

        return validated;
    };

    const handlePrevStep = () => {
        setStep(currentStep-1);
    };

    return (
        <div className="prosumatori-informatii-tehnice-lc m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3 mb-4">Date tehnice și energetice pentru locul de producere</h4>

            <ChoiceComponent
                title={'La locul de producere există sisteme de stocare a energiei electrice produse din surse regenerabile?*'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleSurseRegen}
                value={surseRegen}
                disabled={isEditing === false}
                id="reg"
            />
            {surseRegen === 1 && <form className="m-0 mb-4 overflow-auto">
                <table className="dynamic-table">
                    <tbody>
                    <tr style={{height: '25px'}}>
                        <td className="p-2">Detalii schemă alimentare</td>
                        <td colSpan={2} className="custom-td">
                            <input
                                className="table-input"
                                onChange={(e) => handleDetaliiSchema(e.target.value)}
                                value={detaliiSchema}
                                disabled={isEditing === false}
                            />
                        </td>
                    </tr>
                    <tr style={{height: '25px'}}>
                        <td className="p-2">Capacitate baterii de acumulare (Ah)</td>
                        <td className="custom-td">
                            <input
                                className="table-input"
                                onChange={(e) => {
                                    const re = /^[,.0-9\b]+$/;

                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        handleCapacitate(e.target.value);
                                    }
                                }}
                                value={capacitate}
                                disabled={isEditing === false}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>}
            {SRError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{SRError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'La locul de producere există echipamente de măsurare a energiei electrice montate în instalația de utilizare, altele decât cele aparținând operatorilor de distribuție?*'}
                subtitle={'(dacă da, te rog precizează caracteristicile acestora, respectiv serie contor, tip contor, date tehnice)'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleMasurare}
                value={masurare}
                disabled={isEditing === false}
                id="em"
            />
            {masurare === 1 && <DynamicTable format={tabelDateEM} data={dateEM} handler={handleDateEM} isEditing={isEditing}/>}
            {EMError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{EMError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            {validationError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{validationError}</p>}
            {error !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{error}</p>}
            {numbersError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{numbersError}</p>}
            <div className="d-flex flex-row justify-content-between">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </div>
    );
};

export default LPNotificare2;