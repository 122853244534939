import { toggleLoader } from '@actions/LoaderActions';
import { registerNotPortfolioCp, registerPortfolioCps } from '@api/requests/requests';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { REPORT_DATE_RANGE_TYPE_NONE } from '@constants/requests/RequestsConstants';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as notification from '@lib/Notification';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import LocuriConsum from './ConsumptionPointsFilterAndList/LocuriConsum';
import { ReportDateRange } from './DateSelector/ReportDateRange';
import { ConfigType } from './Requests';
import ValidatePodCountyLocallityForm from './ValidatePodCountyLocalityForm/ValidatePodCountyLocalityForm';

const CP_LOCATION_FROM_PORTFOLIO = 'CP_LOCATION_FROM_PORTFOLIO';
const CP_LOCATION_NOT_FROM_PORTFOLIO = 'CP_LOCATION_NOT_FROM_PORTFOLIO';
const BASE_URL_CONSUMURI_ZILNICE_GN = 'OrdersConsumuriZilniceGN';

export type NewRequestProps = {
  config: ConfigType;
  supplierId: string;
  isClient: boolean;
  onSuccesCreateRequest: () => void;
  baseUrl: string;
  setShowNewRequestPage: (value: boolean) => void;
};

type ConsumptionPoint = {
  clientCode: string;
  clientName: string;
  consumptionCode: string;
  pod: string;
  address: string;
  contractStatus: string;
  meterNumber: string;
  connectionStatus: string;
  tariffCategory: string;
  srmp: string;
  minPressure: string;
  maxPressure: string;
  technicalMinPressure: string;
  contractCapacity: number;
  contractDuration: string;
  supplierCode: string;
  partnerId: string;
  invoiceClass: string;
  tariff: string;
  contractNo: string;
  codLocConsum: string;
};

export const NewRequest = ({ config, supplierId, onSuccesCreateRequest, baseUrl, setShowNewRequestPage, isClient }: NewRequestProps) => {
  //user
  const [utility, setUtility] = useState(0);

  //cps
  const [showModalSelectCPLocation, setShowModalSelectCPLocation] = useState(false);
  const [selectedConsumptionPoints, setSelectedConsumptionPoints] = useState<ConsumptionPoint[]>([]);
  const [selectedConsumptionPointsLocation, setSelectedConsumptionPointsLocation] = useState('');

  //CP location options
  const [cpLocationOptions, setCpLocationOptions] = useState<any>([]);

  const [date, setDate] = useState<any>({});

  //Adaugare solicitare pentru CP din afara portofoliu
  const [isValidPodCountyLocallityForm, setIsValidPodCountyLocallityForm] = useState(false);
  const [fileList, setFileList] = useState<File[]>([] as File[]);
  const [accordFile, setAccordFile] = useState<any>(null);
  const [validatedData, setValidatedData] = useState<any>(null);
  const [reportTypes, setReportTypes] = useState<string[] | any>(null);

  const dispatch = useDispatch();
  const [error, setError] = useState('');

  useEffect(() => {
    setUtility(config.utility);
    if (config.allowConsumptionPointsOutsidePortfolio) {
      setCpLocationOptions([
        { id: CP_LOCATION_FROM_PORTFOLIO, title: 'Din portofoliu' },
        { id: CP_LOCATION_NOT_FROM_PORTFOLIO, title: 'Din afara portofoliului' }
      ]);
      setShowModalSelectCPLocation(true);
    } else {
      // setCpLocationOptions([{ id: CP_LOCATION_FROM_PORTFOLIO, title: 'Din portofoliu' }]);
      setSelectedConsumptionPointsLocation(CP_LOCATION_FROM_PORTFOLIO);
    }

    //set buttons
    if (config.reportTypes && config.reportTypes.length > 0) {
      setReportTypes(config.reportTypes);
    }
  }, [config]);

  const requestReport = (reportTypeId?: number) => {
    dispatch(toggleLoader(true));
    setError('');

    let payload: any = {};
    if (date.startDate) {
      payload.StartDate = date.startDate;
    }
    if (date.endDate) {
      payload.EndDate = date.endDate;
    }
    payload.ReportTypeId = reportTypeId;

    if (selectedConsumptionPointsLocation === CP_LOCATION_FROM_PORTFOLIO) {
      payload.ConsumptionPoints = selectedConsumptionPoints;
      registerPortfolioCps(baseUrl, payload)
        .then((res) => {
          notification.success('Solicitarea a fost creată.');
          onSuccesCreateRequest();
          dispatch(toggleLoader(false));
        })
        .catch((error) => {
          if (error.status == 200) {
            notification.success('Solicitarea a fost creată.');
            onSuccesCreateRequest();
          } else {
            notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    } else {
      payload.Pod = validatedData.pod;
      payload.CountyCode = validatedData.countyCode;
      payload.LocalityCode = validatedData.localityCode;
      payload.DocumentAcordPreluareDate = accordFile;

      registerNotPortfolioCp(baseUrl, payload)
        .then((res) => {
          notification.success('Solicitarea a fost creată.');
          onSuccesCreateRequest();
          dispatch(toggleLoader(false));
        })
        .catch((error) => {
          if (error.status == 200) {
            notification.success('Solicitarea a fost creată.');
            onSuccesCreateRequest();
          } else {
            try {
              if (baseUrl == BASE_URL_CONSUMURI_ZILNICE_GN && error == 'Loc de consum invalid') {
                setError(`Locul de consum ${validatedData.pod} nu dispune de consumuri zilnice.`);
              } else {
                notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
              }
            } catch (err) {
              notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
            }
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const handleCloseModalSelectCPLocation = (opt: any) => {
    setSelectedConsumptionPointsLocation(opt.id);
    setShowModalSelectCPLocation(false);
  };

  const handleFileListChange = (list: File[]) => {
    setFileList(list);
    let file = list.map((li) => {
      const base64 = li.base64 ? li.base64.slice(li.base64.lastIndexOf('base64,') + 7) : '';
      return {
        NumeDocument: li.name,
        ContinutDocument: base64
      };
    })[0];
    setAccordFile(file);
  };

  return (
    <>
      <div>
        {selectedConsumptionPointsLocation && (
          <>
            <div className="mb-3 mt-1 ms-3">
              <Button
                onClick={() => {
                  setShowNewRequestPage(false);
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
                <span>Înapoi</span>
              </Button>
            </div>
            {selectedConsumptionPointsLocation === CP_LOCATION_FROM_PORTFOLIO ? (
              <>
                <LocuriConsum
                  utility={utility}
                  supplierId={supplierId}
                  isClient={isClient}
                  metterTypes={config ? config.metterTypes : null}
                  mmrOnly={config ? config.mmrOnly : null}
                  handleChangeConsumptionPointsChecked={(cpc: any) => {
                    setSelectedConsumptionPoints(cpc.map((cp: ConsumptionPoint) => cp.pod));
                  }}
                  reportDateRangeType={config ? config.reportDateRangeType : 0}
                  setDate={setDate}
                />
                <div className="mb-2" style={{ display: 'flex', justifyContent: 'end' }}>
                  <p className="m-0">Locuri de consum: {selectedConsumptionPoints.length}</p>
                </div>
                <hr className="m-0 mb-2" />
              </>
            ) : (
              <>
                <ValidatePodCountyLocallityForm
                  editDisabled={isValidPodCountyLocallityForm}
                  initialMeasurementPointCode=""
                  metterTypes={[]}
                  setValid={(valid: any) => {
                    setIsValidPodCountyLocallityForm(valid);
                  }}
                  setData={(data: any) => {
                    setValidatedData(data);
                  }}
                  utility={utility}
                />
                {isValidPodCountyLocallityForm && (
                  <>
                    <div className="mt-2 m-3">
                      <h5 style={{ position: 'relative', left: '5px' }}>Document acord</h5>
                      <UploadFile index={0} uploadedFilesLimit={1} onSetFileList={handleFileListChange} fileList={fileList} />
                    </div>
                    <Row>
                      <div className="mt-3 mb-0">
                        <h5 style={{ position: 'relative', left: '5px' }}>Perioada de raportare</h5>
                        <Col xs={12} sm={8} md={6} className="mt-2">
                          <ReportDateRange reportDateRangeType={config ? config.reportDateRangeType : 0} setDate={setDate} large={true} />
                        </Col>
                      </div>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )}

        {error && (
          <div className="ms-3" style={{ color: 'red', fontWeight: 'bold' }}>
            {error}
          </div>
        )}

        <div className="d-flex justify-content-center justify-content-sm-end flex-wrap">
          {selectedConsumptionPointsLocation &&
            !(
              selectedConsumptionPointsLocation === CP_LOCATION_NOT_FROM_PORTFOLIO &&
              (!date.startDate || !date.endDate || !accordFile)
            ) && (
              <>
                {reportTypes ? (
                  reportTypes.map((reportType: any) => {
                    return (
                      <Button
                        disabled={
                          selectedConsumptionPointsLocation === CP_LOCATION_FROM_PORTFOLIO &&
                          (((!date.startDate || !date.endDate) && config.reportDateRangeType != REPORT_DATE_RANGE_TYPE_NONE.id) ||
                            selectedConsumptionPoints.length == 0)
                        }
                        className="btn-large mt-1 ms-1 me-1"
                        onClick={() => requestReport(reportType.id)}
                        style={{minWidth: '205px'}}
                      >
                        Solicită {reportType.name.charAt(0).toLocaleLowerCase() + reportType.name.slice(1)}
                      </Button>
                    );
                  })
                ) : (
                  <Button
                    disabled={
                      selectedConsumptionPointsLocation === CP_LOCATION_FROM_PORTFOLIO &&
                      (((!date.startDate || !date.endDate) && config.reportDateRangeType != REPORT_DATE_RANGE_TYPE_NONE.id) ||
                        selectedConsumptionPoints.length == 0)
                    }
                    className="btn-large mt-1 ms-2"
                    onClick={() => requestReport()}
                  >
                    Solicită raport
                  </Button>
                )}
              </>
            )}
        </div>
      </div>
      <ChoiceModal
        showModal={showModalSelectCPLocation}
        modalTitle={'Raport pentru loc consum'}
        options={cpLocationOptions}
        onClose={handleCloseModalSelectCPLocation}
        onHide={() => {
          setShowModalSelectCPLocation(false);
          setShowNewRequestPage(false);
        }}
      ></ChoiceModal>
    </>
  );
};

export default NewRequest;
