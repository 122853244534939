import React from 'react';
import Slider from 'react-slick';

const AdCardDetailsSlider = (props: any) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000
    };

    return (
        <div className="ad-card-details-slider">
            <Slider {...settings}>
                {props.images.map((img: any, index: number) => (
                    <img 
                        key={index} 
                        src={img} 
                        alt={img} 
                        className="ad-card-slider-image" 
                        onClick={() => {
                            if (props.onClick) {
                                props.onClick(img);
                            }
                        }}
                        onDoubleClick={() => {
                            if (props.onDoubleClick) {
                                props.onDoubleClick(img);
                            }
                        }}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default AdCardDetailsSlider;