import { toggleLoader } from '@actions/LoaderActions';
import { getTemplatesCreateAccount, validateAccount, verifySupplier } from '@api/user/user';
import ClientPersoanaFizica from '@components/auth/createAccount/tabs/ClientPersoanaFizica';
import ClientPersoanaJuridica from '@components/auth/createAccount/tabs/ClientPersoanaJuridica';
import FurnizorEnergieElectrica from '@components/auth/createAccount/tabs/FurnizorEnergieElectrica';
import FurnizorGazeNaturale from '@components/auth/createAccount/tabs/FurnizorGazeNaturale';
import OperatorEconomic from '@components/auth/createAccount/tabs/OperatorEconomic';
import PartenerValoriMasurate from '@components/auth/createAccount/tabs/PartenerValoriMasurate';
import VerificareDate from '@components/auth/createAccount/tabs/VerificareDate';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { IState } from '@type/store';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const CreateAccountForm = (props: any) => {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const deviceId: string | undefined = useSelector<IState>((state) => state?.device?.deviceId) as string;
    const dispatch = useDispatch();
    const [wasGNDataVerified, setWasGNDataVerified] = useState(false);
    const [wasEEDataVerified, setWasEEDataVerified] = useState(false);
    const [syncErrors, setSyncErrors] = useState<string[]>([]);
    const [verifyError, setVerifyError] = useState('');
    const [verifiedSupplierEE, setVerifiedSupplierEE] = useState({});
    const [verifiedSupplierGN, setVerifiedSupplierGN] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [templates, setTemplates] = useState<any>(null);
    const [activeTabKey, setActiveTabKey] = useState('');

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = url.search;
        const searchParams = new URLSearchParams(params);

        if (searchParams.has('formType')) {
            const formType = searchParams.get('formType');
            setActiveTabKey(formType ?? '');
        } else {
            setActiveTabKey('cpf');
        }
      }, []);

    useEffect(() => {
        dispatch(toggleLoader(true));
        getTemplatesCreateAccount()
          .then((res) => {
            setTemplates(res);
            dispatch(toggleLoader(false));
          })
          .catch((err) => {
            dispatch(toggleLoader(false));
          });
      }, []);

    useEffect(() => {
        if(success) {
            setModalMessage('Un mail a fost trimis pentru activare!');
            setModalTitle('Succes!');
            setShowModal(true);
        }
    },[success]);

    // const handleVerifiedEE = (supplier: any) => setVerifiedSupplierEE(supplier);
    // const handleVerifiedGN = (supplier: any) => setVerifiedSupplierGN(supplier);

    const setErrorMessage = (errorDesc: any) => {
        switch (errorDesc) {
            case 'UnknownError': return 'A apărut o eroare.';
            case 'ExistingUser': return 'Utilizator existent.';
            case 'InvalidFirstNameOrLastName': return 'Nume sau prenume invalid.';
            case 'InvalidSupplier': return 'Furnizor invalid.';
            case 'InvalidEmail': return 'Adresa de email invalidă.';
            case 'InvalidMvPartner': return 'Cod ETSO invalid.';
            case 'InvalidContactPhoneNumber': return 'Număr telefon contact invalid.';
            case 'InvalidEconomicOperator': return 'Operator Economic invalid.';
            case 'InvalidPhoneNumber': return 'Număr telefon invalid.';
            case 'MultipleOperatorsWithSameAuthorizationNumber': return 'Nr. Autorizare este folosit deja de către un operator.';
            case 'InvalidMvPartnerEmail': return 'Pentru accesul la această secțiune vă rugăm contactați Serviciul Prognoza si Monitorizare Energie DELGAZ GRID S.A.';
            case 'ExistingUserWithAnotherRole': return 'Există deja un cont pentru această adresă de mail.';
            case 'ExistingUserWithRegistrationCode': return 'Acest cod de înregistrare fiscală este deja folosit.';
            default: return 'A apărut o eroare.';
        }
    };

    //eslint-disable-next-line
    const getSyncErrors = (errors: any) => {
        let errArray = [];

        if (errors.cui) {
            errArray.push('CUI: ' + errors.cui);
        }

        if (errors.authorizationNumber) {
            errArray.push('Nr. Autorizatie: ' + errors.authorizationNumber);
        }

        if (errors.tradeRegisterNumber) {
            errArray.push('Nr. Registrul Comertului: ' + errors.orc);
        }

        if (errors.companyName) {
            errArray.push('Denumire societate: ' + errors.companyName);
        }

        if (errors.name) {
            errArray.push('Denumire: ' + errors.name);
        }

        if (errors.fixPhone) {
            errArray.push('Telefon fix: ' + errors.fixPhone);
        }

        if (errors.mobilePhone) {
            errArray.push('Telefon mobil: ' + errors.mobilePhone);
        }

        if (errors.etsoCode) {
            errArray.push('Cod ETSO: ' + errors.etsoCode);
        }

        if (errors.faxNumber) {
            errArray.push('Fax: ' + errors.faxNumber);
        }

        if (errors.nrReg) {
            errArray.push('Registrul Comertului: ' + errors.nrReg);
        }

        if (errors.phoneNumber) {
            errArray.push('Telefon mobil: ' + errors.phoneNumber);
        }

        if (errors.residenceLocality) {
            errArray.push('Localitate: ' + errors.residenceLocality);
        }

        if (errors.residenceCounty) {
            errArray.push('Judet: ' + errors.residenceCounty);
        }

        setSyncErrors(errArray);
    };

    const verify = async (values: any, util: number) => {
        dispatch(toggleLoader(true));
        setVerifyError('');

        let data = {
            email: values.email,
            code: values.supplierCode,
            license: values.licence,
            licenseType: util
        };

        await verifySupplier(data).then((res: any) => {
            if (res.message === null && res.partner) {
                let partner = res.partner;
                partner = { ...partner, email: data.email };
                partner = { ...partner, supplierCode: data.code };
                partner = { ...partner, licence: data.license };
                partner = { ...partner, emailConfirmation: data.email };
                partner = { ...partner, password: '' };
                partner = { ...partner, passwordConfirmation: '' };

                util === 1 && setVerifiedSupplierEE(partner);
                util === 2 && setVerifiedSupplierGN(partner);

                util === 1 && setWasEEDataVerified(true);
                util === 2 && setWasGNDataVerified(true);
            } else {
                setVerifyError(res.message);
            }
        }).catch((e: any) => console.log(e));

        dispatch(toggleLoader(false));
        // setWasDataVerified(true);
    };

    const submit = async (values: any) => {
        dispatch(toggleLoader(true));
        let data = values;
        data = { ...data, deviceId };
        data = { ...data, email: data.email.trim() };
        data = { ...data, emailConfirmation: data.emailConfirmation.trim() };

        setError('');
        setSuccess('');
        setSyncErrors([]);
        setVerifyError('');

        // call fara sync
        await validateAccount(data).then((res: any) => {
            if (res.success === false) {
                setError(setErrorMessage(res.errors[0].errorDescription));
            }
        }).catch((e: any) => {
            if (e.status === 204) {
                setSuccess('success');
            } else {
                setError('A apărut o eroare.');
            }
        });

        dispatch(toggleLoader(false));
    };

    const handleSelect = (key: any) => {
        setActiveTabKey(key);
        setError('');
        setSyncErrors([]);
        setVerifyError('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        window.location.href = '/login';
    };

    return (
        <div>
            <Tabs className="create-account-tabs d-flex flex-wrap flex-md-nowrap justify-content-center" mountOnEnter={true} unmountOnExit={true} onSelect={handleSelect} activeKey={activeTabKey}>
                <Tab eventKey="cpf" title={'Client persoană fizică'} mountOnEnter={true} unmountOnExit={true}>
                    <ClientPersoanaFizica submit={submit} error={error} success={success} syncErrors={syncErrors}/>
                </Tab>
                <Tab eventKey="cpj" title={'Client persoană juridică'} mountOnEnter={true} unmountOnExit={true}>
                    <ClientPersoanaJuridica submit={submit} error={error} success={success} syncErrors={syncErrors}/>
                </Tab>
                <Tab eventKey="pvm" title={'Partener valori măsurate'}>
                    <PartenerValoriMasurate submit={submit} error={error} success={success} syncErrors={syncErrors}/>
                </Tab>
                <Tab eventKey="fgn" title={'Furnizor Gaze Naturale'} mountOnEnter={true} unmountOnExit={true}>
                    {wasGNDataVerified ? <FurnizorGazeNaturale supplier={verifiedSupplierGN} submit={submit} error={error} success={success}/> : <VerificareDate error={verifyError} utility={2} verify={verify} />}
                    {/*<FurnizorGazeNaturale submit={submit} error={error} success={success} syncErrors={syncErrors}/>*/}
                </Tab>
                <Tab eventKey="fen" title={'Furnizor Energie Electrică'} mountOnEnter={true} unmountOnExit={true}>
                    {wasEEDataVerified ? <FurnizorEnergieElectrica supplier={verifiedSupplierEE} submit={submit} error={error} success={success}/> : <VerificareDate error={verifyError} utility={1} verify={verify} />}
                    {/*<FurnizorEnergieElectrica submit={submit} error={error} success={success} syncErrors={syncErrors}/>*/}
                </Tab>
                <Tab eventKey="oevr" title={'Operator Economic Verificări/Revizii'}>
                    <OperatorEconomic submit={submit} error={error} success={success} syncErrors={syncErrors} templates={templates}/>
                </Tab>
            </Tabs>
            <ModalConfirm modalTitle={modalTitle} showModal={showModal} onClose={handleCloseModal} >
                <h5>{modalMessage}</h5>
            </ModalConfirm>
        </div>
    );
};

export default CreateAccountForm;
