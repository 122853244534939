import { Proxy, proxy } from '@api/azureProxy';
import { store } from '@lib/store';

export function getTimelineStepsEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/iconnect/RequestConsumer/request_timeline?requestId=${payload.requestId}`,
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getTimelineStepsGN(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/iconnect/RequestGN/request_timeline?requestId=${payload.requestId}`,
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function postTimelineCreateComplaintEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/iconnect/RequestConsumer/create_complaint',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postTimelineCreateComplaintGN(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/iconnect/RequestGN/create_complaint',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function getEventDocTypesEE() {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/iconnect/RequestConsumer/event_doc_types',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getEventDocTypesGN() {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/iconnect/RequestGN/event_doc_types',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function saveEventDocumentsEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/iconnect/RequestConsumer/save_event_document_list',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function saveEventDocumentsGN(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/iconnect/RequestGN/save_event_document_list',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postCancelRequestEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/iconnect/RequestConsumer/cancel_request',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function postCancelRequestGN(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/iconnect/RequestGN/cancel_request',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}
