import { toggleLoader } from '@actions/LoaderActions';
import { postTimelineCreateComplaintEE, postTimelineCreateComplaintGN } from '@api/timeline';
import InputTextArea from '@components/common/Input/InputTextArea';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import Modal from '@components/dev/Components/Modal';
import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';
import * as notification from '@lib/Notification';
import React, { Fragment, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type SendReclamationPropsType = {
  utility: number | any;
  requestId: any;
  className?: any;
  isBo: boolean | any;
  reloadTimeline: () => void;
};

const SendReclamation = (props: SendReclamationPropsType) => {
  //modal reclamation
  const { utility, requestId } = props;

  const [showModalNewReclamation, setShowModalNewReclamation] = useState<any>(false);
  const [reclamationMessage, setReclamationMessage] = useState<any>('');
  const [reclamationFileList, setReclamationFileList] = useState<File[]>([] as File[]);
  const [sendReclamationError, setSendReclamationError] = useState<any>('');

  const dispatch = useDispatch();

  const sendReclamation = () => {
    dispatch(toggleLoader(true));
    setSendReclamationError('');

    let payload = {
      requestId: requestId,
      message: reclamationMessage,
      documents: reclamationFileList.map((file: File) => {
        return {
          name: file.name,
          content: file.base64.split(',')[1]
        };
      })
    };

    if (utility == UTILITY_EE) {
      postTimelineCreateComplaintEE(payload)
        .then((res) => {
          dispatch(toggleLoader(false));
          notification.success('Sesizarea a fost trimisă cu succes.');
          setReclamationMessage('');
          setReclamationFileList([]);
          setShowModalNewReclamation(false);
          props.reloadTimeline();
        })
        .catch((err) => {
          if (err.status == 200) {
            notification.success('Sesizarea a fost trimisă cu succes.');
            setReclamationMessage('');
            setReclamationFileList([]);
            setShowModalNewReclamation(false);
            props.reloadTimeline();
          } else if (err === 'Request is completed') {
            setSendReclamationError('Cererea a fost deja finalizată.');
          } else {
            setSendReclamationError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    } else if (utility == UTILITY_GN) {
      postTimelineCreateComplaintGN(payload)
        .then((res) => {
          dispatch(toggleLoader(false));
          notification.success('Sesizarea a fost trimisă cu succes.');
          setReclamationMessage('');
          setReclamationFileList([]);
          setShowModalNewReclamation(false);
          props.reloadTimeline();
        })
        .catch((err) => {
          if (err.status == 200) {
            notification.success('Sesizarea a fost trimisă cu succes.');
            setReclamationMessage('');
            setReclamationFileList([]);
            setShowModalNewReclamation(false);
            props.reloadTimeline();
          } else if (err === 'Request is completed') {
            setSendReclamationError('Cererea a fost deja finalizată.');
          } else {
            setSendReclamationError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  return (
    <Fragment>
      <Button
        className={props.className}
        onClick={() => {
          if (props.isBo) {
            notification.warning('Acțiunea este accesibilă doar clienților!');
            return;
          }
          setShowModalNewReclamation(true);
        }}
      >
        Sesizări
      </Button>

      <Modal
        title="Adaugă sesizare"
        show={showModalNewReclamation}
        setShow={(state: boolean) => {
          setShowModalNewReclamation(state);
          if (state == false) {
            setReclamationMessage('');
            setReclamationFileList([]);
          }
        }}
        size={'lg'}
        footerAction={
          <Button
            className="btn btn-primary"
            disabled={!reclamationMessage && reclamationFileList.length == 0}
            onClick={(e) => {
              sendReclamation();
            }}
            variant="primary"
          >
            Trimite
          </Button>
        }
        onClose={() => {
          setShowModalNewReclamation(false);
          setReclamationMessage('');
          setReclamationFileList([]);
          setSendReclamationError('');
        }}
      >
        <Row className="mt-2">
          <Col xs={12} sm={12} md={7}>
            <InputTextArea
              value={reclamationMessage}
              label={'Adaugă sesizare privind procesul de racordare'}
              maxLength={2000}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setReclamationMessage(event.target.value);
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={5}>
            <UploadFile
              onSetFileList={(list: any) => {
                setReclamationFileList(list);
              }}
              fileList={reclamationFileList}
              index={0}
              uploadedFilesLimit={10}
              hideDownloadButton
            />
          </Col>
          {sendReclamationError && <div style={{ color: 'red', fontWeight: 'bold' }}>{sendReclamationError}</div>}
        </Row>
      </Modal>
    </Fragment>
  );
};

export default SendReclamation;
