import React, { FunctionComponent } from 'react';

type DropdownOptionProps = {
  value: string|number;
  label: string;
  selected?: boolean;
  disabled?: boolean;
  hasFocus?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const DropdownOption: FunctionComponent<DropdownOptionProps> = (props) => {
  const { label, selected = false, disabled = false, hasFocus = false, onClick } = props;

  const className = `delgazui-dropdown-option ${selected ? 'delgazui-selected' : ''} ${disabled ? 'delgazui-disabled' : ''} ${
    hasFocus ? 'delgazui-focus' : ''
  }`;

  return (
    <div className={className} onClick={onClick}>
      {label}
    </div>
  );
};

export default DropdownOption;
