import React from 'react';

const HeadlineRacordare = () => {
  return (
    <div className="headline-racordare">
        <div className="headline-text">
            <span>Te ghidăm pas cu pas în procesul de racordare la rețea</span>
        </div>
        <div className="headline-content">
            {
                window.location.href.includes('gaze') &&
                    <div className="headline-content-gn">
                        <span className="hc-title mb-3">Vrei să îți conectezi casa la rețeaua de distribuție gaze naturale? Dorești să modifici traseul instalației de racordare sau a instalației de utilizare existent? Vrei o instalație nouă și montarea unui contor separat?</span>
                        <span className="hc-text mb-3">Noi suntem responsabili de racordarea la rețea a imobilelor aflate în localitățile în care suntem operator al sistemului de distribuție al gazelor naturale</span>
                        <span className="hc-text">Poți verifica <a href={'https://delgaz.ro/gaze-naturale'} className="link red hover-red">aici</a> care sunt zonele geografice în care poți solicita racordarea la rețeaua de distribuție gaze naturale.</span>
                    </div>
            }
            {
                window.location.href.includes('energie') &&
                    <div className="headline-content-ee">
                        <span className="hc-title mb-3">Vrei să îți conectezi casa la rețeaua de distribuție energie electrică? Dorești să modifici instalația de racordare? Vrei creșterea puterii absorbite?</span>
                        <span className="hc-text mb-3">Noi suntem responsabili de racordarea la rețea a imobilelor aflate în localitățile în care suntem operator al sistemului de distribuție al energiei electrice.</span>
                        <span className="hc-text">Poți verifica <a href={'https://delgaz.ro/energie-electrica'} className="link red hover-red">aici</a> care sunt zonele geografice în care poți solicita racordarea la rețeaua de distribuție a energiei electrice.</span>
                    </div>
            }
        </div>
    </div>
  )
}

export default HeadlineRacordare