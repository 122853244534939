import { toggleLoader } from '@actions/LoaderActions';
import { registerRSSBO } from '@api/rss/rss';
import DatePicker from '@components/common/Date/DatePicker';
import { Input } from '@components/common/Input';
import InputTextArea from '@components/common/Input/InputTextArea';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import PFNotInPortofolio from '@components/rssClient/forms/PFNotInPortofolio';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RSSClientPFNotInPortofolioSchema, RSSClientPJNotInPortofolioSchema } from '../../helpers/schemas';
import DatePetitie from './DatePetitie';
import PJNoClient from './PJNoClient';
import Search from './Search';
import InputMask from 'react-input-mask';

let PFValues = {
  cnp: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  county: '',
  locality: '',
  street: '',
  number: '',
  postalCode: ''
};

let PJValues = {
  cui: '',
  name: '',
  phone: '',
  email: '',
  county: '',
  locality: '',
  street: '',
  number: '',
  postalCode: '',
  representative: ''
};

type RSSCallCenterProps = {
  onRegisterRssSuccesBo?: () => any;
  setShowRSSSaveButton?: (val: boolean) => any;
  setDisableRSSSaveButton?: (val: boolean) => any;
  userRoles: any[];
};

const RSSCallCenter = forwardRef((props: RSSCallCenterProps, ref: any) => {
  useImperativeHandle(ref, () => ({
    submitFromBo
  }));

  const [isClient, setIsClient] = useState<boolean | null>(null);
  const [searchCounty, setSearchCounty] = useState('');
  const [pod, setPod] = useState('');
  const [cnp, setCnp] = useState('');
  const [cui, setCui] = useState('');

  //client data
  const [data, setData] = useState<any>(null);
  const [dataType, setDataType] = useState<string>('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [CPAddress, setCPAddress] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [clientType, setClientType] = useState<number | null>(null);
  const [PFFormData, setPFFormData] = useState(JSON.parse(JSON.stringify(PFValues)));
  const [PJFormData, setPJFormData] = useState(JSON.parse(JSON.stringify(PJValues)));
  const [category, setCategory] = useState('');
  const [subject, setSubject] = useState('');
  const [subSubject, setSubSubject] = useState('');
  const [requestType, setRequestType] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [fileList, setFileList] = useState<File[]>([] as File[]);
  const [checkboxOne, setCheckboxOne] = useState(false);
  const [checkboxTwo, setCheckboxTwo] = useState(false);
  const [hasRegNr, setHasRegNr] = useState(1);
  const [regNr, setRegNr] = useState('');
  const [regNrDate, setRegNrDate] = useState('');
  const [degrDate, setDegrDate] = useState('');
  const [validationError, setValidationError] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [rssId, setRssId] = useState('');
  const [modalHeight, setModalHeight] = useState('unset');
  const [emailError, setEmailError] = useState('');
  const [phoneErorr, setPhoneError] = useState('');

  const formikRef = useRef<any>();

  const { reloadListResults } = React.useContext(BackOfficeContext) as BackOfficeContextType;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkboxOne || !checkboxTwo) {
      props.setDisableRSSSaveButton && props.setDisableRSSSaveButton(true);
    } else {
      props.setDisableRSSSaveButton && props.setDisableRSSSaveButton(false);
    }
  }, [checkboxOne, checkboxTwo]);

  useEffect(() => {
    if (isClient === true && data) {
      props.setShowRSSSaveButton && props.setShowRSSSaveButton(true);
    } else if (isClient === false && clientType !== null) {
      props.setShowRSSSaveButton && props.setShowRSSSaveButton(true);
    } else {
      props.setShowRSSSaveButton && props.setShowRSSSaveButton(false);
    }
  }, [isClient, data, clientType]);

  useEffect(() => {
    if (data) {
      if (data.clientCnp !== null) {
        setDataType('PF');
        setFirstName(data.firstName);
        setLastName(data.lastName);
      } else {
        setDataType('PJ');
        setName(data.lastName + ' ' + data.firstName);
      }

      setCPAddress(data.address);
    }
  }, [data]);

  const handleIsClient = (type: boolean) => {
    if (type === true) {
      setModalHeight('550px');
    } else {
      setModalHeight('unset');
    }

    setData(null);
    setSearchCounty('');
    setApiResponse('');
    setValidationError('');
    setCategory('');
    setSubject('');
    setSubSubject('');
    setRequestType(null);
    setMessage('');
    setCheckboxOne(false);
    setCheckboxTwo(false);
    setFileList([]);
    setEmailError('');

    setPod('');
    setCnp('');
    setCui('');

    setIsClient(type);
  };

  const handleSearchCounty = (county: string) => {
    setSearchCounty(county);
  };

  const handlePod = (pod: string) => {
    setPod(pod);
  };

  const handleCnp = (cnp: string) => {
    setCnp(cnp);
  };

  const handleCui = (cui: string) => {
    setCui(cui);
  };

  const handleData = (data: any) => {
    setEmailError('');
    setPhoneError('');
    setApiResponse('');
    setValidationError('');
    setCategory('');
    setSubject('');
    setSubSubject('');
    setRequestType(null);
    setMessage('');
    setCheckboxOne(false);
    setCheckboxTwo(false);
    setFileList([]);

    setData(data);
  };

  const handleClientType = (type: number) => {
    setClientType(type);

    setPFFormData({
      cnp: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      county: '',
      locality: '',
      street: '',
      number: '',
      postalCode: ''
    });
    setPJFormData({
      cui: '',
      name: '',
      phone: '',
      email: '',
      county: '',
      locality: '',
      street: '',
      number: '',
      postalCode: '',
      representative: ''
    });

    setApiResponse('');
    setValidationError('');
    setCategory('');
    setSubject('');
    setSubSubject('');
    setRequestType(null);
    setMessage('');
    setCheckboxOne(false);
    setCheckboxTwo(false);
    setFileList([]);
  };

  const handlePFForm = (data: any, field: any) => {
    let newData = PFFormData;

    Object.keys(newData).map((key: string, index) => {
      if (key === field) {
        //@ts-ignore
        newData[key] = data;
      }
    });

    setPFFormData(newData);
  };

  const handlePJForm = (data: any, field: any) => {
    let newData = PJFormData;

    Object.keys(newData).map((key: string, index) => {
      if (key === field) {
        //@ts-ignore
        newData[key] = data;
      }
    });

    setPJFormData(newData);
  };

  const handleCategory = (data: any) => {
    setCategory(data);
    setSubject('');
    setSubSubject('');
  };

  const handleSubject = (data: any) => {
    setSubject(data);
    setSubSubject('');
  };

  const handleSubSubject = (data: any) => {
    setSubSubject(data);
  };

  const handleRequestType = (type: string) => {
    setRequestType(type);
  };

  const handleFileListChange = (list: File[]) => {
    setFileList(list);
  };

  const handleHasRegNr = (type: number) => {
    setHasRegNr(type);
  };

  const filePayload = () => {
    const files: any[] = [];

    fileList.map((file: File) => {
      let add = {
        NumeDocument: file.name,
        ContinutDocument: file.base64.split(',')[1]
      };

      files.push(add);
    });

    return files;
  };

  const submitFromBo = () => {
    if (isClient) {
      submitClient();
    } else {
      formikRef.current?.submitForm();
    }
  };

  const submit = (values: any) => {
    setValidationError('');
    setApiResponse('');
    setRssId('');

    const atributeGenerice = {
      IdExtern: uuidv4(),
      Scenariu: 'RSS_LOCCONSUM_NO_POD',
      DocumenteSolicitare: filePayload()
    };

    const atributeSpecificeScenariului = {
      Judet: '',
      Localitate: '',
      CodJudet: values.county,
      CodLocalitate: values.locality,
      TipAdresa: requestType,
      CategorieSubiect: category,
      Subiect: subject,
      SubSubiect: subSubject,
      CNP: values.cnp || '',
      CUI: values.cui || '',
      Telefon: values.phone,
      Descriere: message,
      AcordPrelucrareDateClient: 'DA',
      Utilitate: '',
      Strada: values.street,
      Numar: values.number,
      CodPostal: values.postalCode,
      Email: values.email,
      Nume: values.lastName || '',
      Prenume: values.firstName || '',
      NumeCompanie: values.name || '',
      NumeReprezentantLegal: values.representative || '',
      ImobilRacordat: false,
      IsCompany: clientType == 2 ? true : false,
      ...(hasRegNr === 2 && { RequestNumber: regNr }),
      ...(hasRegNr === 2 && { RequestDateUtc: regNrDate }),
      ...(hasRegNr === 2 && props.userRoles.includes('CRPRSS') && { DegrEntryDateUtc: degrDate })
    };

    const payload = {
      atributeGenerice,
      atributeSpecificeScenariului
    };

    let wasValidated = true;
    let CRPRole = props.userRoles.includes('CRPRSS');

    if (message === '' || category === '' || subject === '' || subSubject === '' || requestType === null) {
      setValidationError('Câmpurile marcate cu * sunt obligatorii.');
      wasValidated = false;
    }

    if (CRPRole === true && filePayload().length === 0) {
      setValidationError('Este obligatorie încarcărea documentului.');
      wasValidated = false;
    }

    if (hasRegNr === 2) {
      if (regNr === '' || regNrDate === '' || (CRPRole && degrDate === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
      } else if (regNr.includes('_') || regNr.length !== 8) {
        setValidationError('Numărul de înregistrare trebuie să fie de forma RCXXXXXX.');
        wasValidated = false;
      }
    }

    if (wasValidated) {
      apiCall(payload);
    }
  };

  const submitClient = () => {
    setValidationError('');
    setApiResponse('');
    setRssId('');

    const atributeGenerice = {
      IdExtern: uuidv4(),
      Scenariu: 'RSS_LOCCONSUM_ALTFURNIZOR',
      DocumenteSolicitare: filePayload()
    };

    const atributeSpecificeScenariului = {
      Judet: '',
      Localitate: '',
      CodJudet: data.countyCode,
      CodLocalitate: data.localityCode,
      TipAdresa: requestType,
      CategorieSubiect: category,
      Subiect: subject,
      SubSubiect: subSubject,
      CNP: data.clientCnp || '',
      CUI: data.clientCui || '',
      Telefon: phone,
      Descriere: message,
      AcordPrelucrareDateClient: 'DA',
      Utilitate: pod[0] === 'E' ? 'EE' : 'GN',
      Strada: data.street,
      Numar: data.streetNo,
      CodPostal: data.postalCode,
      Email: email,
      Nume: data.clientCnp ? lastName : '',
      Prenume: data.clientCnp ? firstName : '',
      NumeCompanie: data.clientCui ? name : '',
      NumeReprezentantLegal: '',
      ImobilRacordat: false,
      IsCompany: data.clientCui ? true : false,
      pod: pod,
      ...(hasRegNr === 2 && { RequestNumber: regNr }),
      ...(hasRegNr === 2 && { RequestDateUtc: regNrDate }),
      ...(hasRegNr === 2 && props.userRoles.includes('CRPRSS') && { DegrEntryDateUtc: degrDate })
    };

    const payload = {
      atributeGenerice,
      atributeSpecificeScenariului
    };

    let wasValidated = true;
    let CRPRole = props.userRoles.includes('CRPRSS');

    if (message === '' || category === '' || subject === '' || subSubject === '' || requestType === null || phone === '') {
      setValidationError('Câmpurile marcate cu * sunt obligatorii.');
      wasValidated = false;
    }

    if (email !== '' && !validateEmail(email)) {
      wasValidated = false;
    }

    if (phone !== '' && !validatePhone(phone)) {
      wasValidated = false;
    }

    if (CRPRole === true && filePayload().length === 0) {
      setValidationError('Este obligatorie încarcărea documentului.');
      wasValidated = false;
    }

    if (hasRegNr === 2) {
      if (regNr === '' || regNrDate === '' || (CRPRole && degrDate === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
      } else if (regNr.includes('_') || regNr.length !== 8) {
        setValidationError('Numărul de înregistrare trebuie să fie de forma RCXXXXXX.');
        wasValidated = false;
      }
    }

    if (wasValidated) {
      apiCall(payload);
    }
  };

  const regNrFormat = {
    '0': '[R]',
    '1': '[C]',
    '2': '[0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ]'
  };

  const renderSuccessMessage = (id: string) => {
    const date = moment();
    const day = moment().day();

    if (day !== 6 && day !== 7 && date.isBefore(moment('16:30', 'hh:mm'))) {
      if (hasRegNr === 1) {
        return (
          <div>
            <p className="red fw-bold mb-0">Petiția a fost înregistrată cu nr. {id}.</p>
            <p className="red fw-bold">În cel mai scurt timp, clientul va primi un email/sms cu numărul de înregistrare.</p>
          </div>
        );
      } else {
        return (
          <div>
            <p className="red fw-bold mb-0">Petiția a fost înregistrată cu nr. {id}.</p>
            {/* <p className="red fw-bold">În cel mai scurt timp, clientul va primi un email/sms cu numărul de înregistrare.</p> */}
          </div>
        );
      }
    } else {
      return (
        <div>
          <p className="red fw-bold mb-0">Petiția a fost înregistrată.</p>
          <p className="red fw-bold">Clientul va primi un email/sms cu numărul de înregistrare în următoarea zi lucrătoare.</p>
        </div>
      );
    }
  };

  const apiCall = async (payload: any) => {
    dispatch(toggleLoader(true));

    await registerRSSBO(payload)
      .then((response: any) => {
        if (response && response.data && response.data.status === 'Succes' && response.data.rssId && response.data.rssId !== 0) {
          setModalHeight('unset');

          if (response.data.descriereEroare) {
            setRssId(response.data.descriereEroare);
          } else {
            if (hasRegNr === 2) {
              setRssId(regNr);
            } else {
              setRssId(response.data.rssId);
            }
          }

          props.onRegisterRssSuccesBo && props.onRegisterRssSuccesBo();
          reloadListResults();
        } else if (response && response.data && response.data.locuriDeConsumInvalide && response.data.locuriDeConsumInvalide.length > 0) {
          if (response.data.locuriDeConsumInvalide[0].erori && response.data.locuriDeConsumInvalide[0].erori.length > 0) {
            setApiResponse(response.data.locuriDeConsumInvalide[0].erori[0].mesaj);
          } else {
            setApiResponse('Locul de consum nu a fost găsit.');
          }
        } else {
          setApiResponse('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.');
        }
      })
      .catch((err) => setApiResponse('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.'));

    dispatch(toggleLoader(false));
  };

  const validateEmail = (mail: string) => {
    setEmailError('');
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      setEmailError('Adresa de email are formatul greșit.');
      return false;
    }
  };

  const validatePhone = (phone: string) => {
    setPhoneError('');

    if (/^[0-9]*$/.test(phone) && phone.length === 10) {
      return true;
    } else {
      setPhoneError('Numărul de telefon are formatul greșit.');
      return false;
    }
  };

  const renderClient = () => {
    return (
      <div>
        <h5 className="mb-4">Date de identificare</h5>
        {dataType === 'PF' && (
          <Row>
            <Col xs={12} sm={6} className="mb-1">
              <Input value={lastName} label="Nume" disabled />
            </Col>
            <Col xs={12} sm={6}>
              <Input value={firstName} label="Prenume" disabled />
            </Col>
          </Row>
        )}
        {dataType === 'PJ' && (
          <Row>
            <Col xs={12} className="mb-1">
              <Input value={name} label="Denumire companie" disabled />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className="mb-1">
            <Input value={CPAddress} label="Adresă loc de consum" disabled />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="mb-3">
            <Input value={email} label="Email" onChange={(e) => setEmail(e.target.value)} mbZero />
            {emailError !== '' && <p className="red fw-bold">{emailError}</p>}
          </Col>
          <Col xs={12} sm={6}>
            <Input value={phone} label="Telefon*" onChange={(e) => setPhone(e.target.value)} mbZero />
            {phoneErorr !== '' && <p className="red fw-bold">{phoneErorr}</p>}
          </Col>
        </Row>

        {renderRSSData()}

        {apiResponse !== '' && <p className="red fw-bold">{apiResponse}</p>}
        {validationError !== '' && <p className="red fw-bold">{validationError}</p>}
      </div>
    );
  };

  const renderNoClient = () => {
    return (
      <div>
        <h5>Date de identificare</h5>
        <Formik
          initialValues={clientType === 1 ? PFFormData : PJFormData}
          enableReinitialize={true}
          onSubmit={(values) => submit(values)}
          validationSchema={clientType === 1 ? RSSClientPFNotInPortofolioSchema : RSSClientPJNotInPortofolioSchema}
          innerRef={formikRef}
        >
          {(props) => (
            <Form>
              <ChoiceComponent
                options={[
                  { title: 'Persoană fizică', id: 1 },
                  { title: 'Persoană juridică', id: 2 }
                ]}
                onChange={handleClientType}
                value={clientType}
                mZero
              />
              {clientType === 1 && (
                <PFNotInPortofolio
                  props={props}
                  formData={PFFormData}
                  handleData={handlePFForm}
                  disabledCounty={false}
                  isEmailDisabled={false}
                />
              )}
              {clientType === 2 && <PJNoClient props={props} formData={PJFormData} handleData={handlePJForm} disabledCounty={false} />}

              {clientType !== null && renderRSSData()}

              {apiResponse !== '' && <p className="red fw-bold">{apiResponse}</p>}
              {validationError !== '' && <p className="red fw-bold">{validationError}</p>}
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const renderRSSData = () => {
    return (
      <div className="mt-4">
        <h5>Date despre petiție</h5>
        <DatePetitie
          category={category}
          handleCategory={handleCategory}
          subject={subject}
          handleSubject={handleSubject}
          subSubject={subSubject}
          handleSubSubject={handleSubSubject}
          pod={isClient ? pod : null}
        />
        <ChoiceComponent
          options={[
            { title: 'Reclamație', id: 'Reclamatie' },
            { title: 'Solicitare', id: 'Solicitare' },
            { title: 'Sesizare', id: 'Sesizare' }
          ]}
          onChange={handleRequestType}
          value={requestType}
          subtitle={'Alege tipul interacțiunii*'}
          mZero
        />
        <Row className="mt-4">
          <Col xs={12}>
            <InputTextArea label="Mesaj*" value={message} onChange={(e) => setMessage(e.target.value)} mbZero />
            <span className="property-label mb-3" style={{ fontSize: '14px' }}>
              Maxim 2000 de caractere.
            </span>
          </Col>
        </Row>

        <Row className="mt-4 mb-4">
          <Col xs={12} className="mb-2">
            <div className="d-flex flex-column">
              <UploadFile
                onSetFileList={handleFileListChange}
                fileList={fileList}
                index={0}
                acceptedExtensionsError={'Sunt acceptate următoarele formate de fișiere: '}
              />
              <span className="property-label" style={{ fontSize: '14px' }}>
                Se acceptă documente în format BMP, GIF, JPG și PNG, fișiere .doc., pdf. si xls.
              </span>
              <span className="property-label" style={{ fontSize: '14px' }}>
                Documentele nu trebuie să depășească limita maximă de 10MB/fișier.
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <span>
            Completarea formularului de mai sus presupune faptul că ești îndreptățit să folosești datele comunicate. Dacă nu ești
            destinatarul datelor solicitate te informăm că orice divulgare, copiere, distribuire sau luarea de măsuri în dependență de
            conţinutul acestor informaţii realizată cu rea credinţă este strict interzisă şi poți suporta consecinţele legale.
          </span>
        </Row>
        <Row>
          <Col xs={12}>
            <Row className="mt-3 mb-3">
              <Col className="checkbox-container pointer">
                <input
                  className="create-account-checkbox"
                  type="checkbox"
                  checked={checkboxOne}
                  onChange={() => setCheckboxOne(!checkboxOne)}
                />
                <span className="fw-bold checkbox-span" onClick={() => setCheckboxOne(!checkboxOne)} style={{ marginLeft: '5px' }}>
                  Sunt de acord cu{' '}
                </span>
                <a className="red hover-red pointer" href="https://delgaz.ro/conditii-de-utilizare" target="_blank" rel="noreferrer">
                  Conditiile de utilizare ale site-ului.
                </a>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col className="checkbox-container pointer">
                <input
                  className="create-account-checkbox"
                  type="checkbox"
                  checked={checkboxTwo}
                  onChange={() => setCheckboxTwo(!checkboxTwo)}
                />
                <span className="fw-bold checkbox-span" onClick={() => setCheckboxTwo(!checkboxTwo)} style={{ marginLeft: '5px' }}>
                  Am luat cunoștință de informațiile privind{' '}
                </span>
                <a className="red hover-red pointer" href="https://delgaz.ro/protectia-datelor" target="_blank" rel="noreferrer">
                  Protecția și confidențialitatea datelor.
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <ChoiceComponent
            options={[
              {
                title: 'Generare automată număr de înregistrare',
                id: 1
              },
              {
                title: 'Există deja un număr de înregistrare',
                id: 2
              }
            ]}
            value={hasRegNr}
            onChange={handleHasRegNr}
            mZero
          />

          {hasRegNr === 2 && (
            <>
              <Col xs={12} sm={4} className="mb-3">
                <div className={`field-group mb-0`}>
                  <span className="input">
                    <InputMask
                      value={regNr}
                      onChange={(e) => setRegNr(e.target.value)}
                      className={'input-with-value'}
                      mask={'01222222'}
                      placeholder={'RC______'}
                      formatChars={regNrFormat}
                    />
                    <label>Număr de înregistrare*</label>
                  </span>
                </div>
                {/* <Input value={regNr} label="Număr de înregistrare*" onChange={(e) => setRegNr(e.target.value)} mbZero/> */}
              </Col>
              <Col xs={12} sm={4} className="mb-3">
                <DatePicker
                  label="Dată de înregistrare*"
                  name="Dată de înregistrare"
                  selected={regNrDate !== '' ? moment(regNrDate).toDate() : regNrDate}
                  onChange={(e: any) => {
                    if (e !== null) {
                      let date = moment(e).format('YYYY-MM-DD');
                      setRegNrDate(date);
                    } else {
                      setRegNrDate('');
                    }
                  }}
                  maxDate={moment().toDate()}
                  mbZero
                />
              </Col>
              {props.userRoles.includes('CRPRSS') && (
                <Col xs={12} sm={4} className="mb-3">
                  <DatePicker
                    label="Dată intrare în DEGR*"
                    name="Dată intrare în DEGR"
                    selected={degrDate !== '' ? moment(degrDate).toDate() : degrDate}
                    onChange={(e: any) => {
                      if (e !== null) {
                        let date = moment(e).format('YYYY-MM-DD');
                        setDegrDate(date);
                      } else {
                        setDegrDate('');
                      }
                    }}
                    maxDate={moment().toDate()}
                    mbZero
                  />
                </Col>
              )}
            </>
          )}
        </Row>
      </div>
    );
  };

  return (
    <div className="rss-call-center-wrapper" style={{ minHeight: modalHeight }}>
      {rssId === '' && (
        <div>
          <ChoiceComponent
            options={[
              { title: 'Sunt client Delgaz Grid', id: true },
              { title: 'Nu sunt client Delgaz Grid', id: false }
            ]}
            onChange={handleIsClient}
            value={isClient}
            mZero
          />

          {isClient && (
            <Search
              county={searchCounty}
              pod={pod}
              cnp={cnp}
              cui={cui}
              handleCounty={handleSearchCounty}
              handlePod={handlePod}
              handleCnp={handleCnp}
              handleCui={handleCui}
              handleData={handleData}
            />
          )}

          {isClient === false && renderNoClient()}
          {isClient === true && data && renderClient()}
        </div>
      )}

      {rssId !== '' && renderSuccessMessage(rssId)}
    </div>
  );
});

export default RSSCallCenter;
