import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';
import React, { Fragment } from 'react';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch, useSelector } from 'react-redux';
import * as notification from '@lib/Notification';
import { IState } from '@type/store';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { store } from '@lib/store';

type DocumentsTableTimelinePropsType = {
  documents: any;
  isBo: boolean | any;
  boBasePath: string | any;
  utility: number;
  showNotificationDate: boolean;
  showDownloadAllButton: boolean;
  requestId: string;
  eventId: number;
};

const DocumentsTableTimeline = (props: DocumentsTableTimelinePropsType) => {
  const apiHost: string | undefined = useSelector<IState>((state) => state.options.apiHost) as string;
  const token: string | undefined = useSelector<IState>((state) => state.auth.token) as string;
  const subKey: string | undefined = useSelector<IState>((state) => state.options.subscriptionKey) as string;
  const state = store.getState();

  const dispatch = useDispatch();

  const downloadAll = () => {
    let url = `${apiHost}/${props.boBasePath}/download_documents_by_event?requestId=${props.requestId}&eventId=${props.eventId}`;
    downloadFile(url, `export-documente-${moment().format('YYMMDDHms')}.zip`);
  };

  const downloadDocumentByLink = async (docLink: string, fileName: string) => {
    let finalLink = '';
    if (docLink && docLink.startsWith('/')) {
      if (props.isBo) {
        finalLink = `${apiHost}/${props.boBasePath}${docLink}`;
      } else {
        if (props.utility == UTILITY_EE) {
          finalLink = `${apiHost}/iconnect/RequestConsumer${docLink}`;
        } else if (props.utility == UTILITY_GN) {
          finalLink = `${apiHost}/iconnect/RequestGN${docLink}`;
        }
      }
      downloadFile(finalLink, fileName);
    } else {
      window.location.href = docLink;
    }
  };

  const downloadFile = (url: string, fileName: string) => {
    dispatch(toggleLoader(true));

    let headers: any = {
      'Ocp-Apim-Subscription-Key': subKey,
      Authorization: 'Bearer ' + token
    };

    if (props.isBo) {
      headers.BackOfficeSecurityContext = state.backOffice.securityContext ?? '';
    }

    axios
      .get(url, {
        headers: headers,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        try {
          setTimeout(() => {
            let res = response.data as BlobPart;
            const blob: Blob = new Blob([res]);
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);

            dispatch(toggleLoader(false));
          }, 500);
        } catch (error) {
          notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          dispatch(toggleLoader(false));
        }
      })
      .catch((err) => {
        notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  return (
    <Fragment>
      <h5 className="mb-2 mt-4">Documente încărcate</h5>
      <div className="general-table-container">
        <table>
          <thead>
            <tr>
              <th>Nr. crt.</th>
              <th className="p-2">Denumire document</th>
              {props.showNotificationDate && <th className="p-2">Dată notificare</th>}
              <th className="p-2">Acțiuni</th>
            </tr>
          </thead>
          <tbody>
            {props.documents.map((document: any, idx: number) => {
              return (
                <tr key={idx}>
                  <td className="p-2">{idx + 1}</td>
                  <td className="p-2">{document.docTypeName}</td>
                  {props.showNotificationDate && <td className="p-2">{document.notificationDate}</td>}
                  <td className="p-2">
                    <a className="bold-link" onClick={() => downloadDocumentByLink(document.docLink, document.fileName)}>
                      Descarcă document
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {props.isBo && props.showDownloadAllButton && (
        <Button className="mt-2 btn-wide" onClick={downloadAll}>
          Descarcă toate
        </Button>
      )}
    </Fragment>
  );
};

export default DocumentsTableTimeline;
