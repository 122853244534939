import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { OverlayImageKentico } from '@type/kentico-types';
import Overlay from '@components/dev/Components/Image/Overlay';

export type TabsProps = { kentico: OverlayImageKentico };

export const OverlayImageComponent = (props: TabsProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <Overlay {...props} />
    </DelgazWrapper>
  );
};
