import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';

const EditableRow = (props: any) => {
    const {columns, rows, editCell, addRowAtIndex, removeRowAtIndex, canEdit, modifyLines} = props;

    const rowHasCheckbox = (row: any) => {
        let hasCheckbox = false;

        Object.keys(row).map((key: any, idx) => {
            if (columns[idx].type === 'checkbox') {
                if (row[key] === false) {
                    hasCheckbox = true;
                }
            }
        });

        return hasCheckbox;
    };

    return (
        <Fragment>
            {rows.map((row: any, index: number) => (
                <tr key={index} style={{height: '25px'}}>
                    {Object.keys(row).map((key: any, idx) => {
                        if (columns[idx].type === 'input') {
                            return <td style={{border: '1px solid grey', minWidth: 'unset'}} className={`${columns[idx].customTd ? 'custom-td' : ''}`} key={idx}>
                                <input
                                    style={{width: columns[idx].width}}
                                    className="table-input"
                                    value={row[key]}
                                    disabled={canEdit === false || rowHasCheckbox(row)}
                                    onChange={(e) => {
                                        e.preventDefault();

                                        if (columns[idx].dataType === 'string') {
                                            editCell(row, key, e.target.value);
                                        } else if (columns[idx].dataType === 'numeric') {
                                            // const re = /^[,.0-9\b]+$/;
                                            const dots = /^[^.]*.?[^.]*$/;
                                            const cons = /^(?!.*\.\.)[,.0-9]+$/;

                                            if (e.target.value === '' || cons.test(e.target.value)) {
                                                if (dots.test(e.target.value)) {
                                                    editCell(row, key, e.target.value);
                                                }
                                            }
                                        } else if (columns[idx].dataType === 'integer') {
                                            const re = /^[0-9\b]+$/;

                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                editCell(row, key, e.target.value);
                                            }
                                        }
                                    }}
                                />
                            </td>;
                        } else if (columns[idx].type === 'select') {
                            return <td style={{border: '1px solid grey'}} key={idx}>
                                <div className="table-select-group field-group select">
                                    <select onChange={(e) => editCell(row, key, e.target.value)} className="table-select" value={row[key]} disabled={canEdit === false}>
                                        <option></option>
                                        { columns[idx].options.map((option: any, indx: number) => (
                                            <option key={indx} value={option.name}>
                                                {option.name}
                                            </option>
                                        )) }
                                    </select>
                                </div>

                            </td>;
                        } else if (columns[idx].type === 'readOnly') {
                            return <td style={{border: '1px solid grey'}} key={idx}>
                                <input
                                    style={{width: columns[idx].width}}
                                    className="table-input"
                                    value={row[key]}
                                    disabled={true}
                                />
                            </td>;
                        } else if (columns[idx].type === 'checkbox') {
                            return <td style={{border: '1px solid grey'}} key={idx}>
                                <input 
                                    className="d-flex" 
                                    style={{margin: '0 auto'}} 
                                    type="checkbox" 
                                    checked={row[key]} 
                                    onChange={(e) => editCell(row, key, !row[key])} 
                                    disabled={canEdit === false}
                                />
                            </td>;
                        }
                    })}
                    {(canEdit !== false) && <td className="d-flex text-center actions-td" style={{marginLeft: '8px'}}>
                        {(rows.length < 10 && modifyLines !== false) && <FontAwesomeIcon className="add-row" icon={faPlus} onClick={() => addRowAtIndex(index)}/>}
                        {(rows.length > 1 && modifyLines !== false) && <FontAwesomeIcon className="remove-row" icon={faMinus} onClick={() => removeRowAtIndex(index)}/>}
                    </td>}
                </tr>
            ))}
        </Fragment>
    );
};

export default EditableRow;