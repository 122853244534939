import { useState } from 'react';
import ConsumptionPointsList from '../consumptionPoints/ConsumptionPointsList';

const InPortofolio = (props: {selected: any, handleSelect: any, onTabChange: any, subject: any}) => {
  //eslint-disable-next-line
  const [list, setList] = useState<any[]>([]);
  //eslint-disable-next-line
  const [metadata, setMetadata] = useState<any>({});

  const handleList = (list: any[]) => {
    setList(list);
  };

  const handleMetadata = (data: any) => {
    setMetadata(data);
  };

  return (
    <div>
      <ConsumptionPointsList 
        handleList={handleList} 
        handleMetadata={handleMetadata} 
        selected={props.selected} 
        handleSelect={props.handleSelect} 
        onTabChange={props.onTabChange}
        subject={props.subject}
      />
    </div>
  );
};

export default InPortofolio;