import { SET_COUNTIES, SET_LOCALITIES, FETCH_SUCCESS } from '@constants/ActionTypes/PostTypes';
import { Action } from '.';

export type County = {
  id: number,
  name: string,
  geoCode: string
}

export type Locality = {
  id: number,
  name: string,
  geoCode: string
}

export class GeoPayload {
  counties: County[] = [];
  localities: Locality[] = [];
}

const initialState = new GeoPayload();

export function geo(state = initialState, action = {} as Action<GeoPayload>) {
  const { type, payload, initialType } = action;

  switch (type) {
    case FETCH_SUCCESS: {

      if (initialType === SET_COUNTIES) {
        return {
          ...state,
          ...payload
        };
      }

      if (initialType === SET_LOCALITIES) {
        return {
          ...state,
          ...payload
        };
      }

      return state;
    }
    default:
      return state;
  }
}
