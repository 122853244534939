(function (delgaz) {
  delgaz.collapsibleList = {
    init: function () {
      this.addEventListeners();
    },
    addEventListeners: function () {
      var $collapsibleContentTitle = document.querySelectorAll('.delgaz-ro-collapsible .delgaz-ro-collapsible-title');
      $collapsibleContentTitle && $collapsibleContentTitle.forEach((element) => {
        element.addEventListener('click', function (ev) {
          let content = this.nextElementSibling;
          var collapsibleContentElement = this.closest('.delgaz-ro-collapsible');
          if (collapsibleContentElement) {
            if (collapsibleContentElement.classList.contains('active')) {
              content !== null && content.setAttribute('style', 'max-height:0');
              collapsibleContentElement.classList.remove('active');
            }
            else {
              content !== null && content.setAttribute('style', `max-height:${content.scrollHeight}px`);
              collapsibleContentElement.classList.add('active');
            }
          }
        });
      });
    }
  };

  window.addEventListener('load', (event) => {
    delgaz.collapsibleList.init();
  });
})((window.delgaz = window.delgaz || {}));
