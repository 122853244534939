import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AdresaObiectivuluiSchema } from '../../../helpers/schemas';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const AdresaObiectivuluiAA = (props: {isEditing: any, innerRef: any, submit?: any, formData: any, handleFormData: any, utility?: string}) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    //eslint-disable-next-line
    const [locality, setLocality] = useState<number | null>(null);

    const {isEditing, innerRef, formData, handleFormData, utility} = props;

    useEffect(() => {
        console.log('formData: ', formData);
        GetCounties(utility && utility).then(res => setCounties(res));

        if (formData.Judet !== '') {
            GetLocalities(formData.Judet).then((res) => {
                setLocalities(res);
                res.map((c: LocalityTemplate, i: number) => {
                    if (formData.Localitate === c.localityId) {
                        console.log(c);
                        setLocality(c.localityId);
                    }
                });
            });
        }

    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    useEffect(() => {
        console.log('formData: ', formData);
    }, [formData]);

    return (
        <div className="mt-3 mb-3">
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={(values) => (values)}
                validationSchema={AdresaObiectivuluiSchema}
                innerRef={innerRef}
                validateOnMount={true}
                validateOnChange={true}
            >
                {(props) => (
                    <Form>
                        <Row>
                            <Col className="mb-3">
                                <Dropdown
                                    options={counties.map((option, index) => (
                                        {
                                            id: option.countyCode,
                                            name: option.countyName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Județ*'}
                                    defaultPlaceholder={'Alege județul'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        console.log(value);
                                        value && handleFormData(value, 'Judet');
                                        value && props.setFieldValue('Judet', value);
                                        value.length === 0 && props.setFieldValue('Judet', '');
                                        setCounty(value);
                                    }}
                                    value={formData.Judet}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Judet" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3">
                                <Dropdown
                                    options={localities.map((option, index) => (
                                        {
                                            id: option.localityCode,
                                            name: option.localityName
                                        }
                                    )) as  DropdownOptionType[]}
                                    label={'Localitate*'}
                                    defaultPlaceholder={'Alege localitatea'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        console.log(value);
                                        value && handleFormData(value, 'Localitate');
                                        value && props.setFieldValue('Localitate', value);
                                        value.length === 0 && props.setFieldValue('Localitate', '');
                                    }}
                                    value={formData.Localitate}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Localitate" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Strada' id="Strada" name="Strada" placeholder="Strada">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Strada}
                                            label='Stradă'
                                            onBlur={() => props.setFieldTouched('Strada')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'Strada');
                                                props.setFieldValue('Strada', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Numar' id="Numar" name="Numar" placeholder="Numar">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Numar}
                                            label='Număr'
                                            onBlur={() => props.setFieldTouched('Numar')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'Numar');
                                                props.setFieldValue('Numar', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Bloc' id="Bloc" name="Bloc" placeholder="Bloc">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Bloc}
                                            label='Bloc'
                                            onBlur={() => props.setFieldTouched('Bloc')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'Bloc');
                                                props.setFieldValue('Bloc', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Scara' id="Scara" name="Scara" placeholder="Scara">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Scara}
                                            label='Scară'
                                            onBlur={() => props.setFieldTouched('Scara')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'Scara');
                                                props.setFieldValue('Scara', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Etaj' id="Etaj" name="Etaj" placeholder="Etaj">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Etaj}
                                            label='Etaj'
                                            onBlur={() => props.setFieldTouched('Etaj')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Etaj');
                                                props.setFieldValue('Etaj', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Ap' id="Ap" name="Ap" placeholder="Ap">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Ap}
                                            label='Apartament'
                                            onBlur={() => props.setFieldTouched('Ap')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'Ap');
                                                props.setFieldValue('Ap', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='nrCF' id="nrCF" name="nrCF" placeholder="nrCF">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.nrCF}
                                            label='Nr. Extras carte funciară (CF)'
                                            onBlur={() => props.setFieldTouched('nrCF')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'nrCF');
                                                props.setFieldValue('nrCF', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='nrCad' id="nrCad" name="nrCad" placeholder="nrCad">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.nrCad}
                                            label='Coordonate geografice, număr cadastral'
                                            onBlur={() => props.setFieldTouched('nrCad')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'nrCad');
                                                props.setFieldValue('nrCad', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AdresaObiectivuluiAA;
